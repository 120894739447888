import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import useQuery from "src/js/hooks/useQuery";
import formatPriceListRow from "../formatPriceListRow";
import getDefaultForm from "./Utilities/getDefaultForm";

type SetFormFunction = (form: Partial<ArticleType> | null | ((prevForm: Partial<ArticleType>) => Partial<ArticleType>)) => void;

type ArticleContextValue = {
	form: Partial<ArticleType> | null;
	article: ArticleType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	handleSave: (article: ArticleType) => any;
} & WithTranslation;

const ArticleContext = React.createContext({} as ArticleContextValue);

export const ArticleContextProvider = withTranslation(["article", "common"])(
	({ id: propsId, match, history, children, t, defaultData = getDefaultForm(history) }) => {
		const [form, setForm] = useState<Partial<ArticleType> | null>(defaultData);
		const id = propsId || match.params.id;
		const [isSaving, setIsSaving] = useState(false);

		const queryClient = useQueryClient();

		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/articles/${id}.json`);

				return res.data.article;
			} catch (error) {
				toastr.error(error);
				return defaultData || null;
			}
		}, [id, defaultData]);

		const queryKey = [id && `article-${id}`];
		const { data: article = null, isFetching }: UseQueryResult<ArticleType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: defaultData || null,
			enabled: !!id,
		});

		const formatPriceListRows = useCallback((article: ArticleType) => {
			const rows = article.article_pricelists?.map((pricelist) => formatPriceListRow(pricelist));

			return {
				...article,
				article_pricelists: rows,
			};
		}, []);

		useEffect(() => {
			if (article) setForm(formatPriceListRows(article));
		}, [article, formatPriceListRows]);

		const saveFunction = useCallback(
			async (article: ArticleType) => {
				const endpoint = !id ? `/api/articles.json` : `/api/articles/${id}.json`;

				const res = await (id ? API.put(endpoint, { ...article }) : API.post(endpoint, { ...article }));

				const successMessage = !id
					? t("articles.responses.article_created", "Artikel skapad")
					: t("articles.responses.article_saved", "Artikel uppdaterad");

				toastr.success(successMessage);

				history.replace(`/admin/articles/${res.data.article.id}`, {
					data: res.data.article,
				});

				return res;
			},
			[id, t, history]
		);

		const update = async ({ article }) => {
			const response = await saveFunction(article);
			return response?.data?.article;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			async (article: ArticleType) => {
				try {
					if (!article) {
						throw new Error("No article");
					}

					setIsSaving(true);
					return await mutation.mutateAsync({ article });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				} finally {
					setIsSaving(false);
				}
			},
			[mutation]
		);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				article,
				isSaving,
			}),
			[isFetching, t, id, form, handleSave, setForm, article, isSaving]
		);

		return useMemo(() => <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>, [value, children]);
	}
);
export default ArticleContext;
