import React, { Component } from "react";
import { Subheading, ChoiceList, Scrollable, Spinner } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import Accordion from "./components/Accordion.js";
import BoardHelper from "./BoardHelper.js";
import Sheet from "../../components/sheet";

class WidgetNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editingTitle: false,
			title: props.widget.title,
			description: props.widget.description ? props.widget.description : null,
			selection: props.widget.options.columns || [],
			type: props.widget.options.type || "sum",
			showSettings: props.showSettings,
		};
		this.state.sum = this.calculateSum(props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings != this.state.showSettings) {
			this.setState({ showSettings: props.showSettings });
		}
		this.setState({ sum: this.calculateSum(props) });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	onClick() {
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	getBoards() {
		if (this.props.dashboard) {
			return this.props.dashboard.boards || [];
		} else {
			return [this.props.board];
		}
	}

	changeWidgetTitle() {
		this.setState({ editingTitle: false });
		if (this.state.title != this.props.widget.title || this.state.description != this.props.widget.description) {
			this.props.widget.title = this.state.title;
			this.props.widget.description = this.state.description;

			API.put(
				"/" +
					"api" +
					(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
					"/widgets/" +
					this.props.widget.id +
					".json",
				{ title: this.state.title, description: this.state.description },
				{ params: {} }
			)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					this.props.onUpdate(result.data.widget);
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	getColumnOptions(board, types) {
		const arr = [];
		if (types.indexOf("rows") >= 0) {
			arr.push({ value: "board_" + board.id + "_rows", label: "Antal rader" });
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (types.indexOf(BoardHelper.getColumn(board.columns[i]).type) >= 0) {
				arr.push({ value: board.columns[i].id + "", label: board.columns[i].title });
			}
		}
		return arr;
	}

	updateOptions() {
		this.setState({ sum: this.calculateSum(this.props) });
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getColumn(selection) {
		for (let i = 0; i < this.getBoards().length; i++) {
			const board = this.getBoards()[i];
			for (let s = 0; s < board.columns.length; s++) {
				if (selection.indexOf(board.columns[s].id + "") >= 0) {
					return board.columns[s];
				}
			}
		}
	}

	calculateSum(props) {
		let sum = 0;
		for (let i = 0; i < props.rows.length; i++) {
			const row = props.rows[i];
			if (this.state.selection.indexOf("board_" + row.board_id + "_rows") >= 0) {
				sum++;
			} else {
				const values = BoardHelper.getColumnValues(row, this.getColumn(this.state.selection));
				for (let s = 0; s < values.length; s++) {
					if (values[s].value) {
						if (typeof values[s].value == "object") {
							if (values[s].value.duration) {
								sum += Math.ceil((values[s].value.duration / 3600) * 100) / 100;
							}
						} else {
							sum += values[s].value * 1;
						}
					}
				}
			}
		}
		return sum;
	}

	render() {
		return (
			<React.Fragment>
				{/* sudda ut true sen */}
				<div>
					<div style={{ paddingLeft: !this.props.compact ? 90 : 65, paddingTop: !this.props.compact ? 24 : 20 }}>
						<div
							style={{
								fontSize: !this.props.compact ? 22 : 18,
								lineHeight: "27px",
							}}
						>
							<div>
								{this.props.loading ? (
									<Spinner size="small" color="teal" />
								) : this.state.type == "avg" ? (
									(this.state.sum / this.props.rows.length).toFixed(2)
								) : (
									this.state.sum.toFixed(2)
								)}
							</div>
						</div>
						{this.state.editingTitle ? (
							<div
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<div
									style={{
										border: "1px dashed #777",
										fontSize: !this.props.compact ? 14 : 12,
									}}
								>
									<input
										ref={(input) => {
											if (input) {
												input.focus();
											}
										}}
										onKeyDown={(event) => {
											if (event.key === "Enter") {
												this.changeWidgetTitle();
											} else if (event.key === "Escape") {
												this.setState({ title: this.props.widget.title, editingTitle: false });
											}
										}}
										onChange={(event) => {
											this.setState({ title: event.target.value });
										}}
										onBlur={this.changeWidgetTitle.bind(this)}
										type="text"
										value={this.state.title}
										style={{
											fontSize: !this.props.compact ? 14 : 13,
											fontWeight: 300,
											display: "block",
											width: "100%",
											border: 0,
											color: "#777",
										}}
									/>
								</div>
							</div>
						) : (
							<div
								onClick={() => {
									if (this.props.editing) {
										this.setState({ editingTitle: true });
									}
								}}
							>
								<span
									className="widget-title"
									style={{
										fontWeight: 300,
										fontSize: 14,
										// paddingTop: 5,
									}}
								>
									{this.state.title}
								</span>
							</div>
						)}
					</div>
				</div>

				<Sheet
					open={this.state.showSettings}
					title={this.props.t("widget.modals.number_settings.title", "Inställningar")}
					onClose={this.props.onCloseSettings}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ height: "100%" }}>
							<Accordion label={this.props.t("widget.modals.number_settings.fields.type.label", "Summering")} expanded>
								<ChoiceList
									title={this.props.t("widget.modals.number_settings.fields.type.options.title", "Uträkning")}
									choices={[
										{ label: this.props.t("widget.modals.number_settings.fields.type.options.sum", "Summa"), value: "sum" },
										{ label: this.props.t("widget.modals.number_settings.fields.type.options.avg", "Genomsnitt"), value: "avg" },
									]}
									selected={this.state.type ? [this.state.type] : []}
									onChange={(v) => {
										this.setState({ type: v[0] });
										this.props.widget.options.type = v[0];
										this.updateOptions();
									}}
								/>
							</Accordion>
							<Accordion label={this.props.t("widget.modals.number_settings.fields.columns.label", "Välj kolumner att använda")} expanded>
								{this.getBoards().map((board) => (
									<div key={board.id} style={{ marginBottom: 15 }}>
										<div style={{ marginBottom: 5 }}>
											<Subheading>{board.title}</Subheading>
										</div>
										<ChoiceList
											allowMultiple
											choices={this.getColumnOptions(board, ["rows", "number", "timetrack"])}
											selected={this.state.selection}
											onChange={(v) => {
												this.setState({ selection: v });
												this.props.widget.options.columns = v;
												this.updateOptions();
											}}
										/>
									</div>
								))}
							</Accordion>
						</Scrollable>
					</div>
				</Sheet>
			</React.Fragment>
		);
	}
}
export default withTranslation(["widget", "common"], { withRef: true })(WidgetNumber);
