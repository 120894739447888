/* eslint-disable react/jsx-props-no-spreading */
import { Select as ShopifySelect } from "@shopify/polaris";
import type { SelectProps as ShopifySelectProps } from "@shopify/polaris";
import { DefaultTFuncReturn } from "i18next";
import { forwardRef } from "react";
import styled, { css } from "styled-components";

interface SelectProps extends Omit<ShopifySelectProps, "label"> {
	label?: string | DefaultTFuncReturn;
	readOnly?: boolean;
	required?: boolean;
}

const Select = forwardRef(({ label = "", readOnly, required, ...rest }: SelectProps, ref) => {
	return (
		<Wrapper readOnly={readOnly} ref={ref}>
			<ShopifySelect label={label as string} {...rest} requiredIndicator={rest.requiredIndicator || required} />
		</Wrapper>
	);
});

export default Select;

const Wrapper = styled.div<{ readOnly?: boolean; ref: any }>`
	${({ readOnly }) =>
		readOnly
			? css`
					pointer-events: none;

					.Polaris-Select__Icon {
						display: none;
					}
			  `
			: ""}
`;
