import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { DatesWrapper, FilterWrapper, Wrapper } from "./styles";
import { WithTranslation, withTranslation } from "react-i18next";
import SalesAdvancedStatsBoxes from "./sections/SalesAdvancedStatsBoxes";
import SalesLeaderBoard from "./sections/SalesLeaderBoard";
import moment from "moment";
import NewDatePicker from "src/js/components/NewDatePicker";
import { store } from "src/js/store";
import useQuery from "src/js/hooks/useQuery";
import API from "src/js/API";
import SalesValueBarGraph from "./sections/SalesValueBarGraph";
// import UserFilter from "./UserFilter";
import SalesLineGraph from "./sections/SalesLineGraph";
import getFiscalYearOrDefaultStartDate from "src/js/Utilities/getFiscalYearOrDefaultStartDate";
import MyFilters from "src/js/components/MyFilters";
import { Checkbox } from "@shopify/polaris";
import { useSelector } from "react-redux";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import Button from "src/js/components/Button";
import { RefreshMajor } from "@shopify/polaris-icons";
import { SectionWrapper, GraphWrapper } from "../styles";

type BoardSalesDashboardProps = {
	history: any;
} & WithTranslation;

const BoardSalesDashboard = ({ t, history }: BoardSalesDashboardProps) => {
	const startDate = getFiscalYearOrDefaultStartDate();
	const [filterSettings, setFilterSettings] = useState<any>({});
	const [appliedFilters, setAppliedFilters] = useState([]);
	const storeUsers = useSelector((state: any) => state.users);
	const userIdsOptions = useMemo(() => {
		const options = storeUsers
			.map((user) => ({
				label: user.name + (!user.enabled ? ` (${t("sales.dashboard.terms.inactive", "Inaktiv")})` : ""),
				media: <ProfileAvatar user={user} size={30} />,
				value: user.id,
				inactive: !user.enabled,
			}))
			.sort((a, b) => a.label.localeCompare(b.label))
			?.filter((u) => {
				return filterSettings?.assigned_user_ids?.incluceInactive || !u.inactive;
			});

		return [
			// {
			// 	label: t("sales.dashboard.filters.users.missing", "< Saknar användare >"),
			// 	value: null,
			// },
			...options,
		];
	}, [storeUsers, t, filterSettings?.assigned_user_ids]);

	const [fromdate, setFromdate] = useState(startDate);
	const [todate, setTodate] = useState(moment(startDate).add(1, "year").subtract(1, "day").format("YYYY-MM-DD"));

	const [userIds, setUserIds] = useState();

	useLayoutEffect(() => {
		try {
			const hash = window.location.hash;
			if (hash) {
				const params = JSON.parse(decodeURIComponent(hash.substring(1)));
				if (params.dates_from) setFromdate(params.dates_from);
				if (params.dates_to) setTodate(params.dates_to);
				if (params.assigned_user_ids) setUserIds(params.assigned_user_ids);
				if (params.column_filters) {
					setAppliedFilters(
						Object.entries(params.column_filters || {}).map(([key, value]) => {
							return {
								key,
								value,
							};
						}) as any
					);
				}
			}
		} catch (error) {
			console.error("Failed to parse filters: ", error);
		}
	}, []);

	const params: any = useMemo(() => {
		const p: any = {
			also_archived: 1,

			dates_from: fromdate,
			dates_to: todate,
			assigned_user_ids: userIds,
			column_filters: appliedFilters.reduce((acc: any, f: any) => {
				acc[f.key] = f.value;

				return acc;
			}, {}),
		};

		return p;
	}, [fromdate, todate, userIds, appliedFilters]);

	useEffect(() => {
		setTimeout(() => {
			// window.location.hash = encodeURIComponent(JSON.stringify(params));
			history.replace({
				pathname: window.location.pathname,
				hash: encodeURIComponent(JSON.stringify(params)),
			});
		}, 0);
	}, [params, history]);

	const boardId = store.getState().account.sales_board_id;
	const board = store.getState().boards?.[boardId];

	const handleFetchStats = async () => {
		const response = await API.post(`/api/standard_dashboards/sales.json`, params);

		return response.data;
	};

	const queryKey = [`board_${boardId}_dashboard`, params];
	const {
		data,
		isFetching: isLoading,
		refetch,
	} = useQuery({
		queryKey,
		queryFn: handleFetchStats,
	});

	const handleChangeDate = (key) => (value) => {
		if (key === "fromdate") {
			setFromdate(value);
		} else if (key === "todate") {
			setTodate(value);
		}
	};

	const handleFiltersChange = (newAppliedFilters) => {
		setAppliedFilters(newAppliedFilters);
	};

	const filters = useMemo(
		() => [
			{
				key: "assigned_user_ids",
				label: t("dashboards.filters.assigned_user_ids.label", "Användare"),
				type: "options",
				options: userIdsOptions,
				shortcut: true,
				allowMultiple: true,
				search: {
					suffix: (
						<Checkbox
							label={t("sales.dashboard.filters.include_inactive", "Inkludera inaktiva")}
							checked={!!filterSettings?.assigned_user_ids?.incluceInactive}
							onChange={(checked) => {
								setFilterSettings({
									...filterSettings,
									assigned_user_ids: {
										incluceInactive: checked,
									},
								});
							}}
						/>
					),
				},
			},
			...(board.columns
				?.filter((col) => ["tags", "dropdown"].includes(col.type))
				.map((col) => {
					return {
						key: col.id,
						label: col.title,
						type: "options",
						options:
							(col.type === "dropdown"
								? col.options?.choices
										?.map((choice, index) => ({
											label: choice,
											value: index,
											removed: col.options?.removed_choices?.includes(index),
										}))
										?.filter((chooice) => {
											return filterSettings?.[col.id]?.incluceRemoved || !chooice.removed;
										})
								: board.tags.map((tag) => ({
										value: tag.id,
										prefix: (
											<div
												style={{
													borderRadius: 10,
													height: 20,
													width: 20,
													backgroundColor: tag.color,
												}}
											/>
										),
										label: tag.title,
								  }))) || [],

						allowMultiple: true,
						search:
							col.type === "dropdown"
								? {
										suffix: (
											<Checkbox
												label={t("sales.dashboard.filters.include_removed", "Inkludera borttagna")}
												checked={!!filterSettings?.[col.id]?.incluceRemoved}
												onChange={(checked) => {
													setFilterSettings({
														...filterSettings,
														[col.id]: {
															incluceRemoved: checked,
														},
													});
												}}
											/>
										),
								  }
								: true,
					};
				}) || []),
		],
		[t, board, filterSettings, userIdsOptions]
	);

	return (
		<>
			<FilterWrapper>
				<Button loading={isLoading} icon={RefreshMajor} onClick={refetch} />
				<MyFilters filters={filters} appliedFilters={appliedFilters} onFiltersChange={handleFiltersChange} hideQueryField>
					<DatesWrapper style={{ marginLeft: "1rem" }}>
						<NewDatePicker optionalTime={false} value={fromdate} onChange={handleChangeDate("fromdate")} />
						<NewDatePicker optionalTime={false} value={todate} onChange={handleChangeDate("todate")} />
					</DatesWrapper>
				</MyFilters>
			</FilterWrapper>
			<Wrapper>
				<SectionWrapper gridArea="line_graph">
					<GraphWrapper>
						<SalesLineGraph data={data} loading={isLoading} fromdate={fromdate} todate={todate} />
					</GraphWrapper>
				</SectionWrapper>

				<SectionWrapper gridArea="leaderboard">
					<SalesLeaderBoard data={data} loading={isLoading} />
				</SectionWrapper>

				<SectionWrapper gridArea="bar_graph">
					<GraphWrapper>
						<SalesValueBarGraph data={data} loading={isLoading} />
					</GraphWrapper>
				</SectionWrapper>

				<SectionWrapper gridArea="boxes">
					<SalesAdvancedStatsBoxes data={data} loading={isLoading} fromdate={fromdate} todate={todate} />
				</SectionWrapper>
			</Wrapper>
		</>
	);
};

export default withTranslation(["sales", "common"])(BoardSalesDashboard);
