import React, { Component } from "react";
import { ExceptionList, ChoiceList, Modal, FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import BigData from "../../components/big_data";

class AutomationIndex extends Component {
	constructor(props) {
		super(props);
		this.state = { option: ["visitors"], visibleItems: [] };
	}

	componentDidMount() {}

	renderCell(item, column) {
		if (column.handle == "TITLE") {
			const exceptions = [];
			if (item.content) {
				exceptions.push({
					icon: "notes",
					description: item.content,
				});
			}

			const exceptionList = exceptions.length ? (
				<div className="CustomerListItem__Exceptions">
					<ExceptionList items={exceptions} />
				</div>
			) : null;
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
					{exceptionList}
				</div>
			);
		}
	}

	gotoAutomation(item) {
		item.path = "/admin/automations/" + item.id;
		this.props.history.push("/admin/automations/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("automation.breadcrumb", "E-postkampanjer"),
					item: { id: item.id, item, path: "/admin/automations/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	render() {
		const columns = [{ handle: "TITLE", label: this.props.t("automation.columns.title.label", "Händelse"), sortable: false, type: "text" }];
		const filters = [];
		return (
			<React.Fragment>
				<BigData
					history={this.props.history}
					// setRefreshHandler={(refreshHandler) => {
					// 	this.refresh = refreshHandler;
					// }}
					title={this.props.t("automation.title", "Automatiseringar")}
					primaryAction={{
						content: this.props.t("automation.actions.new", "Ny automatisering"),
						onClick: () => {
							this.setState({ showCreateModal: true });
						},
					}}
					savedSearchHandle="automations"
					resourceUrl="/api/automations.json"
					resourceHandle="automations"
					resourceName={{
						singular: this.props.t("automation.singular", "automatisering"),
						plural: this.props.t("automation.plural", "automatiseringar"),
					}}
					renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.gotoAutomation(item);
					}}
					columns={columns}
					filters={filters}
					promotedBulkActions={[]}
					bulkActions={[]}
				/>
				<Modal
					open={this.state.showCreateModal}
					onClose={() => {
						this.setState({ showCreateModal: false });
					}}
					title={this.props.t("automation.modal.title", "Spårningskod")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showCreateModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<FormLayout>
							<ChoiceList
								title={this.props.t("automation.fields.how.label", "Hur hamnar en kontakt här?")}
								choices={[
									{ label: this.props.t("automation.fields.how.visitors", "Identifierade besökare från hemsidan"), value: "visitors" },
									{ label: this.props.t("automation.fields.how.empty", "Blank"), value: "blank" },
								]}
								selected={this.state.option}
								onChange={(v) => {
									this.setState({ option: v });
								}}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</React.Fragment>
		);
	}
}
export default withTranslation(["automation", "common"], { withRef: true })(AutomationIndex);
