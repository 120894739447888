import i18n from "./i18n";

const getTranslations = () => {
	return {
		Polaris: {
			DropZone: {
				FileUpload: {
					actionTitleImage: i18n.t("polaris.dropzone.fileupload.actiontitleimage", "Ladda upp bild"),
					actionHintImage: i18n.t("polaris.dropzone.fileupload.actionHintImage", "eller dra och släpp bild här"),
					actionTitleSpreadsheet: i18n.t("polaris.dropzone.fileupload.actionTitleSpreadsheet", "Ladda upp fil"),
					actionHintSpreadsheet: i18n.t("polaris.dropzone.fileupload.actionHintSpreadsheet", "eller dra och släpp fil här"),
				},
			},
			Common: {
				undo: i18n.t("polaris.common.undo", "ångra"),
			},
			DatePicker: {
				previousMonth: i18n.t("polaris.datepicker.previousmonth", "Föregående"),
				nextMonth: i18n.t("polaris.datepicker.nextmonth", "Nästa"),
				months: {
					january: i18n.t("polaris.datepicker.months.january", "januari"),
					february: i18n.t("polaris.datepicker.months.february", "februari"),
					march: i18n.t("polaris.datepicker.months.march", "mars"),
					april: i18n.t("polaris.datepicker.months.april", "april"),
					may: i18n.t("polaris.datepicker.months.may", "maj"),
					june: i18n.t("polaris.datepicker.months.june", "juni"),
					july: i18n.t("polaris.datepicker.months.july", "juli"),
					august: i18n.t("polaris.datepicker.months.august", "augusti"),
					september: i18n.t("polaris.datepicker.months.september", "september"),
					october: i18n.t("polaris.datepicker.months.october", "oktober"),
					november: i18n.t("polaris.datepicker.months.november", "november"),
					december: i18n.t("polaris.datepicker.months.december", "december"),
				},
				daysAbbreviated: {
					monday: i18n.t("polaris.datepicker.daysabbreviated.monday", "mån"),
					tuesday: i18n.t("polaris.datepicker.daysabbreviated.tuesday", "tis"),
					wednesday: i18n.t("polaris.datepicker.daysabbreviated.wednesday", "ons"),
					thursday: i18n.t("polaris.datepicker.daysabbreviated.thursday", "tor"),
					friday: i18n.t("polaris.datepicker.daysabbreviated.friday", "fre"),
					saturday: i18n.t("polaris.datepicker.daysabbreviated.saturday", "lör"),
					sunday: i18n.t("polaris.datepicker.daysabbreviated.sunday", "sön"),
				},
			},
			Filters: {
				filter: i18n.t("polaris.filters.filter", "Sök"),
				cancel: i18n.t("polaris.filters.cancel", "avbryt"),
				done: i18n.t("polaris.filters.done", "klar"),
				noFiltersApplied: i18n.t("polaris.filters.nofiltersapplied", "Inga filter"),
				clear: i18n.t("polaris.filters.clear", "rensa"),
				clearLabel: i18n.t("polaris.filters.clearLabel", "rensa"),
				clearAllFilters: i18n.t("polaris.filters.clearallfilters", "rensa alla filter"),
				moreFilters: i18n.t("polaris.filters.morefilters", "Fler filter"),
			},
			ResourceList: {
				loading: i18n.t("polaris.resourcelist.loading", "Laddar {{resource}}", {
					resource: "{resource}",
				}),
				showing: i18n.t("polaris.resourcelist.showing", "Visar {{itemsCount}} {{resource}}", {
					itemsCount: "{itemsCount}",
					resource: "{resource}",
				}),
				defaultItemPlural: i18n.t("polaris.resourcelist.defaultitemplural", "artiklar"),
				defaultItemSingular: i18n.t("polaris.resourcelist.defaultitemsingular", "artikel"),
				sortingLabel: i18n.t("polaris.resourcelist.sortinglabel", "Sortera på"),
				selected: i18n.t("polaris.resourcelist.selected", "{{selectedItemsCount}} markerade", {
					selectedItemsCount: "{selectedItemsCount}",
				}),
				allItemsSelected: i18n.t("polaris.resourcelist.allitemsselected", "Alla {{itemsLength}}+ {{resourceNamePlural}} är markerade", {
					itemsLength: "{itemsLength}",
					resourceNamePlural: "{resourceNamePlural}",
				}),
				selectAllItems: i18n.t("polaris.resourcelist.selectallitems", "Markera alla {{itemsLength}}+ {resourceNamePlural}", {
					itemsLength: "{itemsLength}",
					resourceNamePlural: "{resourceNamePlural}",
				}),
				emptySearchResultTitle: i18n.t("polaris.resourcelist.emptysearchresulttitle", "Inga {{resourceNamePlural}} hittades", {
					resourceNamePlural: "{resourceNamePlural}",
				}),
				emptySearchResultDescription: i18n.t("polaris.resourcelist.emptysearchresultdescription", "Prova att ändra filter eller sökning"),
				selectButtonText: i18n.t("polaris.resourcelist.selectbuttontex", "Markera"),
				a11yCheckboxDeselectAllSingle: i18n.t("polaris.resourcelist.a11ycheckboxdeselectallsingle", "Avmarkera {{resourceNameSingular}}", {
					resourceNameSingular: "{resourceNameSingular}",
				}),
				a11yCheckboxSelectAllSingle: i18n.t("polaris.resourcelist.a11ycheckboxselectallsingle", "Markera {{resourceNameSingular}}", {
					resourceNameSingular: "{resourceNameSingular}",
				}),
				a11yCheckboxDeselectAllMultiple: i18n.t(
					"polaris.resourcelist.a11ycheckboxdeselectallmultiple",
					"Avmarkera alla {{itemsLength}} {{resourceNamePlural}}",
					{
						itemsLength: "{itemsLength}",
						resourceNamePlural: "{resourceNamePlural}",
					}
				),
				a11yCheckboxSelectAllMultiple: i18n.t(
					"polaris.resourcelist.a11ycheckboxselectallmultiple",
					"Markera alla {{itemsLength}} {{resourceNamePlural}}",
					{
						itemsLength: "{itemsLength}",
						resourceNamePlural: "{resourceNamePlural}",
					}
				),
				ariaLiveSingular: i18n.t("polaris.resourcelist.arialivesingular", "{{itemsLength}} artikel", {
					itemsLength: "{itemsLength}",
				}),
				ariaLivePlural: i18n.t("polaris.resourcelist.arialiveplural", "{{itemsLength}} artiklar", {
					itemsLength: "{itemsLength}",
				}),
				BulkActions: {
					actionsActivatorLabel: i18n.t("polaris.resourcelist.bulkactions.actionsactivatorlabel", "Åtgärd"),
					moreActionsActivatorLabel: i18n.t("polaris.resourcelist.bulkactions.moreactionsactivatorlabel", "Fler åtgärder"),
				},
				FilterCreator: {
					filterButtonLabel: i18n.t("polaris.resourcelist.filtercreator.filterbuttonlabel", "Filter"),
					selectFilterKeyPlaceholder: i18n.t("polaris.resourcelist.filtercreator.filterbuttonlabel", "Välj ett filter…"),
					addFilterButtonLabel: i18n.t("polaris.resourcelist.filtercreator.addfilterbuttonlabel", "Lägg till filter"),
					showAllWhere: i18n.t("polaris.resourcelist.filtercreator.showallwhere", "Visa alla {{resourceNamePlural}} med:", {
						resourceNamePlural: "{resourceNamePlural}",
					}),
				},
				FilterControl: {
					textFieldLabel: i18n.t("polaris.resourcelist.filtercontrol.textfieldlabel", "Sök"),
				},
				FilterValueSelector: {
					selectFilterValuePlaceholder: i18n.t("polaris.resourcelist.filtervalueselector", "Välj ett filter…"),
				},
				DateSelector: {
					dateFilterLabel: i18n.t("polaris.resourcelist.dateselector.datefilterlabel", "Välj ett värde"),
					dateValueLabel: i18n.t("polaris.resourcelist.dateselector.datevaluelabel", "Datum"),
					dateValuePlaceholder: i18n.t("polaris.resourcelist.dateselector.datevalueplaceholder", "YYYY-MM-DD"),
					SelectOptions: {
						PastWeek: i18n.t("polaris.resourcelist.dateselector.selectoptions.pastweek", "in the last week"),
						PastMonth: i18n.t("polaris.resourcelist.dateselector.selectoptions.pastmonth", "in the last month"),
						PastQuarter: i18n.t("polaris.resourcelist.dateselector.selectoptions.pastquarter", "in the last 3 months"),
						PastYear: i18n.t("polaris.resourcelist.dateselector.selectoptions.pastyear", "in the last year"),
						ComingWeek: i18n.t("polaris.resourcelist.dateselector.selectoptions.comingweek", "next week"),
						ComingMonth: i18n.t("polaris.resourcelist.dateselector.selectoptions.comingmonth", "next month"),
						ComingQuarter: i18n.t("polaris.resourcelist.dateselector.selectoptions.comingquarter", "in the next 3 months"),
						ComingYear: i18n.t("polaris.resourcelist.dateselector.selectoptions.comingyear", "in the next year"),
						OnOrBefore: i18n.t("polaris.resourcelist.dateselector.selectoptions.onorbefore", "från och med"),
						OnOrAfter: i18n.t("polaris.resourcelist.dateselector.selectoptions.onorafter", "till och med"),
					},
					FilterLabelForValue: {
						past_week: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.past_week", "in the last week"),
						past_month: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.past_month", "in the last month"),
						past_quarter: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.past_quarter", "in the last 3 months"),
						past_year: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.past_year", "in the last year"),
						coming_week: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.coming_week", "next week"),
						coming_month: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.coming_month", "next month"),
						coming_quarter: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.coming_quarter", "in the next 3 months"),
						coming_year: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.coming_year", "in the next year"),
						on_or_before: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.on_or_before", "till och med {{date}}", {
							date: "{date}",
						}),
						on_or_after: i18n.t("polaris.resourcelist.dateselector.filterlabelforvalue.on_or_after", "från och med {{date}}", {
							date: "{date}",
						}),
					},
				},
			},
			frame: {
				routes: {
					dashboard: i18n.t("frame.routes.dashboard", "Dashboard"),
					products: i18n.t("frame.routes.products", "Produkter"),
					pricing: i18n.t("frame.routes.pricing", "Priser"),
					inventory: i18n.t("frame.routes.inventory", "Lager"),
					categories: i18n.t("frame.routes.categories", "Kategorier"),
					listings: i18n.t("frame.routes.listings", "Listningar"),
					orders: i18n.t("frame.routes.orders", "Ordrar"),
					dropshipping_catalog: i18n.t("frame.routes.dropshipping_catalog", "Dropshipping produkter"),
					sales_channels: i18n.t("frame.routes.sales_channels", "Säljkanaler"),
					settings: i18n.t("frame.routes.settings", "Inställningar"),
					blogs: i18n.t("frame.routes.blogs", "Blogginlägg"),
					pages: i18n.t("frame.routes.pages", "Sidor"),
					customers: i18n.t("frame.routes.customers", "Kunder"),
					fulfillments: i18n.t("frame.routes.fulfillments", "Leveranser"),
					customize_design: i18n.t("frame.routes.customize_design", "Ändra design"),
				},
				user: {
					log_out: i18n.t("frame.user.log_out", "Logga ut"),
				},
				search: {
					placeholder: i18n.t("frame.search.placeholder", "Sök affärer, kontakter, bolag, ärenden"),
				},
			},
			searches: {
				"no_%resourceName%_found": i18n.t("searches.no_resourcename_found", "Inga {{resourceName}} hittades", {
					resourceName: "%resourceName%",
				}),
				"add_%tagName%": i18n.t("searches.add_tagname", "Lägg till {{tagName}}", {
					tagName: "%tagName%",
				}),
				"create_new_%resourceName%": i18n.t("searches.create_new_resourcename", "Skapa ny {{resourceName}}", {
					resourceName: "%resourceName%",
				}),
				sorting: {
					title_asc: i18n.t("searches.sorting.title_asc", "Titel (A-Ö)"),
					title_desc: i18n.t("searches.sorting.title_desc", "Titel (Ö-A)"),
					created_at_desc: i18n.t("searches.sorting.created_at_desc", "Nyast"),
					created_at_asc: i18n.t("searches.sorting.created_at_asc", "Äldst"),
					updated_at_desc: i18n.t("searches.sorting.updated_at_desc", "Senast uppdaterad"),
					updated_at_asc: i18n.t("searches.sorting.updated_at_asc", "Uppdaterad sist"),
				},
				filters: {
					is: i18n.t("searches.filters.i", "är"),
					yes: i18n.t("searches.filters.yes", "Ja"),
					no: i18n.t("searches.filters.no", "Nej"),
					is_greater_than: i18n.t("searches.filters.is_greater_than", "större än"),
					modal: {
						title: i18n.t("searches.filters.modal.title", "Spara filter"),
						save_as_a_new_filter: i18n.t("searches.filters.modal.save_as_a_new_filter", "Spara som en nytt filter"),
						new_saved_filter_name: i18n.t("searches.filters.modal.new_saved_filter_name", "Namn på nytt filter"),
						overwrite_an_existing_filter: i18n.t("searches.filters.modal.overwrite_an_existing_filter", "Skriv över ett sparat filter"),
						select_filter_to_overwrite: i18n.t("searches.filters.modal.select_filter_to_overwrite", "Vilket filter vill du skriva över"),
					},
				},
				saved_searches: {
					singular: i18n.t("searches.saved_searches", "sparad sökning"),
					plural: i18n.t("searches.saved_searches", "sparade sökningar"),
					"all_%types%": i18n.t("searches.saved_searches.all_types", "Alla {{types}}", {
						types: "%types%",
					}),
					custom_search: i18n.t("searches.saved_searches.custom_search", "Egen sökning"),
					actions: {
						save: i18n.t("searches.saved_searches.actions.save", "Spara"),
					},
					modal: {
						title: i18n.t("searches.saved_searches.modal.title", "Spara sökning"),
						save_as_a_new_search: i18n.t("searches.saved_searches.modal.save_as_a_new_search", "Spara som en ny sökning"),
						new_saved_search_name: i18n.t("searches.saved_searches.modal.new_saved_search_name", "Namn på ny sökning"),
						overwrite_an_existing_search: i18n.t("searches.saved_searches.modal.overwrite_an_existing_search", "Skriv över en sparad sökning"),
						select_search_to_overwrite: i18n.t("searches.saved_searches.modal.select_search_to_overwrite", "Vilken sökning vill du skriva över?"),
					},
				},
			},
			security: {
				login_title: i18n.t("security.login_title", "Logga in"),
				register_title: i18n.t("security.register_title", "Registrera ett nytt konto"),
				forgot_password_title: i18n.t("security.forgot_password_title", "Återställ lösenord"),
				register_new_account: i18n.t("security.register_new_account", "Inget konto? Boka demo här"),
				email: i18n.t("security.email", "E-post"),
				by_signing_up_you_agree_to_our: i18n.t("security.by_signing_up_you_agree_to_our", "Genom att skapa konto accepterar du våra"),
				login: {
					password: i18n.t("security.login.password", "Lösenord"),
					forgot_password: i18n.t("security.login.forgot_password", "Glömt lösenord?"),
				},
				register: {
					currency: i18n.t("security.register.currency", "Valuta"),
					street: i18n.t("security.register.street", "Gatuadress"),
					zip: i18n.t("security.register.zip", "Postnummer"),
					city: i18n.t("security.register.city", "Stad"),
					language: i18n.t("security.register.language", "Språk"),
					country: i18n.t("security.register.country", "Land"),
					phone: i18n.t("security.register.phone", "Telefon"),
					email: i18n.t("security.register.email", "E-post"),
					employees: i18n.t("security.register.employees", "Antal anställda"),
					name: i18n.t("security.register.name", "För- och efternamn"),
					products: i18n.t("security.register.products", "Produkter jag vill sälja"),
					company_name: i18n.t("security.register.company_name", "Företagsnamn"),
					orgnr: i18n.t("security.register.orgnr", "Org.nr"),
					password: i18n.t("security.register.password", "Lösenord"),
					password_again: i18n.t("security.register.password_again", "Bekräfta lösenord"),
				},
				cancel: i18n.t("security.cancel", "Avbryt"),
				loading: {
					message1: i18n.t("security.loading.message1", "Skapar konto..."),
					message2: i18n.t("security.loading.message2", "Ställer in standardvärden..."),
					message3: i18n.t("security.loading.message3", "Skapar personlig kalender..."),
					message4: i18n.t("security.loading.message4", "Laddar in exempel..."),
					message5: i18n.t("security.loading.message5", "Dricker en kopp kaffe..."),
					message6: i18n.t("security.loading.message6", "Väntar bara life..."),
					message7: i18n.t("security.loading.message7", "Ibland tar det tid... men oroa dig inte!"),
					message8: i18n.t("security.loading.message8", "Snart...!"),
					message9: i18n.t("security.loading.message9", "Håll ut...!"),
					message10: i18n.t("security.loading.message10", "Ge inte upp...!"),
				},
			},
			buttons: {
				change_plan: i18n.t("buttons.change_plan", "Byt abonnemang"),
				import: i18n.t("buttons.import", "Importera"),
				close: i18n.t("buttons.close", "Stäng"),
				cancel: i18n.t("buttons.cancel", "Avbryt"),
				create: i18n.t("buttons.create", "Skapa"),
				add_card: i18n.t("buttons.add_card", "Add card"),
				save: i18n.t("buttons.save", "Spara"),
				delete: i18n.t("buttons.delete", "Ta bort"),
				remove: i18n.t("buttons.remove", "Ta bort"),
				update: i18n.t("buttons.update", "Uppdatera"),
				connect: i18n.t("buttons.connect", "Anslut"),
				disconnect: i18n.t("buttons.disconnect", "Koppla från"),
				add: i18n.t("buttons.add", "Lägg till"),
				ok: i18n.t("buttons.ok", "OK"),
				post: i18n.t("buttons.post", "Skicka"),
				merge: i18n.t("buttons.merge", "Slå ihop"),
			},
			categories: {
				title: i18n.t("categories.title", "Kategorier"),
				new_title: i18n.t("categories.new_title", "Ny kategori"),
				empty_title: i18n.t("categories.empty_title", "Inga kategorier"),
				empty_explanation: i18n.t(
					"categories.empty_explanation",
					"Lägg till eller skapa din första kategori genom att skapa en produkt eller en kategori"
				),
				empty_primary: i18n.t("categories.empty_primary", "Skapa kategori"),
				empty_secondary: i18n.t("categories.empty_secondary", "Skapa ny produkt"),
				new_subset_title: i18n.t("categories.new_subset_title", "Ny underkategori"),
				singular: i18n.t("categories.singular", "kategori"),
				plural: i18n.t("categories.plural", "kategorier"),
				"%num%_products": i18n.t("categories.num_products", "{{num}} produkter", {
					num: "%num%",
				}),
				not_listed_on_any_channels: i18n.t("categories.not_listed_on_any_channels", "Ej ansluten till några kanaler"),
				"listed_on_%num%_channels": i18n.t("categories.listed_on_num_channels", "Listad på {{num}} kanaler", {
					num: "%num%",
				}),
				sorting: {
					size_asc: i18n.t("categories.sorting.size_asc", "Storlek (minst till störst)"),
					size_desc: i18n.t("categories.sorting.size_desc", "Storlek (störst till minst)"),
				},
				alerts: {
					removed_categories: i18n.t("categories.alerts.removed_categories", "Tog bort kategorier"),
					removed_category: i18n.t("categories.alerts.removed_category", "Tog bort kategori"),
					saved_category: i18n.t("categories.alerts.saved_category", "Kategorin sparades"),
					created: i18n.t("categories.alerts.created", "Kategorin skapades"),
				},
				actions: {
					add_category: i18n.t("categories.actions.add_category", "Skapa kategori"),
					create_automatic_subset: i18n.t("categories.actions.create_automatic_subset", "Skapa automatisk underkategori"),
					remove_selected: i18n.t("categories.actions.remove_selected", "Ta bort valda kategorier"),
				},
				conditions: {
					title: i18n.t("categories.conditions.title", "Titel"),
					category: i18n.t("categories.conditions.category", "Kategori"),
					content: i18n.t("categories.conditions.content", "Beskrivning"),
					keyword: i18n.t("categories.conditions.keyword", "Nyckelord"),
					key_feature: i18n.t("categories.conditions.key_feature", "Säljargument"),
					metafield: i18n.t("categories.conditions.metafield", "Attribut"),
					tag: i18n.t("categories.conditions.tag", "Tagg"),
					brand: i18n.t("categories.conditions.brand", "Varumärke"),
					type: i18n.t("categories.conditions.type", "Produkttyp"),
				},
				operators: {
					contains: i18n.t("categories.operators.contains", "innehåller"),
					not_contains: i18n.t("categories.operators.not_contains", "innehåller inte"),
					equals: i18n.t("categories.operators.equals", "är lika med"),
					not_equal_to: i18n.t("categories.operators.not_equal_to", "är annat än"),
					begins_with: i18n.t("categories.operators.begins_with", "börjar med"),
					ends_with: i18n.t("categories.operators.ends_with", "slutar med"),
					is_empty: i18n.t("categories.operators.is_empty", "är tom"),
					is_not_empty: i18n.t("categories.operators.is_not_empty", "är ej tom"),
				},
				form: {
					title: i18n.t("categories.form.title", "Titel"),
					sales_channels: {
						card_title: i18n.t("categories.form.sales_channels.card_title", "Säljkanaler"),
						button_manage: i18n.t("categories.form.sales_channels.button_manage", "Hantera"),
						"available_on_%num%_of_%available%": i18n.t(
							"categories.form.sales_channels.available_on_num_of_available",
							"Tillgänglig på %num% av %available%",
							{
								num: " %num%",
								available: "%available%",
							}
						),
						modal: {
							title: i18n.t("categories.form.modal.title", "Hantera tillgängliga säljkanaler"),
						},
					},
					subsets: {
						card_title: i18n.t("categories.form.subsets.card_title", "Underkategorier"),
					},
					conditions: {
						card_title: i18n.t("categories.form.conditions.card_title", "Regler"),
						button_add_rule: i18n.t("categories.form.conditions.button_add_rule", "Ny regel"),
						match_all_conditions: i18n.t("categories.form.conditions.match_all_conditions", "Matcha ALLA följande regler"),
						match_any_conditions: i18n.t("categories.form.conditions.match_any_conditions", "Matcha minst EN av följande regler"),
						value_cant_be_blank: i18n.t("categories.form.conditions.value_cant_be_blank", "Värdet kan inte vara tomt"),
						enter_category_name: i18n.t("categories.form.conditions.enter_category_name", "Fyll i kategoriens namn"),
						metafield_handle: i18n.t("categories.form.conditions.metafield_handle", "Attribut namn"),
					},
					products: {
						card_title: i18n.t("categories.form.products.card_title", "Produkter"),
						search_placeholder: i18n.t("categories.form.products.search_placeholder", "Sök efter produkter att lägga till"),
						no_valid_conditions_added: i18n.t("categories.form.products.no_valid_conditions_added", "Inga giltiga regler finns"),
						show_in_subset: i18n.t("categories.form.products.show_in_subset", "Visa produkter i underkategori"),
						hide_in_subset: i18n.t("categories.form.products.hide_in_subset", "Dölj produkter i underkategori"),
						alerts: {
							added_product: i18n.t("categories.form.products.alerts.added_product", "Skapde produkt"),
							removed_product: i18n.t("categories.form.products.alerts.removed_product", "Tog bort produkt"),
							removed_products: i18n.t("categories.form.products.alerts.removed_products", "Tog bort produkter från kategori"),
							added_tags_to_products: i18n.t("categories.form.products.alerts.added_tags_to_products", "Taggade produkter"),
							removed_tags_from_products: i18n.t("categories.form.products.alerts.removed_tags_from_products", "Taggar borttagna"),
						},
						actions: {
							add_to_subset: i18n.t("categories.form.products.actions.add_to_subset", "Lägg i underkategori"),
							add_tags: i18n.t("categories.form.products.actions.add_tags", "Lägg till taggar"),
							remove_tags: i18n.t("categories.form.products.actions.remove_tags", "Ta bort taggar"),
							remove_from_category: i18n.t("categories.form.products.actions.remove_from_category", "Ta bort från kategori"),
						},
						modals: {
							subset: {
								"title_%num%": i18n.t("categories.form.products.modals.subset.title_num", "Lägg %num% produkter i underkategori"),
								save_as_a_new_subset: i18n.t("categories.form.products.modals.subset.save_as_a_new_subset", "Lägg i ny underkategori"),
								new_subset_name: i18n.t("categories.form.products.modals.subset.new_subset_name", "Namn på ny underkategori"),
								add_to_existing_subset: i18n.t("categories.form.products.modals.subset.add_to_existing_subset", "Lägg i befintlig underkategori"),
								which_subset_to_add_product_to: i18n.t(
									"categories.form.products.modals.subset.which_subset_to_add_product_to",
									"Vilken underkategori vill du lägga produkterna i?"
								),
							},
						},
					},
					mappings: {
						card_title: i18n.t("categories.form.mappings", "Kopplingar"),
						empty_message: i18n.t("categories.form.mappings", "Inga säljkanaler är tillagda"),
						mapped_to: i18n.t("categories.form.mappings", "Ansluten till"),
						not_mapped: i18n.t("categories.form.mappings", "Ej ansluten till kategori"),
						alerts: {
							created_category_on_channel: i18n.t("categories.form.mappings.alerts.created_category_on_channel", "Skapade ny kategori på kanal"),
						},
						modal: {
							"title_%channel%": i18n.t("categories.form.mappings.modal.title_channel", "Anslutning till {{channel}}", {
								channel: "%channel%",
							}),
							placeholder: i18n.t("categories.form.mappings.modal.placeholder", "-- let vänlig välj kategorier --"),
							no_categories_found: i18n.t("categories.form.mappings.modal.no_categories_found", "inga kategorier hittades"),
							new: {
								title: i18n.t("categories.form.mappings.modal.new.title", "Kategorinamn"),
							},
						},
					},
				},
				types: {
					subset: i18n.t("categories.types.subset", "Underkategori"),
					have_subsets: i18n.t("categories.types.have_subsets", "har underkategorier"),
					custom: i18n.t("categories.types.custom", "Manuell"),
					custom_description: i18n.t("categories.types.custom_description", "Lägg till utvalda produkter till kategorin en och en"),
					smart: i18n.t("categories.types.smart", "Automatisk"),
					smart_description: i18n.t(
						"categories.types.smart_description",
						"Befintliga och framtida produkter som matchar reglerna du sätter kommer automatiskt att läggas till i kategorin"
					),
				},
				filters: {
					availability: i18n.t("categories.filters.availability", "Tillgänglighet"),
					"available_on_%channel%": i18n.t("categories.filters.available_on_channel", "Finns på {{%channel%}}", {
						channel: "%channel%",
					}),
					"unavailable_on_%channel%": i18n.t("categories.filters.unavailable_on_channel", "Finns ej på %channel%", {
						channel: "%channel%",
					}),
					category_type: i18n.t("categories.filters.category_type", "Typ"),
				},
			},
			modals: {
				remove: {
					"title_remove_singular_%type%": i18n.t("modals.remove.title_remove_singular_type", "Ta bort {{type}}?", {
						type: "%type%",
					}),

					"prompt_remove_singular_%type%": i18n.t(
						"modals.remove.prompt_remove_singular_type",
						"Är du säker på att du vill ta bort denna '{{type}}'? Detta går ej att ångra.",
						{
							type: "%type%",
						}
					),

					"prompt_remove_singular_%object%": i18n.t(
						"modals.remove.prompt_remove_singular_objec",
						"Är du säker på att du vill ta bort '{{object}}'? Detta går ej att ångra.",
						{
							object: "%object%",
						}
					),

					"title_remove_plural_%types%": i18n.t("modals.remove.title_remove_plural_types", "Ta bort {{types}}?", {
						types: "%types%",
					}),

					"prompt_remove_%num%_%types%": i18n.t(
						"modals.remove.prompt_remove_num_types",
						"Är du säker på att du vill ta bort '{{num}} {{types}}'? Detta går ej att ångra.",
						{
							types: "%types%",
							num: "%num%",
						}
					),
				},
				tags: {
					"title_add_tags_to_%num%_%type%": i18n.t("modals.tags.title_add_tags_to_num_type", "Lägg till taggar på {{num}} {{type}}", {
						num: "%num%",
						type: "%type%",
					}),
					"title_remove_tags_from_%num%_%type%": i18n.t("modals.tags.title_remove_tags_from_num_type", "Ta bort taggar från {{num}} {{type}}", {
						type: "%type%",
						num: "%num%",
					}),
					tags: i18n.t("modals.tags.tags", "Taggar"),
					buttons: {
						add_tags: i18n.t("modals.tags.buttons.add_tags", "Lägg till taggar"),
						remove_tags: i18n.t("modals.tags.buttons.remove_tags", "Ta bort taggar"),
					},
				},
			},
			projects: {
				singular: i18n.t("projects.singular", "Projekt"),
				plural: i18n.t("projects.plural", "Projekt"),
			},
		},
	};
};
export default getTranslations;
