import React, { Component } from "react";
import { Stack, Button } from "@shopify/polaris";
import { FixedSizeList, areEqual } from "react-window";
import axios from "axios";
import { CircleRightMajor } from "@shopify/polaris-icons";
import API from "../../../API";
import RowSnippet from "./RowSnippet";
import { store } from "../../../store";
import { toastr } from "../../../components/toastr.js";
import RowModal from "./RowModal";
import BoardHelper from "../BoardHelper";

class Miniboard extends Component {
	constructor(props) {
		super(props);
		this.Row = React.memo(this.renderRow.bind(this), areEqual);
		this.state = {
			query: "",
			myrows: [],
			rows: [],
			groups: [],
			offset: 0,
			limit: 1000,
		};
		this.requests = [];
	}

	componentDidMount() {
		if (this.props.board && this.props.board.columns) {
			// Sometimes we have a light version of a board
			this.setState({ board: this.props.board });
		} else {
			this.fetchBoard();
		}
		if (this.props.rows && this.props.rows.length) {
			this.setState({ rows: this.props.rows }, this.updateGroupRows.bind(this));
		} else {
			this.fetchRows();
		}
		if (this.props.groups && this.props.groups.length) {
			this.setState({ groups: this.props.groups }, this.updateGroupRows.bind(this));
		} else {
			this.fetchGroups();
		}
	}

	createCancelToken(request, c) {
		request.cancel = c;
	}

	chechValidlength(arr) {
		return arr && (Array.isArray(arr) ? arr.length : typeof arr === "object" && Object.keys(arr).length);
	}

	filterDuplicates(ar) {
		return ar.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ((this.chechValidlength(props.rows) || this.chechValidlength(props.groups)) && props.board && props.board.id) {
			this.setState((c) => {
				const rows = this.filterDuplicates(
					[]
						.concat(Object.values(props.rows), c.rows)
						.filter((r) => r && !r.removed && !r.archived && parseInt(r.board_id) === parseInt(props.board.id))
				);
				const groups = this.filterDuplicates(
					[]
						.concat(props.groups ? Object.values(props.groups) : [], c.groups)
						.filter((r) => r && !r.removed && !r.archived && parseInt(r.board_id) === parseInt(props.board.id))
				);
				const row = (c.row && rows.find((r) => parseInt(r.id) === parseInt(c.row.id))) || c.row;

				return { rows, groups, row };
			}, this.updateGroupRows.bind(this));
		}
		if (props.query != this.state.query) {
			this.setState({ query: props.query }, this.updateGroupRows.bind(this));
		}
	}

	fetchBoard() {
		API.get("/api/boards/" + this.props.board_id + ".json", { params: {} })
			.then((result) => {
				store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				this.setState({ board: result.data.board });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchRows() {
		this.setState({ loadingRows: true });
		const propsParams = this.props.params || {};
		const params = Object.assign(this.props.params || {}, {});
		if (this.props.contact_id) {
			params.contact_id = this.props.contact_id;
			params.also_archived = 1;
		}
		if (propsParams && "also_archived" in propsParams) {
			params.also_archived = propsParams.also_archived;
		}
		params.offset = this.state.offset;
		params.limit = this.state.limit;

		API.get("/api/boards/" + this.props.board_id + "/rows.json", { params })
			.then((result) => {
				if (result.data.count <= params.limit) {
					this.setState({ loadingRows: false, rows: result.data.rows }, this.updateGroupRows.bind(this));
				} else {
					for (let i = 1; i < Math.ceil(result.data.count / this.state.limit); i++) {
						this.state.offset += this.state.limit;
						this.state.rows = result.data.rows;
						this.requests.push({
							offset: this.state.offset,
							limit: this.state.limit,
							complete: false,
						});
						this.fetchRowRequest(this.requests[i - 1]);
					}
				}
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingRows: false });
			});
	}

	componentWillUnmount() {
		this.cancelRequests();
	}

	cancelRequests() {
		if (this.requests) {
			for (let i = 0; i < this.requests.length; i++) {
				if (!this.requests[i].complete) {
					this.requests[i].cancel();
				}
			}
		}
	}

	fetchRowRequest(request) {
		const params = Object.assign({}, {});
		if (this.props.contact_id) {
			params.contact_id = this.props.contact_id;
			params.also_archived = 1;
		}
		params.offset = request.offset;
		params.limit = request.limit;
		request.cancelToken = new axios.CancelToken(this.createCancelToken.bind(this, request));
		API.get("/api/boards/" + this.props.board_id + "/rows.json", { params })
			.then(this.handleResponse.bind(this, request))
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingRows: false });
			});
	}

	handleResponse(request, result) {
		request.complete = true;
		for (let i = 0; i < result.data.rows.length; i++) {
			this.state.rows.push(result.data.rows[i]);
		}
		for (let i = 0; i < this.requests.length; i++) {
			if (!this.requests[i].complete) {
				return;
			}
		}
		this.setState({ loadingRows: false, rows: this.state.rows }, this.updateGroupRows.bind(this));
	}

	fetchGroups() {
		this.setState({ loadingGroups: true });
		API.get("/api/boards/" + this.props.board_id + "/groups.json", {
			params: {},
		})
			.then((result) => {
				this.setState({ loadingGroups: false, groups: result.data.groups }, this.updateGroupRows.bind(this));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingGroups: false });
			});
	}

	renderRow(props) {
		const { data: items, index, style } = props;
		const item = items[index];

		const key =
			item.type == "quickadd" || item.type == "summary" || item.type == "blank" ? item.type + item.group_id : (item.color ? "g" : "") + item.id;

		const obj = this.renderItem(item, index, style, key, this.props.rowRightButton);
		return obj;
	}

	getColumn(id) {
		if (!this.state.board) return;
		if (this.state.board.columns) return this.state.board.columns.find((c) => c.id == id);
	}

	clickRow(row, onTitle) {
		if (this.state.board) {
			Object.values(row.column_values).forEach((col) => {
				if (this.getColumn(col.column_id) && ["contact", "rowlink", "ticket"].includes(this.getColumn(col.column_id).type)) {
					BoardHelper.fetchResources(this.getColumn(col.column_id).type, col.value);
				}
			});
		}
		if (this.props.onClickRow) {
			this.props.onClickRow(row, onTitle);
			return;
		}
		// Open row modal
		this.setState({ showRowModal: true, row });
	}

	onCreateRow(row) {
		this.state.rows.push(row);
		this.setState({ rows: this.state.rows }, this.updateGroupRows.bind(this));
		if (this.props.onCreateRow) {
			this.props.onCreateRow(row);
		} else {
			BoardHelper.updateRows([row], "add");
		}
	}

	duplicateToGroup(group) {
		this.setState({ saving: true });
		API.post(
			"/api/boards/" + this.props.row.board_id + "/rows/" + this.props.row.id + "/move.json",
			{
				group,
				row_link_column_id: this.props.column ? this.props.column.id : null,
				dupe: true,
				force: true,
			},
			{ params: {} }
		)
			.then((result) => {
				if (result.data.unmapped) {
					this.setState({
						saving: false,
						// failed_move_group: { id: result.data.group_id },
						// missing_columns: result.data.unmapped,
						// failed_move_board: result.data.board,
					});
					return;
				}
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.state.rows.push(result.data.row);
				this.setState({ saving: false, rows: this.state.rows }, () => {
					this.updateGroupRows();
					this.onCreateRow(result.data.row);
				});
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	renderItem(item, index, style, key, rowRightButton) {
		if (item.type == "blank") {
			return <div key={key} className="board-item" data-index={index} style={style} />;
		} else if (item.color) {
			return (
				<div key={key} className="board-item" style={style}>
					<Stack wrap={false}>
						<Stack.Item fill>
							<div
								style={{
									color: item.color,
									fontSize: 14,
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								{item.title}
							</div>
						</Stack.Item>
						{item.badge ? item.badge : null}
						{this.props.row ? (
							<div style={{ marginTop: 10, marginRight: "1rem" }}>
								<Button plain loading={this.state.saving} icon={CircleRightMajor} onClick={this.duplicateToGroup.bind(this, item)}>
									Duplicera rad
								</Button>
							</div>
						) : null}
					</Stack>
				</div>
			);
		} else if (item.type == "quickadd") {
			return (
				<div key={key} className="board-item" style={style}>
					<RowSnippet
						openModal={this.props.handleOpenModal}
						onCreateRow={this.onCreateRow.bind(this)}
						quickadd
						group={{ id: item.group_id, color: item.group_color }}
						row={item}
					/>
				</div>
			);
		} else {
			let selected = null;
			if (this.props.selection) {
				selected = false;
				if (this.props.selection.indexOf(item.id) >= 0) {
					selected = true;
				}
			}
			return (
				<div key={key} className="board-item have-selection" style={style}>
					<RowSnippet
						selected={selected}
						onClick={this.clickRow.bind(this)}
						group={{ id: item.group_id, color: item.group_color }}
						row={item}
						rowRightButton={rowRightButton}
					/>
				</div>
			);
		}
	}

	updateGroupRows() {
		const allRows = [];
		const selection = [];
		const groups = this.state.groups.sort((a, b) => a.position - b.position);
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i];
			const rows = this.state.rows
				.filter((row) => {
					if (row.group_id == group.id) {
						if (this.state.query) {
							const filterRegex = new RegExp(this.state.query, "i");
							if (row.title.match(filterRegex)) {
								return true;
							}
							if (row.board_number && row.board_number.toString().match(filterRegex)) {
								return true;
							}
							if (group.title.match(filterRegex)) {
								return true;
							}
							return false;
						}
						return true;
					}
					return false;
				})
				.sort((a, b) => a.position - b.position);

			if (!this.props.contact_id || rows.length > 0) {
				group.count = rows.length;
				allRows.push(group);

				group.rows = [];
				group.selection = [];
				let lastPosition = 0;
				for (let s = 0; s < rows.length; s++) {
					group.rows.push(rows[s].id); // For the select
					if (rows[s].selected) {
						group.selection.push(rows[s].id);
						selection.push(rows[s].id);
					}
					rows[s].group_color = group.color;
					lastPosition = rows[s].position + 1;
					allRows.push(rows[s]);
				}
				if (!this.props.skipadd) {
					allRows.push({
						type: "quickadd",
						position: lastPosition,
						board_id: this.props.board_id,
						group_id: group.id,
						group_color: group.color,
					});
				}
				allRows.push({
					type: "blank",
					board_id: this.props.board_id,
					group_id: group.id,
					group_color: group.color,
				});
			}
		}
		// eslint-disable-next-line react/no-unused-state
		this.setState({ myrows: allRows, selection });
	}

	render() {
		const selectedRows =
			(this.props.showSelecteFirst && this.state.myrows && this.state.myrows.filter((r) => this.props.selection.includes(r.id))) || [];
		const myRows = this.props.showSelecteFirst
			? this.state.myrows && this.state.myrows.filter((r) => !this.props.selection.includes(r.id))
			: this.state.myrows;

		return (
			<div className="miniboard">
				{selectedRows && !!selectedRows.length && (
					<div key="selected">
						<FixedSizeList
							// height={selectedRows.length * 36 + 1}
							height={Math.min(this.props.height ? this.props.height : selectedRows.length * 36, selectedRows.length * (36 + 2))}
							ref={(r) => {
								// eslint-disable-next-line react/no-unused-class-component-methods
								this.list2 = r;
							}}
							itemCount={selectedRows.length}
							itemSize={36}
							itemData={selectedRows}
							overscanCount={10}
						>
							{this.Row}
						</FixedSizeList>
					</div>
				)}
				{myRows && !!myRows.length && (
					<div key="noSelected">
						<FixedSizeList
							height={this.props.height ? this.props.height : myRows.length * 36}
							// height={myRows.length * 36 + 1}
							ref={(r) => {
								// eslint-disable-next-line react/no-unused-class-component-methods
								this.list = r;
							}}
							itemCount={myRows && myRows.length}
							itemSize={36}
							itemData={myRows}
							overscanCount={10}
						>
							{this.Row}
						</FixedSizeList>
					</div>
				)}
				{(!this.state.myrows || !this.state.myrows.length) && !this.state.loadingRows && !this.state.loadingGroups && (
					<div className="board-empty_screen" style={{ position: "relative", margin: "1rem 0" }}>
						<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
						<p>Ingen {this.props.handle || (this.props.board && this.props.board.singular.toLowerCase())} hittades</p>
					</div>
				)}
				{(this.state.loadingRows || this.state.loadingGroups) && (!this.state.myrows || !this.state.myrows.length) && (
					<div style={{ padding: 10 }}>
						{[...Array(1)].map((i, index) => (
							<RowSnippet
								key={index + ""}
								row={{
									title: <div className="loading">Laddar</div>,
								}}
								group={{ color: "rgb(51, 57, 60)" }}
								borderColor="rgb(51,57,68)"
								style={{ marginBottom: 0, PointerEvent: "none" }}
							/>
						))}
					</div>
				)}
				{this.state.board && this.state.row ? (
					<RowModal
						open={this.state.showRowModal}
						row={this.state.row}
						board={this.state.board}
						groups={this.state.groups}
						onUpdateRow={() => {}}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ showRowModal: false, row: null });
						}}
					/>
				) : null}
			</div>
		);
	}
}
export default Miniboard;
