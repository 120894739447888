/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useImperativeHandle } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import { Button, Icon, Tooltip } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor, CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import { store } from "src/js/store";
import Badge from "src/js/components/Badge";
import moment from "moment";
import { AgendaTasksHeader, AgendaTasksSections, AgendaTasksWrapper } from "./StyledComponents";
import TaskSection from "./TaskSection";
import { WeeklyTasksContext } from "./WeeklyTasksContextProvider";
import WeeklyTasksBulkActions from "./WeeklyTasksBulkActions";
import WeeklyTasksExcludeBoards from "./WeeklyTasksExcludeBoards";
import LateIncompletedTasksSheet from "./LateIncompletedTasksSheet";

type WeekklTasksProps = WithTranslation;

const WeeklyTasks = React.forwardRef(({ t }: WeekklTasksProps, ref) => {
	const { currentWeek, week, year, next, prev, refresh, onOpen, totalLateTasksCount, setSelection, selection } = useContext(WeeklyTasksContext);
	const { lateRows, todayRows, upcomingRows, completedRows } = currentWeek || {};

	useImperativeHandle(ref, () => ({
		refresh,
	}));

	return (
		<AgendaTasksWrapper>
			<AgendaTasksHeader>
				<h1 style={{ display: "flex", gap: "1rem" }}>
					{t("dashboard.todo.title", "Att göra")}
					{totalLateTasksCount ? (
						<LateIncompletedTasksSheet
							count={totalLateTasksCount}
							refresh={refresh}
							activator={
								<button className="plain">
									<Badge fontSize={14} color={Colors.red}>
										{totalLateTasksCount}
									</Badge>
								</button>
							}
						/>
					) : (
						<div style={{ width: 20 }} />
					)}
					<WeeklyTasksExcludeBoards onSuccess={refresh} />
				</h1>
				<div style={{ display: "flex", alignItems: "center", gap: "0.5rem", background: "var(--main3)", borderRadius: "50px" }}>
					<Tooltip content={t("commmon.week.previous", "Föregående Vecka")}>
						<Button onClick={prev} icon={ChevronLeftMinor} />
					</Tooltip>
					<Tooltip
						content={`${moment().isoWeek(week).set("year", year).startOf("week").format("YYYY-MM-DD")} - ${moment()
							.isoWeek(week)
							.set("year", year)
							.endOf("week")
							.format("YYYY-MM-DD")}`}
					>
						<p>
							{t("dashboard.todo.week.title", "Vecka {{week}} ({{year}})", {
								week,
								year,
							})}
						</p>
					</Tooltip>
					<Tooltip content={t("commmon.week.next", "Nästa Vecka")}>
						<Button onClick={next} icon={ChevronRightMinor} />
					</Tooltip>
				</div>

				<div className="rightSection">
					<WeeklyTasksBulkActions rows={currentWeek.rows} selection={selection} setSelection={setSelection} refresh={refresh} />

					{store.getState().user.todo_board && (
						<Button onClick={() => onOpen()}>
							{
								(
									<div className="button_inner">
										<span>{t("dashboard.todo.actions.new", "Ny Uppgift") as string}</span>
										<Icon source={CirclePlusOutlineMinor} />
									</div>
								) as unknown as string
							}
						</Button>
					)}
				</div>
			</AgendaTasksHeader>
			<AgendaTasksSections>
				<TaskSection
					open
					title={t("dashboard.todo.terms.today", "Idag")}
					icon={<img alt="" src="/assets/svg/bulb.svg" />}
					color={Colors.yellow}
					rows={todayRows}
				/>
				<TaskSection
					title={t("dashboard.todo.terms.late", "Försenade")}
					icon={<img alt="" src="/assets/svg/alarm.svg" />}
					color={Colors.red}
					rows={lateRows}
				/>
				<TaskSection
					title={t("dashboard.todo.terms.comming", "Kommande")}
					icon={<img alt="" src="/assets/svg/chart.svg" />}
					color={Colors.blue}
					rows={upcomingRows}
				/>
				<TaskSection
					title={t("dashboard.todo.terms.completed", "Färdiga")}
					icon={<img alt="" src="/assets/svg/boxCheckmark.svg" />}
					color={Colors.green}
					rows={completedRows}
				/>
			</AgendaTasksSections>
		</AgendaTasksWrapper>
	);
});

export default withTranslation(["dashboard", "common"], { withRef: true })(WeeklyTasks);
