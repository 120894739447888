import React, { Component } from "react";
import { Card, Button, Stack } from "@shopify/polaris";
import ConfirmPopup from "src/js/components/modal/ConfirmPopup";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import Switch from "../../../components/Switch.js";

class TriggerPreview extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	updateEnabled() {
		API.put(
			"/api/boards/" + this.props.board.id + "/triggers/" + this.props.trigger.id + ".json",
			{ enabled: this.props.trigger.enabled },
			{ params: {} }
		)
			.then((result) => {})
			.catch((error) => {
				toastr.error(error);
			});
	}

	remove() {
		this.setState({ removing: true });
		API.delete("/api/boards/" + this.props.board.id + "/triggers/" + this.props.trigger.id + ".json", { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("board.triggers.responses.removed", "Trigger borttagen"));
				this.props.onRemove();
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	render() {
		return (
			<Card>
				<div style={{ position: "relative" }}>
					<Card.Section>
						<Stack>
							<Switch
								disabled={this.props.disabled}
								onChange={() => {
									this.props.trigger.enabled = !this.props.trigger.enabled;
									this.updateEnabled();
									this.props.onChange(this.props.trigger);
								}}
								checked={!!this.props.trigger.enabled}
							/>

							<div
								style={{ cursor: "pointer", paddingRight: "20px" }}
								onClick={(event) => {
									event.stopPropagation();
									this.props.onClick();
								}}
							>
								{this.props.trigger.title}
							</div>
						</Stack>

						<div style={{ position: "absolute", right: "2rem", top: "2rem" }}>
							<ConfirmPopup
								activator={<Button destructive icon={MobileCancelMajor} plain loading={this.state.removing} />}
								primaryButton={
									<Button
										destructive
										loading={this.state.removing}
										onClick={(event) => {
											event.stopPropagation();
											this.remove();
										}}
									>
										{this.props.t("common.actions.remove", "Ta bort")}
									</Button>
								}
							/>
						</div>
					</Card.Section>
				</div>
			</Card>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(TriggerPreview);
