import { Card, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import Skeleton2Col from "src/js/components/skeleton_2col";
import moment from "moment";
import FortnoxContractContext from "./FortnoxContractContext";
import FortnoxContractRows from "./FortnoxContractRows";
import FortnoxContractInfo from "./FortnoxContractInfo";
import { InfoWrapper } from "../styles";

const FortnoxContractInfoView = ({ history, t }) => {
	const { form, isFetching, setForm, isSaving } = useContext(FortnoxContractContext);
	const disabled = form?.Status === "FINISHED";

	const getChangeHandler = (field: FortnoxContractTypeMutableKeys) => (value: any) => {
		setForm((c: Partial<FortnoxContractType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			if (field === "InvoiceInterval" && moment(newForm?.PeriodStart).isValid()) {
				newForm.PeriodEnd = moment(newForm.PeriodStart).add(value, "months").subtract(1, "days").format("YYYY-MM-DD");
			}

			if (field === "PeriodStart" && moment(newForm?.PeriodStart).isValid()) {
				if (newForm?.InvoiceInterval) {
					newForm.PeriodEnd = moment(newForm.PeriodStart).add(newForm.InvoiceInterval, "months").subtract(1, "days").format("YYYY-MM-DD");
				} else {
					const diff = moment(newForm.PeriodEnd).diff(moment(newForm.PeriodStart), "months");

					newForm.PeriodEnd = moment(newForm.PeriodStart).add(diff, "months").subtract(1, "days").format("YYYY-MM-DD");
				}
			}

			if (field === "Continuous" && newForm.Continuous) {
				newForm.ContractLength = 0;
			}

			return newForm;
		});
	};

	return (
		<InfoWrapper>
			<Card sectioned>
				{!form || isFetching ? (
					<Skeleton2Col />
				) : (
					<Layout>
						<Layout.Section>
							<FortnoxContractInfo isSaving={isSaving} contract={form} onChange={getChangeHandler} disabled={disabled} />
						</Layout.Section>

						<Layout.Section>
							<div style={{ marginTop: "5rem" }}>
								<FortnoxContractRows isSaving={isSaving} contract={form} onChange={getChangeHandler} disabled={disabled} />
							</div>
						</Layout.Section>
					</Layout>
				)}
			</Card>
		</InfoWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxContractInfoView);
