import React, { Component } from "react";
import { Spinner, TextField, ActionList } from "@shopify/polaris";
import { MobileBackArrowMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";

class BoardSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			boards: [],
			groups: [],
			input: "",
			selectedBoard: this.props.sameBoard ? this.props.board : null,
		};
	}

	componentDidMount() {
		if (!this.state.selectedBoard) {
			this.fetchBoards();
		} else {
			this.fetchGroups();
		}
	}

	fetchBoards() {
		this.setState({ loading: true });
		const params = Object.assign({}, {});
		if (this.props.withContact) {
			params.have_column_type = "contact";
		}
		API.get("/api" + (this.props.board ? "/boards/" + this.props.board.id : "") + "/boards.json", { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ boards: result.data.boards });
				this.setState({ loading: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	fetchGroups() {
		this.setState({ loading: true });
		API.get("/api/boards/" + this.props.board.id + "/boards/" + this.state.selectedBoard.id + "/groups.json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ groups: result.data.groups });
				this.setState({ loading: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	updateInput(value) {
		this.setState({ input: value });
	}

	getOptions() {
		const filterRegex = new RegExp(this.state.input, "i");

		let opts = [];
		if (this.state.selectedBoard) {
			opts = this.state.groups;
		} else {
			if (this.props.includeSelf) {
				opts = this.state.boards;
			} else {
				opts = this.state.boards.filter((board) => !this.props.board || board.id != this.props.board.id);
			}
		}

		const options = opts
			.filter((board) => board.title.match(filterRegex))
			.map((board) => ({
				onAction: this.select.bind(this, board),
				content: board.title,
			}));

		if (this.state.selectedBoard && !this.props.sameBoard) {
			options.unshift({
				onAction: () => {
					this.setState({ selectedBoard: null, input: "" });
				},
				icon: MobileBackArrowMajor,
				content: this.props.t("board.terms.back", "Tillbaka"),
			});
		}

		return options;
	}

	select(value) {
		if (!this.state.selectedBoard && this.props.requireGroup) {
			this.state.selectedBoard = value;
			this.setState({ selectedBoard: value, input: "" });
			this.fetchGroups();
		} else if (this.state.selectedBoard) {
			this.props.onSelect(value);
		} else {
			this.props.onSelect(value);
		}
	}

	render() {
		return (
			<div
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<div style={{ padding: 5 }}>
					<TextField
						autoComplete="off"
						type="search"
						autoFocus
						onChange={this.updateInput.bind(this)}
						value={this.state.input}
						placeholder={this.props.t("board.terms.search_here", "Sök board…")}
					/>
				</div>
				{this.state.loading ? (
					<div style={{ padding: 15 }}>
						<Spinner size="small" />
					</div>
				) : (
					<ActionList items={this.getOptions()} />
				)}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardSelector);
