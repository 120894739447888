import { Card, Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import TextField from "src/js/components/TextField";
import CustomfieldContext from "./context";
import { withTranslation } from "react-i18next";
import { InfoWrapper } from "./styles";
import Select from "src/js/components/select/Select";
import Options from "./Options";
import typeOptions from "./Utilities/typeOptions";

const Info = ({ history, t }) => {
	const { form, setForm, isSaving, isFetching } = useContext(CustomfieldContext);

	const getChangeHandler = (field: CustomFieldTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<CustomFieldType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			if (field === "type") {
				newForm.options = null;
				newForm.default_value = "";
			}

			return newForm;
		});
	};

	const disabled = isSaving || isFetching;

	return (
		<InfoWrapper>
			<Card sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled id="type" label={t("fortnox.fields.disabled.label2", "Status")}>
								<Checkbox
									checked={!form.disabled}
									disabled={disabled}
									label={t("fortnox.fields.disabled.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>
							<TextField
								disabled={disabled}
								label={t("customfields.fields.title.label", "Titel")}
								value={form.title}
								onChange={getChangeHandler("title")}
							/>
							<TextField
								disabled={disabled}
								label={t("customfields.fields.description.label", "Beskrivning")}
								value={form.description}
								onChange={getChangeHandler("description")}
								multiline={2}
							/>

							<TextField
								disabled={disabled}
								label={t("customfields.fields.position.label", "Position")}
								value={form.position}
								onChange={getChangeHandler("position")}
								type="number"
							/>

							<Labelled id="type" label={t("customfields.fields.can_not_edit_when_placing_order.label", "Kan ej ändras vid orderläggning")}>
								<Checkbox
									checked={form.can_not_edit_when_placing_order}
									disabled={disabled}
									label={
										t("customfields.fields.can_not_edit_when_placing_order.label2", "låst")
										// t("fortnox.fields.can_not_edit_when_placing_order.value", "Detta fält kan {{value}} ändras när ordern är lagd", {
										// 	value: !form.can_not_edit_when_placing_order
										// 		? t("fortnox.fields.can_not_edit_when_placing_order.true", "")
										// 		: t("fortnox.fields.can_not_edit_when_placing_order.false", "INTE"),
										// }) as string
									}
									onChange={getChangeHandler("can_not_edit_when_placing_order")}
								/>
							</Labelled>
							<Labelled id="type" label={t("customfields.fields.show_when_editing_article.label", "Visa i artikelvyn")}>
								<Checkbox
									checked={form.show_when_editing_article}
									disabled={disabled}
									label={
										t("customfields.fields.show_when_editing_article.label2", "Visa i artikelvyn")
										// t("fortnox.fields.show_when_editing_article.value", "Detta fält kan {{value}} ändras när ordern är lagd", {
										// 	value: !form.show_when_editing_article
										// 		? t("fortnox.fields.show_when_editing_article.true", "")
										// 		: t("fortnox.fields.show_when_editing_article.false", "INTE"),
										// }) as string
									}
									onChange={getChangeHandler("show_when_editing_article")}
								/>
							</Labelled>
							<Labelled id="type" label={t("customfields.fields.show_when_placing_order.label", "Visa vid orderläggning")}>
								<Checkbox
									checked={form.show_when_placing_order}
									disabled={disabled}
									label={
										t("customfields.fields.show_when_placing_order.label2", "Visa vid orderläggning")
										// t("fortnox.fields.show_when_placing_order.value", "Detta fält kan {{value}} ändras när ordern är lagd", {
										// 	value: !form.show_when_placing_order
										// 		? t("fortnox.fields.show_when_placing_order.true", "")
										// 		: t("fortnox.fields.show_when_placing_order.false", "INTE"),
										// }) as string
									}
									onChange={getChangeHandler("show_when_placing_order")}
								/>
							</Labelled>
						</FormLayout>
					</Layout.Section>
					<Layout.Section oneHalf>
						<FormLayout>
							<Select
								disabled={disabled}
								label={t("customfields.fields.type.label", "Typ")}
								value={form.type}
								onChange={getChangeHandler("type")}
								options={typeOptions}
							/>
							{form.type === "dropdown" ? (
								<Select
									disabled={disabled}
									label={t("customfields.fields.default_value.label", "Standardvärde")}
									value={form.default_value}
									onChange={getChangeHandler("default_value")}
									options={[
										{
											label: t("customfields.fields.default_value.options.select", "Välj"),
											value: "",
										},
										...(form.options || []),
									]}
								/>
							) : (
								<TextField
									disabled={disabled}
									label={t("customfields.fields.default_value.label", "Standardvärde")}
									value={form.default_value}
									onChange={getChangeHandler("default_value")}
									type={form.type === "date" ? "date" : undefined}
								/>
							)}
							{form.type === "dropdown" && <Options options={form.options} onChange={getChangeHandler("options")} disabled={disabled} />}
						</FormLayout>
					</Layout.Section>
				</Layout>
			</Card>
		</InfoWrapper>
	);
};
export default withTranslation(["customfields", "common"])(Info);
