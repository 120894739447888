import { ActionList, Icon, Popover } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";

import React, { Component } from "react";

class CalendarButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick() {
		if (this.props.onClick) this.props.onClick();
		if (this.props.items && this.props.items.length) {
			this.setState((c) => ({ open: !c.open }));
		}
	}

	render() {
		const { icon, label, items, className } = this.props;
		return (
			<Popover
				active={this.state.open}
				activator={
					<div
						onClick={this.onClick.bind(this)}
						className="calendar_modal__reminder_button"
						style={Object.assign({ display: "flex", gap: "1rem" }, this.props.style || {})}
					>
						<button className={className}>
							{!icon || React.isValidElement(icon) ? icon : <Icon source={icon} />}
							{label}
							{items && items.length && (
								<span className="disclosure">
									<Icon source={ChevronDownMinor} />
								</span>
							)}
						</button>
					</div>
				}
				onClose={() => this.setState({ open: false })}
			>
				<ActionList items={items} onActionAnyItem={() => this.setState({ open: false })} />
			</Popover>
		);
	}
}

export default CalendarButton;
