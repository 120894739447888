import React, { useMemo, useState } from "react";
import { Button as PolarisButton } from "@shopify/polaris";
import Modal from "../modal";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Button = withTranslation(["common"])(
	withRouter(({ children, connectedDisclosure: propsConnectedDisclosure, history, t, ...props }: any) => {
		const [item, setItem] = useState<any>(null);
		const [isLoading, setIsLoading] = useState(false);

		const connectedDisclosure = useMemo(() => {
			if (!propsConnectedDisclosure) return undefined;

			return {
				...propsConnectedDisclosure,
				actions: propsConnectedDisclosure?.actions
					?.filter((item) => !("enabled" in item) || item.enabled)
					?.map((item) => ({
						...item,
						onAction: () => {
							if (item.confirmation) {
								setItem(item);
								return;
							}

							item.onAction();
						},
					})),
			};
		}, [propsConnectedDisclosure]);

		const handleAction = async () => {
			setIsLoading(true);

			try {
				if (item.url) {
					if (item.url?.startsWith("http") || item.url?.startsWith("www")) {
						window.open(item.url, "_blank");
					} else {
						history.push(item.url);
					}
				} else {
					await item.onAction?.();
				}
			} catch (error) {
				console.error("error:", error);
			}

			setIsLoading(false);
			setItem(null);
		};

		const confirm = {
			showButtons: true,
			sectioned: true,
			...(item?.confirmation || item?.confirm || {}),
		};
		const text = confirm?.content === undefined ? `${item?.content}?` : confirm?.content || confirm?.text || confirm?.message;

		return (
			<>
				<PolarisButton {...props} connectedDisclosure={connectedDisclosure}>
					{children}
				</PolarisButton>

				<Modal
					open={!!item}
					onClose={() => setItem(null)}
					title={confirm?.title || item?.content}
					primaryAction={
						!confirm?.showButtons
							? undefined
							: {
									...item,
									content: confirm?.confirm || item?.content,
									disabled: confirm?.disabled,
									onAction: handleAction,
									loading: item?.loading || isLoading,
							  }
					}
					secondaryActions={[
						{
							content: confirm?.cancel || t("common.actions.cancel", "Avbryt"),
							onAction: () => setItem(null),
						},
					]}
					sectioned={confirm?.sectioned}
				>
					{text}
				</Modal>
			</>
		);
	})
);
export default Button;
