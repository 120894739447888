/* eslint-disable quotes */
import React, { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { Button, type ComplexAction } from "@shopify/polaris";
import { WithTranslation, withTranslation } from "react-i18next";
import { store } from "../../store";
import SheetHeader from "../../StyledComponents/SheetHeader";
import Banner from "../banner";
import useMountTransition from "../../hooks/useMountTransition";
import { getPortalsMaxZIndex } from "../../Utilities";
import ConfirmPopup from "../modal/ConfirmPopup";
import SheetWidthResizer from "./SheetWidthResizer";
import useSheetSettings from "./useSheetSettings";

const ANIMATION_IN = 100;
const ANIMATION_OUT = 250;
const DEFAULT_ZINDEX = 519;

interface SecondaryAction extends ComplexAction {
	confirm?: boolean;
}

interface SheetProps extends WithTranslation {
	open: boolean;
	noTransition?: boolean;
	onClose: (e?: EventSource) => void;
	title: string | React.ReactNode;
	hideHeader?: boolean;
	status?: string;
	children?: any;
	scrollable?: boolean;
	primaryAction?: ComplexAction;
	secondaryActions?: SecondaryAction[];
}

const Sheet = ({
	children,
	onClose,
	open,
	title,
	hideHeader,
	status,
	noTransition,
	scrollable = true,
	primaryAction,
	secondaryActions,
	t,
	...rest
}: SheetProps): JSX.Element | null => {
	const hasTransitionedIn = useMountTransition(open, ANIMATION_OUT);
	const ref = useRef<any>();
	const id = useRef<number | undefined>();
	const zIndex = useRef<number | undefined>(DEFAULT_ZINDEX);
	const headerRef = useRef<any>();
	const footerRef = useRef<any>();

	const {
		sheetSettings: { isPinned },
		updateSheetSettingsKey,
	} = useSheetSettings();

	const toggleIsPinned = () => {
		updateSheetSettingsKey("isPinned", !isPinned);
	};

	const handleOnClose = useCallback(
		(e: any = null) => {
			e?.stopPropagation();
			if ([2, 419, 418].includes(store.getState().user.id)) {
				onClose(e);
			}
		},
		[onClose]
	);

	const onKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Escape") handleOnClose(e);
		},
		[handleOnClose]
	);

	const getElementIndex = () => {
		if (!ref.current) return 0;
		return Math.max(Array.from(document.querySelector("#PolarisPortalsContainer")?.children || []).indexOf(ref.current), 0);
	};

	useEffect(() => {
		const dataId = getElementIndex();
		id.current = dataId;
	}, []);

	useEffect(() => {
		if (open) {
			zIndex.current = Math.max(DEFAULT_ZINDEX, getPortalsMaxZIndex()) + 2;
		}
	}, [open]);

	const show = open || hasTransitionedIn;
	const container: Element | null = document.querySelector("#PolarisPortalsContainer") || document.querySelector("body");

	if (!container) return null;

	const getHeaderHeight = () => headerRef.current?.clientHeight || 0;
	const getFooterHeight = () => footerRef.current?.clientHeight || 0;

	const footerEle = (primaryAction || secondaryActions?.length) && (
		<Footer ref={footerRef}>
			{primaryAction && (
				<Button
					primary={!primaryAction.destructive}
					onClick={primaryAction.onAction}
					icon={primaryAction.icon}
					loading={primaryAction.loading}
					disabled={primaryAction.disabled}
					destructive={primaryAction.destructive}
				>
					{primaryAction.content}
				</Button>
			)}

			{!!secondaryActions?.length && (
				<SecondaryActionsContainer>
					{secondaryActions?.map((secondaryAction, index) => {
						const { content, onAction, loading, disabled, destructive, icon, confirm } = secondaryAction;
						if (destructive && confirm) {
							return (
								<ConfirmPopup
									key={index}
									activator={
										<Button key={index} onClick={onAction} icon={icon} loading={loading} disabled={disabled} destructive={destructive}>
											{content}
										</Button>
									}
								/>
							);
						}

						return (
							<Button key={index} onClick={onAction} icon={icon} loading={loading} disabled={disabled} destructive={destructive}>
								{content}
							</Button>
						);
					})}
				</SecondaryActionsContainer>
			)}
		</Footer>
	);

	return createPortal(
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Wrapper data-pinned={isPinned} ref={ref} data-id={id.current} data-zindex={(open && zIndex.current) || null} {...rest}>
			{show && (
				<div>
					<InnerContainer
						data-pinned={isPinned}
						data-zindex={zIndex.current}
						onKeyDown={onKeyDown}
						data-hastransitionedin={hasTransitionedIn || noTransition}
						data-notransition={noTransition}
						data-open={open}
					>
						<InnerWrapper style={{ position: "relative" }}>
							<SheetWidthResizer />

							{!hideHeader && (
								<div ref={headerRef}>
									{status ? (
										<Banner status={status as any} title={title} onDismiss={onClose} style={{ padding: "1rem" }} />
									) : (
										<SheetHeader title={title} onClose={onClose} isPinned={isPinned} toggleIsPinned={toggleIsPinned} />
									)}
								</div>
							)}
							{scrollable ? (
								<ScrollContainer headerHeight={getHeaderHeight()} footerHeight={getFooterHeight()}>
									{children}
								</ScrollContainer>
							) : (
								children
							)}
							{footerEle}
						</InnerWrapper>
					</InnerContainer>
				</div>
			)}

			{open && !isPinned && <Backdrop data-zindex={(zIndex.current || DEFAULT_ZINDEX) - 1} onClick={handleOnClose} />}
		</Wrapper>,
		container
	);
};
export default withTranslation(["common"], { withRef: true })(Sheet);
const ScrollContainer = styled.div<{ headerHeight: number; footerHeight: number }>`
	overflow-y: auto;
	max-height: 100vh;
	height: ${({ headerHeight, footerHeight }) => `calc(100% - calc(${headerHeight}px + ${footerHeight}px))`};
`;
const Wrapper = styled.div.attrs((props) => ({ "data-portal-id": `sheet-Polarisportal${props["data-id"]}` }))``;

const InnerWrapper = styled.div.attrs({ className: "Polaris-Sheet", role: "dialog", "aria-modal": "true", tabindex: "-1" })``;

type BackdropProps = {
	onClick: () => void;
	"data-zindex"?: number | null | undefined;
};
const Backdrop = styled.div.attrs<BackdropProps>({
	className: "Polaris-Backdrop Polaris-Backdrop--transparent",
})`
	z-index: ${(props) => props["data-zindex"] || 518};
`;

type InnerContainerProps = {
	"data-hastransitionedin"?: boolean | undefined | null;
	"data-open"?: boolean;
	"data-notransition"?: boolean;
	"data-zindex"?: number | null | undefined;
};
const InnerContainer = styled.div.attrs<InnerContainerProps>(() => ({
	className: "Polaris-Sheet__Container",
	"data-polaris-layer": "true",
	"data-polaris-overlay": "true",
}))`
	transition: ${(props) => {
		if (props["data-notransition"]) return null;

		if (props["data-open"]) {
			return `transform ${ANIMATION_IN}ms, opacity ${ANIMATION_IN / 2}ms;`;
		}
		return `transform ${ANIMATION_OUT}ms, opacity ${ANIMATION_OUT * 2}ms;`;
	}};
	transform: ${(props) => (props["data-hastransitionedin"] && props["data-open"] ? "translateX(0)" : "translateX(100%)")};
	z-index: ${(props) => props["data-zindex"] || 519};
`;

const Footer = styled.div.attrs({ className: "Polaris-Sheet__Footer" })`
	display: flex;
	justify-content: space-between;
	gap: 2rem;
	padding: 2rem;
`;

const SecondaryActionsContainer = styled.div.attrs({ className: "Polaris-Sheet__SecondaryActions" })`
	display: flex;
	gap: 0.5rem;
`;
