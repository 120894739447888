import React, { Component } from "react";
import { Labelled, Stack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import ResourcePicker from "./resource_picker.js";
import { store } from "../store";
import ProfileAvatar from "./ProfileAvatar.js";

class SellerSelector extends Component {
	constructor(props) {
		super(props);
		this.state = { user: props.user };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ((props.user && !this.state.user) || (!props.user && this.state.user) || (props.user && props.user.id != this.state.user.id)) {
			this.setState({ user: props.user });
		}
	}

	onChange(user) {
		if (!user || !user.id) {
			this.setState({ user: null });
			this.props.onChange(null);
		} else {
			this.setState({ user });
			this.props.onChange(user);
		}
	}

	render() {
		// source={(((!this.props.compact) && (this.state.user)) ? this.state.user.avatar:null)}
		const caption = (
			<Stack spacing="tight" wrap={false}>
				<div style={this.state.user && !this.props.noPadding ? { paddingTop: 6 } : null}>
					<span
						style={{
							whiteSpace: "nowrap",
							textOverflow: "ellipsis",
							overflow: "hidden",
							maxWidth: "100%",
						}}
					>
						{this.state.user
							? this.props.grouponly
								? this.state.user.name
								: this.state.user.fullname
							: this.props.group
							? this.props.t("common.seller_selector.empty.caption", "Ingen grupp/användare")
							: this.props.grouponly
							? this.props.t("common.seller_selector.empty_group.caption", "Ingen grupp")
							: this.props.emptyCaption || this.props.t("common.seller_selector.empty_coworker.caption", "Ingen medarbetare")}
					</span>
					{!this.props.noMe && !this.state.user && !this.props.grouponly ? (
						<span
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<div
								className="selhovshow"
								onClick={() => {
									this.setState({ user: store.getState().user });
									this.props.onChange(store.getState().user);
								}}
							>
								{this.props.t("common.seller_selector.actions.choose_me", "välj mig")}
							</div>
						</span>
					) : null}
				</div>
			</Stack>
		);

		return (
			<Labelled label={this.props.label}>
				{this.props.disabled ? (
					caption
				) : (
					<ResourcePicker
						caption={caption}
						fixedCaption
						items={
							this.props.group
								? store.getState().groups.concat(store.getState().users)
								: this.props.grouponly
								? store.getState().groups
								: store.getState().users?.filter((user) => user.enabled)
						}
						resource={(this.props.group ? "usergroups" : this.props.grouponly ? "groups" : "users") + ".json?enabled=1"}
						fullWidth={false}
						selectSelf={!this.props.grouponly}
						item={this.state.user}
						onChange={this.onChange.bind(this)}
						plain={this.props.plain}
						renderLabel={(user) => (
							<Stack spacing="tight" wrap={false}>
								<ProfileAvatar size="small" user={user} />
								<div style={user ? { paddingTop: 6 } : null}>{this.props.grouponly ? user.name : user.fullname}</div>
							</Stack>
						)}
						clearOption={
							this.props.group || this.props.grouponly
								? this.props.t("common.seller_selector.actions.show_all", "Visa alla")
								: this.props.clearable
								? this.props.t("common.seller_selector.empty_coworker.caption", "Ingen medarbetare")
								: null
						}
						id_handle="id"
						resource_handle={this.props.group ? "usergroups" : this.props.grouponly ? "groups" : "users"}
						label_handle={this.props.grouponly ? "name" : "fullname"}
						resourceName={{
							singular: this.props.group
								? this.props.t("common.seller_selector.user_group.singular", "användare/grupp")
								: this.props.grouponly
								? this.props.t("common.seller_selector.group.singular", "grupp")
								: this.props.t("common.seller_selector.user.singular", "användare"),
							plural: this.props.group
								? this.props.t("common.seller_selector.user_group.plural", "användare/grupper")
								: this.props.grouponly
								? this.props.t("common.seller_selector.group.singular", "grupper")
								: this.props.t("common.seller_selector.user.singular", "användare"),
						}}
					/>
				)}
			</Labelled>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(SellerSelector);

SellerSelector.defaultProps = {
	plain: true,
};
