import React, { Component } from "react";
import { SkeletonPage, Layout, Card, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

class Skeleton2Col extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<SkeletonPage fullWidth secondaryActions={2} primaryAction>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<SkeletonBodyText />
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
					</Layout.Section>
					<Layout.Section secondary>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
							<Card.Section>
								<SkeletonBodyText lines={1} />
							</Card.Section>
						</Card>
						<Card subdued>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={2} />
								</TextContainer>
							</Card.Section>
							<Card.Section>
								<SkeletonBodyText lines={2} />
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
			</SkeletonPage>
		);
	}
}

export default Skeleton2Col;
