import React, { Component } from "react";
import { Banner } from "@shopify/polaris";
import API from "../API";
import { toastr } from "./toastr.js";

class OpenNotification extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.notification_id, loading: true };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.notification_id != this.state.id) {
			this.fetchNotification(props.notification_id);
		}
	}

	componentDidMount() {
		this.fetchNotification(this.state.id);
	}

	fetchNotification(id) {
		this.setState({ loading: true, id });
		API.get("/api/notifications/" + id + ".json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ loading: false, notification: result.data.notification });
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	render() {
		if (this.state.loading) {
			return <div />;
		}
		return (
			<div style={{ marginBottom: 20 }}>
				<Banner title={this.state.notification.content} status="info" />
			</div>
		);
	}
}
export default OpenNotification;
