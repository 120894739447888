import React, { Component } from "react";
import { Heading, Icon, Collapsible, Spinner, Button, ResourceItem, Stack } from "@shopify/polaris";
import { ChevronRightMinor, CirclePlusMajor, LinkMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";

class ExpandableSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		return (
			<div className="Polaris-ResourceList__ItemWrapper">
				<ResourceItem
					onClick={() => {
						if (!this.state.open && this.props.onExpand) {
							this.props.onExpand();
						}
						this.setState({ open: !this.state.open });
					}}
				>
					<Heading>
						<Stack>
							<Stack.Item fill>
								<Stack spacing="loose">
									<div className="icon">
										{this.props.icon && (React.isValidElement(this.props.icon) ? this.props.icon : <Icon source={this.props.icon} />)}
									</div>
									<Stack spacing="tight">
										<span>{this.props.title}</span>
										{this.props.link && this.props.history && (
											<a
												onClick={() => {
													if (this.props.history) this.props.history.push(this.props.link);
												}}
											>
												<Icon source={LinkMinor} color="base" />
											</a>
										)}
										<span style={{ transition: "transform 250ms", display: "flex", transform: `rotate(${this.state.open ? 90 : 0}deg)` }}>
											<Icon source={ChevronRightMinor} />
										</span>
									</Stack>
								</Stack>
							</Stack.Item>
							{this.props.count}
							{this.props.onCreate ? (
								this.props.loading ? (
									<Spinner size="small" color="teal" />
								) : (
									<div style={{ paddingTop: 2 }} onClick={(e) => e.stopPropagation()}>
										<Button plain onClick={this.props.onCreate}>
											<Icon source={CirclePlusMajor} />
										</Button>
									</div>
								)
							) : null}
						</Stack>
					</Heading>
				</ResourceItem>
				<Collapsible open={this.state.open}>
					{this.props.loading ? (
						<ResourceItem>
							<Stack>
								<Spinner size="small" />{" "}
								<span>
									{this.props.t("common.expandable.loading", "Laddar {{plural}}...", {
										plural: this.props.resourceName.plural,
									})}
								</span>
							</Stack>
						</ResourceItem>
					) : (
						this.props.children
					)}
				</Collapsible>
			</div>
		);
	}
}

export default withTranslation(["common"], { withRef: true })(ExpandableSection);
