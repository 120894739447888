import numberFormatter from "src/js/Utilities/numberFormatter";

/* eslint-disable camelcase */
const getInCents = (value) => {
	if (value === null || value === undefined) return 0;

	const v = String(value).replace(/,/g, ".").replace(/\s/g, "");

	const [integerPart, fractionalPart = "00"] = v.split(".");
	const normalizedFractionalPart = (fractionalPart + "00").slice(0, 2);
	const integerPartInCents = parseInt(integerPart, 10) * 100;
	const fractionalPartInCents = parseInt(normalizedFractionalPart, 10);

	return integerPartInCents + fractionalPartInCents;
};

const formatPriceListRow = (articlePricelist: ArticlePricelistType): ArticlePricelistType => {
	const pricelist = { ...(articlePricelist || {}) };
	const price_in_cents = getInCents(pricelist.price || 0);
	const tb1_in_cents = getInCents(pricelist.tb1 || 0);
	const tb2_in_cents = getInCents(pricelist.tb2 || 0);

	pricelist.price_in_cents = price_in_cents;
	pricelist.tb1_in_cents = tb1_in_cents;
	pricelist.tb2_in_cents = tb2_in_cents;

	pricelist.price = numberFormatter({ value: price_in_cents / 100, minimumFractionDigits: 2, removeCurrencySymbol: true }) as unknown as number;
	pricelist.tb1 = numberFormatter({ value: tb1_in_cents / 100, minimumFractionDigits: 2, removeCurrencySymbol: true }) as unknown as number;
	pricelist.tb2 = numberFormatter({ value: tb2_in_cents / 100, minimumFractionDigits: 2, removeCurrencySymbol: true }) as unknown as number;

	return pricelist;
};
export default formatPriceListRow;
export { getInCents };
