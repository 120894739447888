import React, { Component } from "react";
import { Stack, Icon } from "@shopify/polaris";
import { MaximizeMinor, MinimizeMinor } from "@shopify/polaris-icons";
import $ from "jquery";
import API from "../../../API";
import KanbanColumn from "./KanbanColumn";
import { toastr } from "../../../components/toastr.js";

class KanbanGroup extends Component {
	constructor(props) {
		super(props);

		this.state = { collapsed: props.group ? props.group.collapsed : false };
	}

	collapseGroup() {
		this.setState({ collapsed: true });
		API.put("/api/boards/" + this.props.board.id + "/groups/" + this.props.group.id + ".json", { collapsed: true }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateGroup(result.data.group);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	expandGroup() {
		this.setState({ collapsed: false });
		API.put("/api/boards/" + this.props.board.id + "/groups/" + this.props.group.id + ".json", { collapsed: false }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateGroup(result.data.group);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		const rows = {};

		for (let i = 0; i < this.props.column.options.statuses.length; i++) {
			rows[this.props.column.options.statuses[i].id] = [];
		}
		for (let i = 0; i < this.props.rows.length; i++) {
			const row = this.props.rows[i];

			let status = null;
			for (let s = 0; s < row.values?.length; s++) {
				const value = row.values[s];
				if (value.column_id == this.props.column.id) {
					if (value.value || value.value === 0 || value.value === "0") {
						status = value.value;
						break;
					}
				}
			}

			if (status !== null && rows[status]) {
				rows[status].push(row);
			}
		}

		if (this.props.group.id && this.state.collapsed) {
			return (
				<a
					onClick={this.expandGroup.bind(this)}
					className="fixed-column"
					style={{
						display: "block",
						// marginBottom: 20,
						marginRight: -10000,
						width: $(".Polaris-Frame__Content").width(),
						height: 60,
					}}
				>
					<div className="collapsed-group" style={{ paddingLeft: 0, marginBottom: 20 }}>
						<Stack spacing="tight" wrap={false} /* distribution="fill" */>
							<div>
								<a onClick={this.expandGroup.bind(this)} style={{ cursor: "pointer" }}>
									<Icon source={MaximizeMinor} color="inkLighter" />
								</a>
							</div>
							<Stack.Item fill>
								<span className="overflow-ellipsis group-title" style={{ width: "100%", color: this.props.group.color }}>
									{this.props.group.title}
								</span>
							</Stack.Item>
						</Stack>
					</div>
				</a>
			);
		}

		const kanbanRow = (
			<Stack wrap={false} /* distribution="fill" */>
				{this.props.column.options.statuses.map((status) => {
					if (status.removed) {
						return null;
					}
					return (
						<KanbanColumn
							key={status.id}
							rows={rows[status.id]}
							board={this.props.board}
							group={this.props.group}
							column={this.props.column}
							onCreateRow={this.props.onCreateRow}
							status={status}
							statusIndex={status.id}
							editRow={this.props.editRow}
							view={this.props.view}
						/>
					);
				})}
			</Stack>
		);

		if (this.props.group.id) {
			return (
				<div
					style={{
						height: $(".board-container").height() - (($(".board-main-header")?.height() || 0) + 40),
					}}
				>
					<div
						className="fixed-column"
						style={{
							marginRight: -10000,
							width: $(".Polaris-Frame__Content").width(),
							marginTop: 20,
						}}
					>
						<div
							className="collapsed-group"
							style={{
								backgroundColor: "transparent",
								paddingLeft: 0,
								border: 0,
							}}
						>
							<Stack spacing="tight" wrap={false}>
								<div>
									<a onClick={this.collapseGroup.bind(this)} style={{ cursor: "pointer" }}>
										<Icon source={MinimizeMinor} color="inkLighter" />
									</a>
								</div>
								<Stack.Item fill>
									<span className="overflow-ellipsis group-title" style={{ width: "100%", color: this.props.group.color }}>
										{this.props.group.title}
									</span>
								</Stack.Item>
							</Stack>
						</div>
					</div>
					<div>{kanbanRow}</div>
				</div>
			);
		}
		return kanbanRow;
	}
}
export default KanbanGroup;
