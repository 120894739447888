import React, { useState } from "react";
import ReactQuill from "react-quill";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import { Card, Icon } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import EmailViewerModal from "./EmailViewerModal";

type RenderEmailProps = {
	email: string;
	comment?: CommentType;
	inline?: boolean;
	preview?: boolean;
} & WithTranslation;

const RenderEmail = ({ email, comment, t, inline, preview = true }: RenderEmailProps) => {
	const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);

	const getContent = () => {
		try {
			return JSON.parse(email);
		} catch (error) {
			return {
				subject: comment?.row?.title || null,
				body: email,
			};
		}
	};

	const emailContent = getContent();

	const handleOpenModal = () => {
		if (!inline) setEmailModalIsOpen(true);
	};

	const content = !preview ? (
		<div onClick={handleOpenModal} style={{ cursor: "pointer" }}>
			<Card
				sectioned
				title={emailContent?.subject}
				actions={[
					{
						content: t("emails.actions.show", "Visa mailet") as string,
						onAction: handleOpenModal,
					},
				]}
			/>
		</div>
	) : (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();

				if (!inline) handleOpenModal();
			}}
			style={{ display: "flex", flexDirection: "column", gap: "0.5rem", position: "relative", cursor: "pointer" }}
		>
			{emailContent?.subject && <h2>{`${t("timeline.fields.subject.label", "Ämne")}: ${emailContent?.subject}`}</h2>}
			{emailContent?.body && (
				<div className="sellfinity-quill text-editor" style={{ position: "relative" }}>
					{!inline && (
						<OpenEmailButton
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();

								handleOpenModal();
							}}
							title={t("emails.actions.open_all", "Öppna hela mailet") as string}
						>
							{/* <Tooltip content={t("emails.actions.open_all", "Öppna hela mailet")}> */}
							<Icon source={ExternalMinor} />
							{/* </Tooltip> */}
						</OpenEmailButton>
					)}

					{comment?.external_id && <RenderEmailContent>{emailContent?.body}</RenderEmailContent>}
					{!comment?.external_id && <ReactQuill className="slim" modules={{ toolbar: false }} value={emailContent?.body} theme="snow" readOnly />}
				</div>
			)}
		</div>
	);

	return (
		<Container>
			{content}
			<EmailViewerModal
				title={emailContent?.subject}
				onClose={() => {
					setEmailModalIsOpen(false);
				}}
				open={emailModalIsOpen}
				email={email}
				comment={comment}
			/>
		</Container>
	);
};
export default withTranslation(["emails", "common"])(RenderEmail);

const RenderEmailContent = ({ children }) => {
	return (
		<Wrapper
			dangerouslySetInnerHTML={{
				__html: children,
			}}
		/>
	);
};

const Wrapper = styled.div`
	padding: 12px 15px;
	background-color: var(--modals-input-bg);
	border-radius: 5px;
	white-space: pre-wrap;
	color: #000;
	&,
	* {
		font-size: 12px !important;
		/* color: var(--textColor) !important; */
	}
`;

const Container = styled.div`
	.Polaris-Card {
		background-color: var(--main3);

		.Polaris-Card__Section {
			padding: 1rem;
		}
	}

	&,
	* {
		font-size: 12px !important;
		/* color: var(--textColor); */
	}
`;

const OpenEmailButton = styled.button`
	position: absolute;
	top: 0;
	left: 0;
	background: transparent;
	border: none;
	outline-color: none;
	cursor: pointer;
	z-index: 2;
	transform: scaleX(-1);
`;
