import { Card, Layout } from "@shopify/polaris";
import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import Skeleton2Col from "src/js/components/skeleton_2col";
import FortnoxOfferContext from "./FortnoxOfferContext";
import FortnoxOfferRows from "./FortnoxOfferRows";
import FortnoxOfferInfo from "./FortnoxOfferInfo";
import { InfoWrapper } from "../styles";

const FortnoxOfferInfoView = ({ history, t }) => {
	const { form, isFetching, setForm, isSaving } = useContext(FortnoxOfferContext);

	const getChangeHandler = (field: FortnoxOfferTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<FortnoxOfferType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;
			return newForm;
		});
	};

	return (
		<InfoWrapper>
			<Card sectioned>
				{!form || isFetching ? (
					<Skeleton2Col />
				) : (
					<Layout>
						<Layout.Section>
							<FortnoxOfferInfo isSaving={isSaving} offer={form} onChange={getChangeHandler} />
						</Layout.Section>

						<Layout.Section>
							<div style={{ marginTop: "5rem" }}>
								<FortnoxOfferRows isSaving={isSaving} offer={form} onChange={getChangeHandler} />
							</div>
						</Layout.Section>
					</Layout>
				)}
			</Card>
		</InfoWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOfferInfoView);
