import React, { Component } from "react";
import { Link, FooterHelp, Layout, Card, FormLayout, TextField, TextStyle, ResourceList, ExceptionList } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { store } from "../../store";
import IntegrationModal from "../../components/IntegrationModal.js";
import WebIntegrationModal from "../../components/WebIntegrationModal.js";

class Integrations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentIntegration: {
				title: "",
			},
		};
	}

	renderItem(item) {
		const exceptions = [];

		if (item.content) {
			exceptions.push({
				icon: "notes",
				description: item.content,
			});
		}

		const exceptionList = exceptions.length ? (
			<div className="CustomerListItem__Exceptions">
				<ExceptionList items={exceptions} />
			</div>
		) : null;

		return (
			<ResourceList.Item id={item.id} onClick={this.openIntegrationModal.bind(this, item)}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
				{exceptionList}
			</ResourceList.Item>
		);
	}

	openIntegrationModal(item) {
		this.setState({ openWebIntegrationModal: true, currentIntegration: item });
	}

	closeIntegrationModal() {
		this.setState({ openWebIntegrationModal: false });
	}

	render() {
		return (
			<div>
				<Page
					title={this.props.t("integration.title", "API & Integrationer")}
					breadcrumbs={[{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }]}
				>
					<Layout>
						<Layout.AnnotatedSection
							title={this.props.t("integration.sections.api.title", "API uppgifter")}
							description={this.props.t("integration.sections.api.description", "Skapa och hämta information genom vårt API")}
						>
							<Card sectioned title={this.props.t("integration.sections.api.card_title", "Uppgifter")}>
								<FormLayout>
									<TextField
										label={this.props.t("integration.sections.api.fields.acc_id.label", "Konto id")}
										value={store.getState().account.id + ""}
									/>
									<TextField
										label={this.props.t("integration.sections.api.fields.api_key.label", "API Nyckel")}
										value={store.getState().user.token}
									/>
									<TextField
										label={this.props.t("integration.sections.api.fields.shared_secret.label", "Delad hemlighet")}
										value={store.getState().account.shared_secret}
									/>
								</FormLayout>
							</Card>
						</Layout.AnnotatedSection>

						<Layout.AnnotatedSection
							title={this.props.t("integration.sections.integrations.title", "Integrationer")}
							description={this.props.t("integration.sections.integrations.description", "Integrera delar av ert egna system i sellfinity")}
						>
							<Card
								title={this.props.t("integration.sections.integrations.card_title", "Integrationer")}
								actions={[
									{
										content: this.props.t("integration.actions.new", "Ny integration"),
										onAction: () => {
											this.setState({ openIntegrationModal: true });
										},
									},
									{
										content: this.props.t("integration.actions.new_webb", "Ny webbintegration"),
										onAction: this.openIntegrationModal.bind(this, { title: "", type: "web" }),
									},
								]}
							>
								<ResourceList
									resourceName={{
										singular: this.props.t("intagrations.singular", "integration"),
										plural: this.props.t("integration.plural", "integrationer"),
									}}
									items={store.getState().account.integrations}
									renderItem={this.renderItem.bind(this)}
								/>
								{store.getState().account.integrations.length ? null : (
									<Card.Section>
										<TextStyle variation="subdued">{this.props.t("integration.empty", "Inga integrationer att visa")}</TextStyle>
									</Card.Section>
								)}
							</Card>
						</Layout.AnnotatedSection>
					</Layout>
				</Page>
				<WebIntegrationModal
					open={this.state.openWebIntegrationModal}
					integration={this.state.currentIntegration}
					onCreate={(type) => {
						this.closeIntegrationModal();
					}}
					onClose={this.closeIntegrationModal.bind(this)}
				/>
				<IntegrationModal
					open={this.state.openIntegrationModal}
					type="all"
					onCreate={(type) => {}}
					onClose={() => {
						this.setState({ openIntegrationModal: false });
					}}
				/>
				<FooterHelp>
					{this.props.t("integration.footer1", "Läs mer om hur du gör avancerade integrationer med")}
					<Link url="https://github.com/itbmedia/KUND24-API-PHP"> {this.props.t("integration.footer2", "vårt API")}</Link>
				</FooterHelp>
			</div>
		);
	}
}

export default withTranslation(["integration", "common"], { withRef: true })(Integrations);
