import React, { Component } from "react";
import { SkeletonPage, Layout, Card, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

class SkeletonProvider extends Component {
	constructor(props) {
		super(props);
		this.state = { title: props.title };
	}

	render() {
		return (
			<SkeletonPage title={this.state.title}>
				<Layout>
					<Layout.Section>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={4} />
								</TextContainer>
							</Card.Section>
						</Card>
						<Card>
							<Card.Section>
								<TextContainer>
									<SkeletonDisplayText size="small" />
									<SkeletonBodyText lines={4} />
								</TextContainer>
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout>
			</SkeletonPage>
		);
	}
}

export default SkeletonProvider;
