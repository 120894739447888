import { Form, TextField, Tooltip } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "src/js/Colors";
import { ColorBox } from "./edit";

const Entry = React.memo(({ entry: propsEntry, onSubmitEntry }) => {
	const [entry, setEntry] = useState(propsEntry);

	useEffect(() => {
		setEntry(propsEntry);
	}, [propsEntry]);

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				if (propsEntry.value !== entry.value) onSubmitEntry(entry);
				event.target?.querySelector("input")?.blur();
			}}
		>
			<Wrapper missing={!entry.exists} same={entry.value === entry.originalValue}>
				<ColorBox color={!entry.exists ? Colors.red : entry.value === entry.originalValue ? Colors.yellow : "transparent"}>
					<Tooltip content={`Handle: ${entry.handle}`}>
						<div style={{ height: "100%", width: "100%" }} />
					</Tooltip>
				</ColorBox>

				<TextField
					prefix={
						<>
							<span>{entry.originalValue}</span>
							<span style={{ marginLeft: "0.9rem" }}>/</span>
						</>
					}
					value={entry.value}
					onChange={(value) => {
						setEntry((c) => ({ ...c, value }));
					}}
					onBlur={() => {
						if (propsEntry.value !== entry.value) onSubmitEntry(entry);
					}}
				/>
			</Wrapper>
		</Form>
	);
}, deepEqual);

function deepEqual(obj1, obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export default Entry;

const Wrapper = styled.div`
	margin-block: 0.5rem;
	display: flex;
	gap: 1px;

	& > * {
		flex: 1;
	}

	/* background-color: ${(props) => (props.missing ? "red" : props.same ? "yellow" : "transparent")}; */
`;
