import { Button, FormLayout, Page, Spinner, TextField, Stack, Modal } from "@shopify/polaris";
import { PrintMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { toastr } from "react-redux-toastr";
import { withTranslation } from "react-i18next";
import API from "../../API";
import PdfTemplateSheet from "../../components/PdfTemplateSheet";

class EditPDF extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: props.location?.state?.stacks?.[0]?.item || { variables: [] },
			id: props.id || props.match?.params?.id,
		};
	}

	componentDidMount() {
		this.fetch();
	}

	UNSAFE_componentWillReceiveProps(props) {
		const id = props.id || props.match?.params?.id;
		if (id != this.state.id) {
			this.setState({ id, form: { variables: [] } }, this.fetch.bind(this));
		}
	}

	handleChange(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	fetch() {
		this.setState({ loading: true });
		API.get(`/api/pdf_templates/${this.state.id}.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState({ form: result.data.pdf_template, loading: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	onSave() {
		this.setState({ saving: true });

		API.put(`/api/pdf_templates/${this.state.id}.json`, this.state.form)
			.then((result) => {
				this.setState({ saving: false });

				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("pdf.responses.updated", "Uppdaterade PDF"));
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	remove() {
		this.setState({ removing: true });
		API.delete(`/api/pdf_templates/${this.state.id}.json`)
			.then((result) => {
				this.setState({ removing: false, removeModalIsOpen: false });

				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				this.props.history.push("/admin/pdf_templates/");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	gotoEditor(index) {
		this.props.history.push(`/admin/pdf_templates/editor/${this.state.id}${index !== null && index !== undefined ? `?page=${index}` : ""}`);
	}

	openModal(name) {
		this.setState({ [name]: true });
	}

	closeModal(name) {
		this.setState({ [name]: false });
	}

	handleOpenRemove() {
		this.setState({ removeModalIsOpen: true });
	}

	handleCloseRemove() {
		this.setState({ removeModalIsOpen: false });
	}

	duplicatePdf() {
		this.setState({ duplicating: true });
		API.post(`/api/pdf_templates/${this.state.id}/duplicate.json`, { title: "Kopia av " + this.state.form.title })
			.then((result) => {
				this.setState({ duplicating: false });

				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("pdf.responses.duplicated", "Duplicerade PDF"));
				this.props.history.push("/admin/pdf_templates/" + result.data.pdf_template.id);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		const form = this.state.form || {};
		const disabled = this.state.loading || this.state.removing;
		return (
			<Page
				title={
					<span>
						{this.props.t("pdf.edit.title", "Redigera PDF")} {this.state.loading && <Spinner size="small" />}
					</span>
				}
				breadcrumbs={[{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }]}
				primaryAction={{ content: this.props.t("common.actions.save", "Spara"), onAction: this.onSave.bind(this), loading: this.state.saving }}
				secondaryActions={[
					{
						content: (
							<span>
								{this.props.t("common.actions.remove", "Ta bort")} {this.state.removing && <Spinner size="small" />}
							</span>
						),

						loading: this.state.removing,
						onAction: this.handleOpenRemove.bind(this),
						destructive: true,
					},
					{
						content: this.props.t("pdf.actions.duplicate", "Duplicera"),
						loading: this.state.duplicating,
						onAction: this.duplicatePdf.bind(this),
					},
				]}
			>
				<FormLayout>
					<TextField
						disabled={disabled}
						type="text"
						label={this.props.t("pdf.fields.title.label", "Titel")}
						onChange={this.handleChange.bind(this, "title")}
						value={form.title}
					/>

					<div className="pdf-pages-overview">
						{form.pages &&
							form.pages.map((page = {}, index) => (
								<div key={page.id || index} onClick={() => this.gotoEditor(index + 1)} className="page-tumbnail">
									<img alt="" src={page.thumbnail_url} />
								</div>
							))}
					</div>
					<Stack>
						<Button disabled={this.state.loading} onClick={this.openModal.bind(this, "printModalOpen")} icon={PrintMajor}>
							{this.props.t("common.actions.print", "Skriv ut")}
						</Button>
						<Button disabled={this.state.saving} onClick={() => this.gotoEditor()}>
							{this.props.t("pdf.actions.show_all_pages", "se alla sidor")}
						</Button>
						<Button primary loading={this.state.saving} onClick={this.onSave.bind(this)}>
							{this.props.t("common.actions.save", "Spara")}
						</Button>
						<Button destructive loading={this.state.removing} onClick={this.handleOpenRemove.bind(this)}>
							{this.props.t("common.actions.remove", "Ta bort")}
						</Button>
					</Stack>
				</FormLayout>
				<PdfTemplateSheet template={this.state.form} open={this.state.printModalOpen} onClose={this.closeModal.bind(this, "printModalOpen")} />

				<Modal
					open={this.state.removeModalIsOpen}
					title={`${this.props.t("common.actions.remove", "Ta bort")} "${form.title}"`}
					onClose={this.handleCloseRemove.bind(this)}
					secondaryActions={[
						{
							content: this.props.t("common.actions.remove", "Ta bort"),
							onAction: this.remove.bind(this),
							loading: this.state.removing,
							destructive: true,
						},
						{
							content: this.props.t("common.actions.cancel", "Avbryt"),
							onAction: this.handleCloseRemove.bind(this),
						},
					]}
				>
					<Modal.Section>
						<p>Säker du vill ta bort PDF &quot;{form.title}&quot;</p>
					</Modal.Section>
				</Modal>
			</Page>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(EditPDF);
