import React, { Component } from "react";
import { Card, Stack, Heading, Avatar, TextStyle } from "@shopify/polaris";
import moment from "moment";
import nl2br from "react-nl2br";
import MemberAvatar from "./MemberAvatar";

class RowActivity extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<Card sectioned>
				<Stack wrap={false}>
					{this.props.activity.user ? <MemberAvatar user={this.props.activity.user} /> : <Avatar customer size="small" />}
					<Stack.Item fill>
						<Stack vertical spacing="none">
							<Stack distribution="fill">
								<Heading>{this.props.activity.user ? this.props.activity.user.name : "System"}</Heading>
								<TextStyle variation="subdued">{moment(this.props.activity.created_at).locale("sv").calendar()}</TextStyle>
							</Stack>
							<div style={{ marginTop: 15 }}>{nl2br(this.props.activity.title)}</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card>
		);
	}
}
export default RowActivity;
