import React, { Component } from "react";
import { ActionList, Stack, Button, Icon, Badge, Spinner } from "@shopify/polaris";
import { CircleDotsMajor, ChatMajor, CircleCancelMajor, CircleTickMajor, CircleDisabledMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import Popover from "./Popover.js";

class TicketStatus extends Component {
	constructor(props) {
		super(props);
		this.state = { popoverActive: false };
	}

	render() {
		let title;
		let status;
		let icon;

		if (this.props.loading) {
			return <Spinner size="small" color="teal" />;
		}
		if (this.props.status == "open") {
			title = this.props.t("ticket.statuses.open", "Öppen");
			status = "attention";
			icon = ChatMajor;
		} else if (this.props.status == "pending") {
			title = this.props.t("ticket.statuses.pending", "Väntande");
			status = "info";
			icon = CircleDotsMajor;
		} else if (this.props.status == "closed") {
			title = this.props.t("ticket.statuses.closed", "Avslutad");
			status = "success";
			icon = CircleTickMajor;
		} else if (this.props.status == "removed") {
			title = this.props.t("ticket.statuses.removed", "Borttagen");
			status = "warning";
			icon = CircleCancelMajor;
		} else if (this.props.status == "spam") {
			title = this.props.t("ticket.statuses.spam", "Markerat som spam");
			status = "default";
			icon = CircleDisabledMajor;
		}

		let activator;

		if (this.props.badge) {
			activator = (
				<span
					style={{ cursor: "pointer" }}
					onClick={() => {
						this.setState({ popoverActive: !this.state.popoverActive });
					}}
				>
					<Stack spacing="extraTight" wrap={false}>
						<Icon source={icon} />
						<Badge status={status}>{title}</Badge>
					</Stack>
				</span>
			);
		} else {
			activator = (
				<Button
					onClick={() => {
						this.setState({ popoverActive: !this.state.popoverActive });
					}}
					disclosure
					outline={this.props.status == "pending"}
					primary={this.props.status == "closed"}
					destructive={!!(this.props.status == "removed" || this.props.status == "spam")}
				>
					<Icon source={icon} />
				</Button>
			);
		}

		return (
			<span
				onClick={(event) => {
					event.stopPropagation();
				}}
			>
				<Popover
					active={this.state.popoverActive}
					activator={activator}
					onClose={() => {
						this.setState({ popoverActive: !this.state.popoverActive });
					}}
				>
					<ActionList
						items={[
							{
								content: (
									<Stack>
										<Icon source={ChatMajor} /> <span>{this.props.t("ticket.option.open", "Öppen")}</span>
									</Stack>
								),
								onAction: () => {
									this.props.onChange("open");
									this.setState({ popoverActive: false });
								},
							},
							{
								content: (
									<Stack>
										<Icon source={CircleDotsMajor} /> <span>{this.props.t("ticket.option.pending", "Väntande")}</span>
									</Stack>
								),
								onAction: () => {
									this.props.onChange("pending");
									this.setState({ popoverActive: false });
								},
							},
							{
								content: (
									<Stack>
										<Icon source={CircleTickMajor} /> <span>{this.props.t("ticket.option.closed", "Avslutad")}</span>
									</Stack>
								),
								onAction: () => {
									this.props.onChange("closed");
									this.setState({ popoverActive: false });
								},
							},
							{
								destructive: true,
								content: (
									<Stack>
										<Icon source={CircleDisabledMajor} /> <span>{this.props.t("ticket.option.spam", "Markera som spam")}</span>
									</Stack>
								),
								onAction: () => {
									this.props.onChange("spam");
									this.setState({ popoverActive: false });
								},
							},
							{
								destructive: true,
								content: (
									<Stack>
										<Icon source={CircleCancelMajor} /> <span>{this.props.t("ticket.option.opremoveden", "Ta bort")}</span>
									</Stack>
								),
								onAction: () => {
									this.props.onChange("removed");
									this.setState({ popoverActive: false });
								},
							},
						]}
					/>
				</Popover>
			</span>
		);
	}
}

export default withTranslation(["ticket", "common"], { withRef: true })(TicketStatus);
