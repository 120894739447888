import { Card, FormLayout, Layout } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import TextField from "src/js/components/TextField";
import { store } from "src/js/store";

type OneOf<T, K extends keyof T> = {
	[P in K]-?: Required<Pick<T, P>> & Partial<Record<Exclude<K, P>, never>>;
}[K];

type PartialFortnoxForm = OneOf<
	{
		invoice: Partial<FortnoxInvoiceType> | null;
		order: Partial<FortnoxOrderType> | null;
		offer: Partial<FortnoxOfferType> | null;
	},
	"invoice" | "order" | "offer"
>;

type FortnoxCustomerFieldsPropsBase = {
	history: any;
	setForm: (c: any) => void;
	isSaving: boolean;
} & WithTranslation;

type FortnoxCustomerFieldsProps = PartialFortnoxForm & FortnoxCustomerFieldsPropsBase;

const FortnoxCustomerFields = ({ invoice, order, offer, t, setForm, history, isSaving }: FortnoxCustomerFieldsProps) => {
	const form: FortnoxInvoiceType | FortnoxOrderType | FortnoxOfferType = invoice || order || (offer as any);
	const disabled = !form || (form && "InvoiceReference" in form && !!form?.InvoiceReference) || form?.Cancelled || form.Sent || isSaving;

	const onChange =
		(
			field: FortnoxInvoiceTypeMutableKeys | FortnoxOrderTypeMutableKeys | FortnoxOfferTypeMutableKeys,
			secondField: FortnoxInvoiceTypeMutableKeys | FortnoxOrderTypeMutableKeys | FortnoxOfferTypeMutableKeys | keyof EmailInformation | null = null
		) =>
		(value: string | boolean) => {
			setForm((c: PartialFortnoxForm) => {
				const newForm = { ...c };

				if (secondField) {
					newForm[field as string][secondField as string] = value;
				} else {
					newForm[field as string] = value;
				}

				return newForm;
			});
		};

	return (
		<Card sectioned>
			<Layout>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.CustomerName.label", "Namn")}
							value={form?.CustomerName}
							onChange={onChange("CustomerName")}
						/>
						<TextField
							readOnly
							disabled={disabled}
							label={t("fortnox.fields.OrganisationNumber.label", "Organisationsnummer")}
							value={form?.OrganisationNumber}
							onChange={onChange("OrganisationNumber")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.EmailInformation.EmailAddressTo.label", "E-postadress")}
							value={form?.EmailInformation?.EmailAddressTo}
							onChange={onChange("EmailInformation", "EmailAddressTo")}
						/>
						<TextField disabled={disabled} label={t("fortnox.fields.Phone1.label", "Telefon")} value={form?.Phone1} onChange={onChange("Phone1")} />
					</FormLayout>
				</Layout.Section>
				<Layout.Section oneHalf>
					<FormLayout>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.Address1.label", "Fakturaadress")}
							value={form?.Address1}
							onChange={onChange("Address1")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.Address2.label", "Fakturaadress 2")}
							value={form?.Address2}
							onChange={onChange("Address2")}
						/>
						<TextField
							disabled={disabled}
							label={t("fortnox.fields.ZipCode.label", "Postnummer")}
							value={form?.ZipCode}
							onChange={onChange("ZipCode")}
						/>
						<TextField disabled={disabled} label={t("fortnox.fields.City.label", "Ort")} value={form?.City} onChange={onChange("City")} />

						{/* should be a search/select field */}
						{/* <TextField disabled={disabled} label={t("fortnox.fields.Country.label", "Land")} value={form?.Country} onChange={onChange("Country")} />
						 */}
						<Select
							disabled={disabled}
							label={t("fortnox.fields.Country.label", "Land") as string}
							options={[{ label: "Välj land", value: "" }, ...store.getState().countries.map((c) => ({ label: c.label, value: c.label }))]}
							value={form?.Country || ""}
							onChange={onChange("Country")}
						/>
						{/*
						<SearchField
							label={t("fortnox.fields.Country.label", "Land")}
							value={form?.Country}
							onChange={(country) => {
								onChange("Country")(country?.label);
							}}
							resourceName={{
								singular: t("common.countries.singular", "Land"),
								plural: t("common.countries.plural", "Land"),
							}}
							resource="countries.json"
							resource_handle="countries"
							label_handle="label"
							id_handle="label"
							disabled={disabled}
						/> */}
					</FormLayout>
				</Layout.Section>
			</Layout>
		</Card>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxCustomerFields);
