import React, { Component } from "react";
import { Card, Spinner } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import Banner from "./banner";
import FortnoxOrderView from "./fortnox/FortnoxOrderView";
import FortnoxSettings from "./fortnox/FortnoxSettings";
import FortnoxContractView from "./fortnox/FortnoxContractView";
import FortnoxQuoteView from "./fortnox/FortnoxQuoteView";
import FortnoxInvoiceView from "./fortnox/FortnoxInvoiceView";

class FortnoxIntegration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			customer: null,
			form: { OurReference: store.getState().user.name },
			options: (props.integration && props.integration.options) || {},
			integration: props.integration || {},
		};

		if (!this.state.options.sync_fields) {
			this.state.options.sync_fields = [];
		}
		if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
			// Send to Apps Webview
			window.ReactNativeWebView.postMessage(JSON.stringify({ view_integration_id: props.integration.id }));
		}
	}

	componentDidMount() {
		this.fetchCustomer();
		if (this.getPath() == "quotes") {
			if (this.props.integration && this.props.integration.type != "fortnox_invoice") {
				this.fetchCustomer();
			}
		} else if (this.getPath() == "orders") {
			this.fetchCustomer();
		} else if (this.getPath() == "contracts") {
			this.fetchCustomer();
		} else if (this.getPath() == "sync_company") {
			this.syncToErp();
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ options: props.integration.options || {} });
		if (!this.state.options.sync_fields) {
			this.state.options.sync_fields = [];
		}
	}

	fetchCustomer() {
		API.get(
			"/api/integrations/" + this.props.integration.id + "/contacts/" + (this.props.contact && this.props.contact.id) + "/customer.json",
			{},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ customer: result.data.customer });
			})
			.catch((error) => {
				this.setState({ error: error.response.data.message });
			});
	}

	syncToErp() {
		API.post(
			"/api/integrations/" + this.props.integration.id + "/contacts/" + (this.props.contact && this.props.contact.id) + "/sync.json",
			{},
			{ params: {} }
		)
			.then((result) => {
				if (!this.props.external) {
					document.location = "/admin/contacts/" + this.props.contact.id;
				}
			})
			.catch((error) => {});
	}

	getPath() {
		if (!this.props.path) return;
		const path = this.props.path.replace("/", "");
		return path;
	}

	render() {
		const form = this.state.form || {};
		if (this.state.error) {
			return (
				<Card sectioned>
					<Banner status="critical" title={this.state.error} />
				</Card>
			);
		} else if (this.getPath() == "quotes") {
			return <FortnoxQuoteView integration={this.state.integration} form={form} customer={this.state.customer} />;
		} else if (this.getPath() == "orders") {
			return <FortnoxOrderView integration={this.state.integration} form={form} customer={this.state.customer} />;
		} else if (this.getPath() == "contracts") {
			return <FortnoxContractView integration={this.state.integration} form={form} customer={this.state.customer} />;
		} else if (this.getPath() == "invoices") {
			return <FortnoxInvoiceView integration={this.state.integration} form={form} customer={this.state.customer} />;
		} else if (this.getPath() == "sync_company") {
			return (
				<div style={{ padding: 50 }}>
					<Spinner size="large" />
					{this.props.t("fortnox.statuses.syncing", "Synkar företag till fortnox...")}
				</div>
			);
		} else {
			return <FortnoxSettings options={this.state.options} external={this.props.external} integration={this.props.integration} />;
		}
	}
}

export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxIntegration);
