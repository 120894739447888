import React from "react";
import styled from "styled-components";
import API from "src/js/API";
import { WithTranslation, withTranslation } from "react-i18next";
import { toastr } from "src/js/components/toastr";
import CardList from "src/js/components/CardList";

type ResourceListManagerProps = {
	resourceUrl?: string;
	resourceHandle: string;
	addRemoveUrl: string;
	params?: any;
	history: any;
	resourceName?: {
		singular: string;
		plural: string;
	};
	filters?: any[];
	onClick: (item: any) => void;
	renderItem: (item: any) => any;
	appliedFilters?: any[];
	promotedBulkActions?: any[];
} & WithTranslation;

const ResourceListManager = ({
	resourceUrl,
	params = {},
	resourceHandle = "",
	addRemoveUrl,
	resourceName,
	promotedBulkActions: propsPromotedBulkActions,
	filters = [],
	renderItem,
	onClick,
	appliedFilters,
	t,
}: ResourceListManagerProps) => {
	const ref = React.useRef<any>();

	const handleRemove = async () => {
		const ids = ref.current?.getSelectedItems();

		await API.delete(addRemoveUrl, { data: { ids } });
		toastr.success(t("resources.responses.removed", "Borttagen"));
		ref.current?.reload();
	};

	const handleAdd = async () => {
		const ids = ref.current?.getSelectedItems();

		await API.post(addRemoveUrl, { ids });
		toastr.success(t("resources.responses.added", "Tillagd"));
		ref.current?.reload();
	};

	const promotedBulkActions =
		(propsPromotedBulkActions !== undefined
			? propsPromotedBulkActions
			: [
					{
						content: t("resources.add", "Lägg till") as unknown as string,
						onAction: handleAdd,
					},
					{
						content: t("resources.delete", "Ta bort") as unknown as string,
						onAction: handleRemove,
					},
			  ]) || [];
	return (
		<Wrapper>
			<CardList
				appliedFilters={appliedFilters}
				ref={ref}
				params={params}
				onClick={onClick}
				filters={filters}
				resourceUrl={resourceUrl || `/api/${resourceHandle}.json`}
				resourceName={resourceName}
				resourceHandle={resourceHandle}
				renderItem={renderItem}
				promotedBulkActions={promotedBulkActions}
				bulkActions={[]}
			/>
		</Wrapper>
	);
};

export default withTranslation(["resources", "common"])(ResourceListManager);

const Wrapper = styled.div``;
