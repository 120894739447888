import { FormLayout, Layout } from "@shopify/polaris";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import TextField from "src/js/components/TextField";
import Page from "src/js/components/page";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";

const OrderSettings = ({ t }) => {
	const [form, setForm] = useState(store.getState().account);

	const handleChange = (field: string) => (value: any) => {
		setForm((c) => ({ ...c, [field]: value }));
	};
	const handleSave = async () => {
		try {
			const res = await API.put(`/api/account.json`, form);

			toastr.success(t("orders.responses.account.updated", "Konto uppdaterat"));

			store.dispatch({ type: "SET_ACCOUNT", account: res.data.account });
			setForm(res.data.account);

			return res;
		} catch (error) {
			toastr.error(error);
		}
	};

	return (
		<Page
			title={t("orders.settings.title", "Order inställningar")}
			primaryAction={{
				content: t("common.save", "Spara"),
				onAction: handleSave,
			}}
		>
			<Layout sectioned>
				<FormLayout>
					<TextField
						label={t("orders.settings.order_starting_number.label", "Ordernummer")}
						value={form?.order_starting_number}
						onChange={handleChange("order_starting_number")}
					/>
				</FormLayout>
			</Layout>
		</Page>
	);
};
export default withTranslation(["orders", "common"])(OrderSettings);
