import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Tabs } from "@shopify/polaris";
import { HeaderWrapper } from "../styles";

type HeaderProps = {
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const TargetHeader = ({ tabs, onSelect, selected, t }: HeaderProps) => {
	return (
		<HeaderWrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</HeaderWrapper>
	);
};
export default withTranslation(["targets", "common"])(TargetHeader);
