import React from "react";
import { Button, ActionList } from "@shopify/polaris";
import Popover from "../../../components/Popover";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";

type MyActionListProps = {
	disclosure?: boolean;
	activatorProps?: any;
	title?: string | React.ReactNode;
	items: any[];
	activator?: any;
	closeOnAction?: boolean;
};

const MyActionList = ({
	disclosure = false,
	activatorProps = {},
	title = "",
	items,
	activator: propsActivator = null,
	closeOnAction = true,
}: MyActionListProps) => {
	const [isOpen, setIsOpen] = React.useState(false);

	const toggleActive = () => {
		setIsOpen((c) => !c);
	};

	const activator = React.cloneElement(
		propsActivator || (
			<Button onClick={toggleActive} disclosure={disclosure && isOpen} plain icon={MobileVerticalDotsMajor} {...(activatorProps || {})}>
				{title}
			</Button>
		),
		{
			onClick: toggleActive,
		}
	);

	return (
		<Popover active={isOpen} activator={activator} onClose={toggleActive}>
			<ActionList
				onActionAnyItem={() => {
					if (closeOnAction) {
						toggleActive();
					}
				}}
				// items={this.props.items.map((i) =>
				// 	Object.assign(
				// 		{
				// 			i,
				// 		},
				// 		{
				// 			onAction: () => {
				// 				i.onAction();
				// 				this.toggleActive();
				// 			},
				// 		}
				// 	)
				// )}
				items={items}
			/>
		</Popover>
	);
};

export default MyActionList;
