import React, { PureComponent } from "react";
import { TextStyle, Tooltip, ActionList, Spinner, Stack, Icon, Modal } from "@shopify/polaris";
import {
	MinimizeMajor,
	DragHandleMinor,
	DropdownMinor,
	CalendarMajor,
	CircleCancelMajor,
	LinkMinor,
	ConnectMinor,
	DnsSettingsMajor,
	CircleAlertMajor,
	ProfileMajor,
	WandMajor,
	CustomerPlusMajor,
	CustomerMinusMajor,
	SearchMinor,
	SortMinor,
} from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import BoardHelper from "./BoardHelper.js";
import ColumnHeaderSortButton from "./ColumnHeaderSortButton.js";
import CellText from "./CellText.js";
import DeadlineModal from "./components/DeadlineModal.js";
import CompanyContactModal from "./components/CompanyContactModal.js";
import FormulaModal from "./components/FormulaModal.js";
import UploadIntegrationModal from "./components/UploadIntegrationModal.js";
import RowlinkConstraintsModal from "./components/RowlinkConstraintsModal.js";
import RowlinkConnectionModal from "./components/RowlinkConnectionModal.js";
import { toastr } from "../../components/toastr.js";
import MemberAvatar from "./components/MemberAvatar";
import Popover from "../../components/Popover";
import HouseIcon from "../../icons/HouseIcon";
import { PeopleIcon } from "../../icons";
import PersonColumnModal from "./components/PersonColumnModal";

class ColumnHeader extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			editingTitle: false,
			title: props.column ? props.column.title : props.group.title,
			showContext: false,
			focused: false,
			query: "",
			permittedUserIds: props.column.permitted_user_ids || [],
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ((props.column ? props.column.title : props.group.title) != this.state.title) {
			this.setState({
				title: props.column ? props.column.title : props.group.title,
			});
		}
	}

	focusCell() {
		this.setState({ focused: true });
	}

	blurCell() {
		this.setState({ focused: false });
	}

	allowMultiplePerson() {
		this.setState({ saving: true, showContext: false });
		const data = {
			options: {
				single: false,
			},
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("column.header.responses.settings_changed", "Ändrade inställning för kolumn"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	onlyOnePerson() {
		this.setState({ saving: true, showContext: false });
		const data = {
			options: {
				single: true,
			},
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("column.header.responses.settings_changed", "Ändrade inställning för kolumn"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	changeGroupTitle() {
		this.setState({ editingTitle: false, saving: true });
		if (this.state.title != this.props.group.title) {
			this.props.group.title = this.state.title;

			API.put("/api/boards/" + this.props.board.id + "/groups/" + this.props.group.id + ".json", { title: this.state.title }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						this.setState({ saving: false });
						return;
					}
					this.props.onUpdateGroup(result.data.group);
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	changeColumnTitle() {
		this.setState({ saving: true });
		this.props.column.title = this.state.title;

		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", { title: this.state.title }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				BoardHelper.updateColumn(result.data.column);
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeUploadIntegration() {
		this.setState({ showContext: false });
		// const column = store.getState().user.calendar_board.columns.find((c) => c.type == "timeline");
		const data = {
			options: {},
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}

				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("column.header.responses.integration_removed", "Integration borttagen"));
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	disconnectSyncedCalendar() {
		// const column = store.getState().user.calendar_board.columns.find((c) => c.type == "timeline");
		const data = {
			options: {},
		};

		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("column.header.responses.calendar_removed", "Synkad Kalender borttagen"));
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	removeColumn() {
		this.setState({ saving: true, showContext: false });
		API.delete("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				toastr.success(this.props.t("column.header.responses.column_removed", "Tog bort kolumn") + " " + this.props.column.title);
				this.setState({ saving: false });
				BoardHelper.removeColumn(this.props.column);
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeAutoContact() {
		this.setState({ saving: true, showContext: false });
		const data = {
			connected_column_id: null,
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				this.props.t("column.header.responses.auto_contact_connection_removed", "Tog bort automatisk kontaktkoppling");
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	enableDeadlineModeFromRow() {
		this.setState({ saving: true, showContext: false });
		const data = {
			options: {
				show_deadlines: true,
			},
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				this.props.t("column.header.responses.enabled_deadline_mode", "Visar nu deadlines");
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	disableDeadlineModeFromRow() {
		this.setState({ saving: true, showContext: false });
		const data = {
			options: {
				show_deadlines: false,
			},
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				this.props.t("column.header.responses.disabled_deadline_mode", "Visar nu radnamn");
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeDeadlineMode() {
		this.setState({ saving: true, showContext: false });
		const data = {
			connected_column_id: null,
			link_column_id: null,
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateColumn(result.data.column);
				this.props.t("column.header.responses.as_deadline_removed", "Tog bort kolumn som deadline");
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	onCollapse() {
		this.props.group.collapsed = true;
		store.dispatch({ type: "UPDATE_BOARD_GROUP", group: this.props.group });
		API.put("/api/boards/" + this.props.board.id + "/groups/" + this.props.group.id + ".json", { collapsed: true }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	isSortable() {
		if (this.props.column.id === "title") {
			return true;
		}
		const column = BoardHelper.getColumn(this.props.column);
		const sortableColumns = ["title", "board_number", "person", "created_at", "datetime", "timeline", "number", "text", "timetrack"];
		return sortableColumns.includes(column.type);
	}

	toggleColumnAddPersonModal() {
		this.setState((c) => ({
			showColumnAddPersonModal: !c.showColumnAddPersonModal,
		}));
	}

	closeColumnAddPersonModal() {
		this.setState({ showColumnAddPersonModal: false });
	}

	clearAllPermittedUsers() {
		this.setState({
			permittedUserIds: [],
		});

		const data = {
			permitted_user_ids: [],
		};

		API.put(`/api/boards/${this.props.board.id}/columns/${this.props.column.id}.json`, data, {
			params: {},
		})
			.then(() => {
				this.setState({
					permittedUserIds: [],
				});
			})
			.catch((e) => console.error("addPersonToColumn e:", e));
	}

	addPersonToColumn(user) {
		const userId = parseInt(user.id);

		this.setState((c) => {
			const newUsers = (() => {
				if (this.state.permittedUserIds.includes(userId)) {
					if (this.state.permittedUserIds.length !== 1 && parseInt(userId) === store.getState().user.id) {
						toastr.error(this.props.t("column.header.errors.cant_remove_self", "Kan inte ta bort dig själv medans andra användare är tillagd."));
						return c.permittedUserIds;
					}
					return c.permittedUserIds.filter((v) => parseInt(v) !== userId);
				}

				return Array.from(new Set(c.permittedUserIds.concat([userId, store.getState().user.id])));
			})();

			const data = {
				permitted_user_ids: newUsers,
			};

			API.put(`/api/boards/${this.props.board.id}/columns/${this.props.column.id}.json`, data, {
				params: {},
			}).catch((e) => console.error("addPersonToColumn e:", e));

			return { permittedUserIds: newUsers };
		});
	}

	toggleContactColumnHidePerson() {
		if (Array.isArray(this.props.column.options)) {
			this.props.column.options = {
				hide_person: !this.props.column.options?.hide_person,
			};
		} else {
			this.props.column.options.hide_person = !this.props.column.options?.hide_person;
		}
		BoardHelper.updateColumnOptions(this.props.column, this.props.column.options);
	}

	render() {
		const actions = [];

		if (this.props.column && this.props.column.type == "timeline") {
			if (this.props.column.options && !this.props.column.options.integration) {
				actions.push({
					content: this.props.t("column.header.actions.sync_calendar_add", "Synka till kalender"),
					icon: CalendarMajor,
					onAction: () => {
						this.props.openCalendarModal();
						this.setState({ showContext: false });
					},
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.sync_calendar_remove", "Koppla bort synkad kalender"),
					icon: CalendarMajor,
					onAction: () => {
						this.disconnectSyncedCalendar();
						this.setState({ showContext: false });
					},
				});
			}
		}
		if (this.props.column && (this.props.column.type == "timeline" || this.props.column.type == "datetime")) {
			if (!this.props.column.connected_column_id) {
				actions.push({
					content: this.props.t("column.header.actions.deadline_add", "Sätt som deadline"),
					icon: CircleAlertMajor,
					onAction: () => {
						this.setState({ showDeadlineModal: true, showContext: false });
					},
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.deadline_remove", "Ta bort som deadline"),
					icon: CircleAlertMajor,
					onAction: this.removeDeadlineMode.bind(this),
				});
			}
		}
		if (this.props.column && this.props.column.type == "company_number") {
			if (!this.props.column.connected_column_id) {
				actions.push({
					content: this.props.t("column.header.actions.auto_contact_connection", "Automatiskt kontaktkoppling"),
					icon: CustomerPlusMajor,
					onAction: () => {
						this.setState({
							showCompanyContactModal: true,
							showContext: false,
						});
					},
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.auto_contact_connection_remove", "Ta bort kontaktkoppling"),
					icon: CustomerMinusMajor,
					onAction: this.removeAutoContact.bind(this),
				});
			}
		}
		if (this.props.column && this.props.column.type == "formula") {
			actions.push({
				content: this.props.t("column.header.actions.formula_change", "Ändra formel"),
				icon: WandMajor,
				onAction: () => {
					this.setState({ showFormulaModal: true, showContext: false });
				},
			});

			if (this.props.board?.id === store.getState().account.sales_board_id) {
				actions.push({
					content: this.props.column.link_column_id
						? this.props.t("column.header.actions.formula_person_column", `Kopplad kolumn: "{{title}}"`, {
								title: this.props.board.columns.find((c) => c.id == this.props.column.link_column_id)?.title,
						  })
						: this.props.t("column.header.actions.formula_set_person_column", "Välj personkolumn"),

					icon: PeopleIcon,
					onAction: () => {
						this.setState({ showConnectPersonColumn: true, showContext: false });
					},
				});
			}
		}
		if (this.props.column && this.props.column.type == "rowlinkfrom") {
			if (!this.props.column.options.show_deadlines) {
				actions.push({
					content: this.props.t("column.header.actions.enable_deadline_mode", "Visa deadlines"),
					icon: CircleAlertMajor,
					onAction: this.enableDeadlineModeFromRow.bind(this),
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.disable_deadline_mode", "Visa radnamn"),
					icon: CircleAlertMajor,
					onAction: this.disableDeadlineModeFromRow.bind(this),
				});
			}
		}
		if (this.props.column && this.props.column.type == "upload") {
			if (this.props.column.options && !this.props.column.options.integration) {
				actions.push({
					content: this.props.t("column.header.actions.integration_add", "Lägg till integration"),
					icon: WandMajor,
					onAction: () => {
						this.setState({
							showUploadIntegrationModal: true,
							showContext: false,
						});
					},
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.integration_remove", "Ta bort integration"),
					icon: WandMajor,
					onAction: () => {
						this.removeUploadIntegration();
					},
				});
			}
		}
		if (this.props.column && this.props.column.type == "person") {
			if (this.props.column.options.single) {
				actions.push({
					content: this.props.t("column.header.actions.person_allow_multipul", "Tillåt flera"),
					icon: CustomerPlusMajor,
					onAction: this.allowMultiplePerson.bind(this),
				});
			} else {
				actions.push({
					content: this.props.t("column.header.actions.person_allow_one", "Begränsa till en"),
					icon: CustomerMinusMajor,
					onAction: this.onlyOnePerson.bind(this),
				});
			}
		}
		if (this.props.column && this.props.column.type == "button") {
			actions.push({
				content: this.props.t("column.header.actions.rules_change", "Ändra regler"),
				icon: DnsSettingsMajor,
				onAction: () => {
					this.setState({
						showRowlinkConstraintsModal: true,
						showContext: false,
					});
				},
			});

			actions.push({
				content: this.props.t("column.header.actions.show_connections", "Visa kopplingar"),
				icon: ConnectMinor,
				onAction: () => {
					this.setState({
						showRowlinkConnectionModal: true,
						showContext: false,
					});
				},
			});
		}
		if (this.props.column && this.props.column.type == "contact") {
			actions.push({
				content: this.props.column.options?.hide_person
					? this.props.t("column.header.actions.show_person_and_company", "Visa både person och företag")
					: this.props.t("column.header.actions.show_only_company", "Visa endast företag"),
				icon: this.props.column.options?.hide_person ? PeopleIcon : HouseIcon,
				onAction: () => {
					this.toggleContactColumnHidePerson();
					this.setState({ showContext: false });
				},
			});
		}

		if (this.isSortable()) {
			actions.push({
				content: (
					<ColumnHeaderSortButton
						showText
						inline
						column_id={this.props.column.id}
						column_type={this.props.column.type}
						sorting={this.props.sorting}
						onChangeSorting={this.props.onChangeSorting}
						onClick={() => this.setState({ showContext: false })}
					/>
				),
				icon: SortMinor,
				// onAction: this.toggleColumnAddPersonModal.bind(this),
			});
		}
		actions.push({
			content: this.props.t("column.header.actions.allowed_users", "Tillåtna användare"),
			icon: ProfileMajor,
			onAction: this.toggleColumnAddPersonModal.bind(this),
		});

		actions.push({
			content: this.props.t("column.header.actions.column_remove", "Ta bort kolumn"),
			destructive: true,
			icon: CircleCancelMajor,
			onAction: this.removeColumn.bind(this),
		});

		return (
			<div
				className={
					"column-header" +
					(this.props.column
						? " hover-bg column-" +
						  this.props.column.id +
						  (this.state.focused ? " focused" : "") +
						  (this.state.showContext || this.state.saving ? " active" : "") +
						  (this.props.draggingColumn && this.props.draggingColumn.id == this.props.column.id ? " dragging" : "")
						: "")
				}
				style={
					this.props.column
						? {
								display: "flex",
								flexBasis: this.props.column.width,
								minWidth: this.props.column.width,
								maxWidth: this.props.column.width,
						  }
						: null
				}
			>
				<div style={{ width: "100%" }}>
					<Stack spacing="none" wrap={false}>
						{!this.props.draggingColumn && !this.state.editingTitle ? (
							<div className={this.props.column ? "board-display-hover" : "board-show-hover"}>
								<span
									onClick={(event) => {
										event.stopPropagation();
									}}
								>
									<Stack spacing="extraTight" wrap={false}>
										{!this.props.column ? (
											<a onClick={this.onCollapse.bind(this)} style={{ cursor: "pointer" }}>
												<Icon source={MinimizeMajor} color="inkLighter" />
											</a>
										) : null}
										{this.props.column ? (
											<a onMouseDown={this.props.onDragStart} style={{ cursor: "move" }}>
												<Icon source={DragHandleMinor} color="inkLighter" />
											</a>
										) : (
											// eslint-disable-next-line react/jsx-props-no-spreading
											<a {...this.props.dragHandleProps} style={{ cursor: "move" }}>
												<Icon source={DragHandleMinor} color="inkLighter" />
											</a>
										)}
									</Stack>
								</span>
							</div>
						) : null}
						{this.props.column && this.props.column.type == "mirror" ? (
							<div>
								<Icon source={LinkMinor} />
							</div>
						) : null}
						{this.props.column && this.props.column.type == "formula" ? (
							<div>
								<Icon source={WandMajor} />
							</div>
						) : null}
						{this.props.column && this.props.column.type == "timeline" && this.props.column.options.integration ? (
							<Tooltip
								content={
									this.props.t("column.header.text.column_connected_to", "Denna kolumn är kopplad till") +
									" " +
									this.props.column.options.integration.type
								}
							>
								<Icon source={CalendarMajor} />
							</Tooltip>
						) : null}
						<Stack.Item fill>
							{!this.props.column ? (
								this.state.editingTitle ? (
									<div
										onClick={(event) => {
											event.stopPropagation();
										}}
									>
										<div style={{ border: "1px dashed #777" }}>
											<input
												ref={(input) => {
													if (input) {
														input.focus();
													}
												}}
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														this.changeGroupTitle();
													} else if (event.key === "Escape") {
														this.setState({
															title: this.props.group.title,
															editingTitle: false,
														});
													}
												}}
												onChange={(event) => {
													this.setState({ title: event.target.value });
												}}
												onBlur={this.changeGroupTitle.bind(this)}
												type="text"
												value={this.state.title}
												style={{
													fontSize: "1.6rem",
													fontWeight: 600,
													display: "block",
													color: this.props.group.color,
													width: "100%",
													border: 0,
												}}
											/>
										</div>
									</div>
								) : (
									<div
										onClick={() => {
											this.setState({ editingTitle: true });
										}}
									>
										<span
											className="overflow-ellipsis group-title"
											style={{
												width: "100%",
												cursor: "text",
												color: this.props.group.color,
											}}
										>
											{this.state.title}
										</span>
									</div>
								)
							) : (
								<div style={{ height: 25 }}>
									<CellText
										onChange={(value) => {
											this.setState({ title: value.value }, this.changeColumnTitle.bind(this));
										}}
										onFocus={this.focusCell.bind(this)}
										onBlur={this.blurCell.bind(this)}
										value={this.state.title}
										noclear
									/>
								</div>
							)}
						</Stack.Item>
						{!this.props.draggingColumn && this.props.column ? (
							<div className="board-display-hover" style={{ position: "relative" }}>
								{this.state.saving ? (
									<Spinner size="small" />
								) : (
									<Popover
										active={this.state.showContext}
										fixed
										activator={
											<a
												onClick={() => {
													this.setState({
														showContext: !this.state.showContext,
													});
												}}
												style={{ cursor: "pointer" }}
											>
												<Icon source={DropdownMinor} color="inkLighter" />
											</a>
										}
										onClose={() => {
											this.setState({ showContext: false });
										}}
									>
										<ActionList items={actions} />
									</Popover>
								)}
							</div>
						) : null}
					</Stack>
					{!this.props.draggingColumn && !this.state.focused && !this.state.editingTitle ? (
						<a alt="" className="resize-handle" onMouseDown={this.props.onResizeStart} />
					) : null}
					{this.isSortable() && (
						<ColumnHeaderSortButton
							column_id={this.props.column.id}
							column_type={this.props.column.type}
							sorting={this.props.sorting}
							onChangeSorting={this.props.onChangeSorting}
						/>
					)}
				</div>
				{this.props.column && this.props.column.type == "button" ? (
					<RowlinkConstraintsModal
						open={this.state.showRowlinkConstraintsModal}
						onClose={() => {
							this.setState({ showRowlinkConstraintsModal: false });
						}}
						onOpen={() => {
							this.setState({ showRowlinkConstraintsModal: true });
						}}
						onUpdateColumn={this.props.onUpdateColumn}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}
				{this.props.column && this.props.column.type == "rowlink" ? (
					<RowlinkConnectionModal
						open={this.state.showRowlinkConnectionModal}
						onClose={() => {
							this.setState({ showRowlinkConnectionModal: false });
						}}
						onOpen={() => {
							this.setState({ showRowlinkConnectionModal: true });
						}}
						onUpdateColumn={this.props.onUpdateColumn}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}
				{this.props.column && (this.props.column.type == "timeline" || this.props.column.type == "datetime") ? (
					<DeadlineModal
						open={this.state.showDeadlineModal}
						onClose={() => {
							this.setState({ showDeadlineModal: false });
						}}
						onOpen={() => {
							this.setState({ showDeadlineModal: false });
						}}
						onUpdateColumn={(column) => {
							this.setState({ showDeadlineModal: false });
							this.props.onUpdateColumn(column);
						}}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}

				{this.props.column && this.props.column.type == "company_number" ? (
					<CompanyContactModal
						open={this.state.showCompanyContactModal}
						onClose={() => {
							this.setState({ showCompanyContactModal: false });
						}}
						onOpen={() => {
							this.setState({ showCompanyContactModal: false });
						}}
						onUpdateColumn={(column) => {
							this.setState({ showCompanyContactModal: false });
							this.props.onUpdateColumn(column);
						}}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}

				{this.props.column && this.props.column.type == "formula" && this.props.board?.id === store.getState().account.sales_board_id && (
					<PersonColumnModal
						open={this.state.showConnectPersonColumn}
						onClose={() => {
							this.setState({ showConnectPersonColumn: false });
						}}
						onOpen={() => {
							this.setState({ showConnectPersonColumn: false });
						}}
						onUpdateColumn={(column) => {
							this.setState({ showConnectPersonColumn: false });
							this.props.onUpdateColumn(column);
						}}
						column={this.props.column}
						board={this.props.board}
					/>
				)}

				{this.props.column && this.props.column.type == "formula" ? (
					<FormulaModal
						open={this.state.showFormulaModal}
						onClose={() => {
							this.setState({ showFormulaModal: false });
						}}
						onOpen={() => {
							this.setState({ showFormulaModal: false });
						}}
						onUpdateColumn={(column) => {
							this.setState({ showFormulaModal: false });
							this.props.onUpdateColumn(column);
						}}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}

				{this.props.column && this.props.column.type == "upload" ? (
					<UploadIntegrationModal
						open={this.state.showUploadIntegrationModal}
						onClose={() => {
							this.setState({ showUploadIntegrationModal: false });
						}}
						onOpen={() => {
							this.setState({ showUploadIntegrationModal: false });
						}}
						onUpdateColumn={(column) => {
							this.setState({ showUploadIntegrationModal: false });
							this.props.onUpdateColumn(column);
						}}
						column={this.props.column}
						board={this.props.board}
					/>
				) : null}

				<Modal
					open={this.state.showColumnAddPersonModal}
					onClose={this.closeColumnAddPersonModal.bind(this)}
					title={this.props.t("column.header.modals.allowed_users.title", "Tillåtna användare")}
					primaryAction={{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.closeColumnAddPersonModal.bind(this),
					}}
					secondaryActions={[
						{
							content: this.props.t("column.header.actions.remove_all", "Ta bort alla"),
							onAction: this.clearAllPermittedUsers.bind(this),
							destructive: true,
						},
					]}
				>
					<Modal.Section>
						<div style={{ position: "relative" }}>
							<span style={{ position: "absolute", left: 7, top: 10 }}>
								<Icon source={SearchMinor} color="inkLighter" />
							</span>
							<input
								onKeyDown={(event) => {
									if (event.key === "Escape") {
										this.setState({ query: "" }, this.props.onBlur);
									}
								}}
								className="bgInput"
								style={{ paddingLeft: 30 }}
								placeholder={this.props.t("column.header.placeholder", "Sök person...")}
								type="text"
								value={this.state.query}
								onChange={(event) => {
									this.setState({ query: event.target.value.toLowerCase() });
								}}
							/>
						</div>
						<ActionList
							items={[]
								.concat(store.getState().users)
								.concat(this.props.board.members)
								.filter(
									(item, index, self) =>
										((item && item.enabled) || (item && item.user && item.user.enabled)) &&
										index ===
											self.findIndex(
												(t) =>
													parseInt(t.user_id || (t.user && t.user.id) || t.id) === parseInt(item.user_id || (item.user && item.user.id) || item.id)
											) &&
										(!this.state.query ||
											(item.name && item.name.toLowerCase().includes(this.state.query)) ||
											(item.email && item.email.toLowerCase().includes(this.state.query)))
								)
								.map((user) => ({
									content: (
										<div className="personItem">
											<Stack alignment="center">
												<MemberAvatar member={user} active={this.state.permittedUserIds && this.state.permittedUserIds.includes(user.user_id)} />
												<Stack vertical spacing="none">
													<div>{user.fullname}</div>
													<TextStyle variation="subdued">{user.email}</TextStyle>
												</Stack>
											</Stack>
										</div>
									),
									onAction: this.addPersonToColumn.bind(this, user),
								}))}
						/>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["column", "common"], { withRef: true })(ColumnHeader);
