import { Icon } from "@shopify/polaris";
import React, { Component } from "react";
import NumberBadge from "../../../components/NumberBadge";
import Colors from "../../../Colors";

class ContactTabsBar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const tabs = this.props.tabs;
		return (
			<div className="contact_tabs_bar">
				{tabs.map((tab, index) => {
					const { title, icon, id, count } = tab;
					return (
						<div key={index} className={`contact_tab ${this.props.activeTabId === id ? " active" : ""}`} onClick={() => this.props.onChange(id)}>
							<div>
								<div className="contact_tab__icon">
									<div style={{ position: "relative" }}>
										<div
											className={`contact_tab__count${!count ? " disabled" : ""}`}
											style={{ visibility: !("count" in tab) ? "hidden" : "visible" }}
										>
											<NumberBadge color={count && count !== "-" ? Colors.green : "rgb(150,150,150)"}>{count || 0}</NumberBadge>
										</div>
										{React.isValidElement(icon) ? icon : <Icon source={icon} />}
									</div>
								</div>

								<h3>{title}</h3>
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}
export default ContactTabsBar;
