import styled, { css } from "styled-components";

const Overlay = styled.div`
	position: absolute;
	inset: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Wrapper = styled.div<{
	loading: boolean;
}>`
	transition: opacity 250ms;
	height: 100%;
	position: relative;

	table {
		border-collapse: collapse;

		width: calc(100% - 40px);
		margin-left: 40px;

		thead {
			tr {
				background-color: var(--main1);
			}
			th {
				padding: 3px;

				.Polaris-Select {
					width: max-content;
					margin-left: -15px;
				}

				&:first-of-type {
					width: 75px;
				}
			}
		}
		th,
		td {
			text-align: end;

			&:first-of-type {
				text-align: start;
			}
		}
	}

	${(props) =>
		props.loading &&
		css`
			opacity: 0.5;
		`}
`;

const TableRow = styled.tr`
	border-radius: 64px;
	position: relative;
	font-size: 14px;
	color: #ffffff;

	td {
		z-index: 1;
		position: relative;
	}

	&::before {
		inset: 0;
		left: -40px;
		/* transform: translateX(100%); */
		right: unset;
		content: attr(data-position);
		position: absolute;
		z-index: 1;
		opacity: 0.5;

		align-items: center;
		display: flex;

		/* height: min-content;
		transform: translateY(50%); */
	}

	&::after {
		background: linear-gradient(99.71deg, #171d21 -9.63%, #8c7968 155.37%);
		inset: 0;
		content: "";
		position: absolute;
		border-radius: 64px;
		z-index: 0;
	}

	&[data-index="0"]::after {
		background: linear-gradient(108deg, #20282e -7.66%, #dcbe6f 150.09%);
	}
	&[data-index="1"]::after {
		background: linear-gradient(100.52deg, #20282e -17.86%, #d2d2cf 172.63%);
	}
	&[data-index="2"]::after {
		background: linear-gradient(99.71deg, #20282e -9.63%, #ccb29c 155.37%);
	}

	/* &::after {
		content: attr(data-position);
		position: absolute;
		left: -15px;
		height: 100%;
		display: flex;
		align-items: center;
	} */

	// td:first-child,
	td:first-of-type {
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
	}
	// td:last-child,
	td:last-of-type {
		border-top-right-radius: 25px;
		border-bottom-right-radius: 25px;
		padding-right: 2rem;
	}

	/* td:first-of-type {
		width: 75px;
	} */
`;

const Spacer = styled.tr`
	height: 5px;
`;

export { Wrapper, TableRow, Spacer, Overlay };
