import React, { useEffect } from "react";
import { Wrapper } from "./Styles";
import Sheet from "src/js/components/sheet";
import { WithTranslation, withTranslation } from "react-i18next";
import { store } from "src/js/store";

import SalesStats from "./Sections/SalesStats";
import { toastr } from "src/js/components/toastr";
import API from "src/js/API";

type BoardSettingsProps = {
	open: boolean;
	onClose: () => void;
	board: BoardType;
} & WithTranslation;

const BoardSettings = ({ open, onClose, board, t }: BoardSettingsProps) => {
	const [options, setOptions] = React.useState(board.options || {});
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setOptions(board.options || {});
	}, [board.options]);

	const handleSave = async () => {
		store.dispatch({
			type: "UPDATE_BOARD",
			board: {
				...board,
				options,
			},
		});

		setLoading(true);

		try {
			const result = await API.put(`/api/boards/${board.id}.json`, {
				options,
			});

			store.dispatch({
				type: "UPDATE_BOARD",
				board: result.data.board,
			});

			toastr.success(t("boards.response.settings.saved", "Inställningarna har sparats"));

			onClose();
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
		} finally {
			setLoading(false);
		}
	};

	const handleChange = () => {
		setOptions({ ...options });
	};

	return (
		<Sheet
			open={open}
			onClose={onClose}
			title={t("board.settings.title", "Inställningar")}
			primaryAction={{ content: t("common.actions.save", "Spara") as string, onAction: handleSave, loading }}
		>
			<Wrapper>
				{board.id === store.getState().account.sales_board_id && <SalesStats board={board} options={options} onChange={handleChange} />}
			</Wrapper>
		</Sheet>
	);
};
export default withTranslation(["board", "common"])(BoardSettings);
