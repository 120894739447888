import { Icon } from "@shopify/polaris";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../icons/Home";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import IntersectionObserverElement from "./IntersectionObserverElement";

class MainMenuSection extends Component {
	constructor(props) {
		super(props);
		this.state = { search: "" };
		this.ref = React.createRef();
		this.secondaryRef = React.createRef();
	}

	getTriggerIcon() {
		const { icon } = this.props;
		if (!icon) return <HomeIcon />;
		if (React.isValidElement(icon)) return icon;
	}

	getIcon(icon) {
		if (!icon) return null;
		if (React.isValidElement(icon)) return icon;
		return <Icon source={icon} />;
	}

	onClick(url, onClick, closeOnClick) {
		if (onClick) onClick();
		if (closeOnClick && this.props.toggleMenu) {
			this.props.toggleMenu();
		}
		if (url) this.props.history.push(url);
		// requestAnimationFrame(() => {
		// 	this.setState({ rnd: Math.random() });
		// });
	}

	onSearchChange(e) {
		this.setState({ search: e });
	}

	render() {
		const { items, title, actions, children, id, secondaryItemsStyle } = this.props;

		return (
			<div ref={this.ref} id={id} className={`mainmenu-item ${this.props.className || ""}`}>
				{this.props.url ? (
					<Link to={this.props.url} className="mainmenu-item-trigger" data-content={title} onClick={this.props.onClick} style={{}}>
						{this.getTriggerIcon()}
						<span className="mainmenu-item-trigger_title">{title}</span>
					</Link>
				) : (
					<span className="mainmenu-item-trigger" data-content={title} onClick={this.props.onClick} style={{}}>
						{this.getTriggerIcon()}
						<span className="mainmenu-item-trigger_title">{title}</span>
					</span>
				)}
				<div ref={this.secondaryRef} className="mainmenu-item__secondary noscroll">
					<div className="mainmenu-item__secondary__header">
						<span className="mainmenu-item__secondary__header__title">{title}</span>
						<div className="mainmenu-item__secondary__actions" />
					</div>
					{(items || (actions && !!actions.length)) && (
						<MainMenuSectionSearch onChange={this.onSearchChange.bind(this)} value={this.state.search} actions={actions} />
					)}
					<div className="mainmenu-item__secondary__items" style={secondaryItemsStyle}>
						{children}
						{items &&
							items
								?.filter((item) => item.label?.toLowerCase()?.includes((this.state.search || "")?.toLowerCase()))
								.map((item, index) => {
									const { label, icon, url, activeUrl, onClick, closeOnClick } = item;
									const className =
										(activeUrl || url) && this.props.location && this.props.location.pathname.indexOf(activeUrl || url) >= 0 ? "active" : "";

									return (
										<div
											className={`mainmenu-item__secondary__item ${className}`}
											onClick={this.onClick.bind(this, url, onClick, closeOnClick)}
											key={index}
										>
											<div>
												{this.getIcon(icon)}
												{label}
											</div>
										</div>
									);
								})}
						<IntersectionObserverElement
							parent={this.secondaryRef.current}
							hideShadow
							onChange={(intersecting) => {
								// const shadow = this.ref.current.closest("bottom-scroll-shadow");
								const shadow = document.querySelector(`#${this.props.id}-shadow`);
								if (shadow) {
									if (intersecting) {
										shadow.classList.remove("visible");
										shadow.classList.add("invisible");
									} else if (this.ref.current.classList.contains("active")) {
										shadow.classList.remove("invisible");
										shadow.classList.add("visible");
									}
								}
							}}
							shadowStyle={{ width: this.ref.current && this.ref.current.clientWidth }}
						/>
					</div>
				</div>
				<div id={`${this.props.id}-shadow`} className="bottom-scroll-shadow" />
			</div>
		);
	}
}

export default MainMenuSection;
