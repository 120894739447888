import { Button, FormLayout, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr";

class BoardTermEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			singular: this.props.board && this.props.board.singular,
			plural: this.props.board && this.props.board.plural,
		};
	}

	onSave() {
		if (!this.props.board) {
			toastr.error(this.props.t("board.terms.erorrs.no_board", "Finns ingen board att spara mot"));
			return;
		}
		this.setState({ saving: true });
		this.props.board.singular = this.state.singular;
		this.props.board.plural = this.state.plural;
		this.props.onUpdateBoard(this.props.board);
		const params = { singular: this.state.singular, plural: this.state.plural };
		API.put("/api/boards/" + this.props.board.id + ".json", params, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				this.props.onUpdateBoard(result.data.board);
				this.setState({ saving: false });
				if (this.props.onClose) this.props.onClose();
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	handleChange(field, value) {
		this.setState({ [field]: value });
	}

	render() {
		return (
			<FormLayout>
				<TextField
					label={this.props.t("common.terms.singular", "Ental")}
					value={this.state.singular}
					onChange={this.handleChange.bind(this, "singular")}
				/>
				<TextField
					label={this.props.t("common.terms.plural", "Flertal")}
					value={this.state.plural}
					onChange={this.handleChange.bind(this, "plural")}
				/>
				<Button loading={this.state.saving} primary onClick={this.onSave.bind(this)}>
					{this.props.t("common.actions.save", "Spara")}
				</Button>
			</FormLayout>
		);
	}
}
export default withTranslation(["board", "commob"], { withRef: true })(BoardTermEdit);
