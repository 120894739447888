import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import SkipIcon from "../../icons/SkipIcon";

class YoutubeVideoAdPreview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { backgroundImageUrl, channel, title = "", profileImageUrl } = this.props;
		return (
			<Wrapper backgroundImageUrl={backgroundImageUrl}>
				<BottomWrapper>
					<LeftWrapper>
						<ProfileImage src={profileImageUrl} />
						<TextWrapper>
							<Title>{title}</Title>
							{channel && (
								<p>
									{this.props.t("common.terms.by", "by")} {channel}
								</p>
							)}{" "}
						</TextWrapper>
					</LeftWrapper>
					<SkipAddButton>
						{this.props.t("ads.youtube_preview.skip", "Skip Ad")} <SkipIcon />
					</SkipAddButton>
				</BottomWrapper>
			</Wrapper>
		);
	}
}
export default withTranslation(["ads", "common"], { withRef: true })(YoutubeVideoAdPreview);

const Wrapper = styled.div.attrs({ className: "asd" })`
	background-image: ${({ backgroundImageUrl }) => `url(${backgroundImageUrl})`};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	aspect-ratio: 16/9;
	position: relative;
`;

const BottomWrapper = styled.div`
	display: flex;
	padding: 3rem;
	justify-content: space-between;
	color: #ffffff;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.19) 29.17%,
		rgba(0, 0, 0, 0.39) 53.12%,
		rgba(0, 0, 0, 0.62) 72.4%,
		#000000 100%
	);
`;
const LeftWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
`;
const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const SkipAddButton = styled.div`
	padding: 1rem 1.5rem;
	color: black;
	background: #ffffff;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	font-size: 12px;
	border-radius: 0.5rem;
	height: max-content;

	svg {
		height: 16px;
	}
`;

const ProfileImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
	background-color: #ffffff;
	border-radius: 0.5rem;
`;

const Title = styled.p`
	font-weight: 700;
	font-size: 16px;
`;
