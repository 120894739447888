import React, { Component } from "react";
import { Labelled, Stack, Button, ActionList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import Popover from "./Popover.js";

class AdAccountSelector extends Component {
	constructor(props) {
		super(props);
		this.state = { category: props.ad_account };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ category: props.ad_account });
	}

	onChange(category) {
		if (!category) {
			this.props.onChange(category);
			this.setState({ category, categoryPopoverActive: false });
		} else if (!category.id) {
			this.setState({ categoryPopoverActive: true });
		} else {
			this.setState({ category, categoryPopoverActive: false });
			this.props.onChange(category);
		}
	}

	toggleCategoryPopover() {
		this.setState({ categoryPopoverActive: !this.state.categoryPopoverActive });
	}

	getTypeTitle(type) {
		return <span>({type})</span>;
	}

	render() {
		const options = [];

		if (this.state.category && !this.props.required) {
			options.push({ content: this.props.t("ad.account.none", "Inget annonskonto"), onAction: this.onChange.bind(this, null) });
		}

		for (let i = 0; i < store.getState().ad_accounts.length; i++) {
			const category = store.getState().ad_accounts[i];
			options.push({
				content: (
					<span>
						{this.getTypeTitle(category.type)} {category.title}
					</span>
				),
				onAction: this.onChange.bind(this, category),
			});
		}
		const button = (
			<Button disabled={this.props.disabled} plain onClick={this.toggleCategoryPopover.bind(this)}>
				{this.state.category ? (
					<span>
						{this.getTypeTitle(this.state.category.type)} {this.state.category.title}
					</span>
				) : (
					this.props.t("ad.account.none", "Inget annonskonto")
				)}
			</Button>
		);
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<Stack>
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						{this.props.disabled ? (
							<span>
								{this.getTypeTitle(this.state.category && this.state.category.type)} {this.state.category && this.state.category.title}
							</span>
						) : (
							<Popover active={this.state.categoryPopoverActive} activator={button} onClose={this.toggleCategoryPopover.bind(this)}>
								<ActionList items={options} />
							</Popover>
						)}
					</span>
				</Stack>
			</Labelled>
		);
	}
}
export default withTranslation(["ad", "common"], { withRef: true })(AdAccountSelector);
