import { Button, Icon, TextField, Collapsible } from "@shopify/polaris";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { MaximizeMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../../API";
import NewPopup from "../../../../components/NewPopup";
import { ClockIcon } from "../../../../icons";
import Colors from "../../../../Colors";
import { msToHMS } from "../../../../Utilities";
import ProfileAvatar from "../../../../components/ProfileAvatar";
import { store } from "../../../../store";
import CalendarButton from "./CalendarButton";
import BoardHelper from "../../BoardHelper";
import CalendarHelper from "./CalendarHelper";
import RowSheet from "../RowSheet";
import { toastr } from "../../../../components/toastr";

class CalendarPopupPreview extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false, loading: false };
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	open(row, column, board, color, domElement) {
		const value = row && row.column_values[column.id] && row.column_values[column.id].value;
		const showResponseButtons = !CalendarHelper.haveResponded(value);

		// eslint-disable-next-line react/no-unused-state
		this.setState({ row, column, board, color, value, showResponseButtons, responseOpen: false, responseComment: null }, () => {
			this.popup.open(domElement);
		});
	}

	onClose() {
		if (this.popup && this.popup.close) {
			this.setState({ responseOpen: false, respondAction: null, responseComment: null, showResponseButtons: false });
			this.popup.close(true);
		}
	}

	onCloseSheet() {
		this.setState({ open: false, responseOpen: false, showResponseButtons: false, respondAction: null });
	}

	onChangeState(field, value) {
		this.setState({ [field]: value });
	}

	respondToEmail(response) {
		this.setState({ loading: true });

		API.put(`/api/boards/${this.state.board.id}/rows/${this.state.row.id}/columns/${this.state.column.id}/invitation_response.json`, {
			response,
			comment: this.state.responseComment,
		})
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				toastr.success(
					`${this.props.t("calendar.invitation", "Inbjudan")} ${this.props.t(
						`Calendar.response.${CalendarHelper.getInlineStatusLabel2(response)}`,
						CalendarHelper.getInlineStatusLabel2(response)
					)}`
				);

				if (this.props.onComplete) this.props.onComplete();
				this.onClose();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => this.setState({ loading: false }));
	}

	openRespond(respondAction) {
		this.setState({ responseOpen: true, respondAction });
	}

	closeRespond() {
		this.setState({ responseOpen: false, respondAction: null });
	}

	haveContent(str) {
		if (!str) return false;
		const div = document.createElement("div");
		div.innerHTML = str;
		const text = (div.textContent || div.innerText).trim();
		return text.length > 0;
	}

	onUpdateRow(row) {
		BoardHelper.updateRows([row], "update");
	}

	render() {
		const value = this.state.value;
		const duration = value && value.start && value.end && moment.duration(moment(value.end).diff(moment(value.start)));

		return (
			<NewPopup
				ref={(r) => {
					this.popup = r;
				}}
				onClose={() => this.closeRespond.bind(this)}
			>
				{this.state.row && (
					<CalendarEventPreview>
						<StyledMaximizeBtn onClick={() => this.setState({ open: true }, this.onClose.bind(this))}>
							<Icon source={MaximizeMinor} />
						</StyledMaximizeBtn>
						<Field style={{ marginBottom: 5 }}>
							<FirstColumn>{/* <StatusCircle color={this.state.color} /> */}</FirstColumn>
							<Title>{this.state.row.title}</Title>
						</Field>
						<div>
							<Field style={{ padding: "0.1rem 0" }}>
								<FirstColumn />
								<p>
									{value.start}{" "}
									{moment(value.start).format("YYYY-MM-DD") == moment(value.end).format("YYYY-MM-DD") && (
										<span>
											{" "}
											- {moment(value.end).format("HH:mm")}{" "}
											<span style={{ opacity: 0.7, marginLeft: 15 }}>
												{" "}
												<ClockIcon /> {duration && msToHMS(duration)}
											</span>
										</span>
									)}
								</p>
								{/* <p>{moment(value.start).calendar()}</p> */}
							</Field>

							{moment(value.start).format("YYYY-MM-DD") != moment(value.end).format("YYYY-MM-DD") && (
								<Field style={{ padding: "0.1rem 0" }}>
									<FirstColumn />
									<p>{value.end}</p>
								</Field>
							)}
						</div>
						{moment(value.start).format("YYYY-MM-DD") != moment(value.end).format("YYYY-MM-DD") && (
							<Field>
								<FirstColumn>
									<ClockIcon />
								</FirstColumn>
								<p>{duration && msToHMS(duration)}</p>
								{/* <p>{moment.duration(moment(value.start).diff(moment(value.end)))}</p> */}
							</Field>
						)}
						<Line />
						{CalendarHelper.getTeamsMeetingUrl(value) && (
							<React.Fragment>
								<Field>
									<FirstColumn>
										<img alt="teams" src="/assets/images/teams.png" />
									</FirstColumn>
									<PreviewButtonAsLink href={CalendarHelper.getTeamsMeetingUrl(value)} target="_blank">
										{this.props.t("calendar.actions.teams.connect", "Anslut till Teams möte")}
									</PreviewButtonAsLink>
								</Field>
								<Line />
							</React.Fragment>
						)}
						{CalendarHelper.getGoogleHangoutUrl(value) && (
							<React.Fragment>
								<Field>
									<FirstColumn>
										<img style={{ width: 25 }} alt="google_hangout" src={value?.external_event?.conferenceData?.conferenceSolution?.iconUri} />
									</FirstColumn>
									<div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
										{value?.external_event?.conferenceData?.entryPoints?.map((entry, index) => {
											return (
												<PreviewButtonAsLink key={index} href={entry.url} target="_blank" style={{ maxWidth: "max-content" }} color={Colors.green}>
													{this.props.t("calendar.fields.goole_hangout.connect", "Anslut till Google möte {{with_type}}", {
														with_type:
															entry.entryPointType === "phone"
																? this.props.t("calendar.fields.goole_hangout.with_phone", "med telefon")
																: entry.entryPointType === "video"
																? this.props.t("calendar.fields.goole_hangout.with_video", "med video")
																: "",
													})}
												</PreviewButtonAsLink>
											);
										})}
									</div>
								</Field>
								<Line />
							</React.Fragment>
						)}
						{this.haveContent(CalendarHelper.getContent(value.content)) && (
							<Field>
								<FirstColumn />
								{CalendarHelper.getContent(value.content)?.indexOf("<") >= 0 ? (
									<Content dangerouslySetInnerHTML={{ __html: CalendarHelper.getContent(value.content) }} />
								) : (
									<Content>{CalendarHelper.getContent(value.content)} </Content>
								)}
							</Field>
						)}
						{CalendarHelper.isMeeting(value) && (
							<React.Fragment>
								<Field style={{ paddingBottom: 0 }}>
									<FirstColumn>
										<ProfileAvatar
											size={25}
											user={(() => {
												const organizer = CalendarHelper.getOrganizer(value);
												const foundUser = store.getState().users.find((user) => user.email === (organizer && organizer.email));

												return (
													foundUser || {
														email: organizer && organizer.email,
														name: organizer && organizer.name,
													}
												);
											})()}
										/>
									</FirstColumn>
									{value.external_event ? (
										<div>
											<b>
												{CalendarHelper.isOrganizer(value)
													? this.props.t("calendar.outlook.status.iam", "Du är organisatören.")
													: CalendarHelper.getOrganizer(value) &&
													  `${CalendarHelper.getOrganizer(value).name} ${this.props.t("calendar.response.invted", "bjöd in dig.")}`}
											</b>
										</div>
									) : null}
								</Field>
								<Field>
									<FirstColumn />
									<p>
										{CalendarHelper.getAttendeesByStatus(value).map((group, index, cur) => {
											return (
												<span key={index}>
													{group.attendees.map((att) => att.name || att.email || (typeof att === "string" ? att : "")).join(", ")}{" "}
													{this.props.t("common.terms.have", "har")} {CalendarHelper.getInlineStatusLabel(group.status)}
													{index < cur.length - 1 ? ", " : ""}
												</span>
											);
										})}
									</p>
								</Field>
							</React.Fragment>
						)}

						<Collapsible open={this.state.showResponseButtons && this.state.responseOpen}>
							<div>
								<TextField
									multiline={3}
									placeholder="Valfri text.."
									onChange={this.onChangeState.bind(this, "responseComment")}
									value={this.state.responseComment}
								/>
								<div style={{ display: "flex", gap: "1rem", padding: "1rem 0" }}>
									<Button primary loading={this.state.loading} onClick={this.respondToEmail.bind(this, this.state.respondAction)}>
										{this.props.t("Send", "Skicka")}
									</Button>
									<Button onClick={this.closeRespond.bind(this)}>{this.props.t("common.actions.cancel", "Avbryt")}</Button>
								</div>
							</div>
						</Collapsible>
						{CalendarHelper.isRepliable(value) && (
							<Collapsible open={this.state.showResponseButtons && !this.state.responseOpen}>
								<Field>
									<FirstColumn />
									<RespondWrapper className="calendar_modal__body__answer_buttons_wrapper">
										<CalendarButton
											label={this.props.t("calendar.actions.accept", "Acceptera")}
											// icon={MobileAcceptMajor}
											className={`accept ${CalendarHelper.getMyResponse(value) === "accepted" ? "active" : ""}`}
											onClick={this.openRespond.bind(this, "accept")}
										/>
										{
											<CalendarButton
												label={this.props.t("calendar.actions.tentative", "Preliminärt")}
												// icon={QuestionMarkInverseMajor}
												className={`pre ${CalendarHelper.getMyResponse(value) === "tentativelyAccepted" ? "active" : ""}`}
												onClick={this.openRespond.bind(this, "maybe")}
											/>
										}

										<CalendarButton
											label={this.props.t("calendar.actions.decline", "Tacka nej")}
											// icon={MobileCancelMajor}
											className={`decline ${CalendarHelper.getMyResponse(value) === "declined" ? "active" : ""}`}
											onClick={this.openRespond.bind(this, "decline")}
										/>
									</RespondWrapper>
								</Field>
							</Collapsible>
						)}
						{CalendarHelper.isRepliable(value) && CalendarHelper.haveResponded(value) && !this.state.showResponseButtons && (
							<Field>
								<FirstColumn />
								<span style={{ marginRight: 15 }}>
									{this.props.t("calendar.response.string", "Du har {{responseStatus}}", {
										responseStatus: CalendarHelper.getInlineStatusLabel(CalendarHelper.getMyResponse(value)),
									})}
								</span>
								<PreviewButton onClick={() => this.setState({ showResponseButtons: true })}>
									{this.props.t("calendar.actions.change_answer", "Ändra ditt svar")}
								</PreviewButton>
							</Field>
						)}
						{!CalendarHelper.isRepliable(value) && (
							<Field>
								<FirstColumn />
								<PreviewButton onClick={() => this.setState({ open: true }, this.onClose.bind(this))}>
									{this.props.t("common.actions.edit", "Redigera")}
								</PreviewButton>
							</Field>
						)}
					</CalendarEventPreview>
				)}
				<RowSheet
					open={this.state.open}
					row={this.state.row}
					board={this.state.board}
					initialTabIndex={0}
					onUpdateRow={this.onUpdateRow.bind(this)}
					onClose={this.onCloseSheet.bind(this)}
					onComplete={this.props.onComplete}
				/>
			</NewPopup>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(CalendarPopupPreview);

const CalendarEventPreview = styled.div`
	position: relative;
	padding: 1rem;

	/* padding-left: 0; */
	display: flex;
	flex-direction: column;
	/* gap: 1rem; */
	font-size: 0.9em;
`;

const Title = styled.h1`
	font-weight: 600;
	&&&& {
		font-size: 1.1em;
	}

	word-break: break-word;
	width: calc(100% - 60px);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	line-clamp: 3;
	-webkit-box-orient: vertical;
`;

const FirstColumn = styled.div`
	display: flex;
	justify-content: center;
	width: 50px;
	min-width: 50px;
	margin-left: -1rem;
`;

const Field = styled.div`
	display: flex;
	align-items: center;
	padding: 0.35rem 0;
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgb(80, 80, 80);
	margin: 0.35rem 0;
`;

const PreviewButtonStyled = css`
	padding: 0.25rem 1rem;
	background-color: ${({ color = Colors.blue }) => color};
	color: #ffffff !important;
	width: max-content;
	cursor: pointer;
	border-radius: 4px;
	transition: filter 250ms;

	&:hover {
		filter: brightness(1.2);
	}
`;

export const PreviewButtonAsLink = styled.a`
	${PreviewButtonStyled}
	text-decoration:none!important;
`;
const PreviewButton = styled.div`
	${PreviewButtonStyled}
`;

const Content = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	/* -webkit-line-clamp: 5;
	line-clamp: 3; */
	-webkit-box-orient: vertical;

	max-height: 250px;
	overflow: hidden;

	* {
		color: var(--textColor) !important;
	}
`;

const RespondWrapper = styled.div`
	button {
		font-size: 1.1rem;
		padding: 5px 10px;
	}

	justify-content: space-between;
`;

const StyledMaximizeBtn = styled.div`
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
	transition: scale 500ms;

	&:hover {
		scale: 1.1;
	}
`;
