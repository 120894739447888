import React, { Component } from "react";
import { Modal, FormLayout, Stack, Tag } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

import SearchField from "./search_field.js";

class TagModal extends Component {
	constructor(props) {
		super(props);
		this.state = { tags: [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.open && this.state.tags && this.state.tags.length > 0) {
			this.setState({ tags: [] });
		}
	}

	addTag(tag) {
		const found = this.state.tags.find((t) => t.title.toLowerCase() === tag.title.toLowerCase());
		if (!found) {
			this.state.tags.push(tag);
			this.setState({ tags: this.state.tags });
		}
	}

	removeTag(title) {
		const newTags = this.state.tags.filter((t) => t.title.toLowerCase() != title.toLowerCase());
		this.state.tags = newTags;
		this.setState({ tags: this.state.tags });
	}

	confirm() {
		this.props.onConfirm(this.state.tags);
	}

	render() {
		let title;
		if (this.props.remove) {
			title = this.props.t("modals.tags.title_remove_tags_from_count_object", "Ta bort taggar från {{ count }} {{objectName }}", {
				count: this.props.numItems,
				objectName: this.props.numItems === 1 ? this.props.resourceName.singular : this.props.resourceName.plural,
			});
		} else {
			title = this.props.t("modals.tags.title_add_tags_to_count_object", "Lägg till taggar på {{ count }} {{objectName }}", {
				count: this.props.numItems,
				objectName: this.props.numItems === 1 ? this.props.resourceName.singular : this.props.resourceName.plural,
			});
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={title}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					destructive: this.props.remove,
					content: this.props.remove
						? this.props.t("modals.tags.buttons.remove_tags", " Ta bort taggar")
						: this.props.t("modals.tags.buttons.add_tags", " Lägg till taggar"),
					disabled: this.state.tags.length < 1,
					loading: this.props.loading,
					onAction: this.confirm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						<SearchField
							label={this.props.t("modals.tags.tags", "Taggar")}
							resource={this.props.resource + "/tags"}
							onSelect={this.addTag.bind(this)}
							resourceName={{ singular: this.props.t("modals.tags.singular", "tagg"), plural: this.props.t("modals.tags.plural", "taggar") }}
							resource_handle="tags"
							id_handle="title"
							label_handle="title"
							tags
						/>
					</FormLayout>
					<div style={{ paddingTop: 10 }}>
						<Stack>
							{this.state.tags.map((tag, index) => (
								<Tag key={index} onRemove={this.removeTag.bind(this, tag.title)}>
									{tag.title}
								</Tag>
							))}
						</Stack>
					</div>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(TagModal);
