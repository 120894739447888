import React, { Component } from "react";
import { Spinner } from "@shopify/polaris";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import ResourcePicker from "./resource_picker.js";

class TagSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	addTag(tag) {
		this.props.onCreate(tag);
	}

	render() {
		if (this.props.loading) {
			return <Spinner size="small" />;
		}
		return (
			<ResourcePicker
				plain
				tags={store.getState().user.roles.includes("ROLE_CREATE_TAG")}
				resource="contacts/tags"
				caption={this.props.caption === undefined ? this.props.t("tags.caption", "Lägg till tagg") : this.props.caption || ""}
				onChange={this.addTag.bind(this)}
				resourceName={{ singular: this.props.t("tags.singular", "tagg"), plural: this.props.t("tags.plural", "taggar") }}
				resource_handle="tags"
				id_handle="title"
				label_handle="title"
				icon={CirclePlusMajor}
				params={{
					type: this.props.contact.is_company !== null && this.props.contact.is_company ? "company" : "person",
					group_by: "title",
					sort: "TITLE_ASC",
				}}
			/>
		);
	}
}
export default withTranslation(["tags", "common"], { withRef: true })(TagSelector);
