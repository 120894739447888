import { DropZone, Image, InlineError } from "@shopify/polaris";
import React, { useCallback, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../modal";
import TextField from "../TextField";
import API from "src/js/API";
import { toastr } from "../toastr";

type ImageUploadProps = {
	source: string | null;
	button?: boolean;
	size?: { width?: number | string; height?: number | string };
	style?: any;
	dropOnPage?: boolean;
	disabled?: boolean;
	options?: {
		title: boolean;
	};
	onUpload?: (file: any) => void;
} & WithTranslation;

const ImageUpload = ({ source, button = true, size, style = null, dropOnPage = false, disabled, options, t, onUpload }: ImageUploadProps) => {
	const [open, setOpen] = useState(false);
	const [file, setFile] = useState<any>(null);
	const [error, setError] = useState(null);

	const handleDropZoneDrop = useCallback((dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
		const file = acceptedFiles[0];

		const reader = new FileReader();
		reader.onload = handleAddFile.bind(null, file);
		reader.readAsDataURL(file);
	}, []);

	const handleAddFile = (file: File, event) => {
		const data = event.target.result;
		setFile({ name: file.name, type: file.type, size: file.size, data });
		setOpen(true);
	};

	const handleChange = (field: string) => (value: any) => {
		setFile((f) => {
			const newFile = { ...f };
			newFile[field] = value;

			return newFile;
		});
	};

	const handleSave = async () => {
		setError(null);

		try {
			const result = await API.post("/api/uploads.json", { files: [file] });

			// toastr.success(t("common.upload.responses.uploaded_singel", "Laddade upp fil"));

			await onUpload?.(result.data.uploads[0]);
			setOpen(false);
		} catch (error) {
			setError(toastr.error(error));
		}
	};

	const handleCancel = () => {
		setFile(null);
		setOpen(false);
	};

	const getZone = (source) => (
		<DropZone
			// openFileDialog={open}
			// onClick={file ? () => setOpen(true) : undefined}
			dropOnPage={dropOnPage}
			onDrop={handleDropZoneDrop}
			type="image"
			disabled={disabled}
			allowMultiple={false}
		>
			{source && <Image source={source} alt="Bild" />}
			{!source && (
				<DropZone.FileUpload
					actionTitle={t("common.upload.actions.choose", "Välj fil") as string}
					actionHint={t("common.upload.actions.helptext", "Eller dra och släpp en fil") as string}
				/>
			)}
		</DropZone>
	);

	return (
		<Wrapper size={size} disabled={disabled}>
			{getZone(source)}

			<Modal
				large
				titleHidden
				title=""
				open={open}
				onClose={() => {
					setOpen(false);
					setError(null);
				}}
				primaryAction={{
					content: t("common.save", "Spara") as string,
					onAction: handleSave,
				}}
				secondaryActions={[
					{
						content: t("common.cancel", "Avbryt") as string,
						onAction: handleCancel,
					},
				]}
			>
				{error && <InlineError fieldID={"error"} message={error} />}
				<ImageWrapper>{getZone(file?.data || source)}</ImageWrapper>

				{options?.title && <TextField label={t("imageUpload.fields.name", "Namn")} value={file?.name} onChange={handleChange("name")} />}
			</Modal>
		</Wrapper>
	);
};
export default withTranslation(["imageUpload"])(ImageUpload);

const ImageWrapper = styled.div`
	img {
		width: 100%;
	}
`;

const Wrapper = styled.div<{ size?: { width?: number | string; height?: number | string }; disabled?: boolean }>`
	transition: background-color 0.3s, opacity 0.3s;

	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	.Polaris-DropZone {
		transition: background-color 0.3s, opacity 0.3s;
		background-color: var(--main3);
	}

	/* .Polaris-DropZone--isDisabled {
		opacity: 0.5;
	} */

	img {
		width: ${(props) => props.size?.width || "unset"};
		height: ${(props) => props.size?.height || "unset"};
		border-radius: var(--p-border-radius-base);
	}
`;
