import React, { Component } from "react";
import { List, Modal, FormLayout, Stack, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class MissingColumnConfirmationModal extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	getMappingOptions(column) {
		const arr = [{ label: this.props.t("column.fields.remove.label", "-- ta bort värden --"), value: "" }];
		for (let i = 0; i < this.props.board?.columns?.length; i++) {
			if (
				this.props.board.columns[i].id + "" == column.new_id ||
				(this.props.board.columns[i].type == column.type &&
					!(this.props.mapped_columns && this.props.mapped_columns.find((col) => col.newColumn.id == this.props.board.columns[i].id)) &&
					!this.props.columns.find((col) => col.new_id == this.props.board.columns[i].id + ""))
			) {
				arr.push({ label: this.props.board.columns[i].title, value: this.props.board.columns[i].id + "" });
			}
		}
		arr.push({ label: this.props.t("column.actions.create_new", "Skapa ny kolumn"), value: "create" });
		return arr;
	}

	updateMapping(index, value) {
		this.props.columns[index].new_id = value;
		this.props.onChange(this.props.columns);
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("common.terms.move_to", "Flytta till") + " " + this.props.board.title}
				primaryAction={{
					content: this.props.t("common.actions.continue", "Fortsätt"),
					loading: this.props.loading,
					onAction: this.props.onConfirm,
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.cancel", "Avbryt"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<div>
							{this.props.t("column.missing.following_missing_in", "Följande kolumner saknas i")}
							{this.props.board.title}
						</div>
						<div>
							{this.props.t(
								"column.missing.text",
								"Du måste skapa eller koppla dessa kolumner på den nya boarden innan flytt, annars kommer informationen i dessa kolumner att förloras"
							)}
						</div>
						<List type="bullet">
							{this.props.columns.map((column, index) => (
								<List.Item key={column.id}>
									<Stack>
										<Stack.Item fill>{column.title}</Stack.Item>
										<div style={{ minWidth: 200 }}>
											<Select options={this.getMappingOptions(column)} value={column.new_id} onChange={this.updateMapping.bind(this, index)} />
										</div>
									</Stack>
								</List.Item>
							))}
						</List>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["column", "common"], { withRef: true })(MissingColumnConfirmationModal);
