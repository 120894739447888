import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import OrderContext, { OrderContextProvider } from "../OrderContext";
import Page from "src/js/components/page";
import OrderHeader from "./Header";
import OrderOverview from "./Overview";
import DeliveryOverview from "./DeliveryOverview";
import CustomerOverview from "./CustomerOverview";
import copyOrder from "../Utilities/copyOrder";
import { toastr } from "src/js/components/toastr";
import { DuplicateMinor } from "@shopify/polaris-icons";

const EditOrder = ({ history }) => {
	const { form, order, id, handleSave, isLoading, t } = useContext(OrderContext);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "overview",
					content: t("tagets.tabs.overview", "Grunduppgifter"),
					render: <OrderOverview />,
				},
				{
					id: "customer",
					content: t("tagets.tabs.customer", "Kunduppgifter"),
					render: <CustomerOverview />,
				},
				{
					id: "delivery",
					content: t("tagets.tabs.delivery", "Leveransuppgifter"),
					render: <DeliveryOverview />,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[t]
	);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as OrderType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "overview";
	}, [tabs]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};
	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());

	const tab = tabs.find((tab) => tab.id === tabID);
	const title = id
		? t("orders.edit.title", "Redigera order: #{{order}}", { order: order?.order_number })
		: t("orders.create.title", "Skapa ny order");

	return (
		<Page
			title={title}
			history={history}
			primaryAction={{
				content: id ? t("common.save", "Spara") : t("common.create", "Skapa"),

				onClick: handleSaveForm,
				disabled: isLoading,
			}}
			secondaryActions={[
				{
					content: t("orders.actions.copy.label", "Kopiera order") as string,
					onAction: () => {
						history.push("/admin/orders/create", { data: copyOrder(form) });
						toastr.info(t("orders.actions.copy.message", "Order kopierad. Kom ihåg att spara."), "Order kopierad");
					},
					icon: DuplicateMinor,
					enabled: !!form?.id,
				},
			].filter((a) => !("enabled" in a) || a.enabled)}
		>
			<OrderHeader tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["order", "common"])((props) => {
	return (
		<OrderContextProvider {...props}>
			<EditOrder {...props} />
		</OrderContextProvider>
	);
});
