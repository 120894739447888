import React, { Component } from "react";
import { Icon, Button, ActionList } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import Popover from "../../components/Popover";

class CellCategory extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", value: props.value };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value });
		}
	}

	setCategory(category) {
		if (this.state.value != category.id) {
			this.state.value = category.id;
			this.props.onChange({ value: this.state.value });
			this.setState({ value: this.state.value });
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	createCategory() {
		if (!this.state.query || this.state.saving) {
			return;
		}
		this.setState({ saving: true });
		API.post("/api/boards/" + this.props.column.board_id + "/categories.json", { title: this.state.query }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });
				store.dispatch({
					type: "ADD_BOARD_CATEGORY",
					category: result.data.category,
				});
				this.setCategory(result.data.category);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
		this.setState({ active: false, query: "" }, this.props.onBlur);
	}

	getAllCategories() {
		const categories = [];
		// const filterRegex = new RegExp(this.state.query, "i");
		if (store.getState().boards[this.props.board.id].categories?.length) {
			for (let i = 0; i < store.getState().boards[this.props.board.id].categories?.length; i++) {
				categories.push({
					content: store.getState().boards[this.props.board.id].categories[i].title,
					onAction: this.setCategory.bind(this, store.getState().boards[this.props.board.id].categories[i]),
				});
			}
		}
		return categories;
	}

	getCategory(categoryId) {
		const board = store.getState().boards[this.props.board.id];
		if (board && board.categories?.length) {
			for (let i = 0; i < board.categories.length; i++) {
				if (board.categories[i].id == categoryId) {
					return board.categories[i];
				}
			}
			return null;
		}
		return null;
	}

	render() {
		return (
			<Popover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						className={this.state.active ? "expandedCell" : ""}
						style={{
							height: this.state.active ? "auto" : this.props.height,
							minHeight: this.props.height,
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						{this.getCategory(this.state.value) ? this.getCategory(this.state.value).title : null}
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ width: 250, position: "relative" }}>
					<span style={{ position: "absolute", left: 7, top: 10 }}>
						<Icon source={SearchMinor} color="inkLighter" />
					</span>
					<input
						ref={(input) => {
							this.input = input;
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								this.createCategory();
							} else if (event.key === "Escape") {
								this.setState({ active: false, query: "" }, this.props.onBlur);
							}
						}}
						style={{ paddingLeft: 30 }}
						className="bgInput"
						placeholder={this.props.t("cell.category.fields.search.placeholder", "Sök kategori...")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value });
						}}
					/>
				</div>
				{this.state.active ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<ActionList items={this.getAllCategories()} />
					</div>
				) : null}
				{this.state.query ? (
					<div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
						<Button fullWidth loading={this.state.saving} disabled={!this.state.query} primary onClick={this.createCategory.bind(this)}>
							{this.props.t("cell.category.action.create", "Skapa ny kategori")}
						</Button>
					</div>
				) : null}
			</Popover>
		);
	}
}
export default withTranslation(["board"], { withRef: true })(CellCategory);
