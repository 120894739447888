import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Button, Icon, Spinner, Stack, Tooltip } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { MobileCancelMajor, NoteMajor } from "@shopify/polaris-icons";
import Popover from "src/js/components/Popover";
import moment from "moment";
import NewDatePicker from "src/js/components/NewDatePicker";
import BoardHelper from "../Workspaces/BoardHelper";

const WeeklyTasksBulkActions = ({ t, rows: propsRows, refresh, setSelection, selection }) => {
	// const { selection, setSelection, refresh, currentWeek } = useContext(WeeklyTasksContext);
	const [loadings, setLoadings] = useState({ today: false, tomorrow: false, custom_date: false });
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [date, setDate] = useState<string | null>(null);

	const setLoading = (id: string, loading: boolean) => setLoadings((c) => ({ ...c, [id]: loading }));
	const rows = (propsRows || []).filter((row: DeadlineBoardRowType) => {
		return selection.includes(row.key);
	});

	const colors = rows.map((row: DeadlineBoardRowType) => row.color);
	const groupedColors = colors.reduce((acc, color) => Object.assign(acc, { [color]: (acc[color] || 0) + 1 }), {});
	const mostUsedColor = Object.keys(groupedColors).sort((a, b) => groupedColors[b] - groupedColors[a])?.[0];

	const setRowsDate = (id: string, date: string | null) => {
		return async () => {
			setLoading(id, true);
			const rowsGroupedByDateColumn = rows?.reduce((acc, row: DeadlineBoardRowType) => {
				const column = row.deadline.dateColumn;
				if (!acc[column?.id]) {
					acc[column?.id] = {
						column,
						rows: [],
						data: {
							value: column?.type === "datetime" ? { datetime: date } : { start: date, end: date },
						},
					};
				}

				acc[column?.id].rows.push(row);
				return acc;
			}, {});

			const rowsGroupedByStatusdColumnAndComplete = rows?.reduce((acc, row: DeadlineBoardRowType) => {
				const column = row.deadline?.statusColumn;
				if (!column || row.deadline?.status?.color !== "#00c875") return acc;

				if (!acc[column?.id]) {
					acc[column?.id] = {
						column,
						rows: [],
						data: {
							value: null,
						},
					};
				}

				acc[column?.id].rows.push(row);
				return acc;
			}, {});

			try {
				await Promise.allSettled([
					...Object.values(rowsGroupedByDateColumn).map(async ({ column, rows, data }: any) => {
						const res = await BoardHelper.onUpdateMultipleRowsValue(rows, column, data);
						return res;
					}),
				]);

				await Promise.allSettled([
					...Object.values(rowsGroupedByStatusdColumnAndComplete).map(async ({ column, rows, data }: any) => {
						const res = await BoardHelper.onUpdateMultipleRowsValue(rows, column, data);
						return res;
					}),
				]);

				setLoading(id, false);
				setSelection([]);
				setDate(null);
				setShowDatePicker(false);
				refresh();
			} catch (err) {
				console.error("err:", err);
				setLoading(id, false);
			}
		};
	};

	const anyLoading = Object.values(loadings).some((l) => l);

	return (
		<Wrapper className="weekly_bulk_actions" open={!!selection?.length}>
			<InnerWrapper>
				<Stack spacing="none">
					<Count color={mostUsedColor}>{selection.length}</Count>
					<Stack.Item fill>
						<div style={{ paddingBottom: "5px" }}>
							<Stack vertical spacing="tight">
								<div style={{ paddingTop: 15, paddingLeft: 20, fontSize: 18 }}>
									{selection.length == 1 ? t("dashboard.todo.row_marked", "Rad markerad") : t("dashboard.todo.rows_marked", "Rader markerad")}
								</div>
								<div style={{ paddingLeft: 20 }}>
									<Stack spacing="extraTight">
										{rows
											.sort((a, b) => {
												if (a.position < b.position) return -1;
												if (a.position > b.position) return 1;
												if (a.position == b.position) return 0;
												return 0;
											})
											.map((dot, index) => (
												<div key={index + ""} style={{ width: 10, height: 10, borderRadius: 5, backgroundColor: dot.color }} />
											))}
									</Stack>
								</div>
							</Stack>
						</div>
					</Stack.Item>
					<div>
						<Stack>
							<ActionButton
								id="today"
								onClick={setRowsDate("today", moment().format("YYYY-MM-DD"))}
								loading={loadings.today}
								icon={<Icon source={NoteMajor} />}
								text={t("dashboard.terms.today", "Idag")}
								disabled={anyLoading}
								date={moment().format("YYYY-MM-DD")}
							/>
							<ActionButton
								id="tomorrow"
								onClick={setRowsDate("tomorrow", moment().add(1, "day").format("YYYY-MM-DD"))}
								loading={loadings.tomorrow}
								icon={<Icon source={NoteMajor} />}
								text={t("dashboard.terms.tomorrow", "Imorgon")}
								disabled={anyLoading}
								date={moment().add(1, "day").format("YYYY-MM-DD")}
							/>

							<Popover
								active={showDatePicker && selection?.length}
								activator={
									<ActionButton
										id="custom_date"
										onClick={() => setShowDatePicker((c) => !c)}
										loading={loadings.custom_date}
										icon={<Icon source={NoteMajor} />}
										text={t("dashboard.terms.custom_date", "Eget datum")}
										disabled={anyLoading}
										date={date ? moment(date).format("YYYY-MM-DD") : "[date]"}
									/>
								}
								onClose={() => {
									setShowDatePicker(false);
									setDate(null);
								}}
								sectioned
							>
								<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<NewDatePicker
										value={date}
										onChange={(v) => {
											setDate(v);
										}}
									/>

									<Button fullWidth loading={loadings.custom_date} onClick={setRowsDate("custom_date", date)} disabled={!date} primary>
										{t("common.actions.save", "Spara")}
									</Button>
								</div>
							</Popover>
						</Stack>
					</div>
					<ActionCancelButton
						onClick={() => {
							setSelection([]);
						}}
						disabled={anyLoading}
					>
						<Icon source={MobileCancelMajor} />
					</ActionCancelButton>
				</Stack>
			</InnerWrapper>
		</Wrapper>
	);
};
export default withTranslation(["dashboard", "common"], { withRef: true })(WeeklyTasksBulkActions);

const ActionButton = withTranslation(["agenda", "common"], { withRef: true })(({ loading, icon, text, onClick, disabled, id, t, date }) => {
	return (
		<Tooltip
			content={t(`agenda.weekly.actions.${id}`, "Ändra datum till {{date}} och nollställ status om den är klarmarkerad", { date })}
			preferredPosition="above"
		>
			<StyledActionButton onClick={onClick} disabled={disabled}>
				{loading ? <Spinner size="small" /> : icon}
				{text}
			</StyledActionButton>
		</Tooltip>
	);
});

const InnerWrapper = styled.div`
	pointer-events: auto;
	max-width: 700px;
	margin: 0 auto;
	background-color: rgb(44, 50, 79);
	min-height: 70px;
	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	position: relative;
`;

const Wrapper = styled.div<{ open?: boolean }>`
	pointer-events: none;
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	z-index: 9999999;
	min-height: 100px;
	transition: 0.1s ease;
	margin-bottom: -120px;
	opacity: 0;

	${({ open }) =>
		open &&
		css`
			margin-bottom: 20px;
			opacity: 1;
		`}
`;

const Count = styled.div`
	font-size: 30px;
	color: #fff;
	width: 70px;
	text-align: center;
	border-radius: 5px 0px 0px 5px;
	background-color: #0085ff;
	min-height: 70px;
	line-height: 70px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ color }) => color || "#0085ff"};
`;
const StyledActionButton = styled.button<{ disabled?: boolean }>`
	cursor: pointer;
	height: 70px;
	color: var(--textColor);
	background-color: transparent;
	border: none;
	transition: 0.1s ease;
	pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 10px 10px;

	.Polaris-Icon {
		margin-bottom: 6px;
	}

	&:hover {
		background-color: rgb(53, 60, 95);
	}
`;
const ActionCancelButton = styled.div<{ disabled?: boolean }>`
	cursor: pointer;
	// display: block;
	padding: 25px;
	border-left: 1px solid #ccc;
	height: 100%;
	display: flex;
	color: var(--textColor);
	pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	> .Polaris-Stack > .Polaris-Stack__Item:last-child {
		position: absolute;
		right: 0;
	}

	&:hover {
		background-color: rgb(53, 60, 95);
	}
`;

// .bulkActionButton,
// .bulkCancel {
// 	color: $textColor;

// 	&:hover {
// 		background-color: $bgFocusColor;
// 	}
// }
