import React, { useContext } from "react";
import { OverviewWrapper } from "../styles";
import TextField from "src/js/components/TextField";
import { Card, FormLayout, Layout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import OrderContext from "../OrderContext";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";

type OrderTypeDeliveryKeys = keyof OrderType["delivery_fields"];
type OrderTypeDellveryMutableKeys = Exclude<OrderTypeDeliveryKeys, "readonly">;

const DeliveryOverview = ({ t }) => {
	const { form, setForm, disabled } = useContext(OrderContext);

	const getChangeHandler = (field: OrderTypeDellveryMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<OrderType>) => {
			const newForm = { ...c };
			if (!newForm.delivery_fields) newForm.delivery_fields = {};
			newForm.delivery_fields[field as string] = value;

			return newForm;
		});
	};

	return (
		<Wrapper>
			<Card sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<TextField
								disabled={disabled}
								label={t("orders.delivery.fields.name.label", "Namn")}
								value={form?.delivery_fields?.name}
								onChange={getChangeHandler("name")}
							/>
							<TextField
								type="date"
								disabled={disabled}
								label={t("orders.delivery.fields.delivery_date.label", "Leveransdatum")}
								value={form?.delivery_fields?.delivery_date}
								onChange={getChangeHandler("delivery_date")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.delivery.fields.address.label", "Levaransadress")}
								value={form?.delivery_fields?.address}
								onChange={getChangeHandler("address")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.delivery.fields.address2.label", "Levaransadress 2")}
								value={form?.delivery_fields?.address2}
								onChange={getChangeHandler("address2")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.delivery.fields.postal_code.label", "Postnummer")}
								value={form?.delivery_fields?.postal_code}
								onChange={getChangeHandler("postal_code")}
							/>
							<TextField
								disabled={disabled}
								label={t("orders.delivery.fields.city.label", "Stad")}
								value={form?.delivery_fields?.city}
								onChange={getChangeHandler("city")}
							/>
							<Select
								disabled={disabled}
								label={t("orders.delivery.fields.country.label", "Land")}
								options={[{ label: "Välj land", value: "" }, ...store.getState().countries.map((c) => ({ label: c.label, value: c.label }))]}
								value={form?.delivery_fields?.country}
								onChange={getChangeHandler("country")}
							/>
							<Select
								disabled={disabled}
								label={t("orders.delivery.fields.delivery_type.label", "Leveranssätt")}
								options={[
									{ label: "Välj leveranssätt", value: "" },
									{
										label: "Hämtas",
										value: "pickup",
									},
									{
										label: "Levereras",
										value: "delivery",
									},
								]}
								value={form?.delivery_fields?.delivery_type}
								onChange={getChangeHandler("delivery_type")}
							/>
						</FormLayout>
					</Layout.Section>
				</Layout>
			</Card>
		</Wrapper>
	);
};

export default withTranslation(["orders", "common"])(DeliveryOverview);

const Wrapper = styled(OverviewWrapper)`
	&&&& {
		.info-view__labels,
		.info-view__values {
			row-gap: 3px;
		}
	}
`;
