import React, { Component } from "react";
import { Modal, Badge, Avatar, ResourceList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import BigSearch from "../../components/big_search.js";
import UserInviter from "../../components/UserInviter.js";

class CustomerIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItems: [],
		};
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	renderItem(item) {
		const url = "/admin/users/" + item.id;
		const media = <Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />;

		return (
			<ResourceList.Item id={item.id} media={media} url={url}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.name}</h3>
						<span className="CustomerListItem__Location">
							{item.group ? item.group.name : ""}
							{!item.enabled ? <Badge>{this.props.t("user.statuses.disabled", "avstängd")}</Badge> : null}
						</span>
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				<div />
				<div className="darkBadge" />
			</ResourceList.Item>
		);
	}

	updateParams(params) {
		this.setState({});
	}

	render() {
		const filters = [
			{
				key: "role",
				label: this.props.t("user.filters.package.label", "Användarpaket"),
				type: "select",
				operatorText: "har",
				options: [
					{ value: "ROLE_MARKETING", label: this.props.t("user.roles.marketing", "Marknadsföring") },
					{ value: "ROLE_SUPPORT", label: this.props.t("user.roles.support", "Servicedesk") },
					{ value: "ROLE_SALES", label: this.props.t("user.roles.sales", "Försäljning") },
					{ value: "ROLE_PROJECT", label: this.props.t("user.roles.project", "Projekthantering") },
					{ value: "ROLE_ADMIN", label: this.props.t("user.roles.admin", "Administratör") },
				],
				shortcut: true,
			},
			{
				key: "enabled",
				label: this.props.t("user.filters.disabled.label", "avstängd"),
				type: "select",
				options: [
					{ value: "0", label: this.props.t("common.terms.yes", "Ja") },
					{ value: "1", label: this.props.t("common.terms.no", "Nej") },
				],
				shortcut: true,
			},
		];

		const promotedBulkActions = [];

		const bulkActions = [];

		const sortOptions = [];

		return (
			<div>
				<BigSearch
					small
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title={this.props.t("user.title", "Användare")}
					primaryAction={{
						content: this.props.t("user.actions.invite", "Bjud in användare"),
						onAction: () => {
							this.setState({ showInviteModal: true });
						},
					}}
					savedSearchHandle="users"
					resourceUrl="/api/users.json"
					resourceHandle="users"
					resourceName={{
						singular: this.props.t("user.singular", "användare"),
						plural: this.props.t("user.plural", "användare"),
					}}
					onUpdateParams={this.updateParams.bind(this)}
					renderItem={this.renderItem.bind(this)}
					selectedItems={this.state.selectedItems}
					onSelectionChange={this.onSelectionChange.bind(this)}
					filters={filters}
					sortOptions={sortOptions}
					promotedBulkActions={promotedBulkActions}
					bulkActions={bulkActions}
				/>
				<Modal
					open={this.state.showInviteModal}
					onClose={() => {
						this.setState({ showInviteModal: false });
					}}
					title={this.props.t("user.actions.invite", "Bjud in användare")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showInviteModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<UserInviter
							onSave={() => {
								this.setState({ showInviteModal: false });
								this.refresh();
							}}
						/>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}

export default withTranslation(["user", "common"], { withRef: true })(CustomerIndex);
