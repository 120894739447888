import { Button } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { store } from "../../store";
import ProfileAvatar from "../../components/ProfileAvatar";

interface LicenseUserItemProps {
	user: UserType;
	onRemove?: () => void;
}
type LicenseUserItemState = any;

class LicenseUserItem extends Component<LicenseUserItemProps, LicenseUserItemState> {
	constructor(props: LicenseUserItemProps) {
		super(props);
		this.state = {};
	}

	render() {
		const { user, onRemove } = this.props;
		return (
			<StyledWrapper key={user.id}>
				<StyledInnerWrapper>
					<ProfileAvatar size={30} user={store.getState().users.find((usr: UserType) => String(usr.id) === String(user.id))} />
					<StyledTitle>{user?.name}</StyledTitle>
					{onRemove && (
						<span className="remove remove_user">
							<Button plain destructive icon={CircleCancelMajor} onClick={onRemove} />
						</span>
					)}
				</StyledInnerWrapper>
			</StyledWrapper>
		);
	}
}

const mapStateToProps = (state: any) => ({
	users: state.users,
});

export default connect(mapStateToProps)(LicenseUserItem);

const StyledWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	.Polaris-Avatar {
		background-color: rgba(255, 255, 255, 0.1);

		text {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	.remove_user {
		transition: opacity 250ms;
		opacity: 0;
	}

	&:hover {
		.remove,
		.remove_user {
			opacity: 1;
		}
	}
`;
const StyledInnerWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const StyledTitle = styled.p`
	font-size: 14px;
	font-weight: 700;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
`;
