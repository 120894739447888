import { FormLayout, Modal, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr";

class UserGoalModal extends Component {
	constructor(props) {
		super(props);
		this.state = { form: props.goal };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (props.open) {
				this.fetchItem(props.goal && props.goal.id);
				this.setState({ form: props.goal });
			}
		}
	}

	fetchItem(id) {
		API.get("/api/goals/" + id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateUser(field, value) {
		this.state.form.users.find((user) => user.user_id == this.props.user.id)[field] = value;
		this.setState({ form: this.state.form });
	}

	saveForm() {
		if (!this.state.form || !this.state.form.id) {
			toastr.error(this.props.t("goals.errors.no_id", "Inget målsättnings id"));
			return;
		}
		this.setState({ saving: true });
		API.put("/api/goals/" + this.state.form.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false, form: result.data.goal });
				if (this.props.onCreate) this.props.onCreate(result.data.goal);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		if (!this.state.form) return null;
		const user =
			this.state.form &&
			Array.isArray(this.state.form.users) &&
			this.state.form.users.find((u) => u.user_id == (this.props.user && (this.props.user.user_id || this.props.user.id)));
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("goals.titles.change", "Ändra målsättning")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					onAction: this.saveForm.bind(this),
					loading: this.state.saving,
				}}
			>
				<Modal.Section>
					{user && (
						<FormLayout>
							<TextField
								label={`${this.state.form.start_at} - ${this.state.form.end_at}`}
								value={user.value + ""}
								onChange={this.updateUser.bind(this, "value")}
								suffix={this.state.form.suffix}
							/>
							<TextField
								label={`${this.state.form.next_start_at || ""} - ${this.state.form.next_end_at || ""}`}
								type="numeric"
								value={(user.next_value || 0) + ""}
								onChange={this.updateUser.bind(this, "next_value")}
								suffix={this.state.form.suffix}
								// helpText={
								// 	"Ange ett målvärde " +
								// 	(this.state.form.interval != "period"
								// 		? this.getIntervalTitle(this.state.form.interval)
								// 		: "för hela perioden (" + this.getWeekdays(this.state.from, this.state.to) + " arbetsdagar)")
								// }
							/>
							{/* <Checkbox label="Skicka daglig rapport" checked={user.mail_daily_report} onChange={this.updateUser.bind(this, "mail_daily_report")} /> */}
						</FormLayout>
					)}
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["goals", "common"], { withRef: true })(UserGoalModal);
