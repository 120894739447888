import { Label } from "@shopify/polaris";
import React from "react";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Popover from "../Popover";
import styled from "styled-components";
import Button from "../Button";
import { WithTranslation, withTranslation } from "react-i18next";

type ColorPickerProps = {
	value?: string;
	onChange: (color: string) => void;
	label?: string;
	activator?: React.ReactElement;
	activatorStyle?: React.CSSProperties;
} & WithTranslation;

const ColorPicker = ({ value = "#0000000", onChange, label = "", activator: propsActivator, activatorStyle, t }: ColorPickerProps) => {
	const [open, setOpen] = React.useState(false);

	const handleToggle = () => {
		setOpen(!open);
	};

	const handleChange = (color) => {
		onChange(color);
	};

	const activator = React.cloneElement(propsActivator || <ColorPickerActivator onClick={handleToggle} color={value} />, {
		onClick: () => {
			propsActivator?.props?.onClick?.();

			handleToggle();
		},

		style: {
			...(activatorStyle || {}),
			cursor: "pointer",
		},
	});

	return (
		<Popover
			open={open}
			activator={
				<>
					{label && <Label id="color-picker-label">{label}</Label>}
					{activator}
				</>
			}
			onClose={() => setOpen(false)}
			sectioned
		>
			<Wrapper>
				<HexColorPicker color={value} onChange={handleChange} />
				<HexColorInput prefix="#" color={value} onChange={handleChange} alpha />
				<Button onClick={() => handleChange("#00000000")}>{t("pdf.editor.table.columns.colorPicker.transaprent", "Transparent")}</Button>
			</Wrapper>
		</Popover>
	);
};
export default withTranslation(["common"])(ColorPicker);

const ColorPickerActivator = styled.div<{ color: string }>`
	width: 36px;
	height: 36px;
	border-radius: 3px;
	border: 3px solid var(--main2-50);
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	cursor: pointer;

	background-color: ${({ color }) => color};
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: max-content;

	input {
		width: 100%;
	}
`;
