import Moment from "moment";
import { extendMoment } from "moment-range";
import Colors from "src/js/Colors";
import i18n from "src/js/i18n";
import { store } from "src/js/store";

const moment = extendMoment(Moment as any);

export const groupByBoard = (rows: BoardRowType[]) => {
	const boardMap = new Map();
	if (!rows) return [...boardMap];

	rows.forEach((row: BoardRowType) => {
		const boardId = row.board_id;
		const rowsForBoard = boardMap.get(boardId) || [];

		boardMap.set(boardId, [...rowsForBoard, row]);
	});

	return [...boardMap];
};

export const getDeadlinesRelatedColumns = (board: BoardType) => {
	const relatedColumns: DeadlinesColumnType[] = [];

	board?.columns?.forEach((column: BoardColumnType) => {
		if (["datetime", "timeline"].includes(column.type) && column.connected_column) {
			relatedColumns.push({
				statusColumn: column.connected_column,
				dateColumn: column,
			});
		}
	});

	return relatedColumns;
};

export const addRowForEachDateInDates = (rows: DeadlineBoardRowType[]) => {
	return rows.flatMap((row) => {
		const { deadline } = row;
		const { dates } = deadline;

		return dates.map((date) => ({
			...row,
			deadline: {
				...deadline,
				date,
			},
		}));
	});
};

export const formatDeadlineRows = ({ rows, boards }: { rows: BoardRowType[]; boards: BoardType[] }) => {
	const groupedByBoard = groupByBoard(rows);
	const formattedRows: DeadlineBoardRowType[] = [];

	groupedByBoard.forEach(([boardId, rows]) => {
		const board = boards.find((b: BoardType) => b.id === boardId) as BoardType;
		const contactColumn = board?.columns?.find((c: BoardColumnType) => c.type === "contact");
		const deadlinesColumns = getDeadlinesRelatedColumns(board);

		rows.forEach((row: BoardRowType) => {
			deadlinesColumns.forEach((dlc) => {
				const dateValue = row.column_values[dlc.dateColumn.id]?.value;
				if (!dateValue) return;

				const statusValue = row.column_values[dlc.statusColumn.id]?.value;
				const status = dlc.statusColumn?.options?.statuses?.find((s) => s.id === statusValue);

				const dates = new Set([dateValue.datetime || dateValue.start]);
				if (dateValue.end && !moment(dateValue.start).isSame(dateValue.end, "d")) {
					const range = moment.range(moment(dateValue.start).add(1, "d"), moment(dateValue.end));
					const momentDates = Array.from(range.by("days"));
					const datesRange = momentDates.map((date, index, arr) => (arr.length - 1 === index ? dateValue.end : date.format("YYYY-MM-DD")));
					datesRange.forEach((date) => dates.add(date));
				}

				const deadline: DeadlineType = {
					...dlc,
					date: dateValue.datetime || dateValue.start,
					dateValue,
					status,
					statusValue,
					dates: Array.from(dates),
					type: dlc.dateColumn.type,
					completed: status?.color === "#00c875",
				};

				formattedRows.push({
					key: `${row.id}-${dlc.dateColumn.id}` as `${number}-${number}`,
					...row,
					board,
					deadline,
					contactColumn,
					contactValue: contactColumn && row?.column_values[contactColumn.id]?.value,
					date: dateValue.datetime || dateValue.start, // legacy (now in deadline)
					statusColumn: dlc.statusColumn, // legacy (now in deadline)
					dates: Array.from(dates), // legacy (now in deadline)
					dateColumnValues: dlc.dateColumn, // legacy (where to put this..., this is data from outlook/google) (this can now be in deadline.dateValue (but this returns the .value which dateColusmnValues did not, so gotta refactor the code that uses dateColumnValues))
					row, // legacy?
				});
			});
		});
	});

	return formattedRows as DeadlineBoardRowType[];
};

export const getRowTypeLabel = (row: BoardRowType | DeadlineBoardRowType | null | undefined) => {
	if (!row) return i18n.t("row.singular", "Rad");
	if (row?.board_id == store.getState().account.sales_board_id) return i18n.t("row.deal.singular", "Affär");
	if (row?.board_id == store.getState().account.todo_board_id) return i18n.t("common.task.singular", "Uppgift");
	if (row?.board_id == store.getState().user.todo_board_id) return i18n.t("common.task.singular", "Uppgift");
	if (row?.board_id == store.getState().user.calendar_board_id) return i18n.t("common.calendar.singular", "Kalender inlägg");

	const board = store.getState().boards[row?.board_id];

	return board?.singular || i18n.t("row.singular", "Rad");
};

export const groupDeadlineRowsByDateAndStatus = (rows: DeadlineBoardRowType[] | null | undefined) => {
	// Group rows into 4 groups: "Today", "Late", "Upcoming", "Completed" based on the deadline date and status

	const today = moment().format("YYYY-MM-DD");
	const todayRows: DeadlineBoardRowType[] = [];
	const lateRows: DeadlineBoardRowType[] = [];
	const upcomingRows: DeadlineBoardRowType[] = [];
	const completedRows: DeadlineBoardRowType[] = [];
	const restRows: DeadlineBoardRowType[] = [];

	rows?.forEach((row) => {
		const { deadline } = row;
		const { status, dates } = deadline;

		const { color } = status || {};

		if (color === "#00c875") {
			row.color = Colors.green;
			completedRows.push(row);
		} else if (dates.some((date) => moment(date).isSame(today, "day"))) {
			row.color = Colors.yellow;
			todayRows.push(row);
		} else if (dates.every((date) => moment(date).isBefore(today))) {
			row.color = Colors.red;
			lateRows.push(row);
		} else if (dates.some((date) => moment(date).isAfter(today))) {
			row.color = Colors.blue;
			upcomingRows.push(row);
		} else {
			restRows.push(row);
		}
	});

	return {
		todayRows,
		lateRows,
		upcomingRows,
		completedRows,
		restRows,
		rows,
	};
};

export const filterDefaultValues = (defaultValues: { column_id: number; value: any }[]) => {
	if (!defaultValues) return [];
	const boards = defaultValues?.reduce((acc: any, v) => {
		const { board, column } = Object.values(store.getState().boards)?.reduce((acc: any, b: any) => {
			const column = b?.columns?.find((c) => c.id == v.column_id);

			if (column) {
				acc.board = b;
				acc.column = column;
			}

			return acc;
		}, {}) as any;

		if (board) {
			acc[v.column_id] = {
				board,
				column,
			};
		}

		return acc;
	}, {});

	return (
		defaultValues
			?.map((v) => {
				const board = boards?.[v.column_id]?.board;
				const column = boards?.[v.column_id]?.column;
				if (!column) return v;

				if (column.type === "status") {
					const status = column.options.statuses.find((s: BoardStatusOptionType) => s.id === v.value);
					if (status?.removed) return null;
				}

				if (column.type === "person") {
					const people = v?.value?.filter((id: number) => store.getState().users.find((p) => p.id === id && p.enabled));
					v.value = people;
				}

				if (column.type === "contact") {
					const contacts = v?.value?.filter((id: number) => store.getState().board_contacts[id] && !store.getState().board_contacts[id]?.removed);
					v.value = contacts;
				}

				if (column.type === "tags") {
					const tags = v?.value?.filter((id: number) => board?.tags.find((t: BoardTagType) => t.id == id));
					v.value = tags;
				}
				if (column.type === "rowlink") {
					const linkedRows = v.value?.filter((id: number) => store.getState().board_rows[id] && !store.getState().board_rows[id]?.removed);
					v.value = linkedRows;
				}

				if (column.type === "dropdown") {
					const dropdowns = v.value?.filter((id: number) => !column.options.removed_choices?.includes(id));
					v.value = dropdowns;
				}

				return v;
			})
			.filter((v) => v) || []
	);
};
type BoardRowUrl = `/admin/workspaces/${number | "none"}/boards/${number}?row_id=${number}`;

export const getBoardRowUrl = (row: BoardRowType | DeadlineBoardRowType): BoardRowUrl => {
	const { board_id: BoardId, id } = row;
	const boardRowUrl = `/admin/workspaces/none/boards/${BoardId}?row_id=${id}`;
	return boardRowUrl as BoardRowUrl;
};

export const getBoardTitle = (board: BoardType) => {
	if (store.getState().user.todo_board_id == board.id) return "Att göra (Privat)";
	if (store.getState().account.todo_board_id == board.id) return "Att göra";

	return board?.title;
};
