import React, { Component } from "react";
import { Modal, FormLayout, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";

class DeadlineModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			status_column_id: null,
			person_column_id: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
	}

	getColumns(types) {
		const arr = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf("*") >= 0 || types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push(this.props.board.columns[i]);
			}
		}
		return arr;
	}

	saveForm() {
		this.setState({ saving: true });
		const data = {
			connected_column_id: this.state.status_column_id,
			link_column_id: this.state.person_column_id,
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("deadline.responses.saved", "Sparade kolumn som deadline"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		const statusOptions = this.getColumns(["status"]).map((column) => ({
			value: column.id + "",
			label: column.title,
		}));
		statusOptions.unshift({
			value: "",
			label: this.props.t("column.actions.choose", "Välj kolumn"),
		});
		const personOptions = this.getColumns(["person"]).map((column) => ({
			value: column.id + "",
			label: column.title,
		}));
		personOptions.unshift({
			value: "",
			label: this.props.t("column.actions.choose", "Välj kolumn"),
		});

		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("deadline.title", "Få koll på dina deadlines med lätthet")}
				primaryAction={{
					disabled: !this.state.status_column_id,
					onAction: this.saveForm.bind(this),
					loading: this.state.saving,
					content: this.props.t("deadline.actions.connect", "Koppla ihop kolumner"),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<p>
							För att detta ska fungera, anslut <strong>datumet</strong> med relevant statuskolumn & personkolumn. Se till att använda den gröna
							statusen när någonting är avslutat
						</p>
						<Select
							label={this.props.t("deadline.fields.column_id.label", "Välj en statuskolumn")}
							options={statusOptions}
							value={this.state.status_column_id}
							onChange={(v) => {
								this.setState({ status_column_id: v });
							}}
						/>
						<Select
							label={this.props.t("deadline.fields.person_column_id.label", "Välj en personkolumn")}
							helpText={this.props.t("deadline.fields.person_column_id.", "Detta är valfritt")}
							options={personOptions}
							value={this.state.person_column_id}
							onChange={(v) => {
								this.setState({ person_column_id: v });
							}}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["deadline", "common"], { withRef: true })(DeadlineModal);
