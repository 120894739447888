import React, { Component } from "react";
import { Stack, Card } from "@shopify/polaris";
import API from "../../../API";

import { toastr } from "../../../components/toastr.js";

class RowVisitorForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hits: [],
			session_id: props.session_id,
		};
	}

	componentDidMount() {
		this.fetchSession();
	}

	fetchSession() {
		API.get("/api/visitors/sessions/" + this.state.session_id + "/hits.json", {
			params: {},
		})
			.then((result) => {
				this.setState({
					hits: result.data.hits,
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		return (
			<Card>
				{this.state.hits.map((hit, index) => (
					<Card.Section key={index} title={hit.title}>
						<Stack>
							<Stack.Item fill>{hit.url}</Stack.Item>
							<div>{hit.created_at}</div>
						</Stack>
					</Card.Section>
				))}
			</Card>
		);
	}
}
export default RowVisitorForm;
