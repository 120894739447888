import React, { Component } from "react";
import { Scrollable } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import RowSheetContent from "./RowSheetContent.js";
import Sheet from "../../../components/sheet";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors.js";

class RowSheet extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		return (
			<Sheet
				open={this.props.open}
				onClose={this.props.onClose}
				staus="info"
				title={
					this.props.row?.title ? (
						<div>
							{this.props.row?.title}
							{this.props.row?.archived && <Badge color={Colors.yellow}>{this.props.t("row.archived", "Arkiverad")}</Badge>}
						</div>
					) : (
						`${this.props.t("common.actions.create", "Skapa")} ${
							(this.props.board && this.props.board.singular) || this.props.t("row.singular", "Rad")
						}`
					)
				}
			>
				<div
					className="row_sheet"
					style={{
						display: "flex",
						flexDirection: "column",
						// height: "calc(100% - 56px)",
					}}
				>
					<Scrollable style={{ flex: 1 }}>
						<RowSheetContent
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...this.props}
							initialTabIndex={this.props.initialTabIndex != undefined ? this.props.initialTabIndex : 0}
							open={this.props.open}
							board={this.props.board}
							onClose={this.props.onClose}
							row={this.props.row}
							onUpdateRow={this.props.onUpdateRow}
						/>
					</Scrollable>
				</div>
			</Sheet>
		);
	}
}
export default withTranslation(["row", "common"], { withRef: true })(RowSheet);
