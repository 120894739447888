import React, { Component } from "react";
import { Button, Modal, Spinner, ChoiceList } from "@shopify/polaris";
import { CategoriesMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";

class BoardConnector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			boards: [],
			selection: this.props.dashboard.boards.map((board) => board.id),
		};
	}

	fetchBoards() {
		this.setState({
			loading: true,
			selection: this.props.dashboard.boards.map((board) => board.id),
		});

		API.get("/api/dashboards/" + this.props.dashboard.id + "/boards.json", {
			params: {},
		})
			.then((result) => {
				this.setState({ loading: false, boards: result.data.boards });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	saveForm() {
		const boards = this.state.selection.map((boardId) => {
			for (let i = 0; i < this.state.boards.length; i++) {
				if (this.state.boards[i].id == boardId) {
					return this.state.boards[i];
				}
			}
			for (let i = 0; i < this.props.dashboard.boards.length; i++) {
				if (this.props.dashboard.boards[i].id == boardId) {
					return this.props.dashboard.boards[i];
				}
			}
			return null;
		});
		this.setState({ saving: true });
		API.put("/api/dashboards/" + this.props.dashboard.id + ".json", { boards }, { params: {} })
			.then((result) => {
				this.setState({ saving: false, showBoardModal: false });
				this.props.onUpdateDashboard(result.data.dashboard);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<div>
				<Button
					outline
					onClick={() => {
						this.setState({ showBoardModal: true }, this.fetchBoards.bind(this));
					}}
					icon={CategoriesMajor}
				>
					{this.props.t("board.board_connector.count", "{{count}} board", {
						count: this.props.dashboard.boards.length,
					})}
				</Button>
				<Modal
					open={this.state.showBoardModal}
					onClose={() => {
						this.setState({ showBoardModal: false });
					}}
					title={this.props.t("board.modals.board_connector.title", "Valda boards")}
					preventAutofocus
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showBoardModal: false });
							},
						},
					]}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				>
					{this.state.loading ? (
						<Modal.Section>
							<Spinner />
						</Modal.Section>
					) : null}
					{!this.state.loading ? (
						<Modal.Section>
							<ChoiceList
								allowMultiple
								title={this.props.t("board.plural", "Boards")}
								choices={this.state.boards.map((board) => ({
									label: board.title,
									value: board.id,
								}))}
								selected={this.state.selection}
								onChange={(sel) => {
									this.setState({ selection: sel });
								}}
							/>
						</Modal.Section>
					) : null}
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardConnector);
