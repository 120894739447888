import React, { Component } from "react";
import { ActionList, Labelled, Stack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../store";

import Popover from "./Popover";

class ProjectTaskTypeSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			statusPopoverActive: false,
			status: props.type,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ status: props.type });
	}

	toggleStatusPopover() {
		this.setState({ statusPopoverActive: !this.state.statusPopoverActive });
	}

	changeStatus(value) {
		this.setState({ status: value });
		this.toggleStatusPopover();
		this.props.onChange(value);
	}

	render() {
		const button = (
			<span style={{ cursor: "pointer" }} onClick={this.toggleStatusPopover.bind(this)}>
				{this.state.status ? (
					<span
						className="Polaris-Badge"
						style={{
							color: (this.state.status ? this.state.status.color_hsl.brightness : 0.5) >= 0.8 ? "#000" : "#fff",
							backgroundColor: this.state.status ? this.state.status.color : "#eee",
						}}
					>
						{this.state.status.title}
					</span>
				) : (
					this.props.t("project.text.no_task_type", "ingen uppdragstyp")
				)}
			</span>
		);
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<Stack>
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Popover active={this.state.statusPopoverActive} activator={button} onClose={this.toggleStatusPopover.bind(this)}>
							<ActionList
								items={store.getState().project_types.map((type) => ({
									content: (
										<span
											className="Polaris-Badge"
											style={{ color: type.color_hsl.brightness >= 0.8 ? "#000" : "#fff", backgroundColor: type.color }}
										>
											{type.title}
										</span>
									),
									onAction: this.changeStatus.bind(this, type),
								}))}
							/>
						</Popover>
					</span>
				</Stack>
			</Labelled>
		);
	}
}
export default withTranslation(["project", "common"], { withRef: true })(ProjectTaskTypeSelector);
