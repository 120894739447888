import React, { Component } from "react";
import { Badge, Select, Scrollable, Subheading, Stack } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import Accordion from "./components/Accordion.js";
import Sheet from "../../components/sheet";
import BoardHelper from "./BoardHelper.js";

class WidgetSummaryList extends Component {
	constructor(props) {
		super(props);
		this.state = { showSettings: props.showSettings };
		this.state.data = this.calculateData(props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings != this.state.showSettings) {
			this.setState({ showSettings: props.showSettings });
		}
		this.setState({ data: this.calculateData(props) });
	}

	resetAxis() {
		const axis = {};
		for (let i = 0; i < this.getBoards().length; i++) {
			const boardId = this.getBoards()[i];
			axis[boardId] = "";
		}
		return axis;
	}

	groupDate(dateString, groupBy) {
		const mom = moment(dateString);
		if (groupBy == "day") {
			return mom.format("D MMM");
		} else if (groupBy == "week") {
			return mom.format("YYYY") + " V" + mom.isoWeek();
		} else if (groupBy == "month") {
			return mom.format("YYYY-MM");
		} else if (groupBy == "quarter") {
			return mom.format("YYYY") + " Q" + mom.quarter();
		} else if (groupBy == "year") {
			return mom.format("YYYY");
		}
		return mom.format("YYYY-MM-DD");
	}

	getAxisValue(props, row, axis, type, groupBy) {
		let values = ["none"];
		if (type == "number") {
			values = ["0"];
		} else if (axis == "board") {
			const board = this.getBoards().find((board) => board.id == row.board_id);
			values = [board.title];
		} else if (axis == "group") {
			const group = props.groups.find((group) => group.id == row.group_id);
			values = [group.title];
		} else if (axis == "title") {
			values = [row.title];
		} else if (axis == "created_at") {
			values = [this.groupDate(row.created_at, groupBy)];
		} else if (axis) {
			for (let s = 0; s < row.values?.length; s++) {
				if (row.values[s].column_id == axis) {
					if (type == "datetime") {
						if (row.values[s].value) {
							values = [this.groupDate(row.values[s].value.datetime, groupBy)];
						}
					} else if (type == "person") {
						if (row.values[s].value.length) {
							values = row.values[s].value;
						}
					} else if (type == "status") {
						if (row.values[s].value || row.values[s].value == 0 || row.values[s].value == "0") {
							values = [row.values[s].value];
						}
					} else if (type == "dropdown") {
						if (row.values[s].value) {
							values = row.values[s].value;
						}
					} else if (type == "tags") {
						if (row.values[s].value.length) {
							values = row.values[s].value;
						}
					} else if (type == "number") {
						if (row.values[s].value) {
							values = [row.values[s].value];
						} else {
							values = [0];
						}
					}
					break;
				}
			}
		}
		return values;
	}

	getYAxisValue(row, axis, type) {
		let value = 0;

		if (axis == "rows") {
			value = 1;
		} else if (axis) {
			for (let s = 0; s < row.values.length; s++) {
				if (row.values[s].column_id == axis) {
					if (type == "timetrack") {
						if (row.values[s].value && row.values[s].value.duration) {
							value = parseInt(row.values[s].value.duration); // TODO: Add active time aswell
						}
					} else if (row.values[s].value) {
						value = row.values[s].value;
					}
					break;
				}
			}
		}
		return value;
	}

	getColumnOptions(board, types) {
		const arr = [];
		if (types.indexOf("board") >= 0) {
			arr.push({
				value: "board",
				label: this.props.t("widget.column.options.board.label", "Board"),
			});
		}
		if (types.indexOf("rows") >= 0) {
			arr.push({
				value: "rows",
				label: this.props.t("widget.column.options.rows.label", "Antal rader"),
			});
		}
		if (types.indexOf("group") >= 0) {
			arr.push({
				value: "group",
				label: this.props.t("widget.column.options.group.label", "Grupper"),
			});
		}
		if (types.indexOf("title") >= 0) {
			arr.push({
				value: "title",
				label: this.props.t("widget.column.options.title.label", "Namn"),
			});
		}
		if (types.indexOf("datetime") >= 0) {
			arr.push({
				value: "created_at",
				label: this.props.t("widget.column.options.datetime.label", "Rad skapades"),
			});
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (types.indexOf(board.columns[i].type) >= 0) {
				arr.push({
					value: board.columns[i].id + "",
					label: board.columns[i].title,
				});
			}
		}
		arr.push({
			value: "",
			label: this.props.t("widget.column.options.none.label", "Inget värde"),
		});
		return arr;
	}

	updateOptions() {
		this.setState({ data: this.calculateData(this.props) });
		API.put(
			"/api/dashboards/" + this.props.dashboard.id + "/widgets/" + this.props.widget.id + ".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getLabel(props, row, value, axis, axisType) {
		let label = "";
		if (value == "none") {
			label = this.props.t("widget.column.options.none.label", "Inget värde");
		} else {
			if (axisType == "number" || axisType == "datetime" || axisType == "created_at") {
				label = value;
			} else if (axis == "board") {
				const board = this.getBoards().find((board) => board.id == row.board_id);
				label = board.title;
			} else if (axis == "group") {
				const group = props.groups.find((group) => group.id == row.group_id);
				label = group.title;
			} else if (axis == "title") {
				label = row.value;
			} else {
				const board = this.getBoards().find((board) => board.id == row.board_id);
				const column = board.columns.find((column) => column.id == axis);
				label = BoardHelper.getLabelFromValue({ value }, board, column).title;
			}
		}
		return label;
	}

	calculateData(props) {
		const summaries = {};
		for (let z = 0; z < this.getBoards().length; z++) {
			const board = this.getBoards()[z];
			const rows = props.rows.filter((row) => row.board_id == board.id);
			const xAxis = props.widget.options.x_axis[board.id];

			if (xAxis) {
				const yAxis = props.widget.options.y_axis[board.id];
				if (yAxis) {
					for (let x = 0; x < rows.length; x++) {
						const row = rows[x];
						const groupValue = this.getAxisValue(props, row, xAxis, props.widget.options.x_axis_type, props.widget.options.x_axis_group_by);
						if (groupValue.length > 0) {
							const groupName = groupValue[0];
							if (!(groupName in summaries)) {
								summaries[groupName] = 0;
							}
							const yAxisValue = this.getYAxisValue(row, yAxis, props.widget.options.y_axis_type);
							summaries[groupName] += yAxisValue;
						}
					}
				}
			}
		}

		const sums = [];

		// eslint-disable-next-line no-restricted-syntax
		for (const label in summaries) {
			sums.push({
				label,
				value: summaries[label],
			});
		}

		return sums.sort((a, b) => {
			if (this.props.widget.options.sort_by == "group") {
				// Sort by group
				if (this.props.widget.options.x_axis_type == "datetime") {
					return moment(a.label).unix() - moment(b.label).unix();
				} else if (typeof a.label == "string") {
					return a.label.localeCompare(b.label);
				} else {
					return a.label - b.label;
				}
			} else {
				// Sort by value
				return b.value - a.value;
			}
		});
	}

	getBoards() {
		if (this.props.dashboard) {
			return this.props.dashboard.boards;
		} else {
			return [this.props.board];
		}
	}

	render() {
		return (
			<div style={{ height: "100%", width: "100%", overflow: "auto" }}>
				{this.state.data.map((data, index) => (
					<div style={{ padding: 15 }} key={index}>
						<Stack>
							<Stack.Item fill>{data.label}</Stack.Item>
							<Badge>{data.value}</Badge>
						</Stack>
					</div>
				))}
				<Sheet
					open={this.state.showSettings}
					title={this.props.t("widget.summary_list_settings.title", "Inställningar")}
					onClose={this.props.onCloseSettings}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ height: "100%" }}>
							<Accordion label={this.props.t("widget.summary_list_settings.fields.x_axis_type.label", "Gruppera på")}>
								<Select
									label={this.props.t("widget.summary_list_settings.fields.x_axis_type.label2", "Kolumntyp")}
									options={[
										{
											value: "board",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.board", "Board"),
										},
										{
											value: "datetime",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.datetime", "Datum"),
										},
										{
											value: "dropdown",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.dropdown", "Dropdown"),
										},
										{
											value: "group",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.group", "Grupper"),
										},
										{
											value: "person",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.person", "Person"),
										},
										{
											value: "title",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.title", "Namn"),
										},
										{
											value: "number",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.number", "Siffror"),
										},
										{
											value: "status",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.status", "Status"),
										},
										{
											value: "tags",
											label: this.props.t("widget.summary_list_settings.fields.x_axis_type.options.tags", "Taggar"),
										},
									]}
									value={this.props.widget.options.x_axis_type}
									onChange={(v) => {
										if (v == "datetime") {
											this.props.widget.options.x_axis_group_by = "day";
										}
										this.props.widget.options.x_axis_type = v;
										this.props.widget.options.x_axis = this.resetAxis();
										this.updateOptions();
									}}
								/>
								{this.props.widget.options.x_axis_type == "datetime" ? (
									<Select
										label={this.props.t("widget.summary_list_settings.fields.x_axis_group_by.label", "Gruppera på")}
										options={[
											{
												value: "day",
												label: this.props.t("widget.summary_list_settings.fields.x_axis_group_by.options.day", "Dag"),
											},
											{
												value: "week",
												label: this.props.t("widget.summary_list_settings.fields.x_axis_group_by.options.week", "Vecka"),
											},
											{
												value: "month",
												label: this.props.t("widget.summary_list_settings.fields.x_axis_group_by.options.month", "Månad"),
											},
											{
												value: "quarter",
												label: this.props.t("widget.summary_list_settings.fields.x_axis_group_by.options.quarter", "Kvartal"),
											},
											{
												value: "year",
												label: this.props.t("widget.summary_list_settings.fields.x_axis_group_by.options.year", "År"),
											},
										]}
										value={this.props.widget.options.x_axis_group_by}
										onChange={(v) => {
											this.props.widget.options.x_axis_group_by = v;
											this.updateOptions();
										}}
									/>
								) : null}
								{this.getBoards().map((board) => (
									// Board, Group, Date, Dropdown, Person, Row title, Number, Status, Tags
									<div key={board.id} style={{ marginTop: 15 }}>
										<div style={{ marginBottom: 5 }}>
											<Subheading>{board.title}</Subheading>
										</div>
										<Select
											options={this.getColumnOptions(board, [this.props.widget.options.x_axis_type])}
											value={this.props.widget.options.x_axis[board.id]}
											onChange={(v) => {
												this.props.widget.options.x_axis[board.id] = v;
												this.updateOptions();
											}}
										/>
									</div>
								))}
							</Accordion>
							<Accordion label={this.props.t("widget.summary_list_settings.fields.y_axis_type.label", "Värde")}>
								<Select
									label={this.props.t("widget.summary_list_settings.fields.y_axis_type.label2", "Kolumntyp")}
									options={[
										{
											value: "rows",
											label: this.props.t("widget.summary_list_settings.fields.y_axis_type.options.day", "Antal rader"),
										},
										{
											value: "number",
											label: this.props.t("widget.summary_list_settings.fields.y_axis_type.options.day", "Siffror"),
										},
										{
											value: "timetrack",
											label: this.props.t("widget.summary_list_settings.fields.y_axis_type.options.day", "Tidtagare"),
										},
									]}
									value={this.props.widget.options.y_axis_type}
									onChange={(v) => {
										this.props.widget.options.y_axis_type = v;
										this.props.widget.options.y_axis = this.resetAxis();
										this.updateOptions();
									}}
								/>
								{this.getBoards().map((board) => (
									// Row count, Number, Time tracking
									<div key={board.id} style={{ marginTop: 15 }}>
										<div style={{ marginBottom: 5 }}>
											<Subheading>{board.title}</Subheading>
										</div>
										<Select
											options={this.getColumnOptions(board, [this.props.widget.options.y_axis_type])}
											value={this.props.widget.options.y_axis[board.id]}
											onChange={(v) => {
												this.props.widget.options.y_axis[board.id] = v;
												this.updateOptions();
											}}
										/>
									</div>
								))}
							</Accordion>
							<Accordion label={this.props.t("widget.summary_list_settings.fields.sort_by.label", "Sortering")}>
								<Select
									label={this.props.t("widget.summary_list_settings.fields.sort_by.label2", "Sortera efter")}
									options={[
										{
											value: "group",
											label: this.props.t("widget.summary_list_settings.fields.sort_by.options.group", "Gruppering"),
										},
										{
											value: "sum",
											label: this.props.t("widget.summary_list_settings.fields.sort_by.options.sum", "Summering"),
										},
									]}
									value={this.props.widget.options.sort_by}
									onChange={(v) => {
										this.props.widget.options.sort_by = v;
										this.updateOptions();
									}}
								/>
							</Accordion>
						</Scrollable>
					</div>
				</Sheet>
			</div>
		);
	}
}

export default withTranslation(["widget", "common"], { withRef: true })(WidgetSummaryList);
