import { Card, Checkbox, Collapsible, Labelled } from "@shopify/polaris";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import BoardFilter from "./Filters/BoardFilter";
import ActivityFilter from "./Filters/ActivityFilter";
import Button from "src/js/components/Button";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import getContactActivityTypes from "src/js/Utilities/getContactActivityTypes";
import TextField from "src/js/components/TextField";

const FilterListItem = ({ index, form, target, targetSource, handleChangeFilter, isLoading, t }) => {
	const [open, setOpen] = useState((form?.target_sources?.length <= 3 || !targetSource?.id) && !targetSource?.disabled);
	const getChangeHandler = (index: number, field: TargetSourceTypeMutableKeys) => (value: string | boolean) => {
		const targetSource = form.target_sources?.[index] || {};
		const newTargetSource = { ...targetSource };
		newTargetSource[field as string] = value;

		handleChangeFilter(index)(newTargetSource);

		return newTargetSource;
	};

	const title = (() => {
		if ("board" in targetSource) return `Board: ${targetSource.board.board.title}`;
		if ("contact_activity" in targetSource) {
			if ("contact_activity" in targetSource.contact_activity) return `Kontaktaktivitet: ${targetSource.contact_activity.contact_activity.title}`;
			if ("activity_type" in targetSource.contact_activity) {
				const activityType = getContactActivityTypes().find((type) => type.value === targetSource.contact_activity.activity_type);
				return `Kontaktaktivitet's typ: ${activityType?.label || targetSource.contact_activity.activity_type}`;
			}
		}
		return "";
	})();

	return (
		<Card
			sectioned
			key={index}
			title={
				<TitleWrapper onClick={() => setOpen(!open)} style={{}}>
					<Button icon={open ? ChevronUpMinor : ChevronDownMinor} plain />
					{title}
					{targetSource?.disabled && <span style={{ opacity: 0.75 }}> ({t("targets.fields.disabled.label", "Inaktiv")})</span>}
				</TitleWrapper>
			}
			actions={[
				{
					content: t("targets.fields.filters.actions.remove.label", "Ta bort") as string,
					onAction: () => {
						handleChangeFilter(index)(null);
					},
				},
			]}
		>
			<Collapsible open={open} id={`collapsible-${index}`} transition={{ duration: "150ms", timingFunction: "ease" }} expandOnPrint>
				<div style={{ marginBottom: "2rem" }}>
					<Labelled id="type" label={t("targets.fields.disabled.label2", "Status")}>
						<Checkbox
							checked={!targetSource.disabled}
							disabled={isLoading}
							label={t("targets.fields.disabled.label", "Aktiv")}
							onChange={(v) => getChangeHandler(index, "disabled")(!v)}
						/>
					</Labelled>
					<Labelled id="type" label={t("targets.fields.disabled.label2", "Status")}>
						<Checkbox
							checked={targetSource.is_target_connection}
							disabled={isLoading}
							label={t("targets.fields.is_target_connection.label", "Lägg till som Målvärde {{needSave}}", {
								needSave:
									!targetSource?.id ||
									(targetSource.is_target_connection || false) !==
										(target?.target_sources?.find((ts) => ts.id === targetSource.id)?.is_target_connection || false)
										? `(spara för att ändring ska ta effekt)`
										: "",
							})}
							helpText={
								targetSource.is_target_connection &&
								t("targets.fields.is_target_connection.heltext", "Detta filter kommer inte att inkluderas för användare/gruppstatistiken.")
							}
							onChange={getChangeHandler(index, "is_target_connection")}
						/>
						{targetSource.is_target_connection && (
							<TextField
								label={t("targets.fields.title.label", "Titel")}
								value={targetSource.title}
								onChange={(v) => getChangeHandler(index, "title")(v)}
							/>
						)}
					</Labelled>
				</div>

				<Labelled id="filters" label={t("targets.fields.filters.label", "Filters")}>
					{"board" in targetSource && <BoardFilter key={index} index={index} filterSource={targetSource} handleChangeFilter={handleChangeFilter} />}
					{"contact_activity" in targetSource && (
						<ActivityFilter key={index} index={index} filterSource={targetSource} handleChangeFilter={handleChangeFilter} />
					)}
				</Labelled>
			</Collapsible>
		</Card>
	);
};
export default withTranslation(["targets"])(FilterListItem);

const TitleWrapper = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 1rem;
`;
