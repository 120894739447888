import React, { Component } from "react";
import { Modal, FormLayout, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";

class FormulaModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			options: props.column.options ? props.column.options : { formula: "" },
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
	}

	saveForm() {
		this.setState({ saving: true });

		API.put(
			"/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json",
			{ options: this.state.options },
			{
				params: {},
			}
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return false;
				}
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("board.formula.responses.updated", "Sparade uträkningsfomel"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("board.formula.modal.title", "Ändra uträkningsformel")}
				primaryAction={{
					onAction: this.saveForm.bind(this),
					loading: this.state.saving,
					content: this.props.t("common.actions.save", "Spara"),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							value={this.state.options.formula}
							onChange={(v) => {
								this.state.options.formula = v;
								this.setState({ options: this.state.options });
							}}
							multiline
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(FormulaModal);
