import { Icon, Modal, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { SearchMajor } from "@shopify/polaris-icons";
import { Trans, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import FAQ from "./FAQ";
import Boxes from "./Boxes";
import { toastr } from "../../components/toastr";
import { HelpBarButton } from "../Dashboard/HelpBar/HelpBar";
import HelpDeskSheetContent from "../Dashboard/HelpBar/HelpDeskSheetContent";
import QRCode from "../Dashboard/HelpBar/QRCode";
import HelpHeader from "./HelpHeader";
import CreateModal from "./CreateModal";
import { store } from "../../store";
import Sheet from "../../components/sheet";

class Help extends Component {
	constructor(props) {
		super(props);
		this.state = { items: [] };
	}

	componentDidMount() {
		this.setState({ loading: true });
		API.get("/api/helpcenter.json")
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				if (result.data.items) this.setState({ items: result.data.items });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
				this.setState({ loading: false });
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	handleSearch(search) {
		this.setState({ search: search?.toLowerCase?.() || null });
	}

	openModal(modal, { form, tabIndex } = {}) {
		this.setState({ [modal]: true, form: form || {}, tabIndex });
	}

	closeModal(modal) {
		this.setState({ [modal]: false, form: null, tabIndex: null });
	}

	render() {
		return (
			<Page
				primaryAction={
					["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].some((rule) => store.getState().user.roles.includes(rule)) &&
					String(store.getState().account.id) === "24"
						? {
								content: this.props.t("help.actions.add", "Lägg till"),
								onAction: this.openModal.bind(this, "create", {}),
						  }
						: null
				}
			>
				<HelpPageWrapper>
					<HelpHeader
						title={this.props.t("help.faq.title2", "FAQ och utbildningar")}
						text={
							<Trans
								i18nKey="help.faq.description2"
								values={{
									mobile: "...",
								}}
								components={{ a: <a /> }}
								defaults={`Vår FAQ-avdelning i Sellfinity är en resurs som hjälper användare att hitta slet på vanliga frågor och problem. Här kan användare söka efter slet på sina frågor genom att använda sökfältet eller bläddra igenom olika kategorier.

									Vi strälet alltid efter att uppdatera den med de senaste och mest relevanta svaren på vanliga frågor.`}
								// defaults="Digital signering via scrive <textstyle>{{current}}</textstyle>/<textstyle>{{limit}}</textstyle> denna månad"
							/>
						}
						// image="/assets/3d/witch.png"
						// icon={<RocketIcon />}
					>
						<div style={{ margin: "auto", marginTop: "30px", marginBottom: "20px" }}>
							<p>
								{this.props.t("help.description2", "Har du fler frågor?")} {this.props.t("help.description3", "Tveka inte på att kontakta oss")}
							</p>

							<div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
								<div>
									<HelpBarButton style={{ width: "max-content", height: "40px" }} onClick={this.openModal.bind(this, "helpDeskForm")}>
										{this.props.t("help.terms.contact_us", "Kontakta oss")}
									</HelpBarButton>

									<Sheet open={this.state.helpDeskForm} onClose={this.closeModal.bind(this, "helpDeskForm")}>
										<HelpDeskSheetContent />
									</Sheet>
								</div>
								<HelpBarButton style={{ width: "max-content", height: "40px" }} onClick={this.openModal.bind(this, "installApp")}>
									{this.props.t("help.terms.intall_app", "Installera app")}
								</HelpBarButton>
							</div>
							<Modal
								open={this.state.installApp}
								onClose={this.closeModal.bind(this, "installApp")}
								primaryAction={{
									content: this.props.t("common.actions.done", "Klar"),
									onAction: this.closeModal.bind(this, "installApp"),
								}}
							>
								<Modal.Section>
									<QRCode />
								</Modal.Section>
							</Modal>
						</div>
					</HelpHeader>
					<SearchWrapper>
						<TextField
							onChange={this.handleSearch.bind(this)}
							value={this.state.search}
							placeholder={this.props.t("help.fields.search.placeholder", "Vad behöver du hjälp med?")}
							suffix={<Icon source={SearchMajor} />}
							onClearButtonClick={() => {
								this.handleSearch(null);
							}}
							clearButton
						/>
					</SearchWrapper>
					<Boxes
						openModal={this.openModal.bind(this, "create")}
						search={this.state.search}
						items={this.state.items?.filter((i) => i.type === "box")}
						loading={this.state.loading}
					/>
					<FAQ
						openModal={this.openModal.bind(this, "create")}
						search={this.state.search}
						items={this.state.items?.filter((i) => i.type === "faq")}
						loading={this.state.loading}
					/>
				</HelpPageWrapper>
				<CreateModal
					type={this.state.type}
					tabIndex={this.state.tabIndex}
					open={this.state.create}
					onClose={this.closeModal.bind(this, "create")}
					onSave={(item) => {
						this.state.items.push(item);
						this.setState({ items: this.state.items });
					}}
					onEdit={(item) => {
						const index = this.state.items.findIndex((i) => i.id == item.id);
						this.state.items.splice(index, 1, item);
						this.setState({ items: this.state.items });
					}}
					onDelete={(item) => {
						const index = this.state.items.findIndex((i) => i.id == item.id);
						this.state.items.splice(index, 1);
						this.setState({ items: this.state.items });
					}}
					form={this.state.form}
				/>
			</Page>
		);
	}
}
export default withTranslation(["help", "common"], { withRef: true })(Help);

export const HelpPageWrapper = styled.div`
	/* margin: 75px 150px 100px; */
`;

export const H2 = styled.h1`
	font-weight: 700;
	margin-bottom: 2rem;
	align-items: center;
	display: flex;
	font-size: 18px;
	line-height: 21px;

	svg {
		margin-right: 1rem;
		height: 30px;
		width: 30px;
	}
`;
const SearchWrapper = styled.div`
	margin: auto;
	margin-top: 2em;
	margin-bottom: 2em;
	width: 50%;
	min-width: 500px;

	.Polaris-TextField {
		padding: 1rem;
	}
	.Polaris-TextField,
	.Polaris-TextField * {
		border-radius: 0.5rem;
	}
`;
