import React, { Component } from "react";
import { Select, ButtonGroup, Icon } from "@shopify/polaris";
import {
	CircleUpMajor,
	CircleDownMajor,
	DeleteMajor,
	TextAlignmentLeftMajor,
	TextAlignmentCenterMajor,
	TextAlignmentRightMajor,
	ChevronUpMinor,
	ChevronDownMinor,
} from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import FontSizeChanger from "./FontSizeChanger";
import AddLink from "./AddLink";
import AddTable from "./Table/AddTable";
import AddVariable from "./AddVariable";
import ToolTipButton from "./ToolTipButton";
import getFontFamilies from "./getFontFamilies";
import ColorPicker from "src/js/components/ColorPicker2";

class ActiveObjectToolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.fonts = getFontFamilies({ fontSize: props.fontSize });
		this.keydown = this.handleKeydown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keydown);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keydown);
	}

	handleKeydown(e) {
		switch (e.key) {
			case "Delete":
				this.remove();
				e.preventDefault();
				e.stopPropagation();
				break;
			case "Escape":
				this.props.canvas?.canvas?.discardActiveObject();
				e.preventDefault();
				e.stopPropagation();
				break;
			default:
				break;
		}
	}

	setColor(value) {
		this.props.activeObjects.forEach((ele) => {
			const type = ele.get("type");
			switch (type) {
				case "text":
				case "link":
				case "i-text":
				case "textbox":
					ele.set("fill", value);
					break;
				case "rect":
				case "line":
				case "circle":
					ele.set("stroke", value);
					break;
				default:
					break;
			}
		});
	}

	setBold() {
		const enabled = this.props.activeObjects[0].fontWeight === "bold";
		this.props.activeObjects.forEach((el) => {
			el.fontWeight = enabled ? "" : "bold";
		});
	}

	setItalic() {
		const enabled = this.props.activeObjects[0].fontStyle === "italic";
		this.props.activeObjects.forEach((el) => {
			el.fontStyle = enabled ? "" : "italic";
		});
	}

	setUnderline() {
		// this.props.canvas.canvas.getActiveObject().set("textDecoration", "underline");
		// this.props.canvas.canvas.getActiveObjects().forEach((el) => {
		// 	el.set("textDecoration", false ? "" : "underline");
		// 	el.textDecoration = false ? "" : "underline";
		// });

		const enabled = this.props.activeObjects[0].textDecoration === "underline";
		this.props.activeObjects.forEach((el) => {
			el.textDecoration = enabled ? "" : "underline";
		});
	}

	setTextAlign(value) {
		this.props.activeObjects.forEach((ele) => {
			ele.set("textAlign", value);
		});
	}

	update(key, value) {
		switch (key) {
			case "color":
				this.setColor(value);
				break;
			case "underline":
				this.setUnderline();
				break;
			case "bold":
				this.setBold();
				break;
			case "italic":
				this.setItalic();
				break;
			case "fontFamily":
				this.setFontFamily(value);
				break;
			case "fontSize":
				this.setFontSize(value);
				break;
			case "textAlign":
				this.setTextAlign(value);
				break;
			default:
				this.props.activeObjects[0][key] = value;
				break;
		}
		this.props.canvas.canvas.renderAll();
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	remove() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.remove(ele);
		});
	}

	bringUp() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.bringToFront(ele);
		});
	}

	bringDown() {
		this.props.activeObjects.forEach((ele) => {
			this.props.canvas.canvas.sendToBack(ele);
		});
	}

	moveToPage(page) {
		const newCanvas = this.props.canvases[page];
		if (!newCanvas) return;

		const elements = this.props.activeObjects;
		elements.forEach((ele) => {
			this.props.canvas.canvas.remove(ele);
			// const clone = ele.clone();
			newCanvas.canvas.add(ele);
		});

		setTimeout(() => {
			// newCanvas.canvas.setActiveObject(elements);
			this.props.setActiveObject(elements);
		}, 100);
	}

	moveToPrevPage() {
		this.moveToPage(this.props.page - 1);
		this.props.goToPage(this.props.page - 1);
	}

	moveToNextPage() {
		this.moveToPage(this.props.page + 1);
		this.props.goToPage(this.props.page + 1);
	}

	setFontFamily(font) {
		this.props.addLastFontFamily(font);
		this.props.activeObjects.forEach((ele) => {
			ele.set("fontFamily", font);
		});
	}

	setFontSize(size) {
		this.props.addLastFontSize(size);
		this.props.activeObjects.forEach((ele) => {
			ele.set("fontSize", parseInt(size));
		});
	}

	updateLink(text, href) {
		this.props.activeObjects[0].text = text;
		this.props.activeObjects[0].href = href;
		this.props.canvas.canvas.renderAll();
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	updateTable(data) {
		this.props.activeObjects[0].recreateTable(data);
		this.props.canvas.canvas.renderAll();
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	updateVariable(input, type) {
		this.props.activeObjects[0].text = input;
		this.props.canvas.canvas.renderAll();
		// eslint-disable-next-line react/no-unused-state
		this.setState({ rnd: Math.random() });
	}

	render() {
		if (!this.props.activeObjects || !this.props.activeObjects[0]) return null;
		const ele = this.props.activeObjects[0];
		const type = ele.get("type");

		const color = (() => {
			switch (type) {
				case "text":
				case "i-text":
				case "textbox":
					return ele.get("fill");
				case "rect":
				case "line":
				case "circle":
					return ele.get("stroke");
				default:
					return "transparent";
			}
		})();

		return (
			<div className="editor__toolbar__active-object">
				{type !== "table" && (
					<ColorPicker value={color} onChange={(v) => this.update("color", v)} activatorStyle={{ border: "1px solid rgb(150,150,150)" }} />
				)}

				{["i-text", "text", "textbox", "link"].includes(type) && (
					<React.Fragment>
						{/* <Button onClick={this.update.bind(this, "underline")}>underline</Button> */}
						<FontSizeChanger value={ele.get("fontSize")} onChange={this.update.bind(this, "fontSize")} />
						<ButtonGroup>
							<ToolTipButton
								enabled={ele.get("textAlign") == "left"}
								tooltip="Text align left"
								image={<Icon source={TextAlignmentLeftMajor} />}
								onClick={this.update.bind(this, "textAlign", "left")}
							/>
							<ToolTipButton
								enabled={ele.get("textAlign") == "center"}
								tooltip="Text align center"
								image={<Icon source={TextAlignmentCenterMajor} />}
								onClick={this.update.bind(this, "textAlign", "center")}
							/>
							<ToolTipButton
								enabled={ele.get("textAlign") == "right"}
								tooltip="Text align right"
								image={<Icon source={TextAlignmentRightMajor} />}
								onClick={this.update.bind(this, "textAlign", "right")}
							/>
							<ToolTipButton
								enabled={!!ele.get("fontWeight")}
								tooltip="Bold"
								image={<img alt="bold" src="/assets/svg/format_bold.svg" />}
								onClick={this.update.bind(this, "bold")}
							/>
							<ToolTipButton
								enabled={!!ele.get("fontStyle")}
								image={<img alt="italic" src="/assets/svg/format_italic.svg" />}
								tooltip="Italic"
								onClick={this.update.bind(this, "italic")}
							/>
						</ButtonGroup>
						<Select value={ele.fontFamily} options={this.fonts} onChange={(v) => this.update("fontFamily", v)} />
					</React.Fragment>
				)}
				{type === "link" && <AddLink text={ele?.text || ""} href={ele?.href || ""} onSubmit={this.updateLink.bind(this)} />}

				{type === "textbox" && ele.text?.includes("{{") && <AddVariable element={ele} addVariable={this.updateVariable.bind(this)} />}

				{type === "table" && <AddTable element={ele} data={{ ...(ele?.data || {}) }} onSubmit={this.updateTable.bind(this)} />}

				<ButtonGroup>
					<ToolTipButton tooltip={this.props.t("pdf.editor.move_forward", "Flytta fram")} icon={CircleUpMajor} onClick={this.bringUp.bind(this)} />
					<ToolTipButton
						tooltip={this.props.t("pdf.editor.move_backward", "Flytta bak")}
						icon={CircleDownMajor}
						onClick={this.bringDown.bind(this)}
					/>
				</ButtonGroup>

				<ButtonGroup>
					<ToolTipButton
						tooltip={this.props.t("pdf.editor.move_prev_page", "Flytta till förra sidan")}
						icon={ChevronUpMinor}
						onClick={this.moveToPrevPage.bind(this)}
						disabled={this.props.page === 0}
					/>
					<ToolTipButton
						tooltip={this.props.t("pdf.editor.move_next_page", "Flytta till nästa sidan")}
						icon={ChevronDownMinor}
						onClick={this.moveToNextPage.bind(this)}
						disabled={this.props.page === this.props.canvases.length - 1}
					/>
				</ButtonGroup>

				<ToolTipButton className="remove-object-button" tooltip="ta bort" icon={DeleteMajor} destructive onClick={this.remove.bind(this)} />
			</div>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(ActiveObjectToolbar);
