import { ActionList, Icon, Spinner, TextField, Tooltip } from "@shopify/polaris";
import axios from "axios";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import API from "src/js/API";
import Sheet from "src/js/components/sheet";
import { toastr } from "src/js/components/toastr";
import { DiceIcon } from "src/js/icons";
import { store } from "src/js/store";
import styled from "styled-components";

interface WeeklyTasksExcludeBoardsProps extends WithTranslation {
	onSuccess?: () => void;
	open?: boolean;
}

const WeeklyTasksExcludeBoards = ({ onSuccess, open: propsOpen, t }: WeeklyTasksExcludeBoardsProps) => {
	const [boards, setBoards] = useState<BoardType[]>(store.getState().boards ? Object.values(store.getState().boards) : []);
	const controller = useRef(new AbortController());
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(false);

	const fetchBoards = useCallback(() => {
		setLoading(true);
		controller.current.abort();
		controller.current = new AbortController();

		const params = {
			ids: store.getState().user.excluded_boards,
		};

		API.get("/api/boards.json", {
			params,
			signal: controller.current.signal,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				setBoards(result.data.boards);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => setOpen(!!propsOpen), [propsOpen]);

	useEffect(() => {
		if (open) fetchBoards();

		return () => {
			controller.current.abort();
		};
	}, [fetchBoards, open]);

	const removeBoard = (id: number | string) => {
		const boardIds = (store.getState().user.excluded_boards || []).filter((boardId) => parseInt(boardId) !== parseInt(id as string));
		submitBoards(boardIds);
	};

	const addBoard = (id: number | string) => {
		const boardIds = store.getState().user.excluded_boards || [];
		boardIds.push(id);
		submitBoards(boardIds);
	};

	const refreshWeeklyTasks = debounce(() => {
		onSuccess?.();
	}, 3500);

	const submitBoards = (boardIds: (number | string)[]) => {
		const ids = Array.from(new Set(boardIds));

		const data = {
			board_ids: ids,
		};

		store.dispatch({ type: "SET_USER_EXCLUDED_BOARDS", excluded_boards: ids });
		API.put("/api/users/" + store.getState().user.id + "/excluded_boards.json", data)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => toastr.error(error));

		refreshWeeklyTasks();
	};

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const activator = (
		<ActivatorButtonWrapper onClick={handleOpen}>
			<Icon source={DiceIcon} />
		</ActivatorButtonWrapper>
	);

	const NonExcludedItems = boards
		.filter(
			(b, index, self) =>
				store.getState().user.excluded_boards &&
				!store.getState().user.excluded_boards.includes(b.id) &&
				(!search || b.title.toLowerCase().includes(search.toLowerCase())) &&
				b.id !== store.getState().user.calendar_board_id
		)
		.map((b) => ({
			content: b.title,
			onAction: () => addBoard(b.id),
		}));

	const excludedItems = boards
		.filter(
			(b, index, self) =>
				store.getState().user.excluded_boards &&
				store.getState().user.excluded_boards.includes(b.id) &&
				(!search || b.title.toLowerCase().includes(search.toLowerCase()))
		)
		.map((b) => ({
			content: b.title,
			onAction: () => removeBoard(b.id),
		}));

	return (
		<>
			<Tooltip content={t("dashboard.todo.terms.excluded_board", "Exkluderade boards")}>{activator}</Tooltip>
			<Sheet open={open} onClose={handleClose} title="Exkluderade boards">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "calc(100% - 56px)",
						paddingInline: "1rem",
						paddingTop: "1rem",
					}}
				>
					{/* <Banner status="info" title="Exkluderade boards" onDismiss={handleClose} /> */}

					<TextField
						label
						labelHidden
						value={search}
						onChange={setSearch}
						autoComplete="off"
						placeholder={t("dashboard.placeholder.board_name", "Board namn..") as string}
					/>

					<ListWrapper>
						<List>
							<b>
								{t("dashboard.todo.terms.board", "Boards")} {loading && <Spinner size="small" />}
							</b>
							<ScrollWrapper>
								<ActionList items={NonExcludedItems || []} />
							</ScrollWrapper>
						</List>

						<List>
							<b>
								{t("dashboard.todo.terms.excluded_board", "Exkluderade boards")} {loading && <Spinner size="small" />}
							</b>
							<ScrollWrapper>
								<ActionList items={excludedItems || []} />
							</ScrollWrapper>
						</List>
					</ListWrapper>
				</div>
			</Sheet>
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	boards: state.boards,
	user: state.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
	withTranslation(["dashboard", "common"], { withRef: true })(WeeklyTasksExcludeBoards)
);

const ListWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	height: 100%;
	padding-top: 1rem;
`;

const List = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	b {
		display: flex;
		align-items: center;
		gap: 1rem;
	}

	.Polaris-ActionList__Item {
		transition: background 0.25s ease-in-out;

		&:hover {
			background: var(--main2) !important;
		}
	}
`;

const ScrollWrapper = styled.div`
	overflow: auto;
`;

const ActivatorButtonWrapper = styled.button`
	border: none;
	background: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
`;
