import { Icon, Tooltip } from "@shopify/polaris";
import { StarFilledMinor, StarOutlineMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr";
import { store } from "../../../store";

class DashboardFavotiteButton extends Component {
	constructor(props) {
		super(props);
		// this.state = { isFavorite: props.isFavorite || (this.props.dashboard && this.props.dashboard.favorite) || null };
		this.state = {
			isFavorite: !!(store.getState().favorite_dashboards || []).find((d) => d.id == (this.props.dashboard && this.props.dashboard.id)),
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.state.isFavorite === null) {
			// this.setState({ isFavorite: props.isFavorite || (this.props.dashboard && this.props.dashboard.favorite) });
			this.setState({
				isFavorite: !!(store.getState().favorite_dashboards || []).find((d) => d.id == (this.props.dashboard && this.props.dashboard.id)),
			});
		}
	}

	toggleFavorite() {
		const isFavorite = !this.state.isFavorite;
		this.setState((c) => ({ isFavorite }));

		if (!this.props.salesdashboard && this.props.dashboard) {
			API.post("/api/dashboards/" + this.props.dashboard.id + "/favorite.json", { active: isFavorite }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}

					if (isFavorite) {
						store.dispatch({
							type: "ADD_FAVORITE_DASHBOARD",
							dashboard: this.props.dashboard,
						});
					} else {
						store.dispatch({
							type: "REMOVE_FAVORITE_DASHBOARD",
							dashboard: this.props.dashboard,
						});
					}
					this.setState((c) => ({ isFavorite: result.data.favorite }));
				})
				.catch((error) => toastr.error(error));
		} else {
			API.put("/api/me/toggle_salesdashboard.json", { active: isFavorite }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					store.dispatch({
						type: "TOGGLE_SALES_DASHBOARD_FAVORITE",
						favorite: result.data.favorite,
					});
				})
				.catch((error) => toastr.error(error));
		}
	}

	render() {
		const { icon, content, onClick, color } = this.state.isFavorite
			? {
					icon: StarFilledMinor,
					content: this.props.t("dashboard.favorite.actions.remove", "Ta bort som favorit"),
					color: "yellow",
					onClick: this.toggleFavorite.bind(this),
			  }
			: {
					icon: StarOutlineMinor,
					content: this.props.t("dashboard.favorite.actions.add", "Lägg till som favorit"),
					color: "inkLightest",
					onClick: this.toggleFavorite.bind(this),
			  };

		return (
			<span className={`dashboard-favorite-button-wrapper${this.state.isFavorite ? " active" : ""}`}>
				<Tooltip content={content}>
					<span className="dashboard-favorite-button" onClick={onClick}>
						<Icon source={icon} color={color} />
					</span>
				</Tooltip>
			</span>
		);
	}
}
export default withTranslation(["dashboard"], { withRef: true })(DashboardFavotiteButton);
