import React, { Component } from "react";
import { ProgressBar, Stack, Spinner } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import Modal from "./modal";

class AppIntegrationImportModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, page: 1, progress: 0 };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (props.open) {
				this.state.page = 1;
				this.syncCustomers(this.state.page);
			}
		}
	}

	syncCustomers(page) {
		this.setState({ options: this.state.options });
		const params = Object.assign({}, {}, { page });
		API.post("/api/integrations/" + this.props.integration.id + "/customers/sync.json", {}, { params })
			.then((result) => {
				this.setState({
					progress: (this.state.page / result.data.pages) * 100,
				});
				if (result.data.pages > this.state.page) {
					this.setState({ page: this.state.page + 1 }, () => {
						this.syncCustomers(this.state.page);
					});
				} else {
					this.props.onComplete();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
		return (
			<Modal
				title={this.props.t("integration.modals.app_integration_import.title", "Synkar kunder")}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<Stack>
						<Stack.Item fill>
							<ProgressBar progress={this.state.progress} />
						</Stack.Item>
						<Spinner size="small" />
					</Stack>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["integration", "common"], { withRef: true })(AppIntegrationImportModal);
