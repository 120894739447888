import styled from "styled-components";

export const Wrapper = styled.div<{ color: string }>`
	display: grid;
	gap: 3rem;
	grid-template-columns: 1fr 1fr;

	grid-template-areas:
		"header header"
		"boxes_header boxes_header"
		"value_bar_graph leaderboard"
		"line_graph leaderboard"
		"bar_graph bar_graph";

	.Polaris-Card {
		height: 100%;
	}

	&& {
		.Polaris-ProgressBar {
			height: 24px;
			position: relative;

			.Polaris-ProgressBar__Indicator {
				background-color: ${({ color }) => color};

				position: relative;
				.Polaris-ProgressBar__Label {
					width: unset !important;
					height: unset !important;
					inset: 0 !important;
					/* overflow: visible !important; */
					clip-path: unset !important;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 18px;
					line-height: 18px;
					font-weight: bold;
					text-shadow: 0px 0px 2px black;
				}
			}
		}
	}
`;

export const HeaderNavigation = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	justify-content: center;
	margin-bottom: 1rem;
`;

export const Header = styled.div`
	display: flex;
	align-items: end;
	margin-bottom: 1rem;
	gap: 2rem;

	.Polaris-Select {
		width: max-content;
		min-width: 200px;
	}

	.Polaris-ProgressBar {
		background-color: var(--main2);
	}
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	font-weight: 600;
`;
