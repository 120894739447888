import React, { Component } from "react";
import { Icon, Button, Stack, Popover, TextField, ChoiceList } from "@shopify/polaris";
import { MobileAcceptMajor, MobileCancelMajor, ChevronDownMinor, ChevronRightMinor, CustomerPlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";

class UserInviter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: [{ email: "", roles: [] }],
			sections: [],
		};
	}

	componentDidMount() {
		this.fetchSections();
	}

	fetchSections() {
		API.get("/api/sections.json")
			.then((result) => {
				this.setState({ sections: result.data.workspaces });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && !this.props.open) {
			this.setState({ users: { email: "", roles: [] } });
		}
	}

	getValidUsers() {
		const arr = [];

		for (let i = 0; i < this.state.users.length; i++) {
			if (this.state.users[i].email && this.isEmailValid(this.state.users[i].email)) {
				arr.push(this.state.users[i]);
			}
		}
		return arr;
	}

	isEmailValid(email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}

	updateUser(index, field, value) {
		this.state.users[index][field] = value;
		this.setState({ users: this.state.users });
	}

	// toggleRole(index, role, v) {
	// 	if (this.state.users[index].roles.indexOf(role) >= 0) {
	// 		this.state.users[index].roles.splice(this.state.users[index].roles.indexOf(role), 1);
	// 	} else {
	// 		this.state.users[index].roles.push(role);
	// 	}
	// 	this.setState({ users: this.state.users });
	// }

	// eslint-disable-next-line react/no-unused-class-component-methods
	onSave() {
		this.inviteUsers();
	}

	inviteUsers() {
		this.setState({ saving: true });
		if (this.getValidUsers() && this.getValidUsers().length) {
			API.post("/api/users/invite.json", { users: this.getValidUsers() })
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}

					toastr.success(this.props.t("user.responses.invited", "Bjöd in användare"));
					if (this.props.onSave) {
						this.props.onSave();
					}
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	getRoleOptions(index) {
		const workspaces = store.getState().workspaces;
		const serviceDesk = workspaces && workspaces.find((w) => w.title === "Servicedesk");
		const secviceDesks =
			(serviceDesk &&
				serviceDesk.boards &&
				serviceDesk.boards.map((board) => ({
					value: `ROLE_BOARD_${board.id}`,
					label: `${board.title}`,
				}))) ||
			[];

		const roleOptions = [
			{
				value: "ROLE_SUPPORT",
				label: this.props.t("user.roles.support", "Servicedesk"),
				renderChildren: (isSelected) =>
					isSelected && (
						<ChoiceList
							onChange={this.updateUser.bind(this, index, "roles")}
							choices={[{ value: "ROLE_SUPPORT_RECEIVE", label: this.props.t("user.roles.support_receive", "Ta emot nya ärenden") }].concat(
								secviceDesks
							)}
							selected={this.state.users[index].roles}
							allowMultiple
						/>
					),
				children: [{ value: "ROLE_SUPPORT_RECEIVE", label: this.props.t("user.roles.support_receive", "Ta emot nya ärenden") }].concat(secviceDesks),
			},
			{
				value: "ROLE_MARKETING",
				label: this.props.t("user.roles.marketing", "Marknadsföring"),
			},
		];

		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			if (section.type == "shareable" && section.title != "Servicedesk") {
				roleOptions.push({ value: "ROLE_WORKSPACE_" + section.id, label: section.title });
			}
		}

		roleOptions.push({ value: "ROLE_ADMIN", label: this.props.t("user.roles.admin", "Administratör") });

		for (let i = 0; i < store.getState().account.integrations.length; i++) {
			const integration = store.getState().account.integrations[i];
			roleOptions.push({ value: "ROLE_INTEGRATION_" + integration.id, label: "Integration: " + integration.title });
		}

		return roleOptions;
	}

	render() {
		return (
			<div>
				<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: 3 }}>
					<div
						style={{
							display: "inline-block",
							marginLeft: "1.2rem",
						}}
					>
						{this.props.t("user.fields.email.label", "Email")}
					</div>
					<div>
						<div
							style={{
								display: "inline-block",
								marginLeft: 12,
							}}
						>
							{this.props.t("user.fields.roles.label", "Rättigheter")}
						</div>
					</div>
				</div>
				{this.state.users.map((user, index) => {
					const isEmailValid = this.isEmailValid(user.email);
					const roleOptions = this.getRoleOptions(index);
					return (
						<div key={index} className="user_inviter_item" style={{ display: "flex", gridTemplateColumns: "1fr 1fr", marginBottom: 3 }}>
							<div style={{ position: "relative", marginRight: 1, width: "50%" }}>
								<TextField
									name="new_user_email"
									error={
										user.email && user.email.length ? (isEmailValid ? null : this.props.t("user.fields.email.errors.invalid", "Ogiltig email")) : null
									}
									value={user.email}
									placeholder={this.props.t("user.fields.email.placeholder", "Email...")}
									onChange={this.updateUser.bind(this, index, "email")}
								/>
								{user.email ? (
									<div
										style={{
											position: "absolute",
											right: 5,
											top: 8,
											zIndex: 35,
											cursor: isEmailValid ? "initial" : "pointer",
										}}
										onClick={!isEmailValid ? this.updateUser.bind(this, index, "email", "") : () => {}}
									>
										{isEmailValid ? <Icon source={MobileAcceptMajor} color="green" /> : <Icon source={MobileCancelMajor} color="red" />}
									</div>
								) : null}
							</div>

							<div className="roles_selection">
								<Popover
									activator={
										<div className="fakeInput" onClick={this.updateUser.bind(this, index, "active", !user.active)}>
											{user.roles.length > 0 ? (
												user.roles
													.map((role) => {
														const ss = roleOptions.flatMap((r) => [].concat([r], r.children || [])).filter((i) => i);
														const found = ss && ss.find((opt) => opt.value == role);
														return found && found.label;
													})
													.join(", ")
											) : (
												<Stack spacing="extraTight">
													<span style={{ marginLeft: 4 }}>{this.props.t("user.actions.add_to", "Lägg till")}</span>
													<Icon source={ChevronDownMinor} />
												</Stack>
											)}
										</div>
									}
									active={user.active}
									onClose={this.updateUser.bind(this, index, "active", false)}
								>
									<Popover.Section>
										<div style={{ width: 300 }}>
											<Stack>
												<ChoiceList onChange={this.updateUser.bind(this, index, "roles")} choices={roleOptions} selected={user.roles} allowMultiple />

												{/* {roleOptions.map((role) => (
												<Checkbox
													key={role.id || role.label}
													checked={user.roles.indexOf(role.value) >= 0}
													onChange={this.toggleRole.bind(this, index, role.value)}
													label={role.label}
												/>
											))} */}
											</Stack>
											<div onClick={this.updateUser.bind(this, index, "active", false)} style={{ cursor: "pointer" }}>
												<Stack distribution="trailing" spacing="extraTight">
													<span>{this.props.t("user.terms.done", "klar")}</span>
													<Icon source={ChevronRightMinor} />
												</Stack>
											</div>
										</div>
									</Popover.Section>
								</Popover>
							</div>
						</div>
					);
				})}
				<div style={{ marginTop: 10 }}>
					<Stack distribution="fillEvenly">
						<div>
							<Button
								outline
								onClick={() => {
									this.state.users.push({ email: "", roles: [] });
									this.setState({ users: this.state.users });
								}}
							>
								<Stack spacing="extraTight">
									<span style={{ display: "flex", height: "100%", alignItems: "center" }}>
										{this.props.t("user.actions.new_user", "Ny användare")}
									</span>{" "}
									<Icon source={CustomerPlusMajor} />
								</Stack>
							</Button>
						</div>
						<div style={{ textAlign: "right" }}>
							<Button outline loading={this.state.saving} disabled={this.getValidUsers().length < 1} onClick={this.inviteUsers.bind(this)}>
								{this.props.t("user.actions.send_invitation", "Skicka inbjudan")}
							</Button>
						</div>
					</Stack>
				</div>
			</div>
		);
	}
}
export default withTranslation(["user", "common"], { withRef: true })(UserInviter);
