import React, { useContext } from "react";
import TargetContext from "../Context/TargetContext";
import { OverviewWrapper } from "../styles";
import TextField from "src/js/components/TextField";
import { Card, Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import { TYPES } from "../CONSTANTS";
import InfoView from "src/js/components/InfoView";
import styled from "styled-components";

const TargetOverview = ({ t }) => {
	const { form, setForm, isLoading, id } = useContext(TargetContext);

	const getChangeHandler = (field: TargetTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<TargetType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			if (field === "number_of_decimals") {
				newForm.number_of_decimals = Math.min(10, parseInt((value || 0) as string));
			}

			return newForm;
		});
	};

	const disabled = isLoading;

	return (
		<Wrapper>
			<Card sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled id="type" label={t("targets.fields.disabled.label2", "Status")}>
								<Checkbox
									checked={!form.disabled}
									disabled={disabled}
									label={t("targets.fields.disabled.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>

							<TextField
								requiredIndicator
								disabled={disabled}
								label={t("targets.fields.title.label", "Titel")}
								value={form?.title}
								onChange={getChangeHandler("title")}
							/>

							<TextField
								disabled={disabled}
								label={t("targets.fields.description.label", "Beskrivning")}
								value={form?.description}
								onChange={getChangeHandler("description")}
								multiline={4}
							/>

							<TextField
								requiredIndicator
								disabled={disabled}
								label={t("targets.fields.started_at.label", "Startdatum")}
								value={form?.started_at}
								onChange={getChangeHandler("started_at")}
								type="date"
							/>
							<TextField
								disabled={disabled}
								label={t("targets.fields.suffix.label", "Suffix")}
								value={form?.suffix}
								onChange={getChangeHandler("suffix")}
							/>

							<TextField
								disabled={disabled}
								label={t("targets.fields.number_of_decimals.label", "Antal decimaler")}
								value={String(form?.number_of_decimals)}
								onChange={getChangeHandler("number_of_decimals")}
								type="number"
								max={10}
								maxLength={10}
							/>

							<div style={{ display: "flex", gap: "2rem" }}>
								<TextField
									requiredIndicator
									disabled={!!(disabled || id)}
									label={t("targets.fields.number_of_periods_per_group.label", "Antal")}
									value={form?.number_of_periods_per_group}
									onChange={getChangeHandler("number_of_periods_per_group")}
									type="number"
									helpText={
										disabled || id
											? t("targets.fields.type.disaled_helptext", "Detta går ej att redigera efter skapning")
											: t("targets.fields.type.helptext", "Antal uppdelade perioder per grupp")
									}
								/>

								<Select
									requiredIndicator
									options={[
										{
											label: t("common.select", "Välj typ"),
											value: "",
										},
										...TYPES,
									]}
									value={form?.period_type}
									onChange={getChangeHandler("period_type")}
									label={t("targets.fields.type.label", "Typ")}
									disabled={!!(disabled || id)}
								/>
							</div>

							<InfoView
								style={{ opacity: 0.5, display: "flex", justifyContent: "end" }}
								details={[
									{
										label: t("targets.fields.created_at.label", "Skapad"),
										value: form?.created_at,
									},
									{
										label: t("targets.fields.updated_at.label", "Uppdaterad"),
										value: form?.updated_at,
									},
								]}
							/>
						</FormLayout>
					</Layout.Section>
				</Layout>
			</Card>
		</Wrapper>
	);
};

export default withTranslation(["targets", "common"])(TargetOverview);

const Wrapper = styled(OverviewWrapper)`
	&&&& {
		.info-view__labels,
		.info-view__values {
			row-gap: 3px;
		}
	}
`;
