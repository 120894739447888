import { Collapsible, FormLayout, Icon } from "@shopify/polaris";
import { ChevronDownMinor, ChevronLeftMinor } from "@shopify/polaris-icons";
import { DefaultTFuncReturn } from "i18next";
import React from "react";
import styled from "styled-components";

type GroupProps = {
	label?: string | DefaultTFuncReturn;
	labelBackgroundColor?: string;
	style?: React.CSSProperties;
	collapsible?: boolean;
	sectioned?: boolean;
	children?: React.ReactNode;
};

const Group = ({ children, label, labelBackgroundColor = "inherit", style = {}, collapsible = true, sectioned = false }: GroupProps) => {
	const [collapsed, setCollapsed] = React.useState(false);

	const toggleCollapse = () => {
		setCollapsed((c) => !c);
	};

	return (
		<OuterWrapper>
			{label && (
				<Label onClick={toggleCollapse} labelBackgroundColor={labelBackgroundColor}>
					{label}
				</Label>
			)}
			{collapsible && (
				<CollapseButton labelBackgroundColor={labelBackgroundColor} onClick={toggleCollapse}>
					<Icon source={collapsed ? ChevronDownMinor : ChevronLeftMinor} />
				</CollapseButton>
			)}
			<Wrapper style={style}>
				<Collapsible id="group" open={!collapsed}>
					{sectioned ? <FormLayout>{children}</FormLayout> : children}
				</Collapsible>
			</Wrapper>
		</OuterWrapper>
	);
};
export default Group;

const OuterWrapper = styled.div`
	position: relative;
`;

const Wrapper = styled.div`
	border: 1px solid var(--textColor2);
	border-radius: 1rem;
	padding: 1rem;

	background-color: inherit;
`;

const Label = styled.div<{ labelBackgroundColor: string }>`
	position: absolute;
	top: 0;
	left: 35px;
	transform: translate(0%, -50%);
	z-index: 99;
	padding: 2px;
	background-color: ${({ labelBackgroundColor }) => labelBackgroundColor};
	color: var(--textColor3);

	&::before {
		/* content: "";
		position: absolute;
		inset: 0;
		mix-blend-mode: difference;
		z-index: 99; */

		/* background-color: red; */
	}
`;

const CollapseButton = styled.button<{ labelBackgroundColor: string }>`
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 99;
	padding: 2px;
	background-color: ${({ labelBackgroundColor }) => labelBackgroundColor};
	color: var(--textColor3);
	cursor: pointer;
	border: none;
	transform: translate(0%, -50%);

	&::before {
		/* content: "";
		position: absolute;
		inset: 0;
		mix-blend-mode: difference;
		z-index: 99; */

		/* background-color: red; */
	}
`;
