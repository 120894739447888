import React, { useCallback, useMemo, useRef, useState } from "react";
import PdfTemplateSheet from "../components/PdfTemplateSheet";
import { store } from "../store";

type PDFContextValue = {
	open: boolean;
	handleOpen: (data: any, saveHandler: any) => void;
	handleClose: () => void;
	data: any;
	setData: (data: any) => void;
	saveHandler?: any;
	setSaveHandler?: (handler: any) => void;
};

export const PDFContext = React.createContext({} as PDFContextValue);

const PDFContextProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [data, setData] = useState<any>({});
	const saveHandler = useRef(null);
	const setSaveHandler = (handler) => {
		saveHandler.current = handler;
	};

	const handleOpen = useCallback((data, saveHandler) => {
		setData(data);
		if (saveHandler) {
			setSaveHandler(saveHandler);
		}

		setOpen(true);
	}, []);
	const handleClose = () => setOpen(false);

	const value = useMemo(
		() => ({
			open,
			handleOpen,
			handleClose,
			data: {
				user: store.getState().user,
				account: store.getState().account,
				...(data || {}),
			},
			setData,
			saveHandler: saveHandler.current,
			setSaveHandler,
		}),
		[open, data, saveHandler, handleOpen]
	);

	return (
		<PDFContext.Provider value={value}>
			{children}
			<PdfTemplateSheet
				// onSave={this.addFile.bind(this)}
				// save
				saveHandler={saveHandler.current}
				requireName
				data={data}
				open={open}
				onClose={handleClose}
			/>
		</PDFContext.Provider>
	);
};
export default PDFContextProvider;
