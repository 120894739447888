/* eslint-disable no-template-curly-in-string */
const getFontFamilies = ({ fontSize }) => {
	return [
		{ label: "Times New Roman", value: "Times New Roman" },
		{ label: "Arial", value: "Arial" },
		{ label: "Helvetica", value: "Helvetica" },
		{ label: "Lucida Grande", value: "Lucida Grande" },
		{ label: "Geneva", value: "Geneva" },
		{ label: "Tahoma", value: "Tahoma" },
		{ label: "Interstate", value: "Interstate" },
		{ label: "Minion Pro", value: "Minion Pro" },
		{ label: "Myriad Pro", value: "Myriad Pro" },
	]
		.filter((font) => {
			if (!document.fonts || !document.fonts.check) return true;
			try {
				return document.fonts.check(`${fontSize || "12px"} ${font.value}`);
			} catch (error) {
				console.debug("failed to load font:", font.value);
				return false;
			}
		})
		.sort((a, b) => a.label.localeCompare(b.label));
};

export default getFontFamilies;
