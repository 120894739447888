import React, { Component } from "react";
import { Modal, FormLayout, ChoiceList, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import Banner from "./banner";

class WorkspaceModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: { parent_id: props.parent ? props.parent.id : null, type: "private" }, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: { parent_id: props.parent ? props.parent.id : null, type: "private" } });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = field === "type" ? value[0] : value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/workspaces/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						this.setState({ saving: false });
						return;
					}
					toastr.success(this.props.t("workspace.responses.updated", "Uppdaterade arbetsyta"));
					store.dispatch({ type: "UPDATE_WORKSPACE", workspace: result.data.workspace });
					this.props.onCreate(result.data.workspace);
				})
				.catch((error) => {
					toastr.error(error);
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		} else {
			API.post("/api/workspaces.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("workspace.responses.created", "Skapade arbetsyta"));
					store.dispatch({ type: "ADD_WORKSPACE", workspace: result.data.workspace });
					this.props.onCreate(result.data.workspace);
				})
				.catch((error) => {
					toastr.error(error);
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		}
	}

	render() {
		const singular = this.props.resourceName?.singular || "mapp";
		// const plural = this.props.resourceName?.plural || "mappar";
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={
					this.state.form.id
						? this.props.t("workspace.titles.update_folder_resource", "Uppdatera {{resource}}", {
								resource: singular,
						  })
						: this.props.t("worksapce.titles.new_folder_resource", "Ny {{resource}}", { resource: singular })
				}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							label={this.props.t("workspace.folder.fields.title_resource", "Namn på {{resource}}", {
								resource: singular,
							})}
							value={this.state.form.title}
							autoComplete="off"
							onChange={this.updateForm.bind(this, "title")}
						/>
						<ChoiceList
							choices={[
								{
									label: this.props.t("workspace.folder.type.options.public.label", "Publik"),
									value: "public",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner
												title={this.props.t("workspace.folder.type.options.public.title_resource", "Publik {{resource}}", { resource: singular })}
											>
												{this.props.t("workspace.folder.type.options.public.text_resource", "Alla som har ett konto kommer åt {{resource}}", {
													resource: singular,
												})}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("workspace.folder.type.options.public_read.label", "Publik (endast läs)"),
									value: "public_read",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner
												title={this.props.t("workspace.folder.type.options.public_read.title_resource", "Publik {{resource}} (endast läs)", {
													resource: singular,
												})}
											>
												{this.props.t(
													"workspace.folder.type.options.public_read.text_resource",
													"Alla kan se {{resource}}, men endast kopplade medlemmar har skrivrättigheter",
													{
														resource: singular,
													}
												)}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("workspace.folder.type.options.shareable.label", "Delad"),
									value: "shareable",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner
												title={this.props.t("workspace.folder.type.options.shareable.title_resource", "Delad {{resource}}", {
													resource: singular,
												})}
											>
												{this.props.t(
													"workspace.folder.type.options.shareable.text",
													"Det går att dela {{resource}} med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna",
													{
														resource: singular,
													}
												)}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("workspace.folder.type.options.private.label", "Privat"),
									value: "private",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner
												title={this.props.t("workspace.folder.type.options.private.title.resource", "Privat {{resource}}", {
													resource: singular,
												})}
											>
												{this.props.t("workspace.folder.type.options.private.text_resource", "Enbart ägaren (du) kan se {{resource}}", {
													resource: singular,
												})}
											</Banner>
										) : null,
								},
							]}
							selected={[this.state.form.type]}
							onChange={this.updateForm.bind(this, "type")}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceModal);
