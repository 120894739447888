import i18next from "i18next";

import order from "./ColumnOptions/Fortnox/order";
import invoice from "./ColumnOptions/Fortnox/invoice";
import contract from "./ColumnOptions/Fortnox/contract";
import offer from "./ColumnOptions/Fortnox/offer";
import customer from "./ColumnOptions/Fortnox/customer";
import article from "./ColumnOptions/Fortnox/article";
import getContactOptions from "./ColumnOptions/contact";
import getUserOptions from "./ColumnOptions/user";
import getAccountOptions from "./ColumnOptions/account";

const getColumnOptions = () => {
	return [
		// { label: i18next.t("common.selectValue", "Välj värde"), value: "" },
		{ label: i18next.t("common.todaysDate", "Dagens datum"), value: "today_full_date" },
		{ label: i18next.t("common.todaysDateWithTime", "Dagens datum med tid"), value: "today_full_date_with_time" },
		{ label: i18next.t("common.todaysTime", "Dagens tid"), value: "today_time" },

		getUserOptions(),
		getAccountOptions(),
		getContactOptions(),
		{
			label: i18next.t("fortnox.label", "Fortnox"),
			value: "Fortnox",
			options: [contract, invoice, order, offer, customer, article],
		},
	];
};

export default getColumnOptions;

const getOptionsRecursive = (options) => {
	return options?.flatMap((o) => {
		if (o.options) {
			return [o, ...getOptionsRecursive(o.options)];
		}

		return o;
	});
};

export const findOptionByValue = (options, searchValue) => {
	return getOptionsRecursive(options).find((option) => option.value === searchValue);
};

export const findOptionOptionsByValue = (options, searchValue) => {
	if (!searchValue) return getOptionsRecursive(options);

	return getOptionsRecursive(options).find((option) => option.value === searchValue && option.options)?.options;
};

export const findOptionOptionsByType = (options, type, fullKey) => {
	return getOptionsRecursive(options).filter((option) => option.type === type);
};

export const findFullOptionByValue = (options, searchValue) => {
	return options.find((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue)));
};

export const findOptionsByValue = (options, searchValue, onlyReturnTheMatchedOption) => {
	return options.filter((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue)));
};

// Auxiliary recursive search specifically within sub-options
const findOptionInOptions = (options, searchValue) => {
	return options.some((option) => option.value === searchValue || (option.options && findOptionInOptions(option.options, searchValue)));
};
