import React, { Component } from "react";
import { Layout, Card, TextStyle, Spinner, ResourceList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import BillingServiceModal from "../../components/billingServiceModal.js";

class BillingIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAddCreditCardModal: false,
			loading_bills: true,
			bills: [],
		};
	}

	componentDidMount() {
		this.fetchBills();
	}

	fetchBills() {
		this.setState({ loading_bills: true });
		API.get("/api/transactions.json")
			.then((result) => {
				this.setState({ loading_bills: false, bills: result.data.transactions });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	closeCreditCardModal() {
		this.setState({ showAddCreditCardModal: false });
	}

	renderBill(item) {
		return (
			<ResourceList.Item id={item.id}>
				{item.id} {item.amount} {item.status}
			</ResourceList.Item>
		);
	}

	addedBilling(billing) {
		this.setState({ showAddCreditCardModal: false });
	}

	render() {
		return (
			<Page
				title={this.props.t("billing.title", "Fakturering")}
				breadcrumbs={[{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }]}
			>
				<Layout>
					{/*
            <Layout.AnnotatedSection
              title="Billing information"
              description="Your bills are paid using your active payment method."
            >
              <Card sectioned title="Credit card">
                {((this.state.loading_billings) ? <Spinner size="large" color="teal" />:
                  <div>
                    {((this.state.billing_services.length < 1) ?
                      <TextStyle subdued>Add a credit card and use it to pay your bills.</TextStyle>:<ResourceList
                      loading={this.state.loading_billings}
                      resourceName={{
                        singular: 'credit card',
                        plural: 'credit cards',
                      }}
                      items={this.state.billing_services}
                      renderItem={this.renderBilling.bind(this)}
                    />)}
                    <div style={{marginTop: 20}}>
                      <Button onClick={this.showAddCreditCardModal.bind(this)}>Add credit card</Button>
                    </div>
                  </div>
                )}
              </Card>
            </Layout.AnnotatedSection>
          */}
					<Layout.AnnotatedSection
						title={this.props.t("billing.plural", "Fakturor")}
						description={this.props.t("billing.description", "Här listas era fakturor samt dess betalningsstatus")}
					>
						<Card sectioned>
							{this.state.loading_bills ? (
								<Spinner size="large" color="teal" />
							) : (
								<div>
									{this.state.bills.length < 1 ? (
										<TextStyle subdued>{this.props.t("billing.empty.text", "Du har ännu inte några fakturor")}</TextStyle>
									) : (
										<ResourceList
											loading={this.state.loading_bills}
											resourceName={{
												singular: this.props.t("billing.card.singular", "credit card"),
												plural: this.props.t("billing.card.plural", "credit cards"),
											}}
											items={this.state.bills}
											renderItem={this.renderBill.bind(this)}
										/>
									)}
								</div>
							)}
						</Card>
					</Layout.AnnotatedSection>
				</Layout>
				<BillingServiceModal
					open={this.state.showAddCreditCardModal}
					onComplete={this.addedBilling.bind(this)}
					onClose={this.closeCreditCardModal.bind(this)}
				/>
			</Page>
		);
	}
}

export default withTranslation(["billing", "common"], { withRef: true })(BillingIndex);
