import { Icon, Tooltip } from "@shopify/polaris";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberBadge from "./NumberBadge";

class NavigationHeaderButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { number, onClick, icon, color, className, id, tooltip, to, children } = this.props;
		let content = (
			<div id={id} className={`notification-indicator-container${className ? ` ${className}` : ""}`} onClick={onClick}>
				<div className={"notifications" + (number ? " unread" : "")}>
					{!!number && <div className="heartbit" />}
					{!!number && <div className="MessageIndicator" />}
					{children}
					<span style={{ display: "flex" }}>{React.isValidElement(icon) ? icon : <Icon source={icon} color="white" />}</span>
				</div>
				{!!number && <NumberBadge color={color}>{number}</NumberBadge>}
			</div>
		);

		if (to) {
			content = <Link to={to}>{content}</Link>;
		}

		if (tooltip) {
			content = <Tooltip content={tooltip}>{content}</Tooltip>;
		}

		return content;
	}
}
export default NavigationHeaderButton;
