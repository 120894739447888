import { FormLayout, Layout } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";

const FortnoxInvoiceInfo = ({ invoice, onChange, t, isSaving }) => {
	const disabled = !!invoice?.InvoiceReference || isSaving || invoice.Cancelled;
	return (
		<Layout>
			<Layout.Section oneHalf>
				<FormLayout>
					{invoice.OCR && <TextField disabled label={t("fortnox.fields.OCR.label", "OCR")} value={invoice.OCR} />}
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourReference.label", "Er referens")}
						value={invoice.YourReference}
						onChange={onChange("YourReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.OurReference.label", "Vår referens")}
						value={invoice.OurReference}
						onChange={onChange("OurReference")}
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.InvoiceDate.label", "Fakturadatum")}
						value={invoice.InvoiceDate}
						onChange={onChange("InvoiceDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.DueDate.label", "Förfallodatum")}
						value={invoice.DueDate}
						onChange={onChange("DueDate")}
						type="date"
					/>
					<TextField
						disabled={disabled}
						label={t("fortnox.fields.YourOrderNumber.label", "Ert ordernummer")}
						value={invoice.YourOrderNumber}
						onChange={onChange("YourOrderNumber")}
					/>
				</FormLayout>
			</Layout.Section>
			<Layout.Section oneHalf />
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxInvoiceInfo);
