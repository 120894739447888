import React, { Component } from "react";
import { Stack, InlineError, Select, TextField, Button } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";
import ResourcePicker from "../../components/resource_picker.js";
import SellerSelector from "../../components/SellerSelector.js";
import RelationshipSelector from "../../components/RelationshipSelector.js";

class Condition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			default: props.default,
			field: props.field,
			operator: props.operator,
			target: props.target,
			value: props.value,
		};
	}

	changeField(field) {
		let value = "";
		if (field == "relationship") {
			value = "none";
		}
		if (field == "task_type") {
			value = store.getState().task_types[0].id + "";
		}
		if (field == "deal_stage_type") {
			value = "incoming";
		}
		if (field == "deal_stage" && store.getState().stages.length > 0) {
			value = store.getState().stages[0].id + "";
		}
		if (field == "deal_source" && store.getState().sources.length > 0) {
			value = store.getState().sources[0].id + "";
		}
		this.setState({
			field,
			operator: "equals",
			value,
			target: "",
		});
		this.onChange(field, "equals", "", "");
	}

	changeOperator(operator) {
		this.setState({ operator });
		this.onChange(this.state.field, operator, this.state.value, this.state.target);
	}



	changeValue(value) {
		this.setState({ value });
		this.onChange(this.state.field, this.state.operator, value, this.state.target);
	}

	onChange(field, operator, value, target) {
		this.props.onChange(field, operator, value, target, this.haveError(field, operator, value, target));
	}

	removeCondition() {
		this.props.onRemove();
	}

	haveError(field, operator, value, target) {
		if (field == "user") {
			return null;
		}
		if (operator != "empty" && operator != "not_empty" && !value) {
			return this.props.t("triggers.conditions.value_cant_be_blank", "Kan ej vara tom");
		}
		return null;
	}

	render() {
		const fieldOptions = [
			{ value: "tagged", label: this.props.t("triggers.conditions.fields.tagged.label", "Kontakt - Taggad med") },
			{ value: "email", label: this.props.t("triggers.conditions.fields.email.label", "Kontakt - E-post") },
			{ value: "user", label: this.props.t("triggers.conditions.fields.user.label", "Kontakt - Ansvarig medarbetare") },
			{ value: "relationship", label: this.props.t("triggers.conditions.fields.relationship.label", "Kontakt - Relation") },
		];

		let isString = true;

		if (this.props.event == "tasks.due" || this.props.event == "tasks.complete" || this.props.event == "tasks.remind") {
			fieldOptions.push({
				value: "task_user",
				label: this.props.t("triggers.conditions.fields.task_user.label", "Påminnelse - Ansvarig medarbetare"),
			});
			fieldOptions.push({ value: "task_type", label: this.props.t("triggers.conditions.fields.task_type.label", "Påminnelse - Typ") });
		}
		if (this.props.event == "deals.create" || this.props.event == "deals.update") {
			fieldOptions.push({ value: "deal_user", label: this.props.t("triggers.conditions.fields.deal_user.label", "Affär - Ansvarig medarbetare") });
			fieldOptions.push({ value: "deal_stage", label: this.props.t("triggers.conditions.fields.deal_stage.label", "Affär - Steg") });
			fieldOptions.push({ value: "deal_stage_type", label: this.props.t("triggers.conditions.fields.deal_stage_type.label", "Affär - Steg Typ") });
			fieldOptions.push({ value: "deal_source", label: this.props.t("triggers.conditions.fields.deal_source.label", "Affär - Källa") });
		}
		if (this.props.event == "tickets.create" || this.props.event == "tickets.update") {
			fieldOptions.push({ value: "ticket_title", label: this.props.t("triggers.conditions.fields.ticket_title.label", "Ärende - Rubrik") });
			fieldOptions.push({
				value: "ticket_inbound",
				label: this.props.t("triggers.conditions.fields.ticket_inbound.label", "Ärende - Uppdaterades av"),
			});
		}
		if (this.props.event == "mail.received") {
			fieldOptions.push({
				value: "mail_department",
				label: this.props.t("triggers.conditions.fields.mail_department.label", "Mail affärsområde (konto.AVDELNING@sellfinity.com)"),
			});
			fieldOptions.push({ value: "mail_sender", label: this.props.t("triggers.conditions.fields.mail_sender.label", "Avsändarens e-post") });
			fieldOptions.push({ value: "mail_receiver", label: this.props.t("triggers.conditions.fields.mail_receiver.label", "Mottagaremail") });
		}

		if (this.props.event == "board.row.move") {
			fieldOptions.push({ value: "board", label: this.props.t("triggers.conditions.fields.board.label", "Board") });
			fieldOptions.push({ value: "board_group", label: this.props.t("triggers.conditions.fields.board_group.label", "Grupp") });
		}

		if (
			this.props.event == "projects.tasks.create" ||
			this.props.event == "projects.tasks.update" ||
			this.props.event == "projects.tasks.delegate" ||
			this.props.event == "projects.tasks.undelegate" ||
			this.props.event == "projects.tasks.checkin" ||
			this.props.event == "projects.tasks.checkout" ||
			this.props.event == "projects.tasks.logs.create" ||
			this.props.event == "projects.tasks.logs.update" ||
			this.props.event == "projects.tasks.complete" ||
			this.props.event == "projects.tasks.verified" ||
			this.props.event == "projects.tasks.billed"
		) {
			fieldOptions.push({
				value: "project_task_have_ticket",
				label: this.props.t("triggers.conditions.fields.project_task_have_ticket.label", "Uppdrag - Kopplat till kundtjänstärende"),
			});
			fieldOptions.push({
				value: "project_task_status",
				label: this.props.t("triggers.conditions.fields.project_task_status.label", "Uppdrag - Status"),
			});
		}

		if (
			[
				"user",
				"task_user",
				"deal_user",
				"relationship",
				"task_type",
				"deal_type",
				"deal_stage",
				"deal_source",
				"ticket_inbound",
				"project_task_status",
				"project_task_have_ticket",
			].indexOf(this.state.field) >= 0
		) {
			isString = false;
		}

		const operatorOptions = [];
		operatorOptions.push({ value: "equals", label: this.props.t("triggers.conditions.fields.equals.label", "lik") });
		operatorOptions.push({ value: "not_equal_to", label: this.props.t("triggers.conditions.fields.not_equal_to.label", "ej lik") });

		if (isString) {
			operatorOptions.push({ value: "contains", label: this.props.t("triggers.conditions.fields.contains.label", "innehåller") });
			operatorOptions.push({ value: "not_contains", label: this.props.t("triggers.conditions.fields.not_contains.label", "innehåller ej") });
			operatorOptions.push({ value: "begins_with", label: this.props.t("triggers.conditions.fields.begins_with.label", "börjar med") });
			operatorOptions.push({ value: "ends_with", label: this.props.t("triggers.conditions.fields.ends_with.label", "slutar med") });
		}

		if (isString && this.state.field != "tag" && this.state.field != "key_features" && this.state.field != "keywords") {
			operatorOptions.push({ value: "empty", label: this.props.t("triggers.conditions.fields.empty.label", "tom") });
			operatorOptions.push({ value: "not_empty", label: this.props.t("triggers.conditions.fields.not_empty.label", "ej tom") });
		}

		const haveError = this.haveError(this.state.field, this.state.operator, this.state.value, this.state.target);

		return (
			<Stack wrap={false} alignment="leading" spacing="tight">
				<Stack.Item fill>
					<Stack distribution="fill" spacing="tight">
						<Select
							labelHidden
							disabled={!!this.state.default}
							options={fieldOptions}
							value={this.state.field}
							onChange={this.changeField.bind(this)}
						/>
						{this.state.field != "ticket_inbound" &&
						this.state.field != "project_task_have_ticket" &&
						this.state.field != "user" &&
						this.state.field != "deal_source" &&
						this.state.field != "deal_stage" &&
						this.state.field != "deal_stage_type" &&
						this.state.field != "deal_user" &&
						this.state.field != "task_type" &&
						this.state.field != "task_user" &&
						this.state.field != "relationship" ? (
							<Select options={operatorOptions} value={this.state.operator} onChange={this.changeOperator.bind(this)} />
						) : null}
						{this.state.field == "user" ? (
							<SellerSelector user={this.state.value ? this.state.value : null} onChange={this.changeValue.bind(this)} clearable />
						) : null}
						{this.state.field == "task_user" || this.state.field == "deal_user" ? (
							<SellerSelector user={this.state.value ? this.state.value : null} onChange={this.changeValue.bind(this)} />
						) : null}
						{this.state.field == "relationship" ? (
							<RelationshipSelector relationship={this.state.value} onChange={this.changeValue.bind(this)} />
						) : null}
						{this.state.field == "tagged" ? (
							<ResourcePicker
								caption={this.props.t("triggers.conditions.fields.tags.label", "Välj tagg")}
								item={this.state.value ? this.state.value : null}
								resource="contacts/tags.json"
								resource_handle="tags"
								id_handle="title"
								label_handle="title"
								resourceName={{
									singular: this.props.t("tags.singular", "tagg"),
									plural: this.props.t("tags.plural", "taggar"),
								}}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "task_type" ? (
							<Select
								options={store.getState().task_types.map((type) => ({ value: type.id + "", label: type.title }))}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "deal_stage" ? (
							<Select
								options={store.getState().stages.map((stage) => ({ value: stage.id + "", label: stage.title }))}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "deal_source" ? (
							<Select
								options={store.getState().sources.map((source) => ({ value: source.id + "", label: source.title }))}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "ticket_inbound" ? (
							<Select
								options={[
									{ value: "0", label: this.props.t("triggers.conditions.fields.ticket_inbound.options.us", "oss") },
									{ value: "1", label: this.props.t("triggers.conditions.fields.ticket_inbound.options.contact", "kontakt") },
								]}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "project_task_have_ticket" ? (
							<Select
								options={[
									{ value: "0", label: this.props.t("common.terms.no", "Nej") },
									{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
								]}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "project_task_status" ? (
							<Select
								options={store.getState().project_statuses.map((status) => ({ value: status.id + "", label: status.title }))}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field == "deal_stage_type" ? (
							<Select
								options={[
									{ label: this.props.t("triggers.conditions.fields.deal_stage_type.options.incoming.label", "Inkommande"), value: "incoming" },
									{ label: this.props.t("triggers.conditions.fields.deal_stage_type.options.in_progress.label", "Pågående"), value: "in_progress" },
									{ label: this.props.t("triggers.conditions.fields.deal_stage_type.options.won.label", "Vunnen"), value: "won" },
									{ label: this.props.t("triggers.conditions.fields.deal_stage_type.options.lost.label", "Förlorad"), value: "lost" },
									{
										label: this.props.t("triggers.conditions.fields.deal_stage_type.options.unqualified.label", "Okvalificerad"),
										value: "unqualified",
									},
								]}
								value={this.state.value}
								onChange={this.changeValue.bind(this)}
							/>
						) : null}
						{this.state.field != "ticket_inbound" &&
						this.state.field != "project_task_status" &&
						this.state.field != "project_task_have_ticket" &&
						this.state.field != "tagged" &&
						this.state.field != "user" &&
						this.state.field != "deal_source" &&
						this.state.field != "deal_stage" &&
						this.state.field != "deal_stage_type" &&
						this.state.field != "deal_user" &&
						this.state.field != "task_type" &&
						this.state.field != "task_user" &&
						this.state.field != "relationship" &&
						this.state.operator != "empty" &&
						this.state.operator != "not_empty" ? (
							<TextField error={haveError} value={this.state.value} onChange={this.changeValue.bind(this)} />
						) : haveError ? (
							<InlineError message={haveError} fieldID="myFieldID" />
						) : null}
					</Stack>
				</Stack.Item>
				{this.state.default ? null : <Button icon={DeleteMajor} onClick={this.removeCondition.bind(this)} />}
			</Stack>
		);
	}
}

export default withTranslation(["triggers", "common"], { withRef: true })(Condition);
