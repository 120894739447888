import { Collapsible, Icon } from "@shopify/polaris";
import { ChevronDownMinor, ChevronLeftMinor } from "@shopify/polaris-icons";
import React from "react";
import styled from "styled-components";

const CollapsibleSection = ({ title, children }) => {
	const [collapsed, setCollapsed] = React.useState(true);

	const toggleCollapse = () => {
		setCollapsed((c) => !c);
	};

	return (
		<CollapsibleSectionWrapper>
			<CollapsibleSectionActivator onClick={toggleCollapse}>
				<p>{title}</p>
				<Icon source={collapsed ? ChevronDownMinor : ChevronLeftMinor} />
			</CollapsibleSectionActivator>
			<Collapsible id={title} open={!collapsed}>
				{children}
			</Collapsible>
		</CollapsibleSectionWrapper>
	);
};

export default CollapsibleSection;
const CollapsibleSectionWrapper = styled.div``;
const CollapsibleSectionActivator = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	padding: 1rem 0rem;

	.Polaris-Icon {
		margin: 0;
	}
`;
