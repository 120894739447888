import React, { Component } from "react";
import axios from "axios";
import { Button, TextStyle } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import "../../css/Goal.scss";
import Colors from "../Colors.js";
import CircleDiagramBox from "../views/Goals/CircleDiagramBox";
import GoalUsersStatsModal from "./GoalUsersStatsModal/index.tsx";

class Goal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			user_ids: props.user_ids,
			from: props.goal.start_at,
			to: moment(props.goal.end_at).isAfter(moment()) ? moment().format("YYYY-MM-DD") : props.goal.end_at,
			form: props.goal,
			loading: true,
			count: null,
			sum: null,
		};
		this.refresh = this.refresh.bind(this);
	}

	componentDidMount() {
		this.fetchStats();
		window.addEventListener("refreshGoals", this.refresh, false);
	}

	componentWillUnmount() {
		this.cancelRequest();
		window.removeEventListener("refreshGoals", this.refresh, false);
	}

	refresh() {
		this.fetchStats(null, true);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.stats) {
			if (JSON.stringify(props.user_ids) !== JSON.stringify(this.props.user_ids) || JSON.stringify(props.goal) != JSON.stringify(this.props.goal)) {
				this.state.user_ids = props.user_ids;
				this.fetchStats();
			}
		} else if (this.state.loading) {
			const { start_at: startAt, end_at: endAt, days } = props.goal;
			this.setState({ loading: false, count: props.stats.count, sum: props.stats.sum, form: props.goal, from: startAt, to: endAt, days });
		}
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	async fetchStats(goal = {}, silent = false) {
		const { from = this.state.from, to = this.state.to, form = this.state.form } = goal || {};

		this.cancelRequest();
		const CancelToken = axios.CancelToken;
		if (!silent) this.setState({ loading: true });
		const params = {};
		if (this.props.total) {
			params.total = 1;
		}
		if (this.state.user_ids) {
			params.user_ids = this.state.user_ids;
		}
		const result = await API.get("/api/goals/" + form.id + "/stats.json?from=" + from + "&to=" + to, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				this.setState({
					loading: false,
					count: result.data.count,
					sum: result.data.sum,
					form,
					from,
					to,
					days: result.data.days,
				});
				if (result.data.error) {
					toastr.error(result.data.error);
				}

				return result.data;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				this.setState({
					from,
					to,
					form,
					loading: false,
				});
				toastr.error(error);
			});

		return result;
	}

	onClick(e) {
		if (this.props.disabled) return;

		if (this.props.onClick) {
			e.preventDefault();
			e.stopPropagation();
			if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
				this.props.onClick(e);
			}
		} else {
			this.handleOpenModal();
			// if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
			// this.props.history.push("/admin/goals/" + this.state.form.id, { id: this.state.form.id, from: this.state.from, to: this.state.to });
			// }
		}
	}

	handleOpenModal() {
		this.setState({ modalIsOpen: true });
	}

	handleCloseModal() {
		this.setState({ modalIsOpen: false });
	}

	fetchItem(id) {
		this.setState({ loading: true });
		API.get("/api/goals/" + id + ".json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ loading: false });
					toastr.error(result.data.error);
					return;
				}
				// this.setState(
				// 	{
				// 		from: result.data.goal.start_at,
				// 		to: result.data.goal.end_at,
				// 		form: result.data.goal,
				// 	},
				// 	);
				this.fetchStats({
					from: result.data.goal.start_at,
					to: moment(result.data.goal.end_at).isAfter(moment()) ? moment().format("YYYY-MM-DD") : result.data.goal.end_at,
					form: result.data.goal,
				});

				this.props.onDateChange?.({
					from: result.data.goal.start_at,
					to: moment(result.data.goal.end_at).isAfter(moment()) ? moment().format("YYYY-MM-DD") : result.data.goal.end_at,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	goPrevious(e) {
		e.stopPropagation();
		if (
			this.state.form.old_id &&
			this.state.from == this.state.form.start_at &&
			(this.state.to == this.state.form.end_at || moment(this.state.form.end_at).isAfter(moment()))
		) {
			this.fetchItem(this.state.form.old_id);
			return;
		}
		let a = null;
		let b = null;
		if (this.state.form.interval == "daily") {
			a = moment(this.state.from).subtract(1, "days");
			b = moment(this.state.to).subtract(1, "days");
		} else if (this.state.form.interval == "monthly") {
			a = moment(this.state.from).subtract(1, "months").startOf("month");
			b = moment(a).endOf("month");
		} else if (this.state.form.interval == "weekly") {
			a = moment(this.state.from).subtract(7, "days");
			b = moment(this.state.to).subtract(7, "days");
		} else if (this.state.form.interval == "annually") {
			a = moment(this.state.from).subtract(1, "year");
			b = moment(this.state.to).subtract(1, "year");
		} else {
			a = moment(this.state.from);
			b = moment(this.state.to);
			const days = b.diff(a, "days") + 1;

			if (days == 365 || days == 366) {
				a = moment(this.state.from).subtract(1, "year");
				b = moment(this.state.to).subtract(1, "year");
			} else {
				a = moment(this.state.from).subtract(days, "days");
				b = moment(this.state.to).subtract(days, "days");
			}
		}
		const from = a.format("YYYY-MM-DD");
		const to = b.format("YYYY-MM-DD");
		this.setState({ from, to }, this.fetchStats.bind(this));
		this.props.onDateChange?.({
			from,
			to,
		});
	}

	goNext(e) {
		e.stopPropagation();
		if (
			this.state.form.new_id &&
			this.state.from == this.state.form.start_at &&
			(this.state.to == this.state.form.end_at || moment(this.state.form.end_at).isAfter(moment()))
		) {
			this.fetchItem(this.state.form.new_id);
			return;
		}
		let a = null;
		let b = null;
		if (this.state.form.interval == "daily") {
			a = moment(this.state.from).add(1, "days");
			b = moment(this.state.to).add(1, "days");
		} else if (this.state.form.interval == "monthly") {
			a = moment(this.state.from).add(1, "months").startOf("month");
			b = moment(a).endOf("month");
		} else if (this.state.form.interval == "weekly") {
			a = moment(this.state.from).add(7, "days");
			b = moment(this.state.to).add(7, "days");
		} else if (this.state.form.interval == "annually") {
			a = moment(this.state.from).add(1, "year");
			b = moment(this.state.to).add(1, "year");
		} else {
			a = moment(this.state.from);
			b = moment(this.state.to);
			const days = b.diff(a, "days") + 1;

			if (days == 365 || days == 366) {
				a = moment(this.state.from).add(1, "year");
				b = moment(this.state.to).add(1, "year");
			} else {
				a = moment(this.state.from).add(days, "days");
				b = moment(this.state.to).add(days, "days");
			}
		}
		const from = a.format("YYYY-MM-DD");
		const to = b.format("YYYY-MM-DD");
		this.setState({ from, to }, this.fetchStats.bind(this));
		this.props.onDateChange?.({
			from,
			to,
		});
	}

	render() {
		// if (this.props.total) {
		// 	let myGoal = parseInt(this.state.form.value || 0);
		// } else if (this.state.user_ids && this.state.user_ids.length > 0) {
		// 	let myGoal = 0;
		// 	for (let s = 0; s < this.state.user_ids.length; s++) {
		// 		for (let i = 0; i < this.state.form.users.length; i++) {
		// 			if (this.state.form.users[i].user_id + "" == this.state.user_ids[s] + "") {
		// 				myGoal += this.state.form.users[i].value || 0;
		// 			}
		// 		}
		// 	}
		// } else {
		// 	const me = this.state.form.users.find((usr) => usr.user_id + "" == (this.props.user ? this.props.user.id : store.getState().user.id) + "");
		// 	if (me) {
		// 		let myGoal = me.value;
		// 	} else {
		// 		let myGoal = 0;
		// 	}
		// }

		const user = this.state.form.users.find((usr) => usr.user_id == (this.props.user ? this.props.user.id : store.getState().user.id));
		const value = parseInt(this.state.sum || 0);
		// if (!user.hasOwnProperty("forecasted_value")) {
		if (user && !Object.prototype.hasOwnProperty.call(user, "forecasted_value")) {
			user.forecasted_value = value;
		}

		return (
			<>
				<div
					style={Object.assign({
						flexBasis: `calc(${100 / this.props.perRow}% - ${((this.props.perRow - 1) * 3) / this.props.perRow}rem)`,
						cursor: !this.props.disabled ? "pointer" : "initial",
					})}
					className={`goal-box${this.state.loading ? " loading" : ""}`}
					onClick={this.onClick.bind(this)}
				>
					<CircleDiagramBox
						goal={this.state.form}
						title={this.state.form && this.state.form.title}
						stats={Array.from(Array(this.state.count))}
						sum={this.state.sum}
						loading={this.state.loading}
						size={150}
						empty={!this.state.loading && !this.state.count}
						value={((user?.value || 0) / this.state.form.days) * this.state.days}
						color={Colors.accent}
						days={(moment(this.state.to).isAfter(moment(), "days") ? moment() : moment(this.state.to)).diff(moment(this.state.from), "days")}
						// color={this.props.color}
					>
						<div
							className="date"
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Button disabled={this.state.loading || !this.state.form.old_id} plain onClick={this.goPrevious.bind(this)} icon={ChevronLeftMinor} />
							<TextStyle variation="subdued">
								{this.state.from} - {this.state.to}
							</TextStyle>

							<Button
								plain
								onClick={this.goNext.bind(this)}
								icon={ChevronRightMinor}
								disabled={this.state.loading || !(moment(this.state.to) < moment()) || !this.state.form.new_id}
							/>
						</div>
					</CircleDiagramBox>
				</div>

				<GoalUsersStatsModal open={this.state.modalIsOpen} goal={this.state.form} onClose={this.handleCloseModal.bind(this)} />
			</>
		);
	}
}
export default withTranslation(["goals", "common"], { withRef: true })(Goal);
