import React, { Component } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Skeleton2Col from "../../components/skeleton_2col.js";
import { toastr } from "../../components/toastr.js";
import GoalModal from "../../components/GoalModal";
import Colors from "../../Colors.js";
import GoalViewSection from "./GoalViewSection.js";

class GoalView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.id || props.match.params.id,
			loading: true,
			sortByPercentage: false,
		};
	}

	componentDidMount() {
		if (this.state.id) this.fetchItem(this.state.id);
	}

	UNSAFE_componentWillReceiveProps(props) {
		const id = props.id || props.match.params.id;
		if (id && id != this.state.id) {
			this.setState({ id });
			this.fetchItem(id);
		}
	}

	fetchItem(id = this.state.id, silent = false, resetPreviousGoals = true) {
		// console.log(`Hämtar goal med id ${id} mellan ${from} -> ${to}`);
		if (!silent) this.setState({ loading: true });
		API.get("/api/goals/" + id + ".json")
			.then((result) => {
				this.fetchPreviousItem(result.data.goal.compare_goal_id);

				this.setState(
					(c) => ({
						loading: false,
						form: result.data.goal,
						previous_goals: resetPreviousGoals ? result.data.previous_goals : c.previous_goals,
						firstDate: resetPreviousGoals ? result.data.previous_goals?.at(-1)?.start_at : c.firstDate,
						from: result.data.goal.start_at,
						to: moment(result.data.goal.end_at).isAfter(moment()) ? moment().format("YYYY-MM-DD") : result.data.goal.end_at,
						interval: result.data.goal.interval,
					}),
					this.props.updateState({ rnd: Math.random() })
				);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	fetchPreviousItem(id) {
		if (!id) {
			this.setState({ previousForm: null });
			return;
		}
		this.setState({ loadingPrevious: true });
		API.get("/api/goals/" + id + ".json")
			.then((result) => {
				this.setState(
					{
						loadingPrevious: false,
						previousForm: result.data.goal,
					},
					this.props.updateState({ rnd: Math.random() })
				);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingPrevious: false });
			});
	}

	// saveForm() {
	// 	API.put("/api/goals/" + this.state.id + ".json", this.state.form)
	// 		.then((result) => {
	// 			this.setState({
	// 				form: result.data.goal,
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			toastr.error(error);
	// 		});
	// }

	isSameWeek({ goalStart, goalEnd, from, to } = {}) {
		const monthRange = moment.range(goalStart, goalEnd);
		const weeks = [...new Set([...monthRange.by("week")].map((week) => week.year() + "-" + week.isoWeek()))];

		return weeks.some((w) => w === moment(from).year() + "-" + moment(from).isoWeek());
	}

	getCompareFromDate() {
		if (!this.state.form) return null;
		const subtractYear = this.state.form && this.state.form.compare_with_prev_year;

		if (this.state.interval === "annually") {
			return moment(this.state.from)
				.subtract(1, subtractYear ? "year" : "year")
				.startOf("year")
				.format("YYYY-MM-DD");
		}
		if (this.state.interval === "monthly") {
			const d = moment(this.state.from).subtract(1, subtractYear ? "year" : "month");
			return d.startOf("month").format("YYYY-MM-DD");
		}
		if (this.state.interval === "weekly") {
			const week = moment(this.state.from).isoWeek();

			const d = moment(this.state.from).subtract(1, subtractYear ? "year" : "week");

			if (subtractYear) {
				d.set("isoWeek", week);
			}
			d.format("YYYY-MM-DD");
			return d.startOf("isoWeek").format("YYYY-MM-DD");
		}

		if (this.state.interval === "period") {
			if (!this.state.previousForm || !this.state.previousForm.id) return null;
			return moment(this.state.previousForm.start_at).format("YYYY-MM-DD");
		}
	}

	getCompareToDate() {
		const dayDiff = moment(this.state.to).diff(moment(this.state.from), "days");
		return moment(this.getCompareFromDate()).add(dayDiff, "days").format("YYYY-MM-DD");
	}

	render() {
		if (this.state.loading && (!this.state.form || !this.state.form.id)) {
			return <Skeleton2Col />;
		}

		return (
			<React.Fragment>
				<div className="goals_view_header section_header" style={{ marginBottom: "calc(4rem - 22px)" }}>
					<h1 className="Polaris-DisplayText Polaris-DisplayText--sizeLarge">{this.state.form && this.state.form.title}</h1>

					{/* <Button
						onClick={() => {
							this.props.history.push(`/admin/goals/${this.state.form?.id}/users`, {
								goal: this.state.form,
								stats: this.goalSection1Ref?.state?.stats || [],
							});
						}}
						plain
					>
						{this.props.t("goals.user.actions.edit", "Redigera användare")}
					</Button> */}

					{/* {store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && (
						<Button plain onClick={() => this.setState({ goalModalOpen: true })}>
							<div className="inner_button">
								Redigera målsättning <Icon source={EditMajor} />
							</div>
						</Button>
					)} */}
				</div>

				<div style={{ position: "relative" }}>
					<div className="goal_sections-wrapper">
						<GoalViewSection
							history={this.props.history}
							fetchItem={this.fetchItem.bind(this)}
							setDates={(from, to, interval) => {
								// Find matching goals
								const firstGoal = this.state.previous_goals.find((goal) => {
									if (interval == "weekly") {
										const isSameWeek = this.isSameWeek({ goalStart: goal.start_at, goalEnd: goal.end_at, from, to });
										return isSameWeek;
									} else {
										return moment(from) >= moment(goal.start_at) && moment(to) <= moment(goal.end_at);
									}
								});
								if (firstGoal.id != this.state.form.id) {
									this.fetchItem(firstGoal.id, false, false);
									this.setState({ previousForm: null });
								}

								this.setState({
									from,
									to,
									interval,
								});
							}}
							section={1}
							mainSection
							firstDate={this.state.firstDate}
							ref={(ref) => {
								this.goalSection1Ref = ref;
							}}
							loading={this.state.loading}
							title=""
							color={Colors.green}
							sortByPercentage={this.state.sortByPercentage}
							updateGoal={(form) => {
								this.setState({ form });
							}}
							goal={this.state.form || {}}
							onChange={(state) => {
								this.setState((c) => Object.assign({}, c, state || {}));
							}}
							from={this.state.from}
							to={this.state.to}
							previous_goals={this.state.previous_goals}
							previousGoal={this.state.previousForm}
						/>
						<GoalViewSection
							ref={(ref) => {
								// eslint-disable-next-line react/no-unused-class-component-methods
								this.goalSection2Ref = ref;
							}}
							loading={this.state.loading || this.state.loadingPrevious}
							title={this.props.t("goals.compare_goal_view_title", "Jämför med")}
							color={Colors.blue}
							sortByPercentage={this.state.sortByPercentage}
							updateGoal={(form) => {
								this.setState({
									form,
								});
							}}
							goal={this.state.previousForm}
							from={this.getCompareFromDate()}
							to={this.getCompareToDate()}
						/>
					</div>
				</div>

				<GoalModal
					open={this.state.goalModalOpen}
					goal={this.state.form || {}}
					onClose={() => {
						this.setState({ goalModalOpen: false });
					}}
					onCreate={(goal) => {
						this.setState(
							{
								goalModalOpen: false,
								form: goal,
							},
							() => {
								if (this.goalSection1Ref) {
									this.goalSection1Ref.fetchStats(this.state.form);
								}
							}
						);
					}}
				/>
			</React.Fragment>
		);
	}
}

export default withTranslation(["goals", "common"], {
	withRef: true,
})(GoalView);
