/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";
import { Filters, TextField, DatePicker, FormLayout, Button, Modal, Stack, Tag, OptionList, Checkbox } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import SearchField from "./search_field.js";
import { store } from "../store";
import ComboboxFilter from "./Filters/ComboBoxFilter.tsx";
import NewDatePicker from "./NewDatePicker/index.js";
import MyFilterOptionList from "./Filters/MyFilterOptionList";
import Popover from "./Popover.js";

class MyFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.timeout = null;

		for (let i = 0; i < props.filters?.length; i++) {
			const key = props.filters[i].key;
			this.state[key] = null;
		}

		for (let i = 0; i < props.appliedFilters?.length; i++) {
			const key = props.appliedFilters[i].key;
			this.state[key] = props.appliedFilters[i].value;
		}

		this.searchValue = props.searchValue;
	}

	UNSAFE_componentWillReceiveProps(props) {
		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.state) {
			this.state[i] = null;
		}
		for (let i = 0; i < props.filters?.length; i++) {
			const key = props.filters[i].key;
			this.state[key] = null;
		}

		for (let i = 0; i < props.appliedFilters?.length; i++) {
			const key = props.appliedFilters[i].key;
			this.state[key] = props.appliedFilters[i].value;
		}

		if (!this.typing) {
			this.searchValue = props.searchValue;
		}
		this.setState(this.state);
	}

	getValue(key) {
		return this.state[key];
	}

	setValue(key, type, value) {
		const filter = this.props.filters.find((filter) => filter.key === key);
		if (type == "singlearray") {
			value = value[0];
			if (filter.type === "dateSelector" && (value == "custom" || value.substring(0, 1) == "2" || value.substring(0, 1) == "1")) {
				let now = new Date();
				let fromDate = new Date();
				let toDate = new Date();
				toDate.setDate(toDate.getDate() + 7);

				if (value.substring(0, 1) == "2" || value.substring(0, 1) == "1") {
					if (filter.range) {
						const pcs = value.split(" - ");
						now = new Date(pcs[0]);
						fromDate = new Date(pcs[0]);
						toDate = new Date(pcs[1]);
					} else {
						now = new Date(value);
						toDate = new Date(value);
						fromDate = new Date(value);
					}
				}

				this.datePickerOpen = true;
				this.month = now.getMonth();
				this.year = now.getFullYear();
				this.filter = filter;
				this.selection = {
					start: fromDate,
					end: toDate,
				};
				this.setState(this.state);
				return;
			}
		} else if (type == "resource") {
			value = value?.id || value;
		} else if (type == "date-range") {
			const upd = {
				[filter.key]: value.start,
				[filter.secondKey]: value.end,
			};

			this.setState(upd, () => {
				this.onChange();
				if (filter?.onChange) filter.onChange(value);
			});
			return;
		}

		const upd = {};
		upd[key] = value;
		if (filter?.type === "date" && filter?.range && key === filter.key && !value) {
			upd[filter.secondKey] = null;
		}

		this.setState(upd, () => {
			this.onChange();
			if (filter?.onChange) filter.onChange(value);
		});
	}

	setDelayedValue(key, value) {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		const upd = {};
		upd[key] = value;
		this.setState(upd, () => {
			this.timeout = setTimeout(this.onChange.bind(this), 300);
		});
	}

	disambiguateLabel(key, value) {
		for (let i = 0; i < this.props.filters.length; i++) {
			if (key == "user_id") {
				const user = this.getUserFromId(this.state.user_id);
				if (user) {
					value = user.fullname;
				}
			} else if (key == "contact_id") {
				const contact = this.getContactFromId(this.state.contact_id);
				if (contact) {
					value = contact.name;
				}
			} else if (key == "group_id") {
				const group = this.getGroupFromId(this.state.group_id);
				if (group) {
					value = group.name;
				}
			} else if (this.props.filters[i].key == key && this.props.filters[i].type == "date" && this.props.filters[i].range) {
				const valueEnd = this.state[this.props.filters[i].secondKey];
				return (
					<span>
						{this.props.filters[i].label} {value}
						{" - "}
						{valueEnd || "..."}
					</span>
				);
			} else if (this.props.filters[i].secondKey == key && this.props.filters[i].type == "date" && this.props.filters[i].range) {
				//this is handled in the first key above
				return null;
			} else if (this.props.filters[i].key == key) {
				if (this.props.filters[i].type == "select") {
					for (let s = 0; s < this.props.filters[i].options.length; s++) {
						if (this.props.filters[i].options[s].value == value) {
							if (this.props.filters[i].options[s].labelPlain) {
								value = this.props.filters[i].options[s].labelPlain;
							} else {
								value = this.props.filters[i].options[s].label;
							}
							break;
						}
					}
				} else if (this.props.filters[i].type == "dateSelector") {
					const options = this.getDateOptions(this.props.filters[i]);
					for (let s = 0; s < options.length; s++) {
						if (options[s].value == value) {
							if (options[s].labelPlain) {
								value = options[s].labelPlain;
							} else {
								value = options[s].label;
							}
							break;
						}
					}
				} else if (this.props.filters[i].type == "options" && this.props.filters[i].allowMultiple) {
					const options = this.props.filters[i].options.filter((option) => value.includes(option.value));
					value = options.map((option) => option.label).join(", ");
				} else if (this.props.filters[i].type == "checkbox") {
					if (this.props.filters[i].options) {
						value = this.props.filters[i].options.find((option) => option.value == value).label;
					} else {
						value = value ? this.props.t("common.yes", "Ja") : this.props.t("common.no", "Nej");
					}
				}

				return (
					<span>
						{this.props.filters[i].label}
						{this.props.filters[i].operatorText ? " " + this.props.filters[i].operatorText : ""} {value}
					</span>
				);
			}
		}
		let keyLabel = key;

		if (key == "user_id") {
			keyLabel = this.props.t("filters.labels.coworkers", "Medarbetare");
		}
		if (key == "group_id") {
			keyLabel = this.props.t("filters.labels.group", "Grupp");
		}
		return (
			<span>
				{keyLabel}: {value}
			</span>
		);
	}

	onChange() {
		const arr = [];
		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.state) {
			if (!this.isEmpty(this.state[i])) {
				arr.push({ key: i, value: this.state[i] });
			}
		}

		this.props.onFiltersChange(arr);
	}

	isEmpty(value) {
		if (Array.isArray(value)) {
			return value.length === 0;
		} else {
			return value === "" || value == null;
		}
	}

	getDateOptions(filter) {
		let options = [];
		if (filter.future) {
			options = [
				{ value: "past_week_and_earlier", label: this.props.t("filters.fields.future.options.past_week_and_earlier", "förra veckan och tidigare") },
				{ value: "this_week", label: this.props.t("filters.fields.future.options.this_week", "denna veckan") },
				{ value: "next_week", label: this.props.t("filters.fields.future.options.next_week", "nästa vecka") },
				{ value: "this_month", label: this.props.t("filters.fields.future.options.this_month", "denna månaden") },
				{ value: "next_month", label: this.props.t("filters.fields.future.options.next_month", "nästa månad") },
			];
		} else {
			options = [
				{ value: "this_week", label: this.props.t("filters.fields.future.options.this_week", "denna veckan") },
				{ value: "last_week", label: this.props.t("filters.fields.future.options.last_week", "förra veckan") },
				{ value: "this_month", label: this.props.t("filters.fields.future.options.this_month", "denna månaden") },
				{ value: "last_month", label: this.props.t("filters.fields.future.options.last_month", "förra månaden") },
				{ value: "last_30_days", label: this.props.t("filters.fields.future.options.last_30_days", "senaste 30 dagarna") },
				{ value: "last_90_days", label: this.props.t("filters.fields.future.options.last_90_days", "senaste 90 dagarna") },
			];
		}

		if (this.state[filter.key] && (this.state[filter.key].substring(0, 1) == "2" || this.state[filter.key].substring(0, 1) == "1")) {
			options.push({ value: this.state[filter.key], label: (filter.operatorText ? filter.operatorText + " " : "") + this.state[filter.key] });
		} else {
			options.push({ value: "custom", label: this.props.t("filters.fields.future.options.other", "annan period") });
		}

		return options;
	}

	getYmd(start) {
		return start.getFullYear() + "-" + this.leadingZero(start.getMonth() + 1) + "-" + this.leadingZero(start.getDate());
	}

	leadingZero(val) {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	}

	updateSearch(value) {
		clearTimeout(this.timeout);
		this.searchValue = value;
		this.typing = true;
		this.timeout = setTimeout(() => {
			this.props.onSearchChange(this.searchValue);
			this.typing = false;
		}, 1000);
		this.setState({});
	}

	getGroupFromId(id) {
		for (let i = 0; i < store.getState().groups.length; i++) {
			if (store.getState().groups[i].id == id) {
				return store.getState().groups[i];
			}
		}
		return null;
	}

	getUserFromId(id) {
		for (let i = 0; i < store.getState().users.length; i++) {
			if (store.getState().users[i].id == id) {
				return store.getState().users[i];
			}
		}
		return null;
	}

	getContactFromId(id) {
		return store.getState().board_contacts[id];
	}

	addTag(key, tag) {
		const tags = this.getTags(key);

		if (tags.indexOf(tag.title) <= 0) {
			tags.push(tag.title);
		}

		this.setValue(key, "tag", tags.join(", "));
	}

	getTags(key) {
		const value = this.getValue(key);
		let tags = [];
		if (value !== null) {
			tags = value.split(/,\s/gi);
		}
		return tags;
	}

	removeTag(key, index) {
		const tags = this.getTags(key);
		tags.splice(index, 1);

		if (tags.length < 1) {
			this.setValue(key, "tag", null);
			return;
		}
		this.setValue(key, "tag", tags.join(", "));
	}

	render() {
		const filters = [];

		for (let i = 0; i < this.props.filters.length; i++) {
			const filter = this.props.filters[i];
			let component = null;
			let options = [];
			if (filter.type === "select") {
				component = (
					<div style={{ marginLeft: "-0.5rem", marginRight: "-0.5rem" }}>
						<OptionList
							options={filter.options}
							selected={this.state[filter.key] ? [this.state[filter.key]] : []}
							onChange={this.setValue.bind(this, filter.key, "singlearray")}
						/>
					</div>
				);
			} else if (filter.type === "options") {
				component = (
					<div style={{ marginLeft: "-0.5rem", marginRight: "-0.5rem" }}>
						<MyFilterOptionList
							filter={filter}
							selected={this.state[filter.key] ? this.state[filter.key] : []}
							onChange={this.setValue.bind(this, filter.key, null)}
						/>
					</div>
				);
			} else if (filter.type === "dateSelector") {
				options = this.getDateOptions(filter);
				component = (
					<div style={{ marginLeft: "-0.5rem", marginRight: "-0.5rem" }}>
						<OptionList
							options={options}
							selected={this.state[filter.key] ? [this.state[filter.key]] : []}
							onChange={this.setValue.bind(this, filter.key, "singlearray")}
						/>
					</div>
				);
			} else if (filter.type === "date") {
				component = (
					<div style={{ marginLeft: "-0.5rem", marginRight: "-0.5rem" }}>
						<NewDatePicker
							style={{ width: "100%", maxWidth: "100%" }}
							range={filter.range}
							expanded
							optionalTime={filter.optionalTime}
							value={
								filter.range
									? {
											start: this.state[filter.key],
											end: this.state[filter.secondKey],
									  }
									: this.state[filter.key]
							}
							onChange={this.setValue.bind(this, filter.key, `date${filter.range ? "-range" : ""}`)}
						/>
						{/* <TextField value={this.state[filter.key]} onChange={this.setValue.bind(this, filter.key, "date")} type="date" /> */}
					</div>
				);
			} else if (filter.type === "textField" || filter.type === "text") {
				component = (
					<FormLayout>
						<TextField value={this.state[filter.key]} onChange={this.setDelayedValue.bind(this, filter.key)} />
					</FormLayout>
				);
			} else if (filter.type === "checkbox") {
				if (filter.options) {
					component = (
						<div style={{ marginLeft: "-0.5rem", marginRight: "-0.5rem" }}>
							<OptionList
								options={filter.options}
								selected={filter.key in this.state ? [this.state[filter.key]] : []}
								onChange={(v) => this.setValue(filter.key, null, v[0])}
							/>
						</div>
					);
				} else {
					component = (
						<FormLayout>
							<Checkbox label={filter.label} checked={!!this.state[filter.key]} onChange={this.setValue.bind(this, filter.key, "boolean")} />
						</FormLayout>
					);
				}
			} else if (filter.type === "contact_tag") {
				component = (
					<Stack vertical>
						<SearchField
							resource="contacts/tags"
							inline
							autoFocus
							onSelect={this.addTag.bind(this, filter.key)}
							resourceName={{ singular: this.props.t("filters.tag.singular", "tagg"), plural: this.props.t("filters.tag.plural", "taggar") }}
							resource_handle="tags"
							id_handle="title"
							label_handle="title"
						/>
						{this.getTags(filter.key).map((tag, index) => (
							<Tag key={tag?.id || index} onRemove={this.removeTag.bind(this, filter.key, index)}>
								{tag}
							</Tag>
						))}
					</Stack>
				);
			} else if (filter.type === "contact_id") {
				component = (
					<ComboboxFilter
						onSelect={(contact) => {
							store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact });
							this.setValue(filter.key, "resource", contact);
						}}
						value={this.getContactFromId(this.state.contact_id)?.name || null}
						resource="contacts"
						resourceName={{
							singular: this.props.t("filters.contact.singular", "kontakt"),
							plural: this.props.t("filters.contact.plural", "kontakter"),
						}}
						resource_handle="contacts"
						// id_handle="title"
						label_handle="name"
						onRemove={this.setValue.bind(this, "contact_id", null, null)}
					/>
				);
			} else if (filter.type === "contact_company_tag") {
				component = (
					<ComboboxFilter
						onSelect={this.addTag.bind(this, filter.key)}
						value={this.getTags(filter.key)}
						resource="contacts/tags.json?type=company"
						resourceName={{ singular: this.props.t("filters.tag.singular", "tagg"), plural: this.props.t("filters.tag.plural", "taggar") }}
						resource_handle="tags"
						// id_handle="title"
						label_handle="title"
						onRemove={this.removeTag.bind(this, filter.key)}
					/>
				);
			} else if (filter.type === "contact_person_tag") {
				component = (
					<ComboboxFilter
						onSelect={this.addTag.bind(this, filter.key)}
						value={this.getTags(filter.key)}
						resource="contacts/tags.json?type=person"
						resourceName={{ singular: this.props.t("filters.tag.singular", "tagg"), plural: this.props.t("filters.tag.plural", "taggar") }}
						resource_handle="tags"
						// id_handle="title"
						label_handle="title"
						onRemove={this.removeTag.bind(this, filter.key)}
					/>
				);
			} else if (filter.type === "groupuser") {
				component = (
					<Stack vertical>
						<SearchField
							inline
							autoFocus
							resource="usergroups.json?enabled=1"
							items={store.getState().groups.concat(store.getState().users)}
							onSelect={(v) => {
								if (v.is_group == "1" || v.is_group == 1) {
									this.setValue("group_id", "resource", v);
									this.setValue("user_id", null, null);
								} else {
									this.setValue("user_id", "resource", v);
									this.setValue("group_id", null, null);
								}
							}}
							resourceName={{
								singular: this.props.t("filters.usergroups.singular", "användare/grupp"),
								plural: this.props.t("filters.usergroups.plural", "användare/grupper"),
							}}
							resource_handle="usergroups"
							id_handle="id"
							label_handle="fullname"
						/>
						{this.state.user_id ? (
							<Tag onRemove={this.setValue.bind(this, "user_id", null, null)}>{this.getUserFromId(this.state.user_id).fullname}</Tag>
						) : null}
						{this.state.group_id ? (
							<Tag onRemove={this.setValue.bind(this, "group_id", null, null)}>{this.getGroupFromId(this.state.group_id).fullname}</Tag>
						) : null}
					</Stack>
				);
			} else if (filter.type === "group") {
				component = (
					<ComboboxFilter
						options={store.getState().groups?.map((group) => ({ value: group.id, label: group.fullname }))}
						onSelect={this.setValue.bind(this, filter.key, "resource")}
						value={this.state[filter.key]}
						resourceName={{
							singular: this.props.t("filters.groups.singular", "grupp"),
							plural: this.props.t("filters.groups.plural", "grupper"),
						}}
					/>
				);
			} else if (filter.type === "user") {
				component = (
					<ComboboxFilter
						options={store.getState().users?.map((user) => ({ value: user.id, label: user.name }))}
						onSelect={this.setValue.bind(this, filter.key, "resource")}
						value={this.getUserFromId(this.state.user_id)?.fullname || null}
						onRemove={this.setValue.bind(this, "user_id", null, null)}
						resourceName={{
							singular: this.props.t("filters.users.singular", "användare"),
							plural: this.props.t("filters.users.plural", "användare"),
						}}
					/>
				);
			}

			filters.push({
				key: filter.key,
				label: filter.label,
				filter: component,
				shortcut: filter.shortcut,
			});
		}

		const appliedFilters = Object.keys(this.state)
			.filter((key) => !this.isEmpty(this.state[key]))
			.map((key) => ({
				key,
				label: this.disambiguateLabel(key, this.state[key]),
				onRemove: this.setValue.bind(this, key, null, null),
			}))
			?.filter((filter) => filter.label);

		const additionalAction = (() => {
			if (!this.props.additionalAction) return null;
			if (typeof this.props.additionalAction === "function") return this.props.additionalAction();
			if (React.isValidElement(this.props.additionalAction)) return this.props.additionalAction;

			const { content, onAction, styles, ...rest } = this.props.additionalAction || {};

			return (
				<div style={{ paddingLeft: "8px", ...(styles || {}) }}>
					<Button {...rest} onClick={onAction}>
						{content}
					</Button>
				</div>
			);
		})();

		return (
			<div>
				<FilterWrapper>
					{this.props.prefix}
					<Filters
						hideQueryField={this.props.hideQueryField}
						queryValue={this.searchValue}
						filters={filters}
						appliedFilters={appliedFilters}
						onQueryChange={this.updateSearch.bind(this)}
						onQueryClear={() => {
							this.searchValue = "";
							this.props.onSearchChange("");
						}}
						onClearAll={() => {
							// eslint-disable-next-line no-restricted-syntax
							for (const i in this.state) {
								this.state[i] = null;
							}
							this.searchValue = "";
							this.setState(this.state, this.onChange.bind(this));
						}}
					>
						<div style={{ display: "flex", gap: "1rem", marginLeft: "1rem" }}>
							{this.props.hideQueryField &&
								filters
									.filter((f) => f.shortcut)
									.map((f) => {
										return (
											<Popover activator={<Button>{f.label}</Button>} key={f.key} sectioned>
												{f.filter}
											</Popover>
										);
									})}
							{this.props.children}
						</div>
					</Filters>
					{additionalAction && <AdditionalActionWrapper>{additionalAction}</AdditionalActionWrapper>}
				</FilterWrapper>
				<Modal
					open={this.datePickerOpen}
					onClose={() => {
						this.datePickerOpen = false;
						this.setState(this.state);
					}}
					title={this.props.t("filters.terms.date.other_period", "Annan period")}
					primaryAction={{
						content: this.props.t("common.actions.choose", "Välj"),
						onAction: () => {
							this.datePickerOpen = false;
							const data = {};
							data[this.filter.key] = this.getYmd(this.selection.start);
							if (this.filter.range) {
								data[this.filter.key] += " - " + this.getYmd(this.selection.end);
							}
							this.setState(data, () => {
								if (this.filter.onChange) this.filter.onChange(data[this.filter.key]);
								this.onChange();
							});
						},
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.datePickerOpen = false;
								this.setState(this.state);
							},
						},
					]}
				>
					<Modal.Section>
						{this.filter ? (
							<DatePicker
								month={this.month}
								year={this.year}
								weekStartsOn={1}
								onChange={(selection) => {
									this.selection = selection;
									this.setState(this.state);
								}}
								onMonthChange={(month, year) => {
									this.month = month;
									this.year = year;
									this.setState(this.state);
								}}
								selected={this.selection}
								disableDatesAfter={this.filter.future || this.filter.range ? null : new Date()}
								disableDatesBefore={this.filter.future && !this.filter.range ? new Date() : null}
								multiMonth={!!this.filter.range}
								allowRange={!!this.filter.range}
							/>
						) : null}
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}

MyFilters.defaultProps = {
	filters: [],
};

const mapStateToProps = (state) => ({
	users: state.users,
	groups: state.groups,
});

export default connect(mapStateToProps)(withTranslation(["filters", "board", "common"], { withRef: true })(MyFilters));

const FilterWrapper = styled.div`
	display: flex;
	gap: 1rem;

	.Polaris-Filters {
		flex: 1;
	}
`;
const AdditionalActionWrapper = styled.div`
	.Polaris-Button {
		min-height: 36px;
	}

	.Polaris-Button.Polaris-Button--plain {
		margin: 0;
	}
`;
