import { Icon } from "@shopify/polaris";
import { QuestionMarkInverseMajor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import API from "src/js/API";
import styled, { css } from "styled-components";
import debounce from "lodash/debounce";
import { CircleCancel } from "src/js/icons";
import { getLocalStorage, setLocalStorage } from "src/js/Utilities";
import { store } from "src/js/store";
import axios from "axios";

type FAQ = {
	id: number;
	answer: string;
	question: string;
	type: "faq";

	path?: string;
};

type Box = {
	id: number;
	title: string;
	text: string;
	icon?: string;
	color?: string;
	type: "box";

	path?: string;
};

type Item = FAQ | Box;

const matchPath = (path: string, location: any) => {
	const pattern = new RegExp(`^${path.replace(/:\w+/g, "(\\d+)")}$`);
	return location.pathname.match(pattern);
};

const replaceVariables = (path: string, variables: { [key: string]: string | number }) => {
	const result = Object.keys(variables).reduce((acc: string, key: string) => {
		const pattern = new RegExp(`:${key}`, "g");
		return acc.replace(pattern, variables[key] as string);
	}, path);

	return result;
};

const Clippy = ({ history }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [items, setItems] = useState<Item[] | undefined>();
	const [activeItem, setActiveItem] = useState<Item | null>(null);
	const previousPath = React.useRef<string>();

	useEffect(() => {
		API.get("/api/helpcenter.json")
			.then((result) => {
				if (result.data.error) {
					console.error("Clippy error:", result.data.error);
					setIsOpen(false);
					return;
				}

				setItems(result.data.items || []);
			})
			.catch((error) => {
				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}
				setIsOpen(false);
			})
			.finally(() => {});
	}, []);

	useEffect(() => {
		const variables = {
			account_id: store.getState().account.id,
			user_id: store.getState().user.id,
			sales_board_id: store.getState().account.sales_board_id,
		};

		const setOpenDelayed = debounce(() => {
			setIsOpen(true);
		}, 1500);

		const handleChange = (location: any) => {
			if (previousPath.current === location.pathname && items) return;
			const matchedRoute = items?.find((item: Item) => matchPath(replaceVariables(item.path || "", variables), location)) || null;
			setIsOpen(false);

			if (items) previousPath.current = location.pathname;
			if (getLocalStorage("closedClippy")?.includes(matchedRoute?.id)) {
				setActiveItem(null);
				setIsOpen(false);
			} else {
				setActiveItem(matchedRoute);
				setOpenDelayed();
			}
		};

		handleChange(history.location);

		const unlisten = history.listen(handleChange);

		return () => {
			unlisten();
			setOpenDelayed.cancel();
		};
	}, [history, items]);

	const handleToggle = () => {
		setIsOpen((c) => !c);
	};

	if (!activeItem) return null;

	const handleNavigateToHelpPage = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		history.push(`/admin/help/${activeItem.id}`);
	};

	const handleClose = () => {
		setLocalStorage("closedClippy", [...(getLocalStorage("closedClippy") || []), activeItem.id]);
		setActiveItem(null);
	};

	return (
		<Wrapper>
			<InnerWrapper>
				<TextWrapper isOpen={isOpen} onClick={handleNavigateToHelpPage}>
					<p>Frågor och svar</p>
				</TextWrapper>
				<Circle onClick={handleToggle}>
					<Icon source={QuestionMarkInverseMajor} />
				</Circle>
				<CloseButton onClick={handleClose}>
					<CircleCancel />
				</CloseButton>
			</InnerWrapper>
		</Wrapper>
	);
};
export default Clippy;

const Wrapper = styled.div`
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 999999;
`;
const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
	overflow: hidden;
	border-radius: 0px 100px 100px 0px;
`;

const Circle = styled.button`
	border-radius: 50%;
	background-color: #293268;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 15px;
	z-index: 1;
	border: none;
	outline: none;
	cursor: pointer;
	position: relative;

	.Polaris-Icon {
		height: 24px;
		width: 24px;
	}
`;

const CloseButton = styled.button`
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9999999;
	border: none;
	background: transparent;
	outline: none;
	cursor: pointer;
	transform: translate(50%, -50%);
	transition: filter 0.15s ease-in-out;

	&:hover {
		filter: brightness(1.2);
	}
`;

const TextWrapper = styled.div<{ isOpen?: boolean }>`
	padding: 10px;
	border-radius: 6px;
	background-color: #293268;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	color: white;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	height: max-content;
	opacity: 0;
	transition: transform 0.3s ease-in-out, opacity 0.15s ease-in-out, filter 0.15s ease-in-out;
	cursor: pointer;
	transform: translateX(calc(110% + 1rem));

	&:hover {
		filter: brightness(1.2);
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: translateX(0%);
			opacity: 1;

			cursor: pointer;
		`}
`;
