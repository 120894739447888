import React, { Component } from "react";
import { Icon } from "@shopify/polaris";
import { CircleCancelMinor, CustomersMinor, InfoMinor, NoteMajor } from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
import BoardHelper from "./BoardHelper";
import { store } from "../../store";
import Popover from "../../components/Popover";

class CellCompanyNumber extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false, value: props.value || { number: "" } };
		this.tryConnectContact(props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value || { number: "" } }, this.tryConnectContact(props));
	}

	toggle() {
		if (this.props.readonly || this.props.disabled) return;

		if (!this.props.onChange) return;
		if (!this.state.editing) {
			this.props.onFocus?.();
		} else {
			this.props.onChange({ value: this.state.value });
			this.props.onBlur?.();
		}
		this.setState({ editing: !this.state.editing });
	}

	clear(event) {
		event.stopPropagation();
		this.state.value.number = "";
		this.props.onChange({ value: this.state.value });
		this.setState({ value: this.state.value });
	}

	tryConnectContact(props) {
		if (this.state.value.number) {
			const hit = BoardHelper.getCompanyNumber(this.state.value.number);
			if (hit && props.row) {
				if (props.column.connected_column_id) {
					// const board = BoardHelper.getBoard(props.column.board_id);
					if (
						!(props.column.connected_column_id in props.row.column_values) ||
						props.row.column_values[props.column.connected_column_id].value == "[]" ||
						props.row.column_values[props.column.connected_column_id].value === null

						// (!(props.column.connected_column_id in props.row.column_values) ||
						// 	props.row.column_values[props.column.connected_column_id].value == "[]" ||
						// 	props.row.column_values[props.column.connected_column_id].value === null) &&
						// board?.columns?.find((col) => col.id == props.column.connected_column_id)
					) {
						for (let i = 0; i < hit.length; i++) {
							if (hit[i].is_company) {
								this.connectContact(props, hit[i]);
								break;
							}
						}
					}
				}
			}
		}
	}

	connectContact(props, contact) {
		const board = BoardHelper.getBoard(props.row.board_id);
		const column = board.columns.find((col) => col.id == props.column.connected_column_id);
		store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: contact || {} });
		BoardHelper.onUpdateValue(props.row, column, { value: [contact.id] });
	}

	render() {
		const companies = BoardHelper.getCompanyNumber(this.state.value.number);

		return (
			<div
				className={
					"overflow-ellipsis board-editable" +
					(this.state.editing ? " focused" : "") +
					(!this.props.onChange || this.props.disabled ? " disabled" : "")
				}
				style={{
					height: "100%",
					width: "100%",
					cursor: "text",
					textAlign: "center",
					lineHeight: this.props.height ? this.props.height + "px" : null,
					backgroundColor: this.state.value.number && companies?.length ? "red" : this.state.editing ? "transparent" : null,
					display: "flex",
					gap: "0.5rem",
					alignItems: "center",
					justifyContent: "center",
				}}
				onClick={this.toggle.bind(this)}
			>
				{this.state.editing ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
						onDragStart={(event) => {
							event.stopPropagation();
							event.preventDefault();
						}}
						draggable
					>
						<input
							ref={(input) => {
								if (input) {
									input.focus();
								}
							}}
							onChange={(event) => {
								this.state.value.number = event.target.value;
								this.setState({ value: this.state.value });
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									this.toggle();
								} else if (event.key === "Escape") {
									this.setState({ value: this.props.value }, this.toggle.bind(this));
								}
							}}
							onBlur={this.toggle.bind(this)}
							type="text"
							value={this.state.value.number}
							style={{
								textAlign: "center",
								lineHeight: this.props.height ? this.props.height - 1 + "px" : null,
								display: "block",
								width: "100%",
								border: 0,
							}}
						/>
					</div>
				) : this.state.value ? (
					this.state.value.number
				) : null}
				{this.state.value && this.state.value.number && !this.props.noclear ? (
					<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
						<Icon source={CircleCancelMinor} />
					</div>
				) : null}
				{!!companies?.length && (
					<span className="cell_company_number__confilct_button">
						<Popover
							sectioned
							active={this.state.conflictPopoverIsOpen}
							activator={
								<button
									style={{ border: "none", background: "transparent", cursor: "pointer" }}
									onClick={(e) => {
										e.stopPropagation();
										this.setState({ conflictPopoverIsOpen: true });
									}}
								>
									<Icon source={InfoMinor} />
								</button>
							}
							onClose={() => {
								this.setState({ conflictPopoverIsOpen: false });
							}}
						>
							<label style={{ marginBottom: "1rem", display: "block" }}>Detta orgnr finns i dessa boards/kontakter</label>
							{!!companies.filter((v) => v.board_id)?.length && (
								<div>
									<p>Boards</p>
									<ul style={{ marginTop: 0, listStyle: "none", paddingLeft: "1rem" }}>
										{companies
											.filter((v) => v.board_id)
											.map((v, index) => {
												const board = BoardHelper.getBoard(v.board_id);
												return (
													<li key={index} className="cell_company_conflict_li">
														<Link to={`/admin/workspaces/${board?.workspace_id || "none"}/boards/${v.board_id}?row_id=${v.id}`}>
															<Icon source={NoteMajor} /> {v?.title}
														</Link>
													</li>
												);
											})}
									</ul>
								</div>
							)}
							{!!companies.filter((v) => v.is_company)?.length && (
								<div>
									<p>Kontakter</p>
									<ul style={{ marginTop: 0, listStyle: "none", paddingLeft: "1rem" }}>
										{companies
											.filter((v) => v.is_company)
											.map((v, index) => (
												<li key={index} className="cell_company_conflict_li">
													<Link to={`/admin/contacts/${v.id}`}>
														<Icon source={CustomersMinor} /> {v?.title}
														{v?.name}
													</Link>
												</li>
											))}
									</ul>
								</div>
							)}
						</Popover>
					</span>
				)}
			</div>
		);
	}
}
export default CellCompanyNumber;
