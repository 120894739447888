import React, { useEffect, useRef, useState } from "react";
import { Button } from "@shopify/polaris";
import { WithTranslation, withTranslation } from "react-i18next";
import { store } from "src/js/store";
import RowModal from "src/js/views/Workspaces/components/RowModal";
import CalendarTaskModal from "src/js/views/Agenda/CalendarTaskModal";
import { connect } from "react-redux";
import { getRowTypeLabel } from "src/js/views/Workspaces/boardutils/BoardUtils";
import SearchField from "../search_field";
import Modal from "../modal";
import ModalOptionBox from "../ModalOptionBox";

interface AdditionCommentActionProps extends WithTranslation {
	open: boolean;
	onClose?: () => void;
	inline?: boolean;
	onSuccess: (comment: any) => void;
	comment?: CommentType;
	activity?: ContactActivityType | null | undefined;

	contacts?: ContactType[];
	primaryActionContent?: string;
	disabled?: boolean;
	loading?: boolean;
	submitSuffix?: string;
	submitPrefix?: string;
	options?: any;
}

const AdditionCommentAction = ({
	open,
	onClose,
	inline,
	onSuccess,
	comment,
	contacts,
	activity,
	primaryActionContent,
	disabled: propsDisabled,
	loading,
	submitSuffix,
	submitPrefix,
	options,
	t,
}: AdditionCommentActionProps) => {
	const getDefaultForm = (cm?: CommentType) => {
		return cm
			? { ...cm }
			: {
					files: [],
					mentions: [],
			  };
	};
	const [form, setForm] = useState<any>(getDefaultForm(comment));
	const [newDealOption, setNewDealOption] = useState<any>();
	const submitted = useRef(inline);

	useEffect(() => {
		if (!inline) setForm(getDefaultForm(comment));
	}, [comment, inline]);

	useEffect(() => {
		if (inline !== undefined) submitted.current = inline;
		setNewDealOption(null);
	}, [open, inline]);

	const handleClose = () => {
		onClose?.();
		setNewDealOption(null);
		setForm(getDefaultForm(comment));
	};

	const handleSubmit = (paramForm = form) => {
		submitted.current = true;

		const newForm = { ...paramForm, activity };

		if (["new_deal", "follow_up"].includes(newForm.activity?.type)) {
			newForm.content += `\n\n(Skapade ${getRowTypeLabel(paramForm.row)} "${paramForm?.row?.title}")`;
		}
		setForm(newForm);
		onSuccess(newForm);

		// Kanske inte ska stänga modalen här?
		// Men t.ex. om det inte finns någon aktivitet så körs handleSubmit direkt men om man sen inte stänger modalen utanför så kommer den vara kvar öppen men helt tum
		// if (!activity) onClose?.();
	};

	const disabled = (() => {
		switch (activity?.type) {
			case "new_deal":
				return !form.row;
			case "follow_up":
				return !form.row;
			default:
				return false;
		}
	})();

	const content = (() => {
		if (!open) return null;
		switch (activity?.type) {
			case "new_deal": {
				const cont = (() => {
					if (!newDealOption) {
						return (
							<div className="boxes_wrapper">
								<ModalOptionBox title="Befintlig affär" icon={null} description="Sök efter en befintlig affär">
									<Button
										primary
										onClick={() => {
											setNewDealOption("existing");
										}}
										disabled={propsDisabled}
										loading={loading}
									>
										Välj
									</Button>
								</ModalOptionBox>
								<ModalOptionBox title="Ny affär" icon={null} description="Skapa ny affär">
									<Button
										primary
										onClick={() => {
											setNewDealOption("new");
											setForm(getDefaultForm(comment));
										}}
										disabled={propsDisabled}
										loading={loading}
									>
										Välj
									</Button>
								</ModalOptionBox>
							</div>
						);
					}

					if (newDealOption === "new") {
						return (
							<RowModal
								row={comment?.row}
								open
								board={store.getState().boards[store.getState().account.sales_board_id]}
								contact={comment?.contact}
								contacts={contacts}
								onUpdateRow={(row: BoardRowType) => {
									const form = { ...comment, row };
									setForm(form);
								}}
								onUpdateValue={(row: BoardRowType) => {
									const form = { ...comment, row };
									setForm(form);
								}}
								defaultValues={[]}
								getContact={() => {}}
								onClose={() => {
									setNewDealOption(null);
								}}
								onCreateRow={(row) => {
									handleSubmit({ ...comment, row });
								}}
							/>
						);
					}

					return (
						<SearchField
							selected={[form.row?.id]}
							placeholder={`${t("row.deal.singular", "Affär")}..`}
							keepValue
							value={form.row?.title}
							resource="board_rows/search.json"
							params={{
								board_id: store.getState().account.sales_board_id,
							}}
							onSelect={(row) => {
								handleSubmit({ ...form, row });
							}}
							id_handle="id"
							label_handle="title"
							resource_handle="rows"
							resourceName={{
								singular: t("row.deal.singular", "affär"),
								plural: t("row.deal.plural", "affärer"),
							}}
							autoFocus
						/>
					);
				})();

				return cont;
			}
			case "follow_up": {
				return (
					<CalendarTaskModal
						submitSuffix={submitSuffix}
						submitPrefix={submitPrefix}
						open
						onClose={handleClose}
						noModal
						onSuccess={(row) => {
							const form = { ...comment, row };
							setForm(form);
							handleSubmit(form);
						}}
						row={comment?.row}
						options={{ contacts, ...(options || {}) }}
						disabled={propsDisabled}
						loading={loading}
					/>
				);
			}
			default:
				if (submitted.current) return null;
				submitted.current = true;
				requestAnimationFrame(() => {
					handleSubmit();
				});

				return null;
		}
	})();

	if (inline || !content) return content;

	return (
		<Modal
			title={activity?.title || ""}
			open={open}
			onClose={handleClose}
			footer={
				<div style={{ display: "flex", justifyContent: "end", paddingBlock: "1rem", gap: "1rem" }}>
					<Button onClick={handleClose}>{t("common.actions.cancel", "Avbryt") as string}</Button>
					<Button primary onClick={() => handleSubmit()} disabled={disabled}>
						{primaryActionContent || (form.id ? (t("common.actions.save", "Spara") as string) : (t("common.actions.create", "Skapa") as string))}
					</Button>
				</div>
			}
		>
			<Modal.Section>{content}</Modal.Section>
		</Modal>
	);
};

const mapStateToProps = (state) => ({
	boards: state.boards,
	account: state.account,
	// rows: state.board_rows,
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
});

export default connect(mapStateToProps)(withTranslation(["activities", "common"], { withRef: true })(AdditionCommentAction));
