import React, { Component } from "react";
import { Stack, Card, Modal, Button } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import { toastr } from "./toastr.js";
import Money from "./Money.js";

class UsagePackageModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
	}

	getLimit() {
		let limit = 0;
		if (this.props.type == "scrive") {
			limit = this.props.limits ? this.props.limits.scrives_limit : 0;
		}
		if (this.props.type == "companies") {
			limit = this.props.limits ? this.props.limits.company_import_limit : 0;
		}
		if (this.props.type == "emails") {
			limit = this.props.limits ? this.props.limits.emails_limit : 0;
		}

		return limit;
	}

	changePackage(pack) {
		this.setState({ saving: true });
		API.post("/api/account/packages/" + this.props.type + ".json", { limit: pack.limit, price: pack.price }, {})
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("account.usage.responses.package_updated", "Ditt paket har ändrats"));
				if (this.props.onChange) {
					this.props.onChange();
				} else {
					this.props.onClose();
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getPackages() {
		if (this.props.type == "scrive") {
			return [
				{ limit: 10, label: this.props.t("account.usage.scive.options.10", "10 dokument"), price: 0 },
				{ limit: 25, label: this.props.t("account.usage.scive.options.25", "25 dokument"), price: 250 },
				{ limit: 50, label: this.props.t("account.usage.scive.options.50", "50 dokument"), price: 500 },
				{ limit: 100, label: this.props.t("account.usage.scive.options.100", "100 dokument"), price: 1000 },
				{ limit: 500, label: this.props.t("account.usage.scive.options.500", "500 dokument"), price: 5000 },
				{ limit: 1000, label: this.props.t("account.usage.scive.options.1000", "1000 dokument"), price: 10000 },
			];
		}
		if (this.props.type == "companies") {
			return [
				{ limit: 2500, label: this.props.t("account.usage.companies.options.2500", "2500 bolagsimporter"), price: 0 },
				{ limit: 5000, label: this.props.t("account.usage.companies.options.5000", "5000 bolagsimporter"), price: 2500 },
				{ limit: 10000, label: this.props.t("account.usage.companies.options.10000", "10000 bolagsimporter"), price: 7500 },
				{ limit: 50000, label: this.props.t("account.usage.companies.options.50000", "50000 bolagsimporter"), price: 47500 },
			];
		}
		if (this.props.type == "emails") {
			return [
				{ limit: 250, label: this.props.t("account.usage.emails.options.250", "250 e-postkontakter"), price: 0 },
				{ limit: 10000, label: this.props.t("account.usage.emails.options.10000", "10000 e-postkontakter"), price: 3000 },
				{ limit: 50000, label: this.props.t("account.usage.emails.options.50000", "50000 e-postkontakter"), price: 7000 },
				{ limit: 100000, label: this.props.t("account.usage.emails.options.100000", "100000 e-postkontakter"), price: 8250 },
			];
		}

		return [];
	}

	componentDidUpdate() {
		$(".package-popup").closest("[data-portal-id]").addClass("modal-top");
	}

	render() {
		let label = this.props.t("account.usage.labels.usage", "förbrukning");
		if (this.props.type == "scrive") {
			label = this.props.t("account.usage.labels.scrive", "Scrive signeringar");
		}
		if (this.props.type == "companies") {
			label = this.props.t("account.usage.labels.companies", "Bolagsimporter");
		}
		if (this.props.type == "emails") {
			label = this.props.t("account.usage.labels.emails", "E-postutskick");
		}

		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("account.usage.titles.change_package_for", "Ändra paket för") + " " + label}
				secondaryActions={{
					content: this.props.t("common.actions.close", "Stäng"),
					onAction: this.props.onClose,
				}}
			>
				<Modal.Section>
					<div className="package-popup">
						{this.props.t("account.usage.text.current_limit", "Din nuvarande gräns är")} {this.getLimit()}
					</div>
				</Modal.Section>
				<Modal.Section>
					<Stack distribution="fillEvenly">
						{this.getPackages().map((pack, key) => (
							<Card key={key} title={pack.label}>
								<Card.Section>
									{pack.price ? (
										<span>
											<Money amount={pack.price} currency="SEK" />
											/mån
										</span>
									) : (
										this.props.t("account.usage.terms.free", "gratis")
									)}
								</Card.Section>
								<Card.Section>
									<Button loading={this.state.saving} primary onClick={this.changePackage.bind(this, pack)}>
										{this.props.t("common.actions.choose", "välj")}
									</Button>
								</Card.Section>
							</Card>
						))}
					</Stack>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["account", "common"], { withRef: true })(UsagePackageModal);
