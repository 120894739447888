import React, { useEffect, useState } from "react";
import { FormLayout, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import Modal from "src/js/components/modal";

const PersonColumnModal = ({ board, column, onUpdateColumn, t, open, onClose }) => {
	const [personColumnId, setPersonColumnId] = useState(column.link_column_id || "");

	useEffect(() => {
		setPersonColumnId(column.link_column_id || "");
	}, [column]);

	const getColumns = (types = ["person"]) => {
		return board.columns.filter((column) => types.includes(column.type));
	};

	const saveForm = async () => {
		const data = {
			link_column_id: personColumnId,
		};

		try {
			const result = await API.put("/api/boards/" + board.id + "/columns/" + column.id + ".json", data);

			onUpdateColumn?.(result.data.column);
			toastr.success(t("board.columns.settings.connect_person.success", "Kolumnen har kopplats ihop"));
			onClose();

			return result.data.column;
		} catch (e) {
			console.error("Error", e);
			toastr.error(e);
		}
	};

	const options = getColumns().map((column) => ({
		value: column.id + "",
		label: column.title,
	}));

	options.unshift({
		value: "",
		label: t("column.actions.choose", "Välj kolumn"),
	});

	return (
		<Modal
			open={open}
			onClose={onClose}
			title={t("board.columns.settings.connect_person.title", "Koppla ihop kolumner")}
			primaryAction={{
				// disabled: !personColumnId,
				onAction: saveForm,
				// loading: this.state.saving,
				content: t("board.columns.settings.connect_person.title", "Koppla ihop kolumner"),
			}}
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng"),
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<FormLayout>
					<p>
						{t(
							"board.columns.settings.connect_person.description",
							"Koppla denna kolumns värden mot en användare genom att välja en person kolumn. Detta användes sen t.ex. i Dashboard för att visa data om användaren."
						)}
					</p>
					<Select
						label={t("board.columns.settings.connect_person.select_column", "Välj person kolumn")}
						options={options}
						value={String(personColumnId)}
						onChange={(v) => {
							setPersonColumnId(v);
						}}
					/>
				</FormLayout>
			</Modal.Section>
		</Modal>
	);
};

export default withTranslation(["contact", "common"], { withRef: true })(PersonColumnModal);
