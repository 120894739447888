import { Spinner, Tooltip } from "@shopify/polaris";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import Sheet from "src/js/components/sheet";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import BoardHelper from "../Workspaces/BoardHelper";
import { formatDeadlineRows } from "../Workspaces/boardutils/BoardUtils";
import TaskRow from "./TaskRow";
import WeeklyTasksBulkActions from "./WeeklyTasksBulkActions";
import RowModal from "../Workspaces/components/RowModal";
import TaskSheet from "./TaskSheet";

const LateIncompletedTasksSheet = ({ activator, open: propsOpen = false, refresh: propsRefresh, t, count }) => {
	// const { setSelection } = useContext(WeeklyTasksContext);
	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState<any[]>([]);
	const [boards, setBoards] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selection, setSelection] = useState([]);
	const [row, setRow] = useState<BoardRowType | null>(null);
	const [initialTabIndex, setInitialTabIndex] = useState<number | null>(null);

	const fetchLateRows = useCallback(async (silent = false) => {
		if (!silent) setLoading(true);

		const params = {
			assigned_user_id: store.getState().user.id,
			not_board_id: store.getState().user.calendar_board?.id || null,
			not_board_ids: store.getState().user.excluded_boards,
			// really_return_count: 1,
			return_count: true,
			deadline_statuses: 0,
			dates_from: moment(-1).format("YYYY-MM-DD"),
			dates_to: moment().add(-1, "days").format("YYYY-MM-DD"),
		};

		return API.get("/api/board_rows/search.json", {
			params,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				BoardHelper.fetchResources("linked_rows", result.data.linked_row_ids);
				BoardHelper.fetchResources("contacts", result.data.contact_ids);
				BoardHelper.fetchResources("tickets", result.data.ticket_ids);
				store.dispatch({ type: "SET_BOARDS", boards: result.data.boards });
				store.dispatch({ type: "SET_BOARD_GROUPS", groups: result.data.groups });
				store.dispatch({
					type: "SET_BOARD_ROWS",
					rows: result.data.rows.concat(Object.values(store.getState().board_rows)),
				});

				setRows(result.data.rows);
				setBoards(result.data.boards);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const handleRefresh = useCallback(() => {
		fetchLateRows();
		propsRefresh();
	}, [fetchLateRows, propsRefresh]);

	const handleCloseRow = useCallback(() => {
		setRow(null);
		setInitialTabIndex(null);
	}, []);

	const handleOpenRow = useCallback((row = null, initialTabIndex = 0) => {
		setRow(row);
		setInitialTabIndex(initialTabIndex);
	}, []);

	useEffect(() => {
		if (typeof propsOpen === "boolean") setOpen(propsOpen);
	}, [propsOpen]);

	useEffect(() => {
		if (open) fetchLateRows();
	}, [fetchLateRows, open]);

	useEffect(() => {
		if (open) window.addEventListener("refreshTasks", handleRefresh, false);

		return () => {
			window.removeEventListener("refreshTasks", handleRefresh, false);
		};
	}, [handleRefresh, open]);

	const handleToggle = () => {
		setOpen((c) => !c);
	};

	const handleClose = () => {
		setOpen(false);
		setSelection([]);
		setRow(null);
	};

	const updateRow = useCallback(async (newRow: BoardRowType | null) => {
		setRow((row) => (row ? (newRow?.removed ? null : newRow) : null));

		if (newRow) {
			setRows((rows) => {
				const index = rows?.findIndex((r: DeadlineBoardRowType) => r.id === newRow.id);
				if (index === -1) return rows;

				const newRows = [...rows];
				if (newRow.removed) {
					newRows.splice(index, 1);
				} else {
					newRows.splice(index, 1, newRow);
				}

				return newRows;
			});
		}
	}, []);

	const onUpdateValue = useCallback(
		async (row: BoardRowType, column: BoardColumnType, data: any) => {
			const result = await BoardHelper.onUpdateValue(row, column, data);
			if (result?.row) updateRow(result.row);
		},
		[updateRow]
	);

	const deadlineRows = useMemo(() => {
		return formatDeadlineRows({ rows: [...rows], boards: [...boards] })?.sort(
			(a, b) => (new Date(b.deadline.date) as any) - (new Date(a.deadline.date) as any)
		);
	}, [boards, rows]);

	const acvitatorButton = activator ? React.cloneElement(activator, { onClick: handleToggle }) : null;

	const board = (row && (store.getState().boards[row?.board_id] || row.board)) || store.getState().boards[store.getState().user.todo_board_id];
	const groups = Object.values(store.getState().board_groups).filter((group: any) => group.board_id == board?.id);

	const todoboardsIds = [store.getState().user.todo_board_id, store.getState().account.todo_board_id];
	const rowModalOpen = row && !todoboardsIds.includes(row?.board_id);
	const taskSheetOpen = row && todoboardsIds.includes(row?.board_id);

	return (
		<>
			<Tooltip
				content={t("dashboard.todo.total_late_tasks_count", "{{counts}}st oavslutade uppgifter", {
					counts: count,
				})}
			>
				{acvitatorButton}
			</Tooltip>
			<Sheet
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						{t("tasks.late_incomplete_tasks.modal.title", "Försenade uppgifter")}
						{deadlineRows?.length != count && deadlineRows?.length ? (
							<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
								<Badge fontSize={14} color={Colors.red}>
									{t("row.pliural", "Rader")}: {count}
								</Badge>
								<Badge fontSize={14} color={Colors.red}>
									Deadlines: {deadlineRows?.length}
								</Badge>
							</div>
						) : (
							<Badge fontSize={14} color={Colors.red}>
								{count}
							</Badge>
						)}
						{loading && <Spinner size="small" />}
					</div>
				}
				open={open}
				onClose={handleClose}
				scrollable
			>
				<div style={{ transition: "opacity 250ms", opacity: loading ? 0.75 : 1 }}>
					{deadlineRows?.map((row) => (
						<TaskRow
							key={row.key}
							row={row}
							color={Colors.red}
							refresh={handleRefresh}
							onOpen={handleOpenRow}
							setSelection={setSelection}
							selection={selection}
						/>
					))}
				</div>

				<WeeklyTasksBulkActions rows={deadlineRows} selection={selection} setSelection={setSelection} refresh={handleRefresh} />
			</Sheet>
			<RowModal
				open={!!rowModalOpen}
				row={row}
				board={board}
				groups={groups}
				onUpdateValue={onUpdateValue}
				defaultValues={[]}
				getContact={() => {}}
				onClose={() => {
					handleCloseRow();
					// handleRefresh();
				}}
				onUpdateRow={(row: BoardRowType) => {
					updateRow(row);
				}}
				// refreshCalendar={this.props.refreshCalendar}
				refreshTodo={handleRefresh}
				initialTabIndex={initialTabIndex}
			/>

			<TaskSheet
				open={!!taskSheetOpen}
				row={(row || null) as BoardRowType | any}
				// board={board}
				onClose={() => {
					handleCloseRow();
				}}
				onComplete={(row: BoardRowType) => {
					updateRow(row);
					handleCloseRow();
					handleRefresh();
				}}
			/>
		</>
	);
};
export default withTranslation(["tasks", "common"])(LateIncompletedTasksSheet);
