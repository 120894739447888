import React, { Component } from "react";
import { Heading, Scrollable, Stack, Button, ActionList } from "@shopify/polaris";
import { MobileCancelMajor, MaximizeMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import RowSnippet from "./RowSnippet";
import BoardHelper from "../BoardHelper";
import { toastr } from "../../../components/toastr.js";
import Popover from "../../../components/Popover";
import SheetHeader from "../../../StyledComponents/SheetHeader";
import Sheet from "../../../components/sheet";

class MissingColumnValuesSheet extends Component {
	constructor(props) {
		super(props);

		this.state = { selectedColumnIndex: 0 };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.columns.length != this.props.columns.length) {
			this.setState({ selectedColumnIndex: 0 });
		}
	}

	addDefaultValue(row) {
		const column = this.props.columns[this.state.selectedColumnIndex];

		let defaultValue = null;

		for (let s = 0; s < this.props.defaultValues.length; s++) {
			if (this.props.defaultValues[s].column_id == column.id) {
				defaultValue = this.props.defaultValues[s];
				break;
			}
		}

		if (!defaultValue) {
			return;
		}

		let found = false;
		for (let s = 0; s < row.values.length; s++) {
			const value = row.values[s];
			if (value.column_id == column.id) {
				found = true;
				row.values[s].value = defaultValue.value;
				break;
			}
		}

		if (!found) {
			row.values.push(defaultValue);
		}

		API.post("/api/boards/" + this.props.board.id + "/rows/" + row.id + "/columns/" + column.id + "/values.json", defaultValue, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdateRow(result.data.row);
			})
			.catch((error) => {
				toastr.error(error);
			});

		this.props.onUpdateRow(row);
	}

	render() {
		const column = this.props.columns[this.state.selectedColumnIndex];

		const rows = [];
		for (let i = 0; i < this.props.rows.length; i++) {
			const row = this.props.rows[i];
			let found = false;
			for (let s = 0; s < row.values.length; s++) {
				const value = row.values[s];
				if (column && value.column_id == column.id && value.value) {
					found = true;
					break;
				}
			}
			if (!found) {
				rows.push(row);
			}
		}

		return (
			<Sheet open={this.props.open} onClose={this.props.onClose} hideHeader>
				{column ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<div
							style={{
								alignItems: "center",
								borderBottom: "1px solid #DFE3E8",
								display: "flex",
								justifyContent: "space-between",
								padding: "1.6rem",
								width: "100%",
							}}
						>
							<Stack spacing="tight">
								<Heading>{this.props.t("column.terms.row_without", "Rader utan")} </Heading>
								<div style={{ paddingTop: 3 }}>
									<Popover
										active={this.state.showColumnSelector}
										activator={
											<Button
												onClick={() => {
													this.setState({
														showColumnSelector: !this.state.showColumnSelector,
													});
												}}
												disclosure
												plain
											>
												{column.title}
											</Button>
										}
										onClose={() => {
											this.setState({
												showColumnSelector: !this.state.showColumnSelector,
											});
										}}
									>
										<ActionList
											items={this.props.columns.map((column, index) => ({
												content: column.title,
												onAction: () => {
													this.setState({
														selectedColumnIndex: index,
														showColumnSelector: false,
													});
												},
											}))}
										/>
									</Popover>
								</div>
							</Stack>
							<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
						</div>
						<Scrollable style={{ padding: "1.6rem", height: "100%" }}>
							{rows.map((row, index) => {
								const group = BoardHelper.getGroup(row.group_id);
								return (
									<RowSnippet key={row?.id || index} group={group} row={row}>
										<div style={{ paddingTop: 2 }}>
											<Stack spacing="extraTight" wrap={false}>
												<Button size="slim" outline onClick={this.addDefaultValue.bind(this, row)}>
													+{this.props.t("column.missing.actions.add", "Lägg till")}
												</Button>
												<Button
													size="slim"
													outline
													onClick={() => {
														this.props.onEditRow(row);
													}}
													icon={MaximizeMinor}
												/>
											</Stack>
										</div>
									</RowSnippet>
								);
							})}
						</Scrollable>
						<div style={{ padding: 15 }}>
							<Button outline fullWidth onClick={this.props.onCreateRow}>
								+ {(this.props.board && this.props.board.singular) || "rad"}
							</Button>
						</div>
					</div>
				) : (
					<SheetHeader title={this.props.t("column.missing.error.no_found", "No column found")} onClose={this.props.onClose} />
				)}
			</Sheet>
		);
	}
}
export default withTranslation(["column", "common"], { withRef: true })(MissingColumnValuesSheet);
