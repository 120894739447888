import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import useQuery from "src/js/hooks/useQuery";
import getDefaultForm from "./Utilities/getDefaultForm";
import { formatOrderToRead, formatOrderToWrite } from "./Utilities/formatRow";

type SetFormFunction = (form: Partial<OrderType> | ((prevForm: Partial<OrderType>) => Partial<OrderType>)) => void;

type OrderContextValue = {
	form: Partial<OrderType>;
	order: OrderType | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	isSaving: boolean;
	isLoading: boolean;
	disabled: boolean;
	handleSave: (order: OrderType) => any;
} & WithTranslation;

const OrderContext = React.createContext({} as OrderContextValue);

export const OrderContextProvider = withTranslation(["order", "common"])(
	({ id: propsId, match, history, children, t, defaultData = getDefaultForm(history) }) => {
		const [form, setForm] = useState<Partial<OrderType>>(defaultData);
		const id = propsId || match.params.id;

		const queryClient = useQueryClient();

		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/orders/${id}.json`);

				return res.data.order;
			} catch (error) {
				toastr.error(error);
				return defaultData || null;
			}
		}, [id, defaultData]);

		const queryKey = [id && `order-${id}`].filter(Boolean);
		const { data: order = null, isFetching }: UseQueryResult<OrderType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: defaultData || null,
			enabled: !!id,
		});

		useEffect(() => {
			if (order) setForm(formatOrderToRead(order));
		}, [order]);

		const saveFunction = useCallback(
			async (order: OrderType) => {
				const endpoint = !id ? `/api/orders.json` : `/api/orders/${id}.json`;
				const data = {
					...formatOrderToWrite(order),
				};

				const res = await (id ? API.put(endpoint, data) : API.post(endpoint, data));

				const successMessage = id ? t("orders.responses.order_saved", "Order sparad") : t("orders.responses.order_created", "Order skapad");

				toastr.success(successMessage);

				history.replace(`/admin/orders/${res.data.order.id}`, {
					data: res.data.order,
				});

				return res;
			},
			[id, t, history]
		);

		const update = async ({ order }) => {
			const response = await saveFunction(order);
			return response?.data.order;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
			},
		});

		const handleSave = useCallback(
			async (order: OrderType) => {
				try {
					if (!order) {
						throw new Error("No order");
					}

					return await mutation.mutateAsync({ order });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				}
			},
			[mutation]
		);

		const isSaving = mutation.isLoading;
		const isLoading = isSaving || isFetching;
		const disabled = isLoading;
		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				t,
				id,
				handleSave,
				order,
				isSaving,
				disabled,
				isLoading,
			}),
			[isFetching, t, id, form, handleSave, setForm, order, isSaving, disabled, isLoading]
		);

		return useMemo(() => <OrderContext.Provider value={value}>{children}</OrderContext.Provider>, [value, children]);
	}
);
export default OrderContext;
