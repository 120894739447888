import { Link } from "react-router-dom";
import { BadgeWrapper } from "src/js/components/Badge";
import styled, { css } from "styled-components";

const AgendaTaskRowTitle = styled.h4`
	* {
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.6rem;
	}
`;
const AgendaTaskRowSubTitle = styled.p`
	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;
const AgendaTaskRowSubText = styled.span<{ bold?: boolean }>`
	font-size: 0.85em;
	text-decoration: none;
	color: var(--textColor2);
	font-weight: ${({ bold }) => (bold ? "bold" : "normal")};

	.Polaris-Icon {
		height: 1.5rem;
		fill: var(--textColor2);
	}
`;
const AgendaTaskRowCheckboxInner = styled.div<any>`
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
`;
const AgendaTaskRowActions = styled.div<any>`
	display: flex;
	gap: 1rem;
	align-items: center;
`;
const AgendaTaskRowBoardLink = styled(Link)`
	color: var(--textColor);
`;
const AgendaTaskRowCheckbox = styled.div<any>`
	transition: width 250ms, min-width 250ms, opacity 250ms;
	overflow: hidden;
	width: 7px;
	min-width: 7px;
	height: 100%;
	opacity: 0.5;
	display: flex;
	align-items: center;
	border-radius: 2px;
	margin-right: 0.5rem;

	.Polaris-Choice__Control {
		margin: 0;
	}

	${({ onClick }) =>
		!!onClick &&
		css`
			&:hover {
				width: 40px;
				min-width: 40px;
				opacity: 1;
			}
		`}

	${({ selection }) =>
		!!selection?.length &&
		css`
			width: 40px;
			min-width: 40px;
			opacity: 1;
		`}
`;

const StyledAgendaTaskRowContent = styled.div``;
const StyledAgendaTaskRowInner = styled.div`
	grid-area: inner;
	text-align: start;
	display: flex;
	flex-direction: column;
	padding-block: 0.5rem;
`;

const StyledAgendaTaskRow = styled.div.attrs<{ color?: string }>({ role: "button" })`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-block: 0.5rem;
	display: grid;
	grid-template-areas: "checkbox inner actions";
	grid-template-columns: max-content auto max-content;
	min-height: 50px;
	padding-right: 2rem;
	width: 100%;
	border: none;
	color: var(--textColor);
	padding-left: 0;

	/* background: var(--cardbg); */
	background-color: transparent;
	box-shadow: var(--boxShadow);
	border-radius: 10px;
	transition: background 250ms;
	cursor: pointer;

	&:hover {
		background-color: var(--cardbg);
	}

	${AgendaTaskRowCheckbox} {
		grid-area: checkbox;
		${({ color }) =>
			color &&
			css`
				background-color: ${color};
			`}
	}

	${AgendaTaskRowTitle} {
		grid-area: content;
	}

	${AgendaTaskRowSubTitle} {
		grid-area: content;
	}
`;

interface AgendaTaskRowType {
	Title: typeof AgendaTaskRowTitle;
	SubTitle: typeof AgendaTaskRowSubTitle;
	SubText: typeof AgendaTaskRowSubText;
	Content: typeof StyledAgendaTaskRowContent;
	Inner: typeof StyledAgendaTaskRowInner;
	Checkbox: typeof AgendaTaskRowCheckbox & {
		Inner: typeof AgendaTaskRowCheckboxInner;
	};
	Actions: typeof AgendaTaskRowActions;
	BoardLink: typeof AgendaTaskRowBoardLink;
}

const AgendaTaskRow: AgendaTaskRowType | any = StyledAgendaTaskRow;
AgendaTaskRow.Title = AgendaTaskRowTitle;
AgendaTaskRow.SubTitle = AgendaTaskRowSubTitle;
AgendaTaskRow.Inner = StyledAgendaTaskRowInner;
AgendaTaskRow.Content = StyledAgendaTaskRowContent;
AgendaTaskRow.SubText = AgendaTaskRowSubText;
AgendaTaskRow.Checkbox = AgendaTaskRowCheckbox;
AgendaTaskRow.Checkbox.Inner = AgendaTaskRowCheckboxInner;
AgendaTaskRow.Actions = AgendaTaskRowActions;
AgendaTaskRow.BoardLink = AgendaTaskRowBoardLink;
export { AgendaTaskRow };

export const AgendaTasksWrapper = styled.div``;
export const AgendaTasksSections = styled.div``;
export const AgendaTasksHeader = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 10px;
	align-items: center;
	height: 5rem;

	h1 {
		font-size: 2.5rem;
		font-weight: bold;
	}

	.disconnect_calendar_button {
		cursor: pointer;
		background: #fa919100 !important;
		border: none !important;
		height: 100%;
		fill: rgb(200, 200, 200);
	}

	.rightSection {
		display: flex;
		gap: 50px;
		flex-direction: row;
	}
`;

const AgendaTasksSectionHeaderTitle = styled.h3`
	display: flex;
	gap: 1rem;
	user-select: none;
	align-items: center;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.4rem;
`;
const AgendaTasksSectionHeaderRightButtonsWrapper = styled.div`
	display: flex;
	gap: 40px;
`;
const AgendaTasksSectionHeader = styled.button`
	// background-color: $darkerBackground;
	background-color: var(--cardbg);
	padding: 10px;
	padding-right: 20px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	box-shadow: var(--boxShadow);
	cursor: pointer;
	position: relative;
	transition: filter 250ms;

	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;

	outline-color: none;
	border: none;
	width: 100%;

	.leftIcon img {
		user-select: none;
	}

	.title {
		display: flex;
		flex-direction: column;
		user-select: none;
	}

	h2.Polaris-Heading {
		font-size: 2rem;
		font-weight: normal;

		.Polaris-TextStyle--variationSubdued {
			font-size: 1.3rem;
		}
	}

	&:hover {
		filter: brightness(1.1);
		-webkit-filter: brightness(1.1);
	}

	${BadgeWrapper} {
		line-height: 2.4rem;
	}
`;

const AgendaTasksSectionHeaderIcon = styled.div`
	user-select: none;

	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--cardbg);
	box-shadow: var(--boxShadow);
	background: var(--main1);

	.Polaris-Icon {
		transform: scale(1.3);
		-webkit-transform: scale(1.3);
		-moz-transform: scale(1.3);
		-ms-transform: scale(1.3);
		-o-transform: scale(1.3);

		fill: ${({ color }) => color || "var(--textColor)"};
	}
`;
const AgendaTasksSectionList = styled.div``;
export const AgendaTasksSection = styled.div<any>`
	margin-bottom: 10px;
	&:last-child() {
		margin-bottom: 0;
	}

	.Polaris-ProgressBar__Indicator {
		background-color: grey;
	}
	.Polaris-ProgressBar {
		background-color: black;
	}

	.Polaris-Stack__Item {
		display: flex;
		align-items: center;
	}

	${AgendaTasksSectionHeaderIcon} {
		fill: ${({ color }) => color || "var(--textColor)"};
	}
	${AgendaTasksSectionHeaderTitle}, ${AgendaTaskRowBoardLink} {
		color: ${({ color }) => color || "var(--textColor)"};
	}
	${AgendaTaskRowCheckbox} {
		background-color: ${({ color }) => color || "var(--textColor)"};
	}
`;

interface AgendaTaskSectionType {
	Header: typeof AgendaTasksSectionHeader & {
		Icon: typeof AgendaTasksSectionHeaderIcon;
		Title: typeof AgendaTasksSectionHeaderTitle;
		RightButtonsWrapper: typeof AgendaTasksSectionHeaderRightButtonsWrapper;
	};
	List: typeof AgendaTasksSectionList;
}

const AgendaTaskSection: AgendaTaskSectionType | any = AgendaTasksSection;
AgendaTaskSection.Header = AgendaTasksSectionHeader;
AgendaTaskSection.Header.Icon = AgendaTasksSectionHeaderIcon;
AgendaTaskSection.Header.Title = AgendaTasksSectionHeaderTitle;
AgendaTaskSection.Header.RightButtonsWrapper = AgendaTasksSectionHeaderRightButtonsWrapper;
AgendaTaskSection.List = AgendaTasksSectionList;
export { AgendaTaskSection };
