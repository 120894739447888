import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { WithTranslation, withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import CardList from "src/js/components/CardList";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import moment from "moment";

type CategoriesListProps = {
	history: any;
} & WithTranslation;

const CategoryList = ({ history, t }: CategoriesListProps) => {
	const ref = React.useRef<any>();

	const handleBulkDisabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/categories.json", { ids, disabled: 1 });
		toastr.success(t("categories.responses.bulk_disabled", "{{count}} kategorier inaktiverade", { count: result.data?.categories?.length }));
		ref.current?.reload();
	}, [t]);

	const handleBulkEnabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/categories.json", { ids, disabled: 0 });
		toastr.success(t("categories.responses.bulk_enabled", "{{count}} kategorier aktiverade", { count: result.data?.categories?.length }));
		ref.current?.reload();
	}, [t]);

	const renderItem = useCallback(
		(item: CategoryType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/categories/${item.id}`, {
							data: item,
						});
					}}
					title={<>{item.title}</>}
					leftColumn={[
						{
							label: t("articles.status", "Status"),
							value: item.disabled ? (
								<Badge color={Colors.red}>{t("common.inactive", "Inaktiv")}</Badge>
							) : (
								<Badge color={Colors.green}>{t("common.active", "Aktiv")}</Badge>
							),
						},
						{
							label: t("categories.title", "Titel"),
							value: item.title,
						},
						{
							label: t("categories.articles.display", "Artiklar"),
							value: item.articles_display ?? "Inga artiklar",
						},
					]}
					rightColumn={[
						{
							label: t("categories.purchasePrice", "Skapad"),
							value: moment(item.created_at).format("YYYY-MM-DD"),
						},
						{
							label: t("categories.purchasePrice", "Uppdaterad"),
							value: moment(item.updated_at).format("YYYY-MM-DD"),
						},
					]}
				/>
			);
		},
		[history, t]
	);

	const filters = [];
	return (
		<Page
			title={t("categories.title", "Kategorier") as string}
			history={history}
			primaryAction={{
				content: t("categories.newCategory", "Ny kategori"),
				url: "/admin/categories/create",
			}}
		>
			<CardList
				ref={ref}
				promotedBulkActions={[
					{
						content: t("categories.bulkDisable", "Inaktivera") as string,
						onAction: handleBulkDisabled,
					},
					{
						content: t("categories.bulkEnable", "aktivera") as string,
						onAction: handleBulkEnabled,
					},
				]}
				appliedFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				filters={filters}
				resourceUrl="/api/categories.json"
				resourceName={{
					singular: "kategori",
					plural: "kategorier",
				}}
				resourceHandle="categories"
				renderItem={renderItem}
			/>
		</Page>
	);
};
export default withTranslation(["categories", "common"])(CategoryList);
