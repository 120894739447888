import { Checkbox, FormLayout, Labelled, Layout, Card } from "@shopify/polaris";
import React, { useContext } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import ArticleContext from "./context";
import { InfoWrapper } from "../CustomFields/styles";
import PriceLists from "./PriceLists";
import ImageUpload from "src/js/components/ImageUpload";
import CustomFieldValues from "./CustomFieldValues";

type ArticleInfoProps = {
	history: any;
} & WithTranslation;

const ArticleInfo = ({ history, t }: ArticleInfoProps) => {
	const { form, isFetching, setForm, isSaving } = useContext(ArticleContext);

	const getChangeHandler = (field: ArticleTypeMutableKeys) => (value: string | boolean | ArticlePricelistType[] | any) => {
		setForm((c: Partial<ArticleType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	const disabled = isSaving || isFetching;

	return (
		<InfoWrapper>
			<Card sectioned>
				<Layout>
					<Layout.Section oneHalf>
						<FormLayout>
							<Labelled id="Active" label={t("articles.fields.Active.label2", "Status")}>
								<Checkbox
									checked={!form?.disabled}
									disabled={disabled}
									label={t("articles.fields.Active.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>
							<ImageUpload source={form?.image?.url || null} size={{ width: "100%" }} onUpload={getChangeHandler("image")} disabled={disabled} />

							<TextField
								disabled={form?.id != null}
								label={t("articles.fields.articleNumber.label", "Artikelnummer")}
								value={form?.number}
								onChange={getChangeHandler("number")}
							/>

							<TextField
								disabled={disabled}
								label={t("articles.fields.title.label", "Titel")}
								value={form?.title}
								onChange={getChangeHandler("title")}
							/>
						</FormLayout>
					</Layout.Section>
					<Layout.Section oneHalf>
						<FormLayout>
							{/* <TextField
								disabled={disabled}
								label={t("articles.fields.moms.label1", "Moms %")}
								value={form?.vat}
								onChange={getChangeHandler("vat")}
							/> */}
							{/* <TextField
								disabled={disabled}
								label={t("articles.fields.inPrice.label1", "Inköpspris")}
								value={form?.in_price}
								onChange={getChangeHandler("in_price")}
							/> */}

							<TextField
								disabled={disabled}
								label={t("articles.fields.unit.label", "Enhet")}
								value={form?.unit}
								onChange={getChangeHandler("unit")}
							/>

							<TextField
								disabled={disabled}
								label={t("articles.fields.vat_in_percentage.label", "Moms %")}
								value={form?.vat_in_percentage}
								onChange={getChangeHandler("vat_in_percentage")}
							/>
						</FormLayout>
					</Layout.Section>
					<Layout.Section oneHalf>
						<FormLayout>
							<TextField
								disabled={disabled}
								label={t("articles.fields.description.label", "Beskrivning")}
								value={form?.description}
								onChange={getChangeHandler("description")}
								multiline={2}
							/>
							<TextField
								disabled={disabled}
								label={t("articles.fields.notes.label", "Anteckningar")}
								value={form?.notes}
								onChange={getChangeHandler("notes")}
								multiline={2}
							/>
							{/* <Thumbnail source={"https://img.freepik.com/free-photo/colorful-design-with-spiral-design_188544-9588.jpg"} size="large" alt="Avatar" /> */}
							{/*!! TEMP */}
							<CustomFieldValues form={form} onChange={getChangeHandler("custom_field_values" as any)} />
						</FormLayout>
					</Layout.Section>

					<Layout.Section fullWidth>
						<PriceLists isSaving={isSaving} article={form as ArticleType} onChange={getChangeHandler} disabled={disabled} />
					</Layout.Section>
				</Layout>
			</Card>
		</InfoWrapper>
	);
};
export default withTranslation(["article", "common"])(ArticleInfo);
