import React, { Component } from "react";
import { Card, TextStyle } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Condition from "./Condition";

class ConditionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			conditions: props.conditions,
		};
	}

	addCondition(inclusive) {
		const conditions = this.state.conditions;
		conditions.push({
			id: null,
			field: this.props.t("triggers.conditions.fields.title.label", "title"),
			inclusive,
			operator: "equals",
			value: "",
			target: "",
			error: true,
		});

		this.setState({ conditions });
		this.props.onUpdate(conditions);
	}

	changeCondition(index, field, operator, value, target, error) {
		const conditions = this.state.conditions;
		for (let i = 0; i < conditions.length; i++) {
			if (i == index) {
				conditions[i].field = field;
				conditions[i].operator = operator;
				conditions[i].value = value;
				conditions[i].target = target;
				conditions[i].error = error;
			}
		}

		this.setState({ conditions });
		this.props.onUpdate(conditions);
	}

	removeCondition(index) {
		const conditions = [];
		for (let i = 0; i < this.state.conditions.length; i++) {
			if (i != index) {
				conditions.push(this.state.conditions[i]);
			}
		}
		this.setState({ conditions });
		this.props.onUpdate(conditions);
	}

	render() {
		this.inclusive = 0;
		this.noninclusive = 0;
		return (
			<Card title={this.props.t("categories.form.conditions.card_title", "Regler")}>
				<Card.Header
					key="all_header"
					title={this.props.t("categories.form.conditions.match_all_conditions", "Matcha ALLA följande regler")}
					actions={[
						{
							content: this.props.t("categories.form.conditions.button_add_rule", "Ny regel"),
							onAction: this.addCondition.bind(this, false),
						},
					]}
				/>
				{this.state.conditions.map((prop, key) => {
					if (!prop.inclusive) {
						this.noninclusive++;
						return (
							<Card.Section key={"all_" + key}>
								<Condition
									event={this.props.event}
									key={key}
									field={prop.field}
									default={prop.default}
									operator={prop.operator}
									value={prop.value}
									target={prop.target}
									onChange={this.changeCondition.bind(this, key)}
									onRemove={this.removeCondition.bind(this, key)}
								/>
							</Card.Section>
						);
					}

					return null;
				})}
				{this.noninclusive ? null : (
					<Card.Section>
						<TextStyle variation="subdued">{this.props.t("triggers.conditions.empty", "Inga regler tillagda")}</TextStyle>
					</Card.Section>
				)}
				<Card.Header
					key="any_header"
					title={this.props.t("categories.form.conditions.match_any_conditions", "Matcha minst EN av följande regler")}
					actions={[
						{
							content: this.props.t("categories.form.conditions.button_add_rule", "Ny regel"),
							onAction: this.addCondition.bind(this, true),
						},
					]}
				/>
				{this.state.conditions.map((prop, key) => {
					if (prop.inclusive) {
						this.inclusive++;
						return (
							<Card.Section key={"or_" + key}>
								<Condition
									event={this.props.event}
									key={key}
									field={prop.field}
									default={prop.default}
									operator={prop.operator}
									value={prop.value}
									target={prop.target}
									onChange={this.changeCondition.bind(this, key)}
									onRemove={this.removeCondition.bind(this, key)}
								/>
							</Card.Section>
						);
					}

					return null;
				})}
				{this.inclusive ? null : (
					<Card.Section>
						<TextStyle variation="subdued">{this.props.t("triggers.conditions.empty", "Inga regler tillagda")}</TextStyle>
					</Card.Section>
				)}
			</Card>
		);
	}
}

export default withTranslation(["triggers", "common"], { withRef: true })(ConditionForm);
