import React, { Component } from "react";
import { Card, TextStyle } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Action from "./Action";

class ActionForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actions: props.actions,
		};
	}

	changeAction(index, action) {
		this.state.actions[index] = action;
		this.setState({ actions: this.state.actions });
		this.props.onUpdate(this.state.actions);
	}

	removeAction(index) {
		this.state.actions.splice(index, 1);
		this.setState({ actions: this.state.actions });
		this.props.onUpdate(this.state.actions);
	}

	addAction() {
		this.state.actions.push({
			id: null,
		});

		this.setState({ actions: this.state.actions });
		this.props.onUpdate(this.state.actions);
	}

	render() {
		return (
			<Card
				title={this.props.t("triggers.form.title", "Actions")}
				actions={[{ content: this.props.t("triggrs.form.actions.new", "Ny händelse"), onAction: this.addAction.bind(this) }]}
			>
				{this.state.actions.map((action, key) => (
					<Action
						event={this.props.event}
						key={key}
						action={action}
						onChange={this.changeAction.bind(this, key)}
						onRemove={this.removeAction.bind(this, key)}
					/>
				))}
				{this.state.actions.length ? null : (
					<Card.Section>
						<TextStyle variation="subdued">{this.props.t("triggers.form.empty", "Inga händelser tillagda")}</TextStyle>
					</Card.Section>
				)}
			</Card>
		);
	}
}

export default withTranslation(["triggers", "common"], { withRef: true })(ActionForm);
