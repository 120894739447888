import React, { Component } from "react";
import { Stack, ActionList, Modal, ChoiceList, Banner, Icon, Popover, Button } from "@shopify/polaris";
import { MobileBackArrowMajor, SecureMajor, CircleCancelMinor, MobileHorizontalDotsMajor, EditMajor, ChevronDownMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import BoardFilters from "./components/BoardFilters";
import Datepicker from "../../components/datepicker";
import PersonFilter from "./components/PersonFilter";
import CellText from "./CellText";
import BoardMembers from "./components/BoardMembers";
import WidgetCreator from "./WidgetCreator";
import BoardConnector from "./BoardConnector";
import RemoveModal from "../../components/remove_modal.js";
import { store } from "../../store/index.js";
import DashboardFavotiteButton from "./components/DashboardFavotiteButton.js";

class DashboardHeader extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: props.dashboard.type,
			editing: this.props.editing,
			from: this.props.from,
			to: this.props.to,
			caption: this.props.caption,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.dashboard.id != this.props.dashboard.id) {
			this.setState({ type: props.dashboard.type });
		}
	}

	updateTitle(value) {
		if (value.value != this.props.dashboard.title) {
			this.props.dashboard.title = value.value;
			this.props.onUpdateDashboard(this.props.dashboard);
			API.put("/api/dashboards/" + this.props.dashboard.id + ".json", { title: value.value }, { params: {} })
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.dashboard) {
						store.dispatch({
							type: "UPDATE_FAVORITE_DASHBOARD",
							dashboard: result.data.dashboard,
						});
						store.dispatch({
							type: "UPDATE_DASHBOARD",
							dashboard: result.data.dashboard,
						});
					}

					this.props.onUpdateDashboard(result.data.dashboard);
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	saveType() {
		this.setState({ saving: true });
		API.put("/api/dashboards/" + this.props.dashboard.id + ".json", { type: this.state.type }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });

				this.props.onUpdateDashboard(result.data.dashboard);
				this.setState({ saving: false, showTypeModal: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeDashboard() {
		this.setState({ removing: true });
		API.delete("/api/dashboards/" + this.props.dashboard.id + ".json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ removing: false });
					return;
				}

				store.dispatch({
					type: "REMOVE_FAVORITE_DASHBOARD",
					dashboard: this.props.dashboard,
				});
				store.dispatch({
					type: "REMOVE_DASHBOARD",
					dashboard: this.props.dashboard,
				});

				this.setState({ removing: false, showRemoveModal: false });
				if (this.props.history) {
					this.props.history.replace("/admin/dashboard/mine");
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	setEditMode(value) {
		this.setState({ editing: value }, () => {
			this.props.setEditing(this.state.editing);
		});
	}

	haveQuickDate() {
		for (let i = 0; i < this.props.dashboard.widgets.length; i++) {
			if (this.props.dashboard.widgets[i].options.filters) {
				for (let s = 0; s < this.props.dashboard.widgets[i].options.filters.length; s++) {
					if (
						this.props.dashboard.widgets[i].options.filters[s].advanced &&
						this.props.dashboard.widgets[i].options.filters[s].operator == "quickdateselector"
					) {
						return true;
					}
				}
			}
		}
		return false;
	}

	toggleRestActionsOpen() {
		this.setState((c) => ({ restActionsOpen: !c.restActionsOpen }));
	}

	render() {
		const favoriteButton = <DashboardFavotiteButton dashboard={this.props.dashboard} />;

		const boardActions = [
			{
				icon: SecureMajor,
				content: this.props.t("dashboard.actions.change_access", "Ändra åtkomst"),
				onAction: () => {
					this.setState({ showTypeModal: true });
				},
			},
			{
				icon: CircleCancelMinor,
				destructive: true,
				content: this.props.t("common.actions.remove", "Ta bort"),
				onAction: () => {
					this.setState({ showRemoveModal: true });
				},
			},
		];

		const columns = (this.props.dashboard.boards || []).map((b) => {
			const column = (b && b.columns && b.columns.find((c) => c.type === "person")) || {};
			return column && column.id;
		});
		const peopleColumns = (columns || []).filter((i) => i);
		const peopleRowColumn =
			(this.props.rows || []).map(
				(r) =>
					r.column_values &&
					(Array.isArray(r.column_values) ? r.column_values : Object.values(r.column_values)).find((c) => peopleColumns.includes(c.id))
			) || [];
		const peopleValues = Array.from(
			new Set(
				peopleRowColumn
					.filter((i) => i)
					.map((r) => r && r.value)
					.flat(10)
			)
		);
		const people = (store.getState().users && store.getState().users.filter((u) => peopleValues.includes(parseInt(u.id)))) || [];

		return (
			<React.Fragment>
				<div className="dashboard-header fixed-column">
					<div className="dashboard-header__firstrow">
						<div className="dashboard-header__title">
							<span className="dashboard-view-edit-button">
								<Button plain onClick={this.setEditMode.bind(this, !this.state.editing)}>
									{this.state.editing ? <Icon source={MobileBackArrowMajor} /> : <Icon source={EditMajor} />}
								</Button>
							</span>
							{this.state.editing ? (
								<CellText onChange={this.updateTitle.bind(this)} onFocus={() => {}} onBlur={() => {}} value={this.props.dashboard.title} />
							) : (
								<h1>{this.props.dashboard.title}</h1>
							)}
							{favoriteButton}
							{this.state.editing && this.props.dashboard.type == "shareable" ? (
								<BoardMembers dashboard={this.props.dashboard} onUpdateDashboard={this.props.onUpdateDashboard} />
							) : null}
						</div>
						<Stack>
							{this.haveQuickDate() ? (
								<Datepicker onChange={this.props.onChangeRange} caption={this.state.caption} from={this.state.from} to={this.state.to} />
							) : null}
							{/* <SearchFilter onChangeFilters={this.props.onChangeFilters} filters={this.props.filters} /> */}
							<PersonFilter onChangeFilters={this.props.onChangeFilters} filters={this.props.filters} members={people} />
							<BoardFilters
								onChangeFilters={this.props.onChangeFilters}
								filters={this.props.filters}
								boards={this.props.dashboard.boards}
								groups={this.props.groups}
								filteredRows={this.props.filteredRows}
								rows={this.props.rows}
							/>
						</Stack>
					</div>
					<div className="dashboard-header__secondrow">
						{this.state.editing && (
							<React.Fragment>
								<Popover
									activator={
										<div className="restActions-button" onClick={this.toggleRestActionsOpen.bind(this)}>
											<Icon source={MobileHorizontalDotsMajor} />
											{this.props.t("dashboard.terms.rest", "Övrigt")}
											<Icon source={ChevronDownMinor} />
										</div>
									}
									active={this.state.restActionsOpen}
									onClose={this.toggleRestActionsOpen.bind(this)}
								>
									<ActionList items={boardActions} />
								</Popover>
								<BoardConnector dashboard={this.props.dashboard} onUpdateDashboard={this.props.onUpdateDashboard} />
								<WidgetCreator dashboard={this.props.dashboard} onCreate={this.props.onCreateWidget} />
							</React.Fragment>
						)}
					</div>
				</div>
				<Modal
					open={this.state.showTypeModal}
					onClose={() => {
						this.setState({ showTypeModal: false });
					}}
					title={this.props.t("dashboard.modals.access.title", "Ändra behörighet")}
					primaryAction={{
						content: this.props.t("common.actions.change", "Ändra"),
						loading: this.state.saving,
						disabled: this.state.type == this.props.dashboard.type,
						onAction: this.saveType.bind(this),
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showTypeModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<ChoiceList
							choices={[
								{
									label: this.props.t("dashboard.modals.access.options.public.label", "Publik"),
									value: "public",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner title={this.props.t("dashboard.modals.access.options.public.title", "Publik dashboard")}>
												{this.props.t("dashboard.modals.access.options.public.text", "Alla som har ett konto kommer åt dashboarden")}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("dashboard.modals.access.options.public_read.label", "Publik (endast läs)"),
									value: "public_read",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner title={this.props.t("dashboard.modals.access.options.public_read.title", "Publik dashboard (endast läs)")}>
												{this.props.t(
													"dashboard.modals.access.options.public_read.text",
													"Alla kan se dashboarden, men endast kopplade medlemmar har skrivrättigheter"
												)}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("dashboard.modals.access.options.shareable.label", "Delad"),
									value: "shareable",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner title={this.props.t("dashboard.modals.access.options.shareable.title", "Delad dashboard")}>
												{this.props.t(
													"dashboard.modals.access.options.shareable.text",
													"Det går att dela dashboarden med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna"
												)}
											</Banner>
										) : null,
								},
								{
									label: this.props.t("dashboard.modals.access.options.private.label", "Privat"),
									value: "private",
									renderChildren: (isSelected) =>
										isSelected ? (
											<Banner title={this.props.t("dashboard.modals.access.options.private.title", "Privat board")}>
												{this.props.t("dashboard.modals.access.options.private.text", "Enbart ägaren (du) kan se dashboarden")}
											</Banner>
										) : null,
								},
							]}
							selected={[this.state.type]}
							onChange={(v) => {
								this.setState({ type: v[0] });
							}}
						/>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: null });
					}}
					loading={this.state.removing}
					onConfirm={this.removeDashboard.bind(this)}
					numItems={1}
					objectName={this.props.dashboard ? this.props.dashboard.title : ""}
					resourceName={{
						singular: this.props.t("dashboard.singular", "dashboard"),
						plural: this.props.t("dashboard.plural", "dashboard"),
					}}
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["dashboard", "common"], { withRef: true })(DashboardHeader);
