import { UseQueryResult } from "@tanstack/react-query";
import React, { useCallback, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import Colors from "src/js/Colors";
import { currencyFormatter } from "src/js/Utilities";
import IconWithBackround from "src/js/components/IconWithBackround";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import { toastr } from "src/js/components/toastr";
import useQuery from "src/js/hooks/useQuery";
import { BoardIcon, ClockIcon, NoteIcon } from "src/js/icons";
import { store } from "src/js/store";
import styled from "styled-components";
import TicketsGraph from "./TicketsGraph";
import ServiceLeaderboard from "./ServiceLeaderboard";
import moment from "moment";
import NewDatePicker from "src/js/components/NewDatePicker";
import Button from "src/js/components/Button";
import { RefreshMajor } from "@shopify/polaris-icons";

const ServiceDeskDashboard = ({ t, history }) => {
	const [fromdate, setFromdate] = useState(moment().subtract(1, "month").format("YYYY-MM-DD"));
	const [todate, setTodate] = useState(moment().format("YYYY-MM-DD"));

	const boardId = store.getState().account.support_board_id;

	const invoiceParams: any = useMemo(() => {
		const p: any = {
			also_archived: 1,
			fromdate,
			todate,
		};

		return p;
	}, [fromdate, todate]);

	const fetchInvoiceData = useCallback(async () => {
		try {
			const res = await API.get(`/api/standard_dashboards/service_desk/stats.json`, { params: invoiceParams });
			return res.data;
		} catch (error) {
			toastr.error(error);
			return null;
		}
	}, [invoiceParams]);

	const queryKey = [
		`service_desk_dashboard`,
		Object.keys(invoiceParams).length && `board_${boardId}_rows_${JSON.stringify(invoiceParams)}_stats`,
	].filter(Boolean);
	const {
		data: stats = null,
		isFetching: IsLoadingStats,
		refetch,
	}: UseQueryResult<any | null> = useQuery({
		queryKey,
		queryFn: fetchInvoiceData,
		refetchOnWindowFocus: false,
	});

	const handleFormatNumber = (value) => {
		return currencyFormatter({
			value,
			style: "decimal",
		});
	};

	const handleFormatDuration = (value) => {
		moment.locale("sv");
		return moment.duration(value, "s").humanize();
	};

	const handleChangeDate = (key) => (value) => {
		if (key === "fromdate") {
			setFromdate(value);
		} else if (key === "todate") {
			setTodate(value);
		}
	};

	const dateText = (() => {
		const startMoment = moment(fromdate);
		const endMoment = moment(todate);

		const startFormat = "YYYY-MM-DD";
		const endFormat = startMoment.isSame(endMoment, "year") ? "MM-DD" : "YYYY-MM-DD";

		const formattedStartDate = startMoment.format(startFormat);
		const formattedEndDate = endMoment.format(endFormat);

		return <DateText>{`${formattedStartDate} - ${formattedEndDate}`}</DateText>;
	})();

	const colors = {
		new: stats?.colors?.["Nya ärenden"],
		open: stats?.colors?.["Öppna ärenden"],
		pending: stats?.colors?.["Väntande ärenden"],
		closed: stats?.colors?.["Avslutade ärenden"],
	};

	return (
		<>
			<FilterWrapper>
				<Button loading={IsLoadingStats} icon={RefreshMajor} onClick={refetch} />

				<DatesWrapper>
					<NewDatePicker optionalTime={false} value={fromdate} onChange={handleChangeDate("fromdate")} />
					<NewDatePicker optionalTime={false} value={todate} onChange={handleChangeDate("todate")} />
				</DatesWrapper>
			</FilterWrapper>
			<Wrapper>
				<StatsBoxWrapper
					style={{ gridArea: "boxes1", gridTemplateColumns: "1fr 1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("dashboards.service_desk.box.new.label", "Nya ärenden"),
							text: <p>{handleFormatNumber(stats?.boxes.new_count.count)}</p>,
							icon: <IconWithBackround icon={<BoardIcon color={colors.new} />} color={colors.new} />,
							children: dateText,
						},
						{
							title: t("dashboards.service_desk.box.open.label", "Öppna ärenden"),
							text: <p>{handleFormatNumber(stats?.boxes.open_count.count)}</p>,
							icon: <IconWithBackround icon={<NoteIcon color={colors.open} />} color={colors.open} />,
							loading: IsLoadingStats && !stats,
							link: {
								content: t("dashboards.service_desk.box.rows_link", "Visa rader"),
								onAction: () => history.push(stats.boxes.open_count.url),
							},
						},
						{
							title: t("dashboards.service_desk.box.pending.label", "Väntande ärenden"),
							text: <p>{handleFormatNumber(stats?.boxes.pending_count.count)}</p>,
							icon: <IconWithBackround icon={<NoteIcon color={colors.pending} />} color={colors.pending} />,
							loading: IsLoadingStats && !stats,
							link: {
								content: t("dashboards.service_desk.box.rows_link", "Visa rader"),
								onAction: () => history.push(stats.boxes.pending_count.url),
							},
						},
						{
							title: t("dashboards.service_desk.box.closed.label", "Avslutade ärenden"),
							text: <p>{handleFormatNumber(stats?.boxes.closed_count.count)}</p>,
							icon: <IconWithBackround icon={<NoteIcon color={colors.closed} />} color={colors.closed} />,
							children: dateText,
							link: {
								content: t("dashboards.service_desk.box.rows_link", "Visa rader"),
								onAction: () => history.push(stats.boxes.closed_count.url),
							},
						},
					]}
				/>

				<ServiceLeaderboard
					style={{ gridArea: "leaderboard" }}
					loading={IsLoadingStats && !stats}
					data={stats?.leaderboard}
					// title={t("dashboards.service_desk.leaderboard.title", "Topplista")}
					// data={stats?.data.leaderboard}
				/>

				<GraphWrapper style={{ gridArea: "graph" }}>
					<TicketsGraph data={stats?.series} loading={IsLoadingStats} colors={colors} />
				</GraphWrapper>

				<StatsBoxWrapper
					style={{ gridArea: "boxes2", gridTemplateColumns: "1fr 1fr" }}
					loading={IsLoadingStats}
					items={[
						{
							title: t("dashboards.service_desk.bo.average_first_asnwer_time.label", "Genomsnittlig tid från första svar"),
							text: <p>{handleFormatDuration(stats?.data.average_time_to_first_answer)}</p>,
							icon: <IconWithBackround icon={<ClockIcon color={Colors.white} />} color={Colors.grey} />,
							children: dateText,
						},
						{
							title: t("dashboards.service_desk.box.average_closed_time.label", "Genomsnittlig avslutad tid"),
							text: <p>{handleFormatDuration(stats?.data.average_time_to_closed)}</p>,
							icon: <IconWithBackround icon={<ClockIcon color={Colors.white} />} color={Colors.grey} />,
							children: dateText,
						},
						{
							title: t("dashboards.service_desk.box.not_closed_delayed.label2", "Ej avslutade ärenden äldre än 72 timmar"),
							text: <p>{handleFormatNumber(stats?.boxes?.not_closed_but_should_be_count.count)}</p>,
							icon: <IconWithBackround icon={<NoteIcon color={Colors.red} />} color={Colors.red} />,
							loading: IsLoadingStats && !stats,
							link: {
								content: t("dashboards.service_desk.box.rows_link", "Visa rader"),
								onAction: () => history.push(stats?.boxes?.not_closed_but_should_be_count.url),
							},
						},
					]}
				/>
			</Wrapper>
		</>
	);
};
export default withTranslation(["dashboards", "common"], { withRef: true })(ServiceDeskDashboard);

const Wrapper = styled.div`
	display: grid;
	gap: 3rem;
	grid-template-columns: repeat(2, 1fr);

	grid-template-areas:
		"boxes1 leaderboard"
		"graph boxes2";

	.statsBoxWrapper {
		margin: 0;

		.statsBox {
			min-height: 100px;
		}
	}
	.Polaris-Card {
		height: 100%;
	}
`;

const GraphWrapper = styled.div`
	flex: 1;
	position: relative;
	height: 100%;
`;

const DatesWrapper = styled.div`
	display: flex;
	gap: 10px;

	.new_datepicker {
		min-width: unset;
		max-width: unset;

		.no_date_suffix > div {
			padding: 0;
		}
	}
`;

const FilterWrapper = styled.div`
	width: max-content;
	margin-bottom: 2rem;
	display: flex;
	gap: 10px;

	button:first-of-type {
		height: max-content;
	}

	.Polaris-Tag {
		background: var(--main2);
	}

	.Polaris-Filters-ConnectedFilterControl__Wrapper {
		width: max-content;
	}

	/* position: absolute;
	top: 0;
	right: 2.5rem;
	z-index: 1; */
`;

const DateText = styled.span`
	position: absolute;
	top: 0;
	right: 4px;
	z-index: 1;
	padding: 0.35rem;

	transition: all 250ms;
	/* color: var(--textColor2); */
	opacity: 0.35;
	font-size: 12px;

	&:hover {
		opacity: 1;
	}
`;
