import React, { Component } from "react";
import moment from "moment";
import ProfileAvatar from "../../components/ProfileAvatar.js";
import GoalPercentage from "./GoalPercentage";

moment.locale("sv");

class GoalUserListItem extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { user, onClick, money, suffix, getUserSum, index, loading, goal, value } = this.props;

		if (loading) {
			return (
				<div className={`goal-user index-${index} loading`} key={index}>
					<div className="loading-div">
						<div className="loading-profile" />
						<div className="loading-title" style={{ width: Math.min(150, Math.max(25, Math.random() * 200)) }} />
					</div>
					<div className="loading-textt" style={{ width: Math.min(150, Math.max(25, Math.random() * 200)) }} />
				</div>
			);
		}

		const sum = getUserSum(user.user);
		if (!("forecasted_value" in user)) {
			user.forecasted_value = sum;
		}
		const overValue = sum - value;
		const overPercentage = (overValue / value || 0) * 100;

		return (
			<div onClick={onClick} className={`goal-user index-${index}${loading ? " loading" : ""}`} key={user.id || index}>
				<div className="goal-user-title">
					<ProfileAvatar size={50} user={user.user} />
					<b>{user.user.name}</b>
				</div>

				<div style={{ display: "flex", alignItems: "center", padding: "5px", gap: "0.5rem" }}>
					<GoalPercentage days={this.props.days} value={overPercentage} goal={goal} forecasted_value={value} sum={sum} />
					{money(sum, "{{fixed_amount}}")} / {money(value, "{{fixed_amount}}")} {suffix}
				</div>

				{/* <ProgressBar progress={percentage} variant="success" /> */}
			</div>
		);
	}
}

export default GoalUserListItem;
