import { Icon, Stack, Tooltip } from "@shopify/polaris";
import { SimplifyMajor } from "@shopify/polaris-icons";
import React, { useCallback, useState, useRef, useEffect } from "react";
import TextField from "src/js/components/TextField";
import styled from "styled-components";
import { formatNumberToRead, formatNumberToWrite } from "../Utilities/formatNumbers";
import { useEventListener } from "src/js/hooks/UseEventListener";

const TargetValuesRow = ({ targetConnection, getChangeHandler, isLoading, targetConnectionIndex, columns, form }) => {
	const [rowForm, setRowForm] = useState(targetConnection);
	const isInputFocused = useRef(false);
	const ref = React.useRef<any>(null);

	const handleFormatOnBlur = useCallback(() => {
		setRowForm((c: Partial<TargetConnectionType>) => {
			const newForm = { ...c } as TargetType;

			newForm?.target_connection_values?.forEach((targetValue, periodIndex) => {
				if (targetValue.value) {
					targetValue.value = formatNumberToRead(targetValue.value, form.number_of_decimals);
				}
			});

			return newForm;
		});
	}, [form.number_of_decimals]);
	useEventListener("keydown", (e) => {
		if (e.key === "Enter") {
			if (form && [...(ref?.current?.querySelectorAll("input") || [])]?.some((el) => el === document.activeElement)) {
				(document.activeElement as any)?.blur?.();
			}
		}
	});

	useEffect(() => {
		setRowForm(targetConnection);
	}, [targetConnection]);

	const onChange = useCallback(
		(periodIndex: number) => (value: string) => {
			setRowForm((c: Partial<TargetConnectionType>) => {
				const newForm = { ...c } as TargetType;

				const targetValue = targetConnection.target_connection_values[periodIndex] as TargetConnectionValue;
				targetValue.value = value === "" ? null : value;
				targetConnection.target_connection_values[periodIndex] = targetValue;

				return newForm;
			});
		},
		[setRowForm, targetConnection]
	);

	const handleSubmit = useCallback(() => {
		if (!isInputFocused.current) {
			getChangeHandler(targetConnectionIndex, targetConnection.id)(rowForm);
		}
	}, [getChangeHandler, targetConnectionIndex, targetConnection, rowForm]);

	const handleFocus = useCallback(() => {
		isInputFocused.current = true;
	}, []);

	const handleBlur = useCallback(() => {
		isInputFocused.current = false;
		handleFormatOnBlur();

		setTimeout(() => {
			if (!isInputFocused.current) {
				handleSubmit();
			}
		}, 1);
	}, [handleSubmit, handleFormatOnBlur]);

	const total = rowForm?.target_connection_values?.reduce((acc, curr) => {
		const value = curr.value || curr.inherited_value;
		if (value) acc += parseFloat(formatNumberToWrite(value));

		return acc;
	}, 0);

	return (
		<tr key={targetConnection.id} ref={ref}>
			{columns.map((col, periodIndex) => {
				if (col.index === "total") {
					return <TextField align="right" key={"total"} readOnly value={formatNumberToRead(total, form.number_of_decimals)} suffix={form.suffix} />;
				}

				const targetValue = rowForm?.target_connection_values?.[periodIndex];
				const isInherited = targetValue?.inherited_value != null && (targetValue?.value == null || targetValue?.value == undefined);

				return (
					<td key={periodIndex}>
						<TextField
							align="right"
							disabled={isLoading}
							suffix={
								isInherited ? (
									<>
										<Tooltip content={`Ärvt ifrån: ${targetValue.inherited_from}`}>
											<Stack alignment="center">
												<InheritedIconWrapper>
													<Icon source={SimplifyMajor} />
												</InheritedIconWrapper>
												<p>{form.suffix}</p>
											</Stack>
										</Tooltip>
									</>
								) : (
									<p>{form.suffix}</p>
								)
							}
							placeholder={isInherited ? `${targetValue?.inherited_value ?? "-"}` : ""}
							value={targetValue?.value?.toString()}
							onChange={onChange(periodIndex)}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
					</td>
				);
			})}
		</tr>
	);
};

export default TargetValuesRow;

const InheritedIconWrapper = styled.div`
	.Polaris-Icon {
		transition: opacity 0.2s;

		height: 1.25rem;
		width: 1.25rem;
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}
`;
