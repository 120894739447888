import { Button, Checkbox, Icon, Spinner } from "@shopify/polaris";
import { UseQueryResult } from "@tanstack/react-query";
import useQuery from "src/js/hooks/useQuery";

import React, { useCallback, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import { store } from "src/js/store";
import BoardHelper from "src/js/views/Workspaces/BoardHelper";
import fetchRows from "src/js/views/Workspaces/boardutils/fetchRows";
import BoardFilters from "src/js/views/Workspaces/components/BoardFilters";
import styled from "styled-components";
import { FixedSizeList, areEqual } from "react-window";
import RowModal from "src/js/views/Workspaces/components/RowModal";
import { StyledDocumentItem } from "src/js/views/Workspaces/components/BoardUploadSheet";
import RowSnippet2 from "src/js/views/Workspaces/components/RowSnippet2";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";

type MediumBoardProps = {
	rows?: BoardRowType[];
	board?: BoardType | null;
	boardId?: string | number;
	groups?: BoardGroupType[];
	params?: any;
	contact?: ContactType | null;
	contactId?: string | null | number;
	onCreateRow?: (row: BoardRowType) => void;
	onClickRow?: (row: BoardRowType, clickedOnTitle: boolean) => void;
	setCount?: (count: number) => void;
	t: any;
};

const MediumBoard = ({
	rows: propsRows = [],
	board: propsBoard = null,
	boardId: propsBoardId,
	groups: propsGroups = [],
	params: propsparams = {},
	contact = null,
	contactId = null,
	onCreateRow: propsOnCreateRow,
	onClickRow,
	setCount,
	t,
}: MediumBoardProps) => {
	const boardId = propsBoard?.id || propsBoardId;
	const [filters, setFilters] = useState<any>([]);
	const [row, setRow] = useState<BoardRowType | null>(null);
	const [rowModalIsOpen, setRowModalIsOpen] = useState(false);

	const params = propsparams;
	if (contact) {
		params.contact_id = contact.id;
	} else if (contactId) {
		params.contact_id = contactId;
	}

	const handleFetchRows = async () => {
		if (!boardId) return [];
		const rows = await fetchRows({ boardId, params });
		if (setCount) setCount(rows.length);
		return rows;
	};

	const queryKey = Object.entries(params)
		.filter(([, value]) => value)
		.map(([key, value]) => `board_rows-${key}-${value}`);

	const {
		data: rawRows = [],
		isFetching: isFetchingRows,
		refetch: refrechRows,
	}: UseQueryResult<BoardRowType[] | null> = useQuery({
		queryKey,
		queryFn: handleFetchRows,
		refetchOnWindowFocus: false,
		initialData: propsRows,
		enabled: !!((!propsRows || !propsRows.length) && boardId),
	});

	const fetchBoard = async () => {
		const result = await API.get("/api/boards/" + boardId + ".json", { params: {} });
		store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
		return result.data.board;
	};

	const boardQueryKey = [boardId && `board_${boardId}`].filter(Boolean) as any[];
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { data: board = null, isFetching: isFetchingBoard }: UseQueryResult<BoardType | null> = useQuery({
		queryKey: boardQueryKey,
		queryFn: fetchBoard,
		refetchOnWindowFocus: false,
		initialData: propsBoard,
		enabled: !!(!propsBoard && boardId && boardQueryKey?.length),
	});

	const fetchGroups = async () => {
		const result = await API.get("/api/boards/" + boardId + "/groups.json");
		return result.data.groups;
	};

	const groupQueryKey = [boardId && `board_${boardId}_groups`].filter(Boolean) as any[];
	const { data: groups = [], isFetching: isFetchingGroups }: UseQueryResult<BoardGroupType[] | null> = useQuery({
		queryKey: groupQueryKey,
		queryFn: fetchGroups,
		refetchOnWindowFocus: false,
		initialData: propsGroups,
		enabled: !propsGroups && boardId && groupQueryKey?.length,
	});

	const onCreateRow = (row) => {
		if (propsOnCreateRow) {
			propsOnCreateRow(row);
		} else {
			BoardHelper.updateRows([row], "add");
		}
		setRowModalIsOpen(false);

		refrechRows();
	};

	const handleOnClickRow = useCallback(
		(row, clickedOnTitle) => {
			if (onClickRow) {
				onClickRow(row, clickedOnTitle);
				return;
			}

			const getColumn = (id) => {
				if (!board) return;
				if (board.columns) return board.columns.find((c) => c.id == id);
			};

			if (board) {
				Object.values(row.column_values).forEach((col: BoardColumnType) => {
					if (getColumn(col.column_id) && ["contact", "rowlink", "ticket"].includes(getColumn(col.column_id).type)) {
						BoardHelper.fetchResources(getColumn(col.column_id).type, col.value);
					}
				});
			}

			setRow(row);
		},
		[board, onClickRow]
	);

	const rows = useMemo(() => {
		if (!rawRows) return [];
		return BoardHelper.filterRows(rawRows, filters);
	}, [rawRows, filters]);
	const loading = isFetchingRows && !rows?.length;

	const renderRowFunc = useCallback(
		(props) => {
			const { data: rows, index, style } = props;
			const row = rows[index];
			const key = row.type == "quickadd" || row.type == "summary" || row.type == "blank" ? row.type + row.group_id : (row.color ? "g" : "") + row.id;

			const obj = (
				<div key={key} className="board-item" style={style}>
					<RowSnippet2
						key={key}
						onClick={handleOnClickRow}
						group={groups ? groups.find((g) => g.id === row.group_id) : { id: row.group_id, color: row.group_color }}
						row={row}
					/>
				</div>
			);
			return obj;
		},
		[groups, handleOnClickRow]
	);
	const renderRow = React.memo(renderRowFunc, areEqual);

	return (
		<div className="miniboard">
			<div className="miniboard_header">
				<LoadingSpinner className="LoadingSpinner"> {isFetchingRows && <Spinner size="small" />}</LoadingSpinner>
				<div style={{ flex: 1 }}>
					<BoardFilters
						onChangeFilters={setFilters}
						filters={filters}
						boards={[board]}
						groups={groups}
						pinnedFilters={board?.columns
							?.map((col) => {
								if (col.title === "Affärsstatus") return col?.id;
								return null;
							})
							.filter(Boolean)}
						filteredRows={rows}
						rows={rawRows}
						placeholder={
							isFetchingRows
								? `${t("board.miniboard.terms.getting", "Hämtar")} ${(board && board.plural) || "data"}..`
								: t("board.miniboard.search.placeholder", "Sök..")
						}
						additionalAction={
							<Checkbox
								label={t("board.miniboard.terms.also_archived", "Visa även arkiverade {{plural}}", {
									plural: board?.plural?.toLowerCase(),
								})}
								disabled={isFetchingRows}
								checked={filters.archived}
								onChange={(checked) => {
									setFilters({ ...filters, archived: checked ? 1 : 0 });
								}}
							/>
						}
					/>
				</div>
				<span className="create_button">
					<Button plain onClick={() => setRowModalIsOpen(true)} disabled={contact?.removed || !board}>
						{
							(
								<div className="button_inner">
									<span>
										{t("board.actions.add_new", "Lägg till ny")} {(board && board.singular) || (t("board.terms.row", "rad") as string)}
									</span>
									<Icon source={CirclePlusOutlineMinor} />
								</div>
							) as any
						}
					</Button>
				</span>
			</div>

			{/* <Virtuoso
				data={rows}
				defaultItemHeight={125}
				computeItemKey={(index, row) => {
					return row?.id || index;
				}}
				// height={rows.length * 125}
				style={{ flex: 1, height: "100%" }}
				itemContent={(key, row) => {
					return (
						<RowSnippet2
							onClick={handleOnClickRow}
							group={groups ? groups.find((g) => g.id === row.group_id) : { id: row.group_id, color: row.group_color }}
							row={row}
						/>
					);
				}}
				components={{
					EmptyPlaceholder: () => (
						<div className="board-empty_screen">
							<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
							<h2>
								{t("board.terms.none_found", "Inga {{plural}} hittades", {
									plural: board?.plural?.toLowerCase(),
								})}
							</h2>
						</div>
					),
				}}
			/> */}

			<FixedSizeList height={rows.length * 125} itemCount={rows && rows.length} itemSize={125} itemData={rows} overscanCount={10}>
				{renderRow}
			</FixedSizeList>

			{(!rows || !rows.length) && !isFetchingRows && !isFetchingGroups && (
				<div className="board-empty_screen">
					<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
					<h2>
						{t("board.terms.none_found", "Inga {{plural}} hittades", {
							plural: board?.plural?.toLowerCase(),
						})}
					</h2>
				</div>
			)}
			{loading && (
				<React.Fragment>
					<StyledDocumentItem style={{ height: "125px" }} />
					<StyledDocumentItem style={{ height: "125px" }} />
					<StyledDocumentItem style={{ height: "125px" }} />
				</React.Fragment>
			)}
			{board && (row || rowModalIsOpen) ? (
				<RowModal
					open={rowModalIsOpen}
					contact={!row && contact}
					row={row}
					board={board}
					groups={groups}
					onUpdateRow={() => {}}
					defaultValues={[]}
					getContact={() => {}}
					onClose={() => {
						setRow(null);
						setRowModalIsOpen(false);
					}}
					onCreateRow={onCreateRow}
				/>
			) : null}
		</div>
	);
};
export default withTranslation(["board", "common"])(MediumBoard);

const LoadingSpinner = styled.div`
	position: absolute;
	z-index: 99999;
	background: var(--main3);
	left: 10px;
	height: 20px;
`;
