import React, { Component } from "react";
import { Button } from "@shopify/polaris";
import { CalendarMajor, ChecklistMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import { store } from "../../../store";
import ContactCard from "../../../components/ContactCard.js";
// import ReminderModal from "../../../components/ReminderModal";
import TimeLine from "../../../components/TimeLine.js";
// import TaskSheet from "../../Agenda/TaskSheet";
import CalendarTaskModal from "../../Agenda/CalendarTaskModal";
import { withRouter } from "react-router-dom";
import circularReferenceReplacer from "src/js/Utilities/circularReferenceReplacer";

class RowContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: this.props.contact || {},
		};

		this.abortController = new AbortController();
	}

	componentWillUnmount() {
		this.abortController.abort();
	}

	fetch(id) {
		this.abortController.abort();
		this.abortController = new AbortController();

		this.fetchContact(id);
		this.fetchTasks(id);
	}

	componentWillReceiveProps(nextProps) {
		if (JSON.stringify(nextProps.contact, circularReferenceReplacer()) !== JSON.stringify(this.props.contact, circularReferenceReplacer())) {
			this.setState({ form: nextProps.contact });
		}

		if (nextProps.contact?.id != this.props.contact?.id) {
			this.setState({ form: nextProps.contact });
			this.fetch(nextProps.contact.id);
		}
	}

	componentDidMount() {
		this.fetch(this.props.contact.id);

		// this.fetchComments();
	}

	fetchContact(id = this.props.contact.id) {
		API.get("/api/contacts/" + id + ".json", { params: {}, signal: this.abortController.signal })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ form: result.data.contact });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchTasks(id = this.props.contact.id) {
		this.setState({ loadingTasks: true });
		API.get("/api/contacts/" + id + "/tasks.json", {
			params: {},
			signal: this.abortController.signal,
		})
			.then((result) => {
				this.setState({ loadingTasks: false, tasks: result.data.tasks });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	showReminderModal() {
		this.setState({ showReminderModal: true, type: "calendar" });
	}

	showTodoModal() {
		this.setState({ showReminderModal: true, type: "task" });
	}

	render() {
		return (
			<div style={this.props.style}>
				<div style={{ display: "flex", gap: "0.75rem", padding: "0.75rem" }}>
					{store.getState().user.calendar_board && (
						<Button removeUnderline plain onClick={this.showReminderModal.bind(this)} icon={CalendarMajor}>
							<span style={{ marginLeft: "5px", color: "var(--textColor)" }}>{this.props.t("calendar.actions.new", "Nytt kalenderinlägg")}</span>
						</Button>
					)}

					{store.getState().user.todo_board && (
						<Button removeUnderline plain onClick={this.showTodoModal.bind(this)} icon={ChecklistMajor}>
							<span style={{ marginLeft: "5px", color: "var(--textColor)" }}>{this.props.t("calendar.actions.new_task", "Ny uppgift")}</span>
						</Button>
					)}
				</div>

				<ContactCard
					compact
					row={this.props.row}
					board={this.props.board}
					contact={this.state.form}
					onChangeContact={() => {}}
					gotoContact={(parent) => {
						this.props.history.push("/admin/contacts/" + parent.id);
					}}
					onUpdate={(contact) => {
						this.setState({ form: contact });
					}}
					external={this.props.external}
				>
					<TimeLine
						external={this.props.external}
						handle={"contacts/" + this.props.contact.id}
						tasks={this.state.tasks || []}
						deals={[]}
						tickets={[]}
						projects={[]}
						call_lists={[]}
						// comments={this.state.comments || []}
						// row_comments={this.state.row_comments || []}
						history={this.props.history}
						onComment={() => {}}
						onNewContact={() => {}}
						contact={this.state.form}
						isCompany={this.state.form && this.state.form.is_company}
						loading={this.state.loadingTasks}
					/>
				</ContactCard>

				<CalendarTaskModal
					open={this.state.showReminderModal}
					selected={this.state.type}
					onClose={() => {
						this.setState({ showReminderModal: false, type: null });
					}}
					onComplete={(row) => {
						this.setState({ showReminderModal: false });
					}}
					options={{
						contacts: this.state.form ? [this.state.form] : [],
					}}
					// external={this.props.external}
				/>
			</div>
		);
	}
}
export default withTranslation(["row", "calendar", "common"], {
	withRef: true,
})(withRouter(RowContactForm));
