import { Button } from "@shopify/polaris";
import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import DocumentStatusBar from "./Document/DocumentStatusBar";

type BoardUploadScriveIntegrationProps = {
	file: any;
	column: BoardColumnType;
	row: BoardRowType;
	values: any;
	contact: ContactType;
	history?: any;
	updateFile: (file: any) => void;
} & WithTranslation &
	RouteComponentProps;

const BoardUploadScriveIntegration = ({ file, column, row, values, contact, t, updateFile, history }: BoardUploadScriveIntegrationProps) => {
	const [isLoading, setIsLoading] = useState(false);

	const handleOpen = (scriveId) => {
		const url = `/admin/scrive/documents/${scriveId}`;
		// window.open(url, "_blank");
		if (history) {
			history.push(url, { document: file.scrive_data, edit: true, tabId: "parties" });
		} else {
			window.location.href = window.location.origin + url;
		}
	};

	const sendToScrive = async () => {
		setIsLoading(true);
		try {
			const result = await API.post("/api/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/scrive_upload.json", {
				id: file.id,
			});

			if (result.data.error) {
				throw result.data.error;
			}

			file = result.data.file;
			updateFile(result.data.file);

			if (result.data.file.id) {
				toastr.success(t("scrive.actions.prepare.success", "Dokument förberett för signering"));
				handleOpen(file.scrive_id);
			}
		} catch (error) {
			toastr.error(error);
		}

		setIsLoading(true);
	};

	if (!file.scrive_id && file.id && (file.type === "application/pdf" || file.type === "pdf")) {
		return (
			<ButtonWrapper>
				<Button loading={isLoading} onClick={sendToScrive} icon={<img src="/assets/scrive.png" alt="scrive" />}>
					{t("scrive.actions.prepare1", "Skicka till Scrive") as string}
				</Button>
			</ButtonWrapper>
		);
	}

	if (file.scrive_id && file.scrive_data)
		return (
			<DocumentStatusBar
				onClick={(e: any) => {
					e?.stopPropagation();
					e?.preventDefault();
					handleOpen(file.scrive_id);
				}}
				document={file.scrive_data}
			/>
		);

	return null;
};
export default withTranslation(["scrive", "common"])(withRouter(BoardUploadScriveIntegration));

const ButtonWrapper = styled.div`
	.Polaris-Button__Text {
		font-size: 12px;
	}
`;
