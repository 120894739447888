/* eslint-disable no-return-await */
/* eslint-disable camelcase */
/* eslint-disable func-names */
import axios from "axios";
import { toastr } from "../components/toastr";
import { store } from "../store";
import { getCookie, getRefreshToken } from "../Utilities";
import { TTL, decodeTokenUser, getTokenTTL } from "./utils";
import BASE_URL from "./BASE_URL";
import clearLogin from "src/js/login/clearLogin";
import { ERROR_MESSAGES } from "src/constants/errors";
import retryFortnoxRequest from "./retryFortnoxRequest";

console.debug("BASE_URL:", BASE_URL);
const API = axios.create({
	timeout: 60000,
	// baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "https://itbmedia3.eu.ngrok.io" : "",
	// baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://192.168.10.225:8000" : "",
	// baseURL: "http://192.168.1.201:8000",
	baseURL: BASE_URL,
});

let promise = null;
export const setPromise = (data) => {
	promise = data;
};

export const fetchAndSetTokens = async (refreshToken = getRefreshToken(), options = {}) => {
	const { history } = { ...(options || {}) };
	// const token = store.getState().user?.jwttoken || getLocalStorage("jwttoken");
	if (refreshToken) {
		return axios
			.post(BASE_URL + "/api/token/refresh_token", { refresh_token: refreshToken })
			.then((res) => {
				if (res.data.refresh_token) {
					store.dispatch({
						type: "SET_REFRESH_TOKEN",
						refresh_token: res.data.refresh_token,
					});
				}

				if (res.data.token) {
					store.dispatch({ type: "SET_TOKEN", token: res.data.token });
				}

				return res;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				clearLogin();

				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}

				const { code, message } = error.response?.data || {};

				if (window.location.href.indexOf("admin") > 0) {
					const error = message || "Invalid login session";
					const url = `/admin/authentication/login?error_code=${code}&error_msg=${error}`;
					if (history) {
						history.replace(url);
					} else {
						window.location.assign(encodeURI(url));
					}

					throw new Error("ignore");
				}

				return Promise.reject(error);
			});
	} else {
		if (window.location.href.indexOf("admin") > 0) {
			// For now, because we dont have a refresh token for mobile iframes
			const url = `/admin/authentication/login?error_code=401&error_msg=Invalid login session`;

			if (history) {
				history.replace(url);
			} else {
				window.location.assign(encodeURI(url));
			}
		}
		console.error("No refreshToken available");
		throw new Error("No refreshToken available");
	}
};

const tokenValidation = async () => {
	if (!promise?.request || Date.now() > promise?.ttl) {
		const request = fetchAndSetTokens().catch((error) => console.error(error));

		promise = {
			request,
			ttl: Date.now() + TTL - 60000,
		};

		if (promise) promise.ttl = getTokenTTL(await request.then((r) => r?.data?.token));
	}

	return promise?.request;
};

const getToken = async () => {
	const token = store.getState()?.token || getCookie("JWTTOKEN");
	const tokenData = decodeTokenUser(token);
	if ((tokenData?.exp || 0) * 1000 - 60 * 1000 > Date.now()) {
		return token;
	} else {
		const request = await tokenValidation();
		return request?.data?.token;
	}
};

API.interceptors.request.use(
	async (config) => {
		try {
			const urlParams = new URLSearchParams(window.location.search);

			if (!config.params) {
				config.params = {};
			}

			const acc_id = urlParams.get("acc_id");
			if (acc_id) config.params.acc_id = acc_id;

			const account_id = urlParams.get("account_id");
			if (account_id) config.params.account_id = account_id;

			const token = urlParams.get("token");

			// guest view
			if (token) {
				config.params.token = token;
				if (!store.getState()?.user?.token)
					store.dispatch({
						type: "UPDATE_ME",
						user: {
							token,
						},
					});
			}
			// guest view
			if (!config.params.token && window.location.pathname?.startsWith("/guest/")) {
				const token = store.getState().user?.token;
				if (token) {
					config.params.token = token;
				}
			}

			const signed_token = urlParams.get("signed_token");
			if (signed_token) config.params.signed_token = signed_token;

			if (!token) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const refreshToken = getRefreshToken();

				if ((!config.headers?.Authorization?.length || config.headers?.Authorization?.length < 20) && !config.skipToken) {
					const token = await getToken();
					if (token) {
						config.headers.Authorization = `Bearer ${token}`;
					}
				}
			}
		} catch (e) {
			console.error("error", e);
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		if (response.data.error) {
			console.error("error:", response.data.error);
			toastr.error(response.data.error);
			return response;
			// throw response.data.error;
		}

		return response;
	},
	async function (error) {
		if (axios.isCancel(error)) {
			console.debug("Request canceled");
			return Promise.reject(error);
		}

		// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
		const { code, message } = error.response?.data || {};
		// Expired JWT Token
		// Invalid JWT Token

		if (
			[
				ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN_OR_INVALID_FOR_THE_CLIENT,
			].includes(message)
		) {
			return await retryFortnoxRequest(error);
		}

		if (code === 401 || error?.headers?.location === "/admin/authentication/login") {
			const token = await getToken();
			return await axios({
				...(error.config || {}),
				headers: {
					...(error.config.headers || {}),
					Authorization: `Bearer ${token}`,
				},
			}).catch((error) => {
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				const { code, message } = error.response?.data || {};

				switch (code) {
					case 401:
						switch (message) {
							case "Expired JWT Token":
								window.location.assign(encodeURI("/admin/authentication/login?error_code=401&error_msg=Expired login session"));
								break;
							case "Invalid JWT Token":
								window.location.assign(encodeURI("/admin/authentication/login?error_code=401&error_msg=Invalid login session"));
								break;
							default:
								console.debug("other");
								window.location.assign("/admin/authentication/login");
								break;
						}
						break;
					default:
						break;
				}

				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}
				toastr.error(error);
				throw error;
			});
		}

		if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
			console.error("error:", error);
		}
		toastr.error(error);

		return Promise.reject(error);
	}
);

export default API;
