// import { Button, Card, FormLayout, Layout } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import ConvertinvoiceToDealModal from "../../views/Fortnox/ConvertinvoiceToDealModal";
import API from "../../API";
import { store } from "../../store/index.js";
import FortnoxSyncedFalseScreen from "../../views/Workspaces/FortnoxSyncedFalseScreen";
import AppIntegrationImportModal from "../AppIntegrationImportModal";
import Banner from "../banner";
import { toastr } from "../toastr";
import Page from "../page";

const TARGET = "integration-window";

//! NOT IN USE, REPLACE BY FortnoxSettings.tsx
class FortnoxSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: props.options,
			...props,
		};

		this.onMessageRecived = this.onWindowReciveMessage.bind(this);
	}

	save() {
		API.put("/api/integrations/" + this.props.integration.id + ".json", {
			options: this.state.options,
		})
			.then((result) => {
				this.setState({ saving: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
			});
	}

	syncCustomers() {
		this.setState({ openImportModal: true });
	}

	toggleField(field) {
		const foundIndex = this.state.options.sync_fields.indexOf(field);
		if (foundIndex >= 0) {
			this.state.options.sync_fields.splice(foundIndex, 1);
		} else {
			this.state.options.sync_fields.push(field);
		}
		this.setState({ options: this.state.options });
		this.save();
	}

	addOrderModule() {
		const { handle } = store.getState().account;

		if (handle) {
			this.openWindow(
				`/api/fortnox/connect?${new URLSearchParams({
					scopes: "customer order offer article invoice print settings companyinformation",
					type: "fortnox",
					title: "Fortnox",
					integration_id: "fortnox",
					handle,
				}).toString()}`
			);
		}
	}

	onWindowReciveMessage(event) {
		const {
			data: { payload, source },
			isTrusted,
		} = event;
		if (isTrusted && source === TARGET) {
			if (payload.success == true) {
				window.removeEventListener("message", this.onMessageRecived);
				toastr.success(`Lyckades ansluta integrationen "${payload.title}"`);
				window.location.reload();
			} else {
				console.debug("---data:", event?.data);
			}
		}
	}

	openWindow(url) {
		const centerX = (window.screen.availWidth - 425) / 2;
		const centerY = (window.screen.availHeight - 700) / 2;
		// eslint-disable-next-line react/no-unused-class-component-methods
		this.childWindow = window.open(
			url,
			TARGET,
			`scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=425,height=700,left=${centerX},top=${centerY}`
		);
		window.addEventListener("message", this.onMessageRecived);
	}

	render() {
		const { options } = this.state;

		return (
			<React.Fragment>
				{options?.synced ? (
					<Page
						title={this.props.t("fortnox.synced.title", "Fortnox")}
						subtitle={this.props.t("fortnox.synced.sub_title", "Välkommen till inställningssidan för er integration med Fortnox.")}
						primaryAction={{
							content: this.props.t("fortnox.synced.actions.sync_import", "Synka från Fortnox"),
							onAction: this.syncCustomers.bind(this),
							loading: this.state.saving,
						}}
					>
						{this.state.integration.type == "fortnox_invoice" && (
							<Banner status="info" actions={[{ content: "Koppla", onAction: this.addOrderModule.bind(this) }]} title="Aktivera ordermodul">
								<p>
									{this.props.t(
										"fortnox.synced.activate.order.module",
										"Om du har ordermodulen så kan du aktivera den igenom att klicka på knappen nedan."
									)}
								</p>
							</Banner>
						)}

						{/* <Layout.AnnotatedSection
							title={this.props.t("fortnox.invoice_to_board_row.title", "Importera fortnox fakturor som vunna affärer")}
							description={this.props.t(
								"fortnox.invoice_to_board_row.description",
								"Importera fortnox fakturor till affärs boarden som vunna affärer"
							)}
						>
							<Card sectioned>
								<FormLayout>
									<Button
										size="large"
										onClick={() => {
											this.setState({ InvoicetoDealModalIsOpen: true });
										}}
										primary
									>
										{this.props.t("fortnox.actions.invoice_to_board_row.import", "Importera")}
									</Button>
								</FormLayout>
								<ConvertinvoiceToDealModal
									integration={this.state.integration}
									open={this.state.InvoicetoDealModalIsOpen}
									onClose={() => {
										this.setState({ InvoicetoDealModalIsOpen: false });
									}}
									onComplete={() => {
										this.setState({ InvoicetoDealModalIsOpen: false });
									}}
								/>
							</Card>
						</Layout.AnnotatedSection> */}
						{/* <Layout>{this.renderSettings(settings)}</Layout> */}
					</Page>
				) : (
					<FortnoxSyncedFalseScreen
						syncCustomers={this.syncCustomers.bind(this)}
						options={this.state.options}
						toggleField={this.toggleField.bind(this)}
						integration={this.props.integration}
					/>
				)}
				<AppIntegrationImportModal
					open={this.state.openImportModal}
					external={this.props.external}
					onComplete={() => {
						this.state.options.synced = true;
						this.setState({ openImportModal: false });
						this.save();
						if (!this.props.external) {
							document.location = "/admin/contacts/companies";
						}
					}}
					onClose={() => {
						this.setState({ openImportModal: false });
					}}
					integration={this.props.integration}
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxSettings);
