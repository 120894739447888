import { Pagination, ResourceList, Spinner } from "@shopify/polaris";
import React, { useImperativeHandle, useState } from "react";
import API from "src/js/API";
import { isCancel } from "axios";
import { WithTranslation, withTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import { store } from "src/js/store";
import styled, { css } from "styled-components";
import MyFilters from "src/js/components/MyFilters";
import debounce from "lodash/debounce";
import useQuery from "src/js/hooks/useQuery";
import { toastr } from "../../components/toastr";
import { StyledDocumentItem } from "../Workspaces/components/BoardUploadSheet";

interface FortnoxContactResourceListProps extends WithTranslation {
	renderItem: (item: any) => React.ReactNode;
	// resouce from result.data[resource] and as fallback for url (t.ex ${resourceHandle || resource}.json)
	resource: string;
	// resouce in url (t.ex ${resourceHandle}.json)
	resourceHandle?: string;
	params?: any;
	contact?: ContactType;
	resourceName?: { singular: string; plural: string };
	filters?: any[];
	additionalAction?: any;
	limit?: number;
}

const FortnoxContactResourceList = React.forwardRef(
	(
		{
			renderItem,
			resource,
			resourceHandle,
			contact,
			params: propsParams,
			t,
			resourceName,
			filters: propsFilters,
			additionalAction,
			limit: propsLimit,
		}: FortnoxContactResourceListProps,
		ref
	) => {
		const [appliedFilters, setAppliedFilters] = useState([]);
		const [search, setSearch] = useState("");
		const [params = {}, setParams] = useState<any>({
			...(propsParams || {}),
			offset: 0,
		});
		const limit = propsLimit || 10;

		const handleFiltersChange = (appliedFilters) => {
			setAppliedFilters(appliedFilters);
			setOffset(0);
		};

		const delayedUpdateParams = debounce((params) => {
			setParams(params);
		}, 350);

		const handleSearchChange = (search) => {
			setSearch(search);
			setOffset(0);

			if (!search) {
				setParams(({ q, ...rest }) => ({
					...rest,
				}));
			} else {
				delayedUpdateParams({
					...params,
					q: search,
				});
			}
		};

		const setOffset = (offset) => {
			setParams((c) => ({
				...c,
				offset,
			}));
		};

		const fetch = async () => {
			const url = contact?.id
				? `/api/fortnox/contacts/${contact?.id}/${(resourceHandle || resource).toLowerCase()}.json`
				: `/api/fortnox/${(resourceHandle || resource).toLowerCase()}.json`;
			return await API.get(url, {
				params: {
					...(propsParams || {}),
					limit,
					...params,
					...appliedFilters.reduce((acc, val: any) => {
						acc[val.key] = val.value;
						return acc;
					}, {}),
				},
			})
				.then((response) => {
					return {
						data: response?.data?.[resource] || [],
						count: response?.data?.count,
					};
				})
				.catch((error) => {
					if (isCancel(error)) {
						console.debug("Request canceled", error.message);
					} else {
						toastr.error(error);
						throw error;
					}
				});
		};
		const queryKey = [
			contact?.id && `contact-${contact?.id}`,
			contact?.fortnox_customer_id && `contact_fornox_customer_id_${contact?.fortnox_customer_id}`,
			resource && `fortnox_${resource}`,
			...((Object.entries(params)?.map((arr) => arr.join("_")) || []) as string[]),
			...((appliedFilters?.map((val: any) => `${val.key}_${val.value}`) || []) as string[]),
		].filter(Boolean);

		const { data: queryData, isFetching }: UseQueryResult<any> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			enabled: !!(store.getState().account.fortnox_integration && queryKey?.length),
			initialData: {
				data: [],
				count: 0,
			},
		});

		const { data = [], count = 0 } = queryData || { data: { items: [], count: 0 } };

		const onNext = () => {
			setOffset(params.offset + limit);
		};

		const onPrevious = () => {
			setOffset(params.offset - limit);
		};

		useImperativeHandle(ref, () => ({
			refetch: () => {
				setOffset(0);
			},
		}));

		const pagination = (
			<Pagination
				label={
					<div style={{ position: "relative" }}>
						{isFetching && !!count && (
							<div style={{ position: "absolute", inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
								<Spinner size="small" />
							</div>
						)}
						<span>
							{t("fortnox.invoice.pagination.shows", "Visar {{counts}} av {{total}}", {
								counts: count ? Math.min(params.offset + limit, count) : "-",
								total: count || "-",
							})}
						</span>
					</div>
				}
				hasNext={count > params.offset + limit}
				hasPrevious={params.offset > 0 && count}
				onNext={onNext}
				onPrevious={onPrevious}
			/>
		);

		const filters = [...(propsFilters || [])];
		if (!contact) {
			if (!filters?.find((f) => f.key === "customernumber")) {
				filters.unshift({
					key: "customernumber",
					label: t("fortnox.contact.fields.CustomerNumber.label", "Kundnummer"),
					type: "textField",
				});
			}

			if (!filters?.find((f) => f.key === "customername")) {
				filters.unshift({
					key: "customername",
					label: t("fortnox.contact.fields.CustomerName.label", "Kundnamn"),
					type: "textField",
				});
			}
		}

		const filterControl = (
			<MyFilters
				filters={filters}
				appliedFilters={appliedFilters}
				onFiltersChange={handleFiltersChange}
				searchValue={search}
				onSearchChange={handleSearchChange}
				additionalAction={additionalAction}
			/>
		);

		return (
			<Wrapper loading={isFetching}>
				{/* {!!items?.length && <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem" }}>{pagination}</div>} */}
				<ResourceList
					// loading={isFetching}
					filterControl={filterControl}
					items={data || []}
					renderItem={renderItem}
					resourceName={resourceName}
					idForItem={(item, index) => {
						return item?.DocumentNumber || index;
					}}
					totalItemsCount={count || 1}
					hasMoreItems={(count || 0) > params.offset + limit}
					emptyState={
						isFetching &&
						!data?.length && (
							<>
								{[...Array(limit)].map((item, index) => {
									return <StyledDocumentItem key={index} style={{ height: "100px", backgroundColor: "var(--main2)" }} />;
								})}
							</>
						)
					}
				/>

				<div style={{ display: "flex", justifyContent: "center" }}>{pagination}</div>
			</Wrapper>
		);
	}
);
export default withTranslation(["fortnox"], { withRef: true })(FortnoxContactResourceList);

const Wrapper = styled.div<{ loading?: boolean }>`
	transition: opacity 250ms;

	${({ loading }) =>
		loading &&
		css`
			opacity: 0.75;
		`}

	.Polaris-ResourceList {
		border-top: none;
	}
	.Polaris-ResourceList__FiltersWrapper {
		padding-inline: 0;
	}

	&&&& .Polaris-TextField__Backdrop {
		border-radius: 6px;
	}
`;
