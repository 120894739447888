import React, { Component } from "react";
import { Button, Stack, Labelled, FormLayout, Select, Badge, Checkbox, Icon } from "@shopify/polaris";
import nl2br from "react-nl2br";
import { ChevronDownMinor, LinkMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import ResourcePicker from "../../components/resource_picker.js";
import { PreviewButtonAsLink } from "./components/CalendarModal/CalendarPopupPreview.js";
import Colors from "../../Colors.js";

class CellTimelineIntegrationStuff extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getFilterContacts(contacts) {
		return [contacts].flat(1).filter((c) => c && c.email && !c.is_company);
	}

	fixAttendees(props) {
		const contacts = this.getFilterContacts(props.contact);
		const formatedAttendees = props.value.attendees
			? props.value.attendees.map((a) => {
					if (typeof a === "string") {
						return {
							email: a,
						};
					}
					return a;
			  })
			: [];

		const original = JSON.stringify(formatedAttendees);
		const ar = []
			.concat(
				contacts.map((c) => ({
					email: c.email,
					contact: true,
					removed: formatedAttendees.find((a) => a.email === c.email) && formatedAttendees.find((a) => a.email === c.email).removed,
				})),
				formatedAttendees &&
					formatedAttendees.filter(
						(a) =>
							!a.contact ||
							(a.contact && contacts.find((c) => c.email === a.email) && !formatedAttendees.find((a) => a.email === props.contact.email))
					)
			)
			.filter((i) => i);
		props.value.attendees = ar;
		if (original != JSON.stringify(ar)) {
			props.onChange(props.value);
		}
	}

	componentDidMount() {
		this.fixAttendees(this.props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.fixAttendees(props);
	}

	respondToInvitation(response) {
		this.setState({ saving: true });
		API.put(
			"/" +
				"api" +
				"/boards/" +
				this.props.column.board_id +
				"/rows/" +
				this.props.row.id +
				"/columns/" +
				this.props.column.id +
				"/invitation_response.json",
			{ response },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.props.value.external_event = result.data.value.value.external_event;
				this.setState({ saving: false });
				this.props.onChange(this.props.value);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	inValid(u) {
		if (
			!u ||
			!u.email ||
			!String(u.email.trim())
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		) {
			return this.props.t("cell.timeline_integraiton.errors.invalid_email", "Invalid email");
		}
		return false;
	}

	handleAddAttendee(u) {
		if (u && u.email) u.email.trim();

		if (this.inValid(u)) return;
		if (Array.isArray(this.props.value.attendees)) {
			if (this.props.value.attendees.find((a) => a.email === u.email)) return;
			this.props.value.attendees.push(u);
		} else {
			this.props.value.attendees = [u];
		}

		this.props.onChange(this.props.value);
	}

	handleRemoveAttendee(attendee) {
		const contacts = this.getFilterContacts(this.props.contact);

		if (attendee.contact && contacts.find((c) => c.email === attendee.email)) {
			const ar = this.props.value.attendees.map((at) => {
				if (at.email === attendee.email) {
					at.removed = !at.removed;
				}
				return at;
			});

			this.props.value.attendees = ar;
		} else {
			const ar = this.props.value.attendees.filter((u) => u.email !== attendee.email);
			this.props.value.attendees = ar;
		}
		this.props.onChange(this.props.value);
	}

	getInvitationStatus(email) {
		if (this.props.value.external_event && this.props.value.external_event.attendees && this.props.value.external_event.attendees.length > 0) {
			for (let i = 0; i < this.props.value.external_event.attendees.length; i++) {
				if (this.props.column.options.integration.type == "outlook365") {
					if (
						this.props.value.external_event.attendees[i].emailAddress &&
						this.props.value.external_event.attendees[i].emailAddress.address == email
					) {
						const response = this.props.value.external_event.attendees[i].status.response;
						let status = "";
						let label = "";

						if (response == "accepted") {
							status = "success";
							label = this.props.t("cell.timeine_integration.statuses.accepted", "Godkänd");
						} else if (response == "declined") {
							status = "critical";
							label = this.props.t("cell.timeine_integration.statuses.declined", "Nekad");
						} else if (response == "tentativelyAccepted") {
							status = "attention";
							label = this.props.t("cell.timeine_integration.statuses.tentativelyAccepted", "Kanske");
						} else if (response == "none") {
							status = null;
							label = this.props.t("cell.timeine_integration.statuses.none", "Inget svar");
						} else {
							status = "info";
							label = this.props.t(`cell.timeine_integration.statuses.${response}`, response);
						}
						return <Badge status={status}>{label}</Badge>;
					}
				} else if (this.props.column.options.integration.type == "google") {
					if (this.props.value.external_event.attendees[i].email && this.props.value.external_event.attendees[i].email == email) {
						const response = this.props.value.external_event.attendees[i].responseStatus;
						let status = "";
						let label = "";

						if (response == "accepted") {
							status = "success";
							label = this.props.t("cell.timeine_integration.statuses.accepted", "Godkänd");
						} else if (response == "declined") {
							status = "critical";
							label = this.props.t("cell.timeine_integration.statuses.declined", "Nekad");
						} else if (response == "tentative") {
							status = "attention";
							label = this.props.t("cell.timeine_integration.statuses.tentative", "Kanske");
						} else if (response == "needsAction") {
							status = null;
							label = this.props.t("cell.timeine_integration.statuses.needsAction", "Inget svar");
						} else {
							status = "info";
							label = this.props.t(`cell.timeine_integration.statuses.${response}`, response);
						}
						return <Badge status={status}>{label}</Badge>;
					}
				}
			}
		}
		return null;
	}

	isOrganizer() {
		const integrationOptions = this.props.column.options.integration;
		const externalEvent = this.props.value.external_event;
		if (integrationOptions && externalEvent) {
			if (integrationOptions.type == "outlook365" && externalEvent.responseStatus && externalEvent.responseStatus.response == "organizer") {
				return true;
			} else if (integrationOptions.type == "google" && externalEvent.organizer?.self) {
				return true;
			}
		}
		return false;
	}

	getResponseStatus() {
		const integrationOptions = this.props.column.options.integration;
		const externalEvent = this.props.value.external_event;

		if (integrationOptions) {
			if (integrationOptions.type == "outlook365") {
				return externalEvent && externalEvent.responseStatus && externalEvent.responseStatus.response;
			} else if (integrationOptions.type == "google") {
				const mine = externalEvent && externalEvent.attendees && externalEvent.attendees.find((att) => att?.self);
				if (mine && mine.responseStatus == "needsAction") {
					return "notResponded";
				} else if (mine && mine.responseStatus == "accepted") {
					return "accepted";
				} else if (mine && mine.responseStatus == "tentative") {
					return "tentativelyAccepted";
				} else if (mine && mine.responseStatus == "declined") {
					return "declined";
				}
			}
		}
	}

	render() {
		const contacts = this.getFilterContacts(this.props.contact);
		const externalEvent = this.props.value.external_event;

		return (
			<div>
				<FormLayout>
					<Stack wrap={false}>
						<Stack.Item fill>
							{externalEvent && externalEvent.body ? (
								<div
									style={{
										maxHeight: this.state.showAll ? 100000 : 75,
										position: "relative",
										overflow: "hidden",
										wordBreak: "break-all",
									}}
								>
									{externalEvent.body.contentType == "text" ? (
										<span>{nl2br(externalEvent.body.content)}</span>
									) : (
										// eslint-disable-next-line react/no-danger
										<span
											dangerouslySetInnerHTML={{
												__html: externalEvent.body.content,
											}}
										/>
									)}
									<div
										className="read-more-btn"
										onClick={() => {
											this.setState({ showAll: !this.state.showAll });
										}}
									>
										{this.state.showAll
											? this.props.t("cell.timeline_integration.actions.less", "Visa mindre")
											: this.props.t("cell.timeline_integration.actions.more", "Visa mer")}
									</div>
								</div>
							) : (
								<Labelled label={this.props.t("cell.timeline_integration.fields.content.label", "Beskrivning")}>
									<textarea
										style={{
											fontSize: 16,
											padding: 5,
											display: "block",
											width: "100%",
											border: 0,
										}}
										onChange={(e) => {
											this.props.value.content = e.target.value;
											// eslint-disable-next-line react/no-unused-state
											this.setState({ rnd: 1 });
										}}
										onBlur={() => {
											this.props.onChange(this.props.value);
										}}
										value={this.props.value.content}
									/>
								</Labelled>
							)}
						</Stack.Item>
						<div style={{ minWidth: 100 }}>
							{this.props.column?.options?.integration?.type === "outlook365" && (
								<Select
									label={this.props.t("cell.timeline_integration.fields.sensitivity.label", "Känslighet")}
									options={[
										{
											value: "normal",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.normal", "Normalt"),
										},
										{
											value: "personal",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.personal", "Personligt"),
										},
										{
											value: "private",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.private", "Privat"),
										},
										{
											value: "confidential",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.confidential", "Konfidentiellt"),
										},
									]}
									value={this.props.value.sensitivity}
									onChange={(v) => {
										this.props.value.sensitivity = v;
										this.props.onChange(this.props.value);
									}}
								/>
							)}
							{this.props.column?.options?.integration?.type === "google" && (
								<Select
									label={this.props.t("cell.timeline_integration.fields.sensitivity.label", "Känslighet")}
									options={[
										{
											value: "default",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.normal", "Normalt"),
										},
										{
											value: "public",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.public", "Publik"),
										},
										{
											value: "private",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.private", "Privat"),
										},
										{
											value: "confidential",
											label: this.props.t("cell.timeline_integration.fields.sensitivity.options.confidential", "Konfidentiellt"),
										},
									]}
									value={this.props.value.visibility}
									onChange={(v) => {
										this.props.value.visibility = v;
										this.props.onChange(this.props.value);
									}}
								/>
							)}
						</div>
					</Stack>
					{!externalEvent || this.isOrganizer() ? (
						<div style={{ paddingTop: "1px" }}>
							<FormLayout>
								<ResourcePicker
									label={this.props.t("cell.timeline_integration.fields.invite.label", "Bjud in deltagare")}
									caption={<Icon source={ChevronDownMinor} />}
									showJustContent
									fixedCaption
									resource="users.json?enabled=1"
									resource_handle="users"
									item={null}
									plain
									fullWidth
									id_handle="id"
									label_handle="name"
									resourceName={{
										singular: this.props.t("cell.timeline_integration.terms.contact_singular", "kontaktperson"),
										plural: this.props.t("cell.timeline_integration.terms.contact_plural", "kontaktpersoner"),
									}}
									onChange={(user) => this.handleAddAttendee(user)}
									onSubmit={(e) => {
										this.handleAddAttendee({ email: e });
									}}
									inValid={(value) => this.inValid({ email: value })}
									placeholder={this.props.t("cell.timeline_integration.fields.invite.placeholder", "example@email.com")}
									style={{ padding: 0 }}
									clearSearchResultsOnClick
								/>

								{this.props.value.attendees && !!this.props.value.attendees.length && (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											gap: "10px",
											flexWrap: "wrap",
										}}
									>
										{this.props.value.attendees &&
											this.props.value.attendees.map((u, index) => (
												<Checkbox
													key={u.email || index}
													label={
														<div>
															{u.email} {this.getInvitationStatus(u.email)}
														</div>
													}
													checked={!u.contact || (!u.removed && u.contact && contacts.find((c) => c.email === u.email))}
													onChange={this.handleRemoveAttendee.bind(this, u)}
												/>
											))}
									</div>
								)}
							</FormLayout>
						</div>
					) : this.getResponseStatus() == "notResponded" || this.getResponseStatus() == "tentativelyAccepted" ? (
						<div>
							<Stack>
								<Button loading={this.state.saving} primary onClick={this.respondToInvitation.bind(this, "accept")}>
									{this.props.t("cell.timeline_integration.actions.accept", "Acceptera")}
								</Button>
								<Button loading={this.state.saving} destructive onClick={this.respondToInvitation.bind(this, "decline")}>
									{this.props.t("cell.timeline_integration.actions.decline", "Neka")}
								</Button>
								<Button
									loading={this.state.saving}
									disabled={this.getResponseStatus() == "tentativelyAccepted"}
									onClick={this.respondToInvitation.bind(this, "maybe")}
								>
									{this.props.t("cell.timeline_integration.actions.maybe", "Kanske")}
								</Button>
							</Stack>
						</div>
					) : null}
					{this.props.value && this.props.value.onlineMeeting && this.props.value.onlineMeeting.joinUrl && (
						<a href={this.props.value.onlineMeeting.joinUrl} className="microsoft-teams-link">
							<Icon source={LinkMinor} />
							{this.props.t("cell.timeline_integration.fields.teams_url.text", "Teams mötes länk")}
						</a>
					)}
					{this.props.value?.external_event?.conferenceData?.entryPoints?.length && (
						<div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
							{this.props.value?.external_event?.conferenceData?.entryPoints?.map((entry, index) => {
								return (
									<PreviewButtonAsLink key={index} href={entry.url} target="_blank" style={{ maxWidth: "max-content" }} color={Colors.green}>
										{this.props.t("calendar.fields.goole_hangout.connect", "Anslut till Google möte {{with_type}}", {
											with_type:
												entry.entryPointType === "phone"
													? this.props.t("calendar.fields.goole_hangout.with_phone", "med telefon")
													: entry.entryPointType === "video"
													? this.props.t("calendar.fields.goole_hangout.with_video", "med video")
													: "",
										})}
									</PreviewButtonAsLink>
								);
							})}
						</div>
					)}
					{(!externalEvent || this.isOrganizer()) && this.props.column.options?.integration?.type == "outlook365" && (
						<Select
							label={this.props.t("cell.timeline_integration.fields.onlineMeetingProvider.label", "Möte")}
							options={[
								{
									label: this.props.t("cell.timeline_integration.fields.onlineMeetingProvider.options.unknown", "inget"),
									value: "unknown",
								},
								{
									label: this.props.t("cell.timeline_integration.fields.onlineMeetingProvider.options.teamsForBusiness", "Teams"),
									value: "teamsForBusiness",
								},
							]}
							onChange={(v) => {
								this.props.value.onlineMeetingProvider = v;
								this.props.value.isOnlineMeeting = v == "unknown" ? null : true;
								this.props.onChange(this.props.value);
							}}
							value={this.props.value.onlineMeetingProvider}
						/>
					)}
				</FormLayout>
			</div>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(CellTimelineIntegrationStuff);
