import React, { Component } from "react";
import { SkeletonPage, Card, Stack, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";
import $ from "jquery";

class SkeletonBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const windowWidth = $(window).width() - ($(".Polaris-TopBar").length ? 240 : 0);
		const titleWidth = Math.round(windowWidth / 3) - 60;
		const columns = Math.floor((windowWidth - titleWidth) / 200);

		if (this.props.noheader) {
			return (
				<div>
					<div style={{ marginTop: 40, marginBottom: 60 }}>
						<div style={{ marginBottom: 10 }}>
							<SkeletonDisplayText size="small" />
						</div>
						<Card sectioned>
							<Stack>
								<div style={{ width: titleWidth }}>
									<TextContainer>
										<SkeletonBodyText lines={1} />
										<SkeletonBodyText lines={1} />
									</TextContainer>
								</div>
								<Stack.Item fill>
									<Stack distribution="fillEvenly">
										{[...Array(columns)].map((x, index) => (
											<TextContainer key={x + index}>
												<SkeletonBodyText lines={1} />
												<SkeletonBodyText lines={1} />
											</TextContainer>
										))}
									</Stack>
								</Stack.Item>
							</Stack>
						</Card>
					</div>
					<div style={{ marginBottom: 60 }}>
						<div style={{ marginBottom: 10 }}>
							<SkeletonDisplayText size="small" />
						</div>
						<Card sectioned>
							<Stack>
								<div style={{ width: titleWidth }}>
									<TextContainer>
										<SkeletonBodyText lines={1} />
										<SkeletonBodyText lines={1} />
									</TextContainer>
								</div>
								<Stack.Item fill>
									<Stack distribution="fillEvenly">
										{[...Array(columns)].map((x, index) => (
											<TextContainer key={x + index}>
												<SkeletonBodyText lines={1} />
												<SkeletonBodyText lines={1} />
											</TextContainer>
										))}
									</Stack>
								</Stack.Item>
							</Stack>
						</Card>
					</div>
				</div>
			);
		}
		return (
			<SkeletonPage fullWidth secondaryActions={0} primaryAction>
				<div style={{ marginTop: 50, marginBottom: 40 }}>
					<Stack>
						<Stack.Item fill>
							<SkeletonDisplayText size="medium" />
						</Stack.Item>
						<Stack>
							<div style={{ minWidth: 100 }}>
								<SkeletonDisplayText size="small" />
							</div>
							<div style={{ minWidth: 70 }}>
								<SkeletonDisplayText size="small" />
							</div>
							<div style={{ minWidth: 100 }}>
								<SkeletonDisplayText size="small" />
							</div>
							<div style={{ minWidth: 100 }}>
								<SkeletonDisplayText size="small" />
							</div>
							<div style={{ minWidth: 80 }}>
								<SkeletonDisplayText size="small" />
							</div>
						</Stack>
					</Stack>
				</div>
				<div style={{ marginBottom: 60 }}>
					<div style={{ marginBottom: 10 }}>
						<SkeletonDisplayText size="small" />
					</div>
					<Card sectioned>
						<Stack>
							<div style={{ width: titleWidth }}>
								<TextContainer>
									<SkeletonBodyText lines={1} />
									<SkeletonBodyText lines={1} />
									<SkeletonBodyText lines={1} />
								</TextContainer>
							</div>
							<Stack.Item fill>
								<Stack distribution="fillEvenly">
									{[...Array(columns)].map((x, index) => (
										<TextContainer key={x + index}>
											<SkeletonBodyText lines={1} />
											<SkeletonBodyText lines={1} />
											<SkeletonBodyText lines={1} />
										</TextContainer>
									))}
								</Stack>
							</Stack.Item>
						</Stack>
					</Card>
				</div>
				<div style={{ marginBottom: 60 }}>
					<div style={{ marginBottom: 10 }}>
						<SkeletonDisplayText size="small" />
					</div>
					<Card sectioned>
						<Stack>
							<div style={{ width: titleWidth }}>
								<TextContainer>
									<SkeletonBodyText lines={1} />
									<SkeletonBodyText lines={1} />
								</TextContainer>
							</div>
							<Stack.Item fill>
								<Stack distribution="fillEvenly">
									{[...Array(columns)].map((x, index) => (
										<TextContainer key={x + index}>
											<SkeletonBodyText lines={1} />
											<SkeletonBodyText lines={1} />
										</TextContainer>
									))}
								</Stack>
							</Stack.Item>
						</Stack>
					</Card>
				</div>
			</SkeletonPage>
		);
	}
}

export default SkeletonBoard;
