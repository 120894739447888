import React, { Component } from "react";
import {
	PageActions,
	Pagination,
	Avatar,
	Button,
	ResourceList,
	Card,
	Modal,
	FormLayout,
	TextField,
	Select,
	Stack,
	Spinner,
	Tooltip,
} from "@shopify/polaris";
import axios from "axios";

import { EditMajor, MobileCancelMajor } from "@shopify/polaris-icons";
import moment from "moment";
import debounce from "lodash/debounce";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import BASE_URL from "src/js/API/BASE_URL";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import Skeleton2Col from "../../components/skeleton_2col.js";
import SearchField from "../../components/search_field.js";
import UsagePackageModal from "../../components/UsagePackageModal.js";
import StatsBoxWrapper from "../../components/statsBoxes";
import IconWithBackround from "../../components/IconWithBackround";
import Colors, { colorToRgba } from "../../Colors";
import Banner from "../../components/banner";
import SendIcon from "../../icons/Send";
import ClickIcon from "../../icons/Click";
import CheckmarkWithBottomIcon from "../../icons/CheckmarkWithBottom";
import SmallConversions from "./SmallConversions";
import NumberBadge from "../../components/NumberBadge";
import { MailIcon, SadFaceIcon, ThumbsUpIcon } from "../../icons";

class EmailCampaignEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedItems: [],
			contacts: [],
			id: props.match.params.id,
			loading: true,
			form: null,
			limit: 25,
			offset: 0,
			testForm: {},
			limits: null,
			filter: { status: "" },
		};
		this.fetchAfterSearch = debounce(
			() => {
				this.cancelRequest();
				this.setState({ offset: 0 }, () => this.fetchContacts());
			},
			100,
			{
				wait: 500,
				leading: false,
				trailing: true,
			}
		);
		this.contactStatuses = [
			{
				label: this.props.t("email_campaign.statuses.empty", "Alla Mottagare"),
				value: "",
			},
			{
				label: this.props.t("email_campaign.statuses.clicked", "Klickad"),
				value: "clicked",
				color: Colors.green,
			},
			{
				label: this.props.t("email_campaign.statuses.opened", "Öppnad"),
				value: "opened",
				color: Colors.green,
			},
			{
				label: this.props.t("email_campaign.statuses.delivered", "Mottaget"),
				value: "delivered",
				color: Colors.blue,
			},
			{
				label: this.props.t("email_campaign.statuses.sent", "Skickad"),
				value: "sent",
				color: Colors.yellow2,
			},
			{
				label: this.props.t("email_campaign.statuses.bounced", "Studsad"),
				value: "bounced",
				color: Colors.orange,
			},
			{
				label: this.props.t("email_campaign.statuses.complained", "Anmäld till ISP"),
				value: "complained",
				color: Colors.red,
			},
			{
				label: this.props.t("email_campaign.statuses.unsubscribed", "Avanmäld"),
				value: "unsubscribed",
				color: Colors.red,
			},
			{
				label: this.props.t("email_campaign.statuses.not_sent", "Ej skickad"),
				value: "not_sent",
				color: Colors.red,
			},
		];
	}

	getReceiverStatus(item) {
		const statusFitler = this.state.filter && this.state.filter.status;

		const status = this.contactStatuses.find(
			({ label, value, color } = {}) => item.email && item.email[value] && (!statusFitler || statusFitler === value)
		);
		const tooltip = item?.email_validation?.status_description;
		if (status) {
			return { color: status.color, text: status.label, tooltip };
		}

		const itemStatus = this.contactStatuses.find(({ label, value, color } = {}) => item.email && item.email[value]);
		if (!itemStatus)
			return {
				color: Colors.red,
				text: this.props.t("email_campaign.statuses.not_sent", "Ej skickad"),
				tooltip,
			};
		return {};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	fetchLimits() {
		// this.setState({ fetchingLimits: true });
		API.get("/api/account/limits.json", {})
			.then((result) => {
				// this.setState({ fetchingLimits: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ limits: result.data.limits });
			})
			.catch((error) => {
				// this.setState({ fetchingLimits: false });
				toastr.error(error);
			});
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		this.fetchLimits();
	}

	fetchItem(id) {
		this.setState({ id, loading: true, import_limit: false });
		API.get("/api/email_campaigns/" + id + ".json")
			.then((result) => {
				this.setState(
					{
						loading: false,
						form: result.data.email_campaign,
						testForm: {
							data:
								result.data.email_campaign &&
								result.data.email_campaign.test_metadata &&
								JSON.stringify(result.data.email_campaign.test_metadata, undefined, 2),
						},
					},
					() => {
						this.fetchStats();
						this.fetchContacts();
					}
				);

				// this.doSearch(this.state.offset);

				API.get("/api/audiences.json", {
					params: {
						ids: result.data.email_campaign && result.data.email_campaign.audiences && result.data.email_campaign.audiences.map((aud) => aud.id),
					},
				})
					.then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
							return;
						}

						this.state.form.audiences.forEach((aud) => {
							const found = result.data.audiences.find((a) => a.id == aud.id);
							aud.count = found.count;
							aud.imported_count = found.imported_count;
							aud.not_imported_count = found.not_imported_count;
						});

						this.setState({ form: this.state.form });
					})
					.catch((error) => toastr.error(error));
			})
			.catch((error) => toastr.error(error));
	}

	fetchStats(silent) {
		if (!silent) this.setState({ loadingStats: true });

		API.get(`/api/email_campaigns/${this.state.id}/stats.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loadingStats: false });
					return;
				}

				this.setState({ loadingStats: false, stats: result.data.stats });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchContacts(silent = false) {
		if (!silent) this.setState({ loadingContacts: true });
		const CancelToken = axios.CancelToken;

		const params = { limit: this.state.limit, offset: this.state.offset };

		if (this.state.filter && this.state.filter.search) params.q = this.state.filter.search;
		if (this.state.filter && this.state.filter.status) params.status = this.state.filter.status;

		API.get(`/api/email_campaigns/${this.state.id}/contacts.json`, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loadingContacts: false });
					return;
				}

				this.setState({
					loadingContacts: false,
					contacts:
						result.data.email_campaign_contacts.map((c) => {
							const contactId = (c.contact && c.contact.id) || c.id;
							c.path = "/admin/contacts/" + contactId + "?email_campaign_id=" + this.state.id;
							return c;
						}) || [],
					count: result.data.count,
				});
			})
			.catch((error) => {
				if (!axios.isCancel(error)) {
					toastr.error(error);
				}
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	updateTestForm(field, value) {
		const formState = this.state.testForm;
		formState[field] = value;
		this.setState({ testForm: formState });
	}

	send() {
		this.setState({ saving: true });
		API.post("/api/email_campaigns/" + this.state.id + "/send.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({
						saving: false,
						import_limit: result.data.import_limit,
					});
					return;
				}
				this.setState({ saving: false, form: result.data.email_campaign });
				this.fetchContacts();
				toastr.success(this.props.t("email_campaign.reponses.marked_sent", "E-postkampanj är nu markerad som skickad"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	onLoadIframe() {
		document.getElementById("preview-iframe").style.height =
			document.getElementById("preview-iframe").contentWindow.document.body.scrollHeight + "px";
	}

	editTemplate(e) {
		e.preventDefault();
		let stacks = [];
		if (this.props.location.state && this.props.location.state.stacks) {
			stacks = this.props.location.state.stacks;
		}
		stacks.push({
			parent_path: this.props.location.pathname + this.props.location.search,
			parent_title: `${this.props.t("email_campaign.singular", "E-postkampanj")}  - ${this.state.form.title}`,
			item: {
				id: this.state.form.template.id,
				item: this.state.form.template,
				path: "/admin/email_templates/" + this.state.form.template.id,
			},
			items: [this.state.form.template],
		});
		this.props.history.push("/admin/email_templates/" + this.state.form.template.id, { stacks, email_campaign: this.state.form });
	}

	gotoContact(item) {
		let stacks = [];
		if (this.props.location.state && this.props.location.state.stacks) {
			stacks = this.props.location.state.stacks;
		}
		const contactId = (item.contact && item.contact.id) || item.id;
		item.path = "/admin/contacts/" + contactId + "?email_campaign_id=" + this.state.id;
		stacks.push({
			parent_path: this.props.location.pathname + this.props.location.search,
			parent_title: `${this.props.t("email_campaign.singular", "E-postkampanj")}  - ${this.state.form.title}`,
			item: { id: contactId, item, path: "/admin/contacts/" + contactId },
			items: this.state.contacts,
		});
		this.props.history.push("/admin/contacts/" + contactId + "?email_campaign_id=" + this.state.id, { stacks });
	}

	// addContact(contact) {
	// 	this.setState({ saving: true });
	// 	API.post("/api/email_campaigns/" + this.state.id + "/contacts.json", {
	// 		contact_id: contact.id,
	// 	})
	// 		.then((result) => {
	// 			this.state.form.contact_ids.push(contact.id);
	// 			this.setState({ saving: false, form: this.state.form });
	// 			this.fetchContacts();
	// 			toastr.success(this.props.t("email_campaign.responses.contact.added", "Kontakt tillagd"));
	// 		})
	// 		.catch((error) => {
	// 			this.setState({ saving: false });
	// 			toastr.error(error);
	// 		});
	// }

	removeEmailCampaign() {
		this.setState({ saving: true });
		API.delete("/api/email_campaigns/" + this.state.id + ".json")
			.then((result) => {
				this.props.history.goBack();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	// removeContact(contact) {
	// 	this.setState({ saving: true });
	// 	API.delete("/api/email_campaigns/" + this.state.id + "/contacts/" + contact.id + ".json")
	// 		.then((result) => {
	// 			const contactIds = [];
	// 			for (let i = 0; i < this.state.form.contact_ids.length; i++) {
	// 				if (this.state.form.contact_ids[i] != contact.id) {
	// 					contactIds.push(this.state.form.contact_ids[i]);
	// 				}
	// 			}
	// 			this.state.form.contact_ids = contactIds;
	// 			this.setState({ saving: false, form: this.state.form });
	// 			this.fetchContacts();
	// 			toastr.success(this.props.t("email_campaign.responses.contact.removed", "Kontakt borttagen"));
	// 		})
	// 		.catch((error) => {
	// 			this.setState({ saving: false });
	// 			toastr.error(error);
	// 		});
	// }

	// toggleContact(contact) {
	// 	if (this.state.form.contact_ids.indexOf(contact.id) >= 0) {
	// 		this.removeContact(contact);
	// 	} else {
	// 		this.addContact(contact);
	// 	}
	// }

	renderItem(item) {
		const media = <Avatar customer size="medium" name={item.name} source={item.email ? item.avatar : null} />;

		const shortcutActions = [];
		const { color, text, tooltip } = this.getReceiverStatus(item);

		const email = (item.email && (item.email.email || item.email.to_mail || item.email.mailbox)) || (item.contact && item.contact.email);

		const statusElement = (
			<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
				<svg fill={color} style={{ width: "8px", height: "8px" }} viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
					<circle cx="5" cy="5" r="5" />
				</svg>
				<span style={{ fontSize: "0.9em" }}>{text}</span>
			</div>
		);
		return (
			<ResourceList.Item id={item.id} media={media} onClick={this.gotoContact.bind(this, item)} shortcutActions={shortcutActions}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						transition: "opacity 250ms",
						opacity: this.state.loadingContacts ? 0.75 : 1,
					}}
				>
					<div>
						<div>{email || "-"}</div>
						{tooltip ? <Tooltip content={tooltip}>{statusElement}</Tooltip> : statusElement}
					</div>
					<span>{moment(item.updated_at).format("MMM D, YYYY")}</span>
				</div>
			</ResourceList.Item>
		);
	}

	// doSearch(offset) {
	// 	const CancelToken = axios.CancelToken;
	// 	this.setState({ loadingList: true });
	// 	axios
	// 		.get("/api/contacts.json", {
	// 			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
	// 			params: {
	// 				email_campaign_id: this.state.id,
	// 				limit: this.state.limit,
	// 				offset,
	// 			},
	// 		})
	// 		.then((result) => {
	// 			for (let i = 0; i < result.data.contacts.length; i++) {
	// 				result.data.contacts[i].path = "/admin/contacts/" + result.data.contacts[i].id + "?email_campaign_id=" + this.state.id;
	// 			}
	// 			this.setState({
	// 				// count: result.data.count,
	// 				loadingList: false,
	// 				items: result.data.contacts,
	// 				cancelToken: null,
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			if (axios.isCancel(error)) {
	// 				console.debug("Request canceled");
	// 			} else {
	// 				toastr.error(error);
	// 			}
	// 		});
	// }

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	goPrevious() {
		const currentIndex = this.getCurrentPaginationIndex();
		const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
		this.props.history.push(lastStack.items[currentIndex - 1].path, this.props.location.state);
	}

	goNext() {
		const currentIndex = this.getCurrentPaginationIndex();
		const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
		this.props.history.push(lastStack.items[currentIndex + 1].path, this.props.location.state);
	}

	getCurrentPaginationIndex() {
		if (
			this.props.location.state.stacks &&
			this.props.location.state.stacks.length &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items.length > 1
		) {
			const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
			for (let i = 0; i < lastStack.items.length; i++) {
				if (lastStack.items[i].path == this.props.location.pathname + this.props.location.search + "") {
					return i;
				}
			}
		}
		return null;
	}

	goContactPrevious() {
		if (this.state.offset > 0) {
			const offset = this.state.offset - this.state.limit;
			this.setState({ offset }, () => this.fetchContacts());
		}
	}

	goContactNext() {
		if (this.state.offset + this.state.limit < this.state.count) {
			const offset = this.state.offset + this.state.limit;
			this.setState({ offset }, () => this.fetchContacts());
		}
	}

	onCloseTestModal() {
		this.setState({ openTestModal: false });
	}

	sendTestEmail() {
		const form = Object.assign({}, this.state.testForm);
		const data = typeof form.data === "string" ? JSON.parse(form.data) : form.data;
		const testFormState = Object.assign(form, { data });

		this.setState({ sending: true });
		API.post(`/api/email_campaigns/${this.state.id}/test.json`, testFormState, {
			params: {},
		})
			.then((res) => {
				if (res.data.error) {
					console.error("error:", res.data.error);
					toastr.error(res.data.error);
				}
				if (res.data.email && res.data.email.sent) {
					toastr.success(this.props.t("email_campaign.text.test_email_sent", "Test email skickat till {{to}}"), { to: res.data.email.to });
					this.setState({ sending: false, openTestModal: false });
				} else {
					this.setState({ sending: false });
				}
			})
			.catch((er) => {
				toastr.error(er);
				this.setState({ sending: false });
			});
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/email_campaigns/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("email_campaign.responses.updated", "E-postkampanj uppdaterades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	formatDataToJson() {
		try {
			const formState = this.state.testForm;
			const pretty = JSON.stringify(JSON.parse(formState.data), undefined, 2);
			formState.data = pretty;
			this.setState({ testForm: formState });
		} catch (error) {
			console.error("error:", error);
		}
	}

	dupeCampaign() {
		const cop = Object.assign({}, this.state.form);
		cop.id = null;
		cop.sent = false;
		cop.title = this.props.t("email_campaign.text.copy_of", "Kopia av") + " " + cop.title;
		cop.audiences = [];
		this.props.history.push("/admin/email_campaigns/new/template" + (this.state.form.template ? "/" + this.state.form.template.id : ""), {
			email_campaign: cop,
		});
	}

	onFilterStatusChange(v) {
		this.setState(
			(c) => {
				c.filter.status = v;
				return { filter: c.filter, offset: 0 };
			},
			() => this.fetchContacts()
		);
	}

	render() {
		if (this.state.loading) {
			return <Skeleton2Col />;
		}

		let pagination = null;

		if (
			this.props.location.state &&
			this.props.location.state.stacks &&
			this.props.location.state.stacks.length &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items.length > 1
		) {
			const currentStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
			const currentIndex = this.getCurrentPaginationIndex();
			const hasPrevious = currentIndex > 0;
			const hasNext = currentIndex < currentStack.items.length - 1;
			pagination = {
				hasPrevious,
				onPrevious: this.goPrevious.bind(this),
				hasNext,
				onNext: this.goNext.bind(this),
			};
		}

		const hasContactPrevious = this.state.offset > 0;
		const hasContactNext = this.state.offset + this.state.limit < this.state.count;

		const getBoxTitle = (key) => {
			if (this.state.loadingStats) return <Spinner size="small" />;
			if (this.state.stats && key in this.state.stats) {
				return `${this.state.stats[key]} st`;
			}
			return "-";
		};

		const statusFilter = this.contactStatuses.find((s) => s.value == (this.state.filter && this.state.filter.status));

		return (
			<Page
				title={this.state.form.title}
				breadcrumbs={[
					{
						content: this.props.t("common.actions.back", "Tillbaka"),
						onAction: () => this.props.history.goBack(),
					},
				]}
				primaryAction={{
					content: "Spara",
					disabled: !!this.state.form.sent,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.duplicate", "Duplicera"),
						onAction: this.dupeCampaign.bind(this),
					},
				]}
				pagination={pagination}
			>
				<div className="email-campaign">
					{this.state.form.sent && (
						<div style={{ marginBottom: 20 }}>
							<Banner
								style={{ margin: "1rem 0" }}
								dismissible
								status="success"
								title={this.props.t("email_campaign.text.sent", "Detta mailet är skickat")}
							>
								<p>{this.props.t("email_campaign.error.sent", "Det går inte längre att ändra namn eller ta bort mottagare")}</p>
							</Banner>
						</div>
					)}
					<StatsBoxWrapper
						// loading={this.state.loadingStats}
						perRow={this.state.form.type == "newsletter" ? 6 : 4}
						// loadingNrOfBoxes={4}
						items={[
							{
								title: getBoxTitle("sent"),
								text: this.props.t("email_campaign.stats.sent.title", "Skickade"),
								icon: <IconWithBackround icon={<SendIcon color={Colors.yellow2} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
							},
							{
								title: getBoxTitle("delivered"),
								text: this.props.t("email_campaign.stats.delivered.title", "Levererade"),
								icon: <IconWithBackround icon={<CheckmarkWithBottomIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
								link: {
									content: this.props.t("email_campaign.stats.delivered.action", "Skapa målgrupp"),
									onAction: () => {
										this.props.history.push("/admin/audiences/new", {
											filters: [
												{
													key: "keywords",
													value: "em_" + this.state.id + "_delivered",
												},
											],
										});
									},
								},
							},
							{
								title: getBoxTitle("opened"),
								text: this.props.t("email_campaign.stats.opened.title", "Öppnade"),
								icon: <IconWithBackround icon={<MailIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
								link: {
									content: "Skapa målgrupp",
									onAction: () => {
										this.props.history.push("/admin/audiences/new", {
											filters: [
												{
													key: "keywords",
													value: "em_" + this.state.id + "_opened",
												},
											],
										});
									},
								},
							},
							{
								title: getBoxTitle("clicked"),
								text: this.props.t("email_campaign.stats.clicked.title", "Klick"),
								icon: <IconWithBackround icon={<ClickIcon color={Colors.purple} />} backgroundColor={colorToRgba(Colors.purple, 0.2)} />,
								link: {
									content: this.props.t("email_campaign.stats.clicked.action", "Skapa målgrupp"),
									onAction: () => {
										this.props.history.push("/admin/audiences/new", {
											filters: [
												{
													key: "keywords",
													value: "em_" + this.state.id + "_clicked",
												},
											],
										});
									},
								},
							},
						].concat(
							this.state.form.type == "newsletter"
								? [
										{
											title: getBoxTitle("conversions"),
											text: this.props.t("email_campaign.stats.conversions.title", "Konverteringar"),
											icon: <IconWithBackround icon={<ThumbsUpIcon color={Colors.pink} />} backgroundColor={colorToRgba(Colors.pink, 0.2)} />,
											link: {
												content: this.props.t("email_campaign.stats.conversions.action", "Skapa målgrupp"),
												onAction: () => {
													this.props.history.push("/admin/audiences/new", {
														filters: [
															{
																key: "keywords",
																value: "em_" + this.state.id + "_converted",
															},
														],
													});
												},
											},
										},
										{
											title: getBoxTitle("unsubscribed"),
											text: this.props.t("email_campaign.stats.unsubscribed.title", "Avanmälningar"),
											icon: <IconWithBackround icon={<SadFaceIcon color={Colors.red} />} backgroundColor={colorToRgba(Colors.red, 0.2)} />,
											link: {
												content: this.props.t("email_campaign.stats.unsubscribed.action", "Skapa målgrupp"),
												onAction: () => {
													this.props.history.push("/admin/audiences/new", {
														filters: [
															{
																key: "keywords",
																value: "em_" + this.state.id + "_unsubscribed",
															},
														],
													});
												},
											},
										},
								  ]
								: []
						)}
					/>
					<Stack distribution="fillEvenly">
						<div>
							<Card title={this.props.t("email_campaign.title_short", "Kampanj")} sectioned>
								<FormLayout>
									<TextField
										label={this.props.t("email_campaign.fields.title.label", "Namn på kampanj")}
										value={this.state.form.title}
										onChange={this.updateForm.bind(this, "title")}
									/>
									<Select
										label={this.props.t("email_campaign.fields.type.label", "Typ av kampanj")}
										options={[
											{
												label: this.props.t("email_campaign.type.options.newsletter.label", "Nyhetsbrev"),
												value: "newsletter",
											},
											{
												label: this.props.t("email_campaign.type.options.transactional.label", "Transaktionsmail"),
												value: "transactional",
											},
										]}
										disabled
										value={this.state.form.type}
										onChange={this.updateForm.bind(this, "type")}
									/>
								</FormLayout>
							</Card>
							{this.state.form.type == "newsletter" ? (
								<Card title="Målgrupper">
									<Card.Section>
										<SearchField
											placeholder={this.props.t("email_campaign.audiences.search.placeholder", "Sök efter målgrupper att lägga till")}
											resourceName={{
												singular: this.props.t("email_campaign.audiences.singular", "målgrupp"),
												plural: this.props.t("email_campaign.audiences.plural", "målgrupper"),
											}}
											disabled={this.state.form.sent}
											resource="audiences.json"
											label_handle="title"
											resource_handle="audiences"
											renderLabel={(o) => o.title + " (" + o.count + ")"}
											onSelect={(aud) => {
												this.state.form.audiences.push(aud);
												this.setState({ form: this.state.form });
											}}
										/>
									</Card.Section>
									{this.state.form.audiences.map((aud) => (
										<Card.Section key={aud.id}>
											<div className="email-audience">
												<div style={{ display: "flex", gap: "2rem" }}>
													{aud.title}
													<NumberBadge color={Colors.green}>
														{this.props.t("email_campaign.text.count", "Antal")}: {aud.count}
													</NumberBadge>
													{/* <NumberBadge color={Colors.green}>
														{this.props.t("email_campaign.text.imported", "Importerade")}: {aud.imported_count}
													</NumberBadge> */}
													{/* <NumberBadge color={Colors.yellow}>
														{this.props.t("email_campaign.text.not_imported", "Ej importerade")}: {aud.not_imported_count}
													</NumberBadge> */}
												</div>
												{!this.state.form.sent ? (
													<Button
														onClick={() => {
															this.state.form.audiences = this.state.form.audiences.filter((i) => i.id !== aud.id);
															this.setState({ form: this.state.form });
														}}
														icon={MobileCancelMajor}
														destructive
													/>
												) : null}
											</div>
										</Card.Section>
									))}
								</Card>
							) : null}
						</div>
						<Card
							title={this.props.t("email_campaign.mail_design.title", "Maildesign")}
							sectioned
							actions={
								this.state.form.sent && this.state.form.type == "newsletter"
									? []
									: [
											{
												icon: EditMajor,
												content: this.props.t("email_campaign.actions.mail.change", "Ändra mail"),
												onAction: this.editTemplate.bind(this),
											},
									  ]
							}
						>
							<Stack>
								<Stack.Item fill>
									<div
										style={{
											display: "flex",
											height: "100%",
											flexDirection: "column",
											justifyContent: "space-between",
										}}
									>
										<TextField
											label={this.props.t("email_campaign.fields.subject.label", "Rubrik")}
											disabled={this.state.form.sent && this.state.form.type == "newsletter"}
											value={this.state.form.subject}
											onChange={this.updateForm.bind(this, "subject")}
										/>
										<div style={{ display: "flex", gap: "2rem" }}>
											{this.state.form.type == "newsletter" ? (
												<Button
													primary
													disabled={!!this.state.form.sent || !this.state.form?.audiences?.length}
													loading={this.state.saving}
													onClick={this.send.bind(this)}
												>
													{this.props.t("email_campaign.mail.actions.send", "Skicka mail")}
												</Button>
											) : null}
											<Button
												onClick={() => {
													this.updateTestForm("email", store.getState().user.email);
													this.setState({ openTestModal: true });
												}}
											>
												{this.props.t("email_campaign.mail.actions.send_test", "Skicka testmail")}
											</Button>
										</div>
									</div>

									{this.state.import_limit ? (
										<Banner
											title={this.props.t("email_camapign.errors.limit.title", "Går ej att skicka")}
											actions={[
												{
													content: this.props.t("email_campaign.actions.upgrade", "Uppgradera paket"),
													onAction: () => {
														this.setState({
															showPackageModal: true,
															type: "emails",
														});
													},
												},
											]}
											status="warning"
										>
											<p>
												{this.props.t("email_camapign.errors.limit.text", "	Mailet skickades ej, ert konto stödjer högst {{limit}} e-postkontakter", {
													limit: this.state.limits?.emails_limit || "-",
												})}
											</p>
										</Banner>
									) : null}
								</Stack.Item>
								<div>
									{this.state.form.template ? (
										<div
											style={{
												position: "relative",
												backgroundColor: "#fff",
												height: 280.538,
												width: 252.48,
												transform: "translate3d(0, 0, 0)",
												borderRadius: 4,
												border: "1px solid #000",
												overflow: "hidden",
											}}
										>
											<iframe
												title={`${this.state.form.template.id}-template_preview`}
												id="preview-iframe"
												src={`${BASE_URL}/api/email_templates/${this.state.form.template.id}/preview.html`}
												onLoad={this.onLoadIframe.bind(this)}
												// allowtransparency="true"
												frameBorder="0"
												scrolling="no"
												width="660"
												height="733.3333333333334"
												style={{
													transform: "scale(0.382552)",
													transformOrigin: "0px 0px",
													position: "absolute",
													left: 0,
													top: 0,
												}}
											/>
											<a
												onClick={this.editTemplate.bind(this)}
												style={{
													position: "absolute",
													top: 0,
													left: 0,
													bottom: 0,
													right: 0,
													cursor: "pointer",
													display: "block",
												}}
											/>
										</div>
									) : null}
								</div>
							</Stack>
						</Card>
					</Stack>
					<Stack distribution="fillEvenly">
						{/* {this.state.form.sent && this.state.form.type == "newsletter" ? ( */}
						{this.state.form.sent ? (
							<div style={{ marginTop: "1.5rem" }}>
								<Card
									title={
										<div className="email-campaign__contacts">
											<span>{(statusFilter && statusFilter.label) || this.contactStatuses[0]} </span>
											{this.state.loadingContacts ? <Spinner size="small" /> : this.state.count && <span>({this.state.count})</span>}
										</div>
									}
								>
									<Card.Section>
										<div className="email-campaign__contacts__filter">
											<TextField
												value={this.state.filter.search}
												onChange={(v) => {
													this.setState((c) => {
														c.filter.search = v;
														return { filter: c.filter };
													}, this.fetchAfterSearch.bind(this));
												}}
												placeholder={this.props.t("common.placeholders.search_here", "Sök här..")}
											/>

											<Select options={this.contactStatuses} value={this.state.filter.status} onChange={this.onFilterStatusChange.bind(this)} />
										</div>
										<ResourceList
											selectedItems={this.state.selectedItems}
											onSelectionChange={(selectedItems) => {
												this.setState({ selectedItems });
											}}
											hasMoreItems={store.getState().user.roles.indexOf("ROLE_SUPER_ADMIN") >= 0 && this.state.count > this.state.limit}
											showHeader
											loading={this.state.loadingContacts || this.state.saving}
											resourceName={{
												singular: this.props.t("email_campaign.contact.singular", "kontakt"),
												plural: this.props.t("email_campaign.contact.plural", "kontakter"),
											}}
											items={this.state.contacts}
											renderItem={this.renderItem.bind(this)}
										/>
									</Card.Section>
									<Card.Section>
										<div style={{ textAlign: "center" }}>
											<Pagination
												hasPrevious={hasContactPrevious}
												onPrevious={this.goContactPrevious.bind(this)}
												hasNext={hasContactNext}
												onNext={this.goContactNext.bind(this)}
											/>
										</div>
									</Card.Section>
								</Card>
							</div>
						) : null}
						<Stack distribution="fillEvenly">
							{this.state.form.sent && this.state.form.type == "newsletter" && (
								<SmallConversions campaign_id={this.state.form && this.state.form.id} />
							)}
						</Stack>
					</Stack>

					<Modal
						large
						size="large"
						open={this.state.openTestModal}
						onClose={this.onCloseTestModal.bind(this)}
						title={this.props.t("email_campaign.actions.send_a_test", "Skicka ett testmail")}
						secondaryActions={[
							{
								content: this.props.t("common.actions.close", "Stäng"),
								onAction: this.onCloseTestModal.bind(this),
							},
						]}
						primaryAction={{
							content: this.props.t("common.actions.send", "Skicka"),
							loading: this.state.sending,
							onAction: this.sendTestEmail.bind(this),
							disabled: this.state.sending || !this.state.testForm.email,
						}}
					>
						<Modal.Section>
							<FormLayout>
								<TextField
									label={this.props.t("email_campaign.fields.receiver.epost", "Mottagerens e-post")}
									value={this.state.testForm.email}
									onChange={this.updateTestForm.bind(this, "email")}
								/>
								{this.state.form.type == "transactional" ? (
									<TextField
										label={this.props.t("email_campaign.text.data_object", "Data object")}
										multiline
										value={this.state.testForm.data}
										onChange={this.updateTestForm.bind(this, "data")}
										onBlur={this.formatDataToJson.bind(this)}
									/>
								) : null}
							</FormLayout>
						</Modal.Section>
					</Modal>
					<PageActions
						secondaryActions={[
							{
								content: this.props.t("common.actions.remove", "Ta bort"),
								destructive: true,
								loading: this.state.saving,
								onAction: this.removeEmailCampaign.bind(this),
							},
						]}
					/>
					<UsagePackageModal
						open={this.state.showPackageModal}
						onClose={() => {
							this.setState({ showPackageModal: false });
						}}
						type={this.state.type}
						limits={this.state.limits}
					/>
				</div>
			</Page>
		);
	}
}
export default withTranslation(["email_campaign", "common"], { withRef: true })(EmailCampaignEdit);
