import moment from "moment";

const getMutableOffer = (offer: FortnoxOfferType | null): Partial<FortnoxOfferType> => {
	const ignoredKeys: string[] = [
		"BasisTaxReduction",
		"TotalToPay",

		"DeliveryState",
		"DeliveryZipCode",
		"DeliveryName",
		"DeliveryDate",
		"DeliveryCountry",
		"DeliveryCity",
		"DeliveryAddress1",
		"DeliveryAddress2",
	];

	return Object.entries(offer || {}).reduce(
		(acc, [key, value]) => {
			if (ignoredKeys.includes(key)) {
				return acc;
			}

			if (key === "OfferRows") {
				acc[key] = getMutableOfferRows(value as (FortnoxOfferRowType | Partial<FortnoxOfferRowType>)[]);
				return acc;
			}

			acc[key] = value;
			return acc;
		},
		{
			OfferRows: [],
			DeliveryDate: offer?.DeliveryDate,
			OfferDate: offer?.OfferDate || moment().format("YYYY-MM-DD"),
			ExpireDate: offer?.ExpireDate || moment().add(30, "days").format("YYYY-MM-DD"),
		} as Partial<FortnoxOfferType>
	);
};
export default getMutableOffer;

const getMutableOfferRows = (offerRows: (FortnoxOfferRowType | Partial<FortnoxOfferRowType>)[]): Partial<FortnoxOfferRowType>[] => {
	const ignoredKeys = ["Total"];

	return (
		(offerRows.map((offerRow) =>
			Object.keys(offerRow || {})
				.filter((key) => !ignoredKeys.includes(key))
				.reduce((acc, key) => {
					acc[key] = offerRow?.[key];
					return acc;
				}, {} as Partial<FortnoxOfferRowType>)
		) as Partial<FortnoxOfferRowType>[]) || []
	);
};
