import React, { Component } from "react";
import { Card, Stack, Icon } from "@shopify/polaris";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import getRoutes from "../../routes.js";

class SettingsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Page title={this.props.t("settings.title", "Inställningar")}>
				<Card sectioned>
					<Stack distribution="fillEvenly">
						{getRoutes().map((prop, key) => {
							if (!prop.settings || !prop.description || ("admin" in prop && !prop.admin) || ("enabled" in prop && !prop.enabled)) {
								return null;
							}
							return (
								<NavLink to={prop.path} key={key} className="settings-item">
									<div className="icon-wrapper">
										<Icon source={prop.icon} />
									</div>
									<div>
										<p>{prop.name}</p>
										<span>{prop.description}</span>
									</div>
								</NavLink>
							);
						})}
					</Stack>
				</Card>
			</Page>
		);
	}
}

export default withTranslation(["settings", "common"], { withRef: true })(SettingsIndex);
