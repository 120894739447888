import React, { useCallback, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import UploadModal from "src/js/components/UploadModal";
import useQuery from "src/js/hooks/useQuery";

type ScriveDocumentContextValue = {
	handleRemoveDocument: () => any;
	handleDeleteDocument: () => any;
	handleCancelDocument: () => any;
	handleRemindDocument: () => any;
	triggerCallback: () => any;
	handleSave: (document: Partial<ScriveDocumentType>, send?: boolean) => any;
	form: ScriveDocumentType | null;
	isFetching: boolean;
	t: any;
	id: string;
	isSaving: boolean;
	setIsSaving: (saving: boolean) => void;
	viewOnly: boolean;
	handleOpenAppendPdfModal: () => any;
};

const ScriveDocumentContext = React.createContext({} as ScriveDocumentContextValue);

export const ScriveDocumentContextProvider = withTranslation(["scrive", "common"])(({ id: propsId, match, history, t, children }) => {
	const id = propsId || match.params.id;
	const [isSaving, setIsSaving] = useState(false);
	const [appendPdfModalIsOpen, setAppendPdfModalIsOpen] = useState(false);

	const fetch = useCallback(async () => {
		try {
			const res = await API.get(`/api/scrive/documents/${id}.json`);
			return res.data.document;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);

			return null;
		}
	}, [id]);
	const queryKeys = [id && `scrive_document-${id}`].filter(Boolean);
	const { data: form = null, isFetching }: UseQueryResult<ScriveDocumentType | null> = useQuery({
		queryKey: queryKeys,
		queryFn: fetch,
		refetchOnWindowFocus: false,
		initialData: history.location.state?.document || null,
	});

	const queryClient = useQueryClient();
	const saveFunction = useCallback(
		async ({ document, send = false }) => {
			const documentId = document.id || id;

			if (!documentId) return;

			const res = await API.put(`/api/scrive/documents/${documentId}.json`, {
				document,
				send: send === true,
			});

			const successMessage =
				send === true
					? t("scrive.responses.document_sent", "Dokumentet skickat för signering.")
					: t("scrive.responses.document_saved", "Dokumentet har uppdaterats.");

			toastr.success(successMessage);

			return res;
		},
		[id, t]
	);

	const updateDocument = async ({ send = false, document }) => {
		const response = await saveFunction({ document, send });
		return response?.data?.document;
	};

	const mutation = useMutation(updateDocument, {
		onSuccess: (data) => {
			queryClient.setQueryData(queryKeys, data);
		},
	});
	const handleSave = useCallback(
		async (document: Partial<ScriveDocumentType>, send = false) => {
			try {
				document = document as ScriveDocumentType;

				if (!document) {
					toastr.error("no dokument");
					return;
				}
				if (send && form?.status !== "preparation") {
					throw new Error("Dokumentet kan inte skickas i detta läge");
				}

				if (form?.status !== "preparation") {
					throw new Error("Dokumentet kan inte sparas i detta läge");
				}

				setIsSaving(true);
				return await mutation.mutateAsync({ send, document });
			} catch (error) {
				toastr.error(error);
			} finally {
				setIsSaving(false);
			}
		},
		[mutation, form]
	);

	const goBack = useCallback(() => {
		// check if we can go back
		if (history.length > 1) {
			history.goBack();
		} else {
			history.push("/admin/scrive/documents");
		}
	}, [history]);

	const handleRemoveDocument = useCallback(async () => {
		try {
			const res = await API.post(`/api/scrive/documents/${id}/trash.json`);
			toastr.success(t("scrive.responses.document_remove", "Dokumentet har lagts i papperskorgen"));
			goBack();
			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);
		}
	}, [id, t, goBack]);

	const handleDeleteDocument = useCallback(async () => {
		try {
			const res = await API.post(`/api/scrive/documents/${id}/trash_delete.json`);
			toastr.success(t("scrive.responses.document_deleted", "Dokumentet har tagits bort"));
			goBack();
			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);
		}
	}, [id, t, goBack]);

	const handleCancelDocument = useCallback(async () => {
		try {
			const res = await API.post(`/api/scrive/documents/${id}/cancel.json`);
			toastr.success(t("scrive.responses.document_canceled", "Dokumentet har avbrutits"));
			goBack();
			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);
		}
	}, [id, t, goBack]);

	// const handleRestartDocument = useCallback(async () => {
	// 	try {
	// 		const res = await API.post(`/api/scrive/documents/${id}/restart.json`);
	// 		toastr.success(t("scrive.responses.document_restarted", "Dokumentet har startats om"));
	// return resizeBy;
	// 	} catch (error) {
	// 		toastr.error(error);
	// 		console.error("error:", error);
	// 	}
	// }, [id, t]);

	const handleRemindDocument = useCallback(async () => {
		try {
			const res = await API.post(`/api/scrive/documents/${id}/remind.json`);
			toastr.success(t("scrive.responses.document_reminded", "Skickar påminese till signerare som ej signerat."));
			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);
		}
	}, [id, t]);

	const triggerCallback = useCallback(async () => {
		try {
			const res = await API.post(`/api/scrive/documents/${id}/callback.json`);
			toastr.success(t("scrive.responses.callback_triggerd", "Dokumentet's callback har triggats."));
			return res;
		} catch (error) {
			toastr.error(error);
			console.error("error:", error);
		}
	}, [id, t]);

	const handleOpenAppendPdfModal = useCallback(async () => {
		setAppendPdfModalIsOpen(true);
	}, []);

	const handlAppendPdf = useCallback(
		async (upload) => {
			try {
				const res = await API.put(`/api/scrive/documents/${id}/appendfile.json`, {
					upload_id: upload.id,
				});
				toastr.success(t("scrive.responses.upload_appended", "Dokumentet har uppdaterats med ny fil"));
				return res?.data?.document;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);
			}
		},
		[id, t]
	);

	// const handleRestartDocument = useCallback(async () => {
	// 	try {
	// 		await API.post(`/api/scrive/documents/${id}/restart.json`);
	// 		toastr.success(t("scrive.responses.document_restarted", "Dokumentet har startats om"));
	// 	} catch (error) {
	// 		toastr.error(error);
	// 		console.error("error:", error);
	// 	}
	// }, [id, t]);
	const viewOnly = false;
	const value: ScriveDocumentContextValue = useMemo(
		() => ({
			handleRemoveDocument,
			handleDeleteDocument,
			handleCancelDocument,
			handleRemindDocument,
			handleSave,
			form,
			isFetching,
			t,
			id,
			isSaving,
			setIsSaving,
			viewOnly,
			triggerCallback,
			handleOpenAppendPdfModal,
		}),
		[
			handleRemoveDocument,
			handleDeleteDocument,
			handleCancelDocument,
			handleRemindDocument,
			form,
			isFetching,
			t,
			id,
			handleSave,
			isSaving,
			setIsSaving,
			viewOnly,
			triggerCallback,
			handleOpenAppendPdfModal,
		]
	);

	return useMemo(
		() => (
			<ScriveDocumentContext.Provider value={value}>
				{children}
				<UploadModal
					open={appendPdfModalIsOpen}
					onClose={() => {
						setAppendPdfModalIsOpen(false);
					}}
					primaryAction={{
						content: t("scrive.append_upload_modal.actions.primary", "Bifoga"),
					}}
					onSelect={async (imgs) => {
						if (imgs?.[0]) {
							const res = await handlAppendPdf(imgs[0]);
							if (res) {
								queryClient.setQueryData(queryKeys, res);
								setAppendPdfModalIsOpen(false);
							}
							return res;
						}
					}}
					allowMultiple={false}
					disabledToast
				>
					<p>{t("scrive.append_upload_modal.description", "Bifoga en PDF som lägs på nuvarande PDF")}</p>
				</UploadModal>
			</ScriveDocumentContext.Provider>
		),
		[value, children, appendPdfModalIsOpen, t, handlAppendPdf, queryClient, queryKeys]
	);
});

export default ScriveDocumentContext;
