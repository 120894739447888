import { ResourceList } from "@shopify/polaris";
import moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import BigSearch from "../../components/big_search";
import NumberBadge from "../../components/NumberBadge";

class LanguagesIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderItem(item, a, b) {
		const url = "/admin/languages/" + item.locale;

		return (
			<ResourceList.Item id={item.id} url={url}>
				<div style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
					<div style={{ flex: 1 }}>
						<span>{item.title}</span>
						<span> - </span>
						<span>{item.locale}</span>
						{/* <div>{item.verified ? <Badge status="success">Verifierad</Badge> : <Badge status="critical">Ej verifierad</Badge>}</div> */}
					</div>
					<div style={{ display: "flex", gap: "1rem" }}>
						<NumberBadge>{item.count}</NumberBadge>
						<span>{moment(item.created_at).format("YYYY-MM-DD")}</span>
					</div>
				</div>
			</ResourceList.Item>
		);
	}

	render() {
		const filters = [
			// {
			// 	key: "verified",
			// 	label: this.props.t("common.language.filters.verified.label", "Vefifierade"),
			// 	type: FilterType.Select,
			// 	options: [
			// 		{ label: this.props.t("common.terms.yes", "Ja"), value: 1 },
			// 		{ label: this.props.t("common.terms.no", "Nej"), value: 0 },
			// 	],
			// },
		];

		const sortOptions = [
			{ label: this.props.t("common.language.sorts.title_asc.label", "Namn (A-Ö)"), value: "TITLE_ASC" },
			{ label: this.props.t("common.language.sorts.title_desc.label", "Namn (Ö-A)"), value: "TITLE_DESC" },
			{ label: this.props.t("common.language.sorts.locale_desc.label", "Skapad (nyast till äldst)"), value: "CREATED_AT_DESC" },
			{ label: this.props.t("common.language.sorts.locale_asc.label", "Skapad (äldst till nyast)"), value: "CREATED_AT_ASC" },
		];

		return (
			<BigSearch
				breadcrumbs={[{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }]}
				title={this.props.t("common.language.title", "Språk")}
				savedSearchHandle="languages"
				resourceUrl="/api/translations.json"
				resourceHandle="languages"
				resourceName={{
					singular: this.props.t("common.language.singluar", "språk"),
					plural: this.props.t("common.language.plural", "språk"),
				}}
				renderItem={this.renderItem.bind(this)}
				filters={filters}
				sortOptions={sortOptions}
				history={this.props.history}
				// defaultSavedSearches={[
				// 	{
				// 		title: "Verifierade",
				// 		filters: [
				// 			{
				// 				key: "verified",
				// 				value: 1,
				// 			},
				// 		],
				// 		sorting: "ID_DESC",
				// 		search: "",
				// 		id: "default_active",
				// 	},
				// ]}
			/>
		);
	}
}
export default withTranslation(["common"])(LanguagesIndex);
