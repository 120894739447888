import React, { Component } from "react";
import { Icon, Stack, Button } from "@shopify/polaris";
import { CirclePlusMinor, CircleCancelMinor, CircleTickOutlineMinor, CircleMinusOutlineMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MyPopover from "../../components/Popover";

class CellChecklist extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: null, query: "", value: props.value || [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value || [] });
		}
	}

	// addTag(tag) {
	// 	console.log("add tag");
	// 	if (this.state.value.indexOf(tag.id) < 0) {
	// 		this.props.onChange({ value: this.state.value, tags: [tag] });
	// 		this.state.value.push(tag.id);
	// 		this.setState({ value: this.state.value });
	// 	}
	// 	this.setState({ active: false }, this.props.onBlur);
	// }

	removeRow(index) {
		this.state.value.splice(index, 1);
		this.setState({ value: this.state.value });
		this.props.onChange({ value: this.state.value });
	}

	createRow() {
		if (!this.state.query) {
			return;
		}
		this.state.value.push({ title: this.state.query, checked: false });
		this.setState({ query: "", value: this.state.value, active: false }, this.props.onBlur);
		this.props.onChange({ value: this.state.value });
	}

	toggleRow(index, event) {
		event.stopPropagation();
		this.state.value[index].checked = !this.state.value[index].checked;
		this.setState({ value: this.state.value });
		this.props.onChange({ value: this.state.value });
	}

	editRow(index, event) {
		event.stopPropagation();
		this.setState({ editing: index });
	}

	updateRow(index, event) {
		this.state.value[index].title = event.target.value;
		this.setState({ value: this.state.value });
	}

	stopEditing() {
		this.setState({ editing: null });
		this.props.onChange({ value: this.state.value });
	}

	render() {
		let checkedItems = 0;
		for (let i = 0; i < this.state.value.length; i++) {
			if (this.state.value[i].checked) {
				checkedItems++;
			}
		}
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active && this.state.editing === null}
				activator={
					<div
						className={this.state.active ? "expandedCell" : ""}
						style={{
							height: this.state.active ? "auto" : this.props.height,
							minHeight: this.props.height,
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						{!this.state.active ? (
							<Stack spacing="none" wrap={false}>
								<div className="board-display-hover" style={{ paddingRight: 3 }}>
									<Icon source={CirclePlusMinor} color="blue" />
								</div>
								<Stack.Item fill>
									<div style={{ textAlign: "center" }}>
										{checkedItems}/{this.state.value.length}
									</div>
								</Stack.Item>
							</Stack>
						) : null}
						{this.state.active
							? this.state.value &&
							  this.state.value.map((row, index) => (
									<div key={index} style={{ padding: 3 }}>
										<Stack spacing="extraTight" wrap={false}>
											{this.state.editing !== index ? (
												<a onClick={this.toggleRow.bind(this, index)}>
													<Icon source={row.checked ? CircleTickOutlineMinor : CircleMinusOutlineMinor} color={row.checked ? "green" : "red"} />
												</a>
											) : null}
											<Stack.Item fill>
												<div onClick={this.editRow.bind(this, index)} className={"board-editable" + (this.state.editing === index ? " focused" : "")}>
													{this.state.editing === index ? (
														<div
															onClick={(event) => {
																event.stopPropagation();
															}}
															onDragStart={(event) => {
																event.stopPropagation();
																event.preventDefault();
															}}
															draggable
														>
															<input
																ref={(input) => {
																	if (input) {
																		input.focus();
																	}
																}}
																onChange={this.updateRow.bind(this, index)}
																onKeyDown={(event) => {
																	if (event.key === "Enter") {
																		this.stopEditing();
																	}
																}}
																onBlur={() => {
																	this.stopEditing();
																}}
																type="text"
																value={row.title}
																style={{ textAlign: "center", display: "block", width: "100%", border: 0 }}
															/>
														</div>
													) : (
														row.title
													)}
												</div>
											</Stack.Item>
											{this.state.editing !== index ? (
												<span className="clear-button-inline" onClick={this.removeRow.bind(this, index)}>
													<Icon source={CircleCancelMinor} />
												</span>
											) : null}
										</Stack>
									</div>
							  ))
							: null}
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ width: 250 }}>
					<input
						ref={(input) => {
							this.input = input;
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								this.createRow();
							} else if (event.key === "Escape") {
								this.setState({ active: false, query: "" }, this.props.onBlur);
							}
						}}
						className="bgInput"
						placeholder={this.props.t("cell.checklist.search.placeholder", "Lägg till ny rad")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value });
						}}
					/>
				</div>
				<div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
					<Button fullWidth loading={this.state.saving} disabled={!this.state.query} primary onClick={this.createRow.bind(this)}>
						{this.props.t("cell.checklist.action.create", "Lägg till")}
					</Button>
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board"], { withRef: true })(CellChecklist);
