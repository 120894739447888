import React, { Component } from "react";
import { Icon, Spinner, Stack, Popover, ActionList } from "@shopify/polaris";
import axios from "axios";
import { CirclePlusMinor, CircleCancelMinor, SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import BoardHelper from "./BoardHelper.js";
import MyPopover from "../../components/Popover";

class CellVisitor extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", result: [], value: props.value || [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value || [] });
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = [];
		this.setState({ value: this.state.value });
		this.props.onChange({ value: this.state.value });
	}

	createContact() {}

	searchContacts() {
		this.setState({ loadingTickets: true, result: [] });
		this.cancelRequest();
		clearTimeout(this.state.timeout);
		this.setState({ timeout: setTimeout(this.doSearch.bind(this, 0), 500) });
	}

	doSearch(offset) {
		const params = Object.assign({}, {});
		params.q = this.state.query;
		params.limit = 50;
		params.offset = offset;
		const CancelToken = axios.CancelToken;
		API.get("/api/boards/" + this.props.column.board_id + "/all_tickets.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				this.setState({ loadingTickets: false, result: result.data.tickets });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingTickets: false });
			});
	}

	toggleRow(row) {
		if (this.state.value.indexOf(row.id) < 0) {
			this.state.value.push(row.id);
			store.dispatch({ type: "UPDATE_BOARD_TICKET", ticket: row });
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		} else {
			this.removeRow(row);
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	removeRow(row) {
		const index = this.state.value.indexOf(row.id);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		}
	}

	render() {
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						style={{
							height: "100%",
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							padding: 3,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.doSearch(0);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<Stack spacing="none" wrap={false}>
							<div className="board-display-hover" style={{ paddingTop: 4, paddingRight: 3 }}>
								<Icon source={CirclePlusMinor} color="blue" />
							</div>
							<Stack.Item fill>
								<div
									className="overflow-ellipsis"
									style={{
										border: "1px solid #ccc",
										opacity: this.state.value.length < 1 ? 0.8 : null,
										// backgroundColor: this.state.value.length < 1 ? null : "#fff",
										borderRadius: "3px 0px 0px 3px",
										borderLeft: "5px solid #006fbb",
										padding: 3,
									}}
								>
									{this.state.value.length < 1
										? "–"
										: BoardHelper.getTicket(this.state.value[0])
										? BoardHelper.getTicket(this.state.value[0]).number
										: this.state.value[0]}
								</div>
							</Stack.Item>
							{this.state.value.length > 1 ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{this.state.value.length - 1}
								</div>
							) : null}
						</Stack>
						{this.state.value.length > 0 ? (
							<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
								<Icon source={CircleCancelMinor} />
							</div>
						) : null}
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ width: 250, position: "relative" }}>
					<span style={{ position: "absolute", left: 7, top: 10 }}>
						<Icon source={SearchMinor} color="inkLighter" />
					</span>
					<input
						ref={(input) => {
							this.input = input;
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								this.createContact();
							} else if (event.key === "Escape") {
								this.setState({ active: false, query: "" }, this.props.onBlur);
							}
						}}
						className="bgInput"
						style={{ paddingLeft: 30 }}
						placeholder={this.props.t("cell.visitor.placeholder", "Sök ärende...")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value }, this.searchContacts.bind(this));
						}}
					/>
					{this.state.loadingTickets ? (
						<Popover.Section>
							<Spinner size="small" />
						</Popover.Section>
					) : (
						<ActionList
							items={this.state.result.map((ticket) => ({
								content: "#" + ticket.number + " " + ticket.status,
								helpText: ticket.title,
								onAction: this.toggleRow.bind(this, ticket),
							}))}
						/>
					)}
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellVisitor);
