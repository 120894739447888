import React, { Component } from "react";
import { Card, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

class Skeleton1Card extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Card sectioned>
				<TextContainer>
					<SkeletonDisplayText size="small" />
					<SkeletonBodyText />
				</TextContainer>
			</Card>
		);
	}
}

export default Skeleton1Card;
