import React, { Component } from "react";
import { Scrollable, ChoiceList, FormLayout, Select } from "@shopify/polaris";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import svLocale from "@fullcalendar/core/locales/sv";

import { withTranslation } from "react-i18next";
import $ from "jquery";
import Page from "src/js/components/page";
import API from "../../API";
import MissingColumnValuesSheet from "./components/MissingColumnValuesSheet";
import { toastr } from "../../components/toastr.js";
import CalendarPopupPreview from "./components/CalendarModal/CalendarPopupPreview";
import RowSheet from "./components/RowSheet";
import Sheet from "../../components/sheet";

class BoardViewCalendar extends Component {
	constructor(props) {
		super(props);

		this.state = { events: [], showMissing: false };
	}

	componentDidMount() {
		this.updateView(this.props);
		this.updateDefaultValues();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings == this.props.showSettings) {
			this.updateView(props);
		}
	}

	updateDefaultValues() {
		const values = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			const column = this.props.board.columns[i];
			if (column.type == "datetime" || column.type == "timeline") {
				if (this.props.view.value_columns.length < 1 || this.props.view.value_columns.indexOf(column.id) >= 0) {
					let val;
					if (column.type == "datetime") {
						val = { datetime: moment().format("YYYY-MM-DD HH") + ":00" };
					}
					if (column.type == "timeline") {
						val = { start: moment().format("YYYY-MM-DD HH") + ":00", end: moment().add("1", "hours").format("YYYY-MM-DD HH") + ":00" };
					}
					values.push({ column_id: column.id, value: val });
				}
			}
		}
		this.props.onChangeDefaultValues(values);
		this.setState({ defaultValues: values });
	}

	getColorByName(name) {
		const colors = ["#47c1bf", "#de3618", "#f49342", "#50b83c", "#006fbb", "#9c6ade"];

		if (!name) {
			return colors[0];
		}
		return colors[name.charCodeAt(0) % colors.length];
	}

	updateView(props) {
		let colorByColumn = null;
		if (props.view.color_by != "group") {
			for (let i = 0; i < props.board.columns.length; i++) {
				if (props.board.columns[i].id == props.view.color_by) {
					colorByColumn = props.board.columns[i];
					break;
				}
			}
		}

		const events = [];

		const columns = {};
		for (let i = 0; i < props.board.columns.length; i++) {
			columns[props.board.columns[i].id + ""] = props.board.columns[i];
		}

		for (let i = 0; i < props.rows.length; i++) {
			const row = props.rows[i];
			let color = "#666666";

			if (colorByColumn && (colorByColumn.type == "status" || colorByColumn.type == "person")) {
				for (let s = 0; s < row.values.length; s++) {
					const value = row.values[s];
					const column = columns[value.column_id + ""];
					if (column?.id == colorByColumn.id) {
						if (value.value || value.value === 0 || value.value === "0") {
							if (colorByColumn.type == "status") {
								const curStatus = colorByColumn.options.statuses.find((s) => s.id == value.value);
								if (curStatus) {
									color = curStatus.color;
								}
							} else if (colorByColumn.type == "person" && value.value.length) {
								for (let x = 0; x < this.props.board.members.length; x++) {
									if (this.props.board.members[x].user_id == value.value[0]) {
										color = this.getColorByName(this.props.board.members[x].title);
										break;
									}
								}
							}
						}
					}
				}
			} else {
				for (let s = 0; s < props.groups.length; s++) {
					if (props.groups[s].id == row.group_id) {
						color = props.groups[s].color;
						break;
					}
				}
			}

			for (let s = 0; s < row.values.length; s++) {
				const value = row.values[s];
				const column = columns[value.column_id + ""];

				if ((column && column.type == "datetime") || (column && column.type == "timeline")) {
					if (value.value && (props.view.value_columns.length < 1 || props.view.value_columns.indexOf(column.id) >= 0)) {
						let taskStart;
						let taskEnd;

						if (column.type == "datetime") {
							if (value.value.datetime && value.value.datetime.length > 10) {
								// Have time
								taskStart = value.value.datetime + ":00";
								taskEnd = moment(value.value.datetime).add(1, "hour").format("YYYY-MM-DD HH:mm") + ":00";
							} else {
								// Only date
								taskStart = value.value.datetime + " 00:00:00";
								taskEnd = moment(value.value.datetime).add(1, "day").format("YYYY-MM-DD") + " 00:00:00";
							}
						} else if (column.type == "timeline") {
							taskStart = value.value.start + ":00";
							taskEnd = value.value.end + ":00";
						}

						events.push({
							id: row.id + "_" + column.id,
							start: taskStart.replace(" ", "T"),
							end: taskEnd.replace(" ", "T"),
							column,
							row,
							backgroundColor: color,
							borderColor: color,
							title: row.title,
							startEditable: column.type == "timeline",
							durationEditable: column.type == "timeline",
							editable: true,
						});
					}
				}
			}
		}

		this.setState({
			events: events.sort((a, b) => moment(a.start).unix() - moment(b.start).unix()),
		});
	}

	getColumnOptions(types) {
		const arr = [];
		if (types.indexOf("group") >= 0) {
			arr.push({ value: "group", label: "Grupper" });
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push({ value: this.props.board.columns[i].id + "", label: this.props.board.columns[i].title });
			}
		}
		return arr;
	}

	getRowModalValues(e) {
		const dateColumn = this.props.board.columns.find((c) => c.type === "datetime" || c.type === "timeline");
		const startDate = (() => {
			if (e.allDay) {
				return moment(e.dateStr + " 08:00").format("YYYY-MM-DD hh:mm");
			}
			return moment(e.dateStr).format("YYYY-MM-DD hh:mm");
		})();

		const values = [
			{
				column_id: dateColumn.id,
				value: { start: startDate, end: moment(startDate).add(1, "h").format("YYYY-MM-DD hh:mm") },
			},
		];

		return values;
	}

	render() {
		const validViewValueColumnOptions = this.getColumnOptions(["datetime", "timeline"]);
		const currentViewValueColumns = [];

		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].type == "datetime" || this.props.board.columns[i].type == "timeline") {
				if (this.props.view.value_columns.length < 1 || this.props.view.value_columns.indexOf(this.props.board.columns[i].id) >= 0) {
					currentViewValueColumns.push(this.props.board.columns[i]);
				}
			}
		}

		const colorByOptions = this.getColumnOptions(["group", "status", "person"]);

		return (
			<div>
				{this.props.header}
				<Page fullWidth>
					<FullCalendar
						// ref={(calendar) => {
						// 	this.calendar = calendar;
						// }}
						dateClick={(e) => {
							const values = this.getRowModalValues(e);
							// this.props.onCreateRow(null, null, values);
							this.setState({
								open: true,
								row: {
									column_values: values.reduce((acc, value) => {
										acc[value.column_id] = value;
										return acc;
									}, {}),
									values,
								},
							});
						}}
						initialView="dayGridMonth"
						allDaySlot={false}
						eventClick={(e) => {
							const { event, el } = e;
							this.calendarPopup.open(event.extendedProps.row, event.extendedProps.column, this.props.board, event.backgroundColor, el);
						}}
						eventResize={(info) => {
							const event = info.event;
							const newStart = event.start.toISOString();
							const newEnd = event.end.toISOString();

							const data = { value: { start: moment(newStart).format("YYYY-MM-DD HH:mm"), end: moment(newEnd).format("YYYY-MM-DD HH:mm") } };

							API.post(
								"/" +
									"api" +
									"/boards/" +
									this.props.board.id +
									"/rows/" +
									event.extendedProps.row.id +
									"/columns/" +
									event.extendedProps.column.id +
									"/values.json",
								data,
								{ params: {} }
							)
								.then((result) => {
									if (result.data.error) {
										toastr.error(result.data.error);
										return;
									}
									this.props.onUpdateRow(result.data.row);
								})
								.catch((error) => {
									toastr.error(error);
								});
						}}
						eventDrop={(info) => {
							const event = info.event;
							const newStart = event.start.toString();
							const newEnd = event.end.toString();
							// if (info.newResource) {
							// 	let newSlotId = info.newResource.id;
							// } else {
							// 	let newSlotId = event.resourceId;
							// }

							const row = event.extendedProps.row;
							let data = null;
							if (event.extendedProps.column.type == "timeline") {
								data = { value: { start: moment(newStart).format("YYYY-MM-DD HH:mm"), end: moment(newEnd).format("YYYY-MM-DD HH:mm") } };
							} else if (event.extendedProps.column.type == "datetime") {
								// for (let i = 0; i < row.values.length; i++) {
								// 	if (row.values[i].column_id == event.extendedProps.column.id) {
								// 		let currentValue = row.values[i];
								// 		break;
								// 	}
								// }
								// let data;
								// if (currentValue.value.datetime && currentValue.value.datetime.length > 10) {
								// 	// Have time
								// 	data = { value: { datetime: moment(newStart).format("YYYY-MM-DD HH:mm") } };
								// } else {
								// 	// Only date
								// 	data = { value: { datetime: moment(newStart).format("YYYY-MM-DD") } };
								// }
							}
							for (let i = 0; i < row.values.length; i++) {
								if (row.values[i].column_id == event.extendedProps.column.id) {
									Object.assign(row.values[i], data);
									break;
								}
							}
							API.post(
								"/api/boards/" + this.props.board.id + "/rows/" + row.id + "/columns/" + event.extendedProps.column.id + "/values.json",
								data,
								{ params: {} }
							)
								.then((result) => {
									if (result.data.error) {
										toastr.error(result.data.error);
									}
								})
								.catch((error) => {
									toastr.error(error);
								});

							this.props.onUpdateRow(row);
						}}
						nowIndicator
						height={$(window).height() - ($(".Polaris-TopBar").length ? 56 : 0) - ($(".board-main-header").height() + 50)}
						firstDay={1}
						businessHours={{
							daysOfWeek: [1, 2, 3, 4, 5],
							startTime: "08:00",
							endTime: "17:00",
						}}
						slotDuration="00:15:00"
						locale={svLocale}
						customButtons={{
							itemList: {
								text: this.props.t("board.terms.more_rows", "Fler rader"),
								click: () => {
									this.setState({ showMissing: true });
								},
							},
						}}
						headerToolbar={{
							left: "prev,next today",
							center: "title",
							right: "dayGridMonth,timeGridWeek,timeGridDay itemList",
						}}
						plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
						events={this.state.events}
					/>
				</Page>
				<MissingColumnValuesSheet
					columns={currentViewValueColumns}
					board={this.props.board}
					rows={this.props.rows}
					groups={this.props.groups}
					open={this.state.showMissing}
					onClose={() => {
						this.setState({ showMissing: false });
					}}
					onUpdateRow={this.props.onUpdateRow}
					defaultValues={this.state.defaultValues}
					onEditRow={(row) => {
						this.setState({ showMissing: false });
						this.props.editRow(row);
					}}
					onCreateRow={() => {
						this.setState({ showMissing: false });
						this.props.editRow(null);
					}}
				/>
				<Sheet
					open={this.props.showSettings}
					onClose={this.props.onCloseSettings}
					title={this.props.t("board.calendar.settings.title", "Inställningar")}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ padding: "1.6rem", height: "100%" }}>
							<FormLayout>
								<ChoiceList
									allowMultiple
									title={this.props.t("board.terms.time_columns", "Tidskolumner")}
									choices={validViewValueColumnOptions}
									selected={this.props.view.value_columns}
									onChange={(v) => {
										this.props.view.value_columns = v;
										this.props.onUpdateView(this.props.view);
										this.updateDefaultValues();
									}}
								/>
								<Select
									label={this.props.t("board.terms.color_from", "Färg från")}
									options={colorByOptions}
									value={this.props.view.color_by}
									onChange={(v) => {
										this.props.view.color_by = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
							</FormLayout>
						</Scrollable>
					</div>
				</Sheet>

				<CalendarPopupPreview
					ref={(r) => {
						this.calendarPopup = r;
					}}
				/>

				<RowSheet
					open={this.state.open}
					row={this.state.row}
					board={this.props.board}
					initialTabIndex={0}
					onUpdateRow={this.props.onUpdateRow}
					onClose={() => {
						this.setState({ open: false });
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardViewCalendar);
