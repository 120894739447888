import React, { Component } from "react";
import { ResourceList, Card, Pagination, Icon, Stack } from "@shopify/polaris";
import axios from "axios";
import httpBuildQuery from "http-build-query";
import { DeleteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import SavedSearchTabs from "./saved_search_tabs.js";
import MyFilters from "./MyFilters.js";
import { store } from "../store";
import { toastr } from "./toastr.js";
import Page from "./page";

class BigSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: props.loading,
			items: props.items || [],
			count: 0,
			offset: 0,
			limit: props.limit || 50,
			searchValue: "",
			selectedItems: [],
			appliedFilters: [],
			saveableSearch: false,
			timeout: null,
			cancelToken: null,
			sortValue: props.sortOptions && props.sortOptions.length ? props.sortOptions[0].value : "",
			defaultSortValue: props.sortOptions && props.sortOptions.length ? props.sortOptions[0].value : "",
			resourceUrl: props.resourceUrl,
			fieldOptionActive: false,
			selectedFields: props.selectedFields,
			queryString: "",
		};

		if (props.history?.location?.search) {
			this.state.queryString = props.history.location.search;
			const state = this.parseState(props.history.location.search);
			// eslint-disable-next-line no-restricted-syntax
			for (const i in state) {
				this.state[i] = state[i];
			}
		}
	}

	parseState(queryString) {
		const data = this.parseQuery(queryString);

		const state = {
			offset: 0,
			sortValue: this.state.defaultSortValue,
			searchValue: "",
			appliedFilters: [],
		};

		// eslint-disable-next-line no-restricted-syntax
		for (const i in data) {
			if (i == "offset") {
				if (data[i]) {
					state.offset = parseInt(data[i]);
				} else {
					state.offset = 0;
				}
			} else if (i == "sort") {
				state.sortValue = data[i];
			} else if (i == "q") {
				state.searchValue = data[i];
			} else {
				state.appliedFilters.push({ key: i, value: data[i] });
			}
		}

		return state;
	}

	parseQuery(queryString) {
		const query = {};
		const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
		if (pairs && pairs.length) {
			for (let i = 0; i < pairs.length; i++) {
				const pair = pairs[i].split("=");
				if (pair.length > 1) {
					query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
				}
			}
		}
		return query;
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.resourceUrl != this.state.resourceUrl) {
			this.state.resourceUrl = props.resourceUrl;
			this.setState({ resourceUrl: props.resourceUrl });
			if (this.state.queryString == props.history.location.search) {
				this.reload();
			}
		}
		if (this.state.queryString != props.history.location.search) {
			this.state.queryString = props.history.location.search;
			this.setState({ queryString: this.state.queryString, selectedItems: [] });
			const state = this.parseState(props.history.location.search);
			this.setState(state);
			this.fetchItems(state.appliedFilters, state.searchValue, state.sortValue, state.offset, false);
			this.props.onSelectionChange?.([]);
		}
		this.setState({ selectedItems: props.selectedItems });
	}

	componentDidMount() {
		this.fetchItems(
			this.state.appliedFilters,
			this.state.searchValue,
			this.state.sortValue,
			this.state.offset,
			!!(this.props.items && this.props.items.length)
		);
		if (this.props.setRefreshHandler) this.props.setRefreshHandler(this.reload.bind(this));
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	handleSearchChange(searchValue) {
		clearTimeout(this.state.timeout);
		this.setState({
			offset: 0,
			searchValue,
			loading: true,
			timeout: setTimeout(this.updateSearch.bind(this, this.state.appliedFilters, searchValue, this.state.sortValue, 0), 10),
		});
	}

	handleSortChange(selected) {
		this.setState({ sortValue: selected });
		this.updateSearch(this.state.appliedFilters, this.state.searchValue, selected, 0);
	}

	handleFiltersChange(appliedFilters) {
		this.setState({ appliedFilters, offset: 0 });
		if (this.props.onFiltersChange) {
			this.props.onFiltersChange(appliedFilters);
		}
		this.updateSearch(appliedFilters, this.state.searchValue, this.state.sortValue, 0);
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	componentWillUnmount() {
		clearTimeout(this.state.refreshTimeout);
		this.setState({ refreshTimeout: null });
	}

	updateSearch(filters, search, sorting, offset) {
		const params = {};
		if (filters && filters.length) {
			for (let i = 0; i < filters.length; i++) {
				params[filters[i].key] = filters[i].value;
			}
		}
		params.q = search;
		this.props.onUpdateParams?.(Object.assign({}, params));
		params.sort = sorting;
		params.offset = offset;
		this.props.history.replace(this.props.history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
	}

	fetchItems(filters, search, sorting, offset, silent) {
		this.cancelRequest();
		const CancelToken = axios.CancelToken;

		const params = Object.assign({}, this.props.params);
		if (filters && filters.length) {
			for (let i = 0; i < filters.length; i++) {
				params[filters[i].key] = filters[i].value;
			}
		}
		params.q = search;
		params.sort = sorting;
		params.offset = offset;
		params.limit = this.state.limit;

		clearTimeout(this.state.refreshTimeout);
		this.setState({ loading: !silent, refreshTimeout: null });
		API.get(this.state.resourceUrl, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				this.setState({ items: [] });
				this.setState({ loading: false, count: result.data.count, items: result.data[this.props.resourceHandle] });
				if (this.props.onItemsFetched) {
					this.props.onItemsFetched(result.data[this.props.resourceHandle]);
				}
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// eslint-disable-next-line no-console
					console.debug("Request canceled");
				} else {
					toastr.error(error);
				}
			});
	}

	// toggleFieldOptionPopover() {
	// 	this.setState({ fieldOptionActive: !this.state.fieldOptionActive });
	// }

	changeTab(tab) {
		this.setState({ appliedFilters: tab.filters, searchValue: tab.search, sortValue: tab.sorting, offset: 0 });
		this.updateSearch(tab.filters, tab.search, tab.sorting, 0);
	}

	setSaveable(val) {
		this.setState({ saveableSearch: val });
	}

	saveSearchModal() {
		this.setState({ showSaveSearchModal: true });
	}

	closeSaveSearchModal() {
		this.setState({ showSaveSearchModal: false });
	}

	removeSearchModal() {
		this.setState({ removeSearchModal: true });
	}

	closeRemoveSearchModal() {
		this.setState({ removeSearchModal: false });
	}

	reload(silent = false) {
		this.fetchItems(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, this.state.offset, silent);
	}

	goPrevious() {
		if (this.state.offset > 0) {
			const offset = this.state.offset - this.state.limit;
			this.setState({ offset });
			this.updateSearch(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, offset);
		}
	}

	goNext() {
		if (this.state.offset + this.state.limit < this.state.count) {
			const offset = this.state.offset + this.state.limit;
			this.setState({ offset });
			this.updateSearch(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, offset);
		}
	}

	// onSelectFieldAction(item) {
	// 	this.setState({ fieldOptionActive: false });
	// 	this.setState({ selectedFields: [item.value] });
	// 	this.props.onSelectedFieldsChange([item.value]);
	// }

	render() {
		$(".Polaris-EmptySearchResult__Image").attr("src", "/assets/images/empty_state/NoResults.png");

		const resourceName = this.props.resourceName;

		const filters = this.props.filters;

		const promotedBulkActions = this.props.promotedBulkActions;

		const bulkActions = this.props.bulkActions;

		const hasPrevious = this.state.offset > 0;
		const hasNext = this.state.offset + this.state.limit < this.state.count;

		const filterControl = (
			<MyFilters
				filters={filters}
				appliedFilters={this.state.appliedFilters}
				onFiltersChange={this.handleFiltersChange.bind(this)}
				searchValue={this.state.searchValue}
				onSearchChange={this.handleSearchChange.bind(this)}
				additionalAction={
					this.props.saveableSearch &&
					(this.state.saveableSearch
						? {
								content: this.props.t("components.big_search.saved_searches.actions.save", "Spara"),
								onAction: this.saveSearchModal.bind(this),
						  }
						: ((this.state.appliedFilters && this.state.appliedFilters.length > 0) || this.state.searchValue) && !this.state.saveableSearch
						? {
								destructive: true,
								content: <Icon source={DeleteMajor} />,
								onAction: this.removeSearchModal.bind(this),
						  }
						: null)
				}
			/>
		);

		const content = (
			<React.Fragment>
				{!this.props.hideHeader && (
					<Stack wrap={false} spacing="none">
						<Stack.Item fill>
							<SavedSearchTabs
								resource={this.props.savedSearchHandle}
								resourceName={this.props.resourceName}
								showModal={this.state.showSaveSearchModal}
								onCloseModal={this.closeSaveSearchModal.bind(this)}
								showRemoveModal={this.state.removeSearchModal}
								onCloseRemoveModal={this.closeRemoveSearchModal.bind(this)}
								onChange={this.changeTab.bind(this)}
								onSaveable={this.setSaveable.bind(this)}
								defaultSort={this.state.defaultSortValue}
								sorting={this.state.sortValue}
								search={this.state.searchValue}
								filters={this.state.appliedFilters}
								defaultSavedSearches={this.props.defaultSavedSearches}
							/>
						</Stack.Item>
						{this.props.selector && (
							<Stack.Item>
								<div style={{ padding: 10, height: 53, borderLeft: "0.1rem solid #dfe3e8", borderBottom: "0.1rem solid #dfe3e8" }}>
									{this.props.selector}
								</div>
							</Stack.Item>
						)}
					</Stack>
				)}
				{this.props.secondTitle && React.isValidElement(this.props.secondTitle) ? this.props.secondTitle : <h2>{this.props.secondTitle}</h2>}
				<ResourceList
					showHeader={!!(!this.props.hideHeader && !this.props.hideSecondHeader)}
					resourceName={resourceName}
					items={this.state.items || []}
					hasMoreItems={store.getState().user.roles.indexOf("ROLE_SUPER_ADMIN") >= 0 && this.state.count > this.state.limit}
					renderItem={this.props.renderItem}
					filterControl={!this.props.hideHeader ? filterControl : null}
					selectedItems={this.props.selectedItems || []}
					loading={this.state.loading}
					onSelectionChange={this.props.onSelectionChange}
					promotedBulkActions={promotedBulkActions}
					bulkActions={bulkActions}
					sortValue={this.state.sortValue}
					sortOptions={this.props.sortOptions}
					onSortChange={this.handleSortChange.bind(this)}
					resolveItemId={({ id }) => id}
					totalItemsCount={this.state.count || 0}
				/>
			</React.Fragment>
		);

		if (this.props.noPage) {
			return (
				<React.Fragment>
					{content}
					{(hasPrevious || hasNext) && (
						<div style={{ height: "100px", paddingTop: 30, textAlign: "center" }}>
							<Pagination hasPrevious={hasPrevious} onPrevious={this.goPrevious.bind(this)} hasNext={hasNext} onNext={this.goNext.bind(this)} />
						</div>
					)}
				</React.Fragment>
			);
		}

		return (
			<Page
				fullWidth={!this.props.small}
				title={this.props.title}
				breadcrumbs={this.props.breadcrumbs}
				primaryAction={this.props.primaryAction}
				secondaryActions={this.props.secondaryActions}
				pagination={
					this.props.showTopNavigation && {
						hasPrevious,
						onPrevious: this.goPrevious.bind(this),
						hasNext,
						onNext: this.goNext.bind(this),
					}
				}
			>
				<div className="big_search">
					<Card>{content}</Card>
					<div style={{ height: "100px", paddingTop: 30, textAlign: "center" }}>
						<div style={{ marginBottom: "0.5rem" }}>
							{this.props.t("components.big_search.footer", "Visar {{from}} - {{to}} av {{total}} {{resourceName}}", {
								from: this.state.offset + 1,
								to: this.state.offset + (this.state.items?.length || 0),
								total: this.state.count,
								resourceName: this.state.count == 1 ? this.props.resourceName.singular : this.props.resourceName.plural,
							})}
						</div>
						{(hasPrevious || hasNext) && (
							<Pagination hasPrevious={hasPrevious} onPrevious={this.goPrevious.bind(this)} hasNext={hasNext} onNext={this.goNext.bind(this)} />
						)}
					</div>
				</div>
			</Page>
		);
	}
}

BigSearch.defaultProps = {
	loading: true,
	showTopNavigation: true,
	saveableSearch: true,

	filters: [],
	sortOptions: [],
	promotedBulkActions: [],
	bulkActions: [],
};

export default withTranslation(["components", "common"], { withRef: true })(BigSearch);
