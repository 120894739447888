import React, { Component } from "react";
import { Labelled, Stack, Button, ActionList } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import Popover from "./Popover.js";

class FieldSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {
		// this.setState({category:props.category});
	}

	onChange(category) {
		if (!category) {
			this.props.onChange(category);
			this.setState({ categoryPopoverActive: false });
		} else if (!category.id) {
			this.setState({ categoryPopoverActive: true });
		} else {
			this.setState({ categoryPopoverActive: false });
			this.props.onChange(category);
		}
	}

	toggleCategoryPopover() {
		this.setState({ categoryPopoverActive: !this.state.categoryPopoverActive });
	}

	render() {
		const options = [];

		if (this.props.field && !this.props.required) {
			options.push({
				content: this.props.empty ? this.props.empty : this.props.t("sales.field.empty.label", "Inget affärsområde"),
				onAction: this.onChange.bind(this, null),
			});
		}

		for (let i = 0; i < store.getState().fields.length; i++) {
			const field = store.getState().fields[i];
			options.push({
				content: (
					<span className="Polaris-Badge" style={{ color: field.color_hsl.brightness >= 0.8 ? "#000" : "#fff", backgroundColor: field.color }}>
						{field.title}
					</span>
				),
				onAction: this.onChange.bind(this, field),
			});
		}
		const button = this.props.disabled ? (
			<span>
				{this.props.field
					? this.props.field.title
					: this.props.empty
					? this.props.empty
					: this.props.t("sales.field.empty.label", "Inget affärsområde")}
			</span>
		) : (
			<Button plain onClick={this.toggleCategoryPopover.bind(this)}>
				{this.props.field ? (
					<span
						className="Polaris-Badge"
						style={{ color: this.props.field.color_hsl.brightness >= 0.8 ? "#000" : "#fff", backgroundColor: this.props.field.color }}
					>
						{this.props.field.title}
					</span>
				) : this.props.empty ? (
					this.props.empty
				) : (
					this.props.t("sales.field.empty.label", "Inget affärsområde")
				)}
			</Button>
		);
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<Stack>
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Popover active={this.state.categoryPopoverActive} activator={button} onClose={this.toggleCategoryPopover.bind(this)}>
							<ActionList items={options} />
						</Popover>
					</span>
				</Stack>
			</Labelled>
		);
	}
}
export default withTranslation(["sales", "common"], { withRef: true })(FieldSelector);
