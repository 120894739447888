/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import { Stack, TopBar } from "@shopify/polaris";
import { NotificationMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
// import { store } from "../store";
import Colors from "../Colors.js";
import NotificationSheet from "./NotificationSheet.js";
import NotificationContext from "../ContextProviders/NotificationContext.js";
import NavigationHeaderButton from "./NavigationHeaderButton.js";
// import ChatIcon from "../icons/ChatIcon.js";
// import ChatHandler from "../views/Chat/ChatHandler";
// import NumberBadge from "./NumberBadge";
// import { getLocalStorage, getSessionStorage } from "../Utilities";
import Sheet from "./sheet";

class Notifications extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<NotificationContext.Consumer>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				{(notificationProps) => <NotificationsBase {...this.props} notification_props={notificationProps} />}
			</NotificationContext.Consumer>
		);
	}
}

class NotificationsBase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// messageUnreadMsgsPreview: false,
			// counts: {},
			// total: 0,
		};

		// eslint-disable-next-line react/no-unused-class-component-methods
		this.audio = new Audio("https://www.sellfinity.com/assets/discord-notification.mp3");
	}

	// componentDidMount() {
	// 	this.getAndSetUnreadMessagesCount();
	// }

	// getAndSetUnreadMessagesCount() {
	// 	const counts = Object.entries(store.getState().chat_messages || {})?.reduce((acc, [chatId, messages]) => {
	// 		const chat = store.getState().chats.find((ch) => String(ch.id) === String(chatId));
	// 		return {
	// 			...acc,
	// 			[chatId]:
	// 				messages?.filter(
	// 					(message) =>
	// 						String(message.id) > (chat?.me_as_member?.last_read_id || 0) &&
	// 						String(message.user_id) !== String(store.getState().user.id) &&
	// 						!chat?.me_as_member?.mute
	// 				)?.length || 0,
	// 		};
	// 	}, {});

	// 	const total = Object.entries(counts)?.reduce((acc, [chatId, count]) => {
	// 		const chat = store.getState().chats.find((ch) => String(ch.id) === String(chatId));
	// 		if (!chat?.me_as_member?.mute && chat?.members?.some((mem) => !mem.hide) && chat?.title) {
	// 			return acc + count;
	// 		}

	// 		return acc;
	// 	}, 0);

	// 	this.setState({
	// 		counts,
	// 		total,
	// 		messageUnreadMsgsPreview: !!total,
	// 	});
	// }

	// UNSAFE_componentWillReceiveProps(props) {
	// 	this.getAndSetUnreadMessagesCount();
	// }

	toggleNotificationSheet(notificationSheetIsOpen = !this.state.notificationSheetIsOpen) {
		this.setState({ notificationSheetIsOpen }, () => {
			if (this.state.notificationSheetIsOpen) {
				this.props.notification_props?.refresh();
			}
		});
		// this.setState({ statusPopoverActive:  state });
	}

	// openMessageUnreadMsgsPreview() {
	// 	this.setState({ messageUnreadMsgsPreview: true });
	// }

	// closeMessageUnreadMsgsPreview() {
	// 	this.setState({ messageUnreadMsgsPreview: false });
	// }

	render() {
		const unreadNotifications = this.props.notification_props && this.props.notification_props.notifications_unread;
		// const refreshToken = getSessionStorage("refresh_token") || getLocalStorage("refresh_token");

		// const messageActions = [
		// 	this.state.counts && Object.keys(this.state.counts)?.length
		// 		? {
		// 				items: Object.entries(this.state.counts)
		// 					.filter(([chatId, count]) => count)
		// 					?.map(([chatId, count]) => {
		// 						const chat = store.getState().chats.find((chat) => String(chat.id) === String(chatId));

		// 						return {
		// 							content: (
		// 								<div style={{ display: "flex", justifyContent: "space-between", gap: "1rem", alignItems: "center", width: "100%" }}>
		// 									<span>{chat?.title}</span>
		// 									<NumberBadge>{count}</NumberBadge>
		// 								</div>
		// 							),
		// 							onAction: () => {
		// 								window.open(
		// 									`https://chatdirect.sellfinity.com/${store.getState().account.id}/chat/${chat.id}?refresh_token=${refreshToken}&acc_id=${
		// 										store.getState().account.id
		// 									}`,
		// 									"sellfinity_chat"
		// 								);
		// 							},
		// 						};
		// 					}),
		// 		  }
		// 		: { items: [] },

		// 	{
		// 		items: [
		// 			{
		// 				content: this.props.t("common.notifications.actions.show_all_chats", "Visa alla Chattar"),
		// 				onAction: () => {
		// 					window.open(
		// 						`https://chatdirect.sellfinity.com/${store.getState().account.id}?refresh_token=${refreshToken}&acc_id=${
		// 							store.getState().account.id
		// 						}`,
		// 						"sellfinity_chat"
		// 					);
		// 				},
		// 			},
		// 		],
		// 	},
		// ];

		return (
			<React.Fragment>
				<Stack wrap={false} spacing="extraTight">
					{/* <TopBar.Menu
						activatorContent={
							<NavigationHeaderButton
								tooltip={this.props.t("common.terms.chat", "Chatt")}
								number={this.state.total}
								color={Colors.yellow}
								icon={ChatIcon}
								onClick={
									this.state.total
										? this.openMessageUnreadMsgsPreview.bind(this)
										: () => {
												window.open(
													`https://chatdirect.sellfinity.com/${store.getState().account.id}?refresh_token=${refreshToken}&acc_id=${
														store.getState().account.id
													}`,mytop
													"sellfinity_chat"
												);
										  }
								}
							/>
						}
						open={this.state.messageUnreadMsgsPreview}
						actions={messageActions}
						onOpen={
							this.state.total
								? this.openMessageUnreadMsgsPreview.bind(this)
								: () => {
										window.open(
											`https://chatdirect.sellfinity.com/${store.getState().account.id}?refresh_token=${refreshToken}&acc_id=${
												store.getState().account.id
											}`,
											"sellfinity_chat"
										);
								  }
						}
						onClose={this.closeMessageUnreadMsgsPreview.bind(this)}
					/> */}
					<TopBar.Menu
						activatorContent={
							<NavigationHeaderButton
								tooltip={this.props.t("common.terms.notifications", "Notifikationer")}
								onClick={this.toggleNotificationSheet.bind(this)}
								number={unreadNotifications?.length || null}
								color={Colors.red}
								icon={NotificationMajor}
							/>
						}
						// open={this.state.notificationSheetIsOpen}
						onOpen={this.toggleNotificationSheet.bind(this, true)}
						onClose={this.toggleNotificationSheet.bind(this, false)}
					/>
				</Stack>

				<Sheet open={this.state.notificationSheetIsOpen} onClose={this.toggleNotificationSheet.bind(this, false)}>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<NotificationSheet history={this.props.history} {...(this.props.notification_props || {})} />
				</Sheet>

				{/* <ChatHandler /> */}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	chats: state.chats,
	chat_messages: state.chat_messages,
});

export default connect(mapStateToProps)(withTranslation(["common"], { withRef: true })(Notifications));
