/* eslint-disable camelcase */
import { Card, Checkbox, ChoiceList, FormLayout } from "@shopify/polaris";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import getContactActivityTypes from "src/js/Utilities/getContactActivityTypes";
import Button from "src/js/components/Button";
import SelectSearchField from "src/js/components/SelectSearchField";
import Select from "src/js/components/select/Select";
import { store } from "src/js/store";
import styled from "styled-components";

const NewFilter = ({ t, onSubmit, isLoading }) => {
	const [sourceType, setSourceType] = useState<any>();
	const [data, setData] = useState<any>();
	const [selectedActivityTypeOption, setSelectedActivityTypeOption] = useState<any>([]);

	const onChange = (field) => (value) => {
		const newData = {
			[sourceType]: {
				...(data?.[sourceType] || []),
				[field]: value,
			},
		};

		setData(newData);
	};

	const handleAddFilter = () => {
		onSubmit(data);

		setData(null);
		setSourceType(null);
	};

	const sourceTypeOptions = [
		{
			label: t("targets.fields.filter.source_type.board", "Board"),
			value: "board",
			renderChildren: (selected) => {
				if (!selected) return null;

				return (
					<SelectSearchField
						label={t("targets.fields.filter.board", "Välj board")}
						resourceName={{ singular: "board", plural: "boards" }}
						resource="boards"
						resourceHandle="boards"
						resourceURL="/api/boards.json"
						labelHandle="title"
						idHandle="id"
						value={data?.board_id}
						renderValue={() => {
							return data?.board?.board?.title;
						}}
						onSelect={(board) => {
							setData({
								board: {
									board,
								},
							});
						}}
					/>
				);
			},
		},
		{
			label: t("targets.fields.filter.source_type.contact_activity", "Kontaktakivitet"),
			value: "contact_activity",
			renderChildren: (selected) => {
				if (!selected) return null;

				const activityOptions = [
					{ value: "", label: t("goals.activities.choose.label", "Välj aktivitet...") },
					...store.getState().contact_activities.map((activity) => ({ value: String(activity.id), label: activity.title })),
				];

				const activityTypeOptions = [{ value: "", label: t("goals.activities.choose.label", "Välj aktivitet...") }, ...getContactActivityTypes()] as {
					label: string;
					value: any;
				}[];

				const baseActivityOptions = [
					{
						value: "ids",
						label: t("goals.activities.ids.label", "Specifik aktivitet"),
						renderChildren: (selected) => {
							if (!selected) return null;

							return (
								<Select
									options={activityOptions}
									value={String(data?.contact_activity.contact_activity?.id)}
									onChange={(value) => {
										const contactActivity =
											value === ""
												? null
												: store
														.getState()
														.contact_activities.find((contact_actvity: ContactActivityType) => String(contact_actvity.id) === String(value)) || value;
										onChange("contact_activity")(contactActivity);
									}}
								/>
							);
						},
					},
					{
						value: "types",
						label: t("goals.activities.types.label", "Aktivitets typ"),
						renderChildren: (selected) => {
							if (!selected) return null;

							return (
								<Select
									options={activityTypeOptions}
									value={data?.contact_activity.activity_type}
									onChange={(activity_type) => onChange("activity_type")(activity_type)}
								/>
							);
						},
					},
				];

				return (
					<ChoiceList
						title={t("goals.activities.choose.label", "Välj aktivitet...")}
						titleHidden
						choices={baseActivityOptions}
						selected={selectedActivityTypeOption ? [selectedActivityTypeOption] : []}
						onChange={(selected) => {
							setSelectedActivityTypeOption(selected[0]);
						}}
					/>
				);
			},
		},
	];

	const disabled = (() => {
		if (isLoading) return true;
		if (!sourceType) return true;

		switch (sourceType) {
			case "board":
				return !data?.board?.board;
			case "contact_activity":
				return !data?.contact_activity.contact_activity && !data?.contact_activity.activity_type;
			default:
				return true;
		}
	})();

	return (
		<Card sectioned>
			<Wrapper>
				<FormLayout>
					<ChoiceList
						title={t("targets.fields.filter.select_label", "Vad ska vi filtrerar på?")}
						choices={sourceTypeOptions}
						selected={sourceType ? [sourceType] : []}
						onChange={(selected) => setSourceType(selected?.[0])}
					/>
					{sourceType === "contact_activity" && data?.contact_activity && (
						<Checkbox
							checked={data?.contact_activity?.count_only_unique_contacts}
							label={t("targets.fields.filter.count_only_unique_contacts.label", "Gruppera på kontakt")}
							onChange={onChange("count_only_unique_contacts")}
							helpText={t("targets.fields.filter.count_only_unique_contacts.help_text", 'Gruppera på kontakt så det blir "kund unika aktiviteter".')}
						/>
					)}

					<Button primary onClick={handleAddFilter} disabled={disabled}>
						{t("targets.fields.filter.add_filter", "Lägg till filter")}
					</Button>
				</FormLayout>
			</Wrapper>
		</Card>
	);
};
export default withTranslation(["targets"])(NewFilter);

const Wrapper = styled.div``;
