/* eslint-disable camelcase */
import { Badge, Card, Modal, PageActions, ResourceItem, Stack } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import API from "../../API";
import OrderRowInput from "../OrderRowInput";
import FortnoxTable from "./FortnoxTable";
import Banner from "../banner";
import { store } from "../../store";
import Money from "../Money";
import BringModalToFront from "../BringModalToFront.js";
import Page from "../page";
import FortnoxResourceListWithPagination from "./FortnoxResourceListWithPagination";

class FortnoxOrderView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			integration: props.integration || {},
			customer: props.customer || {},
			form: this.initForm(),
		};
	}

	initForm() {
		return (
			{
				OurReference: store.getState().user.name,
				...(this.props.form || {}),
				CustomerNumber: this.props?.customer?.CustomerNumber,
				DeliveryAddress1: this.props?.customer?.DeliveryAddress1 || this.props?.customer?.Address1 || "",
				DeliveryAddress2: this.props?.customer?.DeliveryAddress2 || this.props?.customer?.Address1 || "",
				DeliveryZipCode: this.props?.customer?.DeliveryZipCode || this.props?.customer?.ZipCode || "",
				DeliveryCity: this.props?.customer?.DeliveryCity || this.props?.customer?.City || "",
				DeliveryCountry: this.props?.customer?.DeliveryCountry || this.props?.customer?.Country || "",
			} || {}
		);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.customer) {
			this.setState({ customer: props.customer, form: { ...this.state.form, CustomerNumber: props?.customer?.CustomerNumber } });
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	viewOrder(order) {
		if (this?.state?.integration?.id && this?.state?.integration?.integration_id && order.DocumentNumber) {
			API.get(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/orders/${order.DocumentNumber}.json`)
				.then((response) => {
					this.setState({ form: { ...(response.data || {}), ...this.state.form }, manage: true });
				})
				.catch((error) => {
					toastr.error("Error", error?.response?.data?.message || "Unknown error");
				});
		}
	}

	convertOffer(action, payload = {}) {
		const { integration_id, id } = this?.state?.integration || {};
		const { DocumentNumber } = this?.state?.form || {};
		if (id && integration_id && DocumentNumber) {
			this.setState({ actionLoading: true });
			API.put(`/api/integrations/${integration_id}/${id}/orders/${DocumentNumber}/create/${action}.json`, payload)
				.then((response) => {
					this.setState({ showActionModal: false });
				})
				.catch((error) => {})
				.finally(() => {
					this.setState({ actionLoading: false });
				});
		}
	}

	saveOrder() {
		this.state.form = { ...this.state.form, OrderRows: (this?.state?.form?.OrderRows || []).filter((item) => Object.keys(item).length) };
		this.setState({ saving: true });
		if (this?.state?.form?.DocumentNumber) {
			API.put(
				`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/orders/${this.state.form.DocumentNumber}.json`,
				this.state.form
			)
				.then((response) => {
					this.setState({ manage: false, form: response.data || { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					toastr.error("Error", error?.response?.data?.message || "Unknown error!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		} else {
			API.post(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/orders.json`, this.state.form)
				.then((response) => {
					this.setState({ manage: false, form: response.data || { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					toastr.error("Error", error?.response?.data.message || "Unknown error!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		}
	}

	render() {
		const { form, integration } = this.state;
		const commonFields = ["ArticleNumber", "Description", (row, value) => ({ ...row, Price: value.SalesPrice || null }), "Unit"];
		const created = form.OrderReference && form.OrderReference !== "0";

		return (
			<div style={{ paddingTop: 30 }}>
				<Page
					title={
						this.state.manage
							? !form.DocumentNumber
								? this.props.t("fortnox.order.titles.new", "Fortnox - Ny Order")
								: this.props.t("fortnox.order.title", "Fortnox - Order #") + form.DocumentNumber
							: this.props.t("fortnox.order.actions.create", "Fortnox Order")
					}
					primaryAction={
						this.state.manage
							? {
									content: this.props.t("common.actions.save", "Spara"),
									loading: this.state.saving,
									onAction: this.saveOrder.bind(this, true),
									disabled: this.state.saving || created,
							  }
							: {
									content: this.props.t("fortnox.order.actions.create", "Skapa ny order"),
									onAction: () => {
										this.setState({
											manage: true,
											form: this.initForm(),
										});
									},
							  }
					}
					breadcrumbs={
						this.state.manage
							? [
									{
										content: this.props.t("fortnox.order.breadcrumb", "Ordrar"),
										onAction: () => {
											this.setState({ manage: false, form: { OurReference: form.OurReference || "" } });
										},
									},
							  ]
							: []
					}
				>
					{this.state.manage ? (
						<div>
							<div style={{ marginBottom: 20 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ width: 100 }}>{this.props.t("fortnox.order.labels.our_reference", "Vår referens")}</th>
											<th style={{ width: 100 }}>{this.props.t("fortnox.order.labels.your_reference", "Er referens")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput
													value={form.OurReference}
													onChange={this.updateForm.bind(this, "OurReference")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													value={form.YourReference}
													onChange={this.updateForm.bind(this, "YourReference")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ paddingBottom: 5 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th colSpan={2}>{this.props.t("fortnox.common.labels.delivery_address", "Leveransadress")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.address_1", "Address 1")}
													value={form.DeliveryAddress1}
													onChange={this.updateForm.bind(this, "DeliveryAddress1")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.address_2", "Address 2")}
													value={form.DeliveryAddress2}
													onChange={this.updateForm.bind(this, "DeliveryAddress2")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.zip", "Postnummer")}
													value={form.DeliveryZipCode}
													onChange={this.updateForm.bind(this, "DeliveryZipCode")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.city", "Ort")}
													value={form.DeliveryCity}
													onChange={this.updateForm.bind(this, "DeliveryCity")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan={2}>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.country", "Land")}
													value={form.DeliveryCountry}
													onChange={this.updateForm.bind(this, "DeliveryCountry")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div>
								<div style={{ marginBottom: 20 }}>
									<FortnoxTable
										columns={[
											{
												resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
												resource: "Articles",
												translationHandle: "fortnox.order.labels.artnr",
												label: "Artikelnr",
												type: "autoComplete",
												handle: "ArticleNumber",
												updateFields: commonFields,
												props: {
													style: { width: 140 },
												},
											},
											{
												translationHandle: "fortnox.order.labels.term",
												label: "Benämning",
												type: "autoComplete",
												resource: "Articles",
												resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
												handle: "Description",
												updateFields: commonFields,
											},
											{
												translationHandle: "fortnox.order.labels.count",
												label: "Antal",
												type: "input",
												handle: "OrderedQuantity",
												props: {
													style: { width: 70 },
													className: "text-right",
												},
											},
											{
												translationHandle: "fortnox.common.labels.unit",
												label: "Enhet",
												type: "autoComplete",
												resource: "Units",
												handle: "Unit",
												mapper: (data) => data.map((value) => ({ ...value, Unit: value.Code })),
												resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/units.json`,
												props: {
													style: { width: 65 },
												},
											},
											{
												translationHandle: "fortnox.order.labels.a_price",
												label: "À-Pris",
												handle: "Price",
												type: "input",
												props: {
													style: { width: 70 },
													className: "text-right",
												},
											},
											{
												translationHandle: "fortnox.order.labels.sum",
												label: "Summa",
												props: {
													style: { width: 80 },
													className: "text-right",
												},
												handle: (row) => {
													return (
														parseFloat(row.Total) || (parseFloat(row.Price) || 0) * (parseFloat(row.DeliveredQuantity || row.OrderedQuantity) || 0)
													);
												},
												summary: form.OrderRows?.reduce((acc, row) => {
													acc +=
														parseFloat(row.Total || 0) || parseFloat(row.Price || 0) * parseFloat(row.DeliveredQuantity || row.OrderedQuantity || 0);

													return acc;
												}, 0),
											},
										]}
										data={form.OrderRows || Array.from(Array(1)).map(() => ({}))}
										onChange={(data) => {
											this.state.form.OrderRows = data.reduce((acc, curr) => {
												if (Object.keys(curr).length) {
													acc.push({ ...curr, DeliveredQuantity: curr?.OrderedQuantity || 0 });
												} else {
													acc.push(curr);
												}
												return acc;
											}, []);
											this.setState({ form: this.state.form });
										}}
									/>
								</div>
							</div>
							{form.Cancelled ? (
								<Banner title={this.props.t("fortnox.order.statuses.shredded", "Ordern är makulerad")} />
							) : form.InvoiceReference && form.InvoiceReference != "" && form.InvoiceReference != "0" ? (
								<Banner
									status="success"
									title={this.props.t("fortnox.order.statuses.shredded", "Faktura #{{invoiceReference}} skapad", {
										invoiceReference: form.InvoiceReference,
									})}
								/>
							) : (
								<PageActions
									primaryAction={
										form.DocumentNumber && {
											content: this.props.t("fortnox.common.create.invoice", "Skapa faktura"),
											onAction: () => this.setState({ showActionModal: true }),
											disabled: created,
										}
									}
									secondaryActions={
										form.DocumentNumber
											? [
													{
														content: "Förhandsgranska pdf",
														onAction: () =>
															window.open(
																`/api/integrations/${integration.integration_id}/${integration.id}/orders/${form.DocumentNumber}/preview.pdf`,
																"_blank"
															),
													},
											  ]
											: []
									}
								/>
							)}
						</div>
					) : (
						<div>
							<div>
								{this.state.customer ? (
									<Card title={this.props.t("fortnox.order.card.customer_info.title", "Kundinformation från fortnox")} sectioned>
										<p>
											{this.props.t("fortnox.order.labels.customer_number", "Kundnummer")}: {this.state?.customer?.CustomerNumber || "-"}
										</p>
										<p>
											{this.props.t("fortnox.order.labels.submit_invoice", "Leveranssätt faktura")}:{" "}
											{this?.props?.customer?.DefaultDeliveryTypes?.Invoice || "-"}
										</p>
										<p>
											{this.props.t("fortnox.order.labels.invoice_template", "Fakturamall")}:{" "}
											{this?.props?.customer?.DefaultDeliveryTypes?.Invoice != "DEFAULTTEMPLATE"
												? this?.state?.customer?.DefaultTemplates?.Invoice
												: "Standard"}
										</p>
									</Card>
								) : null}

								<FortnoxResourceListWithPagination
									ref={(ref) => {
										this.resourceList = ref;
									}}
									resource="Orders"
									customer={this.state.customer}
									integration={this.state.integration}
									renderItem={(order) => (
										<ResourceItem key={order.DocumentNumber} onClick={this.viewOrder.bind(this, order)}>
											<Stack alignment="center">
												<h3>
													#{order.DocumentNumber} - {order.OrderDate}
												</h3>
												{order.Sent ? (
													<Badge status="success">{this.props.t("fortnox.statuses.customer.sent", "Skickad till kund")}</Badge>
												) : (
													<Badge>{this.props.t("fortnox.statuses.customer.notsent", "Ej skickad till kund")}</Badge>
												)}
												{order.InvoiceCreated && order.InvoiceCreated != "" && order.InvoiceCreated != "0" && (
													<Badge status="info">{this.props.t("fortnox.statuses.customer.invoice_created", "Faktura skapad")}</Badge>
												)}
												{order.Cancelled && <Badge>{this.props.t("fortnox.statuses.shredded", "Makulerad")}</Badge>}
											</Stack>
											<Money amount={order.Total} currency={order.Currency} />
										</ResourceItem>
									)}
								/>
							</div>
						</div>
					)}
				</Page>
				<Modal
					open={this.state.showActionModal}
					title="Bekräftelse"
					primaryAction={{ content: "Ja", onAction: this.convertOffer.bind(this, "invoice", {}) }}
					secondaryActions={[
						{
							content: "Nej",
							onAction: () => this.setState({ showActionModal: false }),
						},
					]}
					onClose={() => this.setState({ showActionModal: false })}
					loading={this.state.actionLoading}
				>
					<BringModalToFront />
					<Modal.Section>
						<p>Är du säker på att du vill skapa en faktura ifrån denna ordern?</p>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxOrderView);
