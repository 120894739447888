import React, { Component } from "react";
import {
	Modal,
	Banner,
	Heading,
	Subheading,
	ProgressBar,
	Icon,
	Checkbox,
	Select,
	TextField,
	FormLayout,
	Stack,
	Tooltip,
	InlineError,
	Button,
} from "@shopify/polaris";
import { ArrowRightMinor, CancelSmallMinor, CircleAlertMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import SearchField from "src/js/components/search_field";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import SniSelector from "../../../components/SniSelector.js";
import { store } from "../../../store/index.js";
import UsagePackageModal from "../../../components/UsagePackageModal.js";
import InfoView from "../../../components/InfoView";
import NumberBadge from "../../../components/NumberBadge";
import Colors from "../../../Colors";
import ImportModal from "./ImportModal";
import ModalOptionBox from "../../../components/ModalOptionBox";
import { BoardIcon } from "../../../icons";

class ImportCompanyBoardModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			blocked: false,
			skipall: false,
			skipmine: false,
			loading: false,
			limits: null,
			currentImport: null,
			open: props.open,
			step: 0,
			title: "",
			sni: "",
			query: "",
			turnover: "",
			employees: "",
			result: null,
			mappings: {},
			region: null,
			city: null,
			template_board: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({
				loading: false,
				blocked: false,
				open: props.open,
				step: props.board ? 1 : 0,
				currentImport: null,
				title: "",
				sni: "",
				query: "",
				turnover: "",
				employees: "",
				result: null,
				selectedOption: null,
				template_board: null,
				mappings: {
					phone: this.getColumnByName("Telefon", "phone"),
					email: this.getColumnByName("E-post", "text"),
					contact: this.getColumnByName("Kontaktperson", "text"),
					website: this.getColumnByName("Hemsida", "text"),
					company_number: this.getColumnsByTypes(["company_number"]).length > 0 ? this.getColumnsByTypes(["company_number"])[0].id + "" : null,
					street: this.getColumnByName("Gatuadress", "text"),
					zip: this.getColumnByName("Postnummer", "text"),
					city: this.getColumnByName("Ort", "text"),
					bransch: this.getColumnByName("Bransch", "text"),
					num_employees: this.getColumnByName("Antal anställda", "text"),
				},
			});

			this.fetchLimits();
		}
	}

	fetchLimits() {
		API.get("/api/account/limits.json", {})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ limits: result.data.limits });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getColumnByName(title, type) {
		const board = this.props.board || this.state.template_board;
		if (!board) {
			return null;
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (board.columns[i].title == title && board.columns[i].type == type) {
				return board.columns[i].id + "";
			}
		}
		return null;
	}

	createParams() {
		const params = {};

		if (this.state.title) {
			params.title = this.state.title;
		}
		if (this.state.query) {
			params.q = this.state.query;
		}
		if (this.state.sni) {
			params.sni = this.state.sni;
		}
		if (this.state.turnover) {
			params.turnover = this.state.turnover;
		}
		if (this.state.employees) {
			params.employees = this.state.employees;
		}

		if (this.state.skipmine) {
			params.skip_mine = 1;
		}
		if (this.state.skipall) {
			params.skip_all = 1;
		}
		if (this.state.region) {
			params.region = this.state.region.replace("s län", "").replace(" län", "").toLowerCase();
		}
		if (this.state.city) {
			params.city = this.state.city.toLowerCase();
		}
		params.hit_count = this.state.result?.hitCount;
		return params;
	}

	search() {
		this.setState({ loading: true });
		const params = this.createParams();

		API.get("/api/companies.json", { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}
				this.setState({ loading: false, result: result.data });
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	getColumns() {
		return [
			{ title: this.props.t("board.terms.phone.label", "Telefon"), example: this.props.t("board.terms.phone.example", "0742-3331222") },
			{ title: this.props.t("board.terms.email.label", "E-post"), example: this.props.t("board.terms.email.example", "info@itbmedia.se") },
			{
				title: this.props.t("board.terms.concat_person.label", "Kontaktperson"),
				example: this.props.t("board.terms.concat_person.example", "Lukas Sjöberg (VD)"),
			},
			{ title: this.props.t("board.terms.url.label", "Hemsida"), example: this.props.t("board.terms.url.example", "www.itbmedia.se") },
			{ title: this.props.t("board.terms.orgnr.label", "Organisationsnummer"), example: this.props.t("board.terms.orgnr.example", "556084-7674") },
			{ title: this.props.t("board.terms.address.label", "Gatuadress"), example: this.props.t("board.terms.address.example", "Kantyxegatan 23") },
			{ title: this.props.t("board.terms.postal_number.label", "Postnummer"), example: this.props.t("board.terms.postal_number.example", "21376") },
			{ title: this.props.t("board.terms.county.label", "Ort"), example: this.props.t("board.terms.county.example", "MALMÖ") },
			{
				title: this.props.t("board.terms.branch.label", "Bransch"),
				example: this.props.t("board.terms.branch.example", "Utgivare av annan programvara"),
			},
			{
				title: this.props.t("board.terms.emplyees_count.label", "Antal anställa"),
				example: this.props.t("board.terms.emplyees_count.example", "20-49 anställda"),
			},
		];
	}

	import() {
		if (this.props.board || this.state.template_board) {
			this.setState({ step: 2 });
			return;
		}
		this.setState({ loading: true });
		const params = this.createParams();

		API.post("/api/companies/import_workspace/" + this.props.workspace.id + ".json", {}, { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState(
					{ currentImport: result.data.roaring_import_log, board: result.data.board, workspace: this.props.workspace },
					this.importNext.bind(this)
				);
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	importWithTemplate() {
		this.setState({ loading: true });
		const params = this.createParams();
		params.template_board_id = this.state.template_board.id;
		const data = {
			mappings: this.state.mappings,
		};

		API.post("/api/companies/import_workspace/" + this.props.workspace.id + ".json", data, { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState(
					{ currentImport: result.data.roaring_import_log, board: result.data.board, workspace: this.props.workspace },
					this.importNext.bind(this)
				);
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	importToBoard() {
		this.setState({ loading: true });
		const params = this.createParams();

		API.post("/api/companies/import_board/" + this.props.board.id + ".json", { mappings: this.state.mappings }, { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState({ currentImport: result.data.roaring_import_log }, this.importNext.bind(this));
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	importNext() {
		if (this.state.currentImport.completed_at) {
			if (this.props.redirect) {
				this.props.history.push(`/admin/workspaces/${this.state.workspace?.id}/boards/${this.state.board?.id}#[]`);
			}
			return;
		}
		API.post("/api/companies/import/" + this.state.currentImport.id + ".json", {}, {})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState(
					{
						currentImport: result.data.roaring_import_log,
						showResult: !!result.data.roaring_import_log.completed_at,
					},
					this.importNext.bind(this)
				);
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.error("error:", error.response.data);
				if (error?.response?.data?.code === 429) this.setState({ error: error?.response?.data?.message });
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	getMapping(index) {
		let n = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.state.mappings) {
			if (n == index) {
				return this.state.mappings[i];
			}
			n++;
		}
		return null;
	}

	updateMapping(index, value) {
		let n = 0;
		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.state.mappings) {
			if (n == index) {
				this.state.mappings[i] = value;
				this.setState({ mappings: this.state.mappings });
				return;
			}
			n++;
		}
	}

	getColumnsByTypes(types) {
		const arr = [];
		const board = this.props.board || this.state.template_board;
		if (!board) {
			return arr;
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (types.includes(board.columns[i].type)) {
				arr.push(board.columns[i]);
			}
		}
		return arr;
	}

	getMappingTargetOptions(index) {
		const column = this.getColumns()[index];
		let columns = [];
		if (column.title == "Organisationsnummer") {
			columns = this.getColumnsByTypes(["company_number"]).map((col) => ({ label: col.title, value: col.id + "" }));
			columns.unshift({ label: this.props.t("board.columns.new_org.label", "Ny organisationsnummerkolumn"), value: "company_number", new: true });
		} else {
			columns = this.getColumnsByTypes(["text", "phone"]).map((col) => ({ label: col.title, value: col.id + "" }));
			columns.unshift({ label: this.props.t("board.columns.new_text.label", "Ny textkolumn"), value: "text", new: true });
		}

		columns.unshift({ label: this.props.t("board.columns.not_imported.label", "--Importeras inte--"), value: "" });

		return columns;
	}

	render() {
		let primaryAction = {
			content: this.props.t("board.terms.next_step", "Nästa steg"),
			disabled: !this.state.title,
			onAction: () => this.setState({ step: 1 }),
		};

		const primaryActionContent = (() => {
			if (this.state.currentImport) {
				return this.props.t(
					"board.terms.importing",
					`Importerar ${this.state.currentImport.current_offset}/${this.state.currentImport.import_count}`
				);
			}

			if (!this.state.result || !this.state.result.hitCount) {
				return this.props.t("board.terms.no_hits", "Inga träffar");
			}

			if ((this.state.result && this.state.result.hitCount) > 100) {
				return this.props.t("board.actions.import_hits_100", "importera 100 av {{counts}} träffar", {
					counts: this.state.result?.hitCount,
				});
			}

			return this.props.t("board.actions.import_hits", "importera {{counts}} träffar", {
				counts: this.state.result?.hitCount,
			});
		})();

		if (this.state.step == 1) {
			if (!this.state.result) {
				primaryAction = {
					content: this.props.t("board.actions.get_list", "Hämta lista"),
					loading: this.state.loading,
					onAction: this.search.bind(this),
				};
			} else {
				primaryAction = {
					content: primaryActionContent,
					loading: this.state.loading,
					onAction: this.import.bind(this),
					disabled: !this.state.result || !this.state.result.hitCount,
				};
			}
		}
		if (this.state.step == 2) {
			primaryAction = {
				content: primaryActionContent,
				loading: this.state.loading || this.state.currentImport,
				disabled: !this.state.mappings.company_number || !this.state.result || !this.state.result.hitCount || this.state.currentImport,
				onAction: this.state.template_board ? this.importWithTemplate.bind(this) : this.importToBoard.bind(this),
			};
		}

		const getUniqueListBy = (arr, key) => [...new Map(arr.map((item) => [item[key], item])).values()];

		const content = (() => {
			if (!this.state.selectedOption) {
				const bolagsImportEnabled = store.getState().user.roles.includes("ROLE_BOLAGS_IMPORT");
				return (
					<Modal.Section>
						<div className="boxes_wrapper">
							<ModalOptionBox title="Excel" icon={<img alt="excel" src="/assets/Excel.png" />}>
								<Button onClick={() => this.setState({ selectedOption: "excel" })}>Excel</Button>
							</ModalOptionBox>
							<ModalOptionBox disabled={!bolagsImportEnabled} title="Bolags import" icon={<BoardIcon />}>
								{!bolagsImportEnabled && (
									<p style={{ textAlign: "center", color: "var(--textColor2)", fontSize: "0.9em", marginBottom: "0.35em" }}>
										{this.props.t("licenses.errors.missing_role_or_license", "Saknar licens eller licens role")}
									</p>
								)}
								<Button disabled={!bolagsImportEnabled} onClick={() => this.setState({ selectedOption: "roaring" })}>
									Bolags import
								</Button>
							</ModalOptionBox>
						</div>
					</Modal.Section>
				);
			}

			if (!this.state.template_board && this.state.template_board !== false && !this.props.board) {
				return (
					<Modal.Section>
						<FormLayout>
							<Subheading>{this.props.t("import.fields.template_board.label", "Vill ni använda en egen ringlista mall?")}</Subheading>
							<div style={{ display: "flex", justifyContent: "space-between", gap: 16 }}>
								<SearchField
									style={{ flex: 1 }}
									selected={[this.state.template_board?.id]}
									placeholder={`${this.props.t("board.singular", "Board")}..`}
									keepValue
									value={this.state.template_board?.title}
									resource="boards.json"
									params={{
										is_template: 1,
										view_type: "call_list",
									}}
									onSelect={(board) => {
										this.setState({ template_board: board });
									}}
									id_handle="id"
									label_handle="title"
									resource_handle="boards"
									resourceName={{
										singular: this.props.t("board.singular", "board"),
										plural: this.props.t("board.plural", "boards"),
									}}
									autoFocus
								/>
								<Button
									onClick={() => {
										this.setState({ template_board: false });
									}}
								>
									{this.props.t("import.fields.template_board.actions.use_default_template", "Använd standard mall")}
								</Button>
							</div>
						</FormLayout>
					</Modal.Section>
				);
			}

			return (
				<>
					{this.state.currentImport ? (
						<Modal.Section>
							<ProgressBar
								progress={Math.round((this.state.currentImport.current_offset / this.state.currentImport.import_count) * 100)}
								size="large"
							/>
							<div>
								{this.state.currentImport.current_offset} / {this.state.currentImport.import_count}
							</div>

							{this.state.error && <InlineError message={this.state.error} />}

							{this.state.showResult && this.state.currentImport && (
								<div className="infoview_result_count">
									<InfoView
										details={[
											{
												label: this.props.t("import.details.created.label", "Importerade"),
												component: (
													<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
														<NumberBadge color={Colors.green}> {this.state.currentImport.quantity || 0}</NumberBadge>
													</div>
												),
											},
											{
												label: this.props.t("import.details.skipped.label", "Skippade"),
												component: (
													<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
														<NumberBadge color={Colors.blue}> {this.state.currentImport.skipped_quantity || 0}</NumberBadge>{" "}
													</div>
												),
											},
										]}
									/>
								</div>
							)}
						</Modal.Section>
					) : null}
					{this.state.blocked ? (
						<Modal.Section>
							<Banner
								title={this.props.t("board.modals.blocked.title", "Alla bolag importerades ej")}
								action={{
									content: this.props.t("board.actions.upgrade_package", "Uppgradera paket"),
									onAction: () => {
										this.setState({ showPackageModal: true, type: "companies" });
									},
								}}
								status="warning"
							>
								<p>
									{this.props.t("board.modals.blocked.text", "Alla bolag importerades ej, ert konto stödjer högst {{total}}", {
										total: this.state.limits?.company_import_limit || "-",
									})}
								</p>
							</Banner>
							<UsagePackageModal
								open={this.state.showPackageModal}
								onChange={() => {
									this.setState({ blocked: false });
									this.fetchLimits();
								}}
								onClose={() => {
									this.setState({ showPackageModal: false });
								}}
								type={this.state.type}
								limits={this.state.limits}
							/>
						</Modal.Section>
					) : null}
					{!this.state.blocked && !this.state.currentImport ? (
						<Modal.Section>
							{this.state.step == 0 ? (
								<FormLayout>
									<TextField
										label={this.props.t("board.calllist.fields.name.label", "Namn för lista")}
										placeholder={this.props.t("board.calllist.fields.name.placeholder", "Namn..")}
										value={this.state.title}
										onChange={(v) => {
											this.setState({ title: v });
										}}
									/>
								</FormLayout>
							) : null}
							{this.state.step == 1 ? (
								<FormLayout>
									<TextField
										label={this.props.t("board.calllist.fields.search_word.label", "Sökord")}
										value={this.state.query}
										placeholder="Sökord.."
										onChange={(v) => {
											this.setState({ query: v }, () => {
												this.setState({ result: null });
											});
										}}
									/>
									<SniSelector
										label=""
										// label={this.props.t("board.calllist.fields.sni_code.label", "Avgränsa SNI-kod")}
										onChange={(sni) => {
											this.setState({ sni: sni.id }, () => {
												this.setState({ result: null });
											});
										}}
									/>
									<Stack spacing="extraLoose">
										<Stack vertical>
											<Select
												label={this.props.t("board.calllist.fields.oms.label", "Omsättning")}
												value={this.state.turnover}
												onChange={(v) => {
													this.setState({ turnover: v }, () => {
														this.setState({ result: null });
													});
												}}
												options={[
													{ value: "", label: this.props.t("board.calllist.fields.oms.options.empty", "-- kvittar --") },
													{ value: ":0", label: this.props.t("board.calllist.fields.oms.options.less_1000", "Mindre än 1tkr") },
													{ value: "1:499", label: this.props.t("board.calllist.fields.oms.options.499", "1 - 499tkr") },
													{ value: "500:999", label: this.props.t("board.calllist.fields.oms.options.999", "500 - 999tkr") },
													{ value: "1000:4999", label: this.props.t("board.calllist.fields.oms.options.4999", "1000 - 4999tkr") },
													{ value: "5000:9999", label: this.props.t("board.calllist.fields.oms.options.9999", "5000 - 9999tkr") },
													{ value: "10000:49999", label: this.props.t("board.calllist.fields.oms.options.49999", "10000 - 49999tkr") },
													{ value: "50000:99999", label: this.props.t("board.calllist.fields.oms.options.99999", "50000 - 99999tkr") },
													{ value: "100000:499999", label: this.props.t("board.calllist.fields.oms.options.499999", "100000 - 499999tkr") },
													{ value: "500000:999999", label: this.props.t("board.calllist.fields.oms.options.999999", "500000 - 999999tkr") },
													{ value: "1000000:4999999", label: this.props.t("board.calllist.fields.oms.options.4999999", "1000000 - 4999999tkr") },
													{ value: "5000000:9999999", label: this.props.t("board.calllist.fields.oms.options.9999999", "5000000 - 9999999tkr") },
													{ value: "10000000:", label: this.props.t("board.calllist.fields.oms.options.more_10000000", "Mer än 10000000tkr") },
												]}
											/>
											<Select
												label={this.props.t("board.calllist.fields.employees_count.label", "Antal anställda")}
												value={this.state.employees}
												onChange={(v) => {
													this.setState({ employees: v }, () => {
														this.setState({ result: null });
													});
												}}
												options={[
													{ value: "", label: this.props.t("board.calllist.fields.employees.options.empty", "--kvittar--") },
													{ value: "0", label: this.props.t("board.calllist.fields.employees.options.0", "Inga anställda") },
													{ value: "1-4", label: this.props.t("board.calllist.fields.employees.options.4", "1-4 anställda") },
													{ value: "5-9", label: this.props.t("board.calllist.fields.employees.options.9", "5-9 anställda") },
													{ value: "10-19", label: this.props.t("board.calllist.fields.employees.options.19", "10-19 anställda") },
													{ value: "20-49", label: this.props.t("board.calllist.fields.employees.options.49", "20-49 anställda") },
													{ value: "50-99", label: this.props.t("board.calllist.fields.employees.options.99", "50-99 anställda") },
													{ value: "100-199", label: this.props.t("board.calllist.fields.employees.options.199", "100-199 anställda") },
													{ value: "200-999", label: this.props.t("board.calllist.fields.employees.options.999", "200-999 anställda") },
													{ value: "1000-", label: this.props.t("board.calllist.fields.employees.options.1000", "1000+ anställda") },
												]}
											/>
										</Stack>
										<Stack vertical>
											<Select
												label={this.props.t("board.calllist.fields.county.label", "Län")}
												value={this.state.region}
												onChange={(v) => {
													this.setState({ region: v, city: "" }, () => {
														this.setState({ result: null });
													});
												}}
												options={[{ label: this.props.t("board.calllist.fields.county.options.empty", "--kvittar--"), value: "" }].concat(
													getUniqueListBy(
														store.getState().cities.map((c) => ({
															label: c.region_title,
															value: c.region_title,
														})),
														"value"
													).filter((c) => c.value)
												)}
											/>
											<Select
												label={this.props.t("board.calllist.fields.county2.label", "Ort")}
												value={this.state.city || "-"}
												onChange={(v) => {
													this.setState({ city: v }, () => {
														this.setState({ result: null });
													});
												}}
												options={[{ label: this.props.t("board.calllist.fields.county2.options.empty", "--kvittar--"), value: "" }].concat(
													getUniqueListBy(
														store
															.getState()
															.cities.filter((c) => {
																if (!this.state.region) return true;
																return c.region_title === this.state.region;
															})
															.map((c) => ({
																label: c.title,
																value: c.title,
															})),
														"value"
													)
												)}
											/>
										</Stack>
									</Stack>
									<Stack vertical spacing="tight">
										<Checkbox
											label={this.props.t("board.calllist.fields.skip_my.label", "Hoppa över egna dubletter")}
											checked={this.state.skipmine}
											onChange={(v) => {
												this.setState({ skipmine: !this.state.skipmine, result: null });
											}}
										/>
										<Checkbox
											label={this.props.t("board.calllist.fields.skip_others.label", "Hoppa över andras dubletter")}
											checked={this.state.skipall}
											onChange={(v) => {
												this.setState({ skipall: !this.state.skipall, result: null });
											}}
										/>
									</Stack>
								</FormLayout>
							) : null}
							{this.state.step == 2 ? (
								<FormLayout>
									{this.getColumns().map((column, index) => (
										<Modal.Section key={index}>
											<Stack distribution="fillEvenly">
												<div>
													<Subheading>{column.title}</Subheading>
													{column.example}
												</div>
												<div>
													{this.getMapping(index) ? (
														<Icon source={ArrowRightMinor} color="green" />
													) : column.title === "Organisationsnummer" && !this.state.mappings.company_number ? (
														<Tooltip content={this.props.t("board.calllist.errors.required_field", "Fält måste sättas")}>
															<Icon source={CircleAlertMajor} color="red" />
														</Tooltip>
													) : (
														<Icon source={CancelSmallMinor} color="inkLightest" />
													)}
												</div>
												<div>
													<Select
														options={this.getMappingTargetOptions(index)}
														onChange={this.updateMapping.bind(this, index)}
														value={this.getMapping(index)}
													/>
												</div>
											</Stack>
										</Modal.Section>
									))}
								</FormLayout>
							) : null}
						</Modal.Section>
					) : null}
					{this.state.result && this.state.step == 1 && !this.state.currentImport ? (
						<Modal.Section>
							<Heading>{this.props.t("common.terms.examle", "Exempel")}</Heading>
							{this.state.result?.hits?.map((hit) => (
								<div key={hit.companyId}>{hit.companyName}</div>
							))}
						</Modal.Section>
					) : null}
				</>
			);
		})();

		if (this.state.selectedOption === "excel" && (this.state.template_board === false || this.state.template_board)) {
			return (
				<ImportModal
					open
					template_board={this.state.template_board}
					board_view="call_list"
					type="board"
					workspace={{ id: store.getState().account.sales_workspace_id }}
					board={this.props.board}
					onComplete={(spreadsheet) => {
						if (this.props.redirect && spreadsheet?.options?.board_id) {
							this.props.history.push(`/admin/workspaces/${store.getState().account.sales_workspace_id}/boards/${spreadsheet?.options?.board_id}#[]`);
						}
					}}
					onClose={this.props.onClose}
				/>
			);
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				large={![0, 1].includes(this.state.step * 1)}
				title={
					this.props.board
						? this.props.t("board.actions.import_bolag_to_board", "Importera bolag till board")
						: this.props.t("board.actions.create_call_list_from", "Skapa ringlista från bolagsregister")
				}
				// primaryAction={this.state.currentImport ? null : primaryAction}
				// secondaryActions={[
				// 	{
				// 		content: this.props.t("common.actions.close", "Stäng"),
				// 		onAction: this.props.onClose,
				// 	},
				// ]}
				footer={
					this.state.selectedOption === "roaring" && (
						<div style={{ padding: "2rem" }}>
							{!this.state.currentImport && (
								<Button primary fullWidth disabled={primaryAction.disabled} onClick={primaryAction.onAction} loading={primaryAction.loading}>
									{primaryAction.content}
								</Button>
							)}
						</div>
					)
				}
			>
				{content}
			</Modal>
		);
	}
}
export default withTranslation(["board", "licenses", "import", "common"], { withRef: true })(ImportCompanyBoardModal);
