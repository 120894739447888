import React, { Component } from "react";
import { ActionList, ChoiceList, Icon, Checkbox, Scrollable } from "@shopify/polaris";
import { TickSmallMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import BoardView from "./BoardView.js";
import Accordion from "./components/Accordion.js";
import Sheet from "../../components/sheet";

class WidgetList extends Component {
	constructor(props) {
		super(props);
		this.state = { showSettings: props.showSettings, columns: this.props.widget.options.columns || [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings != this.state.showSettings) {
			this.setState({ showSettings: props.showSettings });
		}
	}

	updateOptions() {
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getBoards() {
		if (this.props.dashboard) {
			return this.props.dashboard.boards || [];
		} else {
			return [this.props.board];
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	calculateHeight() {
		return this.props.rows.length * 36;
	}

	changeBoard(board) {
		this.props.widget.options.board_id = board.id;
		this.updateOptions();
	}

	getCurrentBoard() {
		if (this.getBoards().length > 0) {
			let board = this.getBoards()[0];
			for (let i = 0; i < this.getBoards().length; i++) {
				if (this.getBoards()[i].id == this.props.widget.options.board_id) {
					board = this.getBoards()[i];
					break;
				}
			}
			return board;
		}
		return null;
	}

	render() {
		const currentBoard = this.getCurrentBoard();
		return (
			<div style={this.props.widget.options.full_width ? { paddingLeft: 20, paddingRight: 20 } : { height: "100%", width: "100%", paddingTop: 70 }}>
				<div style={this.props.widget.options.full_width ? { width: "100%" } : { height: "100%", width: "100%", overflow: "auto" }}>
					{this.getBoards().length > 0 ? (
						<BoardView
							containerClass="dashboard-container"
							id={currentBoard && currentBoard.id}
							loading={this.props.loading}
							filters={this.props.filters}
							loaded_board={currentBoard}
							showOnlyColumns={this.state.columns}
							noheader
							nopreview
							loaded_rows={this.props.rows}
							loaded_groups={this.props.groups}
						/>
					) : null}
					<Sheet
						open={this.state.showSettings}
						title={this.props.t("widget.modals.list_settings.title", "Inställningar")}
						onClose={this.props.onCloseSettings}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "calc(100% - 56px)",
							}}
						>
							<Scrollable style={{ height: "100%" }}>
								<div style={{ padding: "1.4rem 2rem" }}>
									<Checkbox
										label={this.props.t("widget.modals.list_settings.fields.full_width.label", "Visa full lista (nedanför dashboard)")}
										checked={this.props.widget.options.full_width}
										onChange={(v) => {
											this.props.widget.options.full_width = v;
											this.updateOptions();
										}}
									/>
								</div>
								<Accordion label={this.props.t("widget.modals.list_settings.fields.board.label", "Board")}>
									<ActionList
										items={this.getBoards().map((board) => ({
											suffix: currentBoard && currentBoard.id == board.id ? <Icon source={TickSmallMinor} /> : null,
											active: currentBoard && currentBoard.id == board.id,
											content: board.title,
											onAction: this.changeBoard.bind(this, board),
										}))}
									/>
								</Accordion>
								<Accordion label={this.props.t("widget.modals.list_settings.fields.columns.label", "Kolumner")}>
									<ChoiceList
										allowMultiple
										title={this.props.t("widget.modals.list_settings.fields.columns.options.title", "Visa endast dessa kolumner")}
										choices={
											currentBoard && currentBoard.columns && currentBoard.columns.map((column) => ({ label: column.title, value: column.id }))
										}
										selected={this.state.columns}
										onChange={(v) => {
											this.setState({ columns: v });
											this.props.widget.options.columns = v;
											this.updateOptions();
										}}
									/>
								</Accordion>
							</Scrollable>
						</div>
					</Sheet>
				</div>
			</div>
		);
	}
}
export default withTranslation(["board", "widget", "common"], { withRef: true })(WidgetList);
