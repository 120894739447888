import React from "react";
import { StyledSettings, StyledSettingsInnerCard } from "./styles";
import SettingsItem from "./SettingsItem";
import { Card } from "@shopify/polaris";

type SectionItem = {
	onAction?: () => void;
	disabled?: boolean;
	backgroundColor?: string;
	icon?: any;
	title?: string;
	description?: string;
	path?: string;
	tooltip?:
		| string
		| {
				content: string;
				icon?: any;
		  };
	loading?: boolean;

	confirmation?: any;
	confirm?: any;
};

type Section = {
	title?: string;
	items: SectionItem[];
};

type SettingsProps = {
	sections: Section[];
};

const Settings = ({ sections }: SettingsProps) => {
	return (
		<StyledSettings>
			{sections
				?.filter((s) => s.title || s.items?.length)
				.map((section, key) => {
					return (
						<Card key={key} title={section.title}>
							<StyledSettingsInnerCard>
								{section?.items.map((item: any, key) => {
									return <SettingsItem key={key} item={item} />;
								})}
							</StyledSettingsInnerCard>
						</Card>
					);
				})}
		</StyledSettings>
	);
};
export default Settings;
