import { Icon } from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import styled from "styled-components";

class YoutubeVideoAdPreviewSmall extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { imageUrl, title, description, callToAction, profileImageUrl } = this.props;
		return (
			<Wrapper>
				<Image src={imageUrl} />
				<TextWrapper>
					<ProfileImage src={profileImageUrl} />
					<div>
						<Title>{title}</Title>
						<Description>{description}</Description>
					</div>
					<DotsButton>
						<Icon source={MobileVerticalDotsMajor} />
					</DotsButton>
				</TextWrapper>
				{callToAction && <CallToActionButton>{callToAction}</CallToActionButton>}{" "}
			</Wrapper>
		);
	}
}
export default YoutubeVideoAdPreviewSmall;

const Wrapper = styled.div`
	position: relative;
	width: 275px;
	background: #ffffff;
	border-radius: 0.5rem;
`;

const Image = styled.img`
	max-width: 100%;
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	position: relative;
`;
const CallToActionButton = styled.div`
	padding: 1rem 1.5rem;
	color: #ffffff;
	background: rgb(41, 94, 205);
	font-size: 12px;
	border-radius: 0.5rem;
	text-align: center;
	width: 100%;
	margin-top: 1rem;
`;

const ProfileImage = styled.img`
	width: 40px;
	height: 40px;
	object-fit: contain;
	background-color: #ffffff;
	border-radius: 50%;
`;

const Title = styled.p`
	font-weight: 600;
	font-size: 12px;
`;
const Description = styled.p`
	font-size: 10px;
`;
const DotsButton = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;

	.Polaris-Icon {
		margin: 0;
		height: 1.5rem;
		width: 1.5rem;
		fill: grey;
	}
`;
