/* eslint-disable quotes */
/* eslint-disable react/style-prop-object */
import React from "react";
import { EditorState, convertFromHTML, ContentState, RichUtils, getDefaultKeyBinding } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Editor, { composeDecorators } from "draft-js-plugins-editor";
import { ButtonGroup, Modal, Icon, ActionList, TextField, Stack } from "@shopify/polaris";
import { indent } from "indent.js";
import createImagePlugin from "draft-js-image-plugin";
import createFocusPlugin from "draft-js-focus-plugin";

import { ChevronDownMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import UploadModal from "./UploadModal";

import "./rte.scss";
import Popover from "./Popover";
import RTEStyleButton from "./RTEStyleButton";

// Custom overrides for "code" style.
const styleMap = {
	CODE: {
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		// eslint-disable-next-line quotes
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
	H1: {
		fontSize: 28,
	},
};

function getBlockStyle(block) {
	switch (block.getType()) {
		case "blockquote":
			return "RichEditor-blockquote";
		case "h1":
			return "RichEditor-h1";
		case "h2":
			return "RichEditor-h2";
		case "h3":
			return "RichEditor-h3";
		case "h4":
			return "RichEditor-h4";
		case "h5":
			return "RichEditor-h5";
		case "h6":
			return "RichEditor-h6";
		default:
			return null;
	}
}

class RTE extends React.Component {
	constructor(props) {
		super(props);
		const contentState = this.convertFromHTML(props.value || "");

		this.focusPlugin = createFocusPlugin();

		this.decorator = composeDecorators(this.focusPlugin.decorator);

		const editorState = EditorState.createWithContent(contentState);
		this.state = {
			wordcount: this.getWordCount(editorState),
			paragraphActive: false,
			id: props.id,
			focused: false,
			html: props.value || "",
			editorState,
		};

		this.focus = () => this.editor.focus();
		this.onChange = (editorState) => {
			this.setState({ editorState });
			const html = this.convertToHTML(editorState.getCurrentContent());
			this.setState({ html, wordcount: this.getWordCount(editorState) });
			this.props.onChange(html);
		};

		this.handleKeyCommand = this.handleKeyCommand.bind(this);
		this.mapKeyToEditorCommand = this.mapKeyToEditorCommand.bind(this);
		this.toggleBlockType = this.toggleBlockType.bind(this);
		this.toggleInlineStyle = this.toggleInlineStyle.bind(this);
	}

	getWordCount(editorState) {
		const plainText = editorState.getCurrentContent().getPlainText("");
		const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
		const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
		const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
		return wordArray ? wordArray.length : 0;
	}

	findLinkEntities(contentBlock, callback, contentState) {
		contentBlock.findEntityRanges((character) => {
			const entityKey = character.getEntity();
			return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
		}, callback);
	}

	findImageEntities(contentBlock, callback, contentState) {
		contentBlock.findEntityRanges((character) => {
			const entityKey = character.getEntity();
			return entityKey !== null && contentState.getEntity(entityKey).getType() === "IMAGE";
		}, callback);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.state.id != props.id) {
			const contentState = this.convertFromHTML(props.value || "");

			this.state.id = props.id;
			this.state.editorState = EditorState.createWithContent(contentState);
			this.state.wordcount = this.getWordCount(this.state.editorState);
			this.setState(this.state);
		}
	}

	handleKeyCommand(command, editorState) {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	}

	openUploadModal() {
		this.setState({ uploadModalOpen: true });
	}

	closeUploadModal() {
		this.setState({ uploadModalOpen: false });
	}

	mapKeyToEditorCommand(e) {
		switch (e.keyCode) {
			case 9: {
				// TAB
				const newEditorState = RichUtils.onTab(e, this.state.editorState, 4 /* maxDepth */);
				if (newEditorState !== this.state.editorState) {
					this.onChange(newEditorState);
				}
				return;
			}
			default:
				return getDefaultKeyBinding(e);
		}
	}

	toggleBlockType(blockType) {
		this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
	}

	toggleInlineStyle(inlineStyle) {
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
	}

	onFocus() {
		this.setState({ focused: true });
	}

	onBlur() {
		this.setState({ focused: false });
	}

	setParagraph(style) {
		this.toggleBlockType(style);
		this.setState({ paragraphActive: false });
	}

	addLink(d) {
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		if (!selection.isCollapsed()) {
			const contentState = editorState.getCurrentContent();
			const startKey = editorState.getSelection().getStartKey();
			const startOffset = editorState.getSelection().getStartOffset();
			const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
			const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

			let url = "";
			if (linkKey) {
				const linkInstance = contentState.getEntity(linkKey);
				url = linkInstance.getData().url;
			}

			this.setState({
				showLinkModal: true,
				currentUrl: url,
				allowRemoveLink: !!url,
			});
		}
	}

	doAddLink(e) {
		e.preventDefault();
		const { editorState, currentUrl } = this.state;
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { url: currentUrl });
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
		this.setState(
			{
				editorState: RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey),
				showLinkModal: false,
				currentUrl: "",
			},
			() => {
				this.onChange(this.state.editorState);
			}
		);
	}

	doRemoveLink(e) {
		e.preventDefault();
		const { editorState } = this.state;
		const selection = editorState.getSelection();
		if (!selection.isCollapsed()) {
			this.setState(
				{
					editorState: RichUtils.toggleLink(editorState, selection, null),
					showLinkModal: false,
				},
				() => {
					this.onChange(this.state.editorState);
				}
			);
		}
	}

	addFiles(uploads) {
		for (let i = 0; i < uploads.length; i++) {
			const upload = uploads[i];
			if (upload.type.substr(0, 6) == "image/") {
				this.state.html = this.state.html + '<p><img src="' + upload.url + '" alt="' + upload.filename + '" /></p>';
			} else {
				this.state.html = this.state.html + '<p><a href="' + upload.url + '" target="_blank">' + upload.filename + "</a></p>";
			}
		}
		const contentState = this.convertFromHTML(this.state.html || "");
		this.setState({ html: this.state.html, editorState: EditorState.createWithContent(contentState) }, () => {
			this.onChange(this.state.editorState);
		});
	}

	convertFromHTML(html) {
		const blocksFromHTML = convertFromHTML(html);
		const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
		return state;
	}

	convertToHTML(state) {
		return stateToHTML(state);
	}

	toggleHtmlView() {
		if (this.state.viewHtml) {
			const contentState = this.convertFromHTML(this.state.html || "");
			this.state.editorState = EditorState.createWithContent(contentState);
			const html = this.convertToHTML(this.state.editorState.getCurrentContent());
			this.setState({ html });
			this.props.onChange(html);
			this.setState({ editorState: this.state.editorState });
		} else {
			this.state.html = indent.html(this.state.html);
			this.setState({ html: this.state.html });
		}
		this.setState({ viewHtml: !this.state.viewHtml });
	}

	render() {
		const { editorState } = this.state;

		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = "Polaris-TextField Polaris-TextField--hasValue";

		if (this.state.focused) {
			className += " Polaris-TextField--focus";
		}

		const currentStyle = editorState.getCurrentInlineStyle();

		const selection = editorState.getSelection();
		const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

		let isLink = false;

		const contentState = editorState.getCurrentContent();
		const blockWithLinkAtBeginning = contentState.getBlockForKey(selection.getStartKey());
		const linkKey = blockWithLinkAtBeginning.getEntityAt(selection.getStartOffset());
		if (linkKey) {
			isLink = true;
		}

		const imagePlugin = createImagePlugin({ decorator: this.decorator });

		const linkModalSecondaryActions = [
			{
				content: this.props.t("common.actions.close", "Stäng"),
				onAction: () => {
					this.setState({ showLinkModal: false });
				},
			},
		];

		if (this.state.allowRemoveLink) {
			linkModalSecondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.doRemoveLink.bind(this),
			});
		}

		return (
			<React.Fragment>
				{this.props.label ? (
					<div className="Polaris-Labelled__LabelWrapper">
						<div className="Polaris-Label">
							<label className="Polaris-Label__Text">{this.props.label}</label>
						</div>
					</div>
				) : null}
				<div className={"RichEditor-root" + (this.props.fullHeight ? " fullheight" : "")}>
					<div className="toolbar">
						<div className="RichEditor-controls">
							<Stack>
								<ButtonGroup segmented>
									<RTEStyleButton key="HTML" active={this.state.viewHtml} onToggle={this.toggleHtmlView.bind(this)} label="View HTML" style="HTML" />
								</ButtonGroup>
								{!this.state.viewHtml ? (
									<ButtonGroup segmented>
										<Popover
											active={this.state.paragraphActive}
											activator={
												<span
													className={"Polaris-Button Polaris-Button--sizeSlim" + (this.state.paragraphActive ? " Polaris-Button--outline" : "")}
													onMouseDown={(e) => {
														e.preventDefault();
														this.setState({ paragraphActive: !this.state.paragraphActive });
													}}
												>
													<Stack>
														<div style={{ width: 12, height: 12 }}>
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 3 12 12" enableBackground="new -1 3 12 12">
																<title>rte-formatting</title>
																<desc>Created with Sketch.</desc>
																<path d="M10.9 13.2c-.1 0-.3 0-.5-.1-.2 0-.3-.1-.4-.2-.2-.1-.3-.2-.4-.3-.1-.1-.2-.3-.2-.4l-3.9-9.1v-.1h-.8v.1c-.6 1.3-1.2 2.8-1.9 4.4-.7 1.7-1.4 3.2-1.9 4.5l-.3.6c-.1.1-.3.3-.5.4-.1 0-.3.1-.5.1s-.4.1-.5.1h-.1v.8h4.6v-.8h-.1c-.4 0-.8-.1-1.1-.2-.3-.1-.4-.2-.4-.3v-.3c0-.1.1-.3.1-.5l.3-.7c.1-.2.3-.8.5-1.2h3.5l1 2.6v.2s0 .1-.3.1c-.3.1-.6.1-1 .1h-.1v1h5v-.8h-.1zm-5-4.2h-2.7l1.3-3.2 1.4 3.2z" />
															</svg>
														</div>
														<div style={{ width: 12, height: 12 }}>
															<Icon source={ChevronDownMinor} />
														</div>
													</Stack>
												</span>
											}
											onClose={() => {
												this.setState({ paragraphActive: false });
											}}
										>
											<ActionList
												items={[
													{
														content: this.props.t("common.rte.paragraph.paragraph", "Paragraph"),
														onAction: this.setParagraph.bind(this, "paragraph"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-one", "Heading 1"),
														onAction: this.setParagraph.bind(this, "header-one"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-two", "Heading 2"),
														onAction: this.setParagraph.bind(this, "header-two"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-three", "Heading 3"),
														onAction: this.setParagraph.bind(this, "header-three"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-four", "Heading 4"),
														onAction: this.setParagraph.bind(this, "header-four"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-five", "Heading 5"),
														onAction: this.setParagraph.bind(this, "header-five"),
													},
													{
														content: this.props.t("common.rte.paragraph.header-six", "Heading 6"),
														onAction: this.setParagraph.bind(this, "header-six"),
													},
												]}
											/>
										</Popover>
										<RTEStyleButton
											key="BOLD"
											active={currentStyle.has("BOLD")}
											label={this.props.t("common.rte.fields.bold.label", "Bold")}
											onToggle={this.toggleInlineStyle}
											style="BOLD"
										/>
										<RTEStyleButton
											key="ITALIC"
											active={currentStyle.has("ITALIC")}
											label={this.props.t("common.rte.fields.italic.label", "Italic")}
											onToggle={this.toggleInlineStyle}
											style="ITALIC"
										/>
										<RTEStyleButton
											key="UNDERLINE"
											active={currentStyle.has("UNDERLINE")}
											label={this.props.t("common.rte.fields.underline.label", "Underline")}
											onToggle={this.toggleInlineStyle}
											style="UNDERLINE"
										/>
									</ButtonGroup>
								) : null}

								{!this.state.viewHtml ? (
									<ButtonGroup segmented>
										<RTEStyleButton
											key="unordered-list-item"
											active={blockType === "unordered-list-item"}
											label={this.props.t("common.rte.fields.bulleted_list.label", "Bulleted list")}
											onToggle={this.toggleBlockType.bind(this, "unordered-list-item")}
											style="unordered-list-item"
										/>
										<RTEStyleButton
											key="ordered-list-item"
											active={blockType === "ordered-list-item"}
											label={this.props.t("common.rte.fields.numbered_list.label", "Numbered list")}
											onToggle={this.toggleBlockType.bind(this, "ordered-list-item")}
											style="ordered-list-item"
										/>
									</ButtonGroup>
								) : null}

								{!this.state.viewHtml ? (
									<ButtonGroup segmented>
										<RTEStyleButton
											key="LINK"
											active={isLink}
											disabled={selection.isCollapsed()}
											label={this.props.t("common.rte.fields.link.label", "Link")}
											onToggle={this.addLink.bind(this)}
											style="LINK"
										/>
										<RTEStyleButton
											key="IMAGE"
											label={this.props.t("common.rte.fields.image.label", "Image")}
											onToggle={this.openUploadModal.bind(this)}
											style="image"
										/>
									</ButtonGroup>
								) : null}
							</Stack>
						</div>
					</div>
					{!this.state.viewHtml ? (
						<div className={className} onClick={this.focus}>
							<Editor
								readOnly={this.props.readOnly}
								className="Polaris-TextField__Input"
								blockStyleFn={getBlockStyle}
								customStyleMap={styleMap}
								editorState={editorState}
								handleKeyCommand={this.handleKeyCommand}
								keyBindingFn={this.mapKeyToEditorCommand}
								onFocus={this.onFocus.bind(this)}
								onBlur={this.onBlur.bind(this)}
								decorators={[
									{
										strategy: this.findLinkEntities,
										component: (props) => {
											const { url } = props.contentState.getEntity(props.entityKey).getData();
											return <a href={url}>{props.children}</a>;
										},
									},
									{
										strategy: this.findImageEntities,
										component: (props) => {
											const { height, src, width } = props.contentState.getEntity(props.entityKey).getData();

											return <img alt="" src={src} height={height} width={width} />;
										},
									},
								]}
								onChange={this.onChange}
								plugins={[imagePlugin, this.focusPlugin]}
								ref={(ref) => {
									this.editor = ref;
								}}
								spellCheck={false}
								placeholder={this.props.placeholder}
							/>
							<div className="Polaris-TextField__Backdrop" />
						</div>
					) : null}
					{this.state.viewHtml ? (
						<div className="rte-html-view">
							<TextField
								value={this.state.html}
								onChange={(html) => {
									this.setState({ html });
								}}
								readOnly={this.props.readOnly}
								multiline
							/>
						</div>
					) : null}
				</div>
				{!this.state.viewHtml && !this.props.skipWordCount ? <div className="Polaris-Labelled__HelpText">{this.state.wordcount} ord</div> : null}
				<Modal
					open={this.state.showLinkModal}
					title={this.props.t("common.rte.terms.link", "Länk")}
					onClose={() => {
						this.setState({ showLinkModal: false });
					}}
					secondaryActions={linkModalSecondaryActions}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						disabled: !this.state.currentUrl,
						onAction: this.doAddLink.bind(this),
					}}
				>
					<Modal.Section>
						<TextField
							label={this.props.t("common.rte.fields.url.label", "URL")}
							value={this.state.currentUrl}
							onChange={(value) => {
								this.setState({ currentUrl: value });
							}}
						/>
					</Modal.Section>
				</Modal>
				<UploadModal
					open={this.state.uploadModalOpen}
					onClose={this.closeUploadModal.bind(this)}
					onSelect={(uploads) => {
						this.addFiles(uploads);
						this.closeUploadModal();
					}}
				/>
			</React.Fragment>
		);
	}
}

export default withTranslation(["common"], {
	withRef: true,
})(RTE);
