import React, { Component } from "react";
import { EmptyState, ExceptionList, Spinner } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import BigData from "../../components/big_data.js";
import { toastr } from "../../components/toastr.js";
import NumberBadge from "../../components/NumberBadge.js";
import StatsBoxWrapper from "../../components/statsBoxes";
import Colors, { colorToRgba } from "../../Colors";
import IconWithBackround from "../../components/IconWithBackround";
import { CheckmarkWithBottomIcon, SendIcon, ClickIcon, ThumbsUpIcon, MailIcon, SadFaceIcon } from "../../icons";

class EmailCampaigns extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
			loadingStats: true,
			visibleItems: [],
		};
	}

	gotoCampaign(item) {
		item.path = "/admin/email_campaigns/" + item.id;
		this.props.history.push("/admin/email_campaigns/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("email_campaign.plural", "E-postkampanjer"),
					item: { id: item.id, item, path: "/admin/email_campaigns/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	renderCell(item, column) {
		const StatCount = ({ title, icon }) => (
			<div>
				<div className="CustomerListItem__Profile">
					<h3 className="CustomerListItem__Title email-campaigns-list-status">
						{title ? icon : null}
						{title || "-"}
					</h3>
				</div>
			</div>
		);
		if (column.handle == "TITLE") {
			const exceptions = [];
			if (item.content) {
				exceptions.push({
					icon: "notes",
					description: item.content,
				});
			}

			const exceptionList = exceptions.length ? (
				<div className="CustomerListItem__Exceptions">
					<ExceptionList items={exceptions} />
				</div>
			) : null;
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
					{exceptionList}
				</div>
			);
		}

		if (column.handle == "TYPE") {
			const title = (() => {
				if (item.type === "transactional") return "Transaktion";
				if (item.type === "newsletter") return "Nyhetsbrev";

				return "";
			})();
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title capitalize">{title}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "STATUS") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">
							{item.sent ? <NumberBadge color={Colors.green}>Skickad</NumberBadge> : <NumberBadge color={Colors.red}>Ej skickad</NumberBadge>}
						</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "CREATED_AT") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{moment(item.created_at).locale("sv").calendar()}</h3>
					</div>
				</div>
			);
		}
		if (column.handle == "OPENED") {
			return <StatCount title={item.opened_count} icon={<MailIcon color={Colors.green} />} />;
		}
		if (column.handle == "CLICKED") {
			return <StatCount title={item.clicked_count} icon={<ClickIcon color={Colors.purple} />} />;
		}
		if (column.handle == "SENT") {
			return <StatCount title={item.sent_count} icon={<SendIcon color={Colors.yellow} />} />;
		}
		if (column.handle == "DELIVERED") {
			return <StatCount title={item.delivered_count} icon={<CheckmarkWithBottomIcon color={Colors.blue} />} />;
		}
		if (column.handle == "CONVERSIONS") {
			return <StatCount title={item.conversions_count} icon={<ThumbsUpIcon color={Colors.pink} />} />;
		}
		if (column.handle == "UNSUBSCRIBED") {
			return <StatCount title={item.unsubscribed_count} icon={<SadFaceIcon color={Colors.red} />} />;
		}
	}

	getStats(items) {
		this.setState({ loadingStats: true });
		const ids = items.map((i) => i.id);
		if (!ids || !ids.length) return items;
		API.get("/api/email_campaigns_stats.json", { params: { ids } })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				this.setState({ stats: result.data.stats });

				for (let i = 0; i < items.length; i++) {
					const stat = result.data.stats.find((s) => s.id == items[i].id);

					if (stat) {
						Object.keys(stat).forEach((key) => {
							items[i][key] = stat[key];
						});
					}
				}
				this.setState({ visibleItems: items, loadingStats: false, firstFetch: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/email_campaigns/" + items[i].id;
		}
		if (items && items.length) {
			this.getStats(items);
		}
		this.setState({ visibleItems: items, firstFetch: false });
	}

	render() {
		if (this.state.empty) {
			return (
				<div>
					<EmptyState
						heading={this.props.t("email_campaign.empty.heading", "Inga e-postkampanjer")}
						action={{ content: this.props.t("email_campaign.actions.create", "Skapa e-postkampanj"), url: "/admin/email_campaigns/new" }}
						image="/assets/images/empty_state/Email.png"
					>
						<p>{this.props.t("email_campaign.empty.text", "Du har inte skapat några e-postkampanjer")}</p>
					</EmptyState>
				</div>
			);
		}
		const filters = [
			{
				key: "sent",
				label: this.props.t("email_campaign.filters.sent.label", "Skickad"),
				type: "select",
				options: [
					{ value: "1", label: this.props.t("common.terms.yes", "Ja") },
					{ value: "0", label: this.props.t("common.terms.no", "Nej") },
				],
			},
			{
				key: "type",
				label: this.props.t("email_campaign.filters.type.label", "Typ"),

				type: "select",
				options: [
					{ label: this.props.t("email_campaign.type.options.newsletter.label", "Nyhetsbrev"), value: "newsletter" },
					{ label: this.props.t("email_campaign.type.options.transactional.label", "Transaktionsmail"), value: "transactional" },
				],
			},
		];

		const columns = [
			{ handle: "TITLE", label: this.props.t("email_campaign.columns.title.label", "Namn"), sortable: true, type: "text" },
			{ handle: "TYPE", label: this.props.t("email_campaign.columns.type.label", "Type"), sortable: true, type: "text" },
			{ handle: "STATUS", label: this.props.t("email_campaign.columns.status.label", "Status"), sortable: true, type: "text" },
			{ handle: "OPENED", label: this.props.t("email_campaign.columns.opened.label", "Öppna"), sortable: false, type: "text" },
			{ handle: "SENT", label: this.props.t("email_campaign.columns.semt.label", "Skickade"), sortable: false, type: "text" },
			{ handle: "CLICKED", label: this.props.t("email_campaign.columns.clicked.label", "Klickade"), sortable: false, type: "text" },
			{ handle: "DELIVERED", label: this.props.t("email_campaign.columns.delivered.label", "Levererad"), sortable: false, type: "text" },
			{ handle: "CONVERSIONS", label: this.props.t("email_campaign.columns.conversions.label", "konversioner"), sortable: false, type: "text" },
			{ handle: "UNSUBSCRIBED", label: this.props.t("email_campaign.columns.unsibscribed.label", "unsubscribed"), sortable: false, type: "text" },
			{ handle: "CREATED_AT", label: this.props.t("email_campaign.columns.create_at.label", "Skapad"), sortable: true, type: "text" },
		];

		const getBoxTitle = (key) => {
			if (this.state.loadingStats) return <Spinner size="small" />;
			let sum = 0;
			if (this.state.stats) {
				for (let i = 0; i < this.state.stats.length; i++) {
					if (key in this.state.stats[i]) {
						sum += parseInt(this.state.stats[i][key]);
					}
				}
				return `${sum} st`;
			}
			return "-";
		};

		return (
			<div>
				<BigData
					history={this.props.history}
					extraHeader={
						<StatsBoxWrapper
							// loading={this.state.loadingStats}
							perRow={6}
							// loadingNrOfBoxes={4}
							items={[
								{
									title: getBoxTitle("sent_count"),
									text: this.props.t("email_campaign.stats.sent_count.title", "Skickade"),
									icon: <IconWithBackround icon={<SendIcon color={Colors.yellow2} />} backgroundColor={colorToRgba(Colors.yellow2, 0.2)} />,
									// link: {content:'Skapa målgrupp', onAction:() => {this.props.history.push('/admin/audiences/new', {filters:[{key:'keywords', value:'em_sent'}]});}}
								},
								{
									title: getBoxTitle("delivered_count"),
									text: this.props.t("email_campaign.stats.delivered_count.title", "Levererade"),
									icon: <IconWithBackround icon={<CheckmarkWithBottomIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
									link: {
										onAction: () => {
											this.props.history.push("/admin/audiences/new", { filters: [{ key: "keywords", value: "em_delivered" }] });
										},
									},
								},
								{
									title: getBoxTitle("opened_count"),
									text: this.props.t("email_campaign.stats.opened_count.title", "Öppnade"),
									icon: <IconWithBackround icon={<MailIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />,
									link: {
										content: this.props.t("email_campaign.actions.audience.create", "Skapa målgrupp"),
										onAction: () => {
											this.props.history.push("/admin/audiences/new", { filters: [{ key: "keywords", value: "em_opened" }] });
										},
									},
								},
								{
									title: getBoxTitle("clicked_count"),
									text: this.props.t("email_campaign.stats.clicked_count.title", "Klick"),
									icon: <IconWithBackround icon={<ClickIcon color={Colors.purple} />} backgroundColor={colorToRgba(Colors.purple, 0.2)} />,
									link: {
										content: this.props.t("email_campaign.actions.audience.create", "Skapa målgrupp"),
										onAction: () => {
											this.props.history.push("/admin/audiences/new", { filters: [{ key: "keywords", value: "em_clicked" }] });
										},
									},
								},

								{
									title: getBoxTitle("conversions_count"),
									text: this.props.t("email_campaign.stats.conversions_count.title", "Konverteringar"),
									icon: <IconWithBackround icon={<ThumbsUpIcon color={Colors.pink} />} backgroundColor={colorToRgba(Colors.pink, 0.2)} />,
									link: {
										content: this.props.t("email_campaign.actions.audience.create", "Skapa målgrupp"),
										onAction: () => {
											this.props.history.push("/admin/audiences/new", { filters: [{ key: "keywords", value: "em_converted" }] });
										},
									},
								},
								{
									title: getBoxTitle("unsubscribed_count"),
									text: this.props.t("email_campaign.stats.unsubscribed_count.title", "Avanmälningar"),
									icon: <IconWithBackround icon={<SadFaceIcon color={Colors.red} />} backgroundColor={colorToRgba(Colors.red, 0.2)} />,
									link: {
										content: this.props.t("email_campaign.actions.audience.create", "Skapa målgrupp"),
										onAction: () => {
											this.props.history.push("/admin/audiences/new", { filters: [{ key: "keywords", value: "em_unsubscribed" }] });
										},
									},
								},
							]}
						/>
					}
					title={this.props.t("email_campaign.plural", "E-postkampanjer")}
					primaryAction={{ content: this.props.t("email_campaign.actions.new", "Ny e-postkampanj"), url: "/admin/email_campaigns/new" }}
					savedSearchHandle="email_campaigns"
					resourceUrl="/api/email_campaigns.json"
					resourceHandle="email_campaigns"
					onItemsFetched={this.onItemsFetched.bind(this)}
					resourceName={{
						singular: this.props.t("email_campaign.singular", "E-postkampanj"),
						plural: this.props.t("email_campaign.plural", "E-postkampanjer"),
					}}
					defaultSort="SELLER_DESC"
					renderCell={this.renderCell.bind(this)}
					onClickRow={(item) => {
						this.gotoCampaign(item);
					}}
					columns={columns}
					filters={filters}
					promotedBulkActions={[]}
					bulkActions={[]}
				/>
			</div>
		);
	}
}

export default withTranslation(["email_campaign", "common"], { withRef: true })(EmailCampaigns);
