import React from "react";
import Colors from "src/js/Colors";
import { Icon, Stack } from "@shopify/polaris";
import Truncate from "src/js/components/Truncate";
import { WithTranslation, withTranslation } from "react-i18next";
import { CustomersMajor, LocationMajor } from "@shopify/polaris-icons";
import Moment from "react-moment";
import moment from "moment";
import { store } from "src/js/store";
import { toastr } from "src/js/components/toastr";
import CalendarHelper from "../../Workspaces/components/CalendarModal/CalendarHelper";
import TaskCompleteModal from "../TaskCompleteModal";
import { AgendaTaskRow } from "../StyledComponents";

interface RowType extends DeadlineBoardRowType {
	statusColor: string;
}

interface CalendarRowProps extends WithTranslation {
	row: RowType;
	index: number;
	loading?: boolean;
	onClick?: () => void;
	refresh?: () => void;
}

const CalendarRow = ({ row, index, loading, onClick, t, refresh }: CalendarRowProps) => {
	const location = row.deadline.dateValue.external_event?.location?.displayName;
	const organizer = row.deadline.dateValue && CalendarHelper.getOrganizer(row.deadline.dateValue);
	const requireResponse =
		row.deadline.dateValue && CalendarHelper.isMeeting(row.deadline.dateValue) && !CalendarHelper.haveResponded(row.deadline.dateValue);

	const attendeeContact =
		(row.deadline.dateValue && !CalendarHelper.isOrganizer(row.deadline.dateValue) && organizer) || row.deadline.dateValue?.attendees?.[0];

	return (
		<AgendaTaskRow
			onClick={onClick}
			style={{ background: "var(--cardbg)", minHeight: "50px" }}
			className={`todoItem ${requireResponse && "alert"} todoItem-calendar`}
		>
			<AgendaTaskRow.Checkbox style={{ backgroundColor: row.statusColor }}>
				<AgendaTaskRow.Checkbox.Inner />
			</AgendaTaskRow.Checkbox>

			<AgendaTaskRow.Inner>
				<AgendaTaskRow.Content>
					<AgendaTaskRow.Title>
						<Truncate>
							{row.title} {requireResponse && <p style={{ color: Colors.red }}>{t("calendar.terms.require_response", "Svar krävs")}</p>}
						</Truncate>
					</AgendaTaskRow.Title>

					<AgendaTaskRow.SubTitle>
						{row.board.id != store.getState().user.calendar_board_id && (
							<AgendaTaskRow.BoardLink to={`/admin/workspaces/${row.board.workspace_id || "none"}/boards/${row.board.id}`}>
								{`${row.board.title} - `}
							</AgendaTaskRow.BoardLink>
						)}

						{moment(row.deadline.dateValue?.start).format("HH:mm") !== "00:00" && (
							<AgendaTaskRow.SubText>
								<Moment format="DD/MM @ HH:mm">{row.deadline.dateValue && row.deadline.dateValue.start}</Moment>
								{row.deadline.dateValue && row.deadline.dateValue.start !== row.deadline.dateValue.end && (
									<span>
										{" - "}
										<Moment
											format={`${
												!moment(moment(row.deadline.dateValue.end).format("YYY-MM-DD")).isSame(
													moment(row.deadline.dateValue.start).format("YYY-MM-DD")
												)
													? "DD/MM @"
													: ""
											} HH:mm`}
										>
											{row.deadline.dateValue.end}
										</Moment>
									</span>
								)}
							</AgendaTaskRow.SubText>
						)}
					</AgendaTaskRow.SubTitle>
				</AgendaTaskRow.Content>
				{attendeeContact && (
					<AgendaTaskRow.SubText>
						<Stack spacing="tight">
							<Icon source={CustomersMajor} />
							<span>{attendeeContact?.name || attendeeContact?.email}</span>
						</Stack>
					</AgendaTaskRow.SubText>
				)}

				{location && (
					<AgendaTaskRow.SubText>
						<Stack spacing="tight">
							<Icon source={LocationMajor} />
							<span>{location}</span>
						</Stack>
					</AgendaTaskRow.SubText>
				)}
			</AgendaTaskRow.Inner>
			<AgendaTaskRow.Actions
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{!row?.deadline?.completed && (
					<TaskCompleteModal
						type="row"
						row={row}
						onSuccess={() => {
							refresh?.();
						}}
						onClose={(haveSubmit) => {
							if (haveSubmit) {
								refresh?.();
								toastr.success(t("calendar.terms.completed", "Kalendarieinlägg slutfört"));
							}
						}}
					/>
				)}
			</AgendaTaskRow.Actions>
		</AgendaTaskRow>
	);
};
export default withTranslation(["calendar", "common"], { withRef: true })(CalendarRow);
