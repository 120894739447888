import React, { useState } from "react";
import { Card, Collapsible, Icon } from "@shopify/polaris";
import Badge from "src/js/components/Badge";
import styled, { css } from "styled-components";
import { ChevronDownMinor, EditMajor } from "@shopify/polaris-icons";
import InfoView from "src/js/components/InfoView";
import { WithTranslation, withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import Banner from "src/js/components/banner";
import { getPartyFieldContent, getPartyFieldLabel, getPartyName, getPartyStatus } from "../Utilities";
import ScrivePartyModal from "./ScrivePartyModal";
import { CardAction } from "../styles";
import ScrivePartyProgress from "./ScrivePartyProgress";

type ScrivePartyProps = {
	party: ScrivePartyType;
	document: ScriveDocumentType;
} & WithTranslation;

const ScriveParty = ({ party, document, t }: ScrivePartyProps) => {
	const [open, setOpen] = useState(false);
	const [editModalIsOpen, setEditModalIsOpen] = useState(false);

	const handleStartEdit = () => {
		setEditModalIsOpen(true);
	};

	const name = getPartyName(party);

	const status = getPartyStatus(party);
	const highlight = new URLSearchParams(window.location.search).get("party_id") == party.id;
	return (
		<Wrapper highlight={highlight}>
			<Card
				// primaryFooterAction={{ content: "Redgiera", icon: EditMajor, onAction: handleStartEdit }}
				actions={[
					{
						content: (
							<CardAction open={open}>
								{t("common.actions.show", "Visa") as string} <Icon source={ChevronDownMinor} />
							</CardAction>
						) as unknown as string,
						onAction: () => setOpen((c) => !c),
					},
					{
						content: (
							<CardAction>
								<Icon source={EditMajor} />
								{t("common.actions.edit", "Redigera") as string}
							</CardAction>
						) as unknown as string,
						onAction: handleStartEdit,
						enabled: document?.status === "preparation",
					},
				].filter((f) => !("enabled" in f) || f.enabled)}
				key={party.id}
				title={
					<>
						<b>{name} </b>
						{status?.label && (
							<Badge style={{ marginLeft: 10 }} color={status?.color}>
								{status?.label}
								{!!party.rejected_time && party.rejection_reason && ` med anledning: "${party.rejection_reason}"`}
							</Badge>
						)}

						{party.is_author && (
							<Badge style={{ marginLeft: 10 }} color={Colors.purple}>
								{t("scrive.party.is_author", "Sändare")}
							</Badge>
						)}

						{party.signatory_role === "viewer" && (
							<Badge style={{ marginLeft: 10 }} color={Colors.purple}>
								{t("scrive.party.signatory_role_viewer", "Ej signerare")}
							</Badge>
						)}
					</>
				}
				sectioned
			>
				<Collapsible
					id={party.id}
					open={open}
					transition={{
						duration: "250ms",
					}}
				>
					{!!party.rejected_time && (
						<Banner status="critical" style={{ marginBottom: 10 }} title={<>{t("scrive.party.rejected", "Avvisad")}</>}>
							<p>{party.rejection_reason}</p>
						</Banner>
					)}
					<InfoView
						style={{ marginBottom: 10 }}
						// details={party.fields?.map((field) => {
						// 	return {
						// 		label: field.type,
						// 		value: field.value,
						// 	};
						// })}
						details={Object.values(
							party.fields?.reduce((acc, field) => {
								if (field.type === "name") {
									acc[field.type] = {
										label: acc[field.type]?.label || getPartyFieldLabel({ ...field, order: 0 }),
										value: acc[field.type]?.value ? `${acc[field.type]?.value} ${getPartyFieldContent(field)}` : getPartyFieldContent(field),
									};
									return acc;
								}

								acc[field.type] = {
									label: getPartyFieldLabel(field),
									value: getPartyFieldContent(field),
								};

								return acc;
							}, {})
						)}
					/>
				</Collapsible>

				<ScrivePartyProgress party={party} />
			</Card>

			<ScrivePartyModal party={party} open={editModalIsOpen} onClose={() => setEditModalIsOpen(false)} />
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(ScriveParty);

const Wrapper = styled.div<{
	highlight?: boolean;
}>`
	margin-bottom: 2rem;

	.Polaris-Card {
		${({ highlight }) =>
			highlight &&
			css`
				border: 3px solid ${Colors.lightblue};
			`}
	}
`;
