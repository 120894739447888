import React, { Component } from "react";
import { Popover, Icon, Button, Spinner, ActionList, TextStyle, Stack, TextField, Labelled, Form } from "@shopify/polaris";
import axios from "axios";
import { SearchMajor, CirclePlusMajor, MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import { toastr } from "./toastr.js";
import MyPopover from "./Popover";

class ResourcePicker extends Component {
	constructor(props) {
		super(props);

		const pcs = props.resource.split("/");
		const resourceHandle = pcs[pcs.length - 1];

		const randNum = Math.ceil(Math.random() * 10000001);

		this.state = {
			myId: randNum,
			showSearch: !!props.showJustContent,
			item: props.item,
			value: "",
			cancelToken: null,
			resource: props.resource,
			id_handle: props.id_handle ? props.id_handle : "id",
			default_caption: props.caption || typeof props.caption === "string" ? props.caption : "Select " + props.resource,
			label_handle: props.label_handle ? props.label_handle : "title",
			results: [],
			limit: 10,
			offset: 0,
			resource_handle: props.resource_handle ? props.resource_handle : resourceHandle,
			error: this.props.error,
		};
		this.ref = React.createRef();
		this.handleClickOutside = this.handleClickOutsideBase.bind(this);
		this.handleKeydown = this.handleKeydownBase.bind(this);

		if (props.item) {
			// this.state.value = props.item[this.state.label_handle];
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.state.item != props.item) {
			this.setState({ item: props.item });
		}
		if (this.state.default_caption != props.caption) {
			this.setState({ default_caption: props.caption });
		}
		if (this.state.resource != props.resource) {
			this.setState({ resource: props.resource });
		}
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	showSearch() {
		this.setState({ showSearch: true });
	}

	hideSearch() {
		this.setState({ showSearch: false });
	}

	doSearch(offset) {
		if (this.props.items) {
			const filterRegex = new RegExp(this.state.value, "i");
			const resultOptions = this.props.items.filter((option) => option.fullname.match(filterRegex));

			this.setState({ results: resultOptions.slice(0, 9), showSearch: true });
			return;
		}
		const CancelToken = axios.CancelToken;
		const query = this.state.value;
		const params = Object.assign({}, this.props.params || {}, {});
		params.q = query;
		params.offset = offset;
		params.limit = this.state.limit;

		if (this.state.resource == "google_address") {
			API.get("/api/google/addresses.json", {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					this.props.onItemsFetched?.(result.data);
					this.setState({
						loading: false,
						results: result.data,
						cancelToken: null,
						showSearch: true,
					});
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line no-console
						console.debug("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		} else {
			API.get("/api/" + this.state.resource + (this.state.resource.indexOf(".json") >= 0 ? "" : ".json"), {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					const resourceHandle = this.state.resource_handle;

					if (result.data[resourceHandle]) {
						for (let i = 0; i < result.data[resourceHandle].length; i++) {
							this.state.results.push(result.data[resourceHandle][i]);
						}
					}
					this.props.onItemsFetched?.(result.data[resourceHandle]);
					this.setState({
						loading: false,
						loadingMore: false,
						results: this.state.results,
						// results: this.state.results,
						cancelToken: null,
					});
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line no-console
						console.debug("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		}
	}

	selectItem(item) {
		if (item && item.id) {
			this.setState((c) => ({
				item,
				showSearch: false,
				value: "",
				results: this.props.clearSearchResultsOnClick ? [] : c.results,
			}));
		} else {
			this.setState((c) => ({
				showSearch: false,
				value: "",
				results: this.props.clearSearchResultsOnClick ? [] : c.results,
			}));
		}
		this.props.onChange(item);
	}

	update(value) {
		if (this.props.items) {
			this.setState(
				{
					value,
					error: null,
					results: [],
					offset: 0,
				},
				this.doSearch.bind(this, 0)
			);
			return;
		}
		this.cancelRequest();
		clearTimeout(this.state.timeout);
		const newState = {
			value,
			error: null,
			loading: true,
			results: [],
			offset: 0,
			timeout: setTimeout(this.doSearch.bind(this, 0), 500),
		};
		this.setState(newState);
	}

	inResults() {
		for (let i = 0; i < this.state.results.length; i++) {
			if (this.state.results[i][this.state.label_handle].toLowerCase() == this.state.value.toLowerCase()) {
				return true;
			}
		}
		return false;
	}

	findAndSelectItem(id) {
		if (id === null) {
			this.selectItem(null);
			return;
		} else if (id == "new") {
			const ob = { id: null };
			ob[this.state.label_handle] = this.state.value;
			this.selectItem(ob);
			return;
		}
		for (let i = 0; i < this.state.results.length; i++) {
			if (this.state.results[i][this.state.id_handle] == id) {
				this.selectItem(this.state.results[i]);
				return;
			}
		}
	}

	componentDidMount() {
		document.addEventListener("mouseup", this.handleClickOutside);
		document.addEventListener("keydown", this.handleKeydown);
	}

	componentWillUnmount() {
		document.removeEventListener("mouseup", this.handleClickOutside);
		document.removeEventListener("keydown", this.handleKeydown);
	}

	focusInput() {
		$("#searchfield" + this.state.myId + " input").focus();
	}

	handleClickOutsideBase(event) {
		if (this.state.showSearch && this.props.showJustContent && (!this.ref || !this.ref.current || !this.ref.current.contains(event.target)))
			this.hideSearch();
	}

	handleKeydownBase(event) {
		if (event.key === "Escape") this.hideSearch();
	}

	onSubmit() {
		if (!this.state.value) return;
		if (this.props.onSubmit && typeof this.props.onSubmit === "function") {
			const error = this.props.inValid && typeof this.props.inValid === "function" && this.props.inValid(this.state.value);
			if (!error) {
				this.props.onSubmit(this.state.value);
				this.setState({ value: "", error: null });
			} else {
				this.setState({ error });
			}
		}
	}

	render() {
		let caption;
		if (!this.state.item || this.props.fixedCaption) {
			caption = this.state.default_caption;
		} else {
			caption = this.state.item[this.state.label_handle];
		}

		const options = [];
		if (this.props.clearOption && this.state.item) {
			options.push({
				value: null,
				label: (
					<Stack spacing="extraTight">
						<Icon source={MobileCancelMajor} /> <span>{this.props.clearOption}</span>
					</Stack>
				),
			});
		}
		if (this.props.allowCreate || (this.props.tags && this.state.value && !this.inResults())) {
			options.push({
				value: "new",
				label: (
					<Stack spacing="extraTight">
						<Icon source={CirclePlusMajor} />{" "}
						<div>
							{this.props.t("common.actions.create", "Skapa")} {this.props.resourceName.singular}
						</div>
					</Stack>
				),
			});
		}
		for (let i = 0; i < this.state.results.length; i++) {
			if (this.props.renderLabel) {
				options.push({
					value: this.state.results[i][this.state.id_handle],
					label: this.props.renderLabel(this.state.results[i]),
				});
			} else {
				options.push({
					value: this.state.results[i][this.state.id_handle],
					label: this.state.results[i][this.state.label_handle],
				});
			}
		}

		if (this.state.loadingMore) {
			options.push({
				label: (
					<Stack spacing="extraTight">
						<Spinner size="small" />{" "}
						<TextStyle variation="subdued">
							{this.props.t("common.actions.load_more", "Laddar fler")} {this.props.resourceName.plural}
						</TextStyle>
					</Stack>
				),
				value: false,
			});
		}

		const closeOption = this.props.showCloseOption
			? [
					{
						key: "close",
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.hideSearch.bind(this),
					},
			  ]
			: [];

		const content = (
			<div ref={this.ref}>
				<Popover.Pane fixed>
					<div id={"searchfield" + this.state.myId} style={this.props.style || { padding: 5, paddingBottom: 0 }}>
						<Form onSubmit={this.onSubmit.bind(this)}>
							<TextField
								prefix={<Icon source={SearchMajor} color="inkLighter" />}
								autoComplete="off"
								placeholder={this.props.placeholder}
								type="search"
								value={this.state.value}
								onChange={this.update.bind(this)}
								error={this.state.error}
							/>
						</Form>
					</div>
				</Popover.Pane>
				{this.state.loading && this.state.results.length < 1 ? (
					<Popover.Section>
						<Stack spacing="extraTight">
							<Spinner size="small" /> <TextStyle variation="subdued">Laddar {this.props.resourceName.plural}</TextStyle>
						</Stack>
					</Popover.Section>
				) : this.props.showJustContent && !this.state.showSearch ? null : (
					// this.props.showJustContent ? null : (
					<React.Fragment>
						<div />
						<Popover.Pane
							onScrolledToBottomDisabled={() => {
								if (!this.state.loading && !this.state.loadingMore && this.state.offset + this.state.limit == this.state.results.length) {
									this.state.offset += this.state.limit;
									this.setState({
										loadingMore: true,
										offset: this.state.offset,
									});
									this.doSearch(this.state.offset);
								}
							}}
						>
							<ActionList
								items={options
									.map((option, index) => {
										if (option.value === false) {
											if (this.state.loadingMore) {
												return {
													content: option.label,
												};
											}
											// Add empty option to make jumping less
											return {
												content: "",
											};
										}
										return {
											key: index,
											content: option.label,
											onAction: this.findAndSelectItem.bind(this, option.value),
										};
									})
									.concat(closeOption)}
							/>
						</Popover.Pane>
					</React.Fragment>
				)}
			</div>
		);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<span
					onClick={(event) => {
						event.stopPropagation();
					}}
					className="sel"
				>
					{this.props.showJustContent ? (
						content
					) : (
						<MyPopover
							fixed={this.props.fixed}
							active={this.state.showSearch}
							onClose={() => this.setState({ showSearch: false })}
							activator={
								<span className={`resource_picker_btn ${this.props.className || ""}`}>
									<Button
										fullWidth={this.props.fullWidth !== false}
										plain={!!this.props.plain}
										size={this.props.size}
										primary={!!(!this.props.plain && !this.state.item)}
										disabled={this.props.disabled}
										onClick={() => {
											if (!this.state.showSearch) {
												this.state.offset = 0;
												this.setState({
													showSearch: true,
													offset: 0,
													results: [],
													loading: !this.props.items && true,
												});
												this.doSearch(0);
												setTimeout(this.focusInput.bind(this), 5);
											} else {
												this.setState({ showSearch: false });
											}
											if (this.props.onOpen) this.props.onOpen();
										}}
										icon={this.props.icon}
									>
										{!this.props.disabled && caption}
									</Button>
								</span>
							}
						>
							{content}
						</MyPopover>
					)}
				</span>
			</Labelled>
		);
	}
}

export default withTranslation(["common"], { withRef: true })(ResourcePicker);
