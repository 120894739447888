import { Card, Pagination, ResourceList, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import debounce from "lodash/debounce";
import axios from "axios";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr";
import ProfileAvatar from "../../components/ProfileAvatar";

class SmallConversions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedItems: [],
			items: [],
			loading: true,
			limit: 10,
			offset: 0,
			filter: {},
			campaign_id: props.campaign_id,
			ad_id: props.ad_id,
		};
		this.fetchAfterSearch = debounce(() => this.fetch(true, 0, this.state.filter.search), 100, {
			wait: 500,
			leading: false,
			trailing: true,
		});
	}

	componentDidMount() {
		this.fetch();
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	fetch(silent, offset = this.state.offset, search) {
		if (!silent) this.setState({ loading: true });
		const CancelToken = axios.CancelToken;

		API.get("/api/visitors_conversions.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params: {
				limit: this.state.limit,
				offset,
				search,
				campaign_id: this.state.campaign_id,
				ad_id: this.state.ad_id,
			},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				this.setState({
					loading: false,
					items: result.data.visitors_conversions || [],
					count: result.data.count,
				});
			})
			.catch((error) => {
				if (!axios.isCancel(error)) {
					toastr.error(error);
				}
			});
	}

	filterItems() {
		if (!this.state.filter.search && !this.state.filter.status) return this.state.items;

		return this.state.items.filter((c) => {
			const searchMatch = !this.state.filter.search || c.title.toLowerCase().includes(this.state.filter.search);
			return searchMatch;
		});
	}

	renderItem(item) {
		// const media = <Avatar customer size="medium" name={item.name} source={item.email ? item.avatar : null} />;

		const shortcutActions = [];

		return (
			<ResourceList.Item id={item.id} shortcutActions={shortcutActions}>
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<div>
						<div>{item.title}</div>
						{item.contact ? (
							<ProfileAvatar
								size="small"
								onClick={() => {
									this.props.history.push("/admin/contacts/" + item.contact.id);
								}}
								user={item.contact}
							>
								{item.contact.name}
							</ProfileAvatar>
						) : null}
					</div>
					<span>{moment(item.created_at).format("MMM D, YYYY")}</span>
				</div>
			</ResourceList.Item>
		);
	}

	fetchWithPagination(offset) {
		this.fetch(true, offset);
	}

	goPrevious() {
		if (this.state.offset > 0) {
			const offset = this.state.offset - this.state.limit;
			this.setState({ offset });
			this.fetchWithPagination(offset);
		}
	}

	goNext() {
		if (this.state.offset + this.state.limit < this.state.count) {
			const offset = this.state.offset + this.state.limit;
			this.setState({ offset });
			this.fetchWithPagination(offset);
		}
	}

	render() {
		const hasContactPrevious = this.state.offset > 0;
		const hasContactNext = this.state.offset + this.state.limit < this.state.count;

		return (
			<div style={{ marginTop: "1.5rem" }}>
				<Card title={this.props.t("email_campaign.conversions.title", "Konverteringar") + (this.state.count ? " (" + this.state.count + ")" : "")}>
					<Card.Section>
						<div className="email-campaign__contacts__filter">
							<TextField
								value={this.state.filter.search}
								onChange={(v) => {
									this.setState((c) => {
										c.filter.search = v;
										return { filter: c.filter };
									}, this.fetchAfterSearch.bind(this));
								}}
								placeholder={this.props.t("common.placeholders.search_here", "Sök här..")}
							/>
						</div>

						<ResourceList
							selectedItems={this.state.selectedItems}
							onSelectionChange={(selectedItems) => {
								this.setState({ selectedItems });
							}}
							hasMoreItems={this.state.count > this.state.limit}
							showHeader
							loading={this.state.loading || this.state.saving}
							resourceName={{
								singular: this.props.t("email_campaign.contact.singular", "kontakt"),
								plural: this.props.t("email_campaign.contact.plural", "kontakter"),
							}}
							items={this.filterItems()}
							renderItem={this.renderItem.bind(this)}
						/>
					</Card.Section>
					<Card.Section>
						<div style={{ textAlign: "center" }}>
							<Pagination
								hasPrevious={hasContactPrevious}
								onPrevious={this.goPrevious.bind(this)}
								hasNext={hasContactNext}
								onNext={this.goNext.bind(this)}
							/>
						</div>
					</Card.Section>
				</Card>
			</div>
		);
	}
}
export default withTranslation(["email_campaign", "common"], { withRef: true })(SmallConversions);
