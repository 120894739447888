import { ActionList, Button, TextStyle } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import Popover from "../../../components/Popover";
import TicketModal from "../../../components/TicketModal";
import TicketStatus from "../../../components/TicketStatus";

class ChildTicketSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	toggleOpen() {
		this.setState((c) => ({ open: !c.open }));
	}

	closePopover() {
		this.setState((c) => ({ open: false }));
	}

	selectTicket(ticket, index) {
		this.setState({ open: false });
		this.props.onSelect(ticket, index);
	}

	render() {
		const { open } = this.state;

		const activator = (
			<Button onClick={this.toggleOpen.bind(this)} disclosure fullWidth>
				{this.props.t("ticket.terms.side_conversations", "Sidokonversationer")}
			</Button>
		);

		const actions = [];

		for (let i = 0; i < this.props.ticket?.events?.length; i++) {
			if (this.props.ticket.events[i].child_ticket) {
				const ticket = this.props.ticket.events[i].child_ticket;
				actions.push({
					content: (
						<div>
							#{ticket.number} {ticket.title}
							<div>
								<TextStyle variation="subdued">{ticket.contact.fullname}</TextStyle>
							</div>
							<TicketStatus status={ticket.status} badge />
							<div>
								<TextStyle variation="subdued">{nl2br(ticket.last_event.message)}</TextStyle>
							</div>
						</div>
					),
					onAction: this.selectTicket.bind(this, ticket, i),
				});
			}
		}

		actions.push({
			content: this.props.t("ticket.actions.new", "+ Nytt ärende"),
			onAction: () => {
				this.setState({ open: false, showTicketModal: true });
			},
		});

		return (
			<div>
				<label className="Polaris-Label__Text">{this.props.t("ticket.terms.side_conversations", "Sidokonversationer")}</label>
				<Popover active={open} activator={activator} onClose={this.closePopover.bind(this)}>
					<ActionList actionRole="menuitem" items={actions} />
				</Popover>
				<TicketModal
					open={this.state.showTicketModal}
					onCreate={(ticket, parentTicket) => {
						this.setState({ showTicketModal: false });
						this.props.onUpdate(parentTicket);
					}}
					ticket={{ parent_ticket_id: this.props.ticket.id }}
					onMessage={() => {
						this.setState({ showTicketModal: false });
					}}
					// user
					onClose={() => {
						this.setState({ showTicketModal: false });
					}}
				/>
			</div>
		);
	}
}

export default withTranslation(["ticket", "common"], { withRef: true })(ChildTicketSelector);
