import React, { Component } from "react";
import { Button, Icon, Tooltip } from "@shopify/polaris";
import moment from "moment";
import {
	PhoneOutMajor,
	CircleInformationMajor,
	EmailMajor,
	NoteMajor,
	ConversationMinor,
	PhoneMajor,
	CalendarMajor,
	MentionMajor,
	EditMajor,
	AttachmentMajor,
	CustomersMajor,
	AnalyticsBarStackedMinor,
	EmailNewsletterMajor,
} from "@shopify/polaris-icons";
import nl2br from "react-nl2br";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ReactQuill from "react-quill";
import isEqual from "lodash/isEqual";
import ContactSelector from "./ContactSelector.js";
import Mention from "./Mention";
import Attachment from "./Attachment.js";
import ProfileAvatar from "./ProfileAvatar.js";
import { store } from "../store/index.js";
import DatePickerCalendarTextField from "./DatePickerCalendarTextField.js";
import RowSheet from "../views/Workspaces/components/RowSheet.js";
import BoardHelper from "../views/Workspaces/BoardHelper.js";
import { getBoardTitle, getRowTypeLabel } from "../views/Workspaces/boardutils/BoardUtils";
import TaskCompleteModal from "../views/Agenda/TaskCompleteModal";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TaskSheet from "../views/Agenda/TaskSheet";
import RenderEmail from "./Emails/RenderEmail";
import Badge from "./Badge";
import Popover from "./Popover";

class TimeLineRow extends Component {
	constructor(props) {
		super(props);
		this.state = { event: props.event };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.props.event?.id !== props.event?.id || !isEqual(this.props.event, props.event)) {
			this.setState({ event: props.event });
		}
	}

	getIcon(event = {}) {
		if (!event.activity && event.row_comment) {
			return ConversationMinor;
		}

		const type = event.activity?.type || event?.type?.type;
		switch (type) {
			case "return":
				return PhoneOutMajor;
			case "contact_activities":
			case "contact":
				return PhoneMajor;
			case "meeting":
				return CalendarMajor;
			case "comment":
				return ConversationMinor;
			case "email":
				return EmailMajor;
			case "integration-email":
				if (event.external_id) return EmailNewsletterMajor;
				return EmailMajor;
			case "task":
				return NoteMajor;
			case "other":
				return CircleInformationMajor;
			default:
				return CircleInformationMajor;
		}
	}

	getTitle(event) {
		if (!event) return null;
		if (event.title) return event.title;
		if (event.board) return `${event.row.title} - ${getBoardTitle(event.board)}`;
		if (event?.activity?.type === "integration-email") {
			let email = null;
			try {
				email = JSON.parse(event?.content);
			} catch (error) {
				/* empty */
			}

			if (!email) return event.title || event?.activity?.title || "";

			const isSentFromMe = email?.sender?.address === event.user?.email || !email.sender;
			const name = isSentFromMe
				? email?.toRecipients?.[0]?.emailAddress?.name || email?.toRecipients?.[0]?.emailAddress?.address
				: email?.sender?.name || email?.sender?.address;

			return `${event?.activity?.title} ${isSentFromMe ? "till" : "från"} ${name}`;
		}

		return event.title || event?.activity?.title || "";
	}

	getType(event = this.state.event) {
		if (!event) return null;
		if (event.board_id) return "row";
		if (event.activity) return "comment";
		return null;
	}

	getIsEditable(event = this.state.event) {
		const type = this.getType(event);
		if (this.props.disabled || !event) return false;

		switch (type) {
			case "comment":
				return event?.user?.id === store.getState()?.user?.id;
			case "row":
				if (!event?.board_id) return false;
				if (!store?.getState()?.user) return false;

				return [store.getState().user.todo_board_id, store.getState().user.calendar_board_id, store.getState().account.todo_board_id].includes(
					event.board_id
				);
			default:
				return false;
		}
	}

	getToolTip(event) {
		const type = event.activity?.type || event?.type?.type;
		switch (type) {
			case "return":
				return "";
			case "contact_activities":
			case "contact":
				return "";
			case "meeting":
				return "";
			case "comment":
				return "";
			case "email":
				return "";
			case "integration-email":
				if (event.external_id) return this.props.t("emails.status.imoprted_email", "Importerat email");
				return "";
			case "task":
				return "";
			case "other":
				return "";
			default:
				return "";
		}
	}

	shouldHideContactPerson(contact) {
		return contact.is_company && contact?.id == this.props.contact?.id;
	}

	shouldHideContactParent(contact) {
		return this.props.contact?.id == contact?.parent?.id;
	}

	primaryAction() {
		const event = this.state.event;
		const type = this.getType();
		const editable = this.getIsEditable();

		if (type === "comment" && editable) {
			this.setState({ activityModalIsOpen: true });
		} else if (type === "row" && editable) {
			this.setState({ editingRow: event.row });
		}
	}

	render() {
		const event = this.state.event;
		if (!event) return null;
		const activity = this.state.event?.activity;

		const icon = this.getIcon(event);
		const title = this.getTitle(event);
		const type = this.getType();
		const editable = this.getIsEditable();

		const createdAt = event.date || event.created_at ? moment(event.date || event.created_at).format("YYYY-MM-DD HH:mm") : null;
		const user = event.creator || event.user;

		const rowEditable = event.row?.id && this.getIsEditable(event.row);

		const markedAsCompleted = event?.row?.deadline?.completed;

		const time = createdAt?.split(" ")?.[1] === "00:00" ? null : createdAt?.split(" ")?.[1];
		const content = event.content;
		const completedRow = event?.completed_row;
		const nrOfContactsToShow = 1;

		const isTaskRow = [store.getState().account.todo_board_id, store.getState().user.todo_board_id].includes(this.state.editingRow?.board_id);

		const contacts = event.contacts?.filter(Boolean);

		return (
			<>
				<StyledTimeLineRow loading={String(this.props.loading)} className={`timeline__row${this.props.preview ? " preview" : ""}`}>
					<span className="timeline__row__date">
						{time && <span className="timeline__row__date__time">{time}</span>}
						<span className="timeline__row__date__date">{moment(createdAt).locale("sv").format("MMM D")}</span>
						<span className="timeline__row__date__year">{moment(createdAt).locale("sv").format("YYYY")}</span>
					</span>

					<div className="timeline__row__content">
						{/* <div className="timeline__row__line" /> */}
						<div className="timeline__row__content__title__wrapper">
							<div className="timeline__row__content__title">
								<Tooltip content={this.getToolTip(event)}>
									<div
										className="timeline__row__content__title-inner"
										style={{ cursor: editable ? "pointer" : "initial" }}
										onClick={this.primaryAction.bind(this)}
									>
										<Icon source={icon} />
										<h2>{title}</h2> {user && <ProfileAvatar user={user} size={15} />}
									</div>
								</Tooltip>
								<div className="timeline__row__content__buttons">
									{type === "comment" && editable && (
										<span className="edit_btn">
											<Button
												plain
												icon={EditMajor}
												onClick={() => {
													this.setState({ activityModalIsOpen: true });
												}}
											/>
										</span>
									)}

									{rowEditable && (
										<span className="edit_btn">
											<Tooltip content={getRowTypeLabel(event.row)}>
												<Button plain icon={AnalyticsBarStackedMinor} onClick={() => this.setState({ editingRow: event.row })} />
											</Tooltip>
										</span>
									)}
									{rowEditable && !markedAsCompleted && (
										<TaskCompleteModal
											type="row"
											row={{
												...event.row,
												contactValue: contacts?.map((c) => c?.id).filter((c) => c) || (event.contact ? [event.contact.id] : []),
											}}
											onSuccess={(data) => {
												this.props.onSuccess?.();
											}}
										/>
									)}
									{/* {rowEditable && (
										<TodoSectionItemDoneButton
											item={{ ...event, row: event }}
											refresh={this.props.onSuccess}
											type={event.board?.id == store.getState().user.calendar_board_id ? "calendar" : "todo"}
										/>
									)} */}
								</div>
							</div>
						</div>
						{event && (event.date || event.created_at) && !moment(event.date || event.created_at).isSame(moment(createdAt), "D") && (
							<div className="field">
								<span className="label">
									<Icon source={CalendarMajor} />
									{this.props.t("timeline.fields.date.label", "Datum")}:{" "}
								</span>
								<DatePickerCalendarTextField icon={null} date={moment(event.date || event.created_at).format("YYYY-MM-DD")} disabled />
							</div>
						)}
						{!this.props.preview && !contacts && event.contact && event.contact.parent && (
							<div className="field">
								<span className="label">
									<Icon source={CustomersMajor} />
									{this.props.t("timeline.fields.customer.label", "Kontaktperson")}:{" "}
								</span>
								<ContactSelector
									hideIcon
									history={this.props.history}
									contact={event.contact}
									lock
									link
									hidePerson={this.shouldHideContactPerson(event.contact)}
									hideParent={this.shouldHideContactParent(event.contact)}
								/>
							</div>
						)}
						{!this.props.preview && contacts && (
							<div className="field">
								<span className="label">
									<Icon source={CustomersMajor} />
									{this.props.t("timeline.fields.customers.label", "Kontaktpersoner")}:{" "}
								</span>

								<div style={{ display: "flex", gap: 5, alignItems: "center" }}>
									{contacts?.slice(0, nrOfContactsToShow)?.map((contact, index, array) => {
										return (
											<div key={contact.id} style={{ display: "flex" }}>
												<ContactSelector
													key={contact.id}
													hideIcon
													history={this.props.history}
													contact={contact}
													lock
													link
													hidePerson={this.shouldHideContactPerson(contact)}
													hideParent={this.shouldHideContactParent(contact)}
												/>
												{index < array.length - 1 && <span>, </span>}
											</div>
										);
									})}
									{contacts?.length > nrOfContactsToShow && (
										<Popover
											sectioned
											active={this.state.extraContactsPopoverIsOpen}
											onClose={() => {
												this.setState({ extraContactsPopoverIsOpen: false });
											}}
											activator={
												<Badge
													onClick={() => {
														this.setState({ extraContactsPopoverIsOpen: true });
													}}
												>
													{" "}
													+ {contacts.length - nrOfContactsToShow}
												</Badge>
											}
										>
											<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
												{contacts?.slice(nrOfContactsToShow)?.map((contact) => {
													return (
														<ContactSelector
															key={contact.id}
															hideIcon
															history={this.props.history}
															contact={contact}
															lock
															link
															hidePerson={contact.is_company && contact?.id == this.props.contact?.id}
															hideParent={this.props.contact?.id == contact?.parent?.id}
														/>
													);
												})}
											</div>
										</Popover>
									)}
								</div>
							</div>
						)}

						{!this.props.preview &&
							event.files &&
							!!event.files.length &&
							event.files.map((file, key) => (
								<div className="field" key={key}>
									<span className="label">
										<Icon source={AttachmentMajor} />
										{this.props.t("timeline.fields.fil.label", "Fil")}:{" "}
									</span>
									<Attachment icon={null} key={key} file={file} plain />
								</div>
							))}
						{!this.props.preview &&
							event.mentions &&
							!!event.mentions.length &&
							event.mentions.map((user, index) => (
								<div className="field" key={user.id}>
									<span className="label">
										<Icon source={MentionMajor} />
										{this.props.t("timeline.fields.mention.label", "Mention")}:{" "}
									</span>
									<Mention icon={null} key={index} user={user} />
								</div>
							))}

						{/* {completedRow && (
							<div
								style={{ display: "flex", flexDirection: "column", cursor: "pointer", gap: "3px" }}
								onClick={() => this.setState({ editingRow: completedRow })}
							>
								Klarmarkerad rad: <b>{completedRow?.title}</b>
							</div>
						)} */}

						{nl2br(content) && activity?.type != "integration-email" && (
							<div className={`timeline__row__content__text${this.props.preview ? " preview" : ""}`}>
								{content?.trim?.()?.startsWith?.("<") ? (
									// <ReactQuill className="slim" modules={{ toolbar: false }} value={content} theme="snow" readOnly />
									<RenderEmail email={content} comment={event} preview={false} />
								) : (
									nl2br(content)
								)}
							</div>
						)}

						<div style={{ maxHeight: 300, overflow: "hidden" }}>
							{activity?.type == "integration-email" && <RenderEmail email={event.content} comment={event} preview={false} />}
						</div>
					</div>
				</StyledTimeLineRow>

				<TaskCompleteModal
					activator={null}
					comment={this.state.event}
					contacts={
						contacts?.length ? contacts : this.props.contact || this.state.event?.contact ? [this.props.contact || this.state.event?.contact] : []
					}
					open={this.state.activityModalIsOpen}
					onClose={() => this.setState({ activityModalIsOpen: false })}
					onSuccess={(comment) => {
						this.props.onChange?.(comment);
						this.setState({ activityModalIsOpen: false });
					}}
					onRemove={this.props.onRemove}
					onNewContact={this.props.onNewContact}
					submitDisabled={activity?.type === "integration-email"}
				/>

				{(event.row || completedRow) && (
					<TaskSheet
						open={this.state.editingRow && isTaskRow}
						row={this.state.editingRow}
						board={BoardHelper.getBoard(this.state.editingRow?.board_id)}
						onClose={() => {
							this.setState({ editingRow: false });
						}}
						onComplete={() => {
							this.props.onSuccess?.();
							this.setState({ editingRow: false });
						}}
					/>
				)}

				{(event.row || completedRow) && (
					<RowSheet
						open={this.state.editingRow && !isTaskRow}
						row={this.state.editingRow}
						board={BoardHelper.getBoard(this.state.editingRow?.board_id)}
						initialTabIndex={0}
						onUpdateRow={(row) => {
							BoardHelper.updateRows([row], "update");
							this.props.onSuccess?.();
						}}
						onClose={() => {
							this.setState({ editingRow: false });
						}}
						onComplete={(row) => {
							this.props.onSuccess?.();
						}}
					/>
				)}
			</>
		);
	}
}

export default withTranslation(["timeline", "common", "upload"], { withRef: true })(TimeLineRow);

const StyledTimeLineRow = styled.div.attrs({ className: "timeline__row" })`
	transition: "opacity 250ms";
	opacity: ${(props) => (props.loading === "true" ? 0.5 : 1)};

	.ql-editor * {
		color: var(--textColor) !important;
	}
`;
