import React, { Component } from "react";
import { Tabs, Modal, ChoiceList, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import RemoveModal from "./remove_modal.js";

import { toastr } from "./toastr.js";

class SavedSearchTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saveOption: "new",
			removing: false,
			showRemoveModal: props.showRemoveModal,
			saveOverwriteValue: "",
			saveNewValue: "",
			showTemp: false,
			showModal: props.showModal,
			defaultSort: props.defaultSort,
			filters: props.filters,
			sorting: props.sorting,
			search: props.search,
			// resource: props.resource,
			saved_searches: props.defaultSavedSearches || [],
			saving: false,
			selected: 0,
			handle: props.handle ? props.handle : props.resource,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ((props.handle ? props.handle : props.resource) != this.state.handle) {
			this.loadSavedSearches(props.handle ? props.handle : props.resource);
		}
		this.updateSelection(props.filters, props.search, props.sorting);
		this.setState({
			showRemoveModal: props.showRemoveModal,
			// resource: props.resource,
			showModal: props.showModal,
			defaultSort: props.defaultSort,
			sorting: props.sorting,
			search: props.search,
			filters: props.filters,
			handle: props.handle ? props.handle : props.resource,
		});
	}

	componentDidMount() {
		this.loadSavedSearches(this.state.handle);
	}

	loadSavedSearches(handle) {
		const arr = [];
		const savedSearches = store.getState().saved_searches;
		for (let i = 0; i < savedSearches.length; i++) {
			if (savedSearches[i].handle == handle) {
				arr.push(savedSearches[i]);
			}
		}
		this.state.saved_searches = [].concat(this.props.defaultSavedSearches || [], arr);

		this.setState({ saved_searches: this.state.saved_searches });
		this.updateSelection(this.state.filters, this.state.search, this.state.sorting);
	}

	updateSelection(filters, search, sorting) {
		if (filters.length < 1 && !search) {
			if (this.state.selected != 0) {
				this.setState({ selected: 0, showTemp: false });
				this.props.onSaveable(false);
			}
			return;
		}

		for (let i = 0; i < this.state.saved_searches.length; i++) {
			if (this.isSame(this.state.saved_searches[i], filters, search, sorting)) {
				if (this.state.selected != i + 1) {
					this.setState({ selected: i + 1, showTemp: false, currentTab: this.state.saved_searches[i].title });
					this.props.onSaveable(false);
				}
				return;
			}
		}

		if (this.state.selected != this.state.saved_searches.length + 1) {
			this.setState({ selected: this.state.saved_searches.length + 1, showTemp: true });
			this.props.onSaveable(true);
		}
	}

	isSame(savedSearch, filters, search, sorting) {
		if (savedSearch.search != search) {
			return false;
		}

		if (savedSearch.filters.length != filters.length) {
			return false;
		}

		for (let i = 0; i < filters.length; i++) {
			let found = false;
			for (let s = 0; s < savedSearch.filters.length; s++) {
				if (filters[i].key == savedSearch.filters[s].key && JSON.stringify(filters[i].value) == JSON.stringify(savedSearch.filters[s].value)) {
					found = true;
					break;
				}
			}

			if (!found) {
				return false;
			}
		}

		return true;
	}

	closeModal() {
		this.setState({ showModal: false, saveOption: "new", saveNewValue: "", saveOverwriteValue: "" });
		this.props.onCloseModal();
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
		this.props.onCloseRemoveModal();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getSelectedTab() {
		return this.state.saved_searches?.[this.state.selected - 1];
	}

	handleTabChange(selectedTabIndex) {
		this.setState({ selected: selectedTabIndex });
		if (selectedTabIndex != this.state.selected) {
			if (selectedTabIndex == 0) {
				this.props.onChange({ filters: [], search: "", sorting: this.state.defaultSort });
			} else if (selectedTabIndex <= this.state.saved_searches.length) {
				this.props.onChange({
					filters: this.state.saved_searches[selectedTabIndex - 1].filters,
					search: this.state.saved_searches[selectedTabIndex - 1].search,
					sorting: this.state.saved_searches[selectedTabIndex - 1].sorting,
				});
			}
		}
	}

	handleModalOptionChange(saveOption) {
		this.setState({ saveOption });
	}

	save() {
		this.setState({ saving: true });
		if (this.state.saveOption == "new") {
			API.post(
				"/api/saved_searches/" + this.state.handle + ".json",
				{
					title: this.state.saveNewValue,
					filters: this.state.filters,
					search: this.state.search,
					sorting: this.state.sorting,
				},
				{ params: store.getState().authentication.defaultParams }
			)
				.then((result) => {
					this.state.saved_searches.push(result.data.saved_search);
					this.setState({ showTemp: false, saving: false, saved_searches: this.state.saved_searches });
					this.handleTabChange(this.state.saved_searches.length);
					store.dispatch({ type: "ADD_SAVED_SEARCH", saved_search: result.data.saved_search });
					this.closeModal();
				})
				.catch((error) => {
					toastr.error(error);
				});
		} else {
			API.put(
				"/api/saved_searches/" + this.state.saveOverwriteValue + ".json",
				{
					filters: this.state.filters,
					search: this.state.search,
					sorting: this.state.sorting,
				},
				{ params: store.getState().authentication.defaultParams }
			)
				.then((result) => {
					const savedSearches = this.state.saved_searches;
					const savedSearchIndex = savedSearches?.findIndex((s) => String(s.id) === String(result.data.saved_search.id));
					if (savedSearchIndex > 0) {
						savedSearches[savedSearchIndex] = result.data.saved_search;
						this.state.saved_searches = savedSearchIndex > 0 && savedSearches[savedSearchIndex];
					}
					this.setState({ showTemp: false, saving: false, saved_searches: this.state.saved_searches });
					this.handleTabChange(savedSearchIndex + 1);
					store.dispatch({ type: "UPDATE_SAVED_SEARCH", saved_search: result.data.saved_search });
					this.closeModal();
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	remove() {
		const savedSearch = this.state.saved_searches[this.state.selected - 1];
		this.setState({ removing: true, currentTab: savedSearch.title });
		API.delete("/api/saved_searches/" + savedSearch.id + ".json", { params: store.getState().authentication.defaultParams })
			.then((result) => {
				const savedSearches = [];
				for (let i = 0; i < this.state.saved_searches.length; i++) {
					if (this.state.saved_searches[i].id + "" != savedSearch.id + "") {
						savedSearches.push(this.state.saved_searches[i]);
					}
				}
				this.state.saved_searches = savedSearches;
				this.setState({ showTemp: true, removing: false, saved_searches: this.state.saved_searches });
				this.handleTabChange(this.state.selected);
				this.updateSelection(this.state.filters, this.state.search, this.state.sorting);
				this.props.onSaveable(true);
				store.dispatch({ type: "REMOVE_SAVED_SEARCH", id: savedSearch.id });
				this.closeRemoveModal();
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		const tabs = [];

		tabs.push({
			id: "all",
			content: this.props.t("searches.saved_searches.all_resourcename", "Alla {{ resourceName }}", { resourceName: this.props.resourceName.plural }),
			panelID: "all",
		});

		// eslint-disable-next-line no-restricted-syntax
		for (const i in this.state.saved_searches) {
			const savedSearch = this.state.saved_searches[i];
			tabs.push({
				id: savedSearch.id,
				content: savedSearch.title,
				panelID: savedSearch.id,
			});
		}

		if (this.state.showTemp) {
			tabs.push({
				id: "custom",
				content: this.props.t("searches.saved_searches.custom_search", "Egen sökning"),
				panelID: "custom",
			});
		}

		const choices = [
			{
				label: this.props.t("searches.saved_searches.modal.save_as_a_new_search", "Spara som en ny sökning"),
				value: "new",
				renderChildren: (isSelected) =>
					isSelected && (
						<TextField
							label={this.props.t("searches.saved_searches.modal.new_saved_search_name", "Namn på ny sökning")}
							autoFocus
							value={this.state.saveNewValue}
							onChange={(newValue) => {
								this.setState({ saveNewValue: newValue });
							}}
						/>
					),
			},
		];

		if (this.state.saved_searches.length > 0) {
			const options = [];
			let found = false;
			for (let i = 0; i < this.state.saved_searches.length; i++) {
				if (this.state.saveOverwriteValue == this.state.saved_searches[i].id + "") {
					found = true;
				}
				options.push({
					value: this.state.saved_searches[i].id + "",
					label: this.state.saved_searches[i].title,
				});
			}

			if (!found) {
				this.setState({ saveOverwriteValue: this.state.saved_searches[0].id + "" });
			}

			choices.push({
				label: this.props.t("searches.saved_searches.modal.overwrite_an_existing_search", "Skriv över en sparad sökning"),
				value: "existing",
				renderChildren: (isSelected) =>
					isSelected && (
						<Select
							label={this.props.t("searches.saved_searches.modal.select_search_to_overwrite", "Vilken sökning vill du skriva över?")}
							options={options}
							onChange={(newValue) => {
								this.setState({ saveOverwriteValue: newValue });
							}}
							value={this.state.saveOverwriteValue}
						/>
					),
			});
		}

		return (
			<div className={`saved-tabs-wrapper${!this.state.saved_searches || !this.state.saved_searches.length ? " hidden" : ""}`}>
				<Tabs tabs={tabs} selected={this.state.selected} onSelect={this.handleTabChange.bind(this)} />
				<Modal
					open={this.state.showModal}
					onClose={this.closeModal.bind(this)}
					title={this.props.t("searches.saved_searches.modal.title", "Spara filter")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: this.closeModal.bind(this),
						},
					]}
					primaryAction={{
						disabled: !!(this.state.saveOption == "new" && !this.state.saveNewValue),
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						onAction: this.save.bind(this),
					}}
				>
					<Modal.Section>
						<ChoiceList
							choices={choices}
							selected={this.state.saveOption ? [this.state.saveOption] : []}
							onChange={this.handleModalOptionChange.bind(this)}
						/>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					objectName={this.state.currentTab ? this.state.currentTab : tabs[this.state.selected].content}
					resourceName={{
						singular: this.props.t("searches.saved_searches.singular", "sparad sökning"),
						plural: this.props.t("searches.saved_searches.plural", "sparade sökningar"),
					}}
					numItems={1}
					onClose={this.closeRemoveModal.bind(this)}
					loading={this.state.removing}
					onConfirm={this.remove.bind(this)}
				/>
			</div>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(SavedSearchTabs);
