import { InlineError, Modal } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import styled from "styled-components";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import API from "../../API";
import SearchField from "../../components/search_field";
import LicenseUserItem from "./LicenseUserItem";

interface LIcenseUserModalProps extends WithTranslation {
	open?: boolean;
	onClose: () => void;
	onComplete: () => void;
	user: UserType;
	licenseProvider: any;
}
type LicenseUserModalState = {
	form?: any;
	users: UserType[];
	removing?: boolean;
	adding?: boolean;
	error?: string | null;
};

class LicenseUserModal extends Component<LIcenseUserModalProps, LicenseUserModalState> {
	constructor(props: LIcenseUserModalProps) {
		super(props);
		this.state = { users: [] };
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	UNSAFE_componentWillReceiveProps(nextProps: Readonly<LIcenseUserModalProps>, nextContext: any): void {
		if (nextProps.open !== this.props.open) {
			if (nextProps.open) {
				this.setState({ form: nextProps.user });
			} else {
				this.setState({ form: null, users: [] });
			}
		}
	}

	addUser(user) {
		if (!this.state.users?.find((usr) => String(usr.id) === String(user.id))) {
			user.roles.push(`ROLE_${this.props.licenseProvider.role}`);
			const users = [...(this.state.users || []), user];
			this.setState({ users });
		}
	}

	removeUser(user) {
		const users = this.state.users.filter((usr) => String(usr.id) !== String(user.id));
		this.setState({ users });
	}

	saveAddUsers() {
		this.setState({ adding: true, error: null });

		API.put("/api/users.json", { users: this.state.users })
			.then((result) => {
				result.data.users?.forEach?.((user: any) => {
					store.dispatch({ type: "UPDATE_USER", user });
				});

				this.props.onComplete?.();
				this.props.onClose?.();
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ error: error?.response?.data?.message || error?.message });

				toastr.error(error);
			})
			.finally(() => {
				this.setState({ adding: false });
			});
	}

	saveRemoveUser() {
		this.state.form.roles = this.state.form.roles.filter((role) => {
			return role !== `ROLE_${this.props.licenseProvider?.role}`;
		});

		this.setState({ removing: true, error: null });
		API.put("/api/users/" + this.state.form.id + ".json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				store.dispatch({ type: "UPDATE_USER", user: result.data.user });

				if (result.data.user.id == store.getState().user.id) {
					const user = Object.assign({}, store.getState().user);
					user.roles = result.data.user.roles;
					store.dispatch({ type: "SET_USER", user });

					API.get("/api/workspaces.json", { params: {} })
						.then((result) => {
							if (result.data.error) {
								toastr.error(result.data.error);
								return;
							}

							store.dispatch({ type: "SET_WORKSPACES", workspaces: result.data.workspaces || null });
						})
						.catch((error) => {
							console.error("error:", error);
							toastr.error(error);
						});
				}
				this.props.onClose?.();
				this.props.onComplete?.();

				toastr.success(this.props.t("licenses.user.responses.removed", "Användare borttagen ifrån licensen"));
			})
			.catch((error) => {
				this.setState({ error: error?.response?.data?.message || error?.message });
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	render() {
		const { open, onClose } = this.props;

		return (
			<Modal
				title={
					this.props.user?.id
						? this.props.t("licenses.user_remove.title", "Ta bort {{name}}?", { name: this.state.form?.name })
						: this.props.t("licenses.user_add.title", "Lägg till användare")
				}
				open={open || false}
				onClose={onClose}
				primaryAction={
					!this.props.user?.id
						? {
								content: this.props.t("licenses.user.add", "Lägg till användare") || "",
								onAction: this.saveAddUsers.bind(this),
								loading: this.state.adding,
								disabled: !this.state.users?.length,
						  }
						: undefined
				}
				secondaryActions={[
					{ content: this.props.t("common.actions.close", "Stäng") || "", onAction: this.props.onClose },
					{
						content: this.props.t("licenses.user.remove", "Ta bort") || "",
						onAction: this.saveRemoveUser.bind(this),
						loading: this.state.removing,
						destructive: true,
						enabled: this.props.user?.id,
					},
				].filter((i) => !("enabled" in i) || i.enabled)}
			>
				<Modal.Section>
					{this.props.user?.id ? (
						<p>
							{this.props.t("licenses.user_remove.confirm", "Ta bort {{name}} ifrån licensen {{title}}?", {
								name: this.state.form?.name,
								title: this.props.licenseProvider?.title,
							})}
						</p>
					) : (
						<SearchField
							// placeholder="Sök efter användare att lägga till"
							resourceName={{
								singular: "användare",
								plural: "användare",
							}}
							resource="users"
							resource_handle="users"
							label_handle="name"
							onSelect={this.addUser.bind(this)}
							autoFocus
							renderLabel={(user: UserType) => {
								return <LicenseUserItem key={user.id} user={user} />;
							}}
						/>
					)}

					{this.state.error && <InlineError fieldID="error" message={this.state.error} />}

					<StyledList>
						{this.state.users?.map((user: UserType) => {
							return <LicenseUserItem key={user.id} user={user} onRemove={this.removeUser.bind(this, user)} />;
						})}
					</StyledList>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["licenses", "user", "common"], { withRef: true })(LicenseUserModal);

const StyledList = styled.div`
	padding-block: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	& > * {
		justify-content: start;
	}
`;
