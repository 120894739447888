import React, { Component } from "react";
import { Modal, FormLayout, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";

class DashboardModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: {}, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: {} });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/workspaces/" + this.props.workspace.id + "/dashboards.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("dashboard.respones.created", "Skapade dashboard"));
				this.setState({ saving: false });
				store.dispatch({ type: "ADD_DASHBOARD", dashboard: result.data.dashboard });
				setTimeout(() => {
					if (this.props.history) {
						this.props.history.push(`/admin/dashboard/${result.data.dashboard.id}`);
					}
					if (this.props.onCreate) {
						this.props.onCreate(result.data.dashboard);
					}
				}, 0);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("dashboard.modals.new.title", "Ny dashboard")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
					loading: this.state.saving || this.props.loading,

					onAction: this.saveForm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							label={this.props.t("dashboard.fields.title.label", "Namn på dashboard")}
							value={this.state.form.title}
							onChange={this.updateForm.bind(this, "title")}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["dashboard", "common"], { withRef: true })(DashboardModal);
