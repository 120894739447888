import axios from "axios";
import { ERROR_MESSAGES } from "src/constants";
import { toastr } from "../components/toastr";

const MAX_RETRY_ATTEMPTS = 1;

const sleep = (seconds: number) => {
	console.debug(`Sleeping for ${seconds} seconds`);
	/* eslint-disable-next-line no-promise-executor-return */
	return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

const retryFortnoxRequest = async (error, retryAttempt = 1) => {
	console.debug("retryFortnoxRequest");
	const sleepSeconds = 2 ** (retryAttempt - 1);
	await sleep(sleepSeconds);

	const { config, response } = error;
	const { message } = response?.data || {};

	console.debug(`Retrying fortnox request attempt ${retryAttempt} of ${MAX_RETRY_ATTEMPTS}: `, config?.url);

	try {
		const response = await axios(config);
		console.debug(`Success on attempt ${retryAttempt}`, config?.url);
		return response;
	} catch (error: any) {
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		if (error?.code !== "ECONNABORTED") {
			console.error("Error:", error);
		}

		if (
			retryAttempt < MAX_RETRY_ATTEMPTS &&
			[
				ERROR_MESSAGES.FORTNOX.COULD_NOT_LOGIN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN,
				ERROR_MESSAGES.FORTNOX.MISSING_REFRESH_TOKEN_OR_INVALID_FOR_THE_CLIENT,
			].includes(message)
		) {
			return await retryFortnoxRequest(error, retryAttempt + 1);
		}

		toastr.error(error);
		throw error;
	}
};

export default retryFortnoxRequest;
