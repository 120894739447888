/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { Link, Button, Icon, Stack, TextStyle, Spinner, Labelled, ActionList, Tooltip, TextField } from "@shopify/polaris";
import { CustomersMajor, JobsMajor, ChevronDownMinor, InfoMinor, ExternalMinor, SearchMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";
import ResourcePicker from "./resource_picker.js";
import Popover from "./Popover.js";
import AddComanyModal from "./AddComanyModal.js";

class ContactSelector extends Component {
	constructor(props) {
		super(props);
		let person = null;
		let company = null;
		let children = null;

		if (props.contact) {
			if (props.contact.is_company) {
				company = props.contact;
			} else if (!props.contact.is_company) {
				person = props.contact;
				if (props.contact.parent) {
					company = props.contact.parent;
				}
			}
		}

		if (props.children) {
			children = props.children;
		}

		this.state = {
			activePopover: false,
			showContactModal: false,
			selectedContact: {
				name: "",
				phone: this.props.phone,
				is_company: true,
				country: "SE",
				metafields: [],
				tags: [],
			},
			children,
			person,
			company,
		};

		this.companySearchRef = React.createRef();
		this.personSearchRef = React.createRef();
	}

	UNSAFE_componentWillReceiveProps(props) {
		let person = null;
		let company = null;

		if (props.contact && !props.alwaysNull) {
			if (props.contact.is_company) {
				company = props.contact;
			} else if (!props.contact.is_company) {
				person = props.contact;
				// if (this.state.company && props.contact.parent && props.contact.parent.id == this.state.company.id) {
				// 	company = this.state.company;
				// } else if (props.contact.parent) {
				// company = props.contact.parent;
				// }
				// if (props.contact.parent) {
				company = props.contact.parent;
				// }
			}
		}

		if (!props.alwaysNull) {
			this.setState({
				person,
				company,
			});
		}
	}

	fetchChildren() {
		const params = { sort: "NAME_ASC" };
		params.contact_id = this.state.company.id;
		this.setState({ loading: true });
		API.get("/api/contacts.json", {
			params,
		})
			.then((result) => {
				this.addContactsToStore(result.data.contacts);
				this.setState({ loading: false, children: result.data.contacts });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getChildrenFromContact(contact, kids, filter) {
		const children = [];
		const bolagIds = [];

		if (kids) {
			for (let i = 0; i < kids.length; i++) {
				children.push(kids[i]);
				if (kids[i].bolag_id) {
					bolagIds.push(kids[i].bolag_id);
				}
			}
		}

		if (filter !== true) {
			if (contact.people) {
				for (let i = 0; i < contact.people.length; i++) {
					const pcs = contact.people[i].name.split(" ");
					const lastName = pcs[pcs.length - 1];
					const firstName = contact.people[i].name.replace(" " + lastName, "");
					const id = contact.people[i].id ? contact.people[i].id : i;
					if (bolagIds.indexOf(id) < 0) {
						children.push({
							id,
							is_bolag: true,
							name: contact.people[i].name,
							first_name: firstName,
							last_name: lastName,
							title: contact.people[i].title,
							note: "",
							bolag_id: contact.id,
							parent: { name: contact.name, id: contact.id },
							is_company: false,
							country: contact.country,
							relationship: "none",
							tags: [],
							tasks: [],
							tickets: [],
							call_lists: [],
							deals: [],
							devices: [],
						});
						bolagIds.push(id);
					}
				}
			}

			if (contact.bolag && contact.bolag.people) {
				for (let i = 0; i < contact.bolag.people.length; i++) {
					const pcs = contact.bolag.people[i].name.split(" ");
					const lastName = pcs[pcs.length - 1];
					const firstName = contact.bolag.people[i].name.replace(" " + lastName, "");
					const id = contact.bolag.people[i].id ? contact.bolag.people[i].id : i;

					if (bolagIds.indexOf(id) < 0) {
						children.push({
							id,
							is_bolag: true,
							name: contact.bolag.people[i].name,
							first_name: firstName,
							last_name: lastName,
							title: contact.bolag.people[i].title,
							parent: { name: contact.bolag.name, id: contact.bolag.id },
							note: "",
							bolag_id: contact.bolag.id,
							is_company: false,
							country: contact.country,
							relationship: "none",
							tags: [],
							tasks: [],
							tickets: [],
							call_lists: [],
							deals: [],
							devices: [],
						});
						bolagIds.push(id);
					}
				}
			}
		}

		return children;
	}

	loadChildrenIfNeeded() {
		if (!this.state.children) {
			this.fetchChildren();
		}
	}

	onCreateContact(contact) {
		if (contact.is_company) {
			let person = null;
			if (this.state.person) {
				person = Object.assign({}, this.state.person);
				person.parent = Object.assign({}, contact);
				person.parent.children = [];
			}
			this.setCompany(contact);
			if (person) {
				this.setContactPerson(person);
			}
		} else {
			this.setContactPerson(contact);
			if (this.state.company) {
				if (!this.state.company.children) {
					this.state.company.children = [];
				}
				this.state.company.children.push(contact);
				this.setState({ company: this.state.company });
			}
		}

		if (this.props.onCreateContact) {
			this.props.onCreateContact(contact);
		}
	}

	setCompany(contact) {
		if (contact && !contact.id) {
			this.setState({
				showContactModal: true,
				selectedContact: this.decorateFromRow({
					name: contact.name,
					is_company: true,
					country: "SE",
					phone: this.props.phone,
					child: this.state.person,
					metafields: [],
					tags: [],
				}),
				children: null,
				isCompany: true,
			});
			return;
		}
		if ((contact && !this.state.company) || (!contact && this.state.company) || contact.id != this.state.company.id) {
			if (this.props.lock) {
				this.updateCompany(contact);
			} else {
				if (this.state.person?.id && !this.state.person.parent && contact) {
					const company = Object.assign(contact);
					contact = Object.assign(this.state.person, { parent: contact });
					this.updateContact(contact);
					this.setState({ company, person: contact, children: null });
					return;
				}
				this.setState({ company: contact, person: null, children: null });
				this.props.onChange(contact);
			}
		}
	}

	openContactModal(item, isCompany) {
		if (!item) {
			item = {
				is_company: isCompany,
				country: "SE",
				phone: this.props.phone,
				metafields: [],
				tags: [],
			};
		}
		this.setState({
			showContactModal: true,
			selectedContact: this.decorateFromRow(item),
			isCompany,
			activePopover: false,
		});
		if (this.companySearchRef.current) this.companySearchRef.current.hideSearch();

		// this.setState({ showContactModal: true, selectedContact: this.decorateFromRow(item), isCompany });
	}

	closeContactModal() {
		this.setState({ showContactModal: false });
	}

	decorateFromRow(data) {
		if (this.props.row) {
			if (this.props.row.board_id in store.getState().boards) {
				data.name = this.props.row.title;
				const board = store.getState().boards[this.props.row.board_id];

				Object.entries(this.props.row.column_values).forEach(([columnId, value]) => {
					const column = board.columns.find((col) => col.id == columnId);
					if (column.type == "company_number" && value.value && value.value.number) {
						data.orgnr = value.value.number;
					}
					if (
						column.type == "text" &&
						(column.title.toLowerCase() == "e-post" ||
							column.title.toLowerCase() == "epost" ||
							column.title.toLowerCase() == "e-mail" ||
							column.title.toLowerCase() == "email")
					) {
						data.email = value.value;
					}
					if (
						column.type == "text" &&
						(column.title.toLowerCase() == "telefon" ||
							column.title.toLowerCase() == "telnr" ||
							column.title.toLowerCase() == "tel" ||
							column.title.toLowerCase() == "phone" ||
							column.title.toLowerCase() == "mobil" ||
							column.title.toLowerCase() == "mobilnr")
					) {
						data.phone = value.value;
					}
					if (
						column.type == "text" &&
						(column.title.toLowerCase() == "gatuadress" ||
							column.title.toLowerCase() == "address" ||
							column.title.toLowerCase() == "adress" ||
							column.title.toLowerCase() == "besöksadress")
					) {
						data.address1 = value.value;
					}
					if (
						column.type == "text" &&
						(column.title.toLowerCase() == "postnr" ||
							column.title.toLowerCase() == "postnummer" ||
							column.title.toLowerCase() == "zip" ||
							column.title.toLowerCase() == "besökspostnr" ||
							column.title.toLowerCase() == "besökspostnummer")
					) {
						data.zip = value.value;
					}
					if (
						column.type == "text" &&
						(column.title.toLowerCase() == "stad" ||
							column.title.toLowerCase() == "ort" ||
							column.title.toLowerCase() == "postort" ||
							column.title.toLowerCase() == "besökspostort")
					) {
						data.city = value.value;
					}
				});
			}
		}
		return data;
	}

	setContactPerson(contact) {
		if (contact && contact.parent) {
			if (!this.state.company || this.state.company.id != contact.parent.id) {
				if (!this.props.alwaysNull) this.setState({ company: contact.parent });
				contact.parent.children = [];
			}
		}
		if (contact && !contact.id) {
			this.openContactModal(null, false);
			this.setState({ person: null });
			return;
		}
		if (!this.props.alwaysNull) this.setState({ person: contact });

		if (contact) {
			this.props.onChange(contact);
		} else if (this.state.company) {
			this.props.onChange(this.state.company);
		} else {
			this.props.onChange(null);
		}
	}

	selectContactPerson(contact) {
		this.setState({ activePopover: false, childrenSearch: "" });
		this.setContactPerson(contact);
	}

	gotoContact(contact) {
		if (this.props.gotoContact) {
			this.props.gotoContact(contact, []);
		} else if (this.props.history) {
			this.props.history.push("/admin/contacts/" + contact.id, {
				path: {
					title: this.props.contact && this.props.contact.name,
					url: this.props.contact && `admin/contacts/${this.props.contact.id}`,
				},
			});
		}
	}

	updateContact(contact) {
		API.put("/api/contacts/" + contact.id + ".json", contact)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onChange) {
					this.props.onChange(result.data.contact);
				}

				// toastr.success("Kontaktens företag uppdaterades");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateCompany(contact) {
		this.state.person.parent = contact;
		this.setState({ person: this.state.person });

		if (this.props.onChange && this.props.hidePerson) {
			this.props.onChange(contact);
			return;
		}

		const con = Object.assign({}, contact);
		if ("children" in con) {
			delete con.children;
		}
		if ("parent" in con) {
			delete con.parent;
		}

		API.put("/api/contacts/" + this.state.person.id + ".json", {
			parent: con,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onChange) {
					this.props.onChange(result.data.contact);
				}

				toastr.success(this.props.t("contact.responses.contact_company_updated", "Kontaktens företag uppdaterades"));
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	addContactsToStore(contacts) {
		store.dispatch({ type: "SET_BOARD_CONTACTS", contacts });
	}

	render() {
		const placeholder = this.props.placeholder === undefined ? this.props.t("contact.placeholder", "Ingen person") : this.props.placeholder || "";

		let children = [];
		if (this.state.company) {
			children = this.getChildrenFromContact(this.state.company, this.state.children, false)?.filter((child) => {
				if (!this.state.childrenSearch) return true;
				const regexp = new RegExp(this.state.childrenSearch, "i");

				return regexp.test(child.name) || regexp.test(child.email) || regexp.test(child.phone);
			});
		}

		const contactSelect = [];

		if (!this.state.childrenSearch) {
			contactSelect.push({
				content: this.props.t("contact.placeholder", "Ingen person"),
				onAction: this.selectContactPerson.bind(this, null),
			});
		}

		for (let i = 0; i < children.length; i++) {
			const contact = children[i];
			contactSelect.push({
				content: contact.name,
				onAction: this.selectContactPerson.bind(this, contact),
			});
		}

		if (!this.state.childrenSearch) {
			contactSelect.push({
				content: this.props.t("contact.actions.new", "Ny kontaktperson"),
				onAction: () => {
					this.openContactModal(null, false);
				},
			});
		}

		const personSelector =
			this.state.company && !this.props.lock ? (
				<Popover
					active={this.state.activePopover}
					activator={
						<Button
							plain={this.props.plain}
							onClick={() => {
								this.loadChildrenIfNeeded();
								if (this.companySearchRef.current) this.companySearchRef.current.hideSearch();
								this.setState({ activePopover: !this.state.activePopover });
							}}
						>
							{this.state.person ? this.state.person.name : placeholder}
						</Button>
					}
					onClose={() => {
						this.setState({ activePopover: false });
					}}
				>
					<div>
						<TextField
							value={this.state.childrenSearch}
							onChange={(childrenSearch) => {
								this.setState({ childrenSearch });
							}}
							placeholder={this.props.t("common.search", "Sök") + ".."}
							prefix={<Icon source={SearchMajor} color="inkLighter" />}
							type="search"
							autoFocus
						/>
						<ActionList
							items={
								this.state.loading
									? [
											{
												content: (
													<Stack spacing="extraTight">
														<Spinner size="small" />{" "}
														<TextStyle variation="subdued">{this.props.t("contact.loading_contacts", "Laddar kontaktpersoner")}</TextStyle>
													</Stack>
												),
											},
									  ]
									: contactSelect
							}
						/>
					</div>
				</Popover>
			) : (!this.state.person || !this.props.lock) && !this.props.disabled ? (
				<ResourcePicker
					onItemsFetched={this.addContactsToStore.bind(this)}
					ref={this.personSearchRef}
					fixed={this.props.fixed}
					caption={this.props.personCaption || placeholder}
					resource={"contacts.json?is_company=0&is_visitor=0" + (this.state.company ? "&parent_id=" + this.state.company.id : "")}
					resource_handle="contacts"
					item={this.state.person}
					plain={this.props.plain}
					fullWidth={false}
					id_handle="id"
					label_handle="fullname"
					resourceName={{
						singular: this.props.t("contact.person.singular", "kontaktperson"),
						plural: this.props.t("contact.person.plural", "kontaktpersoner"),
					}}
					allowCreate
					clearOption={placeholder}
					onChange={this.setContactPerson.bind(this)}
					onOpen={() => {
						if (this.companySearchRef.current) this.companySearchRef.current.hideSearch();
					}}
					disabled={this.props.disabled}
				/>
			) : (
				<Stack spacing="extraTight">
					<span>
						{this.props.link ? (
							<Link monochrome={this.props.lock} onClick={this.gotoContact.bind(this, this.state.person)}>
								{this.state.person?.name}
							</Link>
						) : (
							this.state.person?.name
						)}
					</span>
					{this.state.person && this.state.company && !this.props.lock && this.props.link ? (
						<ResourcePicker
							onItemsFetched={this.addContactsToStore.bind(this)}
							ref={this.personSearchRef}
							fixed={this.props.fixed}
							caption={<Icon source={ChevronDownMinor} />}
							fixedCaption
							resource={"contacts.json?is_company=0&parent_id=" + this.state.company.id}
							resource_handle="contacts"
							item={null}
							plain={this.props.plain}
							fullWidth={false}
							id_handle="id"
							label_handle="name"
							resourceName={{
								singular: this.props.t("contact.person.singular", "kontaktperson"),
								plural: this.props.t("contact.person.plural", "kontaktpersoner"),
							}}
							allowCreate
							clearOption={placeholder}
							onChange={this.setContactPerson.bind(this)}
							onOpen={() => {
								if (this.companySearchRef.current) this.companySearchRef.current.hideSearch();
							}}
							disabled={this.props.disabled}
						/>
					) : null}
				</Stack>
			);

		const companySelector =
			(!this.props.lock || !this.state.company) && !this.props.disabled ? (
				<ResourcePicker
					onItemsFetched={this.addContactsToStore.bind(this)}
					ref={this.companySearchRef}
					fixed={this.props.fixed}
					caption={
						this.props.companyRequired && !this.state.company ? (
							<span
								style={{ display: "flex", gap: 5, alignItems: "center" }}
								className={`${this.props.companyRequired && !this.state.company ? "missing" : ""}`}
							>
								{this.props.caption || this.props.t("contact.company.caption", "inget företag")}
								<Tooltip content="Måste välja ett företag">
									<Icon source={InfoMinor} color="red" />
								</Tooltip>
							</span>
						) : (
							this.props.caption || this.props.t("contact.company.caption", "inget företag")
						)
					}
					resource="contacts.json?is_company=1&is_visitor=0"
					resource_handle="contacts"
					item={this.state.company}
					plain={this.props.plain}
					renderLabel={(contact) => {
						if (store.getState().account.id == 15) {
							let office = null;
							let kundnr = null;
							for (let i = 0; i < contact.metafields.length; i++) {
								if (contact.metafields[i].handle == "kundnummer") {
									kundnr = contact.metafields[i].value;
								} else if (contact.metafields[i].handle == "kontor") {
									office = contact.metafields[i].value;
								}
							}
							return (office ? office + " - " : "") + contact.name + (kundnr ? " - " + kundnr : "");
						}
						return contact.name;
					}}
					onOpen={() => {
						this.setState({ activePopover: false });
						if (this.personSearchRef.current) this.personSearchRef.current.hideSearch();
					}}
					fullWidth={false}
					id_handle="id"
					label_handle="name"
					resourceName={{
						singular: this.props.t("contact.company.singular", "företag"),
						plural: this.props.t("contact.company.plural", "företag"),
					}}
					allowCreate
					clearOption={this.props.caption || this.props.t("contact.company.clear_option", "Inget företag")}
					onChange={this.setCompany.bind(this)}
				/>
			) : (
				<Stack spacing="extraTight">
					<span>
						{this.props.link ? (
							this.props.element ? (
								this.props.element({
									children: this.state.company?.name || "-",
									onClick: this.gotoContact.bind(this, this.state.company),
								})
							) : (
								<Link monochrome={this.props.lock} onClick={this.gotoContact.bind(this, this.state.company)}>
									{this.state.company?.name || "-"}
								</Link>
							)
						) : (
							this.state.company?.name || "-"
						)}
					</span>
					<ResourcePicker
						onItemsFetched={this.addContactsToStore.bind(this)}
						disabled={this.props.disabled || this.props.contact?.removed}
						ref={this.companySearchRef}
						fixed={this.props.fixed}
						caption={<Icon source={ChevronDownMinor} />}
						fixedCaption
						resource="contacts.json?is_company=1"
						resource_handle="contacts"
						item={null}
						plain={this.props.plain}
						fullWidth={false}
						id_handle="id"
						renderLabel={(contact) => {
							if (store.getState().account.id == 15) {
								let office = null;
								let kundnr = null;
								for (let i = 0; i < contact.metafields.length; i++) {
									if (contact.metafields[i].handle == "kundnummer") {
										kundnr = contact.metafields[i].value;
									} else if (contact.metafields[i].handle == "kontor") {
										office = contact.metafields[i].value;
									}
								}
								return (office ? office + " - " : "") + contact.name + (kundnr ? " - " + kundnr : "");
							}
							return contact.name;
						}}
						label_handle="name"
						resourceName={{
							singular: this.props.t("contact.company.singular", "företag"),
							plural: this.props.t("contact.company.plural", "företag"),
						}}
						allowCreate
						clearOption={this.props.caption || this.props.t("contact.company.clear_option", "Inget företag")}
						onChange={this.setCompany.bind(this)}
						onOpen={() => {
							this.setState({ activePopover: false });
							if (this.personSearchRef.current) this.personSearchRef.current.hideSearch();
						}}
					/>
				</Stack>
			);

		return (
			<span
				className="contact_selector"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				// /admin/workspaces/none/boards/333
			>
				<Labelled label={this.props.label}>
					<Stack spacing="extraTight">
						{(this.state.person?.id || this.state.company?.id) && !this.props.link && !this.props.external && (
							<span className="contact_link">
								<Link
									url={`/admin/contacts/${this.state.person?.id || this.state.company?.id}`}
									// external={(() => {
									// 	const refExp = new RegExp("/admin/workspaces/.*/boards/.*", "i");
									// 	return refExp.test(window.location.pathname);
									// })()}
								>
									<Icon source={ExternalMinor} />
								</Link>
							</span>
						)}
						{this.props.prefix}
						{!this.props.hidePerson ? (
							<span
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Stack wrap={false} spacing="extraTight">
									{!this.props.hideIcon && <Icon color="inkLightest" source={this.props.icon || CustomersMajor} />} {personSelector}
								</Stack>
							</span>
						) : null}
						{!this.props.hideParent ? (
							<span
								onClick={(event) => {
									event.stopPropagation();
								}}
							>
								<Stack wrap={false} spacing="extraTight">
									{this.props.hidePerson || (this.props.disabled && !this.state.person) ? null : <span>på</span>}{" "}
									{!this.props.hideIcon && <Icon color="inkLightest" source={JobsMajor} />}
									{companySelector}
								</Stack>
							</span>
						) : null}
						{this.props.suffix}
					</Stack>
					<AddComanyModal
						open={this.state.showContactModal}
						company={this.state.company}
						contact={this.state.selectedContact}
						onUpdate={this.onCreateContact.bind(this)}
						isCompany={this.state.isCompany}
						onClose={this.closeContactModal.bind(this)}
					/>
				</Labelled>
			</span>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />

ContactSelector.defaultProps = {
	plain: true,
};

export default withTranslation(["contact", "common"], { withRef: true })(ContactSelector);
