import { Button, Spinner } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import HelpBox from "./HelpBox";
import { H2 } from ".";
import { store } from "../../store";
import { StackIcon } from "../../icons";

const GAP = 2;
const ROW_GAP = 7;

class Boxes extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { search, loading, openModal } = this.props;
		const boxes = this.props.items.filter(
			({ title, text }) => !search || title?.toLowerCase?.().includes(search) || text?.toLowerCase()?.includes(search)
		);
		return (
			<React.Fragment>
				<H2>
					<StackIcon />
					{this.props.t("help.functions.title", "Funktioner")}
					{loading && (
						<span style={{ marginLeft: "1rem" }}>
							<Spinner size="small" />
						</span>
					)}
					{["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].some((rule) => store.getState().user.roles.includes(rule)) &&
						String(store.getState().account.id) === "24" && (
							<div style={{ marginLeft: "1rem" }}>
								<Button plain icon={CirclePlusMajor} onClick={() => openModal({ form: { type: "box" }, tabIndex: 1 })} />
							</div>
						)}
				</H2>

				<HelpBoxesWrapper>
					{(!boxes || !boxes.length) && !loading && (
						<p style={{ margin: 0 }}>{this.props.t("help.boxes.error.empty", "Hittade ingen information..")}</p>
					)}

					{boxes.map((box, index) => (
						<HelpBox openModal={() => openModal({ form: box })} key={index} data={box} />
					))}
				</HelpBoxesWrapper>
				{/* <Collapsible open={this.state.showAllBoxes}>
					<HelpBoxesWrapper style={{ marginTop: `${ROW_GAP}rem` }}>
						{boxes.slice(6).map((box, index) => (
							<HelpBox openModal={() => openModal({ form: box })} key={"s" + index} data={box} />
						))}
					</HelpBoxesWrapper>
				</Collapsible> */}
				{/* {boxes.length > 6 && (
					<ShowMore>
						<span onClick={() => this.setState((c) => ({ showAllBoxes: !c.showAllBoxes }))}>
							{this.state.showAllBoxes
								? this.props.t("help.functions.actions.show_less", "Visa mindre funktioner")
								: this.props.t("help.functions.actions.show_all", "Visa alla funktioner")}
							{this.state.showAllBoxes ? <Icon source={ChevronUpMinor} /> : <Icon source={ChevronDownMinor} />}
						</span>
					</ShowMore>
				)} */}
			</React.Fragment>
		);
	}
}

export default withTranslation(["help", "common"], { withRef: true })(Boxes);

const HelpBoxesWrapper = styled.div`
	/* display: flex;
	flex-wrap: wrap;
	row-gap: ${ROW_GAP}rem;
	column-gap: ${GAP}rem; */

	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(auto-fill, minmax(400px, auto));

	/* justify-content: space-between; */

	& > *:not(.Polaris-Collapsible) {
		/* margin: auto; */
	}

	.Polaris-Collapsible--open {
		width: 100%;
	}

	/* & > *:nth-child(3n) {
		margin-right: 0;
	} */
	/* & > *:nth-child(3n + 1) {
		margin-left: 0;
	}
	& > *:nth-child(3n + 1) {
		margin-left: 0;
	} */
`;

// const ShowMore = styled.div`
// 	width: 100%;
// 	text-align: center;
// 	display: flex;
// 	justify-content: center;
// 	margin: ${ROW_GAP}rem 0;

// 	& > span {
// 		cursor: pointer;
// 		display: flex;
// 		width: max-content;

// 		.Polaris-Icon {
// 			margin: 0;
// 		}
// 	}
// `;
