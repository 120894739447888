import { Button, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { store } from "../../../store";
import SheetWrapper from "./SheetWrapper";
import { toastr } from "../../../components/toastr";
import Banner from "../../../components/banner";

class HelpDeskSheetContent extends Component {
	constructor(props) {
		super(props);
		this.state = { form: { phone: store.getState().user.phone, email: store.getState().user.email } };
	}

	componentDidMount() {}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form, sent: false });
	}

	send() {
		this.setState({ loading: true, sent: false });
		API.post("/api/helpcenter/send.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}
				if (result.data.status === "success") this.setState({ loading: false, sent: true });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<SheetWrapper
				title={this.props.t("help.sheet.title", "Hjälpcenter")}
				text={this.props.t("help.sheet.text", "Vår kundtjänst finns här för att hjälpa dig")}
			>
				<Wrapper>
					<TextField
						label={this.props.t("help.sheet.fields.message.label", "Beskriv ditt ärende")}
						placeholder={this.props.t("help.sheet.fields.message.placeholder", "Beskriv ditt ärende så detaljerat du kan")}
						onChange={this.updateForm.bind(this, "message")}
						value={this.state.form.message}
						multiline={4}
					/>
					<TextField
						label={this.props.t("help.sheet.fields.phone.label", "Telefonnummer")}
						placeholder={this.props.t("help.sheet.fields.phone.placeholder", "Skriv ditt telefonnummer här")}
						onChange={this.updateForm.bind(this, "phone")}
						value={this.state.form.phone}
					/>
					<TextField
						label={this.props.t("help.sheet.fields.email.label", "Mailadress")}
						placeholder={this.props.t("help.sheet.fields.email.placeholder", "Skriv en mailadress som du vill vi ska återkoppla på")}
						onChange={this.updateForm.bind(this, "email")}
						value={this.state.form.email}
					/>

					<div style={{ flex: 1, display: "flex", justifyContent: "end" }}>
						<Button
							primary
							onClick={this.send.bind(this)}
							loading={this.state.loading}
							disabled={this.state.loading || (!this.state.form.email && !this.state.form.phone) || this.state.sent}
						>
							{this.props.t("common.actions.send", "Skicka")}
						</Button>
					</div>
					{this.state.sent && (
						<Banner status="success" title={this.props.t("help,sheet.status.sent.title", "Meddelande skickat")}>
							<p>{this.props.t("help.sheet.status.sent.text", "Tack för ditt meddelande vi återkomma till dig snarast på telefon eller email")}</p>
						</Banner>
					)}
				</Wrapper>
			</SheetWrapper>
		);
	}
}
export default withTranslation(["help", "common"], { withRef: true })(HelpDeskSheetContent);

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	&&&&& {
		.Polaris-Select__Content,
		.Polaris-TextField,
		.Polaris-TextField__Input {
			background-color: var(--main1);
		}
	}
`;
