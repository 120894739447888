import { Collapsible, Icon } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { store } from "../../../../store";
import MemberAvatar from "../MemberAvatar";
import CalendarHelper from "./CalendarHelper";

class CalendarModalAttendeesListContent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const statuses = CalendarHelper.getAttendeesByStatus(this.props.form);
		const creator = CalendarHelper.getOrganizer(this.props.form);
		const foundCreator = store.getState().users.find((user) => user.email === creator?.email);
		const name = foundCreator?.name || creator?.name || creator?.email;

		return (
			<div style={{ display: "flex", gap: "2rem", flexDirection: "column" }}>
				<b>{this.props.t("calendar.attendees.organizer", "Organisatör")}</b>
				<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
					<MemberAvatar user={foundCreator || { name }} />
					<div>
						<span>{name}</span>
					</div>
				</div>
				{statuses.map(({ attendees, status }, index) => (
					<div key={index}>
						<div
							style={{ cursor: "pointer", padding: "1rem 0", display: "flex", gap: "0.5rem", fontWeight: "bold" }}
							onClick={() => this.setState((c) => ({ [`${status}closed`]: !c[`${status}closed`] }))}
						>
							<span style={{ rotate: this.state[`${status}closed`] ? "90deg" : "0deg", transition: "rotate 250ms" }}>
								<Icon source={ChevronRightMinor} />
							</span>
							<span>
								{CalendarHelper.getInlineStatusLabel(status).capitalize()}: {attendees && attendees.length}
							</span>
						</div>
						<Collapsible open={!this.state[`${status}closed`]}>
							{attendees &&
								attendees.map((attendee, index) => {
									const email = attendee.email || typeof attendee === "string" ? attendee : "";
									const name = attendee.name || attendee.email || typeof attendee === "string" ? attendee : "";
									const foundUser = store.getState().users.find((user) => user.email === email);

									return (
										<div key={index} style={{ display: "flex", gap: "1rem", alignItems: "center", padding: "0.5rem 0" }}>
											<MemberAvatar user={foundUser || { name, email }} tooltip />
											<div>
												<p>{name}</p>
												<p style={{ color: "rgb(150,150,150)" }}>
													{attendee.type === "required" || !attendee.optional
														? this.props.t("calendar.attendees.type.required", "obligatorisk")
														: this.props.t("calendar.attendees.type.optional", "valfri")}
												</p>
											</div>
										</div>
									);
								})}
						</Collapsible>
					</div>
				))}
			</div>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(CalendarModalAttendeesListContent);
