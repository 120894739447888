import React, { Component } from "react";
import { Stack, Button, Icon, ActionList } from "@shopify/polaris";
import {
	CircleChevronDownMinor,
	MinimizeMajor,
	MaximizeMajor,
	DragHandleMinor,
	CircleRightMajor,
	CircleTickOutlineMinor,
	CircleDisableMinor,
	DeleteMajor,
	ArchiveMajor,
	ExportMinor,
} from "@shopify/polaris-icons";
import { toastr as t } from "react-redux-toastr";
import httpBuildQuery from "http-build-query";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import ColumnHeaderSortButton from "./ColumnHeaderSortButton.js";
import ColumnHeader from "./ColumnHeader.js";
import ColumnCreator from "./ColumnCreator.js";
import BoardSelector from "./components/BoardSelector.js";
import BoardHelper from "./BoardHelper.js";
import Popover from "../../components/Popover.js";
import MissingColumnConfirmationModal from "./components/MissingColumnConfirmationModal.js";
import ColorPicker from "../../components/ColorPicker.js";

class ColumnHeaderRow extends Component {
	constructor(props) {
		super(props);
		this.stopResizeCallback = this.stopResize.bind(this);
		this.resizeDragCallback = this.resizingColumn.bind(this);
		this.stopDragColumnCallback = this.onDragColumnEnd.bind(this);
		this.moveColumnCallback = this.onDragColumnMove.bind(this);
		this.state = {
			editingTitle: false,
			title: props.group.title,
			showContext: false,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.group.title != this.state.title) {
			this.setState({ title: props.group.title });
		}
	}

	expandGroup() {
		this.props.group.collapsed = false;
		store.dispatch({ type: "UPDATE_BOARD_GROUP", group: this.props.group });
		API.put("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + ".json", { collapsed: false }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	onCollapse() {
		this.props.group.collapsed = true;
		store.dispatch({ type: "UPDATE_BOARD_GROUP", group: this.props.group });
		API.put("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + ".json", { collapsed: true }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	changeColor(color) {
		this.props.group.color = color;
		store.dispatch({
			type: "UPDATE_BOARD_GROUP",
			group: Object.assign({}, this.props.group),
		});

		API.put("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + ".json", { color }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => toastr.error(error));
	}

	startResize(column, event) {
		window.addEventListener("mouseup", this.stopResizeCallback);
		window.addEventListener("mousemove", this.resizeDragCallback);
		this.resizingOriginalWidth = column ? column.width : BoardHelper.getBoard(this.props.board_id).title_width;
		this.resizingColumnObject = column || null;
		this.resizingColumn = event.pageX;
		this.resizingMouseX = event.pageX;
	}

	resizingColumn(event) {
		const delta = event.pageX - this.resizingMouseX;

		const classHandle = "column-" + (this.resizingColumnObject ? this.resizingColumnObject.id : "title");

		let newWidth = this.resizingOriginalWidth + delta;

		if (newWidth < 60) {
			newWidth = 60;
		}
		if (this.resizingColumnObject) {
			if (newWidth > 500) {
				newWidth = 500;
			}
		}

		$("." + classHandle).css({
			flexBasis: newWidth,
			minWidth: newWidth,
			maxWidth: newWidth,
		});
	}

	stopResize(event) {
		window.removeEventListener("mouseup", this.stopResizeCallback);
		window.removeEventListener("mousemove", this.resizeDragCallback);
		const delta = event.pageX - this.resizingMouseX;

		const classHandle = "column-" + (this.resizingColumnObject ? this.resizingColumnObject.id : "title");

		let newWidth = this.resizingOriginalWidth + delta;

		if (newWidth < 60) {
			newWidth = 60;
		}
		if (this.resizingColumnObject) {
			if (newWidth > 500) {
				newWidth = 500;
			}
		}

		$("." + classHandle).css({
			flexBasis: newWidth,
			minWidth: newWidth,
			maxWidth: newWidth,
		});

		if (this.resizingColumnObject) {
			API.put("/api/boards/" + this.props.board_id + "/columns/" + this.resizingColumnObject.id + ".json", { width: newWidth }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					BoardHelper.updateColumn(result.data.column);
				})
				.catch((error) => {
					toastr.error(error);
				});
		} else {
			const board = BoardHelper.getBoard(this.props.board_id);
			board.title_width = newWidth;
			store.dispatch({ type: "UPDATE_BOARD", board });
			/*
			API.put("/api/boards/" + this.props.board_id + ".json", { title_width: newWidth }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					store.dispatch({ type: "UPDATE_BOARD", board: result.data.board });
				})
				.catch((error) => {
					toastr.error(error);
				});
			*/
		}
	}

	onDragColumnStart(column, event) {
		event.preventDefault();
		this.setState({ draggingColumn: column });
		window.addEventListener("mouseup", this.stopDragColumnCallback);
		$(".board-container").get(0).addEventListener("mousemove", this.moveColumnCallback);
	}

	onDragColumnMove(event) {
		let columnOffset = 0;

		if ($(document).width() >= 769) {
			if ($(".Polaris-Frame").length > 0) {
				columnOffset += 240; // Left menu
			}
			// columnOffset += 32; //Spacer
		} else {
			// columnOffset += 20; //Spacer
		}

		$(".column-header").removeClass("drag-over");

		let columnStart = BoardHelper.getBoard(this.props.board_id).title_width;
		for (let i = 0; i < BoardHelper.getBoard(this.props.board_id).columns.length; i++) {
			const columnEnd = columnStart + BoardHelper.getBoard(this.props.board_id).columns[i].width;
			const position = event.clientX - columnOffset + $(".board-container").scrollLeft();

			if (
				position >= columnStart &&
				position <= columnEnd &&
				BoardHelper.getBoard(this.props.board_id).columns[i].id != this.state.draggingColumn.id
			) {
				const classHandle = "column-" + BoardHelper.getBoard(this.props.board_id).columns[i].id;
				$(".column-header." + classHandle).addClass("drag-over");
				this.currentDropColumn = BoardHelper.getBoard(this.props.board_id).columns[i];
				return;
			}
			this.currentDropColumn = null;
			columnStart += BoardHelper.getBoard(this.props.board_id).columns[i].width;
		}
	}

	onDragColumnEnd(/* event */) {
		if (this.currentDropColumn) {
			let direction = "left";
			const newArr = [];
			let position = null;
			for (let i = 0; i < BoardHelper.getBoard(this.props.board_id).columns.length; i++) {
				if (BoardHelper.getBoard(this.props.board_id).columns[i].id == this.state.draggingColumn.id) {
					direction = "right";
				}

				if (direction == "left" && BoardHelper.getBoard(this.props.board_id).columns[i].id == this.currentDropColumn.id) {
					position = i;
					newArr.push(this.state.draggingColumn);
				}
				if (BoardHelper.getBoard(this.props.board_id).columns[i].id != this.state.draggingColumn.id) {
					newArr.push(BoardHelper.getBoard(this.props.board_id).columns[i]);
				}
				if (direction == "right" && BoardHelper.getBoard(this.props.board_id).columns[i].id == this.currentDropColumn.id) {
					position = i;
					newArr.push(this.state.draggingColumn);
				}
			}

			API.put("/api/boards/" + this.props.board_id + "/columns/" + this.state.draggingColumn.id + ".json", { position }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
					}
				})
				.catch((error) => {
					toastr.error(error);
				});

			const board = BoardHelper.getBoard(this.props.board_id);
			board.columns = newArr;
			store.dispatch({ type: "UPDATE_BOARD", board });
		}
		window.removeEventListener("mouseup", this.stopDragColumnCallback);
		$(".board-container").get(0).removeEventListener("mousemove", this.moveColumnCallback);
		this.setState({ draggingColumn: null });
		$(".column-header").removeClass("drag-over");
	}

	changeGroupTitle() {
		this.setState({ editingTitle: false, saving: true });
		if (this.state.title != this.props.group.title) {
			this.props.group.title = this.state.title;

			store.dispatch({ type: "UPDATE_BOARD_GROUP", group: this.props.group });

			API.put("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + ".json", { title: this.state.title }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	moveGroupToBoard(board, force = false) {
		this.setState({ saving: true, showContext: false });
		const data = { board };
		if (force) {
			data.force = true;
			data.custom_mappings = this.state.missing_columns;
		}
		API.post("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + "/move.json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.unmapped) {
					this.setState({
						saving: false,
						missing_columns: result.data.unmapped,
						mapped_columns: result.data.mappings,
						failed_move_board: result.data.board,
					});
					return;
				}
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("column.header.row.responses.moved_group", "Flyttade grupp") + " " + this.props.group.title);
				this.setState({ saving: false, failed_move_board: false });
				BoardHelper.removeGroup(this.props.group.id);
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	archiveGroup(undoArchive, g) {
		this.setState({ saving: true, showContext: false });
		const group = g || this.props.group;

		if (undoArchive) {
			group.archived = false;
			// BoardHelper.addRowAtOldPosition(group.id);
		} else {
			BoardHelper.removeGroup(group.id);
		}

		API.put("/api/boards/" + this.props.board_id + "/groups/" + group.id + ".json", { archived: !undoArchive }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (result.data.group && result.data.group.archived) {
					t.warning(
						this.props.t("column.header.row.responses.group_archived", "Arkivarde grupp"),
						this.props.t("column.header.row.responses.revert", "Klicka här för att ångra"),
						{
							timeOut: 12000,
							onToastrClick: () => this.archiveGroup(true, result.data.group),
						}
					);
				} else {
					toastr.success(
						`${this.props.t("column.header.row.responses.group_revert_archived", "Ångrade arkiverad grupp")} "${
							group.title || this.props.group.title
						}"`
					);
				}

				this.setState({ saving: false });
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeGroup() {
		this.setState({ saving: true, showContext: false });
		API.delete("/api/boards/" + this.props.board_id + "/groups/" + this.props.group.id + ".json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				BoardHelper.removeGroup(this.props.group.id);
				toastr.success(this.props.t("column.header.row.responses.removed_group", "Tog bort grupp") + " " + this.props.group.title);
				this.setState({ saving: false });
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	shouldShowArchives() {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("archived") == 1;
	}

	render() {
		if (this.props.group.collapsed || this.props.draggingGroup) {
			return (
				<div
					onClick={this.expandGroup.bind(this)}
					className="fixed-column board-padding-left board-padding-right"
					style={{
						display: "block",
						marginBottom: this.props.draggingGroup ? 0 : 20,
						marginRight: -10000,
						width: $(".Polaris-Frame__Content").width(),
						height: 40,
					}}
				>
					<div className="collapsed-group">
						<Stack spacing="none">
							<div className="board-show-hover">
								<span
									onClick={(event) => {
										event.stopPropagation();
									}}
								>
									<Stack spacing="extraTight" wrap={false}>
										<a onClick={this.expandGroup.bind(this)} style={{ cursor: "pointer" }}>
											<Icon source={MaximizeMajor} color="inkLighter" />
										</a>
										<a
											onMouseDown={(e) => {
												e.preventDefault();
												this.props.onDragGroupStart(this.props.group);
											}}
											style={{ cursor: "move" }}
										>
											<Icon source={DragHandleMinor} color="inkLighter" />
										</a>

										<Popover
											active={this.state.colorPopover}
											onClose={() => this.setState({ colorPopover: false })}
											activator={
												<div
													onClick={() => {
														this.setState({ colorPopover: true });
													}}
													style={{
														borderRadius: "50%",
														width: 20,
														height: 20,
														backgroundColor: this.props.group.color,
														cursor: "pointer",
													}}
												/>
											}
										/>
									</Stack>
								</span>
							</div>
							<Stack.Item fill>
								<span className="overflow-ellipsis group-title" style={{ width: "100%", color: this.props.group.color }}>
									{this.props.group.title}
								</span>
							</Stack.Item>
						</Stack>
						<div
							style={{
								position: "absolute",
								top: 10,
								right: 15,
								color: this.props.group.color,
							}}
						>
							{this.props.count} {this.props.t("column.header.row.terms.rows", "rader")}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div style={{ display: "flex", flexWrap: "nowrap", width: this.props.width }}>
				<div
					className="fixed-column board-first-header column-title"
					style={{
						display: "flex",
						flexBasis: BoardHelper.getBoard(this.props.group.board_id).title_width,
						minWidth: BoardHelper.getBoard(this.props.group.board_id).title_width,
						maxWidth: BoardHelper.getBoard(this.props.group.board_id).title_width,
					}}
				>
					<div style={{ width: "100%" }}>
						<Stack spacing="none" wrap={false}>
							<div className="board-spacer" style={{ height: 40 }}>
								<Popover
									active={this.state.showContext}
									fixed
									activator={
										<div
											style={{
												textAlign: "center",
												width: "100%",
												paddingTop: 5,
											}}
										>
											<Button
												plain
												onClick={() => {
													this.setState({
														showContext: !this.state.showContext,
													});
												}}
											>
												<div
													className="icon-color"
													style={{
														color: this.props.group.color,
														fill: this.props.group.color,
													}}
												>
													<Icon source={CircleChevronDownMinor} />
												</div>
											</Button>
										</div>
									}
									onClose={() => {
										this.setState({ showContext: false });
									}}
								>
									{this.state.showMove ? (
										<BoardSelector onSelect={this.moveGroupToBoard.bind(this)} board={BoardHelper.getBoard(this.props.group.board_id)} />
									) : (
										<span
											onClick={(event) => {
												event.stopPropagation();
											}}
										>
											<ActionList
												items={[
													{
														content: this.props.selected
															? this.props.t("column.header.row.actions.deselect_all", "Avmarkera alla")
															: this.props.t("column.header.row.actions.select_all", "Markera alla"),
														icon: this.props.selected ? CircleDisableMinor : CircleTickOutlineMinor,
														onAction: () => {
															this.props.toggleSelection();
															this.setState({ showContext: false });
														},
													},
													{
														content: this.props.t("column.header.row.actions.move_to_board", "Flytta till board"),
														icon: CircleRightMajor,
														onAction: () => {
															this.setState({ showMove: true });
														},
													},
													{
														content: this.props.t("column.header.row.actions.export", "Exportera"),
														icon: ExportMinor,
														onAction: () => {
															const params = Object.assign(this.state.params || {}, { archived: 0, removed: 0 });
															window.open(
																`/api/boards/${this.props.group.board_id}/groups/${this.props.group.id}/export.xls?${httpBuildQuery(params)}`
															);
															this.setState({ showContext: false });
														},
													},
													this.props.group.archived
														? {
																content: this.props.t("column.header.row.actions.revert_archived_group", "Ångra arkiverad group"),
																icon: ArchiveMajor,
																onAction: () => {
																	this.archiveGroup(true);
																	// this.props.onArchiveGroup();
																	this.setState({ showContext: false });
																},
														  }
														: {
																content: this.props.t("column.header.row.actions.archived_group", "Arkiverad group"),
																destructive: true,
																icon: ArchiveMajor,
																onAction: () => {
																	this.archiveGroup();
																	// this.props.onArchiveGroup();
																	this.setState({ showContext: false });
																},
														  },
													{
														content: this.props.t("column.header.row.actions.remove_group", "Ta bort grupp"),
														destructive: true,
														icon: DeleteMajor,
														onAction: this.removeGroup.bind(this),
													},
												]}
											/>
										</span>
									)}
								</Popover>
							</div>
							<Stack.Item fill>
								<div
									className={
										"column-header" +
										(this.props.column
											? " hover-bg column-" +
											  this.props.column.id +
											  (this.state.focused ? " focused" : "") +
											  (this.state.showContext || this.state.saving ? " active" : "") +
											  (this.props.draggingColumn && this.props.draggingColumn.id == this.props.column.id ? " dragging" : "")
											: "")
									}
									style={
										this.props.column
											? {
													display: "flex",
													flexBasis: this.props.column.width,
													minWidth: this.props.column.width,
													maxWidth: this.props.column.width,
											  }
											: null
									}
								>
									<div style={{ width: "100%" }}>
										<Stack spacing="none" wrap={false}>
											{!this.state.editingTitle ? (
												<div className="board-show-hover">
													<span
														onClick={(event) => {
															event.stopPropagation();
														}}
													>
														<Stack spacing="extraTight" wrap={false}>
															<a onClick={this.onCollapse.bind(this)} style={{ cursor: "pointer" }}>
																<Icon source={MinimizeMajor} color="inkLighter" />
															</a>
															<a
																onMouseDown={(e) => {
																	e.preventDefault();
																	this.props.onDragGroupStart(this.props.group);
																}}
																style={{ cursor: "move" }}
															>
																<Icon source={DragHandleMinor} color="inkLighter" />
															</a>
															<ColorPicker
																style={{
																	borderRadius: "50%",
																	width: "20px",
																	height: "20px",
																}}
																color={this.props.group.color}
																onChange={(color) => {
																	this.changeColor(color);
																}}
															/>
														</Stack>
													</span>
												</div>
											) : null}
											<Stack.Item fill>
												{this.state.editingTitle ? (
													<div
														onClick={(event) => {
															event.stopPropagation();
														}}
													>
														<div style={{ border: "1px dashed #777" }}>
															<input
																ref={(input) => {
																	if (input) {
																		input.focus();
																	}
																}}
																onKeyDown={(event) => {
																	if (event.key === "Enter") {
																		this.changeGroupTitle();
																	} else if (event.key === "Escape") {
																		this.setState({
																			title: this.props.group.title,
																			editingTitle: false,
																		});
																	}
																}}
																onChange={(event) => {
																	this.setState({ title: event.target.value });
																}}
																onBlur={this.changeGroupTitle.bind(this)}
																type="text"
																value={this.state.title}
																style={{
																	fontSize: "1.6rem",
																	fontWeight: 600,
																	display: "block",
																	color: this.props.group.color,
																	width: "100%",
																	border: 0,
																}}
															/>
														</div>
													</div>
												) : (
													<div
														onClick={() => {
															this.setState({ editingTitle: true });
														}}
													>
														<span
															className="overflow-ellipsis group-title"
															style={{
																width: "100%",
																cursor: "text",
																color: this.props.group.color,
															}}
														>
															{this.props.group.title}
														</span>
													</div>
												)}
											</Stack.Item>
										</Stack>
										{!this.state.draggingColumn && !this.state.focused && !this.state.editingTitle ? (
											<a className="resize-handle" onMouseDown={this.startResize.bind(this, null)} />
										) : null}
									</div>
								</div>
							</Stack.Item>
						</Stack>
						<ColumnHeaderSortButton column_id="title" sorting={this.props.sorting} onChangeSorting={this.props.onChangeSorting} />
					</div>
				</div>
				{BoardHelper.getBoard(this.props.group.board_id).columns.map((column) => (
					<ColumnHeader
						key={column.id}
						group={this.props.group}
						column={column}
						onUpdateColumn={(column) => {
							BoardHelper.updateColumn(column);
						}}
						sorting={this.props.sorting}
						onChangeSorting={this.props.onChangeSorting}
						onRemoveColumn={this.props.onRemoveColumn}
						onDragStart={this.onDragColumnStart.bind(this, column)}
						draggingColumn={this.state.draggingColumn}
						onResizeStart={this.startResize.bind(this, column)}
						board={BoardHelper.getBoard(this.props.group.board_id)}
						openCalendarModal={this.props.openCalendarModal}
					/>
				))}
				{!this.shouldShowArchives() && (
					<div
						className="column-header"
						style={{
							display: "flex",
							minWidth: 40,
							flex: 1,
						}}
					>
						<ColumnCreator board={BoardHelper.getBoard(this.props.group.board_id)} />
					</div>
				)}
				{this.state.failed_move_board ? (
					<MissingColumnConfirmationModal
						open
						loading={this.state.saving}
						board={this.state.failed_move_board}
						columns={this.state.missing_columns}
						mapped_columns={this.state.mapped_columns}
						onChange={(v) => {
							this.setState({ missing_columns: v });
						}}
						onConfirm={() => {
							this.moveGroupToBoard(this.state.failed_move_board, true);
						}}
						onClose={() => {
							this.setState({ failed_move_board: null });
						}}
					/>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["column", "common"], { withRef: true })(ColumnHeaderRow);
