import styled from "styled-components";

const StyledSectionWrapper = styled.div<{
	gridArea: string;
}>`
	grid-area: ${({ gridArea }) => gridArea};
	/* height: 100%; */

	h2 {
		margin-bottom: 20px;
		font-size: 2.5rem;
		font-weight: 700;
		line-height: 3rem;
	}
`;

export const SectionWrapper = ({ children, title = "", gridArea }) => {
	return (
		<StyledSectionWrapper gridArea={gridArea}>
			{title && <h2>{title}</h2>}
			{children}
		</StyledSectionWrapper>
	);
};

export const GraphWrapper = styled.div`
	flex: 1;
	position: relative;
	height: 100%;
`;

export const Wrapper = styled.div.attrs<{ selectedTabId: string }>(({ selectedTabId }) => ({ className: `mainDashboard ${selectedTabId}` }))`
	position: relative;
`;

export const SelectorLabel = styled.span`
	color: var(--textColor2);
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1rem;

	.Polaris-Select__Content {
		color: $textColor;
		padding-left: 0.8rem;
	}

	${SelectorLabel},
	.Polaris-Select__Content {
		font-weight: 500;
		font-size: 13px;
		line-height: 15px;
	}

	&&& {
		.Polaris-Select {
			width: max-content;

			.Polaris-Select__SelectedOption {
				padding-right: 0.3rem;
			}

			.Polaris-Select__Icon svg {
				fill: var(--textColor);
			}

			.Polaris-Select__Backdrop,
			.Polaris-Select__Backdrop::after,
			.Polaris-Select__Content {
				background: transparent;
				border: none;
				box-shadow: none;
			}
		}
	}
`;
