/* eslint-disable react/jsx-props-no-spreading */
import { Icon, OptionList as ShopifyOptionList } from "@shopify/polaris";
import type { OptionListProps as ShopifyOptionListProps } from "@shopify/polaris";
import Popover from "../Popover";
import styled, { css } from "styled-components";
import { SortDescendingMajor } from "@shopify/polaris-icons";
import TextField from "../TextField";
import { useMemo, useState } from "react";

interface OptionListProps extends ShopifyOptionListProps {
	popover?: boolean;
	label?: string;
	onClose?: (any) => void;
	style?: React.CSSProperties;
	preferredAlignment?: "left" | "right";
	disabled?: boolean;
	search?: boolean;
	labelPrefix?: string;
}

const OptionList = ({ popover, label, onClose, style, preferredAlignment, disabled, labelPrefix, ...rest }: OptionListProps) => {
	const [search, setSearch] = useState("");
	const options = useMemo(() => {
		if (!search) return rest.options;
		if (!rest.options?.length) return [];
		return rest.options?.filter((option) => (option.label as string).toLowerCase().includes(search.toLowerCase())) || [];
	}, [search, rest.options]);

	const content = (
		<Wrapper>
			{search && <TextField placeholder="sök.." disabled={disabled} value={search} onChange={setSearch} />}
			<ShopifyOptionList {...rest} options={options} />
		</Wrapper>
	);

	const handleClose = () => {
		setSearch("");
		onClose?.(null);
	};

	if (popover) {
		const selected = rest?.options?.filter((option) => rest.selected.includes(option.value)) || [];

		return (
			<Popover
				preferredAlignment={preferredAlignment}
				onClose={handleClose}
				activator={
					<SelectButton style={style} disabled={!!disabled}>
						{label || (
							<span style={{ display: "flex", gap: "1rem" }}>
								{labelPrefix || ""}
								{selected[0]?.label}
								{` ${selected?.length > 1 ? ` (+ ${selected.length - 1})` : ""}` || ""}
							</span>
						)}

						<Icon source={SortDescendingMajor} />
					</SelectButton>
				}
			>
				{content}
			</Popover>
		);
	}

	return content;
};

export default OptionList;

const SelectButton = styled.button<{ disabled: boolean }>`
	border: none;
	background: transparent;
	color: var(--textColor);
	border-color: var(--main3);
	background: var(--main2);
	background-color: var(--main2);
	border-radius: 0.5rem;
	cursor: pointer;
	min-height: 1rem;
	padding: 0.5rem 0.8rem 0.5rem 1.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	transition: opacity 250ms;

	.Polaris-Icon {
		margin: 0;
	}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			cursor: unset;
		`}
`;

const Wrapper = styled.div``;
