import { Stack } from "@shopify/polaris";
import React, { Component } from "react";
import Popover from "./Popover";

class ColorPicker extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
		this.colors = [
			"#037f4c",
			"#00c875",
			"#0086c0",
			"#bb3354",
			"#784bd1",
			"#ff642e",
			"#ffcb00",
			"#ff5ac4",
			"#cab641",
			"#808080",
			"#6cf",
			"#c4c4c4",
			"#9cd326",
			"#ff158a",
			"#7f5347",
			"#e2445c",
			"#579bfc",
			"#a25ddc",
			"#fdab3d",
		];
	}

	handleToggle() {
		this.setState((c) => ({ open: !c.open }));
	}

	handleClose() {
		this.setState({ open: false });
	}

	render() {
		const colors = this.colors;
		const { onChange, color, style = {} } = this.props;

		return (
			<div style={{ position: "relative" }}>
				<Popover
					active={this.state.open}
					activator={
						<div
							className="status-colorpicker-trigger"
							style={Object.assign({ backgroundColor: color, height: 35, width: 20, cursor: "pointer" }, style)}
							onClick={this.handleToggle.bind(this)}
						/>
					}
					onClose={this.handleClose.bind(this)}
				>
					<div
						style={{ width: "250px", background: "rgb(47,52,55)", padding: 5 }}
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Stack spacing="tight" distribution="leading" vertical={false}>
							{colors.map((color) => (
								<div
									onClick={(e) => onChange(color, e)}
									key={color}
									style={{
										borderRadius: "50%",
										width: 20,
										height: 20,
										backgroundColor: color,
										cursor: "pointer",
									}}
								/>
							))}
						</Stack>
					</div>
				</Popover>
			</div>
		);
	}
}

export default ColorPicker;
