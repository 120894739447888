import moment from "moment";

const getMutableInvoice = (invoice: FortnoxInvoiceType | null): Partial<FortnoxInvoiceType> => {
	const ignoredKeys: string[] = [
		"BasisTaxReduction",
		"TotalToPay",
		"TotalExcludingVAT",
		"AdministrationFeeVAT",

		"DeliveryState",
		"DeliveryZipCode",
		"DeliveryName",
		"DeliveryDate",
		"DeliveryCountry",
		"DeliveryCity",
		"DeliveryAddress1",
		"DeliveryAddress2",
	];

	return Object.entries(invoice || {}).reduce(
		(acc, [key, value]) => {
			if (ignoredKeys.includes(key)) {
				return acc;
			}

			if (key === "InvoiceRows") {
				acc[key] = getMutableInvoiceRows(value as (FortnoxInvoiceRowType | Partial<FortnoxInvoiceRowType>)[]);
				return acc;
			}

			acc[key] = value;
			return acc;
		},
		{
			InvoiceRows: [],
			InvoiceDate: invoice?.InvoiceDate || moment().format("YYYY-MM-DD"),
			DueDate: invoice?.DueDate || moment().add(30, "days").format("YYYY-MM-DD"),
		} as Partial<FortnoxInvoiceType>
	);
};
export default getMutableInvoice;

const getMutableInvoiceRows = (invoiceRows: (FortnoxInvoiceRowType | Partial<FortnoxInvoiceRowType>)[]): Partial<FortnoxInvoiceRowType>[] => {
	const ignoredKeys = ["TotalExcludingVAT"];

	return (
		(invoiceRows.map((invoiceRow) => {
			return Object.keys(invoiceRow || {})
				.filter((key) => !ignoredKeys.includes(key))
				.reduce((acc, key) => {
					acc[key] = invoiceRow?.[key];
					return acc;
				}, {} as Partial<FortnoxInvoiceRowType>);
		}) as Partial<FortnoxInvoiceRowType>[]) || []
	);
};
