import React, { Component } from "react";
import { Button, Popover, DatePicker, PageActions, FormLayout, Stack, Select, TextField } from "@shopify/polaris";
import { CalendarMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import MyPopover from "./Popover";

class Datepicker extends Component {
	constructor(props) {
		super(props);

		const viewDate = new Date();
		viewDate.setMonth(viewDate.getMonth() - 1);

		this.state = {
			currentFromDate: props.from,
			currentToDate: props.to,
			active: false,
			custom: false,
			caption: this.props.caption,
			temporaryCaption: "",
			month: viewDate.getMonth(),
			year: viewDate.getFullYear(),
			selected: {
				start: props.from,
				end: props.to,
			},
			from: this.getYmd(props.from),
			to: this.getYmd(props.to),
			selectedValue: "last_30_days",
		};
	}

	isValidDate(d) {
		return d instanceof Date && !Number.isNaN(d);
	}

	togglePopover() {
		if (!this.state.focus) {
			this.setState({ active: !this.state.active });
		}
	}

	updateFromDate(value) {
		this.setState({ from: value });

		const from = new Date(value);
		const to = new Date(this.state.to);

		if (this.isValidDate(from) && this.isValidDate(to)) {
			this.setRange(from, to, false);
			this.setState({ custom: true, selectedValue: "custom", temporaryCaption: this.props.t("date.terms.custom", "custom") });
		}
	}

	updateToDate(to) {
		this.setState({ to });

		const from = new Date(this.state.from);
		const end = new Date(to);

		if (this.isValidDate(from) && this.isValidDate(end)) {
			this.setRange(from, end, false);
			this.setState({ custom: true, selectedValue: "custom", temporaryCaption: this.props.t("date.terms.custom", "custom") });
		}
	}

	handleSelectChange(value) {
		if (value == "today") {
			this.setRange(new Date(), new Date());
			this.setState({ temporaryCaption: this.props.t("date.terms.today", "Idag") });
		} else if (value == "yesterday") {
			const yesterday = new Date();
			yesterday.setDate(yesterday.getDate() - 1);
			this.setRange(yesterday, yesterday);
			this.setState({ temporaryCaption: this.props.t("date.terms.yesterday", "Igår") });
		} else if (value == "last_7_days") {
			const last7day = new Date();
			last7day.setDate(last7day.getDate() - 7);
			this.setRange(last7day, new Date());
			this.setState({ temporaryCaption: this.props.t("date.terms.last_7_days", "Senaste 7 dagarna") });
		} else if (value == "last_30_days") {
			const last30day = new Date();
			last30day.setDate(last30day.getDate() - 30);
			this.setRange(last30day, new Date());
			this.setState({ temporaryCaption: this.props.t("date.terms.last_30_days", "Senaste 30 dagarna") });
		} else if (value == "last_90_days") {
			const last90day = new Date();
			last90day.setDate(last90day.getDate() - 90);
			this.setRange(last90day, new Date());
			this.setState({ temporaryCaption: this.props.t("date.terms.last_90_days", "Senaste 90 dagarna") });
		} else if (value == "last_month") {
			const x = new Date();
			x.setDate(1);
			x.setMonth(x.getMonth() - 1);

			const y = new Date();
			y.setDate(0);
			this.setRange(x, y);
			this.setState({ temporaryCaption: this.props.t("date.terms.last_month", "Förra månaden") });
		} else if (value == "last_year") {
			const x = new Date();
			x.setDate(1);
			x.setMonth(0);
			x.setFullYear(x.getFullYear() - 1);

			const y = new Date();
			y.setDate(31);
			y.setMonth(11);
			y.setFullYear(y.getFullYear() - 1);

			this.setRange(x, y);
			this.setState({ temporaryCaption: this.props.t("date.terms.last_year", "Förra året") });
		}
		this.setState({ selectedValue: value, custom: false });
	}

	getYmd(date) {
		let month = date.getMonth() + 1; // months from 1-12
		let day = date.getDate();
		const year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	isDifferentDates() {
		if (
			this.getYmd(this.state.currentFromDate) != this.getYmd(this.state.selected.start) ||
			this.getYmd(this.state.currentToDate) != this.getYmd(this.state.selected.end)
		) {
			return true;
		}
		return false;
	}

	setRange(from, to, updInput) {
		const selected = {
			start: from,
			end: to,
		};

		if (updInput === false) {
			this.setState({ selected });
		} else {
			this.setState({ from: this.getYmd(from), to: this.getYmd(to), selected });
		}
	}

	handleRangeChange(value) {
		this.setRange(value.start, value.end);
		this.setState({ custom: true, selectedValue: "custom", temporaryCaption: this.props.t("date.terms.custom", "custom") });
	}

	handleMonthChange(month, year) {
		this.setState({
			month,
			year,
		});
	}

	changeRange() {
		this.setState({
			active: false,
			caption: this.state.temporaryCaption,
			currentFromDate: this.state.selected.start,
			currentToDate: this.state.selected.end,
		});
		this.props.onChange(this.state.selected.start, this.state.selected.end, this.state.temporaryCaption);
	}

	render() {
		const { month, year, selected } = this.state;

		const activator = (
			<div style={this.props.style}>
				<Button
					disabled={this.props.disabled}
					primary={this.props.primary}
					plain={this.props.plain}
					icon={CalendarMajor}
					onClick={this.togglePopover.bind(this)}
					disclosure
				>
					{this.state.caption == "custom"
						? this.getYmd(this.state.currentFromDate) + " - " + this.getYmd(this.state.currentToDate)
						: this.state.caption}
				</Button>
			</div>
		);

		const options = [
			{ label: this.props.t("date.terms.today", "Idag"), value: "today" },
			{ label: this.props.t("date.terms.yesterday", "Igår"), value: "yesterday" },
			{ label: this.props.t("date.terms.last_7_days", "Senaste 7 dagarna"), value: "last_7_days" },
			{ label: this.props.t("date.terms.last_30_days", "Senaste 30 dagarna"), value: "last_30_days" },
			{ label: this.props.t("date.terms.last_90_days", "Senaste 90 dagarna"), value: "last_90_days" },
			{ label: this.props.t("date.terms.last_month", "Förra månaden"), value: "last_month" },
			{ label: this.props.t("date.terms.last_year", "Förra året"), value: "last_year" },
		];

		if (this.state.custom) {
			options.unshift({ label: this.props.t("date.terms.own", "Egen"), value: "custom" });
		}

		return (
			<MyPopover active={this.state.active} activator={activator} onClose={this.togglePopover.bind(this)} sectioned>
				<Popover.Pane>
					<Popover.Section>
						<FormLayout>
							<Select
								label={this.props.t("date.fields.date.label", "Datum")}
								options={options}
								onChange={this.handleSelectChange.bind(this)}
								value={this.state.selectedValue}
							/>
							<Stack distribution="fillEvenly">
								<TextField
									onFocus={() => this.setState({ focus: true })}
									onBlur={() => this.setState({ focus: false })}
									label={this.props.t("date.fields.from.label", "Från")}
									onChange={this.updateFromDate.bind(this)}
									value={this.state.from}
								/>
								<TextField
									onFocus={() => this.setState({ focus: true })}
									onBlur={() => this.setState({ focus: false })}
									label={this.props.t("date.fields.to.label", "Till")}
									onChange={this.updateToDate.bind(this)}
									value={this.state.to}
								/>
							</Stack>
							<DatePicker
								month={month}
								year={year}
								onChange={this.handleRangeChange.bind(this)}
								onMonthChange={this.handleMonthChange.bind(this)}
								selected={selected}
								allowRange
								multiMonth
							/>{" "}
							<div style={{ paddingLeft: 15, paddingRight: 15 }}>
								<PageActions
									primaryAction={{
										content: this.props.t("common.actions.change", "Ändra"),
										disabled: !this.isDifferentDates(),
										onAction: this.changeRange.bind(this),
									}}
									secondaryActions={[
										{
											content: this.props.t("common.actions.close", "Stäng"),
											onAction: this.togglePopover.bind(this),
										},
									]}
								/>
							</div>
						</FormLayout>
					</Popover.Section>
				</Popover.Pane>
				<Popover.Pane fixed>
					<div style={{ paddingLeft: 15, paddingRight: 15 }}>
						<PageActions
							primaryAction={{
								content: this.props.t("common.actions.change", "Ändra"),
								disabled: !this.isDifferentDates(),
								onAction: this.changeRange.bind(this),
							}}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: this.togglePopover.bind(this),
								},
							]}
						/>
					</div>
				</Popover.Pane>
			</MyPopover>
		);
	}
}
export default withTranslation(["date", "common"], { withRef: true })(Datepicker);
