import { Icon } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor, MobileCancelMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr";
import Slide from "./Slide";

class Announcements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: this.props.open || !!(this.props.slides && this.props.slides.length),
			// open: false,
			readSlides: [],
			selectedSlide: typeof props.selected === "number" ? props.selected : 0,
			slides: this.props.slides || [],
			loading: this.props.loading,
		};
		this.markAsReadOnOut = this.handleMarkAsReadOnOut.bind(this);
	}

	componentDidMount() {
		window.addEventListener("beforeunload", this.markAsReadOnOu);
		const URLParams = new URLSearchParams(window.location.search);
		if (URLParams.get("addmscalendar") || URLParams.get("addgscalendar")) {
			const ele = document.querySelector(".IntroductionSlide-inner");
			if (ele) ele.classList.add("open");
		}

		if (this.props.fetchNewsletters) {
			this.setState({ loading: true });
			API.get("/api/announcements/unread.json")
				.then((res) => {
					if (res.data.error) {
						this.setState({ loading: false });
						toastr.error(res.data.error);
						return;
					}
					this.setState({ slides: res.data.announcements, open: !!(res.data.announcements || []).length, loading: false });
					// this.setState({ slides: res.data.announcements, loading: false });
				})
				.catch((e) => {
					console.error("announcements/unread.json e:", e);
				});
		}
	}

	componentWillUnmount() {
		this.markAsReadOnOut();
		window.removeEventListener("beforeunload", this.markAsReadOnOut); // remove the event handler for normal unmounting
	}

	handleMarkAsReadOnOut() {
		this.markAsRead();
	}

	markAsRead(lastSlideAsArray = []) {
		const readSlides = [].concat(this.state.readSlides).concat(lastSlideAsArray) || [];
		const readSlidesIds = readSlides.map(({ id } = {}) => id).filter((i) => i);
		if (readSlidesIds && readSlidesIds.length) {
			API.post("/api/announcements/read.json", { ids: readSlidesIds })
				.then((res) => {
					if (res.data.error) {
						toastr(res.data.error);
						return;
					}
					this.setState({ readSlides: [] });
				})
				.catch((e) => {
					console.error("announcements/read.json e:", e);
				});
		}
	}

	onClose(lastSlideAsArray = []) {
		this.setState({ open: false, selectedSlide: 0 });
		this.markAsRead(lastSlideAsArray);
	}

	preventClose(e) {
		e.preventDefault();
		e.stopPropagation();
	}

	next(slide) {
		this.setState((c) => {
			if (c.selectedSlide >= this.state.slides.length - 1) {
				this.state.readSlides.push(slide);
				return { readSlides: this.state.readSlides, open: false, selectedSlide: 0 };
			}

			this.state.readSlides.push(slide);
			return { selectedSlide: ++c.selectedSlide, readSlides: this.state.readSlides };
		});
	}

	prev() {
		this.setState((c) => ({ selectedSlide: --c.selectedSlide }));
	}

	render() {
		const slides = this.state.slides || [];

		if (this.state.open) {
			return ReactDOM.createPortal(
				<div className="IntroductionSlide">
					<div className={`IntroductionSlide-inner${this.state.loading ? " loading" : ""}`} onClick={this.preventClose.bind(this)}>
						<div className="header">
							{slides && slides.length && !!this.state.selectedSlide && !this.props.showNavigation ? (
								<button disabled={this.state.selectedSlide === 0} className="prev" onClick={this.prev.bind(this)}>
									<Icon source={ChevronLeftMinor} />
								</button>
							) : (
								<div />
							)}
							{this.state.selectedSlide + 1 >= (slides && slides.length) && (
								<button className="close" onClick={this.onClose.bind(this, slides.slice(-1))}>
									<Icon source={MobileCancelMajor} />
								</button>
							)}
						</div>
						<div className="slideContainer" style={{ transform: `translateX(-${this.state.selectedSlide * 100}%)` }}>
							{slides &&
								slides.map((slide, index) => (
									<Slide
										key={slide.id || index}
										slide={slide}
										next={this.next.bind(this, slide)}
										prev={this.prev.bind(this)}
										slides={slides}
										index={index}
										onClose={this.onClose.bind(this, slide)}
										history={this.props.history}
									/>
								))}
						</div>
						<div className={`footer ${this.props.showNavigation ? "navigate" : ""}`}>
							{this.props.showNavigation && (
								<button disabled={this.state.selectedSlide === 0} className="prev" onClick={this.prev.bind(this)}>
									<Icon source={ChevronLeftMinor} />
								</button>
							)}
							<span>
								{this.state.selectedSlide + 1} / {slides.length}
							</span>
							{this.props.showNavigation && (
								<button disabled={this.state.selectedSlide === slides.length - 1} className="prev" onClick={this.next.bind(this)}>
									<Icon source={ChevronRightMinor} />
								</button>
							)}
						</div>
					</div>
				</div>,
				document.querySelector("body")
			);
		}
		return null;
	}
}
export default withTranslation(["announcements", "common"], { withRef: true })(Announcements);
