import { ActionList, Button, Icon, Tooltip } from "@shopify/polaris";
import { CancelMajor, ChevronDownMinor, DeleteMajor, DragHandleMinor, ResetMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useMemo } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import TextField from "src/js/components/TextField";
import DropDown from "src/js/components/dropdown";
import { FortnoxRowWrapper } from "../styles";
import { toastr } from "src/js/components/toastr";
import API from "src/js/API";
import SelectSearchField from "src/js/components/SelectSearchField";
import { formatRowNumberToRead, formatRowToRead } from "../Utilities/formatRows";
import { useEventListener } from "src/js/hooks/UseEventListener";
import { getRowStats } from "../Utilities/getTotalStats";

type FortnoxOrderRowProps = {
	row: FortnoxOrderRowType | null;
	order: FortnoxOrderType;
	index?: number;
	onChange?: (index: number, row: FortnoxOrderRowType | null) => void;
	plain?: boolean;
	disabled?: boolean;
	prefix?: React.ReactNode;
	className?: string;
	columns: { header: string; handle: string }[];
} & WithTranslation;

const FortnoxOrderRow = ({
	order,
	row,
	t,
	index,
	onChange = () => {},
	disabled: propsDisabled,
	plain,
	prefix,
	className,
	columns,
}: FortnoxOrderRowProps) => {
	const [form, setForm] = React.useState<FortnoxOrderRowType | null>(row);
	const ref = React.useRef<HTMLDivElement>(null);
	useEventListener("keydown", (e) => {
		if (e.key === "Enter") {
			if (form && index !== undefined && [...(ref?.current?.querySelectorAll("input") || [])]?.some((el) => el === document.activeElement)) {
				onChange(index, formatRowToRead(form));
				(document.activeElement as any)?.blur?.();
			}
		}
	});

	useEffect(() => {
		setForm(row);
	}, [row]);

	const handleClear = useCallback(() => {
		const newRow = Object.entries(form || {})?.reduce((acc, [property, value]) => {
			acc[property] = typeof value === "number" ? 0 : "";

			return acc;
		}, {});

		setForm(newRow as FortnoxOrderRowType);
		if (index !== undefined) onChange(index, newRow as FortnoxOrderRowType);
	}, [form, index, onChange]);

	const handleChange = useCallback(
		(field: string, property = "") =>
			async (value: any) => {
				const newRow = { ...(form || {}) };

				if (field === "ArticleNumber") {
					if (!value || !value.ArticleNumber) {
						handleClear();
						return;
					}

					let newForm = formatRowToRead({
						...newRow,
						ArticleNumber: value.ArticleNumber,
						Article: value,
						Description: value.Description,
						OrderedQuantity: "1",
						DeliveredQuantity: "1",
						Unit: value.Unit,
						Price: value.Price || value.SalesPrice,
						Discount: value.Discount || 0,
						DiscountType: value.DiscountType || "PERCENT",
					}) as FortnoxOrderRowType;
					setForm(newForm);
					if (index !== undefined) onChange(index, newForm);

					try {
						const res = await API.get(`/api/fortnox/articles/${value.ArticleNumber}.json`);
						// const value = res.data as FortnoxArticleType;
						const fullValue = res?.data;

						newForm = formatRowToRead({
							...newRow,
							ArticleNumber: fullValue.ArticleNumber,
							Article: value,
							Description: fullValue.Description,
							OrderedQuantity: "1",
							DeliveredQuantity: "1",
							Unit: fullValue.Unit,
							Price: fullValue.Price || fullValue.SalesPrice,
							Discount: fullValue.Discount || 0,
							DiscountType: fullValue.DiscountType || "PERCENT",
						}) as FortnoxOrderRowType;
						setForm(newForm);
					} catch (e) {
						console.error("Error", e);
						toastr.error(e);
					}

					if (index !== undefined) onChange(index, newForm);
				} else {
					newRow[field] = property ? value[property] : value;

					if (field === "OrderedQuantity") {
						newRow.DeliveredQuantity = value;
					}

					setForm(newRow as FortnoxOrderRowType);

					if (field === "DiscountType") {
						if (index !== undefined) onChange(index, newRow as FortnoxOrderRowType);
					}
				}
			},
		[form, handleClear, index, onChange]
	);

	const handleOnBlur = () => {
		if (form && index !== undefined) {
			onChange(index, formatRowToRead(form));
		}
	};

	const sum = getRowStats(form)?.totalExclVat;
	const disabled = (propsDisabled ? true : undefined) as any;

	const renderdColumns = useMemo(() => {
		return columns.map((column) => {
			if (column.handle === "ArticleNumber") {
				if (prefix) return prefix;
				return (
					<SelectSearchField
						key={column.handle}
						onClear={handleClear}
						disabled={disabled}
						resource="fortnox/articles.json"
						params={{ simple: true }}
						onSelect={handleChange("ArticleNumber")}
						idHandle="ArticleNumber"
						labelHandle="ArticleNumber"
						searchHandle="q"
						resourceHandle="Articles"
						resourceName={{ plural: t("fortnox.articles.plural", "artiklar"), singular: t("fortnox.articles.singular", "artikel") }}
						value={form?.ArticleNumber}
						renderLabel={(item) => `${item.ArticleNumber} - ${item.Description}`}
						suffix={
							!disabled && (
								<span style={{ cursor: "pointer" }}>
									<Icon source={ChevronDownMinor} />
								</span>
							)
						}
					/>
				);
			}

			if (["OrderedQuantity", "Price", "Discount"].includes(column.handle)) {
				return (
					<TextField
						selected
						key={column.handle}
						disabled={disabled}
						value={plain && !form?.[column.handle] ? "" : String(form?.[column.handle] || "")}
						onChange={handleChange(column.handle)}
						suffix={column.handle === "Discount" && (form?.DiscountType === "PERCENT" ? "%" : order.Currency)}
						prefix={
							column.handle === "Discount" &&
							!plain &&
							!disabled && (
								<Tooltip content={t("fortnox.row.fields.Price.DiscountType", "Rabatt typ")}>
									<Button
										plain
										icon={ResetMinor}
										onClick={() => {
											if (form) {
												handleChange("DiscountType")(form.DiscountType === "PERCENT" ? "AMOUNT" : "PERCENT");
											}
										}}
									/>
								</Tooltip>
							)
						}
					/>
				);
			}

			if (column.handle === "Description") {
				return (
					<TextField
						key={column.handle}
						disabled={disabled}
						onChange={handleChange("Description")}
						value={String(form?.Description || "")}
						multiline
					/>
				);
			}

			if (column.handle === "Unit") {
				return (
					<SelectSearchField
						key={column.handle}
						disabled={disabled}
						resource="fortnox/units.json"
						onSelect={handleChange("Unit", "Code")}
						idHandle="Code"
						labelHandle="Code"
						resourceHandle="Units"
						resourceName={{ plural: t("fortnox.units.plural", "enheter"), singular: t("fortnox.units.plural", "enhet") }}
						value={form?.Unit}
						searchHandle={null}
					/>
				);
			}

			if (column.handle === "sum") {
				return <TextField key={column.handle} disabled={disabled} value={formatRowNumberToRead(sum || 0)} suffix={order.Currency} />;
			}

			return null;
		});
	}, [form, columns, sum, disabled, handleChange, handleClear, t, order, plain, prefix]);

	return (
		<FortnoxRowWrapper ref={ref} onBlur={handleOnBlur} plain={plain} className={className}>
			{renderdColumns}
			<DropDown
				activatorStyle={
					!row || disabled
						? {
								opacity: 0,
								pointerEvents: "none",
						  }
						: {}
				}
			>
				<ActionList
					items={[
						{
							content: t("common.actions.clear", "Töm") as string,
							destructive: true,
							icon: CancelMajor,
							onAction: handleClear,
						},
						{
							content: t("common.actions.remove", "Ta bort") as string,
							destructive: true,
							icon: DeleteMajor,
							onAction: () => index !== undefined && onChange(index, null),
						},
					]}
				/>
			</DropDown>
			{row && !plain && <Icon source={DragHandleMinor} />}
		</FortnoxRowWrapper>
	);
};
export default withTranslation(["fornox", "common"])(FortnoxOrderRow);
