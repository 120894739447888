import { EmptyState } from "@shopify/polaris";
import React, { Component } from "react";
import moment from "moment";
import { ProfileMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import BigData from "../../components/big_data";
import { toastr } from "../../components/toastr";

class Pdf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
		};
	}

	renderCell(item, column) {
		if (column.handle == "NAME") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
					</div>
				</div>
			);
		} else if (column.handle == "CREATED_AT") {
			return item.created_at ? moment(item.created_at).locale("sv").calendar() : "–";
		} else if (column.handle == "UPDATE_AT") {
			return item.updated_at ? moment(item.updated_at).locale("sv").calendar() : "–";
		}
	}

	onSelectionChange(selectedItems) {
		this.setState({ selectedItems });
	}

	bulkRemove() {
		this.setState({ removing: true });
		API.put("/api/pdf_templates/bulk_remove.json", { ids: this.state.selectedItems })
			.then((result) => {
				this.setState({ removing: false, selectedItems: null });
				this.refresh();
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	gotoPdf(item) {
		item.path = "/admin/pdf_templates/" + item.id;
		item.variables = [];
		this.props.history.push("/admin/pdf_templates/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("pdf.index.breadcumb", "PDFs"),
					item,
				},
			],
		});
	}

	onItemsFetched(items) {
		if (items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}
		this.setState({ firstFetch: false });
	}

	render() {
		if (this.state.empty) {
			return (
				<div>
					<EmptyState
						heading={this.props.t("pdf.empty.heading", "Inga PDF Mallar")}
						action={{
							content: this.props.t("pdf.actions.craete_template", "Skapa PDF Mall"),
							onAction: () => this.props.history.push("/admin/pdf_templates/new"),
						}}
						image="/assets/images/empty_state/PDF.png"
					>
						<p>{this.props.t("pdf.empty.description", "Du har inga PDF Mallar att visa")}</p>
					</EmptyState>
				</div>
			);
		}

		const filters = [];
		const columns = [
			{
				handle: "NAME",
				label: this.props.t("pdf.columns.name.label", "Namn"),
				sortable: true,
				type: "text",
			},
			{
				handle: "UPDATE_AT",
				label: this.props.t("pdf.columns.updated_at.label", "Senast aktivitet"),
				sortable: true,
				type: "text",
			},
			{
				handle: "CREATED_AT",
				label: this.props.t("pdf.columns.created_at.label", "Skapad"),
				sortable: true,
				type: "text",
			},
		];

		const bulkActions = [
			{
				items: [
					{
						icon: ProfileMajor,
						content: this.props.t("pdf.bulk_actions.remove", "Ta bort PDFs"),
						onAction: this.bulkRemove.bind(this),
						loading: this.state.removing,
					},
				],
			},
		];

		return (
			<BigData
				history={this.props.history}
				setRefreshHandler={(refreshHandler) => {
					this.refresh = refreshHandler;
				}}
				renderMedia={(item) => <img alt="" src={item.thumbnail_url} style={{ objectFit: "cover", height: "65px" }} />}
				title={this.props.t("pdf.templates.title", "PDF mallar")}
				primaryAction={{
					content: this.props.t("pdf.actions.new", "Ny PDF"),
					onAction: () => this.props.history.push("/admin/pdf_templates/new"),
				}}
				savedSearchHandle="pdf_mallar"
				resourceUrl="/api/pdf_templates.json"
				onItemsFetched={this.onItemsFetched.bind(this)}
				resourceHandle="pdf_templates"
				onSelectionChange={this.onSelectionChange.bind(this)}
				resourceName={{
					singular: this.props.t("pdf.tempaltes.singular", "PDF mall"),
					plural: this.props.t("pdf.tempaltes.plural", "PDF mallar"),
				}}
				selectedItems={this.state.selectedItems}
				renderCell={this.renderCell.bind(this)}
				filters={filters}
				columns={columns}
				defaultSort="LAST_ACTIVITY_DESC"
				onClickRow={this.gotoPdf.bind(this)}
				promotedBulkActions={[]}
				bulkActions={bulkActions}
			/>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(Pdf);
