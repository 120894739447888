import { Tabs } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { HeaderWrapper } from "../CustomFields/styles";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	category: Partial<CategoryType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ category, tabs, onSelect, selected, t }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<HeaderWrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</HeaderWrapper>
	);
};
export default withTranslation(["common"])(Header);
