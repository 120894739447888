/* eslint-disable react/no-this-in-sfc */
import { Button, Card, Collapsible, Icon, InlineError } from "@shopify/polaris";
import React, { useCallback, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts";
import { WithTranslation, withTranslation } from "react-i18next";
import Colors, { getColorFromIndex } from "src/js/Colors";
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import getDefaultGraphOptions from "./Utilities/getDefaultGraphOptions";
import Spinner from "../Spinner";
import { ExternalMinor, InfoMinor } from "@shopify/polaris-icons";
import SettingsPopup from "./SettingsPopup";
import handleFormatSeries from "./Utilities/handleFormatSeries";

type Settings = {
	stacked?: boolean;
	grouped?: boolean;
};

type LineGraphProps = {
	data: {
		series: Serie[];
		labels: string[];
	};
	title?: string;
	headerRight?: React.ReactNode;
	loading?: boolean;
	stacked?: boolean;
	numberOfLegendsToShow?: number;
	formatter?: (value: number) => string;
	showLink?: boolean;
	showLegends?: boolean;
	settings?: Settings;
	showSettings?: boolean;
	sort?: boolean;
	error?: string;
	overlayText?: string;
} & WithTranslation;

const LineGraph = React.memo(
	({
		data,
		title,
		t,
		headerRight,
		loading,
		stacked = false,
		numberOfLegendsToShow = data?.series?.length,
		formatter,
		showLink = true,
		showLegends = true,
		settings: propsSettings = { stacked, grouped: false },
		showSettings = true,
		sort = false,
		error = "",
		overlayText = "",
	}: LineGraphProps) => {
		const [selectedSerie, setSelectedSerie] = React.useState<number | null>(null);
		const ref = React.useRef<any>(null);
		const [collapsed, setCollapsed] = React.useState(true);
		const [settings, setSettings] = React.useState(propsSettings);

		const deselectAll = useCallback(() => {
			if (ref.current && ref.current.chart) {
				const chart = ref.current.chart;

				chart.series.forEach((s) => {
					s.update({
						opacity: 1,
					});
				});
			}
		}, []);

		const handleSetSettings = (newSettings) => {
			setSettings(newSettings);
		};

		useEffect(() => {
			if (settings.grouped) {
				deselectAll();
				setSelectedSerie(null);
			}
		}, [settings.grouped, deselectAll]);

		const handleFormat = (value: number) => {
			if (formatter) return formatter(value);

			return value;
		};

		useEffect(() => {
			Highcharts.setOptions({
				lang: {
					numericSymbols: ["", t("common.terms.currency.million", "miljoner"), t("common.terms.currency.billion", "miljarder")],
				},
			});
		}, [t]);

		const series = (data?.series || []).map((serie, index) => {
			return {
				index,
				...serie,
				color: serie.color || getColorFromIndex(index),
				stops: null,
			};
		});
		const labels = data?.labels || [];
		const empty = !series?.length || series?.every((ser) => !ser.data?.filter(Boolean).length);

		const options = merge(
			getDefaultGraphOptions({
				labels,
				// series: [...JSON.parse(JSON.stringify(series))],
				series: cloneDeep(handleFormatSeries({ series, settings })),
				loading: !!loading,
				type: "spline",
			}),

			{
				yAxis: {
					labels: {
						enabled: true,
						formatter() {
							const cont = this as any;
							return handleFormat(cont.value);
						},
					},
					stackLabels: {
						enabled: true,
						formatter() {
							let sum = 0;
							const cont = this as any;
							const series = cont.axis.series;

							series.forEach((p) => {
								sum += p.yData[cont.x];
							});

							if ((sum < 0 && cont.isNegative) || (sum >= 0 && !cont.isNegative)) {
								return handleFormat(sum);
							}
						},
					},
				},
				plotOptions: {
					// series: {
					// 	stacking: stacked ? "normal" : null,
					// },
					column: {
						stacking: settings.stacked ? "normal" : null,
					},
				},

				tooltip: {
					formatter(a) {
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const { total, x: label, y: value, series } = this as any;
						const { name } = series || {};

						return `
					<b>${label}</b><br/>
					<span>${name}: ${handleFormat(value)}</span><br/><br/>
					`;
						// <span>Total: ${handleTotalFormat(total)}</span>
					},
					// shared: true, // shows all series in the tooltip
					// headerFormat: "<b>{point.x}</b><br/>",
					// pointFormat: `{series.name}: {point.y}<br/><br/>Total: {point.stackTotal}`,
				},
			}
		);

		const total = series?.reduce(
			(acc, serie) => (typeof serie.total === "number" ? serie.total : serie.data.reduce((acc2, value) => acc2 + (value || 0), 0)) + acc,
			0
		);

		const { seriesToShow, seriesRest, sortedSeries } = useMemo(() => {
			const sortedSeries = sort
				? series?.sort(
						(a, b) =>
							b.data.reduce((acc: number, value: any) => acc + (value || 0), 0) - a.data.reduce((acc: number, value: any) => acc + (value || 0), 0)
				  )
				: series;

			const seriesToShow = sortedSeries?.slice(
				0,
				numberOfLegendsToShow || series.length
				// numberOfLegendsToShow ? Math.min(numberOfLegendsToShow, series.length) : series.length
			);
			const seriesRest = sortedSeries?.slice(numberOfLegendsToShow || series.length);

			return {
				seriesToShow,
				seriesRest,
				sortedSeries,
			};
		}, [series, numberOfLegendsToShow, sort]);

		// const handleLegendMouseOver = (seriesIndex) => {
		// 	if (ref.current && ref.current.chart && selectedSerie === null) {
		// 		const chart = ref.current.chart;
		// 		const series = chart.series[seriesIndex];
		// 		const otherSeries = chart.series.filter((s) => s !== series);

		// 		otherSeries.forEach((s) => {
		// 			s.update({
		// 				opacity: 0.5,
		// 			});
		// 		});
		// 	}
		// };

		// const handleLegendMouseOut = (seriesIndex) => {
		// 	if (ref.current && ref.current.chart && selectedSerie === null) {
		// 		const chart = ref.current.chart;
		// 		const series = chart.series[seriesIndex];
		// 		const otherSeries = chart.series.filter((s) => s !== series);

		// 		otherSeries.forEach((s) => {
		// 			s.update({
		// 				opacity: 1,
		// 			});
		// 		});
		// 	}
		// };

		const handleSetSelectedSerie = (index: number) => {
			if (ref.current && ref.current.chart) {
				const chart = ref.current.chart;
				const series = chart.series[index];
				const otherSeries = chart.series.filter((s) => s !== series);

				if (selectedSerie === index) {
					chart.series.forEach((s) => {
						s.update({
							opacity: 1,
						});
					});

					setSelectedSerie(null);
				} else {
					series.update({
						opacity: 1,
					});

					otherSeries.forEach((s) => {
						s.update({
							opacity: 0.35,
						});
					});
					setSelectedSerie(index);
				}
			}
		};

		const mouseEvents = (serie) =>
			settings.grouped || sortedSeries.length > 20
				? {}
				: {
						// mouseOver: () => {
						// 	const { index } = serie;
						// 	handleLegendMouseOver(index);
						// },
						// mouseOut: () => {
						// 	const { index } = serie;
						// 	handleLegendMouseOut(index);
						// },
						onClick: () => {
							const { index } = serie;
							handleSetSelectedSerie(index);
						},
				  };

		const renderSerieLegend = (serie) => {
			return (
				<LegendItem selected={selectedSerie !== null ? selectedSerie === serie.index : null} key={serie.index} {...mouseEvents(serie)}>
					<div className="circle" style={{ backgroundColor: serie.color || Colors.white }} />
					{serie.name}:{" "}
					{handleFormat(typeof serie.total === "number" ? serie.total : serie.data?.reduce((acc: number, value: any) => acc + (value || 0), 0))}
					{showLink && serie.link && (
						<span
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Button url={serie.link} plain icon={ExternalMinor} />
						</span>
					)}
					{serie.loading && <Spinner size={16} />}
				</LegendItem>
			);
		};

		return (
			<Wrapper loading={!!loading}>
				{!error && !overlayText && empty && !loading && (
					<OverlayWrapper>
						<span>{t("graph.empty.text", "Det finns inget statistik för denna period")}</span>
					</OverlayWrapper>
				)}
				{error && (
					<OverlayWrapper>
						<InlineError message={error} fieldID="error" />
					</OverlayWrapper>
				)}
				{overlayText && (
					<OverlayWrapper>
						<div style={{ display: "flex", gap: "0.5rem" }}>
							<Icon source={InfoMinor} /> {overlayText}
						</div>
					</OverlayWrapper>
				)}
				{loading && empty && (
					<OverlayWrapper>
						<Spinner />
					</OverlayWrapper>
				)}

				<Card sectioned>
					{title && <h2 className="card_title">{title}</h2>}

					<GraphHeader>
						<SerieLabelsWrapper>
							{showLegends && (
								<>
									<LegendItem>
										<div className="circle" style={{ backgroundColor: "transparent" }} />
										{"Total"}: {handleFormat(total)}
									</LegendItem>

									{seriesToShow?.map(renderSerieLegend)}

									{
										<Collapsible id="bar" open={!collapsed}>
											{seriesRest?.map(renderSerieLegend)}
										</Collapsible>
									}

									{series.length > numberOfLegendsToShow && (
										<LegendItem onClick={() => setCollapsed((collapsed) => !collapsed)}>
											{collapsed
												? t("graph.show_more", "Visa mer + {{count}}", { count: series.length - numberOfLegendsToShow })
												: t("graph.show_less", "Visa mindre")}
										</LegendItem>
									)}
								</>
							)}
						</SerieLabelsWrapper>

						<RightWrapper>
							{headerRight}
							{showSettings && <SettingsPopup settings={settings} setSettings={handleSetSettings} />}
						</RightWrapper>
					</GraphHeader>
					<HighchartsReact highcharts={Highcharts} options={options} ref={ref} />
				</Card>
			</Wrapper>
		);
	}
);
export default withTranslation(["graph", "common"])(LineGraph);

const RightWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
`;

const Wrapper = styled.div<{ loading: boolean }>`
	position: relative;

	${({ loading }) =>
		loading &&
		css`
			pointer-events: none;
			opacity: 0.75;
		`}
`;
const GraphHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 1rem;
	padding-bottom: 2rem;
`;

const SerieLabelsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
`;

const LegendItem = styled.li<{ selected?: boolean | null; onClick?: any }>`
	display: flex;
	align-items: center;
	gap: 1rem;
	cursor: ${(props) => (props.onClick ? "pointer" : "default")};

	.circle {
		border-radius: 50%;
		height: 10px;
		width: 10px;
	}

	${(props) => {
		if (props.selected !== null) {
			if (props.selected) {
				return `
				font-weight: 700;
				`;
			}

			return `
			opacity: 0.5;
			`;
		}
	}}
`;

const OverlayWrapper = styled.div`
	position: absolute;
	inset: 0;
	background-color: var(--main2-50);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.7rem;
	z-index: 1;
`;
