import { FormLayout, Select, Spinner, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr";

class ContactFortnoxContent extends Component {
	constructor(props) {
		super(props);
		this.state = { PrintTemplates: [] };
	}

	componentDidMount() {
		// this.fetch();
		this.fetchTemplates();

		// this.props.integrationForm.id = this.props.integration.id;
		// this.props.updateForm(this.props.integrationForm);
	}

	// fetch() {
	// 	API.get(
	// 		`/admin/integrations/${this.props.integration && this.props.integration.id}/contacts/${
	// 			this.props.contact && this.props.contact.id
	// 		}/customer.json`
	// 	)
	// 		.then((result) => {
	// 			if (result.data.error) {
	// 				toastr.error(result.data.error);
	// 				return;
	// 			}
	// 			this.props.updateForm(Object.assign(result.data.customer, { id: this.props.integration.id }));
	// 		})
	// 		.catch((error) => {
	// 			// console.error("error:", error.ErrorInformation);
	// 			// toastr.error(error);
	// 		});
	// }

	fetchTemplates() {
		this.setState({ loadingTemplates: true });
		API.get(`/api/integrations/fortnox/${this.props.integration && this.props.integration.id}/templates/print.json?type=invoice`)
			.then((result) => {
				if (result.data.error) {
					this.setState({ loadingTemplates: false });
					toastr.error(result.data.error);
					return;
				}

				this.setState(
					Object.assign(
						{
							loadingTemplates: false,
						},
						result.data,
						{
							PrintTemplates:
								result.data.PrintTemplates?.filter(({ Template }) => {
									if (["stocr", "iban", "bg", "constr", "cash", "rem"].includes(Template)) return false;
									return true;
								}) || [],
						}
					)
				);
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ loadingTemplates: false });
				// toastr.error(error);
			});
	}

	updateForm(field, value) {
		if (!this.props.integrationForm) this.props.integrationForm = {};

		if (field === "template_invoice") {
			this.props.integrationForm.DefaultTemplates.Invoice = value;
		} else {
			this.props.integrationForm[field] = value;
		}

		this.props.updateForm(this.props.integrationForm);
	}

	updateDefaultDeliveryTypes(field, value) {
		if (!this.props.integrationForm) this.props.integrationForm = {};
		if (!this.props.integrationForm.DefaultDeliveryTypes) this.props.integrationForm.DefaultDeliveryTypes = {};

		this.props.integrationForm.DefaultDeliveryTypes[field] = value;

		this.props.updateForm(this.props.integrationForm);
	}

	render() {
		const { /* integration, */ loading, integrationForm = {} } = this.props;
		const { loadingTemplates } = this.state;

		const templates = this.state.PrintTemplates || [];
		// eslint-disable-next-line react/no-unstable-nested-components
		const Label = ({ label }) => <span style={{ display: "flex", alignItems: "center", gap: "1rem" }}>{label}</span>;

		const deliveryOptions = [
			{
				value: "",
				label: "Välj..",
			},
			{
				value: "PRINT",
				label: "Utskrift",
			},
			{
				value: "EMAIL",
				label: "E-post",
			},
			{
				value: "PRINTSERVICE",
				label: "E-faktura",
			},
		];

		return (
			<div className="contact_fornox">
				<FormLayout>
					<TextField
						disabled={loading}
						label={
							<React.Fragment>
								{this.props.t("contact.fortnox.fields.EmailInvoice.label", "Faktura email")} {loading && <Spinner size="small" />}
							</React.Fragment>
						}
						onChange={this.updateForm.bind(this, "EmailInvoice")}
						value={integrationForm.EmailInvoice}
					/>
					<TextField
						disabled={loading}
						label={
							<React.Fragment>
								{this.props.t("contact.fortnox.fields.EmailOrder.label", "Order email")} {loading && <Spinner size="small" />}
							</React.Fragment>
						}
						onChange={this.updateForm.bind(this, "EmailOrder")}
						value={integrationForm.EmailOrder}
					/>
					<TextField
						disabled={loading}
						label={
							<React.Fragment>
								{this.props.t("contact.fortnox.fields.EmailOffer.label", "Offert email")} {loading && <Spinner size="small" />}
							</React.Fragment>
						}
						onChange={this.updateForm.bind(this, "EmailOffer")}
						value={integrationForm.EmailOffer}
					/>
					<TextField
						disabled={loading}
						label={
							<React.Fragment>
								{this.props.t("contact.terms.fortnox.out_reference", "Vår referens")} {loading && <Spinner size="small" />}
							</React.Fragment>
						}
						onChange={this.updateForm.bind(this, "OurReference")}
						value={integrationForm.OurReference}
					/>

					<TextField
						disabled={loading}
						label={
							<React.Fragment>
								{this.props.t("contact.terms.fortnox.your_reference", "Er referens")} {loading && <Spinner size="small" />}
							</React.Fragment>
						}
						onChange={this.updateForm.bind(this, "YourReference")}
						value={integrationForm.YourReference}
					/>

					<Select
						label="Faktura leveransätt"
						options={deliveryOptions}
						onChange={this.updateDefaultDeliveryTypes.bind(this, "Invoice")}
						value={integrationForm?.DefaultDeliveryTypes?.Invoice}
					/>
					<Select
						label="Order leveransätt"
						options={deliveryOptions}
						onChange={this.updateDefaultDeliveryTypes.bind(this, "Order")}
						value={integrationForm?.DefaultDeliveryTypes?.Order}
					/>
					<Select
						label="Offert leveransätt"
						options={deliveryOptions}
						onChange={this.updateDefaultDeliveryTypes.bind(this, "Offer")}
						value={integrationForm?.DefaultDeliveryTypes?.Offer}
					/>

					<Select
						disabled={loadingTemplates || loading}
						label={
							<Label
								label={
									<React.Fragment>
										{this.props.t("contact.terms.print_template", "Utskriftsmall")} {loadingTemplates && <Spinner size="small" />}
									</React.Fragment>
								}
							/>
						}
						options={templates.map((item) => ({ label: item.Name, value: item.Template }))}
						placeholder="Default"
						onChange={this.updateForm.bind(this, "template_invoice")}
						value={integrationForm.DefaultTemplates && integrationForm.DefaultTemplates.Invoice}
					/>
				</FormLayout>
			</div>
		);
	}
}
export default withTranslation(["contact"], { withRef: true })(ContactFortnoxContent);
