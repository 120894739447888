import React, { Component } from "react";
import styled from "styled-components";
import { Spinner, Stack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Colors from "../Colors";

/**
 * @param {Array} [details] - Array of objects with label and value [{label: "Namn", value: "Robin"}
 * @returns {Element} JSX
 */
class InfoView extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getValueComponent(
		{ label, value, type, href, component, className, color, height, loading, duplicates, style: propsStyle = {}, valueStyle = {} },
		key
	) {
		const style = Object.assign({}, propsStyle, valueStyle);
		const h = height || this.props.height;
		if (h) style.height = h;
		if (color) style.color = color;

		if (component)
			return (
				<span style={style} className="info-view__compoment" key={key}>
					{component}
				</span>
			);

		if (href && value) {
			return (
				<a href={href} target="_blank" key={key} className={`info-view__row__value ${className || ""}`} style={style} rel="noreferrer">
					{value}
				</a>
			);
		}

		if (typeof value === "string" && (value?.startsWith("http") || value?.startsWith("www"))) {
			return (
				<a href={value} target="_blank" key={key} className={`info-view__row__value ${className || ""}`} style={style} rel="noreferrer">
					{value}
				</a>
			);
		}

		if (type === "email" && value) {
			return (
				<a href={`mailto:${value}`} key={key} className={`info-view__row__value ${className || ""}`} style={style}>
					{value}
				</a>
			);
		}
		if (type === "phone" && value) {
			return (
				<a href={`tel:${value}`} key={key} className={`info-view__row__value ${className || ""}`} style={style}>
					{value}
				</a>
			);
		}
		if ((type === "orgnr" || type === "company_number") && value) {
			return (
				<Stack>
					<a
						href={`https://www.allabolag.se/${value?.number?.replace("-", "") || value?.replace("-", "")}`}
						target="_blank"
						key={key}
						className={`info-view__row__value ${className || ""}`}
						style={style}
						rel="noreferrer"
					>
						{value?.number || value}
					</a>
					{loading && <Spinner size="small" />}
					{!loading && duplicates?.length > 0 && (
						<div style={{ color: Colors.red }}>{this.props.t("contact.errors.exists", "Företag finns redan")}</div>
					)}
				</Stack>
			);
		}
		return (
			<span key={key} style={style} className={`info-view__row__value ${className || ""}`}>
				{typeof value !== "number" && !value ? "-" : value}
			</span>
		);
	}

	render() {
		const { details, labelWidth } = this.props || {};
		if (!details || !details.length) return null;

		if (this.props.fullView) {
			return (
				<Wrapper>
					{this.props.title && <h3>{this.props.title}</h3>}
					<InfoViewWrapper
						gap={this.props.gap}
						className={`info-view ${this.props.className}`}
						style={Object.assign(this.props.style || {}, { flexDirection: "column" })}
					>
						{details.map((i, key) => {
							const height = i.height || this.props.height;
							return (
								<div key={key} className={i.className || ""} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
									<label style={height ? { height } : {}} className="info-view__labels">
										{i.label && i.label.capitalize()}:
									</label>
									<span style={height ? { height } : {}} className="info-view__values">
										{this.getValueComponent(i)}
									</span>
								</div>
							);
						})}
					</InfoViewWrapper>
				</Wrapper>
			);
		}

		return (
			<Wrapper>
				{this.props.title && <h3>{this.props.title}</h3>}
				<InfoViewWrapper gap={this.props.gap} className={`info-view ${this.props.className}`} style={this.props.style}>
					<div className="info-view__labels" style={{ width: labelWidth || "unset" }}>
						{details.map(({ label, value, className, height, style }, key) => {
							const ght = height || this.props.height;

							return (
								<label
									key={key}
									style={{
										...(style || {}),
										...(ght ? { height: ght } : {}),
									}}
									className={`info-view__row__label ${className || ""}`}
									title={label}
								>
									{label && label.capitalize()}:
								</label>
							);
						})}
					</div>
					<div className="info-view__values">{details.map((i, key) => this.getValueComponent(i, key))}</div>
				</InfoViewWrapper>
			</Wrapper>
		);
	}
}
export default withTranslation(["contact", "common"], { withRef: true })(InfoView);
export const InfoViewWrapper = styled.div.attrs({ className: "info-view" })`
	display: flex;
	flex-direction: row;
	column-gap: 2rem;
	row-gap: ${({ gap }) => gap || "2rem"};
	color: var(--textColor);

	.info-view__labels {
		/* text-transform: capitalize; */
		font-weight: bold;
	}
	.info-view__labels,
	.info-view__values {
		display: flex;
		flex-direction: column;
		column-gap: 2rem;
		row-gap: ${({ gap }) => gap || "2rem"};
		color: var(--textColor);

		a {
			color: var(--textColor);
		}
	}

	.info-view__row__label,
	.info-view__row__value {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre;
	}

	.info-view__values {
		text-decoration: none;
		a {
			text-decoration: underline;
		}

		.edit-button {
			height: 15px;

			.Polaris-Button {
				padding: 0;
				min-height: unset;
				min-width: unset;
				height: unset;
			}
		}

		.resource_picker_btn {
			.Polaris-Button {
				min-height: unset;
			}
		}
	}
`;

const Wrapper = styled.div`
	h3 {
		margin: 0 0 1rem 0;
		font-weight: bold;
	}
`;
