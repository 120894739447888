import React, { Component } from "react";
import { Modal } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import FieldSelector from "./FieldSelector.js";
import { store } from "../store";

import SellerSelector from "./SellerSelector.js";

class ChangeUserModal extends Component {
	constructor(props) {
		super(props);
		this.state = { user: null, field: store.getState().fields[0] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.open) {
			this.setState({ user: null, field: store.getState().fields[0] });
		}
	}

	confirm() {
		this.props.onConfirm(this.state.user, this.state.field);
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("user.modals.change.user_contact.title", "Ändra ansvarig medarbetare på {{counts}} kontakter", {
					counts: this.props.numItems,
				})}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.change", "Ändra"),
					loading: this.props.loading,
					onAction: this.confirm.bind(this),
				}}
			>
				<Modal.Section>
					<p style={{ marginBottom: "1rem" }}>{this.props.t("user.modals.change.user_contact.text", "Välj person att koppla mot kontakter")}</p>

					{store.getState().fields.length > 1 ? (
						<FieldSelector
							label={this.props.t("user.modals.change.user_contact.fields.field.label", "Affärsområde")}
							required
							field={this.state.field}
							onChange={(field) => {
								this.setState({ field });
							}}
						/>
					) : null}

					<SellerSelector
						clearable
						user={this.state.user}
						onChange={(value) => {
							this.setState({ user: value });
						}}
					/>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["user", "common"], { withRef: true })(ChangeUserModal);
