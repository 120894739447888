/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import PdfViewer from "src/js/components/PdfViewer";
import Skeleton1Col from "src/js/components/skeleton_1col";
import { getPDFBlobURL } from "./Utilities";

const FortnoxOfferPDFView = ({ history, pdf, loading }) => {
	return <Wrapper>{pdf ? <PdfViewer src={getPDFBlobURL(pdf)} /> : loading && <Skeleton1Col />}</Wrapper>;
};

export default withTranslation(["fortnox", "common"])(FortnoxOfferPDFView);

const Wrapper = styled.div`
	gap: 1rem;
	display: flex;
	justify-content: center;

	.Polaris-Card {
		height: max-content;
	}
`;
