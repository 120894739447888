import { Button, Card, Layout } from "@shopify/polaris";
import { ExternalMinor } from "@shopify/polaris-icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import InfoView from "src/js/components/InfoView";

type FortnoxContactViewProps = {
	contact: ContactType | null;
	history: any;
	customer: FortnoxCustomerType | null;
	type: "order" | "offer" | "invoice" | "contract";
	EmailInformation: EmailInformation | null | undefined;
} & WithTranslation;

const FortnoxContactView = ({ contact, history, customer, t, type, EmailInformation }: FortnoxContactViewProps) => {
	const singular = (() => {
		switch (type) {
			case "order":
				return "ordern";
			case "offer":
				return "offerten";
			case "invoice":
				return "fakturan";
			default:
				return null;
		}
	})();
	const splitView = !!contact && !!customer;
	return (
		<Layout>
			{contact && (
				<Layout.Section oneHalf={splitView}>
					<Card sectioned>
						<InfoView
							title={t("fortnox.sellfinty_contact", "Sellfinity kontakt")}
							details={[
								{
									label: t("fortnox.customer.fields.name.label", "Namn"),
									value: (
										<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
											{contact?.name} <Button plain icon={ExternalMinor} url={`/admin/contacts/${contact?.id}`} />
										</div>
									),
								},
								{
									label: t("fortnox.customer.fields.email.label", "E-post"),
									value: contact?.email,
								},
								{
									label: t("fortnox.customer.fields.phone.label", "Telefon"),
									value: contact?.phone,
								},
								{
									label: t("fortnox.customer.fields.address1.label", "Adress"),
									value: contact?.address1,
								},
								{
									label: t("fortnox.customer.fields.zip.label", "Postnummer"),
									value: contact?.zip,
								},
								{
									label: t("fortnox.customer.fields.city.label", "Stad"),
									value: contact?.city,
								},
								{
									label: t("fortnox.customer.fields.company.label", "Företag"),
									value: contact?.parent?.name,
									enabled: !contact?.is_company,
								},
							].filter((d) => !("enabled" in d) || d.enabled)}
						/>
					</Card>
				</Layout.Section>
			)}
			{customer && (
				<Layout.Section oneHalf={splitView}>
					<Card>
						<Card.Section>
							<InfoView
								title={t("fortnox.fortnox_customer", "Fortnox kund")}
								details={[
									{
										label: t("fortnox.customer.fields.CustomerNumber.label", "Kundnummer"),
										value: customer?.CustomerNumber,
									},
									{
										label: t("fortnox.customer.fields.Name.label", "Namn"),
										value: customer?.Name,
									},
									{
										label: t("fortnox.customer.fields.EmailInvoice.label", "Faktura Email"),
										value: customer?.EmailInvoice,
										enabled: customer?.EmailInvoice,
									},
									{
										label: t("fortnox.customer.fields.EmailOffer.label", "Offert Email"),
										value: customer?.EmailOffer,
										enabled: customer?.EmailOffer,
									},
									{
										label: t("fortnox.customer.fields.EmailOrder.label", "Order Email"),
										value: customer?.EmailOrder,
										enabled: customer?.EmailOrder,
									},
									{
										label: t("fortnox.customer.fields.DefaultDeliveryTypes.Invoice.label", "Leveranssätt faktura"),
										value: customer?.DefaultDeliveryTypes?.Invoice,
									},
									{
										label: t("fortnox.customer.fields.DefaultTemplates.Invoice.label", "Fakturamall"),
										value: customer?.DefaultTemplates?.Invoice !== "DEFAULTTEMPLATE" ? customer?.DefaultTemplates?.Invoice : "Standard",
									},
									{
										label: t("fortnox.customer.fields.YourReference.label", "Er referens"),
										value: customer?.YourReference,
										enbled: customer?.YourReference,
									},
									{
										label: t("fortnox.customer.fields.OurReference.label", "Vår referens"),
										value: customer?.OurReference,
										enbled: customer?.OurReference,
									},
								].filter((d) => !("enabled" in d) || d.enabled)}
							/>
						</Card.Section>
						<Card.Section>
							<InfoView
								title={t("fortnox.fortnox_customer_info_on_object", "Fortnox kund info på {{singular}}", {
									singular,
								})}
								details={[
									{
										label: t("fortnox.EmailInformation.fields.EmailAddressFrom.label", "Skickas ifrån email address"),
										value: EmailInformation?.EmailAddressFrom,
									},
									{
										label: t("fortnox.EmailInformation.fields.EmailAddressTo.label", "Skickas till email address"),
										value: EmailInformation?.EmailAddressTo,
									},
									{
										label: t("fortnox.EmailInformation.fields.EmailAddressCC.label", "CC"),
										value: EmailInformation?.EmailAddressCC,
									},
									{
										label: t("fortnox.EmailInformation.fields.EmailAddressBCC.label", "BCC"),
										value: EmailInformation?.EmailAddressBCC,
									},
									{
										label: t("fortnox.EmailInformation.fields.EmailSubject.label", "Email ämne"),
										value: EmailInformation?.EmailSubject,
									},
									{
										label: t("fortnox.EmailInformation.fields.EmailBody.label", "Email text"),
										value: EmailInformation?.EmailBody,
									},
								].filter((d) => !("enabled" in d) || d.enabled)}
							/>
						</Card.Section>
					</Card>
				</Layout.Section>
			)}
		</Layout>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxContactView);
