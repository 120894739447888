import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

class QRCode extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.ref = React.createRef();
	}

	componentDidMount() {
		if (this.ref.current) {
			const parent = this.ref.current.closest(".Polaris-Modal-Dialog__Modal");
			if (parent) {
				parent.classList.add("IntroductionSlide-inner");
			}
		}
	}

	render() {
		return (
			<Wrapper ref={this.ref}>
				<LeftWrapper>
					<H2>{this.props.t("help.qr_code.title", "Installera Sellfinity på Appstore och Google play")}</H2>
					<p>{this.props.t("help.qr_code.text", "Skanna QR-koden för att ladda ner appen")}.</p>
				</LeftWrapper>
				<RightWrapper>
					<QRCodeImage src="/assets/QR_code.svg" />
				</RightWrapper>
			</Wrapper>
		);
	}
}
export default withTranslation(["help", "common"], { withRef: true })(QRCode);

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 3rem;
	height: 100%;
`;

const LeftWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	flex-direction: column;
	color: #ffffff;
`;
const RightWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
`;
const QRCodeImage = styled.img`
	width: 250px;
	height: 250px;
`;
const H2 = styled.h2`
	font-weight: 400;
	font-size: 30px;
	line-height: 36px;
	margin-bottom: 2rem;
`;
