import moment from "moment";

const getDefaultForm = (history: any) => {
	let data: Partial<OrderType> = {
		order_date: moment().format("YYYY-MM-DD"),
		customer_fields: {},
		delivery_fields: {
			delivery_date: moment().format("YYYY-MM-DD"),
		},
		order_rows: [],
	};

	if (history?.location?.state?.data) {
		const order = history.location.state.data;

		data = {
			...data,
			...order,
		};
	}

	if (history?.location?.state?.contact) {
		const contact = history.location.state.contact;
		data.customer_fields.customer = contact;

		data.customer_fields.name = contact.name;
		data.customer_fields.organisation_number = contact.orgnr;
		data.customer_fields.address = contact.address1;
		data.customer_fields.address2 = contact.address2;
		data.customer_fields.postal_code = contact.zip;
		data.customer_fields.city = contact.city;
		data.customer_fields.country = contact.country;
		data.customer_fields.phone = contact.phone;
		data.customer_fields.email = contact.email;
	}

	return data;
};
export default getDefaultForm;
