import { numberFormatter } from "src/js/Utilities";
import { getInCents } from "../../formatPriceListRow";
import cloneDeep from "lodash/cloneDeep";

export const format = (value: number, removeCurrencySymbol = true) => {
	return numberFormatter({
		value: value || 0,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		removeCurrencySymbol,
	}) as unknown as number;
};

export const formatRowToRead = (row: OrderRowType, order?: OrderType): OrderRowType => {
	const newRow = { ...row };
	newRow.price = format(newRow.price || 0);
	newRow.tb1 = format(newRow.tb1 || 0);
	newRow.tb2 = format(newRow.tb2 || 0);

	return newRow;
};

export const formatRowToWrite = (row: OrderRowType, order?: OrderType): OrderRowType => {
	const newRow = { ...row };
	newRow.price_in_cents = getInCents(formatRowToRead(newRow)?.price);
	newRow.tb1_in_cents = getInCents(formatRowToRead(newRow)?.tb1);
	newRow.tb2_in_cents = getInCents(formatRowToRead(newRow)?.tb2);

	return newRow;
};

export const formatOrderToWrite = (order: OrderType): OrderType => {
	const newOrder = { ...order };
	newOrder.order_rows = newOrder.order_rows?.map((row) => formatRowToWrite(row, newOrder));

	return newOrder;
};

export const formatOrderToRead = (order: OrderType) => {
	// const newOrder = order;
	const newOrder = cloneDeep(order);
	newOrder.order_rows = newOrder.order_rows?.map((row) => formatRowToRead({ ...row }));

	return newOrder;
};
