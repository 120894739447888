import React from "react";
import IntegrationProgress from "src/js/components/IntegrationProgress";
import { getPartyName, getStatuses } from "../Utilities";

const DocumentStatusBar = ({ document, onClick }: { document: ScriveDocumentType; onClick?: (status: any) => void }) => {
	const statuses = getStatuses().map((s) => ({
		...s,
		active: s.value === document.status,
		tooltip:
			s.value === "rejected"
				? `${s.label} av ${document.parties
						?.filter((p: ScrivePartyType) => p.rejected_time)
						?.map((p) => getPartyName(p))
						?.join(",")}`
				: "",
	}));

	return <IntegrationProgress dontFill title="Scrive" statuses={statuses} onClick={onClick} />;
};
export default DocumentStatusBar;
