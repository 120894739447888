import React, { FC, useEffect, useState } from "react";
import { Button, DropZone, FormLayout, Modal } from "@shopify/polaris";
import InlineInput from "src/js/views/Workspaces/components/InlineInput";
import { WithTranslation, withTranslation } from "react-i18next";
import { store } from "src/js/store";
import { AttachmentMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import SellfinityQuill from "../Microsoft365/SellfinityQuill";
import Attachment from "../Attachment";

interface EmailModalProps extends WithTranslation {
	open: boolean;
	loading?: boolean;
	onClose: () => void;
	onSend: (email: EmailModalState, contact?: ContactType) => void;
	allowChangeEmail?: boolean;
	contact?: ContactType;
}

type EmailModalState = {
	email: {
		to?: string;
		copies: Array<string>;
		subject: string;
		body: string;
		attachments?: any[];
	};
	cc?: string;
};

const EmailModal: FC<EmailModalProps> = ({ t, open, loading = false, contact, onClose, onSend }) => {
	const editorRef = React.createRef<any>();
	const subjetRef = React.createRef<any>();
	const [openFileDialog, setOpenFileDialog] = useState(false);

	const getDefaultBody = () => {
		if (!store.getState().user.email_signature) return "";

		return `<br /><br />${store.getState().user.email_signature}`;
	};
	const [state, setState] = useState<EmailModalState>({
		email: {
			to: contact?.email,
			copies: [],
			subject: "",
			body: getDefaultBody(),
			attachments: [],
		},
	});

	const addFile = (file: FileType) => {
		return (event) => {
			const data = event.target.result;

			setState((c) => ({
				...c,
				email: {
					...c.email,
					attachments: [
						...(c.email.attachments || []),
						{
							name: file.name,
							type: file.type,
							size: file.size,
							data,
						},
					],
				},
			}));
		};
	};

	useEffect(() => {
		setState((curr) => ({ ...curr, email: { ...curr.email, to: contact?.email } }));
		return () => {
			setState({
				email: {
					to: undefined,
					copies: [],
					subject: "",
					body: getDefaultBody(),
					attachments: [],
				},
			});
		};
	}, [contact]);

	return (
		<Modal
			open={open}
			large
			title={t("common.emails.send", "Skicka e-post")}
			secondaryActions={[
				{
					content: t("common.actions.close", "Stäng") as string,
					onAction: onClose,
				},
			]}
			primaryAction={{
				content: t("common.actions.send", "Skicka") as string,
				onAction: () => onSend(state, contact),
				loading,
			}}
			onClose={onClose}
		>
			<Wrapper>
				<Modal.Section>
					<FormLayout>
						<InlineInput
							disabled={contact?.email != null}
							autoComplete="off"
							label={t("emails.to", "Till")}
							value={state.email.to}
							onChange={(e) => setState((curr) => ({ ...curr, email: { ...curr.email, to: e } }))}
							autoFocus={!contact?.email}
							onKeyDown={(e) => {
								switch (e.key) {
									case "Enter":
										e.preventDefault();
										e.stopPropagation();
										subjetRef.current?.focus?.();
										break;
									default:
								}
							}}
						/>
						<InlineInput
							autoComplete="off"
							label={t("emails.cc", "Kopia")}
							value={state.cc}
							values={state.email.copies}
							onChange={(e) => setState((curr) => ({ ...curr, cc: e }))}
							onKeyDown={(e) => {
								switch (e.key) {
									case "Enter":
										if (state.cc !== undefined) {
											state.email.copies.push(state.cc);
											setState((curr) => ({
												...curr,
												cc: undefined,
											}));
										}

										break;
									case "Backspace":
										if (!state.cc && state.email.copies.length > 0) {
											setState((curr) => ({ ...curr, email: { ...curr.email, copies: curr.email.copies.slice(0, -1) } }));
										}
										break;
									default:
								}
							}}
						/>
						<InlineInput
							ref={subjetRef}
							autoComplete="off"
							label={t("emails.subject", "Ämne")}
							value={state.email.subject}
							onChange={(e) => setState((curr) => ({ ...curr, email: { ...curr.email, subject: e } }))}
							autoFocus={!!contact?.email}
							onKeyDown={(e) => {
								switch (e.key) {
									case "Enter":
										e.preventDefault();
										e.stopPropagation();
										editorRef.current?.focus?.();
										break;
									default:
								}
							}}
						/>

						<Button
							plain
							onClick={() => {
								setOpenFileDialog(true);
							}}
							icon={AttachmentMajor}
						>
							{t("timeline.text.upload", "Ladda upp fil") as string}
						</Button>
					</FormLayout>
				</Modal.Section>
				{!!state?.email?.attachments?.length && (
					<Modal.Section>
						{state.email.attachments?.map((file, index) => (
							<Attachment
								key={index}
								file={file}
								onRemove={() =>
									setState((c) => ({
										...c,
										email: {
											...c.email,
											attachments: c.email.attachments?.filter((_, i) => i !== index),
										},
									}))
								}
							/>
						))}
					</Modal.Section>
				)}
				<Modal.Section>
					<SellfinityQuill
						ref={editorRef}
						value={state.email.body}
						onChange={(e) => setState((curr) => ({ ...curr, email: { ...curr.email, body: e } }))}
					/>
				</Modal.Section>

				<div style={{ width: 50, height: 50, display: "none" }}>
					<DropZone
						openFileDialog={openFileDialog}
						onFileDialogClose={() => {
							setOpenFileDialog(false);
						}}
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							acceptedFiles.forEach((file) => {
								const reader = new FileReader();
								reader.onload = addFile(file);
								reader.readAsDataURL(file);
							});
						}}
					>
						<DropZone.FileUpload
							actionTitle={t("common.upload.actions.choose", "Välj fil") as string}
							actionHint={t("common.upload.actions.helptext", "Eller dra och släpp en fil") as string}
						/>
					</DropZone>
				</div>
			</Wrapper>
		</Modal>
	);
};

export default withTranslation(["contact"], { withRef: true })(EmailModal);

const Wrapper = styled.div`
	.attachment_files {
		background-color: var(--main2);
	}
`;
