import { Button, Modal, Spinner } from "@shopify/polaris";
import { CirclePlusMajor, EditMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import ContactModal from "./ContactModal";
import { toastr } from "./toastr";
import SearchField from "./search_field";
import InfoView from "./InfoView";

class AddComanyModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.props.open) {
			this.setState({ company: null });
		}
	}

	checkDuplicates() {
		this.setState({ loadingDuplicates: true });
		API.get("/api/contacts.json", { params: { company_number: this.state.company.orgnr } })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loadingDuplicates: false });

					return;
				}
				this.setState({ loadingDuplicates: false, duplicates: result.data.contacts });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	setCompany(company) {
		this.setState({ loading: true });
		API.get(`/api/contacts/roaring/${company.companyId}.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loading: false });

					return;
				}
				this.setState({ company: this.transformToContact(result.data.company), loading: false }, this.checkDuplicates.bind(this));
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	transformToContact(rc = {}) {
		return Object.assign(rc, {
			address1: rc.address,
			street: rc.address,
			city: rc.town,
			country: "SE",
			name: rc.companyName,
			orgnr: rc.companyId,
			phone: rc.phoneNumber,
			zip: rc.zipCode,
			is_company: true,
			fromRoaring: true,
			metafields: rc.webAddress && [{ handle: this.props.t("contact.terms", "hemsida"), metafield_id: 1, value: rc.webAddress }],
		});
	}

	saveContact() {
		const company = Object.assign({}, this.state.company);
		this.setState({ saving: true });

		if (this.props.company && !this.props.contact.is_company) {
			this.props.contact.parent_id = company.id;
			this.props.contact.parent = company;
		}

		API.post("/api/contacts.json", company)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (result.data.contact && result.data.contact.is_company) {
					toastr.success(this.props.t("contact.company.responses.created", "Företag skapad"));
				} else if (result.data.contact) {
					toastr.success(this.props.t("contact.person.responses.created", "Kontaktperson skapad"));
				}

				if (result.data.contact && !this.props.contact.is_company) {
					this.props.contact.parent_id = result.data.contact.id;
					this.props.contact.parent = result.data.contact;
				}

				this.props.onUpdate(result.data.contact);
				if (this.props.onCreate) {
					this.props.onCreate(result.data.contact);
				}
				this.props.onClose();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		const company = this.state.company;
		const details =
			company &&
			[
				{ label: this.props.t("contact.fields.email", "Mailadress"), value: company.email, type: "email" },
				{ label: this.props.t("contact.fields.phone", "Telefonnummer"), value: company.phone, type: "phone" },
				{ label: this.props.t("contact.fields.mobile_phone", "Mobilnummer"), value: company.mobile_phone, type: "phone" },
				{
					label: this.props.t("contact.fields.orgnr", "Orgnummer"),
					value: company.orgnr,
					type: "orgnr",
					loading: this.state.loadingDuplicates,
					duplicates: this.state.duplicates,
				},
				{ label: this.props.t("contact.fields.ort", "Ort"), value: company.city },
				{ label: this.props.t("contact.fields.zip", "Postnummer"), value: company.zip },
				{ label: this.props.t("contact.fields.address", "Adress"), value: company.address },
				{ label: this.props.t("contact.fields.note", "Anteckning"), value: company.note },
				company.metafields &&
					company.metafields.map((m) => ({
						label: m.handle,
						value: m.value,
					})),
				{ label: this.props.t("contact.fields.tags", "Taggar"), value: company.tags && company.tags.map((t) => t.title).join(", ") },
			]
				.flat(1)
				.filter(({ value } = {}) => value);

		if (!this.props.contact || !this.props.contact.is_company) {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <ContactModal {...this.props} />;
		}
		return (
			<React.Fragment>
				<Modal
					open={this.props.open}
					onClose={this.props.onClose}
					title={this.props.t("contact.company.actions.new", "Nytt företag")}
					secondaryActions={{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					}}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						disabled: !this.state.company,
						loading: this.state.saving,
						onAction: this.saveContact.bind(this),
					}}
				>
					<Modal.Section>
						<React.Fragment>
							<SearchField
								placeholder={this.props.t("contact.fields.company.placeholder", "Sök efter företag")}
								resourceName={{
									singular: this.props.t("contact.company.singular", "företag"),
									plural: this.props.t("contact.company.plural", "företag"),
								}}
								resource="companies/search.json"
								resource_handle="companies"
								onSelect={this.setCompany.bind(this)}
								label_handle="title"
								id_handle="companyId"
							/>
							<div style={{ margin: "10px 0" }}>
								<Button plain icon={CirclePlusMajor} onClick={() => this.setState({ contactModalIsOpen: true, company: false })}>
									{this.props.t("contact.actions.add_manually", "Lägg till manuellt")}
								</Button>
							</div>
							{this.state.loading && <Spinner />}
							{this.state.company && (
								<div className="company-search__details">
									<div className="company-search__details__header">
										<h1>{this.state.company.name}</h1>

										<Button onClick={() => this.setState({ contactModalIsOpen: true })} icon={EditMajor}>
											{this.props.t("common.actions.edit", "Ändra")}
										</Button>
									</div>
									<div className="company-search__details__content">
										<InfoView details={details} />
									</div>
								</div>
							)}
						</React.Fragment>
					</Modal.Section>
				</Modal>
				<ContactModal
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...this.props}
					open={this.state.contactModalIsOpen}
					// nomodal
					// company={this.state.company}
					contact={this.state.company}
					onUpdate={this.props.onUpdate}
					isCompany={this.props.isCompany}
					onClose={() => this.setState({ contactModalIsOpen: false })}
					onCreateNoSave={(company) => {
						if (this.props.contact?.is_company) {
							this.setState({ company, contactModalIsOpen: false });
						}
					}}
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["contact", "common"], { withRef: true })(AddComanyModal);
