/* eslint-disable no-console */
/* eslint-disable camelcase */
import React from "react";
import moment from "moment";
import axios from "axios";
import { Avatar } from "@shopify/polaris";
import uniq from "lodash/uniq";
import API from "../../API";
import MemberAvatar from "./components/MemberAvatar";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import i18n from "../../i18n";
import { filterDefaultValues } from "./boardutils/BoardUtils";

class BoardHelper {
	getMyRoles(board) {
		let roles = ["ROLE_BOARD_READ"];
		if (board && board.members && board.members.length) {
			for (let i = 0; i < board.members.length; i++) {
				if (board.members[i].user_id == store.getState().user.id) {
					roles = board.members[i].roles;
					break;
				}
			}
		}

		if (store.getState().user.roles.includes("ROLE_SUPER_ADMIN")) {
			roles.push("ROLE_BOARD_ADMIN");
		}

		if (store.getState().user.roles.includes("ROLE_ADMIN") && board.type == "public") {
			roles.push("ROLE_BOARD_ADMIN");
		}

		return [...new Set(roles)];
	}

	getSortValue(a, b, sort) {
		let isString = false;
		let isDate = false;
		let aVal = null;
		let bVal = null;

		let sortVal = 0;

		if (sort.column_id == "title") {
			aVal = a.title;
			bVal = b.title;
			isString = true;
		} else if (sort.column_id == "group") {
			aVal = this.getGroup(a.group_id).title;
			bVal = this.getGroup(b.group_id).title;
			isString = true;
		} else if (sort.column_type == "board_number") {
			aVal = a.board_number;
			bVal = b.board_number;
		} else if (sort.column_type == "created_at") {
			aVal = a.created_at;
			bVal = b.created_at;
			isDate = true;
		} else {
			if (sort.column_type == "datetime" || sort.column_type == "timeline") {
				isDate = true;
			}
			if (sort.column_type == "text") {
				isString = true;
			}
			if (sort.column_type == "person") {
				isString = true;
			}
			const aValues = this.getColumnValues(a, sort.column);
			const bValues = this.getColumnValues(b, sort.column);
			if (aValues.length > 0) {
				aVal = aValues[0].value;
				if (!aVal) {
					aVal = "";
				} else if (sort.column_type == "datetime") {
					aVal = aVal.datetime;
				} else if (sort.column_type == "timeline") {
					aVal = aVal.start;
				} else if (sort.column_type == "person") {
					if (aVal.length > 0) {
						const user = this.getUser(aVal[0]);
						aVal = user ? user.name : aVal[0].toString();
					} else {
						aVal = "";
					}
				} else if (sort.column_type == "timetrack") {
					if (aVal.running) {
						const duration = moment.duration(moment().diff(moment(aVal.started_at)));
						aVal = aVal.duration + duration.asSeconds();
					} else {
						aVal = aVal.duration;
					}
				}
			}
			if (bValues.length > 0) {
				bVal = bValues[0].value;
				if (!bVal) {
					bVal = "";
				} else if (sort.column_type == "datetime") {
					bVal = bVal.datetime;
				} else if (sort.column_type == "timeline") {
					bVal = bVal.start;
				} else if (sort.column_type == "person") {
					if (bVal.length > 0) {
						const user = this.getUser(bVal[0]);
						bVal = user ? user.name : bVal[0].toString();
					} else {
						bVal = "";
					}
				} else if (sort.column_type == "timetrack") {
					if (bVal.running) {
						const duration = moment.duration(moment().diff(moment(bVal.started_at)));
						bVal = bVal.duration + duration.asSeconds();
					} else {
						bVal = bVal.duration;
					}
				}
			}
		}

		if (isString) {
			if (aVal === null) {
				aVal = "";
			}
			if (bVal === null) {
				bVal = "";
			}
			if (sort.order == "ASC") {
				sortVal = aVal.localeCompare(bVal);
			} else {
				sortVal = bVal.localeCompare(aVal);
			}
		} else if (isDate) {
			if (sort.order == "ASC") {
				if (aVal && bVal) {
					if (moment(aVal).unix() < moment(bVal).unix()) {
						sortVal = -1;
					} else if (moment(aVal).unix() > moment(bVal).unix()) {
						sortVal = 1;
					} else {
						sortVal = 0;
					}
				} else {
					if (!aVal && bVal) {
						sortVal = -1;
					} else if (aVal && !bVal) {
						sortVal = 1;
					} else {
						sortVal = 0;
					}
				}
			} else {
				if (aVal && bVal) {
					if (moment(aVal).unix() < moment(bVal).unix()) {
						sortVal = 1;
					} else if (moment(aVal).unix() > moment(bVal).unix()) {
						sortVal = -1;
					} else {
						sortVal = 0;
					}
				} else {
					if (!aVal && bVal) {
						sortVal = 1;
					} else if (aVal && !bVal) {
						sortVal = -1;
					} else {
						sortVal = 0;
					}
				}
			}
		} else {
			if (!aVal) {
				aVal = 0;
			}
			if (!bVal) {
				bVal = 0;
			}
			if (sort.order == "ASC") {
				if (aVal < bVal) {
					sortVal = -1;
				} else if (aVal > bVal) {
					sortVal = 1;
				} else {
					sortVal = 0;
				}
			} else {
				if (aVal < bVal) {
					sortVal = 1;
				} else if (aVal > bVal) {
					sortVal = -1;
				} else {
					sortVal = 0;
				}
			}
		}

		return sortVal;
	}

	fetchRows(ids, dashboardId, callback, paramsP = {}, light = false, skipRedux = false) {
		this.requests = [];
		this.results = {
			rows: [],
			linked_rows: [],
			linked_from_rows: [],
			contacts: [],
			tickets: [],
			visitors: [],
		};
		this.callback = callback;
		for (let i = 0; i < ids.length; i++) {
			const id = ids[i];
			const params = Object.assign(paramsP || {}, {});
			params.limit = 1000;
			params.offset = 0;
			this.requests.push({
				called: false,
				complete: false,
				type: "rows",
				url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/rows.json",
				options: { params },
			});
			if (!light) {
				this.requests.push({
					called: false,
					complete: false,
					type: "linked_rows",
					url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/linked_rows.json",
					options: { params },
				});
				this.requests.push({
					called: false,
					complete: false,
					type: "linked_from_rows",
					url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/linked_from_rows.json",
					options: { params },
				});
				this.requests.push({
					called: false,
					complete: false,
					type: "contacts",
					url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/row_contacts.json",
					options: { params },
				});
				this.requests.push({
					called: false,
					complete: false,
					type: "tickets",
					url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/tickets.json",
					options: { params },
				});
				this.requests.push({
					called: false,
					complete: false,
					type: "visitors",
					url: "/api/" + (dashboardId ? "dashboards/" + dashboardId + "/boards" : "boards") + "/" + id + "/visitors.json",
					options: { params },
				});
			}
		}

		this.fetchRequests(light, skipRedux);
	}

	async fetchResources(type, allIds = []) {
		if (!this.fetchedResources) {
			this.fetchedResources = {};
		}
		const allValidIds = [];
		for (let i = 0; i < allIds.length; i++) {
			const handle = type + "_" + allIds[i];
			if (!(handle in this.fetchedResources)) {
				this.fetchedResources[handle] = true;
				allValidIds.push(allIds[i]);
			}
		}
		if (allValidIds.length < 1) {
			return;
		}
		const chunks = this.chunks(allValidIds, 50);

		return Promise.allSettled(
			chunks.map((ids) => {
				if (type == "contacts" || type == "contact") {
					return API.get("/api/contacts.json", { params: { ids } })
						.then(this.handleFetchResourceResponse.bind(this, type))
						.catch((error) => {
							toastr.error(error);
						});
				}
				if (type == "tickets" || type == "ticket") {
					return API.get("/api/tickets.json", { params: { ids } })

						.then(this.handleFetchResourceResponse.bind(this, type))
						.catch((error) => {
							toastr.error(error);
						});
				}
				if (type == "linked_rows" || type == "rowlink") {
					return API.get("/api/board_rows/search.json", { params: { ids } })
						.then(this.handleFetchResourceResponse.bind(this, type))
						.catch((error) => {
							toastr.error(error);
						});
				}
				return true;
			})
		).catch((error) => {
			toastr.error(error);
		});
	}

	handleFetchResourceResponse(type, result) {
		if (type == "linked_rows" || type == "rowlink") {
			store.dispatch({ type: "SET_BOARD_LINKED_ROWS", linked_rows: result.data.rows });
			return result.data.rows;
		}
		if (type == "contacts" || type == "contact") {
			store.dispatch({ type: "SET_BOARD_CONTACTS", contacts: result.data.contacts });
			return result.data.contacts;
		}
		if (type == "tickets" || type == "ticket") {
			store.dispatch({ type: "SET_BOARD_TICKETS", tickets: result.data.tickets });
			return result.data.tickets;
		}
	}

	chunks(inputArray, perChunk) {
		const result = inputArray.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk);

			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []; // start a new chunk
			}

			resultArray[chunkIndex].push(item);

			return resultArray;
		}, []);

		return result;
	}

	getColumnValues(row, column = {}) {
		if (!row?.column_values) {
			return [];
		}
		if (column.type == "created_at") {
			return [
				{
					column_id: column.id,
					value: row.created_at,
				},
			];
		} else if (column.type == "board_number") {
			return [
				{
					column_id: column.id,
					value: row.board_number,
				},
			];
		} else if (column.type == "mirror" && column.link_column_id) {
			const arr = [];
			if (column.link_column_id in row.column_values) {
				for (let i = 0; i < row.column_values[column.link_column_id].value.length; i++) {
					if (!column.connected_column && column.options.connected_column_id) {
						const board = this.getBoard(row.board_id);
						const linkColumn = board.original_columns.find((col) => col.id == column.link_column_id);
						if (linkColumn.type == "contact") {
							// Contact
							const contact = this.getContact(row.column_values[column.link_column_id].value[i]);
							if (contact) {
								let val = "";
								if (column.options.connected_column_id == "company") {
									if (contact.is_company) {
										val = contact.name;
									} else if (contact.parent) {
										val = contact.parent.name;
									}
								} else if (column.options.connected_column_id == "phone") {
									val = contact.phone;
								} else if (column.options.connected_column_id == "tags") {
									val = contact.tags || "";
								} else if (column.options.connected_column_id == "parent_tags") {
									val = contact.parent?.tags || "";
								} else if (column.options.connected_column_id == "person") {
									if (!contact.is_company) {
										val = contact.name;
									}
								} else if (column.options.connected_column_id == "orgnr") {
									if (contact.is_company) {
										val = contact.orgnr;
									} else if (contact.parent) {
										val = contact.parent.orgnr;
									}
								} else if (column.options.connected_column_id == "email") {
									val = contact.email;
								} else if (column.options.connected_column_id == "note") {
									val = contact.note;
								} else if (column.options.connected_column_id == "reference") {
									val = contact.reference;
								} else if (column.options.connected_column_id.substring(0, 5) == "meta_") {
									const metafieldId = column.options.connected_column_id.substring(5);
									if (contact.metafields) {
										const metafield = contact.metafields.find((meta) => meta.metafield_id + "" == metafieldId + "");
										if (metafield) {
											val = metafield.value;
										}
									}
									if (!val && contact.parent && contact.parent.metafields) {
										const metafield = contact.parent.metafields.find((meta) => meta.metafield_id + "" == metafieldId + "");
										if (metafield) {
											val = metafield.value;
										}
									}
								}
								arr.push({ column_id: column.options.connected_column_id, value: val });
							}
						} else if (linkColumn.type == "visitor") {
							// Visitor
							const visitor = this.getVisitor(row.column_values[column.link_column_id].value[i]);
							if (visitor) {
								let val = "";
								if (column.options.connected_column_id == "domain") {
									val = visitor.domain;
								} else if (column.options.connected_column_id == "email") {
									val = visitor.email;
								} else if (column.options.connected_column_id == "device_type") {
									val = visitor.device_type;
								} else if (column.options.connected_column_id == "city") {
									val = visitor.city;
								} else if (column.options.connected_column_id == "country") {
									val = visitor.country;
								} else if (column.options.connected_column_id == "num_hits") {
									val = visitor.num_hits;
								} else if (column.options.connected_column_id == "first_url") {
									val = visitor.first_url;
								} else if (column.options.connected_column_id == "first_title") {
									val = visitor.first_title;
								} else if (column.options.connected_column_id == "time_spent") {
									val = visitor.time_spent;
								} else if (column.options.connected_column_id == "referrer") {
									val = visitor.referrer;
								} else if (column.options.connected_column_id == "user_agent") {
									val = visitor.user_agent;
								} else if (column.options.connected_column_id == "ip") {
									val = visitor.ip;
								}
								arr.push({ column_id: column.options.connected_column_id, value: val });
							}
						}
					} else if (column.connected_column) {
						const linkedRow = this.getLinkedRow(row.column_values[column.link_column_id].value[i]);
						if (linkedRow) {
							const values = this.getColumnValues(linkedRow, column.connected_column);
							if (values.length > 0) {
								for (let x = 0; x < values.length; x++) {
									arr.push(values[x]);
								}
							}
						}
					}
				}
			}
			return arr;
		} else {
			if (column.id in row.column_values) {
				return [row.column_values[column.id]];
			}
		}
		return [];
	}

	async onUpdateMultipleRowsValue(paramRows, column, data, callback, reject) {
		// Update single row
		if (!paramRows?.length) {
			return;
		}

		const rows = paramRows.map((row) => {
			if (column.id in row.column_values) {
				row.column_values[column.id] = Object.assign(row.column_values[column.id], data);
			} else {
				row.column_values[column.id] = Object.assign({ column_id: column.id }, data);
			}

			console.debug("row.column_values[column.id]:", row.column_values[column.id]);
			return row;
		});
		this.updateRows(rows, "update");
		const ids = rows.map((r) => r.id);

		return API.post(`/api/boards/${column.board_id}/columns/${column.id}/rows/values.json`, data, {
			params: { ids },
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.updateRows(result.data.rows, "update");
				if (callback && typeof callback === "function") callback(result.data);
				return result.data;
			})
			.catch((error) => {
				toastr.error(error);
				if (reject) reject(error);
			});
	}

	async onUpdateValue(row, column, data, callback, reject) {
		// Update single row
		if (!row || !row.id || !column) {
			return;
		}

		if (column.id in row.column_values) {
			row.column_values[column.id] = Object.assign(row.column_values[column.id], data);
		} else {
			row.column_values[column.id] = Object.assign({ column_id: column.id }, data);
		}

		this.updateRows([row], "update");
		return API.post("/api/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/values.json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.updateRows([result.data.row], "update");
				if (callback && typeof callback === "function") callback(result.data);
				return result.data;
			})
			.catch((error) => {
				toastr.error(error);
				if (reject) reject(error);
			});
	}

	getUser(id) {
		for (let i = 0; i < store.getState().users.length; i++) {
			if (store.getState().users[i].id == id) {
				return store.getState().users[i];
			}
		}
		return null;
	}

	getColumn(column) {
		if (column.type == "mirror" && column.connected_column) {
			return this.getColumn(column.connected_column);
		} else if (column.type == "mirror" && !column.connected_column && column.options.connected_column_id) {
			if (column.options.connected_column_id == "num_hits") {
				return { id: column.options.connected_column_id, type: "number", title: column.options.connected_column_id };
			} else if (column.options.connected_column_id == "time_spent") {
				return { id: column.options.connected_column_id, type: "number", title: column.options.connected_column_id };
			} else if (column.options.connected_column_id == "tags" || column.options.connected_column_id == "parent_tags") {
				return {
					id: column.options.connected_column_id,
					type: "badges",
					title: column.options.connected_column_id,
					options: {
						choices: uniq(
							Object.values(store.getState().board_contacts)?.flatMap((con) => {
								if (column.options.connected_column_id == "tags") return con.tags || [];
								if (column.options.connected_column_id == "parent_tags") return con.parent?.tags || [];
								return [];
							}) || []
						),
					},
				};
			} else {
				// Vill disabla connected kontakt kolumner (telefon nr, mm..) men osäker om där är andra connected kolumner som ska vara redigerbara.
				return { ...column, id: column.options.connected_column_id, type: "text", title: column.options.connected_column_id /* disabled: true */ };
			}
		}
		return column;
	}

	getRowLink(id) {
		if (id in store.getState().board_linked_rows) {
			return store.getState().board_linked_rows[id];
		}
		return null;
	}

	getRowLinkFrom(id) {
		if (id in store.getState().board_linked_from_rows) {
			return store.getState().board_linked_from_rows[id];
		}
		return null;
	}

	getRow(id) {
		if (id in store.getState().board_rows) {
			return store.getState().board_rows[id];
		}
		return null;
	}

	getWorkspace(id) {
		if (id in store.getState().workspaces) {
			return store.getState().workspaces[id];
		}
		return null;
	}

	getCompanyNumber(id) {
		if (id in store.getState().board_companies) {
			return store.getState().board_companies[id];
		}
		return null;
	}

	getBoard(id) {
		if (id in store.getState().boards) {
			return this.filterOutBlockedColumns(store.getState().boards[id]);
		}
		return null;
	}

	getGroupRows(id) {
		const rows = store.getState().board_rows;

		if (Array.isArray(rows)) return rows;

		return Object.values(rows).filter((row) => row.group_id == id);

		// let arr = [];
		// for (let i in rows) {
		// 	if (rows[i].group_id == id) {
		// 		arr.push(rows[i]);
		// 	}
		// }
		// return arr;
	}

	getContact(id) {
		if (Array.isArray(id)) {
			return id
				.map((i) => {
					if (i in store.getState().board_contacts) {
						return store.getState().board_contacts[i];
					}
					return null;
				})
				.filter((i) => i);
		}

		if (id in store.getState().board_contacts) {
			return store.getState().board_contacts[id];
		}
		return null;
	}

	getVisitor(id) {
		if (Array.isArray(id)) {
			return id
				.map((i) => {
					if (i in store.getState().board_visitors) {
						return store.getState().board_visitors[i];
					}
					return null;
				})
				.filter((i) => i);
		}

		if (id in store.getState().board_visitors) {
			return store.getState().board_visitors[id];
		}
		return null;
	}

	getTicket(id) {
		if (id in store.getState().board_tickets) {
			return store.getState().board_tickets[id];
		}
		return null;
	}

	getGroup(id) {
		if (id in store.getState().board_groups) {
			return store.getState().board_groups[id];
		}
		return null;
	}

	getLinkedRow(id) {
		if (id in store.getState().board_linked_rows) {
			return store.getState().board_linked_rows[id];
		}
		return null;
	}

	removeRow(id) {
		const row = this.getRow(id);
		if (row) {
			store.dispatch({
				type: "UPDATE_BOARD_POSITION_UP",
				group_id: row.group_id,
				position: row.position,
			});
			store.dispatch({ type: "REMOVE_BOARD_ROW", row });
		}
	}

	// function for adding row when UNDOING archive
	addRowAtOldPosition(id) {
		const row = this.getRow(id);
		if (row) {
			store.dispatch({
				type: "UPDATE_BOARD_POSITION_UP",
				group_id: row.group_id,
				position: row.position,
			});
			store.dispatch({ type: "UPDATE_BOARD_ROW", row });
		}
	}

	updateColumn(column) {
		const o = this.getBoard(column.board_id);
		const board = Object.assign({}, o);
		for (let i = 0; i < board.columns.length; i++) {
			if (board.columns[i].id == column.id) {
				board.columns[i] = column;
				store.dispatch({ type: "UPDATE_BOARD", board: this.filterOutBlockedColumns(board) });
				break;
			}
		}
	}

	updateColumnOptions(column, options, callback = () => {}) {
		this.updateColumn(column);
		API.put("/api/boards/" + column.board_id + "/columns/" + column.id + ".json", { options }, { params: {} })
			.then((result) => {
				if (callback && typeof callback === "function") callback(result);
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.updateColumn(result.data.column);
			})
			.catch((error) => toastr.error(error));
	}

	removeColumn(column) {
		const board = Object.assign({}, this.getBoard(column.board_id));
		const columns = [];
		for (let i = 0; i < board.columns.length; i++) {
			if (board.columns[i].id != column.id) {
				columns.push(board.columns[i]);
			}
		}
		board.columns = columns;
		store.dispatch({ type: "UPDATE_BOARD", board: this.filterOutBlockedColumns(board) });
	}

	createRow(boardId, title, position, groupId) {
		if (!Object.values(store.getState().board_groups).length) {
			return;
		}
		if (!title) {
			toastr.error(i18n.t("row.errors.title_missing", "Titel måste vara satt"));
			return;
			// throw new Error(i18n.t("row.errors.title_missing", "Titel måste vara satt"));
		}
		const board = this.getBoard(boardId);
		const values = filterDefaultValues(board.default_values);
		let columnValues = {};

		if (!values || values.length < 1) {
			columnValues = {};
		}
		const cols = {};
		for (let i = 0; i < values.length; i++) {
			cols[values[i].column_id] = values[i];
		}
		columnValues = cols;

		const data = {
			title,
			comment_count: 0,
			position,
			board_id: boardId,
			group_id: groupId || Object.values(store.getState().board_groups)[0].id,
			values,
			column_values: columnValues,
			ref: Math.random() * 100,
		};
		store.dispatch({
			type: "UPDATE_BOARD_POSITION_DOWN",
			group_id: data.group_id,
			position: data.position,
		});
		this.updateRows([data], "add");

		API.post("/api/boards/" + boardId + "/groups/" + data.group_id + "/rows.json", data, { params: {} })
			.then((result) => {
				result.data.row.ref = result.data.ref;
				this.updateRows([result.data.row], "update");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	createGroup(board_id, title, position) {
		console.debug("adding group " + title);
		const data = {
			title,
			position,
			board_id,
			ref: Math.random() * 100,
		};

		API.post("/api/boards/" + board_id + "/groups.json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				store.dispatch({
					type: "UPDATE_BOARD_GROUP_POSITION_DOWN",
					board_id: result.data.group.board_id,
					position: result.data.group.position,
				});
				store.dispatch({ type: "ADD_BOARD_GROUP", group: result.data.group });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	removeGroup(id) {
		const group = this.getGroup(id);
		if (group) {
			const rows = this.getGroupRows(id);
			for (let i = 0; i < rows.length; i++) {
				store.dispatch({ type: "REMOVE_BOARD_ROW", row: rows[i] });
			}
			store.dispatch({
				type: "UPDATE_BOARD_GROUP_POSITION_UP",
				board_id: group.board_id,
				position: group.position,
			});
			store.dispatch({ type: "REMOVE_BOARD_GROUP", group });
		}
	}

	getFilterValuesFromRow(row, col) {
		const rowValues = this.getColumnValues(row, col);
		const column = this.getColumn(col);

		let values = [null];
		for (let i = 0; i < rowValues.length; i++) {
			const value = rowValues[i].value;
			if (value === 0 || value === "0" || value) {
				if (column.type == "created_at") {
					if (moment(value) >= moment().startOf("today") && moment(value) <= moment().endOf("today")) {
						values.push("today");
					}
					if (moment(value) >= moment().startOf("tomorrow") && moment(value) <= moment().endOf("tomorrow")) {
						values.push("tomorrow");
					}
					if (moment(value) >= moment().startOf("isoweek") && moment(value) <= moment().endOf("isoweek")) {
						values.push("this_week");
					}
					if (moment(value) >= moment().startOf("month") && moment(value) <= moment().endOf("month")) {
						values.push("this_month");
					}
					if (moment(value) >= moment().add(7, "days").startOf("isoweek") && moment(value) <= moment().add(7, "days").endOf("isoweek")) {
						values.push("next_week");
					}
					if (moment(value) >= moment().add(1, "months").startOf("month") && moment(value) <= moment().add(1, "months").endOf("month")) {
						values.push("next_month");
					}
					if (moment(value) >= moment().subtract(7, "days").startOf("isoweek") && moment(value) <= moment().subtract(7, "days").endOf("isoweek")) {
						values.push("last_week");
					}
					if (moment(value) >= moment().subtract(1, "months").startOf("month") && moment(value) <= moment().subtract(1, "months").endOf("month")) {
						values.push("last_month");
					}
					if (moment(value) > moment()) {
						values.push("future");
					}
					if (moment(value) < moment()) {
						values.push("past");
					}
				} else if (column.type == "datetime") {
					if (moment(value.datetime) >= moment().startOf("today") && moment(value.datetime) <= moment().endOf("today")) {
						values.push("today");
					}
					if (moment(value.datetime) >= moment().startOf("tomorrow") && moment(value.datetime) <= moment().endOf("tomorrow")) {
						values.push("tomorrow");
					}
					if (moment(value.datetime) >= moment().startOf("isoweek") && moment(value.datetime) <= moment().endOf("isoweek")) {
						values.push("this_week");
					}
					if (moment(value.datetime) >= moment().startOf("month") && moment(value.datetime) <= moment().endOf("month")) {
						values.push("this_month");
					}
					if (
						moment(value.datetime) >= moment().add(7, "days").startOf("isoweek") &&
						moment(value.datetime) <= moment().add(7, "days").endOf("isoweek")
					) {
						values.push("next_week");
					}
					if (
						moment(value.datetime) >= moment().add(1, "months").startOf("month") &&
						moment(value.datetime) <= moment().add(1, "months").endOf("month")
					) {
						values.push("next_month");
					}
					if (
						moment(value.datetime) >= moment().subtract(7, "days").startOf("isoweek") &&
						moment(value.datetime) <= moment().subtract(7, "days").endOf("isoweek")
					) {
						values.push("last_week");
					}
					if (
						moment(value.datetime) >= moment().subtract(1, "months").startOf("month") &&
						moment(value.datetime) <= moment().subtract(1, "months").endOf("month")
					) {
						values.push("last_month");
					}
					if (moment(value.datetime) > moment()) {
						values.push("future");
					}
					if (moment(value.datetime) < moment()) {
						values.push("past");
					}
				} else if (column.type == "timeline") {
					values = [];
					if (moment(value.start) <= moment() && moment(value.end) >= moment()) {
						values.push("ongoing");
					} else if (moment(value.start) > moment()) {
						values.push("future");
					} else if (moment(value.end) < moment()) {
						values.push("past");
					}
					if (
						(moment(value.start) >= moment().startOf("today") && moment(value.start) <= moment().endOf("today")) ||
						(moment(value.end) >= moment().startOf("today") && moment(value.end) <= moment().endOf("today"))
					) {
						values.push("today");
					}
					if (
						(moment(value.start) >= moment().startOf("tomorrow") && moment(value.start) <= moment().endOf("tomorrow")) ||
						(moment(value.end) >= moment().startOf("tomorrow") && moment(value.end) <= moment().endOf("tomorrow"))
					) {
						values.push("tomorrow");
					}
					if (
						(moment(value.start) >= moment().startOf("isoweek") && moment(value.start) <= moment().endOf("isoweek")) ||
						(moment(value.end) >= moment().startOf("isoweek") && moment(value.end) <= moment().endOf("isoweek"))
					) {
						values.push("this_week");
					}
					if (
						(moment(value.start) >= moment().startOf("month") && moment(value.start) <= moment().endOf("month")) ||
						(moment(value.end) >= moment().startOf("month") && moment(value.end) <= moment().endOf("month"))
					) {
						values.push("this_month");
					}
					if (
						(moment(value.start) >= moment().add(7, "days").startOf("isoweek") && moment(value.start) <= moment().add(7, "days").endOf("isoweek")) ||
						(moment(value.end) >= moment().add(7, "days").startOf("isoweek") && moment(value.end) <= moment().add(7, "days").endOf("isoweek"))
					) {
						values.push("next_week");
					}
					if (
						(moment(value.start) >= moment().add(1, "months").startOf("month") && moment(value.start) <= moment().add(1, "months").endOf("month")) ||
						(moment(value.end) >= moment().add(1, "months").startOf("month") && moment(value.end) <= moment().add(1, "months").endOf("month"))
					) {
						values.push("next_month");
					}
					if (
						(moment(value.start) >= moment().subtract(7, "days").startOf("isoweek") &&
							moment(value.start) <= moment().subtract(7, "days").endOf("isoweek")) ||
						(moment(value.end) >= moment().subtract(7, "days").startOf("isoweek") &&
							moment(value.end) <= moment().subtract(7, "days").endOf("isoweek"))
					) {
						values.push("last_week");
					}
					if (
						(moment(value.start) >= moment().subtract(1, "months").startOf("month") &&
							moment(value.start) <= moment().subtract(1, "months").endOf("month")) ||
						(moment(value.end) >= moment().subtract(1, "months").startOf("month") &&
							moment(value.end) <= moment().subtract(1, "months").endOf("month"))
					) {
						values.push("last_month");
					}
				} else if (column.type == "timetrack") {
					if (value.running) {
						values = ["running"];
					} else {
						values = ["paused"];
					}
				} else if (column.type == "button") {
					if (value && value.clicked) {
						values = ["clicked"];
					} else {
						values = [null];
					}
				} else if (column.type == "rowlink") {
					if (value.length) {
						values = value;
					} else {
						values = [null];
					}
				} else if (column.type == "link") {
					if (value && value.url) {
						values = [value.url];
					} else {
						values = [null];
					}
				} else if (column.type == "person") {
					if (value.length) {
						values = value;
					} else {
						values = [null];
					}
				} else if (column.type == "contact") {
					if (value.length) {
						values = value;
					} else {
						values = [null];
					}
				} else if (column.type == "ticket") {
					if (value.length) {
						values = value;
					} else {
						values = [null];
					}
				} else if (column.type == "tags") {
					if (value.length) {
						values = value;
					} else {
						values = [null];
					}
				} else if (column.type == "checklist") {
					if (value.length) {
						values = value.map((checklist) => checklist.title);
					} else {
						values = [null];
					}
				} else {
					values = [value];
				}
			}
		}
		return values;
	}

	matchFilter(row, filter, column) {
		// Basic filters
		if (filter.column_id != "search" && filter.column_id != "person" && filter.board_id != row.board_id) {
			return false;
		} else if (filter.column_id == "group") {
			if (Array.isArray(filter.value) && filter.value.indexOf(row.group_id) >= 0) {
				return true;
			}
			return false;
		} else if (filter.column_id == "parent_tagged") {
			const board = this.getBoard(row.board_id);
			const contactColumns = board?.columns?.filter((col) => col.type == "contact");
			const contactIds = contactColumns?.flatMap((col) => this.getColumnValues(row, col).flatMap((val) => val.value));

			const contactTags =
				contactIds?.flatMap((id) => {
					const c = this.getContact(id);
					if (c?.is_company) return c.tags || [];
					if (!c?.is_company && c?.parent) return c.parent?.tags || [];
					return [];
				}) || [];

			return filter.value?.every((tag) => contactTags.includes(tag));
		} else if (filter.column_id == "search") {
			const filterRegex = new RegExp(filter.value, "i");
			if (row.title.match(filterRegex)) {
				return true;
			}
			if (row.board_number && row.board_number.toString().match(filterRegex)) {
				return true;
			}

			if (row && row.metadata) {
				// eslint-disable-next-line no-restricted-syntax
				for (const i in row.metadata) {
					if (i != "persons") {
						for (let s = 0; s < row.metadata[i].filterLabels.length; s++) {
							if (row.metadata[i].filterLabels[s].title && row.metadata[i].filterLabels[s].title.toString().match(filterRegex)) {
								return true;
							}
						}
					}
				}
				return false;
			}
		} else if (filter.column_id == "person") {
			for (let i = 0; i < filter.value.length; i++) {
				if (row.metadata && row.metadata.persons && row.metadata.persons.indexOf(filter.value[i]) >= 0) {
					return true;
				}
			}
			return false;
		} else if (filter.column_id == "title") {
			const filterRegex = new RegExp(filter.value, "i");
			if (filter.operator == "equals") {
				if (row.title.toLowerCase() == filter.value.toLowerCase()) {
					return true;
				}
			} else if (row.title.match(filterRegex)) {
				return true;
			}
			return false;
		}

		if (column) {
			const values = this.getColumnValues(row, column);
			column = this.getColumn(column);

			if (Array.isArray(filter.value) && filter.value.indexOf(null) >= 0 && values.length < 1) {
				return true;
			} else if (filter.value === null && values.length < 1) {
				return true;
			}

			if (column.type === "checkbox" && (filter.value === null || filter.value?.every?.((v) => !v)) && values?.length < 1) {
				return true;
			}

			for (let s = 0; s < values.length; s++) {
				let value = values[s].value;
				let columnType = null;
				if (column.type == "created_at") {
					columnType = "datetime";
				} else if (column.type == "board_number") {
					columnType = "number";
				} else {
					columnType = "value";
					if (value || value === 0) {
						if (column.type == "datetime") {
							value = value.datetime;
							columnType = "datetime";
						} else if (column.type == "contact") {
							columnType = "boolean";

							if (value && value.length > 0 && value[0]) {
								value = true;
							} else {
								value = false;
							}
						} else if (column.type == "checkbox") {
							columnType = "boolean";
						} else if (column.type == "timeline") {
							value = value.start;
							columnType = "datetime";
						} else if (column.type == "text") {
							columnType = "text";
						} else if (column.type == "number") {
							columnType = "number";
						}
					}
				}

				if (columnType == "value") {
					if (Array.isArray(filter.value) && Array.isArray(value)) {
						for (let x = 0; x < value.length; x++) {
							if (filter.value.indexOf(value[x]) >= 0) {
								return true;
							}
						}
					} else if (Array.isArray(filter.value) && filter.value.indexOf(value) >= 0) {
						return true;
					} else if (String(filter.value) === String(value)) {
						// } else if (filter.value == value) {
						// [null] == 0 becomes true (shoud use "==="" (type checked) but not sure if it will break something)
						return true;
					}
				}
				if (columnType == "boolean") {
					if (filter.value[0] == value) {
						return true;
					}
					return false;
				}
				if (columnType == "datetime") {
					if (filter.operator == "between") {
						if (
							moment(value).isSameOrAfter(moment(filter.value + " 00:00:00")) &&
							moment(value).isSameOrBefore(moment(filter.value2 + " 23:59:59"))
						) {
							return true;
						}
					} else if (filter.operator == "period") {
						const toDate = moment().format("YYYY-MM-DD");
						let fromDate = null;
						if (filter.value == "last_7_days") {
							fromDate = moment().add(-7, "days").format("YYYY-MM-DD");
						} else if (filter.value == "last_30_days") {
							fromDate = moment().add(-30, "days").format("YYYY-MM-DD");
						}
						if (moment(value).isSameOrAfter(moment(fromDate + " 00:00:00")) && moment(value).isSameOrBefore(moment(toDate + " 23:59:59"))) {
							return true;
						}
					} else if (filter.operator == "equals") {
						if (moment(moment(value).format("YYYY-MM-DD")).isSame(moment(filter.value))) {
							return true;
						}
					}
				}
				if (columnType == "number") {
					if (filter.value2 && value > filter.value2) {
						return false;
					}
					if (filter.value && value < filter.value) {
						return false;
					}
					return true;
				}
				if (columnType == "text") {
					const filterRegex = new RegExp(filter.value, "i");
					if (filter.operator == "equals") {
						if (value.toLowerCase() == filter.value.toLowerCase()) {
							return true;
						}
					} else if (value.match(filterRegex)) {
						return true;
					}
				}
			}
			return false;
		}

		return false;
	}

	getColumnWidth(column) {
		if (column.type == "checkbox") {
			return 70;
		}
		return 130;
	}

	createCancelToken(request, c) {
		request.cancel = c;
	}

	fetchRequests(light, skipRedux) {
		let incomplete = 0;
		for (let i = 0; i < this.requests.length; i++) {
			const request = this.requests[i];
			if (!request.called) {
				this.requests[i].called = true;
				request.options.cancelToken = new axios.CancelToken(this.createCancelToken.bind(this, request));
				API.get(request.url, request.options)
					.then(this.handleFetchResponse.bind(this, request, light, skipRedux))
					.catch((error) => {
						if (axios.isCancel(error)) {
							return;
						}
						this.requests[i].complete = true;
						toastr.error(error);
					});
			}
			if (!request.complete) {
				incomplete++;
			}
		}

		if (incomplete == 0) {
			// Render results, no more requests
			store.dispatch({ type: "SET_BOARD_LINKED_FROM_ROWS", linked_from_rows: this.results.linked_from_rows });
			store.dispatch({ type: "SET_BOARD_LINKED_ROWS", linked_rows: this.results.linked_rows });
			store.dispatch({ type: "SET_BOARD_CONTACTS", contacts: this.results.contacts });
			store.dispatch({ type: "SET_BOARD_TICKETS", tickets: this.results.tickets });
			store.dispatch({ type: "SET_BOARD_VISITORS", visitors: this.results.visitors });
			if (!skipRedux) {
				if (light) {
					store.dispatch({
						type: "SET_BOARD_ROWS",
						rows: this.results.rows,
					});
				} else {
					this.updateRows(this.results.rows, "set");
				}
			}

			if (this.callback) {
				this.callback(this.results.rows);
			}

			this.results.rows.map((row) => {
				const board = store.getState().boards[row.board_id];
				return this.setRowMetaData(row, board);
			});
		}
	}

	cancelRequests() {
		if (this.requests) {
			for (let i = 0; i < this.requests.length; i++) {
				if (this.requests[i].called && !this.requests[i].complete) {
					this.requests[i].cancel();
				}
			}
		}
	}

	handleFetchResponse(request, light, skipRedux, result) {
		if (request.type == "rows") {
			for (let i = 0; i < result.data.rows.length; i++) {
				this.results.rows.push(result.data.rows[i]);
			}
		}
		if (request.type == "linked_rows") {
			for (let i = 0; i < result.data.linked_rows.length; i++) {
				this.results.linked_rows.push(result.data.linked_rows[i]);
			}
		}
		if (request.type == "linked_from_rows") {
			for (let i = 0; i < result.data.linked_from_rows.length; i++) {
				this.results.linked_from_rows.push(result.data.linked_from_rows[i]);
			}
		}
		if (request.type == "contacts") {
			for (let i = 0; i < result.data.contacts.length; i++) {
				this.results.contacts.push(result.data.contacts[i]);
			}
		}

		if (request.type == "tickets") {
			for (let i = 0; i < result.data.tickets.length; i++) {
				this.results.tickets.push(result.data.tickets[i]);
			}
		}

		if (request.type == "visitors") {
			for (let i = 0; i < result.data.visitors.length; i++) {
				this.results.visitors.push(result.data.visitors[i]);
			}
		}

		if (request.options.params.offset == 0) {
			// Build extra queries after first request only
			const pages = Math.floor(result.data.count / request.options.params.limit);
			for (let i = 1; i <= pages; i++) {
				const params = Object.assign({}, {});
				params.limit = request.options.params.limit;
				params.offset = i * request.options.params.limit;
				if (request.options && request.options.params && request.options.params.archived == 1) params.archived = 1;
				if (request.options && request.options.params && request.options.params.also_archived == 1) params.also_archived = 1;
				this.requests.push({
					called: false,
					complete: false,
					type: request.type,
					url: request.url,
					options: { params },
				});
			}
		}

		request.complete = true;

		this.fetchRequests(light, skipRedux);
	}

	getEventsFromRows(rows, boards, groups, fillInBetweenDates) {
		const addInBetweenDates = (values, dates) => {
			if (values.value.end) {
				const nrDaysBetween = moment(values.value.end).startOf("day").diff(moment(values.value.start).startOf("day"), "days");

				Array.from(Array(nrDaysBetween)).forEach((v, index) => {
					const eachDate = moment(values.value.start).add(index + 1, "days");
					dates.push(eachDate);
				});
			}
		};
		const earlierThisWeek = [];
		const today = [];
		const upcoming = [];
		const withoutDate = [];
		const done = [];

		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const board = boards.find((board) => board.id == row.board_id) || { columns: [] };
			const group = groups.find((group) => group.id == row.group_id);

			const dates = [];
			const dateColumns = [];
			let status = null;
			let complete = false;
			let person = [];
			let contacts = [];
			const statusColumnId = [];
			let personColumnId = null;
			const contactColumnId = (() => {
				try {
					const col = board && board.columns && board.columns.find((c) => c.type === "contact");
					return col ? col.id : null;
				} catch (error) {
					return null;
				}
			})();

			board.columns.forEach((column) => {
				if (column && (column.type == "datetime" || column.type == "timeline")) {
					if (column.connected_column_id) statusColumnId.push(column.connected_column_id);
					if (column.link_column_id) personColumnId = column.link_column_id;
				}
			});

			for (let s = 0; s < row.values.length; s++) {
				const columns = board && board.columns && board.columns.filter((column) => column.id == row.values[s].column_id);
				columns.forEach((column) => {
					if (column && (column.type == "datetime" || column.type == "timeline") && column.connected_column_id) {
						if (column.type == "datetime" && row.values[s].value && row.values[s].value.datetime) {
							dates.push(moment(row.values[s].value.datetime));
							dateColumns.push(column);
						} else if (column.type == "timeline" && row.values[s].value && row.values[s].value.start) {
							dates.push(moment(row.values[s].value.start));
							if (fillInBetweenDates) addInBetweenDates(row.values[s], dates);

							dateColumns.push(column);
						}
					}
				});
			}
			if (!personColumnId) {
				for (let s = 0; s < row.values.length; s++) {
					const column = board.columns.find((column) => column.id == row.values[s].column_id);
					if (column && column.type == "person") {
						// person column
						personColumnId = column.id;
						break;
					}
				}
			}

			if (dates.length < 1) {
				dates.push(null);
			}
			for (let s = 0; s < row.values.length; s++) {
				const column = board.columns.find((column) => column.id == row.values[s].column_id);
				if (column && statusColumnId.includes(column.id)) {
					// if (this.state.settings.my_week_type == 'deadlines') {
					status = row.values[s].value;
					if (column.options && column.options.statuses && column.options.statuses.find((s) => s.id == status)?.color == "#00c875") {
						complete = true;
					}
					// }
				} else if (column && column.id == personColumnId) {
					person = row.values[s].value;
				} else if (column && column.id == contactColumnId) {
					contacts = row.values[s].value;
				}
			}

			// eslint-disable-next-line no-restricted-syntax
			for (const x in dates) {
				const date = dates[x];
				const item = {
					board,
					group,
					date,
					column: dateColumns[x],
					dateColumn: dateColumns[x],
					statusColumn: board.columns.find((column) => statusColumnId.includes(column.id)) || board.columns.find((column) => column.type == "status"),
					statusValue: status,
					personColumn: board.columns.find((column) => column.id == personColumnId),
					personValue: person,
					contactColumn: board.columns.find((column) => column.id == contactColumnId),
					contactValue: contacts,
					row: rows[i],
				};

				if (complete) done.push(Object.assign({ complete }, item));

				// if (this.state.settings.my_week_type != 'deadlines' || statusColumnId) {
				if (!date) {
					withoutDate.push(item);
				} else if (date.format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
					const todoItem = Object.assign({ complete }, item);
					today.push(todoItem);
				}
				// else if (complete) {
				// 	cnt++;
				// 	done.push(item);

				// }
				else if (date.unix() <= moment().unix()) {
					const todoItem = Object.assign({ complete }, item);
					earlierThisWeek.push(todoItem);
				} else {
					const todoItem = Object.assign({ complete }, item);
					upcoming.push(todoItem);
				}
				// }
			}
		}

		return {
			earlierThisWeek,
			today,
			upcoming,
			// withoutDate,
			done,
		};
	}

	getLabelFromValue(value, board, column) {
		let title = null;
		let color = null;
		let prefix = null;

		if (column.type == "mirror" && column.link_column_id && column.connected_column) {
			const linkColumn = board.columns.find((col) => col.id == column.link_column_id);
			const linkedBoard = linkColumn.connected_board;
			return this.getLabelFromValue(value, linkedBoard, column.connected_column);
		}

		if (column.type == "timeline" || column.type == "datetime" || column.type == "created_at") {
			if (value.value == "ongoing") {
				title = i18n.t("labels.ongoing", "Aktiv");
			} else if (value.value == "future") {
				title = i18n.t("labels.future", "Kommande");
			} else if (value.value == "past") {
				title = i18n.t("labels.past", "Passerat");
			} else if (value.value == "today") {
				title = i18n.t("labels.today", "Idag");
			} else if (value.value == "tomorrow") {
				title = i18n.t("labels.tomorrow", "Imorgon");
			} else if (value.value == "this_week") {
				title = i18n.t("labels.this_week", "Denna vecka");
			} else if (value.value == "this_month") {
				title = i18n.t("labels.this_month", "Denna månad");
			} else if (value.value == "next_week") {
				title = i18n.t("labels.next_week", "Nästa vecka");
			} else if (value.value == "next_month") {
				title = i18n.t("labels.next_month", "Nästa månad");
			} else if (value.value == "last_week") {
				title = i18n.t("labels.last_week", "Föregående vecka");
			} else if (value.value == "last_month") {
				title = i18n.t("labels.last_month", "Föregående månad");
			} else if (value.value && value.value.datetime) {
				title = value.value.datetime;
			} else if (value.value && value.value.start && value.value.end) {
				title = value.value.start + " -> " + value.value.end;
			} else if (value.value && value.value.start) {
				title = value.value.start;
			}
		} else if (column.type == "rowlink") {
			const linkedRow = this.getRowLink(value.value);
			if (linkedRow) {
				title = linkedRow.title;
			}
		} else if (column.type == "ticket") {
			const ticket = this.getTicket(value.value);
			if (ticket) {
				title = ticket.number + "";
			}
		} else if (column.type == "contact") {
			const contact = this.getContact(value.value);
			if (contact) {
				if (Array.isArray(contact) && contact[0]) {
					title = contact[0].fullname;
				} else {
					title = contact.fullname;
				}
			}
		} else if (column.type == "timetrack") {
			if (value.value == "running") {
				title = i18n.t("labels.running", "Aktiv");
			} else if (value.value == "paused") {
				title = i18n.t("labels.paused", "Pausad");
			}
		} else if (column.type == "status") {
			const curStatus = value.value !== null && column.options && column.options.statuses && column.options.statuses.find((s) => s.id == value.value);
			if (curStatus) {
				title = curStatus.label;
				color = curStatus.color;
			} else {
				color = "#666";
			}
			prefix = (
				<div
					style={{
						backgroundColor: color,
						width: 10,
						marginTop: 5,
						height: 10,
						borderRadius: 5,
					}}
				/>
			);
		} else if (column.type == "dropdown") {
			if (value.value !== null && column.options && column.options.choices && column.options.choices[value.value]) {
				title = column.options.choices[value.value];
			}
		} else if (column.type == "person") {
			if (value.value !== null) {
				const user = this.getUser(value.value);
				if (user) {
					title = user.name;
					prefix = (
						<div className="smallerAvatars">
							<MemberAvatar member={{ user_id: user.id, user }} />
						</div>
					);
				}
			} else {
				prefix = (
					<div className="smallerAvatars">
						<Avatar size="small" customer />
					</div>
				);
			}
		} else if (column.type == "tags") {
			if (value.value !== null) {
				if (board.tags) {
					for (let i = 0; i < board.tags.length; i++) {
						if (board.tags[i].id == value.value) {
							title = "#" + board.tags[i].title;
							color = board.tags[i].color;
						}
					}
				}
			}
		} else if (value.value) {
			title = value.value;
		}

		return { title, color, prefix };
	}

	updateRows(rows, action) {
		const boards = store.getState().boards;
		for (let i = 0; i < rows?.filter((r) => !r.removed).length; i++) {
			let board = null;

			if (rows[i].board_id in boards) {
				board = boards[rows[i].board_id];
			}
			if (rows[i].values) {
				for (let x = 0; x < rows[i].values.length; x++) {
					rows[i].values[x] = this.addConnectedEntities(rows[i].values[x]);
				}
			}
			rows[i] = this.setRowMetaData(rows[i], board);
		}

		if (action == "set") {
			store.dispatch({ type: "SET_BOARD_ROWS", rows });
		} else if (action == "add") {
			store.dispatch({ type: "ADD_BOARD_ROW", row: rows[0] });
		} else {
			store.dispatch({ type: "UPDATE_BOARD_ROW", row: Object.assign({}, rows[0]) });
		}
	}

	addConnectedEntities(value) {
		if ("linked_rows" in value) {
			for (let i = 0; i < value.linked_rows.length; i++) {
				store.dispatch({ type: "UPDATE_BOARD_LINKED_ROW", linked_row: value.linked_rows[i] });
			}
			delete value.linked_rows;
		}
		if ("contacts" in value) {
			for (let i = 0; i < value.contacts.length; i++) {
				store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: value.contacts[i] });
			}
			delete value.contacts;
		}
		if ("tickets" in value) {
			for (let i = 0; i < value.tickets.length; i++) {
				store.dispatch({ type: "UPDATE_BOARD_TICKET", ticket: value.tickets[i] });
			}
			delete value.tickets;
		}
		if ("tags" in value) {
			for (let i = 0; i < value.tags.length; i++) {
				store.dispatch({ type: "ADD_BOARD_TAG", tag: value.tags[i] });
			}
			delete value.tags;
		}
		return value;
	}

	setRowMetaData(row, board) {
		// Calculate meta data for a row, every time it is updated, created etc.
		// Dont include labels, if its for example a status field or something
		const metadata = { persons: [] };
		let values = {};
		if (board && board.columns && board.columns.length) {
			for (let i = 0; i < board.columns.length; i++) {
				const column = board.columns[i];
				const columnHandle = column.id + "";
				values = {
					filterValues: [],
					filterLabels: [],
				};

				const filterValues = this.getFilterValuesFromRow(row, column);
				values.filterValues = filterValues;
				for (let x = 0; x < filterValues.length; x++) {
					if (column.type == "person" && metadata.persons.indexOf(filterValues[x]) <= 0) {
						metadata.persons.push(filterValues[x]);
					}
				}
				for (let s = 0; s < values.filterValues.length; s++) {
					if (values.filterValues[s]) {
						values.filterLabels.push(this.getLabelFromValue({ value: values.filterValues[s] }, board, column));
					} else {
						values.filterLabels.push({ title: values.filterValues[s], color: null, prefix: null });
					}
				}
				metadata[columnHandle] = values;
			}
		}
		row.metadata = metadata;

		return row;
	}

	filterRows(rows, filters) {
		if (rows && !Array.isArray(rows)) {
			rows = Object.values(rows);
		}
		const groupedFilters = [];
		if (!filters || !filters.length || !rows) return rows;
		for (let i = 0; i < filters.length; i++) {
			const filter = Object.assign({}, filters[i]);

			const board = this.getBoard(filter.board_id);
			if (board) {
				for (let x = 0; x < board.columns.length; x++) {
					if (board.columns[x].id + "" == filters[i].column_id + "") {
						filter.column = board.columns[x];
					}
				}
			}
			groupedFilters.push(filter);
		}
		return rows.filter(this.matchFilters.bind(this, groupedFilters));
	}

	matchFilters(filters, row) {
		for (let i = 0; i < filters.length; i++) {
			if (
				(filters[i].column_id == "search" ||
					filters[i].board_id == row.board_id ||
					filters[i].column_id == "person" ||
					filters[i].column_id == "parent_tagged") &&
				!this.matchFilter(row, filters[i], filters[i].column)
			) {
				return false;
			}
		}
		return true;
	}

	filterOutBlockedColumns(board, showOnlyColumnIds) {
		if (!board || !board.columns) return board;
		const roles = this.getMyRoles(board);
		return Object.assign(board, {
			original_columns: board.original_columns || board.columns,
			columns: board.columns
				.filter(
					(c) =>
						!showOnlyColumnIds ||
						!showOnlyColumnIds.length ||
						(showOnlyColumnIds && Array.isArray(showOnlyColumnIds) && showOnlyColumnIds.indexOf(c.id) >= 0)
				)
				.filter(
					(c) =>
						roles.indexOf("ROLE_BOARD_ADMIN") >= 0 ||
						roles.indexOf("ROLE_BOARD_OWNER") >= 0 ||
						!c.permitted_user_ids ||
						!c.permitted_user_ids.length ||
						(c.permitted_user_ids && Array.isArray(c.permitted_user_ids) && c.permitted_user_ids.includes(store.getState().user.id))
				),
		});
	}

	isSupportBoard(board_id) {
		const thisBoard = this.getBoard(board_id);

		if (
			thisBoard &&
			store.getState() &&
			store.getState().workspaces &&
			store.getState().workspaces.find((w) => w.title == "Servicedesk") &&
			store.getState().workspaces.find((w) => w.title == "Servicedesk").id == thisBoard.workspace_id
		)
			return true;
		return false;
	}

	fetchWorkSpaces(params = {}) {
		API.get("/api/workspaces.json", { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return result.data;
				}

				store.dispatch({ type: "SET_WORKSPACES", workspaces: result.data.workspaces || null });
				return result.data;
			})
			.catch((error) => {
				toastr.error(error);
				return { error };
			});
	}

	getDateRecurringLabelAndNextDate(value) {
		const { recurring_interval, datetime } = value || {};
		const haveTimeStamph = datetime && datetime.length > 10;
		const format = "YYYY-MM-DD" + (haveTimeStamph ? " HH:mm" : "");

		switch (recurring_interval) {
			case "daily":
				return { label: i18n.t("cell.recurring.daily", "dagligen"), upcoming_date: moment(moment(datetime).add(1, "days")).format(format) };
			case "weekly":
				return { label: i18n.t("cell.recurring.weekly", "veckovis"), upcoming_date: moment(moment(datetime).add(1, "weeks")).format(format) };
			case "monthly":
				return { label: i18n.t("cell.recurring.monthly", "måndasvis"), upcoming_date: moment(moment(datetime).add(1, "months")).format(format) };
			case "quarterly":
				return { label: i18n.t("cell.recurring.quarterly", "kvartalsvis"), upcoming_date: moment(moment(datetime).add(3, "months")).format(format) };
			case "yearly":
				return { label: i18n.t("cell.recurring.yearly", "årligen"), upcoming_date: moment(moment(datetime).add(1, "years")).format(format) };
			default:
				return {};
		}
	}

	copyObj(object, deep = true) {
		if (object && typeof object === "object" && !Array.isArray(object)) {
			return Object.keys(object).reduce((acc, key) => {
				acc[key] = deep ? this.copyObj(object[key]) : object[key];
				return acc;
			}, {});
		}

		if (object && Array.isArray(object)) {
			return object.map((v) => (deep ? this.copyObj(v) : v));
		}

		return object;
	}

	getColumnIntegrationOptions(board) {
		const column = board.columns.find((c) => c.type === "timeline");
		return column && column.options.integration;
	}

	getColumnIntegrationActive(board) {
		const integrationOptions = this.getColumnIntegrationOptions(board);
		return integrationOptions && !integrationOptions?.error;
	}

	getColumnIntegrationType(board) {
		const integrationOptions = this.getColumnIntegrationOptions(board);
		return integrationOptions?.type;
	}

	getColumnIntegrationIsExpired(board) {
		const column = board.columns.find((c) => c.type === "timeline");
		const integration = column && column.options && column.options.integration;

		if (this.getColumnIntegrationType(board) == "outlook365") {
			return (
				(moment().diff(moment(column && column.updated_at)) >= 10800000 && !this.getColumnIntegrationType(board) === "outlook365") ||
				moment(integration.token_expires_at).isBefore(moment()) ||
				integration.error
			);
		} else if (this.getColumnIntegrationType(board) == "google") {
			return (
				(moment().diff(moment(column && column.updated_at)) >= 10800000 && !this.getColumnIntegrationType(board) === "google") ||
				moment(integration.token_expires_at).isBefore(moment()) ||
				integration.error
			);
		} else {
			return false;
		}
	}

	formatSalesBoardRows(rows, board) {
		if (!rows || !Array.isArray(rows || !board)) return [];

		const getColumnValue = (column) => {
			if (!column) return null;
			return Array.isArray(column.value) ? column.value[0] : (column.value && column.value.datetime) || column.value;
		};

		const columns =
			board &&
			board.columns.reduce(
				(acc, column) =>
					Object.assign(acc, {
						[column.title]: column.id,
					}),
				{}
			);
		const statusColumn = board && board.columns.find((column) => column.id === columns["Affärsstatus"]);
		const firstQuoteStatusIndex =
			statusColumn &&
			statusColumn.options.statuses &&
			statusColumn.options.statuses.findIndex(({ label }) => {
				// eslint-disable-next-line prefer-regex-literals
				const regExp = new RegExp(/offer|quote|quot/, "i");
				return label && regExp.test(label);
			});

		return rows.flatMap((row) => {
			const value = getColumnValue(row.column_values[columns["Omsättning"]]) || 0;
			const tb = getColumnValue(row.column_values[columns.TB]) || 0;
			const support_tb = getColumnValue(row.column_values[columns["Support TB"]]) || 0;

			const personId = getColumnValue(row.column_values[columns.Person]);
			const person1Id = getColumnValue(row.column_values[columns["Delad provision1"]]);
			const person2Id = getColumnValue(row.column_values[columns["Delad provision2"]]);

			const person1Provision = (person1Id && (getColumnValue(row.column_values[columns["Provision1 %"]]) || 0) / 100) || 0;
			const person2Provision = (person2Id && (getColumnValue(row.column_values[columns["Provision2 %"]]) || 0) / 100) || 0;

			const personValue = value * (1 - (person1Provision + person2Provision));

			const person1Value = value * person1Provision;
			const person2Value = value * person2Provision;

			const personTB = tb * (1 - (person1Provision + person2Provision));
			const person1TB = tb * person1Provision;
			const person2TB = tb * person2Provision;

			const personSupportTB = support_tb * (1 - (person1Provision + person2Provision));
			const person1SupportTB = support_tb * person1Provision;
			const person2SupportTB = support_tb * person2Provision;

			const getRowData = (value, tb, support_tb, person) => {
				const completeDate =
					row.column_values[columns["Vunnen/Förlorad"]] &&
					row.column_values[columns["Vunnen/Förlorad"]].value &&
					row.column_values[columns["Vunnen/Förlorad"]].value.datetime;

				const expected_at =
					row.column_values[columns["Förväntad order"]] &&
					row.column_values[columns["Förväntad order"]].value &&
					row.column_values[columns["Förväntad order"]].value.datetime;
				const status = (() => {
					const statusId = row.column_values[columns["Affärsstatus"]] && row.column_values[columns["Affärsstatus"]].value;
					const currentStatus = statusColumn && statusColumn.options.statuses && statusColumn.options.statuses.find((s) => s.id == statusId);
					const currentStatusIndex =
						statusColumn && statusColumn.options.statuses && statusColumn.options.statuses.findIndex((s) => s.id == statusId);

					return Object.assign(currentStatus || {}, {
						index: currentStatusIndex,
					});
				})();

				return {
					key: row.id + "-" + person,
					id: row.id,
					title: row.title,
					board_id: row.board_id,
					group_id: row.group_id,
					person,
					person_group_id: (() => {
						const user = store.getState().users.find((u) => u.id === person);
						return user && user.group_id;
					})(),
					originalPerson: personId,
					value,
					tb,
					support_tb,
					date: completeDate,
					created_at: row.created_at,
					updated_at: row.updated_at,
					expected_at,
					quote_sent_at:
						row.column_values[columns["Offert skickad"]] &&
						row.column_values[columns["Offert skickad"]].value &&
						row.column_values[columns["Offert skickad"]].value.datetime,
					cold: !completeDate && moment().isAfter(moment(expected_at), "day"),
					hot: !completeDate && status?.index >= firstQuoteStatusIndex && moment().isBefore(moment(expected_at), "day"),
					status,
					statusColumnId: columns["Affärsstatus"],
					dateColumnId: columns["Vunnen/Förlorad"],
					archived: row.archived,
				};
			};

			return [
				personId && getRowData(personValue, personTB, personSupportTB, personId),
				person1Id && person1Provision && getRowData(person1Value, person1TB, person1SupportTB, person1Id),
				person2Id && person2Provision && getRowData(person2Value, person2TB, person2SupportTB, person2Id),
			].filter((i) => i);
		});
	}

	/**
	 *
	 * @param {String} string datestring ex. tomorrow, last_30_days
	 */
	dateStringToDate(string) {
		switch (string) {
			case "this_week":
				return [moment().startOf("week").format("YYYY-MM-DD"), moment().endOf("week").format("YYYY-MM-DD")];
			case "last_week":
				return [moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD"), moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD")];
			case "this_month":
				return [moment().startOf("month").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")];
			case "last_month":
				return [
					moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
					moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
				];
			case "last_30_days":
				return [moment().subtract(30, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
			case "last_90_days":
				return [moment().subtract(90, "days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
			case "next_month":
				return [moment().add(1, "month").startOf("month").format("YYYY-MM-DD"), moment().add(1, "month").endOf("month").format("YYYY-MM-DD")];
			case "next_week":
				return [moment().add(1, "week").startOf("week").format("YYYY-MM-DD"), moment().add(1, "week").endOf("week").format("YYYY-MM-DD")];
			default:
				return null;
		}
	}

	/**
	 *
	 * @param {Number} number boardId
	 */
	getGroups(boardId) {
		console.debug("store.getState().board_groups:", store.getState().board_groups);
		return Object.values(store.getState().board_groups).filter((group) => group.board_id == boardId);
	}
}

export default new BoardHelper();
