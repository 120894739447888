import moment from "moment";

const getMutableOrder = (order: FortnoxOrderType | null): Partial<FortnoxOrderType> => {
	const ignoredKeys: string[] = [
		"BasisTaxReduction",
		"TotalToPay",
		"OrderType",
		"WarehouseReady",
		// "WayOfDelivery",
		// "RoundOff",
		// "PriceList",
		// "OutboundDate",
		// "VATIncluded",
		// "Net",
		// "HouseWork",
		// "HouseFreightVATWork",
		// "Freight",

		"DeliveryState",
		"DeliveryZipCode",
		"DeliveryName",
		"DeliveryDate",
		"DeliveryCountry",
		"DeliveryCity",
		"DeliveryAddress1",
		"DeliveryAddress2",
	];

	return Object.entries(order || {}).reduce(
		(acc, [key, value]) => {
			if (ignoredKeys.includes(key)) {
				return acc;
			}

			if (key === "OrderRows") {
				acc[key] = getMutableOrderRows(value as (FortnoxOrderRowType | Partial<FortnoxOrderRowType>)[]);
				return acc;
			}

			acc[key] = value;
			return acc;
		},
		{
			DeliveryDate: order?.DeliveryDate,
			OrderDate: order?.OrderDate || moment().format("YYYY-MM-DD"),
			OrderRows: [],
		} as Partial<FortnoxOrderType>
	);
};
export default getMutableOrder;

const getMutableOrderRows = (orderRows: (FortnoxOrderRowType | Partial<FortnoxOrderRowType>)[]): Partial<FortnoxOrderRowType>[] => {
	const ignoredKeys = ["TotalExcludingVAT"];

	return (
		(orderRows.map((orderRow) =>
			Object.keys(orderRow || {})
				.filter((key) => !ignoredKeys.includes(key))
				.reduce((acc, key) => {
					acc[key] = orderRow?.[key];
					return acc;
				}, {} as Partial<FortnoxOrderRowType>)
		) as Partial<FortnoxOrderRowType>[]) || []
	);
};
