import React, { Component } from "react";
import {
	ButtonGroup,
	DropZone,
	Tooltip,
	Tabs,
	TextStyle,
	Avatar,
	Heading,
	Banner,
	Icon,
	Button,
	Card,
	Spinner,
	TextField,
	Stack,
	Select,
} from "@shopify/polaris";
import moment from "moment";
import nl2br from "react-nl2br";
import { SendMajor, ChatMajor, EditMajor, CircleCancelMajor, AttachmentMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import { store } from "../../../store";
import IntegrationButtons from "../../../components/IntegrationButtons.js";
import TicketStatus from "../../../components/TicketStatus.js";
import Attachment from "../../../components/Attachment.js";
import ContactSelector from "../../../components/ContactSelector.js";
import SideTicketModal from "../../../components/SideTicketModal.js";
import SellerSelector from "../../../components/SellerSelector.js";

import Colors from "../../../Colors.js";
import UserFollowersSelect from "./UserFollowersSelect.js";
import ChildTicketSelector from "./ChildTicketSelector.js";

class RowTicketForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: Object.assign(
				{
					internal: false,
					message: null,
					status: "open",
					files: [],
					contact: props.ticket.contact,
					followers: props.ticket.followers || [],
				},
				this.props.ticket
			),
			tasks: [],
			randomid: "rndticketinp" + Math.round(Math.random() * 1000),
		};
		this.onPasteEvent = this.onPaste.bind(this);
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.form.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.form.files = files;
		this.setState({ form: this.state.form });
	}

	removeFile(index, file) {
		this.state.form.files.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	updateFile(index, file) {
		this.state.form.fifles[index] = file;
		this.setState({ form: this.state.form });
	}

	componentDidMount() {
		if ($("#" + this.state.randomid + " .Polaris-TextField__Input")[0]) {
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].addEventListener("paste", this.onPasteEvent);
		}
		if (store.getState().account.id == 15 || store.getState().account.id == 1437) {
			this.fetchTasks();
		}
		this.state.form.status = this.props.ticket.status == "open" ? "pending" : this.props.ticket.status;

		this.setState({ form: this.state.form }, () => {
			this.scrollToLatestEvent();
		});
		this.getTicketDepartments();
	}

	componentWillUnmount() {
		if ($("#" + this.state.randomid + " .Polaris-TextField__Input")[0]) {
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].removeEventListener("paste", this.onPasteEvent);
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.loading != this.props.loading) {
			this.scrollToLatestEvent();
		}
	}

	scrollToLatestEvent() {
		setTimeout(() => {
			if ($(".ticket-events").length > 0) {
				const wrapper = document.querySelector(".ticket-events-list");
				if (wrapper && wrapper.lastChild) {
					wrapper.lastChild.scrollIntoView({ behavior: "smooth" });
				}
			}
		}, 50);
	}

	getTicketDepartments() {
		this.setState({ loadingDepartments: true });
		API.get("/api/tickets/departments.json")
			.then((result) => {
				if (result.data.error) {
					this.setState({ loadingDepartments: false });
					toastr.error(result.data.error);
					return;
				}
				this.setState({ departments: result.data.departments, loadingDepartments: false });
			})
			.catch((error) => {
				this.setState({ loadingDepartments: false });
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });

		API.post("/api/tickets/" + this.props.ticket.id + "/events.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				this.state.form.message = "";
				this.state.form.files = [];
				this.props.ticket.events.push(result.data.event);
				this.setState({ form: this.state.form, saving: false });
				if (result.data.event && result.data.event.have_email) toastr.success(this.props.t("ticket.responses.answer_sent", "Slet skickades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getStatusMessage(status) {
		if (status == "closed") {
			return this.props.t("ticket.message.statuses.closed", "Avslutade ärendet");
		}
		if (status == "removed") {
			return this.props.t("ticket.message.statuses.removed", "Tog bort ärende");
		}
		if (status == "pending") {
			return this.props.t("ticket.message.statuses.pending", "Markerades som väntande");
		}
		if (status == "spam") {
			return this.props.t("ticket.message.statuses.spam", "Markerade som spam");
		}
		if (status == "open") {
			return this.props.t("ticket.message.statuses.open", "Ärendet öppnades");
		}
	}

	getStatusStatus(status) {
		if (status == "closed") {
			return "success";
		}
		if (status == "removed") {
			return "critical";
		}
		if (status == "spam") {
			return "critical";
		}
		return "default";
	}

	fetchTasks() {
		// Only for simplify (and visolve)
		this.state.tasks = [];
		this.state.loadingTasks = true;

		API.get("/api/tickets/" + this.props.ticket.id + "/simplify_tasks.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (result.data.tasks) this.setState({ tasks: result.data.tasks });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	getExtraContent() {
		// Only for simplify (and visolve)
		if ((store.getState().account.id == 15 || store.getState().account.id == 1437) && this.state.tasks?.length > 0) {
			return (
				<Card title={this.props.t("ticket.terms.task", "Uppdrag")}>
					{this.state.tasks.map((task) => (
						<Card.Section key={task.id}>
							<Button
								plain
								onClick={() => {
									if (store.getState().account.id == 15) {
										window.open("/admin/integrations/17/0.3593856384690013/" + task.id);
									} else {
										window.open("/admin/integrations/134/0.3593856384690013/" + task.id);
									}
								}}
							>
								{task.title}
							</Button>
							<span className="Polaris-Badge Polaris-Badge--statusAttention" style={{ backgroundColor: task.status_color }}>
								<span className="Polaris-Badge__Content">{task.status}</span>
							</span>
						</Card.Section>
					))}
				</Card>
			);
		}
		return null;
	}

	onPaste(e) {
		if (!e.clipboardData) {
			return;
		}
		const items = e.clipboardData.items;

		if (items == undefined) {
			return;
		}

		for (let i = 0; i < items.length; i++) {
			// Skip content if not image
			if (items[i].type.indexOf("image") >= 0) {
				// Retrieve image on clipboard as blob
				const blob = items[i].getAsFile();

				if (blob) {
					e.preventDefault();
					const reader = new FileReader();
					reader.onload = this.addFile.bind(this, blob);
					reader.readAsDataURL(blob);
				}
			}
		}
	}

	openSideTicket(ticket, eventIndex) {
		this.setState({ openSideTicket: ticket, openSideTicketEventIndex: eventIndex });
	}

	postForm(data = {}) {
		API.put(`/api/tickets/${this.props.ticket.id}.json`, data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (result.data.ticket) {
					this.setState({ form: Object.assign(this.state.form, result.data.ticket) });
				}
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	render() {
		let buttonTitle = "";
		if (this.state.form.status == "open") {
			buttonTitle = this.props.t("ticket.actions.open", "Skicka och öppna");
		} else if (this.state.form.status == "pending") {
			buttonTitle = this.props.t("ticket.actions.pending", "Skicka och vänta");
		} else if (this.state.form.status == "closed") {
			buttonTitle = this.props.t("ticket.actions.closed", "Skicka och avsluta");
		} else if (this.state.form.status == "spam") {
			buttonTitle = this.props.t("ticket.actions.spam", "Markera som spam");
		} else if (this.state.form.status == "removed") {
			buttonTitle = this.props.t("ticket.actions.removed", "Ta bort");
		}

		let tabs = [];
		let actionText = "";
		let haveEmail = false;
		if (this.props.ticket.channel == "chat") {
			haveEmail = true;
			if (this.state.form.internal) {
				actionText = this.props.t("ticket.text.internal", "En intern kommentar skapas, inget skickas till kontakt");
			} else {
				actionText = this.props.t("ticket.text.sent_to_chat", "Ditt slet kommer skickas till chatten");
			}
			tabs = [
				{
					id: "visible",
					disabled: true,
					content: (
						<div>
							<Icon source={SendMajor} />
							{this.props.t("ticket.text.message_to_customer", "Meddelande till kund")}
						</div>
					),
					panelID: "visible",
				},
				{
					id: "internal",
					content: (
						<div>
							<Icon source={EditMajor} />
							{this.props.t("ticket.terms.internal_comment", "Intern kommentar")}
						</div>
					),
					panelID: "internal",
				},
			];
		} else {
			if (this.props.ticket.contact && this.props.ticket.contact.email) {
				haveEmail = true;
				if (this.state.form.internal) {
					actionText = this.props.t("ticket.text.internal", "En intern kommentar skapas, inget skickas till kontakt");
				} else {
					actionText = this.props.t("ticket.text.mail_sent_to", "Ett mail kommer att skickas till") + ": " + this.props.ticket.contact.email;
				}
				tabs = [
					{
						id: "visible",
						disabled: true,
						content: (
							<div>
								<Icon source={SendMajor} />
								{this.props.t("ticket.text.message_to_customer", "Meddelande till kund")}
							</div>
						),
						panelID: "visible",
					},
					{
						id: "internal",
						content: (
							<div>
								<Icon source={EditMajor} />
								{this.props.t("ticket.terms.internal_comment", "Intern kommentar")}
							</div>
						),
						panelID: "internal",
					},
				];
			} else {
				actionText = this.props.t("ticket.text.no_email_only_intennal", "Ingen e-post finns, endast intern kommentar kan skapas till denna kontakt");
				tabs = [
					{
						id: "internal",
						content: (
							<div>
								<Icon source={EditMajor} />
								{this.props.t("ticket.terms.internal_comment", "Intern kommentar")}
							</div>
						),
						panelID: "internal",
					},
				];
			}
		}

		return this.props.loading ? (
			<Spinner size="large" />
		) : (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
				}}
			>
				<div className="ticket-events" style={{ overflow: "auto" }}>
					<div className="ticket-header">
						{/* <SelectPerson
						onSelect={(user) => {
							this.state.form.user = user;
							this.setState({ form: this.state.form });
						}}
						person={this.state.form.user}
					/> */}

						<div>
							<label className="Polaris-Label__Text">{this.props.t("ticket.terms.share", "Tilldela")}</label>

							<SellerSelector
								noPadding
								user={this.props.ticket.user}
								onChange={(user) => {
									this.props.ticket.user = user;
									this.postForm({ user });
									this.props.updateTicket(this.props.ticket);
								}}
								compact={this.props.compact}
								clearable
								plain={false}
								emptyCaption={this.props.t("ticket.fields.seller.empty.caption", "Lägg till..")}
								noMe
							/>
						</div>
						<UserFollowersSelect
							onChange={(selected) => {
								const contacts = this.props.ticket.followers && this.props.ticket.followers.filter((f) => f && f.contact);
								const users = store.getState().users.filter((u) => selected.includes(u.id));

								const followers = [].concat(contacts || [], (users && users.map((user) => ({ user }))) || []);
								this.props.ticket.followers = followers;
								this.props.updateTicket(this.props.ticket);
								this.postForm(Object.assign({ followers }));
							}}
							selected={this.props.ticket.followers && this.props.ticket.followers.filter((f) => f && f.user).map((f) => f.user.id)}
						/>
						<Select
							label={this.props.t("ticket.fields.queue.label", "Kö")}
							disabled={this.state.loadingDepartments}
							options={
								this.state.departments
									? this.state.departments.map((dep) => ({
											label: dep.title,
											value: dep.department,
									  }))
									: []
							}
							onChange={(department) => {
								this.props.ticket.department = department;
								this.postForm({ department });
								this.props.updateTicket(this.props.ticket);
							}}
							value={this.props.ticket.department}
						/>
						<ChildTicketSelector
							onSelect={this.openSideTicket.bind(this)}
							ticket={this.props.ticket}
							onUpdate={(ticket) => {
								this.props.ticket.events = ticket.events;
								this.props.updateTicket(this.props.ticket);
							}}
						/>
					</div>
					<IntegrationButtons
						compact
						contact={this.props.ticket.contact}
						onChangeContact={() => {}}
						row={this.props.row}
						board={this.props.board}
						ticket={this.props.ticket}
						onUpdate={(contact) => {
							this.props.ticket.contact = contact;
						}}
						gotoContact={() => {}}
					/>
					{this.getExtraContent()}
					<Card title={this.props.ticket.title}>
						<div style={{ paddingLeft: 20, paddingRight: 20 }}>
							<TextStyle variation="subdued">
								{this.props.t("ticket.text.created_through", "Skapades {{date}} via {{channel}}", {
									date: moment(this.props.ticket.created_at).locale("sv").calendar() || "",
									channel: this.props.ticket.channel || "",
								})}
							</TextStyle>
						</div>
						<div className="ticket-events-list">
							{this.props.ticket &&
								Array.isArray(this.props.ticket.events) &&
								this.props.ticket.events
									.sort((b, a) => (a.id > b.id ? -1 : b.id > a.id ? 1 : 0))
									.map((event, index) => (
										<Card.Section key={event.id}>
											<Stack vertical>
												<Stack wrap={false}>
													<div style={{ width: 40 }}>
														<Avatar
															source={
																event.user
																	? event.user.email
																		? event.user.avatar
																		: null
																	: event.contact && event.contact.email
																	? event.contact.avatar
																	: null
															}
															customer
															name={event.user ? event.user.name : event.contact ? event.contact.name : null}
														/>
													</div>
													<Stack.Item fill>
														<div>
															<Stack>
																<Heading>{event.user ? event.user.name : event.contact ? event.contact.name : null}</Heading>
																<div style={{ textAlign: "right", position: "relative", top: 4 }}>
																	<TextStyle variation="subdued">{moment(event.created_at).locale("sv").calendar()}</TextStyle>
																</div>
															</Stack>
														</div>
														<div style={{ marginTop: 15 }}>
															{event.status ? (
																<Banner status={this.getStatusStatus(event.status)}>{this.getStatusMessage(event.status)}</Banner>
															) : null}
															{event.internal ? null : <div>{nl2br(event.message)}</div>}
														</div>
														{Array.isArray(event.files) && event.files.map((file, key) => <Attachment fontSize="0.85em" key={key} file={file} />)}
														{!event.have_email && !event.internal && this.props.ticket.channel == "email" && (
															<span style={{ color: Colors.red }}>{this.props.t("ticket.statuses.no_mail_sent", "Inget mail har skickats")}</span>
														)}
													</Stack.Item>
												</Stack>
												{event.internal && event.child_ticket ? (
													<div className="internal-conversation">
														<Stack wrap={false}>
															<div style={{ width: 40 }} className="icon-wrapper">
																<Icon source={ChatMajor} />
															</div>
															<Stack.Item fill>
																<div style={{ marginBottom: 10 }}>
																	<strong>
																		{this.props.t("ticket.terms.side_conversation.", "Sidokonversation")} #{event.child_ticket.number}{" "}
																		{event.child_ticket.title}
																	</strong>
																</div>
																<div>
																	<TextStyle variation="subdued">
																		{this.props.t("ticket.terms.with", "med")} {event.child_ticket.contact.fullname} (
																		{event.child_ticket.contact.email})
																	</TextStyle>
																</div>
																<TicketStatus status={event.child_ticket.status} badge />
																<div>
																	<div>
																		<TextStyle variation="subdued">{nl2br(event.child_ticket.last_event.message)}</TextStyle>
																	</div>
																	<div>
																		<Button onClick={this.openSideTicket.bind(this, event.child_ticket, index)}>
																			{this.props.t("ticket.text.show_answer_side_conversation", "Visa / svara på sidokonversation")}
																		</Button>
																	</div>
																</div>
															</Stack.Item>
														</Stack>
													</div>
												) : null}
												{event.internal && !event.child_ticket ? (
													<div className="internal-comment">
														<Stack wrap={false}>
															<div style={{ width: 40 }} className="icon-wrapper">
																<Icon source={EditMajor} />
															</div>
															<Stack.Item fill>
																<div style={{ marginBottom: 10 }}>
																	<strong>{this.props.t("ticket.terms.internal_comment", "Intern kommentar")}</strong>
																</div>
																{nl2br(event.message)}
																<div style={{ marginTop: 15 }}>
																	{event.status ? (
																		<Banner status={this.getStatusStatus(event.status)}>{this.getStatusMessage(event.status)}</Banner>
																	) : null}
																	{event.internal ? null : <div>{nl2br(event.message)}</div>}
																</div>
																{Array.isArray(event.files) && event.files.map((file, key) => <Attachment fontSize="0.85em" key={key} file={file} />)}
															</Stack.Item>
														</Stack>
													</div>
												) : null}
											</Stack>
										</Card.Section>
									))}
						</div>
					</Card>
				</div>
				<div className="ticket">
					<Tabs
						tabs={tabs}
						fitted
						selected={this.state.form.internal ? (!haveEmail ? 0 : 1) : 0}
						onSelect={(tabIndex) => {
							if (this.props.ticket.contact && this.props.ticket.contact.email) {
								this.state.form.internal = !!tabIndex;
							} else {
								this.state.form.internal = true;
							}
							this.setState({ form: this.state.form });
						}}
					>
						<div>
							<div className="textfield-wrapper">
								{!this.state.form.internal && (
									<div className="ticketfield-header">
										<div className="textfield-header_toHeader">
											<span style={{ marginRight: "0.75ch" }}>{this.props.t("ticket.terms.to", "Till")}</span>
											<ContactSelector
												hideIcon
												label=""
												contact={this.props.ticket.contact}
												onChange={(contact) => {
													this.props.ticket.contact = Object.assign({}, contact);

													this.postForm({ contact });
													this.props.updateTicket(this.props.ticket);
												}}
											/>
										</div>
										{(this.state.showCC || (this.props.ticket.followers && !!this.props.ticket.followers.filter((f) => f && f.contact).length)) &&
											!this.state.form.internal && (
												<React.Fragment>
													<span>CC: </span>
													<div className="ticketfield_ccs-wrapper">
														{this.props.ticket.followers &&
															this.props.ticket.followers.filter((f) => f && f.user) &&
															this.props.ticket.followers.map((f, i) => {
																if (!f || !f.contact) return null;
																return (
																	<div className="ticketfield_ccs__contact" key={f.id}>
																		<ContactSelector
																			hideIcon
																			key={f.id || i}
																			contact={f.contact}
																			onChange={(contact) => {
																				this.props.ticket.followers[i] = { contact };
																				this.postForm({ followers: this.props.ticket.followers });
																				this.props.updateTicket(this.props.ticket);
																			}}
																		/>
																		<Button
																			onClick={() => {
																				this.props.ticket.followers.splice(i, 1);
																				this.postForm({ followers: this.props.ticket.followers });
																				this.props.updateTicket(this.props.ticket);
																			}}
																			icon={CircleCancelMajor}
																			destructive
																		/>
																	</div>
																);
															})}
														<ContactSelector
															hideIcon
															key={"new_" + (this.props.ticket.followers && this.props.ticket.followers.filter((f) => f && f.contact).length)}
															personCaption={this.props.t("ticket.actions.add_cc", "Lägg till cc")}
															hideParent
															alwaysNull
															contact={null}
															onChange={(contact) => {
																if (this.props.ticket.followers && this.props.ticket.followers.find((f) => f && f.id == contact.id && f.contact)) {
																	return null;
																}
																if (this.props.ticket.followers) {
																	this.props.ticket.followers.push({ contact });
																} else {
																	this.props.ticket.followers = [{ contact }];
																}

																this.postForm({ followers: this.props.ticket.followers });
																this.props.updateTicket(this.props.ticket);
															}}
														/>
													</div>
												</React.Fragment>
											)}

										<span className="ticketfield-showCC_btn">
											<Button plain onClick={() => this.setState((c) => ({ showCC: !c.showCC }))}>
												CC
											</Button>
										</span>
									</div>
								)}
								<TextFieldWrapper id={this.state.randomid}>
									<TextField
										placeholder={
											this.state.form.internal
												? this.props.t("ticket.fields.message.placeholder_internal", "Skriv intern kommentar här")
												: this.props.t("ticket.fields.message.placeholder", "Skriv ditt meddelande här...")
										}
										value={this.state.form.message}
										multiline
										suffix={
											<AttachButtonWrapper>
												<Tooltip content={this.props.t("ticket.actions.upload", "Ladda upp fil")}>
													<Button
														plain
														size="slim"
														onClick={() => {
															this.setState({ openFileDialog: true });
														}}
													>
														<Icon color="skyDark" source={AttachmentMajor} />
													</Button>
												</Tooltip>
											</AttachButtonWrapper>
										}
										onChange={this.updateForm.bind(this, "message")}
									/>
								</TextFieldWrapper>
								<div style={{ paddingTop: 5 }}>
									{Array.isArray(this.state.form.files) &&
										this.state.form.files.map((file, key) => (
											<Attachment
												editable
												onRemove={this.removeFile.bind(this, key)}
												onChange={this.updateFile.bind(this, key)}
												key={key}
												file={file}
												fontSize="0.85em"
											/>
										))}
								</div>
							</div>
							<div style={{ width: 50, height: 50, display: "none" }}>
								<DropZone
									openFileDialog={this.state.openFileDialog}
									onFileDialogClose={() => {
										this.setState({ openFileDialog: false });
									}}
									onDrop={(files, acceptedFiles, rejectedFiles) => {
										for (let i = 0; i < acceptedFiles.length; i++) {
											const reader = new FileReader();
											reader.onload = this.addFile.bind(this, acceptedFiles[i]);
											reader.readAsDataURL(acceptedFiles[i]);
										}
									}}
								>
									<DropZone.FileUpload
										actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
										actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
									/>
								</DropZone>
							</div>

							<div style={{ paddingTop: 20 }}>
								<Stack wrap={false}>
									<Stack.Item fill>
										<div className="ticket-action">{actionText}</div>
									</Stack.Item>
									{!this.state.updated_ticket ? (
										<ButtonGroup segmented>
											<Button
												outline={this.state.form.status == "pending"}
												primary={this.state.form.status == "closed"}
												destructive={!!(this.state.form.status == "removed" || this.state.form.status == "spam")}
												loading={this.state.saving}
												disabled={
													!this.state.form.message &&
													(!this.state.form.files || !this.state.form.files.length) &&
													this.state.form.status == this.props.ticket.status
												}
												onClick={this.saveForm.bind(this)}
											>
												{buttonTitle}
											</Button>
											{!this.state.saving ? (
												<TicketStatus
													status={this.state.form.status}
													onChange={(status) => {
														this.state.form.status = status;
														this.setState({ form: this.state.form });
													}}
												/>
											) : null}
										</ButtonGroup>
									) : null}
									{this.state.updated_ticket ? (
										<Button onClick={this.updateTicket.bind(this)} primary>
											{this.props.t("ticket.responses.have_updated", "Ärendet har uppdaterats")}!
										</Button>
									) : null}
								</Stack>
							</div>
						</div>
					</Tabs>
				</div>
				<SideTicketModal
					open={this.state.openSideTicket}
					ticket={this.state.openSideTicket}
					onUpdate={(ticket, event) => {
						this.props.ticket.events[this.state.openSideTicketEventIndex].child_ticket = Object.assign(
							{},
							this.props.ticket.events[this.state.openSideTicketEventIndex].child_ticket,
							ticket,
							{ last_event: event }
						);
						this.setState({ openSideTicket: null });
					}}
					onClose={() => {
						this.setState({ openSideTicket: null });
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["row", "ticket", "common"], { withRef: true })(RowTicketForm);

const AttachButtonWrapper = styled.span`
	.Polaris-Icon {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

const TextFieldWrapper = styled.span`
	.Polaris-TextField__Suffix {
		margin-right: 1rem;
	}

	.Polaris-TextField {
		align-items: flex-start;
	}
`;
