import React from "react";
import styled, { css } from "styled-components";
import Badge from "../Badge";
import { ProgressBar, Tooltip } from "@shopify/polaris";
import Colors from "src/js/Colors";
import ProfileAvatar from "../ProfileAvatar";
import moment from "moment";
import { WithTranslation, withTranslation } from "react-i18next";

type ProgressJobProps = {
	progresJob: ProgressJobType;
	history?: any;
} & WithTranslation;

const ProgressJob = React.memo(({ progresJob, history, t }: ProgressJobProps) => {
	const { progress, url, title, user, started_at: startedAt, error, status } = progresJob;

	const getBadgeAttr = () => {
		switch (status) {
			case "finished":
				return { label: t("progress_job.status.finished", "Klar"), color: Colors.green };
			case "in_progress":
				return { label: t("progress_job.status.in_progress", "Pågående"), color: Colors.accent };
			case "failed":
				return { label: t("progress_job.status.failed", "Misslyckades"), color: Colors.red };
			case "pending":
				return { label: t("progress_job.status.pending", "Väntande"), color: Colors.lightblue };
			default:
				return { label: "", color: Colors.grey };
		}
	};

	const { label, color } = getBadgeAttr();

	const getProgressBarColor = () => {
		switch (status) {
			case "finished":
				return Colors.green;
			case "in_progress":
				return Colors.accent;
			case "failed":
				return Colors.red;
			case "pending":
				return Colors.lightblue;
			default:
				return Colors.grey;
		}
	};

	const getBadge = () => {
		switch (status) {
			case "failed":
				return (
					<Tooltip content={error}>
						<Badge color={color}>{label}</Badge>
					</Tooltip>
				);
			case "finished":
			case "in_progress":
			case "pending":
			default:
				return <Badge color={color}>{label}</Badge>;
		}
	};

	const proressBarColor = getProgressBarColor();

	return (
		<Wrapper
			status={status}
			proressBarColor={proressBarColor}
			onClick={
				progresJob.url
					? () => {
							// history.push(url);
							window.open(url, "_blank");
					  }
					: undefined
			}
		>
			<Header>
				{title}
				{label && getBadge()}
			</Header>

			<ProgressBar animated={status === "in_progress"} progress={progress} size="small" />

			<Footer>
				{user ? (
					<UserWrapper>
						<ProfileAvatar size={15} user={user} />
						{user.name}
					</UserWrapper>
				) : (
					"-"
				)}

				{startedAt && <Date>{moment(startedAt).format("YYYY-MM-DD HH:mm")}</Date>}
			</Footer>
		</Wrapper>
	);
});
export default withTranslation(["progress_job"])(ProgressJob);

const Wrapper = styled.div<{ onClick?: any; proressBarColor: string; status?: string }>`
	display: flex;
	gap: 0.5rem;
	flex-direction: column;
	padding: 0.35rem;
	margin-block: 2.5rem;
	width: 350px;
	transition: 0.2s;
	color: var(--textColor);

	${({ status }) =>
		status === "pending" &&
		css`
			opacity: 0.5;
		`}

	&:first-of-type {
		margin-top: 0;
	}

	&:hover {
		scale: 1.02;
	}

	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}

	.Polaris-ProgressBar {
		height: 12px;
		position: relative;

		.Polaris-ProgressBar__Indicator {
			background: ${({ proressBarColor }) => proressBarColor};

			position: relative;
			.Polaris-ProgressBar__Label {
				width: unset !important;
				height: unset !important;
				inset: 0 !important;
				/* overflow: visible !important; */
				clip-path: unset !important;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				line-height: 12px;
				font-weight: bold;
				text-shadow: 0px 0px 2px black;
			}
		}
	}
`;
const Header = styled.div`
	display: flex;
	gap: 1rem;
	align-items: start;
	justify-content: space-between;
`;

const UserWrapper = styled.div`
	&&&& {
		display: flex;
		gap: 1rem;
		align-items: center;
		opacity: 0.7;
		font-size: 1rem;
	}
`;

const Date = styled.p`
	opacity: 0.7;
	font-size: 0.9rem;
`;

const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
