import React, { Component } from "react";
import { Modal, TextField, FormLayout, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import BigSearch from "../../components/big_search.js";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store/index.js";
import Tag from "./Tag.js";

class TagsIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 20,
		};
	}

	onOpenModal(modal, currentTag) {
		this.setState({ [modal]: true, currentTag: Object.assign({}, currentTag), originalTag: Object.assign({}, currentTag) });
	}

	onCloseModal(modal) {
		this.setState({ [modal]: false, currentTag: null, originalTag: null });
	}

	renderItem(tag) {
		return <Tag onClick={this.onOpenModal.bind(this, "tagModal", tag)} tag={tag} />;
	}

	onCreate() {
		this.setState({ loading: true });

		API.post("/api/contacts/tags/new.json", this.state.currentTag)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				this.refresh();
				this.setState({ newTagModal: false, originalTag: null });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	onSave() {
		this.setState({ loading: true });

		API.put("/api/contacts/tags.json", Object.assign(this.state.currentTag, { prevTitle: this.state.originalTag && this.state.originalTag.title }))
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				this.refresh();
				this.setState({ tagModal: false, originalTag: null });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => this.setState({ loading: false }));
	}

	onRemove() {
		this.setState({ removing: true });
		API.post("/api/contacts/remove_tags.json", { tags: [this.state.originalTag] })
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				if (result.data.count) toastr.success(this.props.t("tags.removed", "{{count}} taggar togs bort", { count: result.data.count }));

				this.refresh();
				this.setState({ tagModal: false, removing: false, originalTag: null });
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	updateCurrentTag(field, value) {
		if (!this.state.currentTag) {
			this.state.currentTag = {};
		}
		this.state.currentTag[field] = value;
		this.setState({ currentTag: this.state.currentTag });
	}

	render() {
		const filters = [];
		const isAdmin = store.getState().user.roles.includes("ROLE_ADMIN") || store.getState().user.roles.includes("ROLE_SUPER_ADMIN");
		return (
			<React.Fragment>
				<BigSearch
					small
					history={this.props.history}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title={this.props.t("tags.title", "Taggar")}
					primaryAction={
						isAdmin && {
							content: this.props.t("tags.actions.new", "Ny Tag"),
							onAction: this.onOpenModal.bind(this, "newTagModal", { type: "company" }),
						}
					}
					savedSearchHandle="tags"
					resourceUrl="/api/contacts/tags.json"
					resourceHandle="tags"
					resourceName={{
						singular: this.props.t("tags.singular", "tag"),
						plural: this.props.t("tags.plural", "taggar"),
					}}
					limit={this.state.limit}
					renderItem={this.renderItem.bind(this)}
					filters={filters}
					sortOptions={[]}
					// sortOptions={[
					// 	{ label: "Nyast först", value: "ID_DESC" },
					// 	{ label: "Titel A-Ö", value: "TITLE_DESC" },
					// 	{ label: "Titel Ö-A", value: "TITLE_ASC" },
					// ]}
					promotedBulkActions={[]}
					bulkActions={[]}
					defaultSavedSearches={[
						{
							title: this.props.t("tags.saved_searches.person_tag", "Persontaggar"),
							filters: [
								{
									key: "type",
									value: "person",
								},
							],
							sorting: "ID_DESC",
							search: "",
							id: "default_person_tag",
						},
						{
							title: this.props.t("tags.saved_searches.company_tag", "Företagstaggar"),
							filters: [
								{
									key: "type",
									value: "company",
								},
							],
							search: "",
							id: "default_company_tag",
						},
					]}
				/>
				<Modal
					open={this.state.tagModal}
					onClose={this.onCloseModal.bind(this, "tagModal")}
					title={`${this.props.t("tags.actions.edit", "Redigera tag")} "${this.state.originalTag && this.state.originalTag.title}"`}
					primaryAction={
						isAdmin && {
							content: this.props.t("common.actions.save", "Spara"),
							onAction: this.onSave.bind(this),
							loading: this.state.loading,
							disabled: !this.state.currentTag || !this.state.currentTag.title || this.state.removing,
						}
					}
					secondaryActions={[
						isAdmin && {
							content: this.props.t("common.actions.remove", "Ta bort"),
							onAction: this.onRemove.bind(this),
							destructive: true,
							loading: this.state.removing,
							disabled: this.state.loading,
						},
					]}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								value={this.state.currentTag && this.state.currentTag.title}
								onChange={this.updateCurrentTag.bind(this, "title")}
								placeholder={this.props.t("tags.fields.title.label", "Tagg")}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>

				{/* Create new tag  */}
				<Modal
					open={this.state.newTagModal}
					onClose={this.onCloseModal.bind(this, "newTagModal")}
					title={this.props.t("tags.modals.new.title", "Ny Tag")}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						onAction: this.onCreate.bind(this),
						loading: this.state.loading,
						disabled: !this.state.currentTag || !this.state.currentTag.title,
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								value={this.state.currentTag && this.state.currentTag.title}
								onChange={this.updateCurrentTag.bind(this, "title")}
								placeholder={this.props.t("tags.fields.title2.label", "Tag title")}
							/>
							<Select
								label={this.props.t("tags.fields.type.label", "Typ av tagg")}
								options={[
									{ value: "company", label: this.props.t("common.terms.company", "Företag") },
									{ value: "person", label: this.props.t("common.terms.person", "Kontaktperson") },
								]}
								value={this.state.currentTag && this.state.currentTag.type}
								onChange={this.updateCurrentTag.bind(this, "type")}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</React.Fragment>
		);
	}
}
export default withTranslation(["tags", "common"], { withRef: true })(TagsIndex);
