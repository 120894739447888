import React, { useEffect, useMemo, useState } from "react";
import SemiCircleGraph from "src/js/components/SemiCircleGraph";
import { TargetProgressBox as TargetBox } from "./styles";
import Button from "src/js/components/Button";
import { ArrowDownMinor, ArrowUpMinor, ChevronLeftMinor, ChevronRightMinor, ExternalMinor, MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import { FormLayout, Icon, Tooltip } from "@shopify/polaris";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import numberFormatter from "src/js/Utilities/numberFormatter";
import useQuery from "src/js/hooks/useQuery";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import Popover from "src/js/components/Popover";
import Select from "src/js/components/select/Select";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { UseQueryResult } from "@tanstack/react-query";
import useAnimatedLiveNumber from "src/js/hooks/useAnimatedLiveNumber";

const ANIMATION_DURATION = 1500;

type TargetProgressBoxProps = {
	target: TargetType;
	history: any;
} & WithTranslation;

const TargetProgressBox = ({ target, history, t }: TargetProgressBoxProps) => {
	const [date, setDate] = useState(target?.group_start_at);
	const optionsPopoverRef = React.useRef<any>(null);
	const [selectedConnectionIndex, setSelectedConnectionIndex] = useState<any>(0);
	const [previousStats, setPreviousStats] = useState<any>(null);

	useEffect(() => {
		setSelectedConnectionIndex(0);
		setPreviousStats(null);
	}, [target?.id]);

	const params = useMemo(
		() => ({
			date,
		}),
		[date]
	);

	const fetch = async () => {
		try {
			const res = await API.get(`/api/targets/${target.id}/stats.json`, { params });
			return res.data;
		} catch (e) {
			toastr.error(e);
		}
	};
	const queryKey = ["target_stats", target.id, params];
	const { data, isFetching: isLoading }: UseQueryResult<TargetConnectionStatsResponse> = useQuery({
		queryKey,
		queryFn: fetch,
		initialData: null,
	});

	const stats = useMemo(() => (isLoading ? data || previousStats : data), [data, previousStats, isLoading]);

	const selectedConnection = stats?.target_connections?.[selectedConnectionIndex];

	const handleGoNext = () => {
		setPreviousStats(stats);
		setDate(stats!.next_group_start_at);
	};

	const handleGoPrevious = () => {
		setPreviousStats(stats);
		setDate(stats!.previous_group_start_at);
	};

	const connectionOptions = stats?.target_connections?.map((c: any, index) => ({
		label: c.label,
		value: String(index),
	}));

	const showOptions = selectedConnection && connectionOptions!.length > 1;

	const value = selectedConnection?.value || 0;
	const intendedValue = selectedConnection?.intended_value || 0;
	const intendedValueToDate = selectedConnection?.intended_value_to_date || 0;

	const valueDiff = (selectedConnection && value - intendedValueToDate) || 0;
	const valueDiffInPercentage = (selectedConnection && (valueDiff / intendedValueToDate) * 100) || 0;
	const valueInPercentage = (selectedConnection && (value / intendedValue) * 100) || 0;

	const animatedPercentageValue = useAnimatedLiveNumber(valueInPercentage, ANIMATION_DURATION);
	const animatedDiff = useAnimatedLiveNumber(Number.isFinite(valueDiffInPercentage) ? valueDiffInPercentage : value || 0, ANIMATION_DURATION);

	const percetageTitle = Number.isFinite(valueDiffInPercentage)
		? `${Math.abs(animatedDiff).toFixed(2)}%`
		: `${animatedDiff.toFixed(0)} ${target.suffix}`;

	const badgeProps = (() => {
		if (intendedValueToDate === 0) {
			return {
				color: Colors.yellow,
				title: `+ ${percetageTitle}`,
			};
		}
		if (valueDiffInPercentage >= 0) {
			return {
				icon: ArrowUpMinor,
				color: Colors.green,
				title: `+ ${percetageTitle}`,
			};
		}

		return {
			icon: ArrowDownMinor,
			color: Colors.red,
			title: `- ${percetageTitle}`,
		};
	})();

	return (
		<TargetBox isLoading={isLoading} haveStats={!!stats}>
			<TargetBox.Header>
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<Tooltip content={t("targets.target_comparison_tooltip", "Klicka för att se mer detaljerat vy")}>
						<div
							style={{
								transform: "scaleX(-1)",
							}}
						>
							<Button
								plain
								icon={ExternalMinor}
								onClick={() => {
									history.push(`/admin/dashboard/targets`, {
										target: {
											...target,
											group_end_at: (stats || target)?.group_end_at,
											group_start_at: (stats || target)?.group_start_at,
											next_group_start_at: (stats || target)?.next_group_start_at,
											previous_group_start_at: (stats || target)?.previous_group_start_at,
										},
									});
								}}
							/>
						</div>
					</Tooltip>
					<TargetBox.Title>
						{target.title}
						{connectionOptions?.length > 1 && ` (${selectedConnection?.label})`}
					</TargetBox.Title>
				</div>

				<TargetBox.HeaderRight>
					{selectedConnection && (
						<TargetBox.Comparison color={badgeProps.color}>
							<Tooltip
								content={
									<>
										<p>
											<Trans
												i18nKey="targets.target_comparison"
												values={{
													intendedValue: numberFormatter({
														value: intendedValueToDate,
														minimumFractionDigits: target.number_of_decimals,
														maximumFractionDigits: target.number_of_decimals,
														removeCurrencySymbol: true,
													}),
													suffix: target.suffix,
												}}
												components={{
													b: <b />,
												}}
												defaults="Enligt målsättning borde detta värde vara <b>{{intendedValue}} {{suffix}}</b> vid denna tidpunkt."
											/>
										</p>

										<p>
											<Trans
												i18nKey="targets.target_comparison2"
												values={{
													value: numberFormatter({
														value,
														minimumFractionDigits: target.number_of_decimals,
														maximumFractionDigits: target.number_of_decimals,
														removeCurrencySymbol: true,
													}),
													suffix: target.suffix,
													valueDiff: valueDiff.toFixed(0),
												}}
												components={{
													b: <b />,
												}}
												defaults="Värdet är <b>{{value}} {{suffix}}</b>, vilket motsvarar en diff på <b>{{valueDiff}} {{suffix}}</b>"
											/>
										</p>
									</>
								}
							>
								<Badge color={badgeProps.color}>
									{badgeProps.icon && <Icon source={badgeProps.icon} />}
									{badgeProps.title}
								</Badge>
							</Tooltip>
						</TargetBox.Comparison>
					)}

					{showOptions && (
						<Popover ref={optionsPopoverRef} sectioned activator={<Button plain icon={MobileVerticalDotsMajor} />}>
							<FormLayout>
								<Select
									label={t("targets.select_connection", "Välj koppling") as string}
									options={connectionOptions}
									value={String(selectedConnectionIndex)}
									onChange={(value) => {
										optionsPopoverRef?.current?.onClose?.();
										setSelectedConnectionIndex(parseInt(value));
									}}
								/>
							</FormLayout>
						</Popover>
					)}
				</TargetBox.HeaderRight>
			</TargetBox.Header>
			<SemiCircleGraph
				duration={ANIMATION_DURATION}
				text={`${animatedPercentageValue.toFixed(0)}%`}
				color={valueDiffInPercentage >= 0 ? Colors.green : Colors.red}
				percentage={Math.max(0, Math.min(valueInPercentage, 100))}
				style={{ paddingBottom: 0 }}
				isLoading={isLoading}
			/>
			<TargetBox.Values>
				<span>
					{numberFormatter({
						value,
						minimumFractionDigits: target.number_of_decimals,
						maximumFractionDigits: target.number_of_decimals,
						removeCurrencySymbol: true,
					})}{" "}
					{target.suffix}
				</span>
				<span>{" / "}</span>
				<span>
					{numberFormatter({
						value: intendedValue,
						minimumFractionDigits: target.number_of_decimals,
						maximumFractionDigits: target.number_of_decimals,
						removeCurrencySymbol: true,
					})}{" "}
					{target.suffix}
				</span>
			</TargetBox.Values>
			<TargetBox.Dates>
				<Button disabled={!stats?.previous_group_start_at || isLoading} plain icon={ChevronLeftMinor} onClick={handleGoPrevious} />

				<span>
					{stats?.group_start_at || target.group_start_at} - {stats?.group_end_at || target.group_end_at}
				</span>

				<Button disabled={!stats?.next_group_start_at || isLoading} plain icon={ChevronRightMinor} onClick={handleGoNext} />
			</TargetBox.Dates>
		</TargetBox>
	);
};
export default withTranslation(["targets"])(TargetProgressBox);
