/* eslint-disable max-classes-per-file */
import React, { Component } from "react";
import styled from "styled-components";
import { Button, Collapsible, Spinner } from "@shopify/polaris";
import { CirclePlusMajor, EditMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { H2 } from ".";
import { RocketIcon } from "../../icons";
import { store } from "../../store";

class FAQ extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { search, items, loading, openModal } = this.props;
		if (!items?.length) return null;
		const questions = items.filter(
			({ question, answer }) => !search || question?.toLowerCase?.()?.includes(search) || answer?.toLowerCase?.()?.includes(search)
		);
		return (
			<React.Fragment>
				<H2 style={{ marginTop: "5rem" }}>
					<RocketIcon />
					{this.props.t("help.faq.title", "Vanliga frågor och svar")}
					{loading && (
						<span style={{ marginLeft: "1rem" }}>
							<Spinner size="small" />
						</span>
					)}
					{["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].some((rule) => store.getState().user.roles.includes(rule)) &&
						String(store.getState().account.id) === "24" && (
							<div style={{ marginLeft: "1rem" }}>
								<Button plain icon={CirclePlusMajor} onClick={() => this.props.openModal({ form: { type: "faq" }, tabIndex: 0 })} />
							</div>
						)}
				</H2>

				{questions.map((item, index) => (
					<Item openModal={() => openModal({ form: item })} key={index} question={item.question} answer={item.answer} />
				))}

				{(!questions || !questions.length) && !loading && <p> {this.props.t("help.faq.empty", "Hittade inga frågor och svar..")}</p>}
			</React.Fragment>
		);
	}
}

export default withTranslation(["help", "common"], { withRef: true })(FAQ);

class Item extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}

	render() {
		const { question, answer, openModal } = this.props;
		return (
			<ItemWrapper>
				<Question onClick={() => this.setState((c) => ({ open: !c.open }))}>{question}</Question>

				{["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].some((rule) => store.getState().user.roles.includes(rule)) &&
					String(store.getState().account.id) === "24" && (
						<StyledEditButton>
							<Button plain onClick={openModal} icon={EditMajor} />
						</StyledEditButton>
					)}
				<Collapsible open={this.state.open}>
					<Answer>{answer}</Answer>
				</Collapsible>
			</ItemWrapper>
		);
	}
}

const ItemWrapper = styled.div`
	background-color: var(--main1);
	border-radius: 0.5rem;
	position: relative;

	&:nth-child(odd) {
		background-color: var(--main2);
	}
`;

const Question = styled.h2`
	padding: 2rem;
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 2rem;
	cursor: pointer;
	white-space: pre-wrap;
	flex: 1;
`;
const Answer = styled.p`
	padding: 2rem;
	padding-top: 0;
	font-size: 1em;
	color: var(--textColor);
	white-space: pre-wrap;
`;

const StyledEditButton = styled.div`
	position: absolute;
	right: 20px;
	top: 20px;
`;
