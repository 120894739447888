import React, { Component } from "react";
import { Button } from "@shopify/polaris";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import BoardHelper from "./BoardHelper";

class CellButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick() {
		this.setState({ loading: true });
		API.post(
			"/api/boards/" + this.props.column.board_id + "/rows/" + this.props.row.id + "/columns/" + this.props.column.id + "/click.json",
			{},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				BoardHelper.updateRows([result.data.row], "update");
				toastr.success(this.props.column.options.caption);
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	render() {
		return (
			<div style={{ height: "100%", width: "100%", textAlign: "center", padding: 3, paddingTop: 0, paddingBottom: 0 }} onClick={() => {}}>
				<Button size="slim" loading={this.state.loading} fullWidth onClick={this.onClick.bind(this)}>
					{this.props.column.options.caption}
				</Button>
			</div>
		);
	}
}
export default CellButton;
