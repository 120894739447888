import React, { Component } from "react";
import { Tooltip } from "@shopify/polaris";
import { connect } from "react-redux";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { getContrastColor } from "src/js/Utilities";
import Badge from "src/js/components/Badge";
import BoardHelper from "../BoardHelper.js";
import { store } from "../../../store/index.js";
import RemoveCircleButton from "../../../components/RemoveCircleButton.js";
import InfoView from "../../../components/InfoView.js";
import ProfileAvatar from "../../../components/ProfileAvatar.js";

export const FIRST_COLUMN_WIDTH = "75px";

class RowSnippet2 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getFirstPersonId(board) {
		const personColumn = board && board.columns.find((col) => col.type === "person");
		const personValues = personColumn && this.props.row && this.props.row.column_values[personColumn.id];
		return personValues && personValues.value && personValues.value[0];
	}

	getValueString(columnId) {
		if (!columnId) return null;
		const board = store.getState().boards[this.props.row.board_id];
		const val = this.props.row && this.props.row.column_values[columnId] && this.props.row.column_values[columnId].value;

		const column = board.columns.find((col) => col.id == columnId);
		const { title, color, prefix } = (column && BoardHelper.getLabelFromValue({ value: val }, board, column)) || {};

		if (prefix && column && column.type === "status")
			return (
				<div
					style={{
						display: "flex",
						height: "30px",
						minWidth: "240px",
						borderRadius: "6px",
						backgroundColor: color,
						alignItems: "center",
						justifyContent: "center",
						color: getContrastColor(color),
					}}
				>
					{title}
				</div>
			);

		if (prefix)
			return (
				<div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
					{title}: {prefix}
				</div>
			);

		if (!prefix) return title;
	}

	getLabelAndValue(columnId) {
		// Get the value as a string/element
		if (columnId === "group") {
			return {
				value: this.props.group && this.props.group.title,
				label: this.props.t("row.terms.group", "Grupp"),
				color: this.props.group && this.props.group.color,
			};
		}
		const board = store.getState().boards[this.props.row.board_id];
		const value = this.getValueString(columnId);
		const column = board && board.columns.find((col) => col.id == columnId);

		return { value, label: column && column.title };
	}

	getFirstRow(view, board) {
		const columnId = (view && view.options && view.options.lastRow_columnId) || null;

		if (!columnId) {
			return;
		}
		const info = this.getLabelAndValue(columnId);

		return (
			<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
				<span className="row_snippet__label">{info.label}:</span>
				<Tooltip content={info.value}>
					<span className="row_snippet__value">{info.value}</span>
				</Tooltip>
			</div>
		);
	}

	getSecondRow(view, board) {
		const columnId = (view && view.options && view.options.titleRow_columnId) || (board && board.columns.find((col) => col.type == "contact").id);

		if (!columnId) {
			return;
		}
		const info = this.getLabelAndValue(columnId);

		return (
			<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
				<span className="row_snippet__label">{info.label}:</span>
				<Tooltip content={info.value}>
					<span className="row_snippet__value">{info.value}</span>
				</Tooltip>
			</div>
		);
	}

	getLastRow(view, board) {
		const columnId = (view && view.options && view.options.sumRow_columnId) || null;

		if (!columnId) {
			return;
		}

		const info = this.getLabelAndValue(columnId);

		return (
			<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
				<span className="row_snippet__label">{info.label}:</span>
				<Tooltip content={info.value}>
					<span className="row_snippet__value">{info.value}</span>
				</Tooltip>
			</div>
		);
	}

	render() {
		if (this.props.quickadd) return null;

		const board = store.getState().boards[this.props.row.board_id];
		const view = board && board.views && board.views.find((view) => view.type === "kanban");

		const getRightValue = () => {
			if (view && view.group_by) return this.getLabelAndValue(view.group_by);
			const column = board && board.columns.find((col) => col.type == "status");
			return this.getLabelAndValue(column && column.id);
		};

		const { value: rightValue, label: rightLabel, color } = getRightValue() || {};

		const userId = this.getFirstPersonId(board);

		return (
			<StyledDocumentItem style={this.props.style} className="row_snippet" onClick={() => this.props.onClick(this.props.row, true)}>
				{this.props.onRemove && (
					<RemoveCircleButton
						title={`${this.props.t("row.terms.remove", "Ta bort")} ${(board && board.singular) || this.props.t("row.singular", "rad")}?`}
						onRemove={this.props.onRemove}
					/>
				)}

				<DocumentHeader>
					{userId && (
						<div style={{ width: FIRST_COLUMN_WIDTH, display: "flex", justifyContent: "center", marginLeft: "-2rem" }}>
							<ProfileAvatar size={36} user={store.getState().users.find((user) => user.id == userId)} />
						</div>
					)}

					<HeaderTextWrapper userId={userId}>
						<LeftTextColumn>
							{/* <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}> */}
							<DocumentTitle>
								{this.props.row && this.props.row.title}{" "}
								{this.props.row?.archived && <Badge>{this.props.t("row.terms.archived", "Arkiverad")}</Badge>}
							</DocumentTitle>
							{/* <Caption>{"caption"}</Caption>
							</div> */}

							{board && board.id != store.getState().account.sales_board_id && (
								<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
									<span className="row_snippet__label">{this.props.t("row.terms.group", "Grupp")}:</span>
									<span style={{ color: this.props.group && this.props.group.color }}>{this.props.group && this.props.group.title}</span>
								</div>
							)}

							{this.getSecondRow(view, board)}

							{this.getLastRow(view, board)}

							{this.getFirstRow(view, board)}
						</LeftTextColumn>

						<RightTextColumn>
							<InfoView
								// fullView
								gap="3px"
								details={[
									{
										label: this.props.t("row.terms.created", "Skapad"),
										value: moment(this.props.row && this.props.row.created_at).format("YYYY-MM-DD"),
									},
									{ label: rightLabel, value: rightValue, className: "right_last", color },
								].filter(({ label, value }) => value && label)}
							/>
						</RightTextColumn>
					</HeaderTextWrapper>
				</DocumentHeader>
			</StyledDocumentItem>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	boards: state.boards,
	rows: state.board_rows,
	groups: state.board_groups,
	board_companies: state.board_companies,
	board_contacts: state.board_contacts,
	users: state.users,
});

export default connect(mapStateToProps)(withTranslation(["row", "common"], { withRef: true })(RowSnippet2));

const pulseFilterBrightness = keyframes`
  0% {filter: brightness(1)}
  40% {filter: brightness(1.25)}
  100% {filter: brightness(1)}
`;

const LeftTextColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 3px;
`;
const RightTextColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.info-view {
		column-gap: 1rem;
	}
`;

export const StyledDocumentItem = styled.div`
	padding: 1.5rem 2rem;
	position: relative;
	background-color: var(--main2);
	border-radius: 6px;
	transition: filter 250ms;
	cursor: pointer;
	margin: 0.5rem 0;
	height: calc(100% - 1rem);

	&:hover {
		filter: brightness(1.2);
	}

	&:empty {
		animation: ${pulseFilterBrightness} 2s linear infinite;
		cursor: wait;
	}

	.img_link {
		opacity: 0.7;
		font-size: 12px;
		display: flex;
		gap: 4px;
		align-items: center;
		width: max-content;

		.Polaris-Icon {
			height: 14px;
			width: 14px;
		}
	}

	span.info-view__row__label {
		justify-content: flex-end;
	}
	span.info-view__row__label,
	span.info-view__row__value {
		/* height: 30px; */

		&.right_last {
			line-height: 30px;
		}
	}

	.right_last {
		margin-top: 5px;
	}
`;
const DocumentHeader = styled.div`
	display: flex;
	align-items: center;
`;

const DocumentTitle = styled.p`
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
`;

const HeaderTextWrapper = styled.div`
	width: ${({ userId }) => (userId ? `calc(100% - calc(${FIRST_COLUMN_WIDTH} - 2rem))` : "100%")};
	/* width: ${() => `calc(100% - ${FIRST_COLUMN_WIDTH})`}; */
	display: flex;
	gap: 1rem;
	align-items: flex-start;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
`;
