import { Button, Icon, Scrollable, TextField } from "@shopify/polaris";
import { ChevronLeftMinor, CirclePlusOutlineMinor, MobileCancelMajor, SearchMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../API";
import Colors, { colorToRgba } from "../Colors";
import { DocumentsIcon } from "../icons";
import BoardHelper from "../views/Workspaces/BoardHelper";
import BoardUploadSheet, { StyledDocumentItem } from "../views/Workspaces/components/BoardUploadSheet";
import IconWithBackround from "./IconWithBackround";
import { toastr } from "./toastr";
import { store } from "../store";
import ContractModal from "../views/Workspaces/ContractModal";

// ? TODO: Refactor to hooks (React 18+) and use useQuery from react-query
class DocumentListSheetContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			/* id: props.contact && props.contact.id */
		};

		this.refresh = this.refresh.bind(this);
	}

	refresh() {
		this.fetch(this.props, true);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.contact && nextProps.column && (nextProps.contact.id !== this.props.contact?.id || nextProps.column.id !== this.props.column?.id)) {
			this.fetch(nextProps);
		}
	}

	componentDidMount() {
		window.addEventListener("refreshContactResources", this.refresh);

		const sheet = document.querySelector(".documents").closest(".Polaris-Sheet__Container");
		if (sheet) sheet.classList.add("sheet-big");
		if (!this.props.items && this.props.contact && this.props.column) {
			this.fetch(this.props);
		} else {
			// eslint-disable-next-line react/no-unused-state
			setTimeout(() => this.setState({ rnd: Math.random() }), 1);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("refreshContactResources", this.refresh);
	}

	fetch(props = this.props, silent) {
		this.setState((c) => ({ loading: silent ? c.loading : true }));
		API.get("/api/documents.json", { params: { column_id: props.column.id, contact_id: props.contact.id } })
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				store.dispatch({
					type: "SET_BOARD_ROWS",
					rows: result.data.rows,
				});

				const documentOptions = result.data.documents.map((document) => {
					const file = (JSON.parse(document.value) || []).find((v) => v.id == document.upload_id);
					const row = result.data.rows.find((row) => row.id == document.row_id);
					return Object.assign(document, { file, row, values: JSON.parse(document.value) });
				});

				this.setState({ loading: false, items: documentOptions });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	handleSearch(search) {
		this.setState({ search });
	}

	onChange(row, column, value, file) {
		BoardHelper.onUpdateValue(row, column, { value });
		let maybeRemoved = false;

		for (let i = 0; i < this.state.items.length; i++) {
			if (this.state.items[i].row_id == row.id) {
				this.state.items[i].values = value;
				this.state.items[i].file = value.find((v) => v.id == this.state.items[i].upload_id);

				if (!this.state.items[i].file) {
					maybeRemoved = true;
				}
			}
		}

		if (maybeRemoved && this.props.refresh) {
			this.props.refresh();
		}
		this.setState({ items: this.state.items });
	}

	getItems() {
		return this.props.items || this.state.items || [];
	}

	getContact(row) {
		const contactColumns = this.props.board && this.props.board.columns && this.props.board.columns.filter((column) => column.type === "contact");

		const contactValues =
			contactColumns &&
			contactColumns.reduce((acc, col) => {
				if (row && col.id in row.column_values) {
					for (let i = 0; i < row.column_values[col.id].value.length; i++) {
						const contactId = row.column_values[col.id].value[i];
						if (!acc.includes(contactId)) {
							acc.push(contactId);
						}
					}
				}
				return acc;
			}, []);

		const contact = contactValues.length > 0 && BoardHelper.getContact(contactValues[0]) && BoardHelper.getContact(contactValues[0]);

		return contact;
	}

	render() {
		const { column } = this.props;
		const items = this.getItems();
		const filteredItems =
			items &&
			items.filter(({ file, row } = {}) => {
				if (!file) return false;
				if (!this.state.search) return true;
				const filterRegex = new RegExp(this.state.search, "i");

				const contact = this.getContact(row);
				return filterRegex.test(file.name) || (row && filterRegex.test(row.title)) || (contact && filterRegex.test(contact.fullname));
				// || (file.emailed_to && file.emailed_to.some((email) => email.match(filterRegex)));
			});

		const list = (
			<React.Fragment>
				{this.state.loading && (
					<React.Fragment>
						<StyledDocumentItem style={{ height: "125px" }} />
						<StyledDocumentItem style={{ height: "100px" }} />
						<StyledDocumentItem style={{ height: "125px" }} />
					</React.Fragment>
				)}
				{filteredItems && !!filteredItems.length
					? filteredItems
							.filter(({ file, row } = {}) => {
								if (!file) return false;
								if (!this.state.search) return true;
								const filterRegex = new RegExp(this.state.search, "i");

								const contact = this.getContact(row);
								return filterRegex.test(file.name) || (row && filterRegex.test(row.title)) || (contact && filterRegex.test(contact.fullname));
								// || (file.emailed_to && file.emailed_to.some((email) => email.match(filterRegex)));
							})
							.map(({ file, row, values } = {}, index) => (
								<BoardUploadSheet
									onChange={this.onChange.bind(this, row, this.props.column)}
									file={file}
									values={values}
									key={file ? file.id : index}
									row={row}
									column={this.props.column}
									board={this.props.board}
									showRow
									history={this.props.history}
								/>
							))
					: !this.state.loading && (
							<div className="board-empty_screen">
								<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
								<h2>
									{this.props.t("board.documents.empty.title", "Inga {{title}} hittades", {
										title: ((this.props.column && this.props.column.title) || "").toLowerCase() || this.props.t("board.row.plural", "rader"),
									})}
								</h2>
							</div>
					  )}
			</React.Fragment>
		);

		return (
			<React.Fragment>
				{!this.props.noHeader && (
					<div className="contact_document_header">
						<div className="contact_document_header__btn_wrapper">
							{this.props.onGoBack && (
								<div className="contact_document_header__back_btn">
									<Button plain icon={ChevronLeftMinor} onClick={this.props.onGoBack}>
										{this.props.t("common.actions.back", "Tillbaka")}
									</Button>
								</div>
							)}
						</div>
						<div className="contact_document_header_titles">
							<h3>
								{this.props.column_title || (this.props.column && this.props.column.title)} -{" "}
								<span className="contact_document_header_board_title">({this.props.board.title})</span>
							</h3>
						</div>
					</div>
				)}

				<div className="documents document_sheet">
					<div className="h">
						{!this.props.noSheet && (
							<div className="header">
								<IconWithBackround
									icon={<DocumentsIcon color={Colors.green} />}
									backgroundColor={colorToRgba(Colors.green, 0.3)}
									style={{ marginRight: "1rem" }}
								/>
								<h1 style={{ fontSize: "2rem", fontWeight: 600, marginRight: "2rem" }}>{column && column.title}</h1>
								<span className="closeButton">
									<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
								</span>
							</div>
						)}
						<div style={{ marginBottom: "0.5rem" }}>
							{this.props.children}
							<div className="miniboard_header">
								<span style={{ flex: 1 }}>
									<TextField
										prefix={<Icon source={SearchMajor} />}
										placeholder={
											this.state.loading
												? this.props.t("board.documents.fields.s.placeholder", "Hämtar {{title}}... ", {
														title: (column && column.title) || this.props.t("board.terms.data", "data"),
												  })
												: "Sök.."
										}
										onChange={this.handleSearch.bind(this)}
										value={this.state.search}
									/>
								</span>

								{!this.props.hideCreate && (
									<span className="create_button">
										<Button plain onClick={() => this.setState({ showContractModal: true })} disabled={this.props.contact?.removed}>
											<div className="button_inner">
												<span> {this.props.createButtonTitle || this.props.t("board.documents.actions.upload", "Ladda upp dokument")}</span>
												<Icon source={CirclePlusOutlineMinor} />
											</div>
										</Button>
									</span>
								)}
							</div>
						</div>
					</div>

					{!this.props.noSheet ? (
						<Scrollable
							style={{
								flex: 1,
								paddingTop: "1rem",
								height: window.innerHeight - ((document.querySelector(".h") ? document.querySelector(".h").clientHeight : 0) + 20 + 25),
							}}
						>
							{list}
						</Scrollable>
					) : (
						list
					)}

					<ContractModal
						open={this.state.showContractModal}
						onClose={() => {
							this.setState({ showContractModal: false });
						}}
						onOpen={() => {
							this.setState({ showContractModal: true });
						}}
						onCreate={() => {
							if (this.props.refresh) this.props.refresh();

							const event = new CustomEvent("refreshContactResources");
							window.dispatchEvent(event);
						}}
						column={this.props.column}
						board={this.props.board}
						contact={this.props.contact}
					/>
				</div>
			</React.Fragment>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(DocumentListSheetContent);
