import React, { Component } from "react";
import { Card, Spinner } from "@shopify/polaris";
import moment from "moment";

import { withTranslation } from "react-i18next";
import CircleDiagramHalf from "../../components/CircleDiagramHalf";
import GoalPercentage from "./GoalPercentage";
import { money } from "../../components/Money";

moment.locale("sv");

class CircleDiagramBox extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { loading, size, strokeWidth } = this.props;
		const value = this.props.value || (this.props.goal && this.props.goal.value);
		const percentageTotal = value && (this.props.sum / (this.props.goal && value)) * 100;

		const overPercentage = ((this.props.sum - value) / value) * 100;

		return (
			<div className={`CircleDiagramBox ${this.props.className || ""}`} style={{ position: "relative" }}>
				<Card sectioned>
					<React.Fragment>
						<div className="goal-box-diagram-header">
							{this.props.title ? <p className="goal-box-title">{this.props.title && this.props.title.capitalize()}</p> : <div />}
							{!this.props.loading && !this.props.empty ? (
								<GoalPercentage days={this.props.days} goal={this.props.goal} forecasted_value={value} sum={this.props.sum} value={overPercentage} />
							) : (
								<div />
							)}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								alignItems: "center",
								minHeight: "125px",
								marginTop: this.props.group ? "2rem" : "1rem",
							}}
							className="goal-box-diagram-inner"
						>
							{loading && <Spinner />}
							{!loading && (
								<React.Fragment>
									<CircleDiagramHalf
										text={`${(percentageTotal || 0).toFixed(1)}%`}
										percentage={(this.props.sum / value) * 100}
										color={this.props.color}
										loading={this.props.loading}
										strokeWidth={strokeWidth || 20}
										size={size || 175}
										empty={!this.props.stats || !this.props.stats.length}
									/>
									{this.props.empty ? (
										this.props.t("goals.empty.data", "Ingen data")
									) : (
										<p style={{ fontWeight: 700, fontSize: "13px", lineHeight: "16px", marginTop: "1rem" }}>
											{`${money(this.props.sum, "{{fixed_amount}}")} ${this.props.goal?.suffix || ""} / ${money(value, "{{fixed_amount}}")} ${
												this.props.goal?.suffix || ""
											}`}
										</p>
									)}
									{this.props.children}
								</React.Fragment>
							)}
						</div>
					</React.Fragment>

					{/* <ProgressBar progress={percentageTotal} variant="success" /> */}
				</Card>
			</div>
		);
	}
}

export default withTranslation(["goals", "common"], { withRef: true })(CircleDiagramBox);
