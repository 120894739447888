import React, { Component } from "react";
import { Card, Select, Stack, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class RowLinkConstraint extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	getColumnOptions(types) {
		const arr = [];
		arr.push({ value: "", label: this.props.t("column.actions.choose", "Välj kolumn") });
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf(this.props.board.columns[i].type) >= 0 || types.indexOf("*") >= 0) {
				arr.push({ value: this.props.board.columns[i].id + "", label: this.props.board.columns[i].title });
			}
		}
		return arr;
	}

	getOperatorOptions() {
		const arr = [{ value: "is_set", label: this.props.t("row.terms.is_not_empty", "är inte tom") }];
		// let selectedColumn = this.getSelectedColumn();
		return arr;
	}

	getSelectedColumn() {
		if (!this.props.constraint.column_id) {
			return null;
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].id == this.props.constraint.column_id) {
				return this.props.board.columns[i];
			}
		}
		return null;
	}

	getValues() {
		let type = "text";
		const selectedColumn = this.getSelectedColumn();
		if (selectedColumn) {
			type = "date";
		}
		if (this.props.constraint.operator == "equals") {
			return (
				<TextField
					type={type}
					value={this.props.constraint.value}
					onChange={(v) => {
						this.props.constraint.value = v;
						this.props.onChange(this.props.constraint);
					}}
				/>
			);
		}
	}

	render() {
		const columnOptions = this.getColumnOptions(["*"]);
		const operatorOptions = this.getOperatorOptions();
		return (
			<Card.Section actions={[{ content: this.props.t("common.actions.remove", "Ta bort"), onAction: this.props.onRemove }]}>
				<Stack distribution="fillEvenly">
					<Select
						value={this.props.constraint.column_id}
						onChange={(v) => {
							this.props.constraint.column_id = v;
							this.props.constraint.column_type = this.getSelectedColumn().type;
							this.props.onChange(this.props.constraint);
						}}
						options={columnOptions}
					/>
					<Select
						value={this.props.constraint.operator}
						onChange={(v) => {
							this.props.constraint.operator = v;
							this.props.onChange(this.props.constraint);
						}}
						options={operatorOptions}
					/>
					{this.getValues()}
				</Stack>
			</Card.Section>
		);
	}
}
export default withTranslation(["row", "column", "common"], { withRef: true })(RowLinkConstraint);
