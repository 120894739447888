import React, { Component } from "react";
import { Modal } from "@shopify/polaris";
import axios from "axios";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Trans, withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import Skeleton2Col from "../../components/skeleton_2col.js";
import DashboardHeader from "./DashboardHeader.js";
import DashboardWidget from "./DashboardWidget.js";

import BoardHelper from "./BoardHelper";

const ResponsiveGridLayout = WidthProvider(Responsive);

class DashboardView extends Component {
	constructor(props) {
		super(props);
		const last30day = new Date();
		last30day.setDate(last30day.getDate() - 30);
		const to = new Date();

		this.state = {
			id: props.dashboard?.id || boardState.boardId || props.match.params.id,
			workspace_id: boardState.boardId ? null : props.match.params.workspace_id,
			loading: true,
			saving: false,
			editing: false,
			form: null,
			filters: [],
			groups: [],
			from: last30day,
			to,
			caption: this.props.t("dashboard.view.caption", "Senaste 30 dagarna"),
		};
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequests() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
		BoardHelper.cancelRequests();
	}

	fetchRows(id) {
		if (this.state.form && this.state.form.boards) {
			this.setState({ loadingRows: true });
			BoardHelper.fetchRows(
				this.state.form.boards.map((board) => board.id),
				id,
				() => {
					this.setState({ loadingRows: false });
				}
			);
		}
	}

	fetchGroups(id) {
		this.setState({ loadingRows: true });
		API.get("/api/dashboards/" + id + "/groups.json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params: {},
		})
			.then((result) => {
				store.dispatch({
					type: "SET_BOARD_GROUPS",
					groups: result.data.groups,
				});
				this.setState({ groups: result.data.groups }, this.fetchRows.bind(this, id));
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
			});
	}

	UNSAFE_componentWillReceiveProps(props) {
		const id = props.dashboard?.id || boardState.boardId || props.match.params.id;
		if (id != this.state.id) {
			this.state.id = id;
			this.cancelRequests();
			this.fetchItem(id);
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	componentWillUmount() {
		this.cancelRequests();
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/dashboards/" + id + ".json", {
			cancelToken: new axios.CancelToken(this.createCancelToken.bind(this)),
			params: {},
		})
			.then((result) => {
				this.setState({
					loading: false,
					favorite: result.data.favorite,
					form: Object.assign(result.data.dashboard, {
						favorite: result.data.favorite,
					}),
					editing: !(result.data.dashboard && result.data.dashboard.widget && result.data.dashboard.widget.length),
				});
				store.dispatch({
					type: "SET_BOARDS",
					boards:
						result.data.dashboard && result.data.dashboard.boards && result.data.dashboard.boards.map((b) => BoardHelper.filterOutBlockedColumns(b)),
				});
				this.fetchGroups(result.data.dashboard.id);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
			});
	}

	onCreateWidget(widget) {
		this.state.form.widgets.push(widget);
		this.setState({ form: this.state.form });
	}

	onUpdateWidget(widget) {
		if (this.state.widgets && this.state.widgets.length) {
			const widgetIndex = this.state.widgets.findIndex((wid) => wid.id == widget.id);
			this.state.widgets[widgetIndex] = widget;
			this.setState({ widgets: this.state.widgets });
		}
	}

	onRemoveWidget(widget) {
		if (this.state.form.widgets && this.state.form.widgets.length) {
			this.setState({
				removingWidget: widget,
				removingIndex: this.state.form.widgets.findIndex((wid) => wid.id == widget.id),
			});
		}
	}

	doRemoveWidget() {
		this.state.form.widgets.splice(this.state.removingIndex, 1);
		API.delete("/api/dashboards/" + this.state.form.id + "/widgets/" + this.state.removingWidget.id + ".json", {
			params: {},
		})
			.then((result) => {
				// this.setState({form:result.data.dashboard});
				if (result.data.dashboard) {
					store.dispatch({
						type: "UPDATE_FAVORITE_DASHBOARD",
						dashboard: result.data.dashboard,
					});
					store.dispatch({
						type: "UPDATE_DASHBOARD",
						dashboard: result.data.dashboard,
					});
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
		this.setState({ form: this.state.form, removingWidget: null });
	}

	updateLayout(layout) {
		if (!this.state.editing) {
			return;
		}
		API.post("/api/dashboards/" + this.state.form.id + "/layout.json", { layout }, { params: {} })
			.then((result) => {
				// this.setState({form:result.data.dashboard});
				if (result.data.dashboard) {
					store.dispatch({
						type: "UPDATE_FAVORITE_DASHBOARD",
						dashboard: result.data.dashboard,
					});
					store.dispatch({
						type: "UPDATE_DASHBOARD",
						dashboard: result.data.dashboard,
					});
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	changeFilters(filters) {
		this.setState({ filters });
	}

	getFilteredRows() {
		if (this.state.form && this.state.form.boards) {
			const boardIds = this.state.form.boards.map((board) => board.id);
			return BoardHelper.filterRows(
				Object.values(store.getState().board_rows).filter((row) => boardIds.indexOf(row.board_id) >= 0),
				this.state.filters
			);
		}
	}

	changeRange(from, to, caption) {
		this.setState({ from, to, caption });
	}

	render() {
		if (this.state.loading) {
			return <Skeleton2Col />;
		}

		const filteredRows = this.getFilteredRows();

		const header = (
			<DashboardHeader
				workspace_id={this.state.workspace_id}
				from={this.state.from}
				to={this.state.to}
				caption={this.state.caption}
				onChangeRange={this.changeRange.bind(this)}
				history={this.props.history}
				editing={this.state.editing}
				favorite={this.state.favorite}
				setEditing={(v) => {
					this.setState({ editing: v });
				}}
				onCreateWidget={this.onCreateWidget.bind(this)}
				groups={this.state.groups}
				rows={Object.values(store.getState().board_rows)}
				filteredRows={filteredRows}
				filters={this.state.filters}
				onChangeFilters={this.changeFilters.bind(this)}
				onUpdateDashboard={(dashboard) => {
					this.setState({ form: dashboard });
					if (dashboard && dashboard.id) {
						this.fetchRows(dashboard.id);
					}
				}}
				dashboard={this.state.form}
			/>
		);

		return (
			// overflow: "auto",
			<div className="dashboard-container" style={{ width: "100%", height: "100%" }}>
				{header}
				<div className="fixed-column">
					<div
						style={{
							marginTop:
								this.state.form &&
								this.state.form.widgets &&
								this.state.form.widgets.find((widget) => (widget.y == 0 || widget.y == "0") && widget.type != "number")
									? 40
									: 15,
						}}
					>
						<ResponsiveGridLayout
							className="layout"
							onLayoutChange={this.updateLayout.bind(this)}
							draggableHandle=".dashboard-drag-handle"
							isResizable={this.state.editing}
							breakpoints={{ xxs: 0 }}
							cols={{ xxs: 12 }}
							margin={[25, 50]}
							containerPadding={[0, 0]}
							rowHeight={90}
							verticalCompact
							onBreakpointChange={(newBreakpoint, newCols) => {}}
						>
							{this.state.form &&
								this.state.form.widgets &&
								this.state.form.widgets
									.filter((widget) => !widget.options.full_width)
									.map((widget, index) => (
										<div
											key={widget.id + ""}
											data-grid={{
												x: widget.x,
												y: widget.y,
												w: widget.width,
												h: widget.height,
											}}
											className="dashboard-widget"
										>
											<DashboardWidget
												widget={widget}
												loading={this.state.loadingRows}
												editing={this.state.editing}
												dashboard={this.state.form}
												rows={filteredRows}
												from={this.state.from}
												to={this.state.to}
												caption={this.state.caption}
												groups={this.state.groups}
												filters={this.state.filters}
												onUpdate={this.onUpdateWidget.bind(this, widget)}
												onRemove={this.onRemoveWidget.bind(this, widget)}
											/>
										</div>
									))}
						</ResponsiveGridLayout>
					</div>
				</div>
				{this.state.form &&
					this.state.form.widgets &&
					this.state.form.widgets
						.filter((widget) => widget.options.full_width)
						.map((widget, index) => (
							<div key={widget.id + ""} style={{ marginLeft: 20, marginRight: 20 }} className="dashboard-widget">
								<DashboardWidget
									widget={widget}
									loading={this.state.loadingRows}
									editing={this.state.editing}
									dashboard={this.state.form}
									rows={filteredRows}
									from={this.state.from}
									to={this.state.to}
									caption={this.state.caption}
									groups={this.state.groups}
									filters={this.state.filters}
									onUpdate={this.onUpdateWidget.bind(this, widget)}
									onRemove={this.onRemoveWidget.bind(this, widget)}
								/>
							</div>
						))}
				<Modal
					open={this.state.removingWidget}
					onClose={() => {
						this.setState({ removingWidget: null });
					}}
					title={this.props.t("dashboard.modals.widget.title", "Ta bort widget?")}
					primaryAction={{
						content: this.props.t("common.actions.remove", "Ta bort"),
						destructive: true,
						loading: this.state.saving,
						onAction: this.doRemoveWidget.bind(this),
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.cancel", "Avbryt"),
							onAction: () => {
								this.setState({ removingWidget: null });
							},
						},
					]}
				>
					<Modal.Section>
						<Trans
							i18nKey="dashboard.modals.widget.text"
							default="Är du säker på att du vill ta bort <strong>{{title}}</strong>? Detta går ej att ångra."
							components={{
								strong: <strong />,
							}}
							values={{
								title: this.state.removingWidget?.title || this.props.t("dashboard.terms.widget", "Widget"),
							}}
						/>
						{this.props.t("dashboard.modals.widget.text")}
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["dashboard", "common"], { withRef: true })(DashboardView);
