import React, { Component } from "react";
import { Icon, Spinner, Stack, Button, Popover } from "@shopify/polaris";
import { CirclePlusMinor, CircleCancelMinor, CirclePlusMajor } from "@shopify/polaris-icons";

import { withTranslation } from "react-i18next";
import BoardHelper from "./BoardHelper";
import ContactSelector from "../../components/ContactSelector.js";
import { store } from "../../store";
import MyPopover from "../../components/Popover";

class CellContact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value || [],
			contacts: props.contacts || this.getContacts(props.value || []),
		};
		this.thisRef = React.createRef();
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({
			value: props.value || [],
			contacts: props.contacts || this.getContacts(props.value || []),
		});
	}

	getContacts(contactIds) {
		const arr = [];
		for (let i = 0; i < contactIds.length; i++) {
			arr.push(store.getState().board_contacts[contactIds[i]]);
		}
		return arr;
	}

	onFocus() {
		if (this.props.onFocus) this.props.onFocus();
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = [];
		this.state.contacts = [];
		this.setState({ value: this.state.value, clearContactsOpen: false });
		this.props.onChange({ value: this.state.value });
	}

	toggleClearPopup(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState((c) => ({ clearContactsOpen: !c.clearContactsOpen }));
	}

	updateRow(index, contact) {
		const id = typeof contact == "number" ? contact : contact && contact.id;

		// if (this.state.value.indexOf(id) < 0) {
		this.state.value[index] = id;
		this.setState({ value: this.state.value });
		store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: contact || {} });
		if (contact && contact.parent) {
			store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: contact.parent || {} });
		}

		this.props.onChange({ value: this.state.value.filter((i) => typeof i === "number" && i) });
		// } else {
		// 	this.removeRow(BoardHelper.getContact(id));
		// }
	}

	toggleRow(contact = {}) {
		const id = typeof contact == "number" ? contact : contact.id;
		// if (this.state.value.indexOf(id) < 0) {
		this.state.value.push(id);
		store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact });
		if (contact && contact.parent) {
			store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: contact.parent || {} });
		}
		/// / this.state.contacts.push(contact);
		this.setState({ value: Array.from(new Set(this.state.value)) });
		this.props.onChange({ value: Array.from(new Set(this.state.value)) });
		// } else {
		// 	this.removeRow(BoardHelper.getContact(id));
		// }
		this.setState({ active: false }, this.props.onBlur);
	}

	render() {
		if (this.state.value && !Array.isArray(this.state.value)) {
			this.state.value = [this.state.value];
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						ref={this.thisRef}
						className="cell-contact"
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										if (this.input) this.input.focus();
									}, 100);

									this.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<Stack spacing="none" wrap={false}>
							<div className="board-display-hover" style={{ paddingTop: 4, paddingRight: 3 }}>
								<Icon source={CirclePlusMinor} color="blue" />
							</div>
							<Stack.Item fill>
								<div
									className="overflow-ellipsis contact-selector-outside"
									style={{
										opacity: this.state.value.length < 1 ? 0.8 : null,
									}}
								>
									{
										!this.state.value.length ? (
											<ContactSelector
												hidePerson={this.props.column?.options?.hide_person}
												row={this.props.row}
												contact={null}
												onChange={this.toggleRow.bind(this)}
												fixed={this.props.fixed}
												disabled={this.props.disabled}
											/>
										) : (
											// ) : BoardHelper.getContact(this.state.value[0]) ? (
											<ContactSelector
												hidePerson={this.props.column?.options?.hide_person}
												row={this.props.row}
												contact={this.state.value[0] && BoardHelper.getContact(this.state.value[0])}
												onChange={this.updateRow.bind(this, 0)}
												fixed={this.props.fixed}
												disabled={this.props.disabled}
											/>
										)
										// ) : (
										// 	this.state.value[0]
										// )
									}
									{!!this.state.value.length && !this.props.disabled && (
										<MyPopover
											active={this.state.clearContactsOpen}
											disabled={this.props.disabled}
											activator={
												<div
													onClick={this.toggleClearPopup.bind(this)}
													className="board-display-hover clear-button"
													style={Object.assign(this.state.clearContactsOpen ? { display: "block" } : {})}
												>
													<Icon source={CircleCancelMinor} />
												</div>
											}
											onClose={() => this.setState({ clearContactsOpen: false })}
										>
											<div
												className="popup-confirm"
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
												}}
											>
												<h1>{this.props.t("cell.contact.confirm_question", "Ta bort alla kontakter?")}</h1>
												<div className="popup-confirm_buttons">
													<Button destructive onClick={this.clear.bind(this)} size="slim">
														{this.props.t("common.actions.remove", "Ta bort")}
													</Button>
													<Button
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															this.setState({ clearContactsOpen: false });
														}}
														size="slim"
													>
														{this.props.t("common.actions.cancel", "Avbryt")}
													</Button>
												</div>
											</div>
										</MyPopover>
									)}
								</div>
							</Stack.Item>
							{this.state.value.length > 1 ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{this.state.value.length - 1}
								</div>
							) : null}
						</Stack>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ minWidth: 300, position: "relative" }} ref={this.props.popupRef}>
					{/* <span style={{ position: "absolute", left: 7, top: 10 }}>
							<Icon source={SearchMinor} color="inkLighter" />
						</span>
						<input
							ref={(input) => {
								this.input = input;
							}}
							onKeyDown={(event) => {
								if (event.key === "Enter") {
									this.createContact();
								} else if (event.key === "Escape") {
									this.setState({ active: false, query: "" }, this.props.onBlur);
								}
							}}
							className="bgInput"
							style={{ paddingLeft: 30 }}
							placeholder="Sök kontakt..."
							type="text"
							value={this.state.query}
							onChange={(event) => {
								this.setState({ query: event.target.value }, this.searchContacts.bind(this));
							}}
						/> */}
					{this.state.loadingContacts ? (
						<Popover.Section>
							<Spinner size="small" />
						</Popover.Section>
					) : (
						<div className="ContactsDropDown">
							{this.state.value &&
								this.state.value?.slice(1)?.map((contactId, index) => (
									<div className="Contact" key={contactId}>
										<ContactSelector
											hidePerson={this.props.column?.options?.hide_person}
											key={contactId}
											row={this.props.row}
											contact={contactId && BoardHelper.getContact(contactId)}
											onChange={this.updateRow.bind(this, index)}
											fixed={this.props.fixed}
											disabled={this.props.disabled}
										/>
									</div>
								))}
							<hr style={{ margin: 0, borderColor: "rgb(175,175,175)" }} />
							<div className="Contact">
								<ContactSelector
									hidePerson={this.props.column?.options?.hide_person}
									row={this.props.row}
									icon={CirclePlusMajor}
									contact={(() => {
										const initContact = this.props.initialContact;
										if (!initContact) {
											const val = this.state.value;
											const contact = val && val[0] && BoardHelper.getContact(val[0]);

											if (contact) {
												if (contact.is_company) return contact;
												if (contact.parent) return contact.parent;
											}
											return null;
										}

										if (typeof initContact === "number") {
											const con = BoardHelper.getContact(initContact) || {};
											return con && (con.parent || (con.is_company && con));
										}

										return initContact && (initContact.parent || (initContact.is_company && initContact));
									})()}
									onChange={this.toggleRow.bind(this)}
									fixed={this.props.fixed}
									disabled={this.props.disabled}
								/>
							</div>
						</div>
						// <ActionList
						// 	items={this.state.result.map((contact) => ({
						// 		icon: contact.is_company ? JobsMajor : CustomersMajor,
						// 		content: contact.fullname,
						// 		helpText: contact.email,
						// 		onAction: this.toggleRow.bind(this, contact),
						// 	}))}
						// />
					)}
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellContact);
