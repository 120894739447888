import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { currencyFormatter } from "src/js/Utilities";
import { WithTranslation, withTranslation } from "react-i18next";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import useQuery from "src/js/hooks/useQuery";
import { store } from "src/js/store";
import API from "src/js/API";
import Colors from "src/js/Colors";
import Badge from "src/js/components/Badge";
import FortnoxContactResourceList from "../../FortnoxContactResourceList";
import { getInvoiceFilters } from "../../Utilities";
import moment from "moment";

type FortnoxInvoicesContactListProps = {
	contact?: ContactType;
	history: any;
	setCount?: any;
} & WithTranslation;

const FortnoxInvoicesContactList = ({ contact, history, t, setCount }: FortnoxInvoicesContactListProps) => {
	const queryKey = [contact?.id && `contact_${contact?.id}_fortnox_invoices_count`].filter(Boolean);
	useQuery({
		queryKey,
		queryFn: async () => {
			try {
				return await API.get(`/api/fortnox/contacts/${contact?.id}/invoices.json`, { params: { limit: 1 } }).then(
					(response) => response?.data?.count
				);
			} catch (error) {
				console.error("error:", error);
				return null;
			}
		},
		refetchOnWindowFocus: false,
		enabled: !!(contact?.id && contact?.fortnox_customer_id && store.getState().account.fortnox_integration && setCount),
		initialData: {
			data: [],
			count: 0,
		},
		onSuccess: (data) => {
			if (setCount) setCount(data);
		},
	});

	const renderItem = useCallback(
		(item: FortnoxInvoiceType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/fortnox/invoices/${item.DocumentNumber}`);
					}}
					title={
						<>
							{item.DocumentNumber}
							{item.Cancelled && <Badge color={Colors.red}>{t("fortnox.invoice.statuses.Cancelled", "Avbruten")}</Badge>}
							{item.Sent && <Badge color={Colors.green}>{t("fortnox.invoice.statuses.Sent", "Skickad")}</Badge>}
							{item.Booked && <Badge color={Colors.green}>{t("fortnox.invoice.statuses.Booked1", "Bokförd")}</Badge>}
							{(item.Balance === 0 && !item.Cancelled) && <Badge color={Colors.green}>{t("fortnox.invoice.statuses.balance_0", "Betald")}</Badge>}
							{item.Balance !== 0 && moment(item.DueDate).isBefore(moment()) && (
								<Badge color={Colors.red}>{t("fortnox.invoice.statuses.balance_and_due_date", "Förfallen")}</Badge>
							)}
						</>
					}
					leftColumn={[
						{
							label: t("fortnox.invoices.fields.OCR.label", "OCR"),
							value: item.OCR,
						},
						{
							label: t("fortnox.invoices.fields.YourReference.label", "Er referens"),
							value: item.YourReference,
						},
						{
							label: t("fortnox.invoices.fields.OurReference.label", "Vår referens"),
							value: item.OurReference,
						},
						{
							label: t("fortnox.invoices.fields.YourOrderNumber.label", "Ert ordernummer"),
							value: item.YourOrderNumber,
							enabled: item.YourOrderNumber,
						},
						{
							label: t("fortnox.invoices.fields.CustomerName.label", "Kund"),
							value: `${item.CustomerName} (${item.CustomerNumber})`,
							enabled: !contact,
						},
					]}
					rightColumn={[
						{
							label: t("fortnox.invoices.fields.total_excl_vat.label", "Total (excl. VAT)"),
							value: currencyFormatter({
								value: item.Total - item.TotalVAT,
								currency: item.Currency,
							}),
							enabled: item.InvoiceDate,
						},
						{
							label: t("fortnox.invoices.fields.total_incl_vat.label", "Total (incl. VAT)"),
							value: currencyFormatter({
								value: item.Total,
								currency: item.Currency,
							}),
							enabled: item.InvoiceDate,
						},

						{
							label: t("fortnox.invoices.fields.InvoiceDate.label", "Fakturadatum"),
							value: item.InvoiceDate,
							enabled: item.InvoiceDate,
						},
						{
							label: t("fortnox.invoices.fields.DueDate.label", "Förfallodatum"),
							value: item.DueDate,
							enabled: item.DueDate,
						},
						{
							label: t("fortnox.invoices.fields.FinalPayDate.label", "Sista betalningsdatum"),
							value: item.FinalPayDate,
							enabled: item.FinalPayDate,
						},
					]}
				/>
			);
		},
		[history, t, contact]
	);
	const filters = [
		{
			key: "filter",
			label: t("fortnox.filters.status.label", "Status"),
			type: "select",
			options: getInvoiceFilters(),
			shortcut: true,
		},
		{
			key: "yourreference",
			label: t("fortnox.filters.yourreference.label", "Er referens"),
			type: "textField",
		},
		{
			key: "ourreference",
			label: t("fortnox.filters.ourreference.label", "Fortnox referens"),
			type: "textField",
		},
		{
			key: "yourordernumber",
			label: t("fortnox.filters.yourordernumber.label", "Ert ordernummer"),
			type: "textField",
		},
		{
			key: "ocr",
			label: t("fortnox.filters.ocr.label", "OCR"),
			type: "textField",
		},
		// {
		// 	key: "todate",
		// 	secondKey: "fromdate",
		// 	label: t("fortnox.filters.fromdate_todate.label", "Till och från datum"),
		// 	type: "date",
		// 	range: true,
		// },
		{
			key: "todate",
			label: t("fortnox.filters.todate.label", "Till datum"),
			type: "date",
		},
		{
			key: "fromdate",
			label: t("fortnox.filters.fromdate.label", "Från datum"),
			type: "date",
		},
		{
			key: "lastmodified",
			label: t("fortnox.filters.lastmodified.label", "Senast ändrad"),
			type: "date",
		},
		{
			key: "tofinalpaydate",
			label: t("fortnox.filters.tofinalpaydate.label", "tofinalpaydate"),
			type: "date",
		},
		{
			key: "fromfinalpaydate",
			label: t("fortnox.filters.fromfinalpaydate.label", "fromfinalpaydate"),
			type: "date",
		},
	];
	return (
		<FortnoxContactResourceList
			filters={filters}
			contact={contact}
			resource="Invoices"
			resourceName={{
				singular: "Faktura",
				plural: "Fakturor",
			}}
			renderItem={renderItem}
			additionalAction={
				contact?.id && {
					content: t("fortnox.invoices.actions.create", "Skapa faktura"),
					plain: true,
					icon: CirclePlusMajor,
					// primary: true,
					onAction: () => {
						// eslint-disable-next-line no-console
						history.push(`/admin/fortnox/contacts/${contact.id}/invoices/create`, {
							contact,
						});
					},
				}
			}
		/>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxInvoicesContactList);
