import cloneDeep from "lodash/cloneDeep";

const copyOrder = (order: OrderType): OrderType => {
	const newOrder = cloneDeep(order);

	delete newOrder.id;
	delete newOrder.your_order_number;
	delete newOrder.created_at;
	delete newOrder.created_by_user;
	delete newOrder.updated_at;

	delete newOrder.customer_fields.id;

	delete newOrder.delivery_fields.id;

	newOrder.order_rows =
		newOrder.order_rows?.map((row) => {
			const newRow = { ...row };
			delete newRow.id;

			delete newRow.created_at;
			delete newRow.updated_at;

			newRow.custom_fields = newRow.custom_fields?.map((field) => {
				const newField = { ...field };
				delete newField.id;
				delete newField.created_at;
				delete newField.updated_at;
				return newField;
			});

			return newRow;
		}) || [];

	return newOrder;
};
export default copyOrder;
