import React, { useEffect, useMemo } from "react";
import { io } from "socket.io-client";
import { store } from "./store";
import API from "./API";
import { getCookie, getRefreshToken } from "./Utilities";
import { toastr } from "./components/toastr";
import debugSocket from "./Utilities/debugSocket";

export const SocketContext = React.createContext();
const SocketProvider = ({ children, history }) => {
	// const socket = useMemo(() => io("http://localhost:4551"), []);
	const socket = useMemo(() => io("https://chatsocket.sellfinity.com"), []);
	const contextValues = useMemo(
		() => ({
			socket,
		}),
		[socket]
	);
	window.socket = socket;
	const logoutCheckTimer = React.useRef(null);

	useEffect(() => {
		return () => {
			clearTimeout(logoutCheckTimer.current);
		};
	}, []);

	useEffect(() => {
		const fetchWorkspaces = async () => {
			const workspaces = await API.get("/api/workspaces.json", { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					store.dispatch({
						type: "SET_WORKSPACES",
						workspaces: result.data.workspaces || null,
					});
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				});

			return workspaces;
		};

		socket?.on("connect", () => {
			console.debug("connecting to socket");
			// TEMPORARY while we still use cookie from php
			socket?.emit("auth", {
				user_id: store.getState().user?.id,
				token: store.getState()?.token || getCookie("JWTTOKEN"),
				acc_id: store.getState().account?.id,
			});
			// socket?.emit("auth", { user_id: store.getState().user?.id, token: store.getState().token, acc_id: store.getState().account?.id });
		});

		socket.on("online", ({ onlineUsers }) => {
			debugSocket("online", onlineUsers);
			if (onlineUsers.includes(store.getState().user.id)) {
				store.dispatch({ type: "UPDATE_ME", user: { online: true } });
			}

			store.dispatch({ type: "SET_ONLINE_USERS", userIds: onlineUsers });
		});

		socket.on("offline", ({ user }) => {
			debugSocket("offline", user);
			if (user.id === store.getState().user.id) {
				store.dispatch({
					type: "UPDATE_ME",
					user: { ...(user || {}), online: false },
				});
			}
			store.dispatch({
				type: "UPDATE_USER",
				user: { ...(user || {}), online: false },
			});
		});

		socket.on("join", ({ chat, user }) => {
			debugSocket("join", { chat, user });
			// console.table({ chat, user });
			if (String(user?.id) === String(store.getState().user.id)) {
				store.dispatch({
					type: "ADD_CHAT",
					chat,
				});
			}
		});

		socket.on("leave", ({ user, chat }) => {
			debugSocket("leave", { user, chat });
			if (String(user.id) === String(store.getState().user.id)) {
				store.dispatch({
					type: "REMOVE_CHAT",
					chat: { id: chat.id },
				});
			}
		});

		socket.on("delete_message", ({ message }) => {
			debugSocket("delete_message", { message });
			store.dispatch({
				type: "REMOVE_CHAT_MESSAGE",
				message,
			});
		});

		socket.on("update_member", ({ member }) => {
			debugSocket("update_member", { member });
			store.dispatch({
				type: "UPDATE_MEMBER",
				member,
			});
		});

		socket.on("message", ({ ref, message }) => {
			debugSocket("message", { ref, message });
			const chat = store.getState().chats.find((chat) => String(chat.id) === String(message.chat_id));

			store.dispatch({
				type: "ADD_CHAT_MESSAGE",
				chat_id: message.chat_id,
				message,
				ref,
			});

			if (chat) {
				store.dispatch({
					type: "UPDATE_CHAT",
					chat: { ...(chat || {}), last_message: message },
				});
			}
		});

		socket.on("board.leave", (board) => {
			debugSocket("board.leave", board);
			console.debug("board.leave board:", board);
			store.dispatch({ type: "REMOVE_BOARD", board });
			fetchWorkspaces();
		});

		socket.on("workspace.create", (workspace) => {
			debugSocket("workspace.create", workspace);
			fetchWorkspaces();
		});

		socket.on("workspace.remove", (workspace) => {
			debugSocket("workspace.remove", workspace);
			fetchWorkspaces();
		});

		socket.on("workspace.update", (workspace) => {
			debugSocket("workspace.update", workspace);
			fetchWorkspaces();
		});

		socket.on("refresh_token", (data) => {
			clearTimeout(logoutCheckTimer.current);
			logoutCheckTimer.current = setTimeout(() => {
				const { refresh_token: newRefreshToken, account_id: accountId, user_id: userId, error } = data || {};

				debugSocket("refresh_token data", data);
				debugSocket("getRefreshToken():", getRefreshToken());

				const currentRefreshToken = getRefreshToken();
				debugSocket("currentRefreshToken:", currentRefreshToken);
				debugSocket("newRefreshToken != currentRefreshToken:", newRefreshToken != currentRefreshToken);
				debugSocket("accountId === store.getState().account.id:", accountId === store.getState().account.id);

				if (newRefreshToken != currentRefreshToken && accountId === store.getState().account.id && userId === store.getState().user.id) {
					console.debug("Loggin out (socket)");
					const url = `/admin/authentication/login?error_code=${403}&error_msg=${error}`;

					if (history) {
						history.replace(url);
					} else {
						window.location.assign(encodeURI(url));
					}
				}
			}, 10000);
		});
	}, [socket, history]);

	return <SocketContext.Provider value={contextValues}>{children}</SocketContext.Provider>;
};
export default SocketProvider;
