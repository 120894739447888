/* eslint-disable react/no-unused-state */
import { Card, FormLayout, Icon, Scrollable, Select } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { TableVirtuoso } from "react-virtuoso";
import styled from "styled-components";
import { store } from "src/js/store";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import Sheet from "../../../../components/sheet";
import BoardHelper from "../../BoardHelper";
import ImportCompanyBoardModal from "../../components/ImportCompanyBoardModal";
import Row from "./Row";
import RowCard from "./RowCard";
import SortableTableHeader from "./SortableTableHeader";
import StatsCard from "./StatsCard";

class BoardViewCallList extends Component {
	constructor(props) {
		super(props);
		this.state = { phoneColumnOptions: [], statusColumnOptions: [], contactColumnOptions: [], titleColumnOptions: [] };
	}

	getAndSetColumns(props = this.props) {
		const statusColumn = props.board.columns.find((column) => String(column.id) === String(props.view.options.status_column));
		const phoneColumn = props.board.columns.find((column) => String(column.id) === String(props.view.options.phone_column));
		const contactColumn = props.board.columns.find((column) => String(column.id) === String(props.view.options.contact_column));
		const titleColumn =
			props.board.columns.find((column) => String(column.id) === String(props.view.options.title_column)) || props.view.options.title_column;

		const phoneColumnOptions = props.board.columns.filter((col) => col?.type === "phone").map((col) => ({ label: col.title, value: String(col.id) }));
		const statusColumnOptions = props.board.columns
			.filter((col) => col?.type === "status")
			.map((col) => ({ label: col.title, value: String(col.id) }));
		const contactColumnOptions = [
			...props.board.columns.filter((col) => col?.type === "contact").map((col) => ({ label: col.title, value: String(col.id) })),
		];
		const titleColumnOptions = [
			{ label: "rad titel", value: "row_title" },
			...props.board.columns.filter((col) => col?.type === "text").map((col) => ({ label: col.title, value: String(col.id) })),
		];

		this.setState({
			statusColumn,
			phoneColumn,
			contactColumn,
			titleColumn,
			phoneColumnOptions,
			statusColumnOptions,
			contactColumnOptions,
			titleColumnOptions,
		});

		return {
			statusColumn,
			phoneColumn,
			contactColumn,
			titleColumn,
			phoneColumnOptions,
			statusColumnOptions,
			contactColumnOptions,
			titleColumnOptions,
		};
	}

	componentDidMount() {
		this.getAndSetColumns();
	}

	UNSAFE_componentWillReceiveProps(props) {
		// if (JSON.stringify({ ...this.props.view }) !== JSON.stringify({ ...props.view })) {
		this.getAndSetColumns(props);
	}
	// }

	renderRow(index, row) {
		return (
			<Row
				board={this.props.board}
				row={row}
				phoneColumn={this.state.phoneColumn}
				statusColumn={this.state.statusColumn}
				titleColumn={this.state.titleColumn}
				contactColumn={this.state.contactColumn}
			/>
		);
	}

	onStart(row) {
		this.setState({ selectedRow: row, selectedRowId: row?.id });
	}

	sortRows(a, b) {
		if (this.state.sortBy?.column) {
			if (this.state.sortBy.direction === "DESC") {
				if (this.state.sortBy?.column === "row_title") {
					return a.title?.localeCompare(b.title);
				}

				const value1 = BoardHelper.getColumnValues(a, this.state.sortBy?.column)?.[0]?.value;
				const value2 = BoardHelper.getColumnValues(b, this.state.sortBy?.column)?.[0]?.value;

				if (value1?.localeCompare && value2?.localeCompare) {
					return value1?.localeCompare(value2);
				} else {
					return value1 - value2;
				}
			} else {
				if (this.state.sortBy?.column === "row_title") {
					return b.title?.localeCompare(a.title);
				}

				const value1 = BoardHelper.getColumnValues(b, this.state.sortBy?.column)?.[0]?.value;
				const value2 = BoardHelper.getColumnValues(a, this.state.sortBy?.column)?.[0]?.value;

				if (value1?.localeCompare && value2?.localeCompare) {
					return value1?.localeCompare(value2);
				} else {
					return value1 - value2;
				}
			}
		}

		const aIndex = this.props.view.options?.order?.indexOf?.(a.id);
		const bIndex = this.props.view.options?.order?.indexOf?.(b.id);

		const posa = aIndex < 0 ? 100000 : aIndex;
		const posb = bIndex < 0 ? 100000 : bIndex;

		return posa - posb;
	}

	selectFirstRow() {
		const firstRow = this.props.rows.sort(this.sortRows.bind(this))[0];
		this.setState({ selectedRow: firstRow, selectedRowId: firstRow?.id });
	}

	setSelectorRow(row) {
		this.setState({ selectedRow: row, selectedRowId: row?.id });
	}

	changeSort(column) {
		this.setState((c) => {
			const direction =
				(c.sortBy?.column?.id || c.sortBy?.column) !== (column?.id || column)
					? "DESC"
					: c.sortBy?.direction === "DESC"
					? "ASC"
					: c.sortBy?.direction === "ASC"
					? null
					: "DESC";

			return { sortBy: { column: direction && column, direction, type: direction && column.type } };
		});
	}

	render() {
		const { rows: unsortedRows, board, view } = this.props;
		const statuses = this.state.statusColumn?.options?.statuses || [];

		const filters = [
			{
				board_id: this.props.board.id,
				column_id: this.state.statusColumn?.id,
				key: `${this.state.statusColumn?.id}-${this.props.board.id}`,
				value: statuses?.reduce(
					(acc, status) => {
						if (["#e2445c", "#00c875"].includes(status.color)) return acc;

						return [...acc, status.id];
					},
					[null, undefined]
				),
			},
		];

		const rows = BoardHelper.filterRows([...unsortedRows].sort(this.sortRows.bind(this)), filters);

		const headerElementHeight = document.querySelector(".board-main-header")?.clientHeight || 0;

		const statusAcitvityOptions = store.getState().contact_activities?.map(({ title, id }) => ({
			label: title,
			value: id,
			disabled: this.props.view.options?.status_acivities && Object.values(this.props.view.options?.status_acivities).find((v) => v == id),
		}));

		statusAcitvityOptions.unshift({ label: this.props.t("activities.none", "Ingen aktivitet"), value: null });

		return (
			<div className="board_view_callist">
				{this.props.header}

				<div className="board_view_callist_grid" style={{ height: `calc(100% - ${headerElementHeight + 15 + 32}px)` }}>
					<StatsCard
						rows={unsortedRows}
						setSelectorRow={this.setSelectorRow.bind(this)}
						statusColumn={this.state.statusColumn}
						board={this.props.board}
						view={this.props.view}
					/>

					<div
						className="board_view_callist_list"
						// style={{ height: `calc(100% - ${document.querySelector(".board_view_callist_graph")?.clientHeight}px)` }}
					>
						<Card sectioned>
							<TableVirtuoso
								ref={this.virtuoso}
								totalListHeightChanged={(height) => {
									// if (!myMessage.current) handleScroll();
								}}
								initialTopMostItemIndex={0}
								firstItemIndex={0}
								// atTopThreshold={0}
								style={{ flex: 1 }}
								data={rows}
								increaseViewportBy={{ top: 250, bottom: 250 }}
								computeItemKey={(_, row) => {
									return row.id;
								}}
								fixedHeaderContent={(index, user) => (
									<tr className="">
										<SortableTableHeader
											disabled
											title={this.props.t("board.calllist.labels.name.label", "Namn")}
											column={this.state.titleColumn}
											sortBy={this.state.sortBy}
											changeSort={this.changeSort.bind(this, this.state.titleColumn)}
										/>
										<SortableTableHeader
											disabled
											style={{ display: "flex", justifyContent: "center" }}
											column={this.state.statusColumn}
											sortBy={this.state.sortBy}
											changeSort={this.changeSort.bind(this, this.state.statusColumn)}
										/>

										<th>
											<span style={{ padding: "1rem", justifyContent: "center" }}>{this.state.phoneColumn?.title}</span>
										</th>
									</tr>
								)}
								itemContent={this.renderRow.bind(this)}
								components={{
									TableRow: ({ children, item, style, ...rest }) => {
										return (
											<tr
												key={item.id}
												data-index={rest["data-index"]}
												data-item-index={rest["data-item-index"]}
												data-known-size={rest["data-known-size"]}
												className={String(this.state.selectedRowId) === String(item?.id) ? "selected" : ""}
												onClick={() => {
													this.setState((c) => {
														const selectedRow = item;
														return { selectedRow, selectedRowId: selectedRow?.id, index: selectedRow ? rest["data-index"] : null };
													});
												}}
												style={{ ...style, cursor: "pointer" }}
											>
												{children}
											</tr>
										);
									},
								}}
							/>
						</Card>
					</div>

					<RowCard
						empty={!rows.length}
						filters={this.props.filters}
						onChangeFilters={this.props.onChangeFilters}
						board={board}
						view={view}
						row={BoardHelper.getRow(this.state.selectedRowId)}
						onStart={this.onStart.bind(this, rows[0])}
						onUpdateView={this.props.onUpdateView}
						selectFirstRow={this.selectFirstRow.bind(this)}
						groups={this.props.groups}
						openImportCompanyBoardModal={() => {
							this.setState({ showImportCompanyBoardModal: true });
						}}
						statusColumn={this.state.statusColumn}
					/>
				</div>

				<ImportCompanyBoardModal
					history={this.props.history}
					open={this.state.showImportCompanyBoardModal}
					board={board}
					onComplete={() => {
						this.setState({ showImportCompanyBoardModal: false });
					}}
					onClose={() => {
						this.setState({ showImportCompanyBoardModal: false });
					}}
				/>
				<Sheet
					open={this.props.showSettings}
					title={this.props.t("board.kanban.terms.settings", "Inställningar")}
					onClose={this.props.onCloseSettings}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ padding: "1.6rem", height: "100%" }}>
							<FormLayout>
								<Select
									label={this.props.t("board.kanban.fields.status_column.label", "Status kolumn")}
									options={[{ value: "", label: "-" }].concat(this.state.statusColumnOptions)}
									value={this.props.view.options.status_column || ""}
									onChange={(v) => {
										this.props.view.options.status_column = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								<Select
									label={this.props.t("board.kanban.fields.phone_column.label", "Telefon kolumn")}
									options={[{ value: "", label: "-" }].concat(this.state.phoneColumnOptions)}
									value={this.props.view.options.phone_column || ""}
									onChange={(v) => {
										this.props.view.options.phone_column = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								<Select
									label={this.props.t("board.kanban.fields.contact_column.label", "Kontakt kolumn")}
									options={[{ value: "", label: "-" }].concat(this.state.contactColumnOptions)}
									value={this.props.view.options.contact_column || ""}
									onChange={(v) => {
										this.props.view.options.contact_column = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								<Select
									label={this.props.t("board.kanban.fields.title_column.label", "Titel kolumn")}
									options={[{ value: "", label: "-" }].concat(this.state.titleColumnOptions)}
									value={this.props.view.options.title_column || ""}
									onChange={(v) => {
										this.props.view.options.title_column = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								<StatusAsignWrapper>
									<tbody>
										{statuses
											?.filter((status) => status.color !== "#c4c4c4")
											?.map((status) => {
												return (
													<tr key={status.id}>
														<td>
															<span style={{ display: "flex", gap: "1rem" }}>
																<div style={{ width: 20, height: 20, borderRadius: "50%", backgroundColor: status.color }} />
																{status.label}
															</span>
														</td>
														<td>
															<Icon source={ArrowRightMinor} />
														</td>
														<td>
															<Select
																options={statusAcitvityOptions}
																onChange={(v) => {
																	if (!this.props.view.options.status_acivities) {
																		this.props.view.options.status_acivities = {};
																	}

																	this.props.view.options.status_acivities[status.id] = parseInt(v);
																	this.props.onUpdateView(this.props.view);
																}}
																value={this.props.view.options.status_acivities?.[status.id] || null}
															/>
														</td>
													</tr>
												);
											})}
									</tbody>
								</StatusAsignWrapper>
							</FormLayout>
						</Scrollable>
					</div>
				</Sheet>
			</div>
		);
	}
}
export default withTranslation(["board", "common", "activities"], {
	withRef: true,
})(BoardViewCallList);

const StatusAsignWrapper = styled.table`
	td {
		padding: 0.25 0.5rem;
	}
`;
