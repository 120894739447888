/* eslint-disable react/jsx-props-no-spreading */
import Colors from "src/js/Colors";
import {
	CircleDisabledMajor,
	ClockMajor,
	DiamondAlertMajor,
	EditMajor,
	PaperCheckMajor,
	SendMajor,
	ViewMajor,
	VocabularyMajor,
} from "@shopify/polaris-icons";
import { Checkbox, Label, OptionList } from "@shopify/polaris";
import React from "react";
import TextField from "src/js/components/TextField";
import i18n from "../../../i18n";

export const getStatuses = () => {
	return [
		{ value: "preparation", color: Colors.yellow2, label: i18n.t("scrive.statuses.preparation", "Utkast"), icon: EditMajor },
		{ value: "pending", color: Colors.lightblue, label: i18n.t("scrive.statuses.pending", "Skickat"), icon: SendMajor },
		{ value: "canceled", color: Colors.grey, label: i18n.t("scrive.statuses.canceled", "Avbruten"), icon: CircleDisabledMajor },
		{ value: "timedout", color: Colors.red, label: i18n.t("scrive.statuses.timedout", "Förfallit"), icon: ClockMajor },
		{ value: "rejected", color: Colors.red, label: i18n.t("scrive.statuses.rejected", "Avvisad"), icon: CircleDisabledMajor },
		{ value: "document_error", color: Colors.red, label: i18n.t("scrive.statuses.document_error", "Fel uppståt"), icon: DiamondAlertMajor },
		{ value: "closed", color: Colors.green, label: i18n.t("scrive.statuses.closed", "Signerat"), icon: PaperCheckMajor },
	];
};

export const getPartyStatuses = () => {
	return {
		sign_time: { value: "sign_time", label: i18n.t("crive.party.status.sign_time", "Signerat"), color: Colors.green, icon: PaperCheckMajor }, // LegalMajor
		rejected_time: {
			value: "rejected_time",
			label: i18n.t("crive.party.status.rejected_time", "Avvisad"),
			color: Colors.red,
			icon: CircleDisabledMajor,
		},
		deferred_time: { value: "deferred_time", label: i18n.t("crive.party.status.deferred_time", "Uppskjuten"), color: Colors.red, icon: ClockMajor },
		seen_time: { value: "seen_time", label: i18n.t("crive.party.status.seen_time", "Öppnad och läst"), color: Colors.yellow2, icon: ViewMajor },
		read_invitation_time: {
			value: "read_invitation_time",
			label: i18n.t("crive.party.status.read_invitation_time", "Läst notis"),
			color: Colors.yellow2,
			icon: VocabularyMajor,
		},
	};
};

export const getPartyStatusesWithActive = (party: ScrivePartyType | null | undefined) => {
	const statuses = getPartyStatuses();

	return Object.values(statuses)
		?.map((status) => {
			if (party?.signatory_role === "viewer") {
				if (status.value === "seen_time") return { ...status, active: party?.seen_time };
				if (status.value === "read_invitation_time") return { ...status, active: party?.read_invitation_time };

				return null;
			}

			if (status.value === "sign_time") return { ...status, active: party?.sign_time };
			if (status.value === "rejected_time")
				return { ...status, active: party?.rejected_time, tooltip: party?.rejection_reason && "Avvisad med anledning: " + party.rejection_reason };
			if (status.value === "deferred_time") return { ...status, active: party?.deferred_time };
			if (status.value === "seen_time") return { ...status, active: party?.seen_time };
			if (status.value === "read_invitation_time") return { ...status, active: party?.read_invitation_time };

			return status;
		})
		.filter(Boolean);
};

export const getStatus = (document: ScriveDocumentType | null | undefined) => {
	switch (document?.status) {
		case "preparation":
			return { key: "", color: Colors.yellow2, label: i18n.t("scrive.statuses.preparation", "Utkast") };
		case "pending":
			return { key: "", color: Colors.lightblue, label: i18n.t("scrive.statuses.pending", "Skickat") };
		case "closed":
			return { key: "", color: Colors.green, label: i18n.t("scrive.statuses.closed", "Signerat") };
		case "canceled":
			return { key: "", color: Colors.grey, label: i18n.t("scrive.statuses.canceled", "Avbruten") };
		case "timedout":
			return { key: "", color: Colors.red, label: i18n.t("scrive.statuses.timedout", "Förfallit") };
		case "rejected":
			return { key: "", color: Colors.red, label: i18n.t("scrive.statuses.rejected", "Avvisad") };
		case "document_error":
			return { key: "", color: Colors.red, label: i18n.t("scrive.statuses.document_error", "Fel uppståt") };
		default:
			return {
				color: null,
				label: null,
			};
	}
};

export const getPartyStatus = (party: ScrivePartyType | null | undefined) => {
	const statuses = getPartyStatuses();

	if (party?.sign_time) return statuses.sign_time;
	if (party?.rejected_time) return statuses.rejected_time;
	if (party?.deferred_time) return statuses.deferred_time;
	if (party?.seen_time) return statuses.seen_time;
	if (party?.read_invitation_time) return statuses.read_invitation_time;

	return { color: null, label: null, value: null };
};

export const getHistoryStatus = (document: ScriveDocumentHistoryType | null | undefined) => {
	switch (document?.status) {
		case "initiated":
			return { color: Colors.yellow2, label: i18n.t("scrive.history_statuses.initiated", "Initierade") };
		case "draft":
			return { color: Colors.yellow2, label: i18n.t("scrive.history_statuses.draft", "Utkast") };
		case "cancelled":
			return { color: Colors.red, label: i18n.t("scrive.history_statuses.cancelled", "Avbrött") };
		case "rejected":
			return { color: Colors.red, label: i18n.t("scrive.history_statuses.rejected", "Avvisade") };
		case "timeouted":
			return { color: Colors.orange, label: i18n.t("scrive.history_statuses.timeouted", "Utgick") };
		case "problem":
			return { color: Colors.red, label: i18n.t("scrive.history_statuses.problem", "Fel") };
		case "deliveryproblem":
			return { color: Colors.red, label: i18n.t("scrive.history_statuses.deliveryproblem", "Leveransproblem") };
		case "sent":
			return { color: Colors.yellow2, label: i18n.t("scrive.history_statuses.sent", "Skickat") };
		case "delivered":
			return { color: Colors.lightblue, label: i18n.t("scrive.history_statuses.delivered", "Mottagen") };
		case "read":
			return { color: Colors.blue, label: i18n.t("scrive.history_statuses.read", "Läst") };
		case "opened":
			return { color: Colors.blue, label: i18n.t("scrive.history_statuses.opened", "Öppnade") };
		case "signed":
			return { color: Colors.green, label: i18n.t("scrive.history_statuses.signed", "Signerade") };
		case "prolonged":
			return { color: Colors.orange, label: i18n.t("scrive.history_statuses.prolonged", "Förlängde") };
		case "sealed":
			return { color: Colors.accent, label: i18n.t("scrive.history_statuses.sealed", "Sluten") };
		case "extended":
			return { color: Colors.orange, label: i18n.t("scrive.history_statuses.extended", "Förlängde") };
		case "authenticationview":
			return { color: Colors.blur, label: i18n.t("scrive.history_statuses.authenticationview", "Autentiseringsvy") };
		case "authenticated":
			return { color: Colors.blue, label: i18n.t("scrive.history_statuses.authenticated", "Autentiserade") };
		case "approved":
			return { color: Colors.green, label: i18n.t("scrive.history_statuses.approved", "Godkände") };
		default:
			return {};
	}
};

export const getPDFBlobURL = (document: ScriveDocumentType) => {
	if (!document?.blob) return null;
	const binaryBlob = atob(document?.blob);
	const byteArray = new Uint8Array(binaryBlob.length);
	for (let i = 0; i < binaryBlob.length; i++) {
		byteArray[i] = binaryBlob.charCodeAt(i);
	}

	const blob = new Blob([byteArray], { type: "application/pdf" });
	const url = URL.createObjectURL(blob);

	return url;
};

export const downloadPDF = (document: ScriveDocumentType) => {
	const url = getPDFBlobURL(document);
	if (!url) return;

	const a = window.document.createElement("a");
	a.href = url;
	a.download = document.file.name;
	window.document.body.appendChild(a);

	a.click();
	window.document.body.removeChild(a);
	URL.revokeObjectURL(url);
};

export const defaultPartyFields: ScriveFieldType[] = [
	{
		type: "name",
		value: "",
		order: 1,
		is_obligatory: true,
		should_be_filled_by_sender: false,
	},
	{
		type: "name",
		value: "",
		order: 2,
		is_obligatory: false,
		should_be_filled_by_sender: false,
	},
	{
		type: "personal_number",
		value: "",
		is_obligatory: false,
		should_be_filled_by_sender: false,
		description: i18n.t("scrive.party.fields.personal_number.description", "Kan fyllas i av mottagaren"),
	},
	{
		type: "email",
		value: "",
		is_obligatory: true,
		should_be_filled_by_sender: false,
		editable_by_signatory: false,
	},
	{
		type: "mobile",
		value: "",
		is_obligatory: false,
		should_be_filled_by_sender: false,
		editable_by_signatory: false,
	},
	{
		type: "company",
		value: "",
		is_obligatory: false,
		should_be_filled_by_sender: false,
	},
	{
		type: "company_number",
		value: "",
		is_obligatory: false,
		should_be_filled_by_sender: false,
	},
];

export const getPartyFieldLabel = (field: ScriveFieldType) => {
	if ("name" in field && field?.name) return field.name;

	switch (field.type?.toLowerCase()) {
		case "name":
			field = field as SignatoryFieldName;
			if (field.order === 1) return i18n.t("scrive.party.fields.first_name.label", "Förnamn");
			if (field.order === 2) return i18n.t("scrive.party.fields.last_name.label", "Efternamn");

			return i18n.t("scrive.party.fields.name.label", "Namn");
		case "personal_number":
			return i18n.t("scrive.party.fields.personal_number.label", "Personnummer");
		case "email":
			return i18n.t("scrive.party.fields.email.label", "E-post");
		case "mobile":
			return i18n.t("scrive.party.fields.mobile.label", "Mobil");
		case "company":
			return i18n.t("scrive.party.fields.company.label", "Företag");
		case "company_number":
			return i18n.t("scrive.party.fields.company_number.label", "Organisationsnummer");
		default:
			return field.type;
	}
};

export const getPartyFieldContent = (
	f: ScriveFieldType,
	props = {
		disabled: true,
	}
) => {
	switch (f.type?.toLowerCase()) {
		case "personal_number":
		case "company_number":
		case "company": {
			const field = f as SignatoryFieldStandard;
			return field.value;
		}
		case "email":
		case "mobile": {
			const field = f as SignatoryFieldEmailMobile;
			return field.value;
		}
		case "name": {
			const field = f as SignatoryFieldName;
			return field.value;
		}
		case "signature": {
			const field = f as SignatoryFieldSignature;
			return field.signature;
		}
		case "checkbox": {
			const field = f as SignatoryFieldCheckbox;
			return <Checkbox checked={field.is_checked} label={field.name} {...(props || {})} />;
		}
		case "radiogroup": {
			const field = f as SignatoryFieldRadiogroup;
			return (
				<div
					style={
						props.disabled
							? {
									opacity: 0.75,
									pointerEvents: "none",
							  }
							: {}
					}
				>
					<OptionList
						onChange={() => {}}
						options={field.values?.map((v) => ({ label: v, value: v }))}
						selected={field.selected_value}
						allowMultiple
						{...(props || {})}
					/>
				</div>
			);
		}
		case "text": {
			const field = f as SignatoryFieldCustomText;
			return field.value;
		}
		case "date": {
			const field = f as SignatoryFieldDate;
			return field.value;
		}
		case "sign_date": {
			const field = f as SignatoryFieldSignDate;
			return field.value;
		}
		default:
			return f.type;
	}
};

export const getPartyFieldEditableContent = (
	f: ScriveFieldType,
	props: {
		onChange(any): void;

		[key: string]: any;
	} = {
		onChange: () => {},
	}
) => {
	const { onChange, key, required, ...rest } = props;
	const placeholder = rest.placeholder || getPartyFieldLabel(f);

	switch (f.type?.toLowerCase()) {
		case "personal_number":
		case "company_number":
		case "company": {
			const field = f as SignatoryFieldStandard;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		case "email":
		case "mobile": {
			const field = f as SignatoryFieldEmailMobile;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		case "name": {
			const field = f as SignatoryFieldName;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		case "signature": {
			const field = f as SignatoryFieldSignature;
			return field.signature;
		}
		case "checkbox": {
			const field = f as SignatoryFieldCheckbox;
			return <Checkbox key={key} checked={field.is_checked} label={getPartyFieldLabel(field)} {...(rest || {})} />;
		}
		case "radiogroup": {
			const field = f as SignatoryFieldRadiogroup;
			return (
				<div
					key={key}
					style={
						props.disabled
							? {
									opacity: 0.75,
									pointerEvents: "none",
							  }
							: {}
					}
				>
					<Label requiredIndicator={required} id={field.type}>
						{getPartyFieldLabel(field)}
					</Label>
					<OptionList
						title={getPartyFieldLabel(field)}
						key={key}
						onChange={onChange}
						options={field.values?.map((v) => ({ label: v, value: v }))}
						selected={field.selected_value}
						allowMultiple
						{...(rest || {})}
					/>
				</div>
			);
		}
		case "text": {
			const field = f as SignatoryFieldCustomText;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		case "date": {
			const field = f as SignatoryFieldDate;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		case "sign_date": {
			const field = f as SignatoryFieldSignDate;
			return (
				<TextField
					requiredIndicator={required}
					key={key}
					label={getPartyFieldLabel(field)}
					value={field.value}
					onChange={onChange}
					placeholder={placeholder}
					{...(rest || {})}
				/>
			);
		}
		default:
			return f.type;
	}
};

export const sortFields = (fields: ScriveFieldType[]) => {
	return fields?.sort((a, b) => {
		if (!("order" in a)) return 0;
		if (!("order" in b)) return 0;
		if (a.order && b.order) return a.order - b.order;
		return 0;
	});
};

export const getDocumentLabel = (field: ScriveDocumentTypeKeys) => {
	switch (field) {
		case "id":
			return i18n.t("scrive.fields.id.label", "Scrive ID");
		case "timeout_time":
			return i18n.t("scrive.fields.timeout_time.label", "Sista dag att signera");
		case "days_to_sign":
			return i18n.t("scrive.fields.days_to_sign.label", "Giltig i");
		case "ctime":
			return i18n.t("scrive.fields.ctime.label", "skapades");
		case "mtime":
			return i18n.t("scrive.fields.mtime.label", "Updaterades");
		case "status":
			return i18n.t("scrive.fields.status.label", "Status");
		case "invitation_message":
			return i18n.t("scrive.fields.invitation_message.label", "Meddelande");
		case "sms_invitation_message":
			return i18n.t("scrive.fields.sms_invitation_message.label", "Sms meddelande");

		default:
			return field;
	}
};

export const getDataFromCallbackUrl = (url: string) => {
	const regex = /\/boards\/([^\/]+)\/rows\/([^\/]+)\/values\/([^\/]+)\//;

	const matches = url.match(regex);

	if (matches) {
		const boardID = matches[1];
		const rowID = matches[2];
		const valueID = matches[3];

		return {
			boardID,
			rowID,
			valueID,
		};
	} else {
		console.error("URL does not match the expected pattern");
	}
};

export const getPartyName = (party: ScrivePartyType): string => {
	const sortedFields = sortFields(party.fields);

	const name = sortedFields?.reduce((acc: string, f: ScriveFieldType) => {
		if (f.type === "name" && f.value) acc += `${f.value} `;
		return acc;
	}, "");

	return name;
};
