/* eslint-disable camelcase */
import { createStore, combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import user from "../reducers/user";
import account from "../reducers/account";
import accounts from "../reducers/accounts";
import cities from "../reducers/cities";
import calendars from "../reducers/calendars";
import subscriptions from "../reducers/subscriptions";
import mailboxes from "../reducers/mailboxes";
import dialer_session from "../reducers/dialer_session";
import dialer_services from "../reducers/dialer_services";
import ad_accounts from "../reducers/ad_accounts";
import categories from "../reducers/categories";
import locales from "../reducers/locales";
import goals from "../reducers/goals";
import currencies from "../reducers/currencies";
import countries from "../reducers/countries";
import stages from "../reducers/stages";
import project_statuses from "../reducers/project_statuses";
import project_types from "../reducers/project_types";
import task_types from "../reducers/task_types";
import ticket_categories from "../reducers/ticket_categories";
import contact_metafields from "../reducers/contact_metafields";
import contact_activities from "../reducers/contact_activities";
import project_activities from "../reducers/project_activities";
import project_metafields from "../reducers/project_metafields";
import column_selection from "../reducers/column_selection";
import deal_metafields from "../reducers/deal_metafields";
import sources from "../reducers/sources";
import users from "../reducers/users";
import workspaces from "../reducers/workspaces";
import favorite_boards from "../reducers/favorite_boards";
import favorite_dashboards from "../reducers/favorite_dashboards";
import dashboards from "../reducers/dashboards";
import groups from "../reducers/groups";
import deal_types from "../reducers/deal_types";
import terms from "../reducers/terms";
import fields from "../reducers/fields";
import translation from "../reducers/translation";
import user_group_selection from "../reducers/user_group_selection";
import saved_searches from "../reducers/saved_searches";
import shortcuts from "../reducers/shortcuts";
import currency_rates from "../reducers/currency_rates";
import authentication from "../reducers/authentication";
import boards from "../reducers/boards";
import board_rows from "../reducers/board_rows";
import board_contacts from "../reducers/board_contacts";
import board_tickets from "../reducers/board_tickets";
import board_visitors from "../reducers/board_visitors";
import board_linked_rows from "../reducers/board_linked_rows";
import board_linked_from_rows from "../reducers/board_linked_from_rows";
import board_companies from "../reducers/board_companies";
import board_groups from "../reducers/board_groups";
import blogs from "../reducers/blogs";
import post_categories from "../reducers/post_categories";
import social_media_connections from "../reducers/social_media_connections";
import theme from "../reducers/theme";
import chats from "../reducers/chats";
import chat_messages from "../reducers/chat_messages";
import refresh_token from "../reducers/refresh_token";
import contact_activity_selection from "../reducers/contact_activity_selection";
import token from "../reducers/token";
import navigation from "../reducers/navigation";
import targets from "../reducers/targets";
import getInitialState from "./getInitialState";

const reducers = {
	user,
	blogs,
	post_categories,
	social_media_connections,
	account,
	subscriptions,
	accounts,
	categories,
	dialer_session,
	dialer_services,
	users,
	groups,
	cities,
	calendars,
	workspaces,
	favorite_boards,
	favorite_dashboards,
	dashboards,
	mailboxes,
	ad_accounts,
	contact_activity_selection,
	countries,
	stages,
	terms,
	project_statuses,
	project_types,
	task_types,
	ticket_categories,
	contact_activities,
	contact_metafields,
	project_metafields,
	project_activities,
	column_selection,
	deal_metafields,
	sources,
	deal_types,
	fields,
	translation,
	saved_searches,
	shortcuts,
	user_group_selection,
	locales,
	goals,
	currencies,
	currency_rates,
	authentication,
	boards,
	board_rows,
	board_contacts,
	board_tickets,
	board_visitors,
	board_linked_rows,
	board_linked_from_rows,
	board_companies,
	board_groups,
	theme,
	chats,
	chat_messages,
	refresh_token,
	token,
	navigation,
	targets,
	toastr: toastrReducer, // <- Mounted at toastr.
};
const combinedReducer = combineReducers(reducers);
const initialState = getInitialState();
// eslint-disable-next-line import/prefer-default-export
export const store = createStore(combinedReducer, initialState);
