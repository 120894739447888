import React, { Component } from "react";
import { ActionList, Labelled, Icon, Stack } from "@shopify/polaris";
import { LockMajor } from "@shopify/polaris-icons";
import { store } from "../store";

import Popover from "./Popover";

class ProjectTaskStatusSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			statusPopoverActive: false,
			status: props.status,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ status: props.status });
	}

	toggleStatusPopover() {
		if (
			(this.state.status && this.state.status.type != "verified" && this.state.status.type != "billed") ||
			(this.state.status.type == "verified" && store.getState().user.roles.indexOf("ROLE_PROJECT_VERIFY") >= 0) ||
			(this.state.status.type == "billed" && store.getState().user.roles.indexOf("ROLE_PROJECT_BILLING") >= 0)
		) {
			this.setState({ statusPopoverActive: !this.state.statusPopoverActive });
		}
	}

	changeStatus(value) {
		this.setState({ status: value });
		this.toggleStatusPopover();
		this.props.onChange(value);
	}

	render() {
		const actions = [];
		for (let i = 0; i < store.getState().project_statuses.length; i++) {
			const status = store.getState().project_statuses[i];
			if (
				(status.type != "verified" && status.type != "billed") ||
				(status.type == "verified" && store.getState().user.roles.indexOf("ROLE_PROJECT_VERIFY") >= 0) ||
				(status.type == "billed" && store.getState().user.roles.indexOf("ROLE_PROJECT_BILLING") >= 0)
			) {
				actions.push({
					content: (
						<span className="Polaris-Badge" style={{ color: status.color_hsl.brightness >= 0.8 ? "#000" : "#fff", backgroundColor: status.color }}>
							{status.title}
						</span>
					),
					onAction: this.changeStatus.bind(this, status),
				});
			}
		}
		const button = (
			<span style={this.props.disabled ? null : { cursor: "pointer" }} onClick={this.props.disabled ? null : this.toggleStatusPopover.bind(this)}>
				<Stack spacing="extraTight" wrap={false}>
					<span
						className="Polaris-Badge"
						style={{
							color: (this.state.status ? this.state.status.color_hsl.brightness : 0.5) >= 0.8 ? "#000" : "#fff",
							backgroundColor: this.state.status ? this.state.status.color : "#eee",
						}}
					>
						{this.state.status ? this.state.status.title : "–"}
					</span>
					{(this.state.status && this.state.status.type != "verified" && this.state.status.type != "billed") ||
					(this.state.status.type == "verified" && store.getState().user.roles.indexOf("ROLE_PROJECT_VERIFY") >= 0) ||
					(this.state.status && this.state.status.type == "billed" && store.getState().user.roles.indexOf("ROLE_PROJECT_BILLING") >= 0) ? null : (
						<Icon color="inkLighter" source={LockMajor} />
					)}
				</Stack>
			</span>
		);
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Labelled {...this.props}>
				<Stack>
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Popover active={this.state.statusPopoverActive} activator={button} onClose={this.toggleStatusPopover.bind(this)}>
							<ActionList items={actions} />
						</Popover>
					</span>
				</Stack>
			</Labelled>
		);
	}
}
export default ProjectTaskStatusSelector;
