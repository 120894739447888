import React, { Component } from "react";
import { Icon, Stack } from "@shopify/polaris";
import { CirclePlusMinor, CircleCancelMinor, SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Miniboard from "./components/Miniboard";
import BoardHelper from "./BoardHelper.js";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import TicketModal from "../../components/TicketModal";
import MyPopover from "../../components/Popover";

const WIDTH = 500;

class CellRowLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: "",
			value: props.value || [],
			linked_rows: props.linked_rows || this.getLinkedRows(props.value || []),
			ticket: {},
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({
			value: props.value || [],
			linked_rows: props.linked_rows || this.getLinkedRows(props.value || []),
		});
	}

	getLinkedRows(rowIds) {
		const arr = [];
		for (let i = 0; i < rowIds.length; i++) {
			arr.push(store.getState().board_linked_rows[rowIds[i]]);
		}
		return arr;
	}

	onFocus() {
		this.props.onFocus();
		setTimeout(() => {
			this.input.focus();
		}, 200);
	}

	toggleRow(row) {
		if (this.state.value.indexOf(row.id) < 0) {
			this.state.value.push(row.id);
			this.state.linked_rows.push(row);
			store.dispatch({ type: "UPDATE_BOARD_LINKED_ROW", linked_row: row });
			this.setState({ value: this.state.value, linked_rows: this.state.linked_rows });
			this.props.onChange({ value: this.state.value });
		} else {
			this.removeRow(row);
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	removeRow(row) {
		const index = this.state.value.indexOf(row.id);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			const linkedRows = [];
			for (let i = 0; i < this.state.linked_rows.length; i++) {
				if (this.state.linked_rows[i].id != row.id) {
					linkedRows.push(this.state.linked_rows[i]);
				}
			}
			this.state.linked_rows = linkedRows;
			this.setState({ value: this.state.value, linked_rows: this.state.linked_rows });
			this.props.onChange({ value: this.state.value });
		}
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = [];
		this.state.linked_rows = [];
		this.setState({ value: this.state.value, linked_rows: this.state.linked_rows });
		this.props.onChange({ value: this.state.value });
	}

	getContactId() {
		if (this.props.column.options.contact_column_id && this.props.row) {
			const contactColumnId = this.props.column.options.contact_column_id + "";
			if (contactColumnId in this.props.row.column_values) {
				const contactValue = this.props.row.column_values[contactColumnId];
				if (contactValue && contactValue.value.length > 0) {
					return contactValue.value[0];
				}
			}
		}
		return null;
	}

	handleOpenModal(name, title) {
		if (name === "ticketmodal") {
			if (title) this.state.ticket.title = title;
			this.state.ticket.origin = "ticket";
			this.setState({ [name]: true, ticket: this.state.ticket });
			return;
		}
		this.setState({ [name]: true });
	}

	handleCloseModal(name) {
		this.setState({ [name]: false, ticket: {} });
	}

	onCreateTicket(ticket) {
		if (!ticket) return null;

		API.get(`/api/boards/${ticket.row_info.board_id}/rows/${ticket.row_info.row_id}.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("cell.row_link.responses.created", "Skapade ärende") + " " + ticket.number);
				this.toggleRow(result.data.row);
				this.handleCloseModal("ticketmodal");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		return (
			<React.Fragment>
				<MyPopover
					fixed
					fluidContent
					fullHeight
					active={this.state.active}
					activator={
						<div
							style={{
								height: "100%",
								width: "100%",
								textAlign: "center",
								cursor: "pointer",
								padding: 3,
							}}
							onClick={() => {
								if (!this.state.active) {
									this.setState({ active: true }, this.onFocus.bind(this));
								} else {
									this.setState({ active: false }, this.props.onBlur);
								}
							}}
						>
							<Stack spacing="none" wrap={false}>
								<div className="board-display-hover" style={{ paddingTop: 4, paddingRight: 3 }}>
									<Icon source={CirclePlusMinor} color="blue" />
								</div>
								<Stack.Item fill>
									<div
										className="overflow-ellipsis"
										style={{
											border: "1px solid #ccc",
											opacity: this.state.value.length < 1 ? 0.8 : null,
											// backgroundColor: this.state.value.length < 1 ? null : "#fff",
											borderRadius: "3px 0px 0px 3px",
											borderLeft: "5px solid #006fbb",
											padding: 3,
											position: "relative",
										}}
									>
										{this.state.value.length < 1
											? "–"
											: BoardHelper.getRowLink(this.state.value[0])
											? BoardHelper.getRowLink(this.state.value[0]).title
											: this.state.value[0]}
										{!!this.state.value.length && (
											<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
												<Icon source={CircleCancelMinor} />
											</div>
										)}
									</div>
								</Stack.Item>
								{this.state.value.length > 1 ? (
									<div
										style={{
											backgroundColor: "#303030",
											color: "#dedede",
											height: 28,
											width: 28,
											borderRadius: 15,
											padding: 3,
											paddingLeft: 5,
											paddingRight: 5,
											fontSize: 12,
										}}
									>
										+{this.state.value.length - 1}
									</div>
								) : null}
							</Stack>
						</div>
					}
					onClose={() => {
						this.setState({ active: false }, this.props.onBlur);
					}}
				>
					<div>
						{/* <Heading>Välj rader</Heading> */}
						<div style={{ width: WIDTH, position: "relative" }}>
							<span style={{ position: "absolute", left: 7, top: 10, zIndex: 2 }}>
								<Icon source={SearchMinor} color="inkLighter" />
							</span>
							<input
								ref={(input) => {
									this.input = input;
								}}
								className="bgInput"
								style={{ paddingLeft: 30, position: "fixed", zIndex: 1, boxShadow: "0px 3px 10px #1d1b1b4f", width: WIDTH }}
								placeholder={this.props.t("cell.row_link.placeholder", "Sök...")}
								type="text"
								value={this.state.query}
								onChange={(event) => {
									this.setState({ query: event.target.value });
								}}
							/>
							<div style={{ padding: "40px 15px 10px 15px" }}>
								<Miniboard
									query={this.state.query}
									contact_id={this.getContactId()}
									board_id={this.props.column.connected_board_id}
									selection={this.state.value}
									row={this.props.row}
									column={this.props.column}
									onClickRow={(row, onTitle) => {
										this.toggleRow(row);
									}}
									onCreateRow={(row) => {
										this.toggleRow(row);
									}}
									height={350}
									showSelecteFirst
									handleOpenModal={this.handleOpenModal.bind(this)}
								/>
							</div>
						</div>
					</div>
				</MyPopover>
				<TicketModal
					open={this.state.ticketmodal}
					onCreate={(ticket) => this.onCreateTicket(ticket)}
					ticket={this.state.ticket || {}}
					// contact={this.state.form.contact}
					onMessage={() => {
						this.handleCloseModal("ticketmodal");
					}}
					// user
					onClose={this.handleCloseModal.bind(this, "ticketmodal")}
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellRowLink);
