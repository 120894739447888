import { formatRowToWrite } from "./formatRows";

const getRowStats = (r) => {
	const row = formatRowToWrite(r);

	const aPrice = parseFloat(row?.Price || 0);
	const aQuantity = parseFloat(row?.Quantity || row.DeliveredQuantity || row.OrderedQuantity || 0);
	const aDiscount = parseFloat(row?.Discount || 0);
	const aVat = parseFloat(row?.VAT || row?.Article?.VAT || 0);
	const aDiscountType = row?.DiscountType || "AMOUNT";

	const price = aPrice * aQuantity;
	const discount = aDiscountType === "PERCENT" ? (price * aDiscount) / 100 : aDiscount;

	const totalWithDiscount = price - discount;
	const vat = (totalWithDiscount * aVat) / 100;
	const totalInclVat = totalWithDiscount + vat;

	return {
		total: totalInclVat,
		totalExclVat: totalWithDiscount,
		vat,
		discount,
	};
};

const getTotalStats = (item) => {
	const rows = item?.OrderRows || item?.InvoiceRows || item?.OfferRows || [];

	const total = rows.reduce(
		(acc, r) => {
			const stats = getRowStats(r);

			acc.total += stats.total;
			acc.totalExclVat += stats.totalExclVat;
			acc.vat += stats.vat;
			acc.discount += stats.discount;
			return acc;
		},
		{
			total: 0,
			totalExclVat: 0,
			vat: 0,
			discount: 0,
		}
	);

	total.roundoff = Math.round(total.total) - total.total;
	total.totalToPay = total.total + total.roundoff;

	return total;
};

export default getTotalStats;
export { getRowStats };
