import { Button, EventListener, Popover } from "@shopify/polaris";
import { CircleCancelMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "./modal";

class RemoveCircleButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onKeyDown = (event) => {
			if (event.key === "Enter") {
				this.props.onRemove();
			}
		};
	}

	render() {
		return (
			<>
				<Wrapper>
					<Button
						plain
						destructive
						icon={CircleCancelMajor}
						loading={this.props.loading}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (this.props.skipConfirm) {
								this.props.onRemove();
							} else {
								this.setState({ open: true });
							}
						}}
					/>
				</Wrapper>
				<Modal
					open={this.state.open}
					onClose={(e) => this.setState({ open: false })}
					title={`Ta bort "${this.props.title}"`}
					primaryAction={{
						content: this.props.t("common.actions.remove", "Ta bort (Enter)"),
						onAction: this.props.onRemove,
						destructive: true,
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.cancel", "Avbryt"),
							onAction: (e) => {
								e.preventDefault();
								e.stopPropagation();
								this.setState({ open: false });
							},
						},
					]}
				>
					<div
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<Modal.Section>
							<p>
								{this.props.t("common.confirm.remove", "Är du säker på att du vill ta bort")} &quot;{this.props.title}&quot;?
							</p>
							<EventListener event="keydown" handler={this.onKeyDown} />
						</Modal.Section>
					</div>
				</Modal>
			</>
		);

		// Popover appeared behind the other popovers, so we use a modal instead for now (https://github.com/itbmedia/kund24/issues/183)
		// eslint-disable-next-line no-unreachable
		return (
			<Popover
				active={this.state.open}
				activator={
					<Wrapper>
						<Button
							plain
							destructive
							icon={CircleCancelMajor}
							loading={this.props.loading}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if (this.props.skipConfirm) {
									this.props.onRemove();
								} else {
									this.setState({ open: true });
								}
							}}
						/>
					</Wrapper>
				}
				onClose={(e) => this.setState({ open: false })}
			>
				<div
					className="popup-confirm"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						this.setState({ open: false });
					}}
				>
					<h1>{this.props.title}</h1>
					<div className="popup-confirm_buttons">
						<Button destructive onClick={this.props.onRemove} loading={this.props.loading} size="slim">
							{this.props.t("common.actions.remove", "Ta bort")}
						</Button>
						<Button
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								this.setState({ open: false });
							}}
							size="slim"
						>
							{this.props.t("common.actions.cancel", "Avbryt")}
						</Button>
					</div>
				</div>
			</Popover>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(RemoveCircleButton);

const Wrapper = styled.div.attrs({ className: "removeButton" })`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(50%, -50%);

	.Polaris-Icon {
		height: 1.5rem;
		width: 1.5rem;
	}
`;
