import React, { Component } from "react";
import { Checkbox } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr";
import { store } from "../../../store";

class CustomizeLayout extends Component {
	constructor(props) {
		super(props);
		this.state = { sections: [], form: store.getState().user || { roles: [] } };
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	onSave() {
		const data = Object.assign({}, this.state.form);

		API.put("/api/users/" + store.getState().user.id + ".json", data)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const user = Object.assign({}, store.getState().user);
				user.roles = result.data.user.roles;
				store.dispatch({ type: "SET_USER", user });
				API.get("/api/workspaces.json", { params: {} })
					.then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
							return;
						}

						store.dispatch({ type: "SET_WORKSPACES", workspaces: result.data.workspaces || null });
					})
					.catch((error) => {});

				// toastr.success("Uppdaterade användare");
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	componentDidMount() {
		this.fetchSections();
	}

	fetchSections() {
		API.get("/api/sections.json")
			.then((result) => {
				this.setState({ sections: result.data.workspaces });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	handleChange(value, checked) {
		this.setState((c) => {
			if (checked) {
				c.form.roles.push(value);
				return { form: c.form };
			}

			const newRoles = c.form.roles.filter((i) => i !== value);
			c.form.roles = newRoles;
			return { form: c.form };
		});
	}

	render() {
		const { workspaces, supportItems } = this.state.sections.reduce(
			(acc, section) => {
				// if (section.type == "shareable" && section.title != "Servicedesk") {
				if (section.title === "Servicedesk") {
					acc.workspaces.push({ value: "ROLE_SUPPORT", label: section.title });
					const boards = section.boards.filter((v) => v.type == "shareable");

					if (this.state.form && this.state.form.roles && this.state.form.roles.includes("ROLE_SUPPORT")) {
						acc.supportItems.push({
							value: "ROLE_SUPPORT_RECEIVE",
							label: this.props.t("roles.service.receive", "Ta emot mail för nya ärenden"),
						});

						for (let s = 0; s < boards.length; s++) {
							acc.supportItems.push({ value: "ROLE_BOARD_" + boards[s].id, label: boards[s].title });
						}
					}
				} else {
					acc.workspaces.push({ value: "ROLE_WORKSPACE_" + section.id, label: section.title });
				}

				return acc;
			},
			{ workspaces: [], supportItems: [] }
		);

		workspaces.unshift({
			value: "ROLE_MARKETING",
			label: this.props.t("roles.marketing", "Marknadsföring"),
		});

		const integrations =
			store.getState().account.integrations.map((integration) => ({ value: "ROLE_INTEGRATION_" + integration.id, label: integration.title })) || [];

		return (
			<div className="checkboxes">
				{workspaces.map((i, index) => (
					<Checkbox
						key={i.value + index}
						label={i.label}
						checked={this.state.form.roles.includes(i.value)}
						onChange={this.handleChange.bind(this, i.value)}
					/>
				))}

				{supportItems && !!supportItems.length && (
					<React.Fragment>
						<p className="section-title">{this.props.t("announcements.terms.service_desk", "Servicedesk")}</p>

						{supportItems.map((i, index) => (
							<Checkbox
								key={i.value + index}
								label={i.label}
								checked={this.state.form.roles.includes(i.value)}
								onChange={this.handleChange.bind(this, i.value)}
							/>
						))}
					</React.Fragment>
				)}

				{integrations && !!integrations.length && (
					<React.Fragment>
						<p className="section-title">{this.props.t("announcements.terms.integrations", "announcements")}x</p>
						{integrations.map((i, index) => (
							<Checkbox
								key={i.value + index}
								label={i.label}
								checked={this.state.form.roles.includes(i.value)}
								onChange={this.handleChange.bind(this, i.value)}
							/>
						))}
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default withTranslation(["announcements", "common"], { withRef: true })(CustomizeLayout);
