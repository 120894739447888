import { Icon, InlineError, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React from "react";
import styled, { css } from "styled-components";
// import type { StatsBoxType } from "./types";
import Spinner from "../Spinner";
import Colors, { colorToRgba } from "src/js/Colors";
import Badge from "../Badge";

type AdvancedStatsBoxProps = {
	onClick?: (() => void) | null;
	item: StatsBoxType;
	children?: React.ReactNode;
};

const AdvancedStatsBox = ({ onClick, item }: AdvancedStatsBoxProps) => {
	const { className = "", title, text, tooltip, loading, col1, col2, error } = item || {};

	return (
		<Wrapper>
			{tooltip && (
				<HelpIcon>
					<Tooltip content={tooltip}>
						<Icon source={CircleInformationMajor} />
					</Tooltip>
				</HelpIcon>
			)}
			{loading && (
				<SpinnerWrapper>
					<Spinner />
				</SpinnerWrapper>
			)}

			<InnerWrapper
				className={`advancedStatsBox ${className} ${onClick ? "button" : ""}`}
				data-title={title}
				onClick={onClick as (() => void) | undefined}
			>
				<Title>{title}</Title>
				{text && <SubText>{text}</SubText>}
				{error && (
					<SubText>
						<InlineError fieldID="error" message={error} />
					</SubText>
				)}
				{!error && (
					<>
						<Col1>
							<ColLabel>{col1.label}</ColLabel>
							{col1.color ? <Badge color={col1.color}>{col1.value}</Badge> : col1.value}
						</Col1>
						<Col2>
							<ColLabel>{col2.label}</ColLabel>
							{col2.color ? <Badge color={col2.color}>{col2.value}</Badge> : col2.value}
						</Col2>
					</>
				)}
			</InnerWrapper>
		</Wrapper>
	);
};
export default AdvancedStatsBox;

const Title = styled.h3`
	grid-area: title;
	font-size: 16px;
	font-weight: 700;
`;
const SubText = styled.p`
	grid-area: subtext;
	color: var(--textColor2);
	font-size: 12px;
`;
const ColLabel = styled.p`
	color: var(--textColor2);
	font-size: 12px;
`;

const ColStyles = css`
	display: flex;
	flex-direction: column;
	/* gap: 0.5rem; */
`;

const Col1 = styled.div`
	grid-area: col1;
	align-items: start;
	${ColStyles}
`;
const Col2 = styled.div`
	grid-area: col2;
	align-items: end;

	${ColStyles}
`;

const Wrapper = styled.div`
	position: relative;
	transition: all 250ms;
`;

const HelpIcon = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;

	.Polaris-Icon {
		width: 1.5rem;
		height: 1.5rem;

		svg {
			fill: var(--color-primary);
		}
	}
`;
const InnerContentLink = styled.a`
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	display: none;
`;

const InnerWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-areas:
		"title title"
		"subtext subtext"
		"col1 col2";

	background-color: var(--cardbg);
	border-radius: 0.7rem;
	box-shadow: var(--boxShadow);
	transition: all 250ms;
	padding: 1.5rem;
	gap: 0.5rem;

	height: 100%;

	&.button {
		cursor: pointer;

		&:hover {
			filter: brightness(1.2);
		}
	}

	&:hover {
		${InnerContentLink} {
			display: block;
		}
	}
`;

const SpinnerWrapper = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;

	background-color: ${colorToRgba(Colors.main2, 0.8)};

	.Polaris-Spinner {
		opacity: 1;
		display: flex;
	}
`;
