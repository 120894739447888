import { Tabs } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Banner from "src/js/components/banner";
import { Wrapper } from "../styles";
import moment from "moment";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	invoice: Partial<FortnoxInvoiceType> | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ invoice, tabs, onSelect, selected, t }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<Wrapper>
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
			{invoice?.Booked && (
				<Banner status="success" title={t("fortnox.invoices.statuses.booked.title", "Faktura bokförd") as string}>
					{t("fortnox.invoices.statuses.booked.description", "Denna faktura är bokförd")}
				</Banner>
			)}
			{invoice?.Cancelled && (
				<Banner status="warning" title={t("fortnox.invoices.statuses.cancelled.title", "Faktura makulerad") as string}>
					{t("fortnox.invoices.statuses.cancelled.description", "Denna faktura är makulerad")}
				</Banner>
			)}
			{invoice?.Sent && (
				<Banner status="success" title={t("fortnox.invoices.statuses.sent.title", "Faktura skickad") as string}>
					{t("fortnox.invoices.statuses.sent.description", "Denna faktura är skickad")}
				</Banner>
			)}

			{invoice?.DocumentNumber && invoice?.Balance === 0 && !invoice.Cancelled && (
				<Banner status="success" title={t("fortnox.invoices.statuses.balance_0.title", "Betald") as string}>
					{t("fortnox.invoices.statuses.balance_0.description", "Denna faktura är betald")}
				</Banner>
			)}

			{invoice?.DocumentNumber && invoice?.Balance !== 0 && moment(invoice?.DueDate).isBefore(moment()) && (
				<Banner status="critical" title={t("fortnox.invoices.statuses.balance_and_due_date.title", "Förfallen") as string}>
					{t("fortnox.invoices.statuses.balance_and_due_date.description", "Denna faktura är förfallen")}
				</Banner>
			)}
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(Header);
