import { Icon, Button, Stack } from "@shopify/polaris";
import React, { Component } from "react";

class CalendarModalHeaderButton extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { label, icon, onClick, loading, disabled, primary, destructive /* active, color, items */ } = this.props;
		return (
			<div className={`calendar_modal__header__button${primary ? " primary" : ""}`}>
				<Button primary={primary} destructive={destructive} onClick={onClick} loading={loading} disabled={disabled}>
					<Stack spacing="extraTight">
						<div style={{ height: 15 }}>{React.isValidElement(icon) ? icon : <Icon source={icon} />}</div>
						<div style={{ paddingTop: 2, color: primary ? "rgb(15,15,15)" : "var(--textColor)" }}>{label}</div>
					</Stack>
				</Button>
			</div>
		);
	}
}
export default CalendarModalHeaderButton;
