import React, { useEffect } from "react";
import useQuery from "src/js/hooks/useQuery";
import { Card } from "@shopify/polaris";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import moment from "moment";
import API from "../../API";
import { toastr } from "../../components/toastr";
import { store } from "../../store";
import TimeLineRow from "../../components/TimeLineRow";
import SkeletonSimpleCard from "../../components/SkeletonSimpleCard";
import { formatDeadlineRows, getBoardTitle } from "../Workspaces/boardutils/BoardUtils";
import CalendarHelper from "../Workspaces/components/CalendarModal/CalendarHelper";

function LateActivity(props) {
	const params = {
		dates_from: moment(-1).format("YYYY-MM-DD"),
		dates_to: moment().subtract(1, "day").format("YYYY-MM-DD"),
		deadline_statuses: 0,
		contact_id: props.contact?.id,
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { data, isFetching, isError, refetch } = useQuery({
		queryKey: Object.entries(params)
			.filter(([key, value]) => value)
			.map(([key, value]) => `${key}_${value}`),
		queryFn: () => fetchData(),
		refetchOnWindowFocus: false,
	});

	const fetchData = async () => {
		try {
			const result = await API.get("/api/board_rows/search.json", { params: { ...params, return_count: true } });

			if (result.data.error) {
				toastr.error(result.data.error);
				return [];
			}

			store.dispatch({ type: "SET_BOARDS", boards: result.data.boards });

			const boardsColumns = result.data.boards.reduce((acc, board) => {
				const dateColumn = board.columns.find((column) => (column.type === "datetime" || column.type === "timeline") && column.connected_column_id);

				acc[board.id] = {
					date: dateColumn,
					person: board.columns.find((column) => column.type === "person" && (!dateColumn || dateColumn?.link_column_id)),
					contact: board.columns.find((column) => column.type === "contact"),
					description: board.columns.find((column) => column.type === "text" && ["description", "beskrivning"].includes(column.title.toLowerCase())),
				};

				return acc;
			}, {});

			const deadlineRows = formatDeadlineRows({
				rows: result.data.rows,
				boards: result.data.boards,
			});

			const formattedRows = deadlineRows.map((r) => {
				const columns = boardsColumns[r.board_id];
				const people =
					r.column_values[columns.person?.id]?.value?.map((id) => store.getState().users.find((user) => String(user.id) === String(id))) || [];

				const date = r.deadline?.date;

				const contacts = columns.contact ? r.column_values[columns.contact.id]?.value?.map((id) => store.getState().board_contacts[id]) : [];
				const description = r.column_values[columns.description?.id]?.value || CalendarHelper.getContent(r.deadline?.dateValue);

				return {
					...r,
					row: r,
					date,
					people,
					user: people[0],
					content:
						description && ![store.getState().account.todo_board_id, store.getState().user.todo_board_id].includes(r.board_id)
							? `<div><b>${r.title}</b><br /><br /><p>${description || ""}</p></div>`
							: r.title,
					contacts,
					title: getBoardTitle(result.data.boards.find((board) => String(board.id) === String(r.board_id))),
				};
			});

			const sortedRows = formattedRows.sort((a, b) => new Date(a.date) - new Date(b.date));

			props.onFetch?.({
				rows: sortedRows || [],
				count: result.data.count,
			});

			return sortedRows;
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
			throw error;
		}
	};

	useEffect(() => {
		const handleRefreshTasks = () => {
			refetch();
		};

		window.addEventListener("refreshTasks", handleRefreshTasks, false);

		return () => {
			window.removeEventListener("refreshTasks", handleRefreshTasks, false);
		};
	}, [refetch]);

	const content = (() => {
		if (props.preview) {
			return (
				<>
					{isFetching && !data?.length ? (
						<div className="loading-skeleton">
							<SkeletonSimpleCard lines={2} secondaryActions={false} primaryAction={false} />
						</div>
					) : data?.length ? (
						<TimeLineRow
							preview
							contact={props.contact}
							event={data[0]}
							onSuccess={(row) => {
								refetch();
								window.dispatchEvent(new CustomEvent("refreshActivities"));
							}}
							loading={isFetching}
						/>
					) : (
						<p>{props.t("contact.activity.text.no_row", "Ingen försenad akvititet")}</p>
					)}

					{data && data.length > 1 && props.onShowMore && (
						<div className="showmore">
							<button
								style={{
									border: "none",
									background: "transparent",
									outline: "none",
								}}
								onClick={props.onShowMore}
							>
								{props.t("timeline.actions.show_more", "Visa mer")}
							</button>
						</div>
					)}
				</>
			);
		}

		return isFetching && !data?.length ? (
			<>
				<div className="loading-skeleton">
					<SkeletonSimpleCard lines={2} secondaryActions={false} primaryAction={false} />
				</div>
				<div className="loading-skeleton">
					<SkeletonSimpleCard lines={2} secondaryActions={false} primaryAction={false} />
				</div>
				<div className="loading-skeleton">
					<SkeletonSimpleCard lines={2} secondaryActions={false} primaryAction={false} />
				</div>
			</>
		) : data?.length ? (
			data?.map((row, index) => (
				<TimeLineRow
					contact={props.contact}
					key={row.key || row.id || index}
					event={row}
					onSuccess={(row) => {
						refetch();
						window.dispatchEvent(new CustomEvent("refreshActivities"));
					}}
					loading={isFetching}
				/>
			))
		) : (
			<p>{props.t("contact.activity.text.no_row", "Ingen försenad akvititet")}</p>
		);
	})();

	return (
		<Wrapper>
			<Card sectioned>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<h2 className="small_card_title">{props.t("contact.late_acitivity.title", "Försenad aktivitet")}</h2>
				</div>
				{content}
			</Card>
		</Wrapper>
	);
}

export default withTranslation(["contact"], { withRef: true })(LateActivity);

const Wrapper = styled.div`
	grid-area: late_activity;

	.timeline__row .timeline__row__content .timeline__row__content__text {
		padding: 0;
	}

	.loading-skeleton .Polaris-Card__Section {
		padding: 9px;
	}
`;
