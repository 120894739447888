import React, { Component } from "react";
import styled from "styled-components";

const StyledHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 60px;
	width: 500px;
	margin: auto;

	svg path {
		fill: var(--textColor);
	}
`;

const StyledText = styled.p`
	white-space: pre-line;
	text-align: center;
	font-size: 14px;
	line-height: 16px;
`;

class HelpHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { title, text, children } = this.props;
		return (
			<StyledHeader>
				<H1>{title}</H1>

				<StyledText>{text}</StyledText>
				{children}
			</StyledHeader>
		);
	}
}

export default HelpHeader;
const H1 = styled.h1`
	font-size: 2.8rem;
	line-height: 3.2rem;
	font-weight: 700;
	margin-bottom: 2rem;
	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		margin-right: 1rem;
		height: 30px;
		width: 30px;
	}
`;
