import { Button, Card, FormLayout, Label, Modal, Tabs, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr";
import ColorPicker from "../../components/ColorPicker";
import Colors from "../../Colors";
import UploadModal from "../../components/UploadModal";

class CreateModal extends Component {
	constructor(props) {
		super(props);
		this.tabs = [
			{ content: props.t("help.tabs.faq.title", "Q&A"), id: "faq" },
			{ content: props.t("help.tabs.boxes.title", "Box"), id: "box" },
		];
		this.state = { tabIndex: props.tabIndex || 0, form: { type: props.type || this.tabs[0]?.id, color: Colors.accent } };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.props.open !== props.open) {
			if (props.open) {
				this.setState((c) => ({
					removeAsk: false,
					form: props.form || c.form || {},
					tabIndex: Math.max(props.tabIndex || this.tabs.findIndex((t) => t.id === props.form.type), 0),
				}));
			} else {
				this.setState({ removeAsk: false, form: { type: this.tabs[0]?.id, color: Colors.accent } });
			}
		}
	}

	onChange(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	onChangeVideo(index, field, value) {
		if (!this.state.form.videos) {
			this.state.form.videos = [{}];
		}

		this.state.form.videos[index][field] = value;
		this.setState({ form: this.state.form });
	}

	onSave() {
		this.setState({ loading: true });

		if (!this.state.form.type) {
			this.state.form.type = this.tabs[this.state.tabIndex]?.id;
		}

		if (this.state.form.id) {
			API.put(`/api/helpcenter/${this.state.form.id}.json`, this.state.form)
				.then((result) => {
					if (result.data.error) {
						console.error("result.data.error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					this.props.onEdit(result.data.item);
					this.props.onClose();
					this.setState({ form: { type: this.tabs[this.state.tabIndex]?.id, color: Colors.accent } });
				})
				.catch((e) => {
					console.error("e:", e);
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		} else {
			API.post("/api/helpcenter.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						console.error("result.data.error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					this.props.onSave(result.data.item);
					this.props.onClose();
					this.setState({ form: { type: this.tabs[this.state.tabIndex]?.id, color: Colors.accent } });
				})
				.catch((e) => {
					console.error("e:", e);
				})
				.finally(() => {
					this.setState({ loading: false });
				});
		}
	}

	getContent() {
		const tab = this.tabs[this.state.tabIndex];
		switch (tab?.id) {
			case "faq":
				return (
					<FormLayout>
						<TextField
							label={this.props.t("help.fields.question.label", "Fråga")}
							onChange={this.onChange.bind(this, "question")}
							value={this.state.form.question}
							placeholder={this.props.t("help.fields.question.placeholder", "Fråga..")}
							multiline
						/>
						<TextField
							label={this.props.t("help.fields.answer.label", "Svar")}
							multiline
							onChange={this.onChange.bind(this, "answer")}
							value={this.state.form.answer}
							placeholder={this.props.t("help.fields.question.answer", "Svar..")}
						/>
					</FormLayout>
				);
			case "box":
				return (
					<FormLayout>
						<>
							<Label>{this.props.t("help.fields.icon.label", "Icon bild")}</Label>
							<Button
								onClick={() => {
									this.setState((c) => ({ openFileDialog: !c.openFileDialog }));
								}}
								plain={this.state.form.icon}
								icon={
									this.state.form.icon ? (
										<img alt="" style={{ height: "50px", width: "50px", objectFit: "cover", borderRadius: "25px" }} src={this.state.form.icon} />
									) : (
										CirclePlusMajor
									)
								}
							/>
						</>
						<UploadModal
							allowMultiple={false}
							open={this.state.openFileDialog}
							onClose={() => {
								this.setState({ openFileDialog: false });
							}}
							onSelect={(files) => {
								this.state.form.icon = files[0]?.url;
								this.setState({ openFileDialog: false });
							}}
							public
						/>

						<TextField
							label={this.props.t("help.fields.title.label", "Titel")}
							onChange={this.onChange.bind(this, "title")}
							value={this.state.form.title}
							placeholder={this.props.t("help.fields.title.placeholder", "Titel..")}
							multiline
						/>
						<TextField
							label={this.props.t("help.fields.text.label", "Text")}
							onChange={this.onChange.bind(this, "text")}
							value={this.state.form.text}
							placeholder={this.props.t("help.fields.text.placeholder", "Text..")}
							multiline
						/>
						<TextField
							label={this.props.t("help.fields.path.label", "Url")}
							onChange={this.onChange.bind(this, "path")}
							value={this.state.form.path}
							placeholder={this.props.t("help.fields.text.placeholder", "Url..")}
							helpText="Exempel: /admin/contacts/people eller /admin/contacts/:id"
						/>
						<>
							<Label>{this.props.t("help.fields.color.label", "Färg")}</Label>
							<ColorPicker
								style={{ height: "36px", width: "36px", borderRadius: "3px" }}
								color={this.state.form?.color}
								onChange={this.onChange.bind(this, "color")}
							/>
						</>

						<>
							<Label>{this.props.t("help.fields.videos.label", "Videos")}</Label>
							{this.state.form.videos?.map(({ title, text, url }, index) => {
								return (
									<Card
										key={index}
										sectioned
										secondaryFooterActions={[
											{
												content: this.props.t("commob.actions.remove", "Ta bort"),
												onAction: () => {
													this.state.form.videos.splice(index, 1);
													this.setState({ form: this.state.form });
												},
											},
										]}
									>
										<TextField
											label={this.props.t("help.fields.title.label", "Titel")}
											onChange={this.onChangeVideo.bind(this, index, "title")}
											value={title}
											placeholder={this.props.t("help.fields.title.placeholder", "Titel..")}
											multiline
										/>
										<TextField
											label={this.props.t("help.fields.text.label", "Text")}
											onChange={this.onChangeVideo.bind(this, index, "text")}
											value={text}
											placeholder={this.props.t("help.fields.text.placeholder", "Text..")}
											multiline
										/>
										<TextField
											label={this.props.t("help.fields.url.label", "Url")}
											onChange={this.onChangeVideo.bind(this, index, "url")}
											value={url}
											placeholder={this.props.t("help.fields.url.placeholder", "Url")}
										/>
									</Card>
								);
							})}
						</>

						<div style={{ marginTop: "1rem" }}>
							<Button
								icon={CirclePlusMajor}
								onClick={() => {
									if (!this.state.form.videos) {
										this.state.form.videos = [];
									}
									this.state.form.videos.push({});
									this.setState({ form: this.state.form });
								}}
							>
								{this.props.t("help.videos.actions.add", "Lägg till video")}
							</Button>
						</div>
					</FormLayout>
				);

			default:
				return null;
		}
	}

	getType() {
		switch (this.state.form.type) {
			case "faq":
				return this.props.t("help.faq.type", "Fråga och svar");
			case "box":
				return this.props.t("help.boxes.type", "Box");
			default:
				return this.tabs[this.state.tabIndex]?.id || "";
		}
	}

	onRemove() {
		this.setState({ removing: true });
		API.delete(`/api/helpcenter/${this.state.form.id}.json`, this.state.form)
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				this.props.onDelete(this.state.form);
				this.props.onClose();
			})
			.catch((e) => {
				console.error("error:", e);
			})
			.finally(() => {
				this.setState({ removing: false });
			});
	}

	render() {
		return (
			<>
				<Modal
					title={this.state.form.id || `${this.props.t("help.terms.new", "Ny")} ${this.getType()}`}
					size="large"
					// large
					// fullscreen
					open={this.props.open}
					onClose={this.props.onClose}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.save", "Spara") : this.props.t("common.actions.create", "Skapa"),
						onAction: this.onSave.bind(this),
						loading: this.state.loading,
					}}
					secondaryActions={
						this.state.form.id
							? [
									{
										content: this.props.t("common.actions.remove", "Ta bort"),
										onAction: () => {
											this.setState({ removeAsk: true });
										},
										destructive: true,
									},
							  ]
							: []
					}
				>
					<Modal.Section>
						{!this.state.form?.id && (
							<div style={{ paddingBottom: "2rem" }}>
								<Tabs
									tabs={this.tabs}
									selected={this.state.tabIndex}
									onSelect={(tabIndex) => {
										this.state.form.type = this.tabs[tabIndex]?.id;
										this.setState({
											tabIndex,
											form: this.state.tabIndex === tabIndex ? this.state.form : { type: this.tabs[tabIndex]?.id, color: Colors.accent },
										});
									}}
								/>
							</div>
						)}
						{this.getContent()}
					</Modal.Section>
				</Modal>

				<Modal
					size="large"
					title={this.props.t("help.remove_modal.title", "Ta bort")}
					open={this.state.removeAsk}
					onClose={() => {
						this.setState({ removeAsk: false });
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.remove", "Ta bort"),
							onAction: this.onRemove.bind(this),
							destructive: true,
							loading: this.state.removing,
						},
						{
							content: this.props.t("common.actions.cancel", "Avbryt"),
							onAction: () => {
								this.setState({ removeAsk: false });
							},
						},
					]}
				>
					<Modal.Section>
						<p>{this.props.t("help.remove_modal.confirm_q", "Är du säker du vill ta bort?")}</p>
					</Modal.Section>
				</Modal>
			</>
		);
	}
}
export default withTranslation(["help", "common"], { withRef: true })(CreateModal);
