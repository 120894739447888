import { Avatar, Card, Checkbox, FormLayout, Labelled, Layout } from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import API from "src/js/API";
import CardList from "src/js/components/CardList";
import ItemCard from "src/js/components/ItemCard";
import Page from "src/js/components/page";
import SelectSearchField from "src/js/components/SelectSearchField";
import TextField from "src/js/components/TextField";
import { toastr } from "src/js/components/toastr";
import useQuery from "src/js/hooks/useQuery";
import Button from "src/js/components/Button";

const GroupEdit = ({ match, history, defaultData = history.location.state?.data, t }) => {
	const ref = React.useRef<any>();

	const id = match?.params?.id;
	const [form, setForm] = useState<Partial<UserGroupType>>(defaultData);
	const queryClient = useQueryClient();
	const [addUserOpen, setAddUserOpen] = useState(false);

	const fetch = async () => {
		try {
			const res = await API.get(`/api/groups/${id}.json`);
			return res.data.group;
		} catch (e) {
			toastr.error(e);
		}
	};

	const queryKey = ["group", { id }];
	const { data: group, isFetching } = useQuery({
		queryKey,
		queryFn: fetch,
		enabled: !!id,
	});

	useEffect(() => {
		if (group) setForm(group);
	}, [group]);

	const saveFunction = useCallback(
		async (group: UserGroupType) => {
			const endpoint = !id ? `/api/groups.json` : `/api/groups/${id}.json`;

			const res = await (id ? API.put(endpoint, { ...group }) : API.post(endpoint, { ...group }));

			const successMessage = id ? t("groups.responses.saved", "Grupp sparad") : t("groups.responses.created", "Grupp skapad");

			toastr.success(successMessage);

			history.replace(`/admin/groups/${res.data.group.id}`, {
				data: res.data.group,
			});

			return res;
		},
		[id, t, history]
	);

	const update = async ({ group }) => {
		const response = await saveFunction(group);
		return response?.data.group;
	};

	const mutation = useMutation(update, {
		onSuccess: (data) => {
			if (queryKey?.length) queryClient.setQueryData(queryKey, data);
		},
	});

	const handleSave = useCallback(
		async (group: UserGroupType) => {
			try {
				return await mutation.mutateAsync({ group: form });
			} catch (e: any) {
				if (!e?.response) throw e;
				toastr.error(e);
			}
		},
		[mutation, form]
	);

	const getChangeHandler = (field: UserGroupTypeMutableKeys) => (value: string | boolean) => {
		setForm((c: Partial<CustomFieldType>) => {
			const newForm = { ...c };
			newForm[field as string] = value;

			return newForm;
		});
	};

	const removeGroup = async () => {
		if (!id) return;

		try {
			await API.delete(`/api/groups/${id}.json`);
			toastr.success(t("groups.responses.removed", "Grupp borttagen"));

			queryClient.invalidateQueries(queryKey);

			history.replace("/admin/groups");
		} catch (e) {
			toastr.error(e);
		}
	};

	const renderItem = (item: any) => {
		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={
					<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
						{item.name}
						{/* {item.connected ? (
							<Badge color={Colors.green}>{t("common.connected", "Ansluten")}</Badge>
						) : (
							<Badge color={Colors.grey}>{t("common.notConnected", "Ej Ansluten")}</Badge>
						)} */}
					</div>
				}
				leftColumn={[
					{
						label: t("contacts.field.email", "Mailadress"),
						value: <a href={`mailto:${item.email}`}>{item.email}</a>,
					},
				]}
				rightColumn={[
					{
						label: t("contacts.field.phone", "Telefon"),
						value: <a href={`tel:${item.phone}`}>{item.phone}</a>,
					},
				]}
			/>
		);
	};

	const addUsers = async (ids = ref.current?.getSelectedItems()) => {
		try {
			await API.post(`/api/groups/${id}/users.json`, {
				user_ids: ids,
			});
			toastr.success(t("groups.responses.users.added", "Användare tillagd"));

			ref.current?.reload();
		} catch (error) {
			toastr.error(error);
		}
	};

	const removeUsers = async () => {
		const ids = ref.current?.getSelectedItems();
		try {
			const res = await API.delete(`/api/groups/${id}/users.json`, { data: { user_ids: ids } });
			toastr.success(t("groups.responses.users.removed", "Användare borttagna"));
			return res;
		} catch (error) {
			toastr.error(error);
		}
	};

	const promotedBulkActions = [
		// {
		// 	content: t("groups.actions.add.label", "Lägg till") as string,
		// 	onAction: addUsers,
		// },
		{
			content: t("groups.actions.remove.label", "Ta bort") as string,
			onAction: removeUsers,
		},
	];

	const disabled = isFetching || mutation.isLoading;

	return (
		<Page
			title={id ? t("groups.edit", "Redigera grupp: {{title}}", { title: group?.name }) : t("groups.create", "Skapa grupp")}
			history={history}
			primaryAction={{
				content: id ? t("groups.save", "Spara") : t("groups.create", "Skapa"),
				onClick: handleSave,
				loading: mutation.isLoading,
			}}
			secondaryActions={[
				{
					content: t("groups.actions.remove.label", "Ta bort") as string,
					onAction: removeGroup,
					enabled: !!id,
					destructive: true,
					confirmation: {
						title: t("groups.actions.remove.confirm.title", "Ta bort grupp") as string,
						content: t("groups.actions.remove.confirm.content", "Är du säker på att du vill ta bort gruppen?") as string,
						confirm: t("groups.actions.remove.confirm.confirm", "Ta bort") as string,
						cancel: t("groups.actions.remove.confirm.cancel", "Avbryt") as string,
					},
				},
			].filter((a) => !("enabled" in a) || a.enabled)}
		>
			<Layout>
				<Layout.Section>
					<Card sectioned>
						<FormLayout>
							<Labelled id="type" label={t("groups.fields.disabled.label2", "Status")}>
								<Checkbox
									checked={!form.disabled}
									disabled={disabled}
									label={t("groups.fields.disabled.label", "Aktiv")}
									onChange={(v) => getChangeHandler("disabled")(!v)}
								/>
							</Labelled>

							<TextField label={t("groups.fields.name.label", "Namn")} value={form?.name} onChange={getChangeHandler("name")} disabled={disabled} />

							<TextField
								disabled={disabled}
								label={t("groups.fields.description.label", "Beskrivning")}
								value={form.description}
								onChange={getChangeHandler("description")}
								multiline={2}
							/>

							<Labelled id="type" label={t("groups.fields.AddUsersAutomatically.label", "Lägg till nya användare i gruppen automatiskt")}>
								<Checkbox
									checked={form?.add_new_users_automatically}
									disabled={disabled}
									label={t("groups.fields.disabled.label1", "Ja")}
									onChange={getChangeHandler("add_new_users_automatically")}
								/>
							</Labelled>
						</FormLayout>
					</Card>
				</Layout.Section>

				{!!id && (
					<>
						<Layout.Section>
							<CardList
								// showHeader={false}
								additionalAction={
									<div style={{ display: "flex", gap: "1rem" }}>
										{addUserOpen && (
											<SelectSearchField
												style={{ minWidth: "400px" }}
												placeholder={t("groups.members.search.placeholder", "Sök efter användare att lägga till")}
												resourceName={{
													singular: t("groups.members.singular", "användare"),
													plural: t("groups.members.plural", "användare"),
												}}
												resource="users"
												resourceHandle="users"
												value={null}
												idHandle="id"
												labelHandle="name"
												onSelect={(user: UserType) => {
													addUsers([user.id]);
												}}
												clearOnSelect
											/>
										)}

										<Button
											onClick={() => {
												setAddUserOpen(!addUserOpen);
											}}
											primary
										>
											{t("groups.members.add", "Lägg till användare")}
										</Button>
									</div>
								}
								appliedFilters={[
									{
										key: "enabled",
										value: "1",
									},
									{
										key: "group_id",
										value: id,
									},
								]}
								ref={ref}
								// params={{
								// 	group_id: id,
								// 	// connected_group_id: id,
								// }}
								onClick={(item) => {
									history.push(`/admin/users/${item.id}`, {
										data: item,
									});
								}}
								// filters={filters}
								resourceUrl={`/api/users.json`}
								resourceName={{ singular: t("common.user", "Användare"), plural: t("common.users", "Användare") }}
								resourceHandle={"users"}
								renderItem={renderItem}
								promotedBulkActions={promotedBulkActions}
								// bulkActions={[]}
							/>
						</Layout.Section>
					</>
				)}
			</Layout>
		</Page>
	);
};

export default withTranslation(["groups", "common"])(GroupEdit);
