import React, { Component } from "react";
import { Button, FormLayout, Icon, Popover, TextField } from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import NestedOptionsList from "src/js/components/NestedOptionsList/NestedOptionsList";
import getColumnOptions from "./Table/getColumnOptions";

class AddVariableContent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			input: this.props.element?.text || "",
			focused: false,
		};
		//Legacy for old pdfs
		this.fixedVaribles = [
			{ label: this.props.t("pdf.variables.empty.label", "Eget värde"), value: "" },
			{ label: this.props.t("pdf.variables.today_date.label", "Dagens datum"), value: "Dagens datum" },
			{ label: this.props.t("pdf.variables.company_name.label", "Företagsnamn"), value: "Företagsnamn" },
			{ label: this.props.t("pdf.variables.company_address.label", "Företagets gatuadress"), value: "Företagets gatuadress" },
			{ label: this.props.t("pdf.variables.company_zip.label", "Företagets postnummer"), value: "Företagets postnummer" },
			{ label: this.props.t("pdf.variables.company_county.label", "Företagets ort"), value: "Företagets ort" },
			{ label: this.props.t("pdf.variables.company_email.label", "Företagets epost"), value: "Företagets epost" },
			{ label: this.props.t("pdf.variables.company.phone", "Företagets telefon"), value: "Företagets telefon" },
			{ label: this.props.t("pdf.variables.org.label", "Organisationsnummer"), value: "Organisationsnummer" },
			{ label: this.props.t("pdf.variables.contact_name.label", "Kontaktperson namn"), value: "Kontaktperson namn" },
			{ label: this.props.t("pdf.variables.contact_email.label", "Kontaktperson epost"), value: "Kontaktperson epost" },
			{ label: this.props.t("pdf.variables.contact.phone.label", "Kontaktperson telefon"), value: "Kontaktperson telefon" },
			{ label: this.props.t("pdf.variables.contact_name.label", "Användarens namn"), value: "Användarens namn" },
			{ label: this.props.t("pdf.variables.user_profile.label", "Användarens profilbild"), value: "PF", type: "profile" },
			{ label: this.props.t("pdf.variables.user_phone.label", "Användarens telefonnr"), value: "Användarens telefonnr" },
			{ label: this.props.t("pdf.variables.user_email.label", "Användarens e-post"), value: "Användarens e-post" },
		];
		this.onKeyDown = this.onKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKeyDown);
		setTimeout(() => {
			this.setState({ focused: true });
		}, 0);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyDown);
	}

	onKeyDown(e) {
		if (e.key === "Enter" && this.props.focused) {
			e.preventDefault();
			e.stopPropagation();
			this.addVariable();
		}
	}

	addVariable() {
		const type = this.fixedVaribles.find((v) => v.value == this.state.input)?.type;
		this.props.addVariable(this.state.input, type);
	}

	updateForm(field, value) {
		this.setState({ [field]: value });
	}

	render() {
		return (
			<div className="variable-popup">
				<Popover.Section>
					<button className="close-button" onClick={this.props.onClose}>
						<Icon source={MobileCancelMajor} />
					</button>
					<FormLayout>
						<TextField
							onFocus={this.props.onFocus}
							onBlur={this.props.onBlur}
							label={this.props.t("pdf.fields.variable.label1", "Variabel")}
							value={this.state.input}
							onChange={this.updateForm.bind(this, "input")}
							focused={this.state.focused}
							// autoFocus
						/>

						{/* <Button onClick={this.closeModal.bind(this, "variable")}>Avbryt</Button> */}
						<NestedOptionsList
							label={this.props.label || this.props.t("pdf.editor.add_variable.label", "Variabel")}
							// options={findOptionOptionsByType(getColumnOptions(), "array")}
							options={getColumnOptions()}
							value={this.state.input?.replace(/{{|}}/g, "")}
							onChange={this.updateForm.bind(this, "input")}
						/>

						<Button primary onClick={this.addVariable.bind(this)}>
							{this.props.element ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Lägg till")}
						</Button>
					</FormLayout>
				</Popover.Section>
			</div>
		);
	}
}

export default withTranslation(["pdf", "common"], { withRef: true })(AddVariableContent);
