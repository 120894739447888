import { ChoiceList, Modal } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../../API";
import Banner from "../../components/banner";
import { toastr } from "../../components/toastr";

class BoardAccessModal extends Component {
	constructor(props) {
		super(props);
		this.state = { type: props.board.type };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && this.props.open !== props.open) {
			this.setState({ type: props.board.type });
		}
	}

	onSave() {
		this.setState({ saving: true });
		API.put("/api/boards/" + this.props.board.id + ".json", { type: this.state.type }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					console.error(result.data.error);
					toastr.error(result.data.error);
					return;
				}

				this.props.onUpdateBoard(result.data.board);
				this.props.onClose();
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			})
			.finally(() => {
				this.setState({ saving: false });
			});
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("board.modals.change_access.title", "Ändra behörighet")}
				primaryAction={{
					content: this.props.t("common.actions.change", "Ändra"),
					loading: this.state.saving,
					disabled: this.state.type == this.props.board.type,
					onAction: this.onSave.bind(this),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<ChoiceList
						choices={[
							{
								label: this.props.t("board.modals.change_access.fields.public.label", "Publik"),
								value: "public",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner title={this.props.t("board.modals.change_access.fields.public.title", "Publik board")}>
											{this.props.t("board.modals.change_access.fields.public.text", "Alla som har ett konto kommer åt boarden")}
										</Banner>
									) : null,
							},
							{
								label: this.props.t("board.modals.change_access.fields.public_read.label", "Publik (endast läs)"),
								value: "public_read",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner title={this.props.t("board.modals.change_access.fields.public_read.title", "Publik board (endast läs)")}>
											{this.props.t(
												"board.modals.change_access.fields.public_read.text",
												"Alla kan se boarden, men endast kopplade medlemmar har skrivrättigheter"
											)}
										</Banner>
									) : null,
							},
							{
								label: this.props.t("board.modals.change_access.fields.shareable.label", "Delad"),
								value: "shareable",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner title={this.props.t("board.modals.change_access.fields.shareable.title", "Delad board")}>
											{this.props.t(
												"board.modals.change_access.fields.shareable.text",
												"Det går att dela boarden med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna"
											)}
										</Banner>
									) : null,
							},
							{
								label: this.props.t("board.modals.change_access.fields.private.label", "Privat"),
								value: "private",
								renderChildren: (isSelected) =>
									isSelected ? (
										<Banner title={this.props.t("board.modals.change_access.fields.private.title", "Privat board")}>
											{this.props.t("board.modals.change_access.fields.private.text", "Enbart ägaren (du) kan se boarden")}
										</Banner>
									) : null,
							},
						]}
						selected={[this.state.type]}
						onChange={(v) => {
							this.setState({ type: v[0] });
						}}
					/>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["board", "row", "common"], { withRef: true })(BoardAccessModal);
