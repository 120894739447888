import { Tabs } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Banner from "src/js/components/banner";
import styled from "styled-components";
// import { getStatus } from "../scriveUtilities";

type HeaderProps = {
	document: ScriveDocumentType | null;
	tabs: { id: string; content: string; render: any }[];
	selected: number;
	onSelect: (selectedTabIndex: number) => void;
} & WithTranslation;

const Header = ({ document, tabs, onSelect, selected, t }: HeaderProps) => {
	// const status = getStatus(document);
	return (
		<Wrapper>
			{document?.is_trashed && (
				<Banner style={{ marginBottom: 10 }} title={t("scrive.banner.trashed.title", "Dokumentet är slängt")} status="critical" />
			)}
			{document?.is_deleted && (
				<Banner style={{ marginBottom: 10 }} title={t("scrive.banner.deleted.title", "Dokumentet är borttaget")} status="critical" />
			)}
			<Tabs tabs={tabs} selected={selected} onSelect={onSelect} />
		</Wrapper>
	);
};
export default withTranslation(["scrive", "common"])(Header);

const Wrapper = styled.div`
	margin-bottom: 3rem;
`;
// {t("scrive.statuses.trashed", "Slängd")}

// {t("scrive.statuses.deleted", "Borttagen")}
