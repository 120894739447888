import React, { Component } from "react";
import { Stack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import moment from "moment";
import styled from "styled-components";
import BoardHelper from "./BoardHelper.js";
import MyPopover from "../../components/Popover";
import { getBoardRowUrl } from "./boardutils/BoardUtils";

const WIDTH = 500;

class CellRowLinkFrom extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onFocus() {
		this.props.onFocus();
		/*
		setTimeout(() => {
			this.input.focus();
		}, 200);
        */
	}

	getRowPreview(row) {
		const showDeadlines = !!this.props.column.options.show_deadlines;
		if (showDeadlines) {
			// TODO: Status och person kanske?
			return (row.deadlines?.length > 0 ? row.deadlines[0].date + " - " : "") + row.title;
		}
		return row.title;
	}

	clickRow(row) {
		window.open(getBoardRowUrl(row), "_blank");
	}

	decorateRowsWithDeadlines(rows) {
		return rows
			.map((row) => {
				const board = BoardHelper.getBoard(row.board_id);
				if (board) {
					const deadlineColumns = board.columns.filter(
						(column) => (column.type == "timeline" || column.type == "datetime") && column.connected_column_id && column.link_column_id
					);

					const deadlines = [];

					if (deadlineColumns.length > 0) {
						for (let i = 0; i < deadlineColumns.length; i++) {
							let date = null;
							let status = null;
							const statusColumn = board.columns.find((col) => col.id == deadlineColumns[i].connected_column_id);
							const userId = row.column_values[deadlineColumns[i].link_column_id].value[0];
							if (deadlineColumns[i].type == "timeline") {
								date = row.column_values[deadlineColumns[i].id].value.start;
							} else {
								date = row.column_values[deadlineColumns[i].id].value.datetime;
							}
							if (statusColumn) {
								status =
									statusColumn.options.statuses.find((s) => s.id == row.column_values[statusColumn.id])?.value ||
									statusColumn.options.statuses.find((s) => s.id == row.column_values[statusColumn.id]);
							}

							const complete = (status && status?.color == "#00c875") || moment(date) < moment(moment().format("YYYY-MM-DD"));

							if (!complete) {
								deadlines.push({
									board_title: board.title,
									status,
									date: moment(date).format("YYYY-MM-DD"), // Ska endast visa hela datum (yeah right)
									userId,
								});
							}
						}
					}
					return Object.assign({ deadlines }, row);
				}
				return row;
			})
			.sort((a, b) => {
				if (a.deadlines?.length > b.deadlines?.length) {
					return -1;
				}
				return 0;
			})
			.sort((a, b) => {
				if (a.deadlines?.length > 0 && b.deadlines?.length > 0) {
					// Both rows have deadlines
					if (moment(a.deadlines[0].date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
						return 1;
					} else if (moment(b.deadlines[0].date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
						return -1;
					} else if (moment(a.deadlines[0].date) > moment(b.deadlines[0].date)) {
						return 1;
					}
					return -1;
				}
				return 0;
			});
	}

	render() {
		let rows = [];

		const showDeadlines = !!this.props.column.options.show_deadlines;

		if (this.props.row.linked_row_ids) {
			for (let i = 0; i < this.props.row.linked_row_ids.length; i++) {
				const row = BoardHelper.getRowLinkFrom(this.props.row.linked_row_ids[i]);
				if (row) {
					rows.push(row);
				}
			}

			if (showDeadlines) {
				// Calculate deadlines and sort by date
				rows = this.decorateRowsWithDeadlines(rows);
			}
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						style={{
							height: "100%",
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							padding: 3,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, this.onFocus.bind(this));
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<Stack spacing="none" wrap={false}>
							<Stack.Item fill>
								<div
									className="overflow-ellipsis"
									style={{
										border: "1px solid #ccc",
										opacity: this.props.row.linked_row_ids?.length < 1 ? 0.8 : null,
										// backgroundColor: this.props.row.linked_row_ids.length < 1 ? null : "#fff",
										borderRadius: "3px 0px 0px 3px",
										borderLeft: "5px solid #006fbb",
										padding: 3,
										position: "relative",
									}}
								>
									{!this.props.row.linked_row_ids || this.props.row.linked_row_ids.length < 1
										? "–"
										: rows.length > 0
										? this.getRowPreview(rows[0])
										: this.props.row.linked_row_ids[0]}
								</div>
							</Stack.Item>
							{this.props.row.linked_row_ids?.length > 1 ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{this.props.row.linked_row_ids.length - 1}
								</div>
							) : null}
						</Stack>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div>
					{/* <Heading>Välj rader</Heading> */}
					<div style={{ width: WIDTH, position: "relative", padding: 10 }}>
						{rows.map((row, index) => {
							return (
								<div key={index} style={showDeadlines && row.deadlines?.length < 1 ? { opacity: 0.5 } : null}>
									<div
										style={{
											fontSize: 14,
											marginTop: 10,
											marginBottom: 10,
										}}
									>
										{BoardHelper.getBoard(row.board_id)?.title}
									</div>
									<RowWrapper onClick={this.clickRow.bind(this, row)}>{this.getRowPreview(row)}</RowWrapper>
								</div>
							);
						})}
					</div>
				</div>
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellRowLinkFrom);

const RowWrapper = styled.div`
	transition: filter 250ms;
	border-width: 1px 1px 1px 5px;
	border-style: solid;
	border-color: rgb(204, 204, 204) rgb(204, 204, 204) rgb(204, 204, 204) rgb(0, 111, 187);

	border-radius: 3px 0px 0px 3px;
	padding: 3px;
	cursor: pointer;
	position: relative;

	&:hover {
		filter: brightness(1.2);
	}
`;
