import React, { Component } from "react";
import { Modal, Tag, Subheading, FormLayout, Checkbox, TextField, Select, Stack, Button, Tooltip, Tabs, Icon, Spinner } from "@shopify/polaris";
import { EditMajor, InfoMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import axios from "axios";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";
import SearchField from "./search_field.js";
import ContactSelector from "./ContactSelector.js";
import RemoveModal from "./remove_modal.js";
import ProfileAvatar from "./ProfileAvatar.js";
import UploadModal from "./UploadModal.js";
import ContactFortnoxContent from "./ContactFortnoxContent.js";
import Banner from "./banner";

class ContactModal extends Component {
	constructor(props) {
		super(props);
		let contact = null;
		if (!props.contact) {
			contact = {
				name: "",
				email: "",
				phone: "",
				mobile_phone: "",
				metafields: [],
				country: "SE",
				note: "",
				tags: [],
			};
			if (props.isCompany) {
				contact.is_company = 1;
			}
		} else {
			contact = Object.assign({}, props.contact);
			if (!props.contact || !props.contact.metafields) {
				contact.metafields = [];
			}
		}
		this.state = { saving: false, form: contact, selectedTabIndex: 0 };
		this.checkDuplicatesByOrgnrDebounced = debounce(this.checkDuplicatesByOrgnr.bind(this), 500, { leading: false, trailing: true });
		this.checkDuplicatesByEmailDebounced = debounce(this.checkDuplicatesByEmail.bind(this), 500, { leading: false, trailing: true });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			let contact = null;
			if (!props.contact) {
				contact = {
					name: "",
					email: "",
					phone: "",
					mobile_phone: "",
					metafields: [],
					country: "SE",
					note: "",
					tags: [],
				};
				if (props.isCompany) {
					contact.is_company = 1;
				}
			} else {
				contact = Object.assign({}, props.contact);
				if (contact.is_bolag) {
					const pcs = contact.id.split("#");
					contact.id = pcs[0];
				}
				if (!props.contact || !props.contact.metafields) {
					contact.metafields = [];
				}
			}

			this.setState({ open: props.open, form: contact, selectedTabIndex: 0 }, () => {
				if (props.open && this.state.form && this.state.form.integrations) {
					const fortnoxIntegration = store.getState().account.integrations.find((i) => {
						const integration = this.state.form.integrations.find((int) => i.id == int.integration_id);

						if (!integration) return false;
						return i.type === "fortnox" || i.type === "fortnox_invoice";
					});

					if (fortnoxIntegration) this.fetchIntegrationDetails(fortnoxIntegration, "fortnox");
				}
			});
		}
	}

	fetchIntegrationDetails(integration, type) {
		if (!integration || !integration.id) return null;
		const number = ((this.state.form.integrations || []).find((connection) => connection.integration_id === integration.id) || {}).their_id || null;
		const loadingType = `loading_${type}`;
		this.setState({ [loadingType]: true });
		if (number && integration.integration_id && integration.integration_id === "fortnox") {
			API.get(`/api/integrations/${integration.integration_id}/${integration.id}/customers/${number}.json`)
				.then((result) => {
					const form = Object.assign(result.data, { id: integration.id });
					if (!this.state.form.integrationfields) {
						this.state.form.integrationfields = {
							[type]: form,
						};
					} else {
						this.state.form.integrationfields[type] = form;
					}
					this.state.form.orgnr = form.OrganisationNumber;
					this.state.form.email = form.Email;
					this.state.form.phone = form.Phone1;
					this.state.form.address1 = form.Address1;
					this.state.form.city = form.City;
					this.state.form.zip = form.ZipCode;
					this.setState({ [loadingType]: false, form: this.state.form });
				})
				.catch((error) => {
					console.error("error:", error);
					this.setState({ [loadingType]: false });
				});
		} else {
			API.get(`/api/integrations/${integration && integration.id}/contacts/${this.state.form && this.state.form.id}/customer.json`)
				.then((result) => {
					if (result.data.error) {
						this.setState({ [loadingType]: false });
						toastr.error(result.data.error);
						return;
					}

					const form = Object.assign(result.data.customer, { id: integration.id });
					if (!this.state.form.integrationfields) {
						this.state.form.integrationfields = {
							[type]: form,
						};
					} else {
						this.state.form.integrationfields[type] = form;
					}
					this.state.form.orgnr = form.OrganisationNumber;
					this.state.form.email = form.Email;
					this.state.form.phone = form.Phone1;
					this.state.form.address1 = form.Address1;
					this.state.form.city = form.City;
					this.state.form.zip = form.ZipCode;
					this.setState({ [loadingType]: false, form: this.state.form });
				})
				.catch((error) => {
					console.error(error);
					this.setState({ [loadingType]: false });
				});
		}
	}

	async updateForm(field, value) {
		if (field === "orgnr") {
			this.abortController?.abort();
			this.setState({ duplicates: [] });
			if (value) {
				this.checkDuplicatesByOrgnrDebounced(value);
			}
		}

		if (field === "email" && !this.state.form.is_company) {
			this.emailAbortController?.abort();
			this.setState({ emailDuplicates: [] });
			if (value) {
				this.checkDuplicatesByEmailDebounced(value);
			}
		}

		if (field === "parent" && !value?.is_company && value.parent) {
			value = value.parent;
		}

		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	saveContact() {
		const form = Object.assign({}, this.state.form);

		this.setState({ saving: true });

		form.is_visitor = false;
		if (this.props.company) {
			form.parent_id = this.props.company.id;
		}
		if (this.state.form.is_company !== undefined) {
			form.is_company = this.state.form.is_company;
		}

		if (this.props.onCreateNoSave) {
			this.setState({ saving: false });
			this.props.onCreateNoSave(form);
			return;
		}

		if (this.state.form.id) {
			API.put("/api/contacts/" + this.state.form.id + ".json", form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					if (result.data.contact && result.data.contact.is_company) {
						toastr.success(this.props.t("contact.company.responses.updated", "Företag uppdaterades"));
					} else if (result.data.contact) {
						toastr.success(this.props.t("contact.person.responses.updated", "Kontaktperson uppdaterad"));
					}
					store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: result.data.contact || {} });

					if (result.data.contact.is_company && store.getState().board_contacts) {
						Object.values(store.getState().board_contacts).forEach((c) => {
							if (!c.is_company && c.parent && c.parent.id == result.data.contact.id) {
								c.parent = result.data.contact;
								store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact: c });
							}
						});
					}

					if (result.data.contact) this.setState({ form: result.data.contact });
					if (this.props.onUpdate) this.props.onUpdate(result.data.contact);
					if (this.props.onClose) this.props.onClose();
				})
				.catch((error) => {
					this.setState({ saving: false });
				});
		} else {
			API.post("/api/contacts.json", form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					if (result.data.contact && result.data.contact.is_company) {
						toastr.success(this.props.t("contact.company.responses.created", "Företag skapad"));
					} else if (result.data.contact) {
						toastr.success(this.props.t("contact.person.responses.created", "Kontaktperson skapad"));
					}
					if (this.props.onUpdate) this.props.onUpdate(result.data.contact);
					if (this.props.onCreate) {
						this.props.onCreate(result.data.contact);
					}
					if (this.props.onClose) this.props.onClose();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	addTag(tag) {
		if (this.state.form && !this.state.form.tags) {
			this.state.form.tags = [tag];
			this.setState({ form: this.state.form });
		}
		const found = this.state.form.tags.find((t) => t.title.toLowerCase() === tag.title.toLowerCase());

		if (!found) {
			this.state.form.tags.push(tag);
			this.setState({ form: this.state.form });
		}
	}

	removeTag(title) {
		const newTags = this.state.form.tags.filter((t) => t.title.toLowerCase() != title.toLowerCase());
		this.state.form.tags = newTags;
		this.setState({ form: this.state.form });
	}

	shouldBeDisabled(metafields, metafield) {
		const field = metafields.some((field) => field.title === "Kundnummer" && this.getMetafieldValue(field) && this.state.form.id);
		if (!field) return false;

		if (this.state.form.is_company && store.getState().account.id == 15 && this.state.form.id && (!metafield || metafield.title == "Kundnummer")) {
			return true;
		}

		return false;
	}

	getMetafieldValue(metafield) {
		return this.state.form?.metafields?.find((field) => field.metafield_id == metafield.id)?.value || (metafield.type == "boolean" ? false : "");
	}

	setMetafieldValue(metafield, value) {
		let found = false;
		for (let i = 0; i < this.state.form.metafields.length; i++) {
			if (this.state.form.metafields[i].metafield_id == metafield.id) {
				this.state.form.metafields[i].value = value;
				found = true;
				break;
			}
		}

		if (!found) {
			this.state.form.metafields.push({
				value,
				handle: metafield.handle,
				metafield_id: metafield.id,
			});
		}

		this.setState({ form: this.state.form });
	}

	removeContact() {
		this.setState({ removing: true });
		API.delete("/api/contacts/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });

				if (this.state.form.is_company) {
					toastr.success(this.props.t("contact.compamy.responses.removed", "Företag borttagen"));
				} else {
					toastr.success(this.props.t("contact.person.responses.removed", "Kontaktperson borttagen"));
				}
				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove(this.state.form);
				} else {
					if (this.state.form.is_company) {
						window.location.href = "/admin/contacts/companies";
					} else {
						window.location.href = "/admin/contacts/people";
					}
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	async checkDuplicatesByOrgnr(orgnr) {
		this.setState({ loadingDuplicates: true });
		this.abortController = new AbortController();
		const contacts = await this.checkDuplicates({ signal: this.abortController.signal, params: { company_number: orgnr } });
		this.setState({ loadingDuplicates: false, duplicates: contacts });
	}

	async checkDuplicatesByEmail(email) {
		this.setState({ loadingEmailDuplicates: true });
		this.emailAbortController = new AbortController();
		const contacts = await this.checkDuplicates({ signal: this.emailAbortController.signal, params: { email } });
		this.setState({ loadingEmailDuplicates: false, emailDuplicates: contacts });
	}

	async checkDuplicates(params) {
		const result = await API.get("/api/contacts.json", params)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				return result.data.contacts;
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// eslint-disable-next-line no-console
					console.debug("Reqeust is cancelled");
				}
				toastr.error(error);
			});

		return result;
	}

	missingRequiredFields() {
		const metafields = store.getState()?.contact_metafields?.filter?.((field) => {
			if (this.state.form.is_company || this.state.form.is_company) {
				if (field.for == "company") return true;
			} else {
				if (field.for == "person") return true;
			}
			return false;
		});

		return metafields.filter((field) => field.required && !this.getMetafieldValue(field)).map((field) => field.id);
	}

	openModal(field) {
		this.setState({ [field]: true });
	}

	checkMissingError(id, fields) {
		if (fields.includes(id)) {
			return this.props.t("contact.metafields.errors.missing", "Obligatorisk");
		}

		return null;
	}

	render() {
		const { selectedTabIndex } = this.state;
		const missingRequiredFields = this.missingRequiredFields();

		const metafields = [];
		for (let i = 0; i < store.getState()?.contact_metafields.length; i++) {
			if (this.state.form.is_company || this.state.form.is_company) {
				if (store.getState()?.contact_metafields[i].for == "company") {
					metafields.push(store.getState()?.contact_metafields[i]);
				}
			} else {
				if (store.getState()?.contact_metafields[i].for == "person") {
					metafields.push(store.getState()?.contact_metafields[i]);
				}
			}
		}

		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (
			this.state.form &&
			this.state.form.id &&
			!this.state.form.is_bolag &&
			store.getState().user.roles.indexOf("ROLE_ADMIN") &&
			!this.props.disabledRemove
		) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		const tabs = [
			{
				content: this.props.t("contact.tabs.basic_info", "Grunduppgifter"),
				render: (
					<FormLayout>
						{/* (((this.state.form.is_company) && (!this.state.form.bolag) ) ? <ResourcePicker
              caption={"Koppla till bolag"}
              fullWidth={false}
              item={null}
              resource="companies.json"
              resource_handle="companies"
              id_handle="id"
              label_handle="fullname"
              resourceName={{
                singular:'bolag',
                plural:'bolag',
              }}
              onChange={this.onChangeBolag.bind(this)}
              helpText="Koppla ihop med bolaget för att hämta information om omsättning och annat"
            />:null) */}

						{this.props.company || this.state.form.is_company ? null : (
							<ContactSelector
								label={this.props.t("contact.fields.parent.label", "Företag")}
								contact={this.state.form}
								onChange={this.updateForm.bind(this, "parent")}
								onCreateContact={this.updateForm.bind(this, "parent")}
								hidePerson
								fixed
							/>
						)}
						{this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.name.label", "Namn")}
								disabled={this.shouldBeDisabled(metafields, null)}
								onChange={this.updateForm.bind(this, "name")}
								value={this.state.form && this.state.form.name}
							/>
						) : null}
						{!this.state.form.is_company && (
							<Stack alignment="center" distribution="equalSpacing">
								<ProfileAvatar
									onClick={this.openModal.bind(this, "openUploadImage")}
									user={this.state.form}
									tooltip={this.props.t("contact.fields.profile.tooltip", "Ändra profil bild")}
									size={40}
								/>
								<Tooltip content={this.props.t("contact.fields.profile.tooltip", "Ändra profil bild")}>
									<Button onClick={this.openModal.bind(this, "openUploadImage")} icon={EditMajor}>
										{this.props.t("common.actions.change", "Ändra")}
									</Button>
								</Tooltip>
							</Stack>
						)}

						{!this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.first_name.label", "Förnamn")}
								onChange={this.updateForm.bind(this, "first_name")}
								value={this.state.form.first_name}
							/>
						) : null}

						{!this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.last_name.label", "Efternamn")}
								onChange={this.updateForm.bind(this, "last_name")}
								value={this.state.form.last_name}
							/>
						) : null}
						{!this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.title.label", "Befattning/Roll")}
								onChange={this.updateForm.bind(this, "title")}
								value={this.state.form.title}
							/>
						) : null}
						{this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.orgnr.label", "Org nr")}
								suffix={this.state.loadingDuplicates && <Spinner size="small" />}
								disabled={this.shouldBeDisabled(metafields, null)}
								onChange={this.updateForm.bind(this, "orgnr")}
								value={this.state.form.orgnr}
								error={
									!this.state.loadingDuplicates && !!this.state.duplicates?.length && this.props.t("contact.errors.exists", "Företag finns redan")
								}
							/>
						) : null}

						<TextField
							label={this.props.t("contact.fields.email.label", "E-post")}
							onChange={this.updateForm.bind(this, "email")}
							value={this.state.form.email}
							suffix={this.state.loadingEmailDuplicates && <Spinner size="small" />}
							error={
								!this.state.loadingEmailDuplicates &&
								!!this.state.emailDuplicates?.length &&
								this.props.t("contact.errors.contact_exists", "Kontakt finns redan")
							}
						/>
						<TextField
							label={this.props.t("contact.fields.phone.label", "Telefonnummer")}
							disabled={this.shouldBeDisabled(metafields, null)}
							onChange={this.updateForm.bind(this, "phone")}
							value={this.state.form.phone}
						/>
						{!this.state.form.is_company ? (
							<TextField
								label={this.props.t("contact.fields.mobile_phone.label", "Mobilnummer")}
								disabled={this.shouldBeDisabled(metafields, null)}
								onChange={this.updateForm.bind(this, "mobile_phone")}
								value={this.state.form.mobile_phone}
							/>
						) : null}

						<TextField
							label={this.props.t("contact.fields.customer_number.label", "Kundnummer {{extrInfo}}", {
								extrInfo: this.state.form?.fortnox_customer_id ? `(Fortnox)` : "",
							})}
							disabled={!!this.props.contact?.fortnox_customer_id}
							onChange={this.updateForm.bind(this, "customer_number")}
							value={this.state.form.customer_number}
						/>
					</FormLayout>
				),
			},
			{
				content: this.props.t("contact.tabs.laddress_info", "Adressuppgifter"),
				enabled: this.state.form.is_company,
				render: (
					<FormLayout>
						<Subheading>{this.props.t("contact.titles.visitor_address", "Besöksadress")}</Subheading>
						{this.state.form.is_company ? (
							<Select
								label={this.props.t("contact.fields.country.label", "Land")}
								options={store.getState().countries}
								onChange={this.updateForm.bind(this, "country")}
								value={this.state.form.country}
							/>
						) : null}
						<TextField
							label={this.props.t("contact.fields.address1.label", "Gatuadress")}
							onChange={this.updateForm.bind(this, "address1")}
							disabled={this.shouldBeDisabled(metafields, null)}
							value={this.state.form.address1}
						/>
						<TextField
							label={this.props.t("contact.fields.address2.label", "CO/adress och annat")}
							onChange={this.updateForm.bind(this, "address2")}
							disabled={this.shouldBeDisabled(metafields, null)}
							value={this.state.form.address2}
						/>
						<Stack distribution="fillEvenly">
							<TextField
								label={this.props.t("contact.fields.zip.label", "Postnummer")}
								onChange={this.updateForm.bind(this, "zip")}
								disabled={this.shouldBeDisabled(metafields, null)}
								value={this.state.form.zip}
							/>
							<TextField
								label={this.props.t("contact.fields.city.label", "Ort")}
								onChange={this.updateForm.bind(this, "city")}
								disabled={this.shouldBeDisabled(metafields, null)}
								value={this.state.form.city}
							/>
						</Stack>
						<Checkbox
							label={this.props.t("contact.fields.use_postals.label", "Har särskild utdelningsadress")}
							checked={this.state.form.use_postal}
							onChange={this.updateForm.bind(this, "use_postal")}
						/>
						{this.state.form.use_postal ? (
							<TextField
								label={this.props.t("contact.fields.postal_address1.label", "Adress för post")}
								onChange={this.updateForm.bind(this, "postal_address1")}
								disabled={this.shouldBeDisabled(metafields, null)}
								value={this.state.form.postal_address1}
							/>
						) : null}
						{this.state.form.use_postal ? (
							<TextField
								label={this.props.t("contact.fields.postal_address2.label", "CO/adress och annat")}
								onChange={this.updateForm.bind(this, "postal_address2")}
								disabled={this.shouldBeDisabled(metafields, null)}
								value={this.state.form.postal_address2}
							/>
						) : null}
						{this.state.form.use_postal ? (
							<Stack distribution="fillEvenly">
								<TextField
									label={this.props.t("contact.fields.postal_zip.label", "Postnummer")}
									onChange={this.updateForm.bind(this, "postal_zip")}
									disabled={this.shouldBeDisabled(metafields, null)}
									value={this.state.form.postal_zip}
								/>
								<TextField
									label={this.props.t("contact.fields.postal_city.label", "Ort")}
									onChange={this.updateForm.bind(this, "postal_city")}
									disabled={this.shouldBeDisabled(metafields, null)}
									value={this.state.form.postal_city}
								/>
							</Stack>
						) : null}
					</FormLayout>
				),
			},
			{
				content: (
					<span style={{ display: "flex", gap: "0.5rem" }}>
						{this.props.t("contact.tabs.other_info", "Övriga uppgifter")}
						{!!missingRequiredFields?.length && (
							<span className="contact_tab__error_icon">
								<Tooltip content={this.props.t("contact.text.missing_required_fields", "Fyll i alla obligatoriska fällt")}>
									<Icon source={InfoMinor} />
								</Tooltip>
							</span>
						)}
					</span>
				),
				render: (
					<FormLayout>
						<TextField
							label={this.props.t("contact.fields.reference.label", "Eget id")}
							value={this.state.form.reference}
							onChange={this.updateForm.bind(this, "reference")}
							multiline
						/>
						<TextField
							label={this.props.t("contact.fields.note.label", "Anteckning")}
							value={this.state.form.note}
							onChange={this.updateForm.bind(this, "note")}
							multiline
						/>
						<SearchField
							label={this.props.t("contact.fields.tags.label", "Taggar")}
							resource="contacts/tags"
							onSelect={this.addTag.bind(this)}
							tags={
								store.getState() && store.getState().user && store.getState().user.roles && store.getState().user.roles.includes("ROLE_CREATE_TAG")
							}
							resource_handle="tags"
							id_handle="title"
							params={{
								type: this.state.form.is_company === undefined ? null : this.state.form.is_company ? "company" : "person",
								group_by: "title",
								sort: "TITLE_ASC",
							}}
						/>
						<div style={{ paddingTop: 10 }}>
							<Stack>
								{(this.state.form.tags || []).map((tag, index) => (
									<Tag key={index} onRemove={this.removeTag.bind(this, tag.title)}>
										{tag.title}
									</Tag>
								))}
							</Stack>
						</div>
						{metafields &&
							metafields.map((metafield) => {
								if (metafield.type == "input") {
									return (
										<TextField
											key={metafield.id}
											label={`${metafield.title}${metafield.required ? " *" : ""}`}
											disabled={this.shouldBeDisabled(metafields, metafield)}
											value={this.getMetafieldValue(metafield)}
											onChange={this.setMetafieldValue.bind(this, metafield)}
											multiline
											error={this.checkMissingError(metafield.id, missingRequiredFields)}
										/>
									);
								} else if (metafield.type == "boolean") {
									return (
										<Checkbox
											key={metafield.id}
											label={`${metafield.title}${metafield.required ? " *" : ""}`}
											checked={this.getMetafieldValue(metafield)}
											onChange={this.setMetafieldValue.bind(this, metafield)}
											error={this.checkMissingError(metafield.id, missingRequiredFields)}
										/>
									);
								} else if (metafield.type == "options") {
									const options = [{ value: "", label: this.props.t("contact.fields.empty.label", "Inget val") }].concat(metafield.options || []);

									// for (let i = 0; i < metafield.options.length; i++) {
									// 	options.push(metafield.options[i]);
									// }
									return (
										<Select
											key={metafield.id}
											label={`${metafield.title}${metafield.required ? " *" : ""}`}
											options={options}
											onChange={this.setMetafieldValue.bind(this, metafield)}
											value={this.getMetafieldValue(metafield)}
											error={this.checkMissingError(metafield.id, missingRequiredFields)}
										/>
									);
								}
								return null;
							})}
					</FormLayout>
				),
			},
		];

		if (this.state.form.integrations) {
			for (let i = 0; i < this.state.form.integrations.length; i++) {
				const contactIntegration = this.state.form.integrations[i];
				const integration = store.getState().account.integrations.find((i) => i.id == contactIntegration.integration_id);

				if (integration && (integration.type == "fortnox" || integration.type == "fortnox_invoice")) {
					tabs.push({
						content: integration.title,
						render: (
							<ContactFortnoxContent
								integrationForm={(this.state.form.integrationfields && this.state.form.integrationfields.fortnox) || {}}
								updateForm={(form) => {
									if (!this.state.form.integrationfields) {
										this.state.form.integrationfields = {
											fortnox: form,
										};
									} else {
										this.state.form.integrationfields.fortnox = form;
									}
									this.setState({ form: this.state.form });
								}}
								integration={integration}
								contact={this.state.form}
								loading={this.state.loading_fortnox}
							/>
						),
					});
				}
			}
		}

		const finallTabs = tabs
			.filter((i) => !("enabled" in i) || i.enabled)
			.map((i, index) => Object.assign(i, { id: i.content, accessibilityLabel: i.content, panelID: `${i.content}-${index}` }));

		const activeTab = finallTabs[selectedTabIndex];
		const content = (
			<div>
				<Tabs tabs={finallTabs} selected={selectedTabIndex} onSelect={(v) => this.setState({ selectedTabIndex: v })} />
				{this.state.form && this.state.form.is_visitor && (
					<Banner status="info" title={this.props.t("contact.visitor.title", "Ej importerad")} style={{ margin: "2rem 2rem 0 2rem" }} dismissible>
						<p>{this.props.t("contact.visitor.text1", "Denna kontakt är ej importerad i crm:et.")}</p>
						<p>{this.props.t("contact.visitor.text3", "Spara för att importera kontakten.")}</p>
					</Banner>
				)}
				<Modal.Section>{activeTab && (typeof activeTab.render === "function" ? activeTab.render() : activeTab.render)}</Modal.Section>
			</div>
		);

		return (
			<React.Fragment>
				{this.props.nomodal ? (
					content
				) : (
					<Modal
						open={this.props.open}
						onClose={this.props.onClose}
						title={
							this.props.asd ||
							(this.state.form.id
								? this.state.form.name
								: this.state.form.is_company
								? this.props.t("contact.titles.new_company", "Nytt företag")
								: this.props.t("contact.titles.new_person", "Ny kontaktperson"))
						}
						secondaryActions={secondaryActions}
						primaryAction={{
							content: this.props.t("common.actions.save", "Spara"),
							disabled: !!missingRequiredFields?.length,
							loading: this.state.saving,
							onAction: this.saveContact.bind(this),
						}}
					>
						{content}
					</Modal>
				)}

				{!this.state.form.is_company && (
					<UploadModal
						open={this.state.openUploadImage}
						onClose={() => {
							this.setState({ openUploadImage: false });
						}}
						onSelect={(imgs) => {
							if (imgs[0] && imgs[0].url) {
								this.state.form.avatar = imgs[0].url;
								this.setState({ form: this.state.form, openUploadImage: false });
							}
						}}
						allowMultiple={false}
						disabledToast
					/>
				)}
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.removeContact.bind(this)}
					numItems={1}
					objectName={this.state.form.name}
					resourceName={
						this.state.form.is_company
							? {
									singular: this.props.t("contact.company.singular", "företag"),
									plural: this.props.t("contact.company.plural", "företag"),
							  }
							: {
									singular: this.props.t("contact.person.singular", "kontaktperson"),
									plural: this.props.t("contact.person.plural", "kontaktpersoner"),
							  }
					}
				/>
			</React.Fragment>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />
export default withTranslation(["contact", "common"], { withRef: true })(ContactModal);
