import { Icon, Popover } from "@shopify/polaris";
import type { IconSource } from "@shopify/polaris";
import { QuestionMarkInverseMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import styled from "styled-components";
import type { StatsBoxType } from "./types";
import Spinner from "../Spinner";
import Colors, { colorToRgba } from "src/js/Colors";

const StatsBox = ({ onClick, item, children }: StatsBoxType) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const onCloseTooltip = () => {
		setTooltipOpen(false);
	};

	const onOpenTooltip = () => {
		setTooltipOpen(true);
	};

	const { className = "", icon, title, text, link, helpText, loading } = item || {};

	return (
		<Wrapper>
			{helpText && (
				<Popover
					active={tooltipOpen}
					onClose={onCloseTooltip}
					activator={
						<HelpIcon onClick={onOpenTooltip}>
							<Icon source={QuestionMarkInverseMajor} />
						</HelpIcon>
					}
				>
					<Popover.Section>
						<p>{helpText}</p>
					</Popover.Section>
				</Popover>
			)}
			<InnerWrapper className={`statsBox ${className} ${onClick ? "button" : ""}`} data-title={title} onClick={onClick as (() => void) | undefined}>
				{icon && (
					<InnerIcon>
						{loading && (
							<SpinnerWrapper>
								<Spinner size="small" />
							</SpinnerWrapper>
						)}
						{React.isValidElement(icon) ? icon : <Icon source={icon as IconSource} />}
					</InnerIcon>
				)}
				<InnerContent icon={!!icon}>
					<InnerContentTitle>
						{title || (!loading && "—")}
						{/* {loading && <Spinner size="small" style={{ marginRight: "0.5rem" }} />} */}
					</InnerContentTitle>
					<InnerContentText>{text}</InnerContentText>
					{link && <InnerContentLink onClick={link.onAction}>{link.content}</InnerContentLink>}
					{children}
				</InnerContent>
			</InnerWrapper>
		</Wrapper>
	);
};
export default StatsBox;

const Wrapper = styled.div`
	position: relative;
	transition: all 250ms;
`;

const HelpIcon = styled.span`
	position: absolute;
	right: 10px;
	top: 10px;
	cursor: pointer;

	.Polaris-Icon {
		width: 1.5rem;
		height: 1.5rem;

		svg {
			fill: var(--color-primary);
		}
	}
`;
const InnerContentLink = styled.a`
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	display: none;
`;

const InnerIcon = styled.div`
	position: relative;
	svg {
		width: 22px;
		height: 22px;
	}
`;
const InnerContent = styled.div<{ icon: boolean }>`
	color: var(--textColor);
	/* width: calc(100% - 50px - 1.5rem); */
	width: ${({ icon }) => (icon ? "calc(100% - 50px - 1.5rem)" : "calc(100% - 1.5rem)")};
`;
const InnerContentTitle = styled.h1`
	&& {
		font-weight: 600;
		font-size: 15px;
		text-overflow: ellipsis;
		/* white-space: pre; */
		overflow: hidden;
		width: 100%;

		/* align-items: center;
		display: flex;
		gap: 3px;
		min-height: 25px; */
	}
`;
const InnerContentText = styled.span`
	opacity: 0.75;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;

	/* overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical; */
`;

const InnerWrapper = styled.div`
	display: flex;
	gap: 1.5rem;
	align-items: center;
	background-color: var(--cardbg);
	border-radius: 0.7rem;
	box-shadow: var(--boxShadow);
	transition: all 250ms;
	padding: 1.5rem;

	height: 100%;

	&.button {
		cursor: pointer;

		&:hover {
			filter: brightness(1.2);
		}
	}

	&:hover {
		${InnerContentLink} {
			display: block;
		}
	}
`;

const SpinnerWrapper = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	border-radius: 50%;
	background-color: ${colorToRgba(Colors.main2, 0.8)};

	.Polaris-Spinner {
		opacity: 1;
		display: flex;
	}
`;
