import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Select, Spinner, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import moment from "moment";
import Page from "src/js/components/page";
import API from "../../API";
import { toastr } from "../../components/toastr";
import Entry from "./Entry";
import NumberBadge from "../../components/NumberBadge";
import Colors from "../../Colors";
import EntrySerchfield from "./EntrySerchfield";
import { store } from "../../store/index.js";

const EditLanguage = (props) => {
	const [form, setForm] = useState({});
	const [originalTitle, setOriginalTitle] = useState();
	const [search, setSearch] = useState();
	const [entries, setEntries] = useState({});
	const [originalEntries, setOriginalEntries] = useState({});
	const [loading, setLoading] = useState();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loadingEntries, setLoadingEntries] = useState();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [loadingOriginalEntries, setLoadingOriginalEntries] = useState();
	const [collapsed, setCollapsed] = useState({});
	const [filter, setFilter] = useState("all");

	useEffect(() => {
		const fetch = (id = props.match.params.id) => {
			setLoading(true);
			API.get(`/api/translations/${id}.json`)
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					setForm(result.data.translation);
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				})
				.finally(() => {
					setLoading(false);
				});
		};

		const fetchEntries = (id = props.match.params.id) => {
			setLoadingEntries(true);
			API.get("/api/translations/entries.json", { params: { lng: id } })
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					setEntries(result.data);
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				})
				.finally(() => {
					setLoadingEntries(false);
				});
		};

		const fetchOriginalEntries = (id) => {
			setLoadingOriginalEntries(true);
			API.get("/api/translations/entries.json", { params: { lng: id } })
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					setOriginalEntries(result.data);
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				})
				.finally(() => {
					setLoadingOriginalEntries(true);
				});
		};

		fetch();
		fetchEntries();
		fetchOriginalEntries("sv");
	}, [props.match.params.id]);

	const onSubmit = () => {
		if (!form?.locale || !form?.title) {
			toastr.error(props.t("common.language.errors.required_title_locale", "Måste ha ett namn"));
			return;
		}
		API.put(`/api/translations/${form?.locale}.json`, { ...form, user_id: store.getState().user.id })
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				setForm(result.data.translation);
				setOriginalTitle(result.data.translation?.title);
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	};

	const onChange = (field, value) => {
		setForm((c) => ({ form: { ...c, [field]: value } }));
	};
	const onChangeTitle = useCallback((v) => onChange("title", v), []);

	const toggleCollapsible = (namespace) => {
		setCollapsed((c) => ({ ...c, [namespace]: !c[namespace] }));
	};

	const onSubmitEntry = useCallback(
		(entry) => {
			const handle = entry.namespace + ":" + entry.handle;

			setEntries((c) => {
				return { ...c, [handle]: entry.value };
			});

			API.put(`/api/translations/${form?.locale}/entries.json`, { ...entry, user_id: store.getState().user.id })
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					const handle = entry.namespace + ":" + entry.handle;

					setEntries((c) => {
						return { ...c, [handle]: result.data.entry.value };
					});

					toastr.success("Översättning uppdaterad");
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
				});
		},
		[form?.locale]
	);

	const namespaces = useMemo(() => {
		const namespaces = Object.entries(originalEntries).reduce((acc, [key, value]) => {
			const [namespace, handle] = key.split(":", 2);
			const entry = {
				namespace,
				handle,
				originalValue: value,
				exists: !!entries[key],
				invalid: !entries[key] || value === entries[key],
				value: entries[key] ?? value,
			};
			return {
				...acc,
				[entry.namespace]: [...(acc[entry.namespace] || []), entry],
			};
		}, {});

		return Object.entries(namespaces).map(([namespace, entries], index) => {
			if (search || filter) {
				const filterRegex = new RegExp(search || "", "i");
				entries = entries.filter((entry) => {
					const filterMatch = (() => {
						if (!filter || filter === "all") return true;
						if (filter === "missing") return entry.invalid;
						if (filter === "translated") return entry.exists;
					})();

					return (
						(!search || entry.originalValue.match(filterRegex) || entry.value.match(filterRegex) || entry.handle.match(filterRegex)) && filterMatch
					);
				});

				if (entries.length < 1) {
					return null;
				}
			}

			const completedEntries = entries?.filter((entry) => entry.exists).length;
			const existingEntries = entries?.length;
			return (
				<Wrapper key={namespace}>
					<CollapsibleTrigger disabled={!!search} aria-expanded={collapsed[namespace]} onClick={() => toggleCollapsible(namespace)}>
						<span>{namespace}</span>
						<NumberBadge color={completedEntries == 0 ? Colors.red : completedEntries === existingEntries ? Colors.green : Colors.blue}>
							{completedEntries} / {existingEntries}
						</NumberBadge>
					</CollapsibleTrigger>
					{(collapsed[namespace] || search) && (
						<div>
							{entries.map((entry) => (
								<Entry key={entry.handle} entry={entry} onSubmitEntry={onSubmitEntry} />
							))}
						</div>
					)}
				</Wrapper>
			);
		});
	}, [originalEntries, entries, collapsed, onSubmitEntry, search, filter]);

	const count = (entries && Object.keys(entries).length) || 0;
	const originalCount = (originalEntries && Object.keys(originalEntries).length) || 0;
	return (
		<Page
			breadcrumbs={[{ content: props.t("common.actions.back", "Tillbaka"), onAction: () => props.history.goBack() }]}
			title={!loading && `${originalTitle || form?.title} - ${form?.locale}`}
			titleMetadata={form?.count && <NumberBadge>{form?.count}</NumberBadge>}
		>
			{form?.locale && (
				<div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
					<NumberBadge color={count === originalCount ? Colors.green : Colors.yellow}>{(entries && Object.keys(entries).length) || 0}</NumberBadge>

					<span>
						{props.t("common.terms.created", "Skapad")}: {moment(form?.created_at).format("YYYY-MM-DD")}
					</span>
					<span>
						{props.t("common.terms.updated", "Uppdaterad")}: {moment(form?.updated_at).format("YYYY-MM-DD")}
					</span>

					<FlexItem>
						<ColorBox color={Colors.red} />
						<span>Saknar översättning</span>
					</FlexItem>
					<FlexItem>
						<ColorBox color={Colors.yellow} />
						<span>Använder orginal ifrån koden</span>
					</FlexItem>
				</div>
			)}
			{loading ? (
				<Spinner />
			) : (
				<>
					<div style={{ marginBottom: "4rem" }}>
						<TextField
							label={props.t("common.language.fields.title.label", "Titel")}
							placeholder={props.t("common.language.fields.title.placeholder", "Titel...")}
							value={form?.title}
							onChange={onChangeTitle}
							onBlur={onSubmit}
						/>
					</div>

					<div style={{ marginBottom: "4rem" }}>
						<EntrySerchfield
							onChange={setSearch}
							connectedRight={
								<Select
									value={filter}
									onChange={(v) => setFilter(v)}
									options={[
										{ label: "Alla", value: "all" },
										{ label: "Saknar översätning", value: "missing" },
										{ label: "Har översättning", value: "translated" },
									]}
								/>
							}
						/>
					</div>

					{namespaces}
				</>
			)}
		</Page>
	);
};
export default withTranslation(["common"])(EditLanguage);

const Wrapper = styled.div`
	margin-block: 1rem;
`;

const CollapsibleTrigger = styled.button`
	font-weight: bold;

	width: 100%;
	padding: 1rem;
	background: var(--main3);
	border: none;
	color: var(--textColor);
	cursor: pointer;
	transition: filter 250ms;
	justify-content: space-between;
	display: flex;

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
		`}

	&:hover {
		filter: brightness(1.2);
	}
`;

export const ColorBox = styled.div`
	min-height: 10px;
	width: 1rem;
	min-width: 1rem;
	max-width: 1rem;
	background: ${({ color }) => color};
	border-radius: 3px;
	/* height: 100%; */
`;

const FlexItem = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
`;
