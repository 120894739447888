import { Collapsible, Icon, Label } from "@shopify/polaris";
import React, { useState } from "react";
import Popover from "src/js/components/Popover";
import TextField from "src/js/components/TextField/TextField";
import styled from "styled-components";
import { SelectMinor } from "@shopify/polaris-icons";

const SelectOptions = ({ value, label, options, onChange }) => {
	const [open, setOpen] = useState(false);
	const [searchValue, setSearchValue] = useState("");

	const handleToggle = (e) => {
		// e.preventDefault();
		// e.stopPropagation();

		setOpen(!open);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// const getValuesAndLabels = (options) => {
	// 	if (!options) return [];
	// 	if (!options.options) return [options.label, options.value];
	// 	return options?.options.flatMap((opt) => getValuesAndLabels(opt));
	// };

	const getValuesObject = (opt) => {
		if (!opt) return [];
		if (!opt.options) return [opt];
		return opt?.options?.flatMap((opt) => getValuesObject(opt)) || [];
	};

	const handleSelect = (value) => {
		onChange(value);
		handleClose();
	};

	const opts = options?.flatMap((opt) => getValuesObject(opt)) || [];
	const selected = opts.find((opt) => opt.value == value);

	// const renderOptions = (options) => {
	// 	if (!options) return null;
	// 	if (!options.options && options.value === value) {
	// 		return options.map((opt) => {
	// 			return (
	// 				<Item onClick={() => handleSelect(opt.value)} key={opt.label}>
	// 					{opt.label}
	// 				</Item>
	// 			);
	// 		});
	// 	}
	// 	return options.map((opt) => {
	// 		return renderOptions(opt.options);
	// 	});
	// };
	// const optionsSections = options.map((opt) => {
	// 	if (value && value === opt.value && opt.options) {
	// 		return renderOptions(opt.options);
	// 	}

	// 	return (
	// 		<Item onClick={() => handleSelect(opt.value)} key={opt.label}>
	// 			{opt.label}
	// 		</Item>
	// 	);
	// });

	return (
		<Popover
			fullWidth
			preferredPosition="below"
			open={open}
			onClose={handleClose}
			activator={
				<>
					{label && <Label id="slecetoptios">{label}</Label>}
					<SelectActivator onClick={handleToggle}>
						{selected?.label || "Välj"}

						<Icon source={SelectMinor} />
					</SelectActivator>
				</>
			}
		>
			<div
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
				}}
			>
				<TextField autoFocus value={searchValue} onChange={setSearchValue} placeholder="Search" />
				{options
					.filter((opt) => {
						// const values = getValuesAndLabels(opt);
						return opt.label.toLowerCase().includes(searchValue.toLowerCase()) || opt.value.toLowerCase().includes(searchValue.toLowerCase());
					})
					?.map((opt, index) => {
						return (
							<Item onClick={() => handleSelect(opt.value)} key={`${index}-${opt.value || opt.label}`}>
								{opt.label}
							</Item>
						);
					})}

				{}
			</div>
		</Popover>
	);
};
export default SelectOptions;

const Item = styled.div`
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: var(--main2);
	}
`;

const SectionTitle = styled.p`
	font-size: 14px;
	font-weight: 700;
	margin: 0;
	padding: 5px 10px;
	cursor: pointer;
`;

const StyledSection = styled.div<{ lvl: number }>`
	&& {
		border-bottom: 1px solid var(--main3);
		/* padding-left: ${({ lvl }) => lvl * 15}px; */

		& > ${Item}, & > ${SectionTitle} {
			padding-left: ${({ lvl }) => 10 + lvl * 15}px;
		}
	}
`;

const SelectActivator = styled.div`
	background-color: var(--modals-input-bg) !important;
	border-radius: 0.5rem;
	height: 34px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	justify-content: space-between;

	.Polaris-Icon {
		margin: 0;
	}
`;

const Section = ({ item, lvl = 1, handleSelect }) => {
	const { title, options } = item;
	const [collapsed, setCollapsed] = useState(false);

	return (
		<>
			<SectionTitle onClick={() => setCollapsed(!collapsed)}>{title}</SectionTitle>
			<Collapsible id={title} open={!collapsed}>
				<StyledSection lvl={lvl}>
					{options.map((opt, index) =>
						opt?.options ? (
							<Section lvl={lvl + 1} key={index} item={opt} handleSelect={handleSelect} />
						) : (
							<Item onClick={() => handleSelect(opt.value)} key={index}>
								{opt.label}
							</Item>
						)
					)}
				</StyledSection>
			</Collapsible>
		</>
	);
};
