import React, { useCallback } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CardList from "src/js/components/CardList";
import ItemCard from "src/js/components/ItemCard";
import Page from "src/js/components/page";
import { format } from "../Utilities/formatRow";

type OrdersListProps = {
	history: any;
	noPage?: boolean;
	additionalAction?: any;
	params?: any;
} & WithTranslation;

const OrdersList = ({ history, noPage, additionalAction, t, params }: OrdersListProps) => {
	const ref = React.useRef<any>();

	const renderItem = useCallback(
		(item: OrderType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/orders/${item.id}`, {
							data: item,
						});
					}}
					title={"Order #" + item.order_number}
					leftColumn={[
						{
							label: t("orders.fields.order_date.label", "Orderdatum"),
							value: item.order_date,
						},
						{
							label: t("orders.fields.customer_fields.customer.name.label", "Kund"),
							value: item.customer_fields?.customer?.name,
						},
						{
							label: t("orders.fields.customer_fields.name.label", "Kund namn"),
							value: item.customer_fields?.name,
						},
						{
							label: t("orders.fields.our_reference.label", "Vår referens"),
							value: item.our_reference,
						},
						{
							label: t("orders.fields.your_reference.label", "Er referens"),
							value: item.your_reference,
						},
						{
							label: t("orders.fields.total_excluding_vat	614.label", "Totalt exkl. moms"),
							value: format(item.total_excluding_vat as number, false),
						},
						{
							label: t("orders.fields.total_including_vat.label", "Totalt"),
							value: format(item.total_including_vat as number, false),
						},
						{
							label: t("orders.fields.total.label", "Moms"),
							value: format(item.total_vat as number, false),
						},
					]}
					rightColumn={[
						{
							label: t("orders.fields.your_order_number.label", "Ert order nummer"),
							value: item.your_order_number,
						},
						{
							label: t("orders.fields.created_at.label", "Skapad"),
							value: item.created_at,
						},
						{
							label: t("orders.fields.created_by_user.label", "Skapad av"),
							value: item.created_by_user?.name,
						},
					]}
				/>
			);
		},
		[history, t]
	);

	const content = (
		<CardList
			ref={ref}
			resourceUrl="/api/orders.json"
			resourceName={{
				singular: t("orders.singular", "Order"),
				plural: t("orders.plural", "Ordrar"),
			}}
			params={params}
			resourceHandle="orders"
			renderItem={renderItem}
			additionalAction={additionalAction}
		/>
	);

	if (noPage) {
		return content;
	}

	return (
		<Page
			title={t("orders.title", "Orders") as string}
			history={history}
			primaryAction={{
				content: t("orders.new", "Ny order") as string,
				url: "/admin/orders/create",
			}}
		>
			{content}
		</Page>
	);
};
export default withTranslation(["orders", "common"])(OrdersList);
