import React, { Component } from "react";
import { PageActions, Layout, Card, FormLayout, TextField, InlineError, Stack, Button, Tooltip, Select, TextStyle } from "@shopify/polaris";
import { DeleteMajor, EditMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Page from "src/js/components/page";
import { fetchAndSetTokens } from "src/js/API/API";
import ReactQuill from "react-quill";
import styled from "styled-components";
import API from "../../API";
import { store } from "../../store";

import { toastr } from "../../components/toastr.js";
import ProfileAvatar from "../../components/ProfileAvatar.js";
import UploadModal from "../../components/UploadModal.js";
import SkeletonProvider from "../../components/skeleton_provider.js";
import EmailSignatureModal from "./EmailSignatureModal";
import Switch from "src/js/components/Switch";

class MyPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: store.getState().user.id,
			loading: true,
			saving: false,
			form: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (store.getState().user.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
		this.fetchLanguages();
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/me.json")
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
				}

				this.setState({ form: result.data.user, showRemove: result.data.user?.avatar });
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false, saving: false });
			});
	}

	fetchLanguages() {
		API.get("/api/translations.json")
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
				}

				this.setState({
					localeOptions: [
						{
							label: this.props.t("user.me.sections.language.fields.locale.options.choose.label", "Välj språk"),
							value: "",
							disabled: true,
						},

						...result.data.languages.map((language) => {
							return {
								label: this.props.t("common.locale." + language.locale + ".label", language.title),
								value: language.locale,
							};
						}),
					],
				});
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	async saveForm() {
		this.setState({ saving: true });
		const form = { ...store.getState().user };
		const result = await API.put("/api/me.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false, removing: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const user = { ...store.getState().user, ...result.data.user };
				this.setState({ form: user, showRemove: result.data.user?.avatar });
				store.dispatch({ type: "SET_USER", user });
				toastr.success(this.props.t("user.me.responses.updated", "Uppdaterade dina uppgifter"));

				if (form.locale !== result.data.user?.locale) {
					// window.location.href = encodeURI("/admin/authentication/login?error_msg=Login for language change to take effect");
					// const refreshToken = store.getState().refresh_token || getSessionStorage("refresh_token") || getLocalStorage("refresh_token");

					this.props.i18n.changeLanguage(user?.locale, () => {
						// eslint-disable-next-line no-console
						console.log(`Changing language to "${user?.locale}" from userpage.`);
					});

					return fetchAndSetTokens().catch((error) => console.error(error));
				}
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ saving: false });
				toastr.error(error);
			});

		return result;
	}

	openModal(field) {
		this.setState({ [field]: true });
	}

	closeModal(field) {
		this.setState({ [field]: false });
	}

	removeProfileImage() {
		this.state.form.avatar = "";
		this.setState({ form: this.state.form, removing: true, openUploadImage: false });
		this.saveForm();
	}

	openEmailSignatureModal() {
		this.setState({ openEmailSignature: true });
	}

	closeEmailSignatureModal() {
		this.setState({ openEmailSignature: false });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonProvider />;
		}
		const newPasswordInvalid = this.state.form.password && this.state.form.password2 && this.state.form.password !== this.state.form.password2;

		return (
			<Page
				title={this.props.t("user.me.title", "Mina sidor")}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					disabled: !this.state.form.email,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Layout>
					<Layout.AnnotatedSection title={this.props.t("user.me.sections.details.title", "Mina uppgifter")} description="">
						<Card title={this.props.t("user.me.sections.details.card.title", "Uppgifter")}>
							<Card.Section>
								<FormLayout>
									<Stack alignment="center" distribution="equalSpacing">
										<ProfileAvatar
											onClick={this.openModal.bind(this, "openUploadImage")}
											user={this.state.form}
											tooltip={this.props.t("user.me.fields.profile.tooltip", "Ändra profil bild")}
											size={50}
										/>
										<Tooltip content={this.props.t("user.me.fields.profile.tooltip", "Ändra profil bild")}>
											<Button onClick={this.openModal.bind(this, "openUploadImage")} icon={EditMajor}>
												{this.props.t("common.actions.change", "Ändra")}
											</Button>
										</Tooltip>
									</Stack>
									<TextField
										label={this.props.t("user.me.fields.name.label", "För- och efternamn")}
										value={this.state.form.name}
										onChange={this.updateForm.bind(this, "name")}
									/>
									<TextField
										label={this.props.t("user.me.fields.email.label", "E-mail")}
										value={this.state.form.email}
										onChange={this.updateForm.bind(this, "email")}
									/>
									<TextField
										label={this.props.t("user.me.fields.phone.label", "Mobilnummer")}
										value={this.state.form.phone}
										onChange={this.updateForm.bind(this, "phone")}
									/>
									<TextField
										type="password"
										label={this.props.t("user.me.fields.old_password.label", "Nuvarade lösenord")}
										value={this.state.form.old_password}
										onChange={this.updateForm.bind(this, "old_password")}
									/>
									<TextField
										type="password"
										label={this.props.t("user.me.fields.password.label", "Nytt lösenord")}
										value={this.state.form.password}
										onChange={this.updateForm.bind(this, "password")}
										error={newPasswordInvalid}
									/>
									{newPasswordInvalid && (
										<InlineError message={this.props.t("user.me.fields.password.error", "Password do not match")} fieldID="passwordField" />
									)}
									<TextField
										type="password"
										label={this.props.t("user.me.fields.password2.label", "Nytt lösenord igen")}
										value={this.state.form.password2}
										onChange={this.updateForm.bind(this, "password2")}
										error={newPasswordInvalid}
									/>
									{newPasswordInvalid && (
										<InlineError message={this.props.t("user.me.fields.password.error", "Password do not match")} fieldID="password2Field" />
									)}
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={this.props.t("user.me.sections.email_signature.title1", "E-postsignatur")}
						description={this.props.t(
							"user.me.sections.email_signature.description2",
							"Sätt din E-postsignatur som kommer användas i systemet när du skickar epost ifrån en kund"
						)}
					>
						<Card
							title={this.props.t("user.me.sections.email_signature.card.title1", "E-postsignatur")}
							actions={[
								{
									content: this.state.form.email_signature
										? this.props.t("common.actions.edit", "Redigera")
										: this.props.t("common.actions.add", "Lägg till"),
									onClick: this.openEmailSignatureModal.bind(this),
								},
							]}
						>
							<Card.Section>
								<FormLayout>
									{this.state.form.email_signature && (
										<SignatureWrapper onClick={this.openEmailSignatureModal.bind(this)}>
											<ReactQuill className="slim" modules={{ toolbar: false }} value={this.state.form.email_signature} theme="snow" readOnly />
										</SignatureWrapper>
									)}
									{!this.state.form.email_signature && (
										<Button primary onClick={this.openEmailSignatureModal.bind(this)}>
											{this.props.t("common.actions.add", "Lägg till")}
										</Button>
									)}
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={this.props.t("user.me.sections.language.title", "Språk")}
						description={this.props.t("user.me.sections.language.description2", "Välj ditt föredragna språk som kommer användas i systemet")}
					>
						<Card title={this.props.t("user.me.sections.language.card.title", "Språk")}>
							<Card.Section>
								<FormLayout>
									<Select
										label={this.props.t("user.me.fields.language.label", "Språk")}
										options={this.state.localeOptions}
										value={this.state.form.locale || ""}
										onChange={this.updateForm.bind(this, "locale")}
									/>
									{store.getState().user.locale !== this.state.form.locale && (
										<p style={{ opacity: 0.75, fontSize: "0.9em" }}>
											{this.props.t("user.me.fields.language.helptext2", "Du kommer behöva logga in på nytt")}
										</p>
									)}
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={this.props.t("user.me.sections.sync_contacts_to_phone.title", "Synka kontakter till telefon")}
						description={this.props.t(
							"user.me.sections.sync_contacts_to_phone.description",
							"Synka dina kontakter/företag till din telefon vid app start"
						)}
					>
						<Card title={this.props.t("user.me.sections.sync_contacts_to_phone.card.title", "Synka kontakter till telefon")}>
							<Card.Section>
								<FormLayout>
									<div>
										<TextStyle>
											{this.props.t("user.me.fields.sync_contacts_to_phone.title2", "Tillåt att kontakter synkas till mobilerna")}{" "}
										</TextStyle>
										<TextStyle variation="strong">
											{this.state.form.sync_contacts_to_phone ? this.props.t("common.terms.yes", "ja") : this.props.t("common.terms.no", "nej")}
										</TextStyle>
									</div>

									<Switch
										label={this.props.t("user.me.fields.sync_contacts_to_phone.label", "Synka kontakter till telefon")}
										checked={this.state.form.sync_contacts_to_phone}
										onChange={this.updateForm.bind(this, "sync_contacts_to_phone")}
										uncheckedIcon={undefined}
										checkedIcon={undefined}
									/>
								</FormLayout>
							</Card.Section>
						</Card>
					</Layout.AnnotatedSection>
				</Layout>

				<UploadModal
					disabledToast
					open={this.state.openUploadImage}
					onClose={this.closeModal.bind(this, "openUploadImage")}
					onSelect={(imgs) => {
						if (imgs[0] && imgs[0].url) {
							this.state.form.avatar = imgs[0].url;
							this.setState({ form: this.state.form, openUploadImage: false });
							this.saveForm();
						}
					}}
					secondaryActions={
						this.state.showRemove && [
							{
								content: this.props.t("user.actions.profile.remove", "Ta bort profilbild"),
								onAction: this.removeProfileImage.bind(this),
								destructive: true,
								icon: DeleteMajor,
								loading: this.state.removing,
							},
						]
					}
					allowMultiple={false}
					as="profile"
				/>
				<PageActions
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						disabled: !this.state.form.email,
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
				<EmailSignatureModal
					emailSignature={this.state.form.email_signature}
					open={this.state.openEmailSignature}
					onClose={this.closeEmailSignatureModal.bind(this)}
					onComplete={(emailSignature) => {
						this.state.form.email_signature = emailSignature;
						this.setState({ form: this.state.form, openEmailSignature: false });

						this.saveForm();
					}}
				/>
			</Page>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
});

export default connect(mapStateToProps)(withTranslation(["user", "common"], { withRef: true })(MyPage));

const SignatureWrapper = styled.div`
	max-width: 500px;
	cursor: pointer;

	* {
		pointer-events: none;
	}
`;
