import React, { Component } from "react";
import { Button, Collapsible, Icon, Link } from "@shopify/polaris";

import { EditMajor, JobsMajor, CircleCancelMajor, MobileChevronMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";
import ContactSelector from "./ContactSelector.js";
import IntegrationButtons from "./IntegrationButtons.js";
import RemoveModal from "./remove_modal.js";
import Skeleton1Card from "./skeleton-1card.js";
import ProfileAvatar from "./ProfileAvatar.js";
import InfoView from "./InfoView.js";
import TagSelector from "./TagSelector.js";
import IconWithBackround from "./IconWithBackround.js";
import ContactModal from "./ContactModal.js";
import Banner from "./banner";
import RoadSignIcon from "../icons/RoadSignIcon.js";
import ContactRelationships from "./ContactRelationships.js";
import RelationshipSelector from "./RelationshipSelector.js";
import SellerSelector from "./SellerSelector.js";
import getFirstRelationship from "../Utilities/contact/getFirstRelationship";

class ContactCard extends Component {
	constructor(props) {
		super(props);

		this.state = { form: props.contact || {}, cardIsExpanded: props.expanded };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ form: props.contact || {} });
	}

	addTag(tag, i) {
		if (Array.isArray(this.state.form.tags)) {
			this.state.form.tags.push(tag);
		} else {
			this.state.form.tags = [tag];
		}

		this.setState((c) => ({ form: this.state.form || {}, saving: true }));

		API.put(
			"/api/contacts/" + this.state.form.id + ".json",
			{
				tags: this.state.form.tags,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("contact.responses.tag.created", "Skapade tagg"));
				this.state.form.tags = result.data.contact.tags;
				this.setState({ saving: false, form: this.state.form });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	removeTag(tag) {
		this.setState({
			removeModalOpen: true,
			currentTagName: tag?.title,
			currentTag: tag,
		});
	}

	doRemoveTag() {
		const tags =
			this.state.form && Array.isArray(this.state.form.tags) ? this.state.form.tags.filter((tag) => tag.title !== this.state.currentTag.title) : [];
		this.state.form.tags = tags;
		this.setState({ form: this.state.form || {}, saving: true });

		API.put(
			"/api/contacts/" + this.state.form.id + ".json",
			{
				tags: this.state.form.tags,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("contact.responses.tag.removed", "Tog bort tagg"));

				this.state.form.tags = result.data.contact.tags;
				this.setState({
					saving: false,
					removeModalOpen: false,
					form: this.state.form,
				});
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	gotoContact() {
		this.props.gotoContact(this.state.form);
	}

	gotoParent() {
		this.props.gotoContact(
			this.state.form.parent,
			this.state.form
				? {
						path: {
							title: this.state.form.name,
							url: `/admin/contacts/${this.state.form.id}`,
						},
				  }
				: {}
		);
	}

	updateCompany(contact) {
		this.state.form.parent = contact;
		this.setState({ form: this.state.form || {} });

		API.put(
			"/api/contacts/" + this.state.form.id + ".json",
			{
				parent: contact,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onUpdate) {
					this.props.onUpdate(result.data.contact);
				}

				toastr.success(this.props.t("contact.responses.contact_company_updated", "Kontaktens företag uppdaterades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	createCompany(contact) {
		this.state.form.parent = contact;
		this.setState({ form: this.state.form || {} });
		if (this.props.onUpdate) {
			this.props.onUpdate(contact);
		}
	}

	getMetafieldValue(metafield) {
		if (this.state.form && Array.isArray(this.state.form.metafields)) {
			for (let i = 0; i < this.state.form.metafields.length; i++) {
				if (this.state.form.metafields[i].metafield_id == metafield.id) {
					return this.state.form.metafields[i].value;
				}
			}
			return metafield.type == "boolean" ? false : "";
		}
		return metafield.type == "boolean" ? false : "";
	}

	closeContactModal() {
		this.setState({ showContactModal: false });
	}

	openContactModal() {
		if (this.props.openContactModal) {
			this.props.openContactModal();
		} else {
			this.setState({
				showContactModal: true,
				selectedContact: this.state.form,
			});
		}
	}

	changeRelationship(field, relationship) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].field_id == field.field_id) {
					this.state.form.fields[i].relationship = relationship;
				}
			}
			this.setState({ form: this.state.form || {} });
		}

		API.put(
			"/api/contacts/" + this.state.form.id + "/fields/" + field.id + "/relationship.json",
			{
				relationship,
				is_company: this.state.form.is_company,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				store.dispatch({
					type: "UPDATE_FIELD",
					field: result.data.contact.fields.find((f) => f.id == field.id),
				});

				if (this.props.onUpdate) {
					this.props.onUpdate(result.data.contact);
				}
				this.setState({ form: result.data.contact });

				toastr.success(this.props.t("contact.responses.relation.updated", "Relation uppdaterades"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	changeRelationshipUser(field, user) {
		if (this.state.form && Array.isArray(this.state.form.fields)) {
			for (let i = 0; i < this.state.form.fields.length; i++) {
				if (this.state.form.fields[i].id == field.id) {
					this.state.form.fields[i].user = user;
				}
			}
			this.setState({ form: this.state.form || {} });
		}

		API.put(
			"/api/contacts/" + this.state.form.id + "/fields/" + field.id + "/user.json",
			{
				user,
				is_company: this.state.form.is_company,
			},
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.onUpdate) {
					this.props.onUpdate(result.data.contact);
				}

				store.dispatch({
					type: "UPDATE_FIELD",
					field: result.data.contact.fields.find((f) => f.id == field.id),
				});
				this.setState({ form: result.data.contact || {} });

				toastr.success(this.props.t("task.responses.customer_manager.changed", "Kundansvarig ändrad"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		const metafields = [];
		if (store.getState().contact_metafields) {
			for (let i = 0; i < store.getState().contact_metafields.length; i++) {
				const value = this.getMetafieldValue(store.getState().contact_metafields[i]);
				if (value) {
					metafields.push({
						metafield: store.getState().contact_metafields[i],
						value,
					});
				}
			}
		}

		const form = this.state.form;
		const address = form.street || form.address || form.address1;
		const topRelationship = getFirstRelationship(form);

		const info = [
			{
				label: this.props.t("contact.fields.company.label", "Företag"),
				enabled: !form.is_company,
				component: (
					<div style={{ display: "flex", gap: "2rem" }}>
						<ContactSelector
							caption={this.props.t("contact.fields.company.caption", "Lägg till företag")}
							contact={form}
							onChange={this.updateCompany.bind(this)}
							onCreateContact={this.createCompany.bind(this)}
							hidePerson
							hideIcon
							link
							lock
							gotoContact={this.gotoParent.bind(this)}
							external={this.props.external}
							disabled={form?.disabled}
							// element={(props) => <Button {...props}>{props.children}</Button>}
						/>
						{/* {this.state.form && this.state.form.parent && (
							<span className="edit-button">
								<Button plain onClick={this.openContactModalParent.bind(this)} icon={EditMajor}></Button>
							</span>
						)} */}
					</div>
				),
			},
			{
				label: this.props.t("contact.fields.email.label", "Mailadress"),
				value: form.email,
				type: "email",
			},
			{
				label: this.props.t("contact.fields.phone.label", "Telefonnummer"),
				value: form.phone,
				type: "phone",
			},
			{
				label: this.props.t("contact.fields.mobile_phone.label", "Mobilnummer"),
				value: form.mobile_phone,
				type: "phone",
			},
			{
				label: this.props.t("contact.fields.title.label", "Befattning/Roll"),
				value: form.title,
				enabled: !form.is_company,
			},
			{
				label: this.props.t("contact.fields.orgnr.label", "Org nr"),
				value: form.orgnr,
				enabled: form.is_company,
				type: "orgnr",
			},
			{
				label: this.props.t("contact.fields.address.label", "Adress"),
				value: address ? address + ", " + form.zip + " " + form.city : "",
			},
			{
				label: this.props.t("contact.fields.contact_user.label", "Ansvarig medarbetare"),
				enabled: store.getState().fields.length <= 1,
				component: (
					<SellerSelector
						noPadding
						user={topRelationship && topRelationship.user}
						onChange={this.changeRelationshipUser.bind(this, topRelationship)}
						clearable
						plain
					/>
				),
			},
		]
			.concat(
				metafields.map((metafield) => ({
					label: metafield.metafield.title,
					value:
						metafield.metafield.type == "boolean"
							? metafield.value
								? this.props.t("common.terms.yes", "Ja")
								: this.props.t("common.terms.no", "Nej")
							: metafield.value,
				}))
			)
			.filter((i) => (!("enabled" in i) || i.enabled) && (i.value || i.component));

		const nrFieldsToShow = 4;

		const contactInfo = info.slice(0, nrFieldsToShow);
		const contactInfoExpanded = info.slice(nrFieldsToShow);

		return (
			<div className="contact-card">
				{form.is_visitor && (
					<Banner
						status="info"
						title={this.props.t("contact.visitor.title", "Ej importerad")}
						style={{ marginBottom: "1.5rem" }}
						dismissible
						actions={[
							{
								primary: true,
								content: this.props.t("contact.visitor.actions.import", "Importera"),
								onAction: this.openContactModal.bind(this),
							},
						]}
					>
						<p>{this.props.t("contact.visitor.text1", "Denna kontakt är ej importerad i crm:et.")}</p>
						<p>{this.props.t("contact.visitor.text2", "Klicka på importera nedan och sen spara för att importera kontakten.")}</p>
					</Banner>
				)}

				<div className={"contact-card-header " + (form.is_company ? "company" : "people")}>
					<div className="contact_section header" style={{ marginBottom: "2rem" }}>
						{form.is_company ? <IconWithBackround icon={JobsMajor} /> : <ProfileAvatar user={form} size={50} />}
						<div className="contact_section_content">
							{/* {<h2 className="contact_section_title">{title}</h2>} */}
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<div>
									<h1
										className="contact_section_title"
										style={{
											alignItems: "center",
											display: "flex",
											gap: "1rem",
											color: "var(--textColor)",
										}}
									>
										{this.props.link ? <Link onClick={this.gotoContact.bind(this)}>{form.name}</Link> : <span>{form.name}</span>}

										<RelationshipSelector
											disabled={this.state.form?.removed}
											relationship={topRelationship && topRelationship.relationship}
											onChange={this.changeRelationship.bind(this, topRelationship)}
											onClick={
												store.getState().fields && store.getState().fields.length > 1
													? () => {
															if (store.getState().fields && store.getState().fields.length > 1) {
																toastr.warning(this.props.t("contact.text.change_relation_else", "Du behöver ändra kundrelation under avdelningar"));
															}
													  }
													: null
											}
										/>
									</h1>

									<div className="tags">
										{this.state.form.tags && (
											<React.Fragment>
												{`${this.props.t("contact.tags.plural", "Taggar")}: `}
												{this.state.form.tags.map((prop, key, self) => (
													<div className="tag" key={key}>
														{key > 0 && key < self.length ? ", " : ""}
														{prop.title}
														{!this.state.form?.removed && <Button onClick={this.removeTag.bind(this, prop)} destructive icon={CircleCancelMajor} />}
													</div>
												))}
											</React.Fragment>
										)}
										{!this.state.form.fromRoaring && !this.state.form?.removed && (
											<span
												className="TagSelector"
												style={{
													marginLeft: this.state.form.tags && this.state.form.tags.length ? "0.5rem" : "0",
												}}
											>
												<TagSelector caption="" contact={this.state.form} onCreate={this.addTag.bind(this)} loading={this.state.saving} />
											</span>
										)}
									</div>
								</div>
								{/* <a href={`https://maps.google.com/?q=${form}`}> */}
								<div
									style={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										alignItems: "end",
									}}
								>
									{!this.props.contact?.removed && (
										<span className="edit-button" style={{ marginBottom: "1rem" }}>
											<Button onClick={this.openContactModal.bind(this)} icon={EditMajor}>
												{this.props.t("common.actions.change", "Ändra")}
											</Button>
										</span>
									)}
									{form.street ? (
										<a
											className="googlemaps_link"
											href={`https://www.google.com/maps/search/?api=1&query=${form.street}, ${form.city} ${form.zip} ${(() => {
												const country = store.getState().countries && store.getState().countries.find((c) => c.value === form.country);
												if (country) return country.label;
												return "";
											})()}`}
											target="_blank"
											rel="noreferrer"
										>
											<RoadSignIcon />
											{this.props.t("contact.terms.directions", "Vägbeskrivning")}
										</a>
									) : (
										<div />
									)}
								</div>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							position: "relative",
						}}
					>
						{this.props.loading && <Skeleton1Card />}
						<InfoView labelWidth="125px" details={contactInfo} fullView />
						{!this.props.expanded && contactInfoExpanded && !!contactInfoExpanded.length && (
							<div
								className="expand-card__btn"
								onClick={() =>
									this.setState((c) => ({
										cardIsExpanded: !c.cardIsExpanded,
									}))
								}
							>
								{this.state.cardIsExpanded
									? this.props.t("contact.actions.hide_info", "Dölj alla kontaktuppgifter")
									: this.props.t("contact.actions.show_info", "Visa alla kontaktuppgifter")}
								<span
									style={{
										rotate: this.state.cardIsExpanded ? "90deg" : "270deg",
										transition: "rotate 350ms",
									}}
								>
									<Icon source={MobileChevronMajor} />
								</span>
							</div>
						)}
					</div>

					<Collapsible open={this.state.cardIsExpanded}>
						<InfoView details={contactInfoExpanded} labelWidth="125px" style={{ marginTop: "1rem" }} fullView />
					</Collapsible>
					{!this.state.form.fromRoaring && <IntegrationButtons contact={this.state.form} external={this.props.external} />}
					{!!store.getState().fields?.length && (
						<div className="gap">
							<div>
								<h3
									style={{
										fontWeight: 700,
										marginBottom: "0.5rem",
										marginTop: "1.75rem",
									}}
								>
									{this.props.t("contact.terms.deals_and_contact_user", "Affärsområde & ansvariga medarbetare")}
								</h3>
								<ContactRelationships
									// ref={(ref) => {
									// 	this.contactRelationships = ref;
									// }}
									contact={this.state.form}
									compact
									onUpdate={(contact) => {
										this.setState({ form: contact });
									}}
									loading={this.props.loading}
								/>
							</div>
							{/* {this.props.children} */}
						</div>
					)}
				</div>

				{this.props.children}

				<RemoveModal
					open={this.state.removeModalOpen}
					loading={this.state.saving}
					onClose={() => {
						this.setState({ removeModalOpen: false });
					}}
					numItems={1}
					objectName={this.state.currentTagName}
					onConfirm={this.doRemoveTag.bind(this)}
					resourceName={{
						singular: this.props.t("contact.tags.singular", "tagg"),
						plura: this.props.t("contact.tags.plural", "taggar"),
					}}
				/>

				<ContactModal
					disabledRemove
					open={this.state.showContactModal}
					onUpdate={(contact = {}) => {
						if (!contact.is_company && this.state.form.is_company) {
							let found = false;

							for (let i = 0; i < this.state.children.length; i++) {
								if (this.state.children[i].id == contact.id) {
									this.state.children[i] = contact;
									found = true;
									break;
								}
							}
							if (!found) {
								this.state.children.push(contact);
							}
							this.setState({ children: this.state.children });
						} else if (contact.is_company && !this.state.form.is_company) {
							this.state.form.parent = contact;
							this.setState({ form: this.state.form });
						} else {
							if (this.state.form.id == contact.id) {
								this.state.form = contact;
								this.setState({ form: this.state.form });
							}
						}

						this.props?.onUpdate?.(contact);
					}}
					onCreate={(contact) => {
						if ("children" in contact) {
							for (let i = 0; i < contact.children.length; i++) {
								if (contact.children[i].id == this.state.form.id) {
									this.gotoContact(contact);
								}
							}
						}
					}}
					onRemove={(contact) => {
						if (contact.id == this.state.form.id) {
							// this.props.history.goBack();
						} else if (this.state.children) {
							const children = [];

							for (let i = 0; i < this.state.children.length; i++) {
								if (this.state.children[i].id != contact.id) {
									children.push(this.state.children[i]);
								}
							}

							this.setState({ children });
						}
					}}
					company={this.state.form && this.state.form.is_company ? this.state.form : this.state.form && this.state.form.parent}
					contact={this.state.selectedContact}
					// contact={this.state.selectedContact || (this.state.form.is_company ? this.state.form : this.state.form.parent)}
					onClose={this.closeContactModal.bind(this)}
				/>
			</div>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />
const mapStateToProps = (state, ownProps) => ({
	fields: state.fields,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(withTranslation(["contact", "common"], { withRef: true })(ContactCard));
