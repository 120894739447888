import { Card } from "@shopify/polaris";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Spinner from "src/js/components/Spinner";
import FilterListItem from "./FilterListItem";

type FiltersListProps = {
	form: Partial<TargetType>;
	target?: Partial<TargetType> | null;
	handleChangeFilter: (index) => (targetSource: Partial<TargetSourceType> | null) => void;
	isLoading?: boolean;
} & WithTranslation;

const FiltersList = ({ t, form, target, handleChangeFilter, isLoading }: FiltersListProps) => {
	const renderTargetSource = (filter: TargetSourceType, i: number) => {
		return (
			<FilterListItem index={i} form={form} target={target} targetSource={filter} handleChangeFilter={handleChangeFilter} isLoading={isLoading} />
		);
	};

	return (
		<>
			{isLoading && !form?.target_sources?.length && (
				<Card sectioned title={t("targets.fields.filters.loading", "Laddar filters") as string}>
					<Spinner />
				</Card>
			)}

			{form?.target_sources?.map((filter, i) => {
				return renderTargetSource(filter, i);
			})}
		</>
	);
};
export default withTranslation(["targets"])(FiltersList);
