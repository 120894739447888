import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";

import BoardHelper from "../Workspaces/BoardHelper";
import BigData from "../../components/big_data";
import NumberBadge from "../../components/NumberBadge";
import Colors from "../../Colors";
import { store } from "../../store";

type ItemType = {
	user_id: number;
	count: number;
	user?: UserType;
};

type BigDataColumn = {
	handle: string;
	label: string;
	type: string;
	sortable: boolean;
};
type UsersActivityProps = any;
type UsersActivityState = { datesFrom?: string | null; datesTo?: string | null; dateFilter?: string };

class UsersActivity extends Component<UsersActivityProps, UsersActivityState> {
	refresh = () => {};

	constructor(props: UsersActivityProps) {
		super(props);
		this.state = {
			datesFrom: moment().subtract(90, "days").format("YYYY-MM-DD"),
			datesTo: moment().format("YYYY-MM-DD"),
			dateFilter: "last_90_days",
		};
	}

	onItemsFetched() {}

	onUpdateParams(params: any) {
		this.setState({ dateFilter: params.date });
	}

	renderCell(item: ItemType, column: BigDataColumn) {
		if (column.handle == "NAME") {
			const user = store.getState().users.find((usr: UserType) => String(usr.id) === String(item.user_id));
			item.user = user;
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{user?.name || "-"}</h3>
						<span className="CustomerListItem__Location" />
					</div>
				</div>
			);
		}

		if (column.handle == "COUNT") {
			return (
				<div>
					<div className="CustomerListItem__Profile">
						<NumberBadge color={Colors.red}>{item.count}</NumberBadge>
					</div>
				</div>
			);
		}
	}

	render() {
		const filters = [
			{
				key: "date",
				label: this.props.t("users_activity.filters.date.label", "Datum"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				range: true,
				shortcut: true,
				onChange: (v: string | null) => {
					if (!v) {
						this.setState({ datesFrom: null, datesTo: null });
					} else {
						if (v.includes(" - ")) {
							const [datesFrom, datesTo] = v.split(" - ");
							this.setState({ datesFrom, datesTo }, this.refresh.bind(this));
						} else {
							const [datesFrom, datesTo] = BoardHelper.dateStringToDate(v) || [];
							this.setState({ datesFrom, datesTo }, this.refresh.bind(this));
						}
					}
				},
			},
		];

		const columns = [
			{ handle: "NAME", label: this.props.t("users_activity.users.columns.name.label", "Namn"), sortable: true },
			{ handle: "COUNT", label: this.props.t("users_activity.users.columns.count.label", "Antal"), sortable: true },
		];

		return (
			<BigData
				breadcrumbs={[{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }]}
				history={this.props.history}
				setRefreshHandler={(refreshHandler: any) => {
					this.refresh = refreshHandler;
				}}
				title={this.props.t("users_activity.title", "Överblick")}
				savedSearchHandle="adimn_overview_users"
				resourceUrl="/api/users_activity/count.json"
				resourceHandle="counts"
				onItemsFetched={this.onItemsFetched.bind(this)}
				params={{
					dates_from: this.state.datesFrom,
					dates_to: this.state.datesTo,
				}}
				onClickRow={(row: ItemType) => {
					this.props.history.push(`/admin/users_activity/user/${row.user_id}`, {
						datesFrom: this.state.datesFrom,
						datesTo: this.state.datesTo,
						defaultAppliedFilters: [
							{
								key: "date",
								value: this.state.dateFilter,
							},
						],
					});
				}}
				limit={10000}
				resourceName={{
					singular: this.props.t("users_activity.users.singular", "Användare"),
					plural: this.props.t("users_activity.users.plural", "Användare"),
				}}
				renderCell={this.renderCell.bind(this)}
				columns={columns}
				filters={filters}
				onUpdateParams={this.onUpdateParams.bind(this)}
				promotedBulkActions={[]}
				bulkActions={[]}
				defaultSort="NAME_DESC"
				defaultAppliedFilters={[
					{
						key: "date",
						value: "last_90_days",
					},
				]}
			/>
		);
	}
}
const mapStateToProps = (state: any) => ({
	users: state.users,
});

export default connect(mapStateToProps)(withTranslation(["users_activity", "common"], { withRef: true })(UsersActivity));
