import { Avatar } from "@shopify/polaris";
import React from "react";
import { withTranslation } from "react-i18next";
import Colors from "src/js/Colors";
import CardList from "src/js/components/CardList";
import ItemCard from "src/js/components/ItemCard";
import Page from "src/js/components/page";
import truncateString from "src/js/Utilities/truncateString";

const GroupIndex = ({ history, t }) => {
	const renderItem = (item: any) => {
		return (
			<ItemCard
				media={<Avatar size="medium" initials={item.initials} name={item.name} source={item.avatar} />}
				title={<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>{item.name}</div>}
				leftColumn={[
					{
						label: t("groups.field.description.label", "Beskrivning"),
						value: truncateString(item.description),
					},
					{
						label: t("groups.field.status.label", "Status"),
						value: item.disable ? (
							<span style={{ fontWeight: 600, color: Colors.red }}>{t("groups.fields.disabled.value", "Inaktiv")}</span>
						) : (
							<span style={{ fontWeight: 600, color: Colors.green }}>{t("groups.fields.enabled.value", "Aktiv")}</span>
						),
					},
					{
						label: t("groups.field.users_display", "Användare"),
						value: item.users_display,
					},
				]}
				rightColumn={[]}
			/>
		);
	};

	const sortOptions = [
		{ label: t("group.sort.title_asc", "Namn (A-Ö)"), value: "TITLE_ASC" },
		{ label: t("group.sort.title_desc", "Namn (Ö-A)"), value: "TITLE_DESC" },
		{ label: t("group.sort.title_desc", "Skapad (nyast till äldst)"), value: "ID_DESC" },
		{ label: t("group.sort.title_asc", "Skapad (äldst till nyast)"), value: "ID_ASC" },
	];

	return (
		<Page
			title={t("groups.title", "Grupper")}
			primaryAction={{
				content: t("groups.create", "Skapa ny grupp"),
				url: "/admin/groups/create",
			}}
		>
			<CardList
				appliedFilters={
					[
						// {
						// 	key: "enabled",
						// 	value: "1",
						// },
					]
				}
				onClick={(item) => {
					history.push(`/admin/groups/${item.id}`, {
						data: item,
					});
				}}
				// filters={filters}
				resourceUrl={`/api/groups.json`}
				resourceName={{ singular: t("common.user", "Grupp"), plural: t("common.groups", "Grupper") }}
				resourceHandle={"groups"}
				renderItem={renderItem}
				sortOptions={sortOptions}
				// promotedBulkActions={promotedBulkActions}
				// bulkActions={[]}
			/>
		</Page>
	);
};
export default withTranslation(["groups", "common"])(GroupIndex);
