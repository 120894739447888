import React, { Component } from "react";
import { Popover, Stack, Button, TextStyle, FormLayout, Select, Icon } from "@shopify/polaris";
import moment from "moment";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import CellTimelineIntegrationStuff from "./CellTimelineIntegrationStuff.js";
import Calendar from "../Agenda/Calendar/Calendar";
import MyPopover from "../../components/Popover";
import NewDatePicker from "../../components/NewDatePicker/index.js";

class CellTimeline extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			value: props.value ? Object.assign({}, props.value) : props.value,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value ? Object.assign({}, props.value) : props.value });
	}

	toggle() {
		if (!this.state.editing) {
			this.props.onFocus();
		} else {
			this.props.onBlur();
		}
		this.setState({ editing: !this.state.editing });
	}

	blur() {
		if (JSON.stringify(this.state.value) != JSON.stringify(this.props.value)) {
			this.props.onChange({ value: this.state.value });
		}
		this.props.onBlur();
	}

	updateValue() {
		if (this.props.expanded) {
			this.props.onChange(this.state.value);
		}
	}

	clear(event) {
		event.stopPropagation();
		this.state.value = null;
		this.props.onChange({ value: this.state.value });
		this.setState({ value: null });
	}

	getReminderText() {
		if (this.state.value.reminder_minutes == "0" || this.state.value.reminder_minutes) {
			let str = this.props.t("cell.timeline.fields.reminder_text.opitons.default", "En påminnelse skickas");

			const opts = {
				0: this.props.t("cell.timeline.fields.reminder_text.options.0", "vid tillfället"),
				15: this.props.t("cell.timeline.fields.reminder_text.options.15", "en kvart innan"),
				30: this.props.t("cell.timeline.fields.reminder_text.options.30", "en halvtimme innan"),
				60: this.props.t("cell.timeline.fields.reminder_text.options.60", "en timme innan"),
				1440: this.props.t("cell.timeline.fields.reminder_text.options.1440", "ett dygn innan"),
			};

			str += " " + opts[this.state.value.reminder_minutes];

			if (this.state.value.priority == "low") {
				// eslint-disable-next-line quotes
				str += " " + this.props.t("cell.timeline.fields.reminder_text.options.low", 'som en "tyst" notis i ditt notiscenter');
			} else if (this.state.value.priority == "high") {
				str += " " + this.props.t("cell.timeline.fields.reminder_text.options.high", "som en pushnotis till appen samt via mail");
			} else {
				str += " " + this.props.t("cell.timeline.fields.reminder_text.options.app", "som en pushnotis till appen");
			}
			return (
				<div style={{ fontSize: "0.8em", paddingTop: 10 }}>
					<TextStyle variation="subdued">{str}</TextStyle>
				</div>
			);
		}
		return null;
	}

	render() {
		const datepicker = (
			<NewDatePicker
				range
				optionalTime
				value={this.state.value}
				onChange={({ start, end } = {}) => {
					if (!this.state.value) {
						this.state.value = {};
					}
					this.state.value.start = start;
					this.state.value.end = end;
					this.setState({ value: this.state.value }, this.updateValue.bind(this));
				}}
			/>
		);

		const timepicker = this.state.value ? (
			<FormLayout key="timepickerkey">
				<Stack distribution="fillEvenly">
					<Select
						label={this.props.t("cell.timeline.fields.reminder_minutes.label", "Skicka påminnelse")}
						options={[
							{ value: "", label: this.props.t("cell.timeline.fields.reminder_minutes.options.none", "Ingen påminnelse") },
							{ value: "0", label: this.props.t("cell.timeline.fields.reminder_minutes.options.0", "Vid tillfället") },
							{ value: "15", label: this.props.t("cell.timeline.fields.reminder_minutes.options.15", "En kvart innan") },
							{ value: "30", label: this.props.t("cell.timeline.fields.reminder_minutes.options.30", "En halvtimme innan") },
							{ value: "60", label: this.props.t("cell.timeline.fields.reminder_minutes.options.60", "En timme innan") },
							{ value: "1440", label: this.props.t("cell.timeline.fields.reminder_minutes.options.1440", "Ett dygn innan") },
						]}
						value={this.state.value.reminder_minutes}
						onChange={(v) => {
							this.state.value.reminder_minutes = v;
							this.state.value.priority = "normal";
							this.setState({ value: this.state.value }, this.updateValue.bind(this));
						}}
					/>
					<Select
						label="Prioritet"
						disabled={!this.state.value.reminder_minutes}
						options={[
							{ value: "low", label: this.props.t("cell.timeline.fields.priority.low", "Låg") },
							{ value: "normal", label: this.props.t("cell.timeline.fields.priority.normal", "Normal") },
							{ value: "high", label: this.props.t("cell.timeline.fields.priority.high", "Hög") },
						]}
						value={!this.state.value.priority ? "normal" : this.state.value.priority}
						onChange={(v) => {
							this.state.value.priority = v;
							this.setState({ value: this.state.value }, this.updateValue.bind(this));
						}}
					/>
				</Stack>
				{this.getReminderText()}
				{this.props.column?.options?.integration ? (
					<CellTimelineIntegrationStuff
						value={this.state.value}
						contact={this.props.contact}
						column={this.props.column}
						row={this.props.row}
						onChange={(v) => {
							this.setState({ value: v }, this.updateValue.bind(this));
						}}
					/>
				) : null}
			</FormLayout>
		) : null;

		if (this.props.expanded) {
			return (
				<Stack vertical>
					<Button key="toggleCalendar" onClick={() => this.setState((c) => ({ showCalendar: !c.showCalendar }))}>
						{this.state.showCalendar ? this.props.t("common.actions.hide", "Dölj") : this.props.t("common.actions.show", "Visa")}{" "}
						{this.props.t("cell.timeline.terms.calendar", "kalendern")}
					</Button>
					{this.state.showCalendar && (
						<Calendar
							key="calendarkey"
							external={this.props.external}
							// ref={(ref) => (this.CalendarRef = ref)}
							hideHeader
							selectedDate={this.state.value && this.state.value.start}
							onChange={(v) => {
								const newVal = moment(v).format("YYYY-MM-DD HH:mm");
								this.setState(
									(c) => ({ value: { start: newVal, end: moment(newVal).add(1, "hours").format("YYYY-MM-DD HH:mm") } }),
									this.updateValue.bind(this)
								);
							}}
							updateExistingModalContact={(v) => {
								if (this.props.updateContact) this.props.updateContact(v);
							}}
							updateExistingModalDate={(v) => {
								const start = moment(v).add(1, "days");
								this.setState({ value: { start: moment(start), end: moment(start).add(1, "hours").format("YYYY-MM-DD HH:mm") } });
								this.updateValue.bind(this);
							}}
							disabledNewModal={!!this.props.updateContact}
						/>
					)}
					{timepicker}
				</Stack>
			);
		}

		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.editing}
				activator={
					<div
						className={"overflow-ellipsis board-editable" + (this.state.editing ? " focused" : "")}
						style={{
							width: "100%",
							cursor: "text",
							textAlign: this.props.textAlign || "center",
							height: 29,
							backgroundColor: this.state.editing ? "transparent" : null,
						}}
						onClick={this.toggle.bind(this)}
					>
						{this.state.value ? (
							<div>
								{this.state.value.start} -{this.state.value.end}
							</div>
						) : null}
						{this.state.value ? (
							<div onClick={this.clear.bind(this)} className="board-display-hover clear-button">
								<Icon source={CircleCancelMinor} />
							</div>
						) : null}
					</div>
				}
				onClose={() => {
					this.setState({ editing: false }, this.blur.bind(this));
				}}
			>
				<Popover.Section>
					<div style={{ minWidth: 500 }}>{datepicker}</div>
				</Popover.Section>
				{this.state.value ? (
					<Popover.Section>
						<div style={{ minWidth: 500 }}>{timepicker}</div>
					</Popover.Section>
				) : null}
			</MyPopover>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(CellTimeline);
