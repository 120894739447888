import { Tabs } from "@shopify/polaris";
import React, { useState } from "react";
import styled from "styled-components";

const DocumentListsTabsWrapper = ({ tabs }) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const handledTabChange = (selectedTabIndex) => {
		setSelectedTabIndex(selectedTabIndex);
	};

	if (tabs?.length <= 1) return tabs?.[0]?.content;

	const tab = tabs?.[selectedTabIndex];
	return (
		<Wrapper>
			<Tabs tabs={tabs?.map((t) => ({ ...t, content: t.title }))} selected={selectedTabIndex} onSelect={handledTabChange} />
			<ContentWrapper key={tab.id}>{tab?.content}</ContentWrapper>
		</Wrapper>
	);
};
export default DocumentListsTabsWrapper;

const Wrapper = styled.div`
	.Polaris-Tabs__Tab {
		padding: 0.6rem 0.3rem;

		.Polaris-Tabs__Title {
			padding: 0.6rem 1.2rem;
		}
	}
`;
const ContentWrapper = styled.div``;
