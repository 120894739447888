import { store } from "../store";

interface NumberFormatterOptions {
	value: number;
	currency?: string;
	locale?: string;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
	style?: "currency" | "decimal" | "percent";
	removeCurrencySymbol?: boolean;
}

const numberFormatter = ({
	value,
	currency = store.getState().account.currency || "SEK",
	locale = store.getState().user.locale || "se-SV",
	minimumFractionDigits = 0,
	maximumFractionDigits = 2,
	style = "currency",
	removeCurrencySymbol = false,
}: NumberFormatterOptions): string => {
	if ((!value && typeof value !== "number") || !Number.isFinite(parseFloat(String(value))) || Number.isNaN(value)) {
		return "-";
	}

	const formatter = new Intl.NumberFormat(locale, {
		style,
		currency,
		minimumFractionDigits,
		maximumFractionDigits,
		// minimumSignificantDigits: 21,
		// maximumSignificantDigits: 4,
		currencyDisplay: "narrowSymbol",
	});

	const parts = formatter.formatToParts(
		parseFloat(
			String(value)
				.replaceAll(/\s/g, "")
				.replace(/,([^,]*)$/, ".$1")
		)
	);

	if (style === "currency" && currency === "SEK" && !removeCurrencySymbol && parts[0].type === "currency") {
		const currencyPart = parts.shift();
		currencyPart!.value = " kr";
		parts.push(currencyPart as any);
	}

	const formattedNumber = parts
		.map((part) => {
			switch (part.type) {
				case "integer":
					// return part.value.replace(/\B(?=(\d{3})+(?!\d))/g, " "); // apply grouping
					return part.value;
				case "group":
					if (locale === "se-SV") {
						return " ";
					}
					return part.value;
				case "decimal":
					if (locale === "se-SV") {
						return ",";
					}
					return part.value;
				case "currency":
					return removeCurrencySymbol ? "" : part.value;
				case "fraction":
				default:
					return part.value;
			}
		})
		.reduce((acc, curr) => acc + curr, "");

	return formattedNumber.trim();
};

export default numberFormatter;
