import React, { Component } from "react";
import API from "../../../API";
import BoardHelper from "../BoardHelper.js";
import { toastr } from "../../../components/toastr.js";

import CellUpload from "../CellUpload";

class RowColumnForm extends Component {
	constructor(props) {
		super(props);

		this.state = { contact: props.contact || null };
	}

	componentDidMount() {
		this.fetchContact();
	}

	updateCell(column, data) {
		if (!this.props.row || !this.props.row.id) {
			const columnValues = {
				[column.id]: Object.assign(data, { column_id: column.id }),
			};

			if (this.state.contact || this.state.contact_id) {
				const contactColumn = this.props.board.columns.find((c) => c.type === "contact");
				columnValues[contactColumn.id] = {
					column_id: contactColumn.id,
					value: [this.state.contact.id || this.state.contact_id],
				};
			}

			API.post(
				"/api/boards/" + this.props.board.id + "/rows.json",
				{
					title: data.value && data.value[0] && data.value[0].name,
					comment_count: 0,
					board_id: this.props.board.id,
					values: Object.values(columnValues),
					column_values: columnValues,
					ref: Math.random() * 100,
				},
				{
					params: {},
				}
			)
				.then((result) => {
					result.data.row.ref = result.data.ref;
					BoardHelper.updateRows([result.data.row], "add");
					if (this.props.onCreate) this.props.onCreate(result.data.row);
					// this.setState({ rnd: 5 });
				})
				.catch((error) => {
					toastr.error(error);
				});
			return;
		}
		BoardHelper.onUpdateValue(this.props.row, column, data, (data) => {
			this.props.row.values = data.row.values;
			this.props.row.column_values = data.row.column_values;
			// eslint-disable-next-line react/no-unused-state
			this.setState({ rnd: 5 });
			if (this.props.onCreate) this.props.onCreate(data.row);
		});
	}

	fetchContact() {
		let contactId = this.props.contact_id;
		if (!contactId && !this.props.contact) {
			for (let i = 0; i < this.props.board.columns.length; i++) {
				const column = this.props.board.columns[i];
				if (column.type == "contact") {
					const values = this.props.row && BoardHelper.getColumnValues(this.props.row, column);
					if (values.length > 0 && values[0]) {
						contactId = values[0].value;
						break;
					}
				}
			}
		}

		if (contactId && !this.props.contact) {
			API.get("/api/contacts/" + contactId + ".json")
				.then((result) => {
					this.setState({ contact: result.data.contact });
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	render() {
		const values = this.props.row && BoardHelper.getColumnValues(this.props.row, this.props.column);
		const column = BoardHelper.getColumn(this.props.column);
		let value = this.props.value;
		if (values && values.length > 0 && values[0]) {
			value = values[0].value;
		}

		if (column.type == "upload") {
			return (
				<div style={{ width: "100%", height: "100%" }}>
					<CellUpload
						expanded
						height={35}
						initialContact={this.state.contact}
						onChange={this.updateCell.bind(this, column)}
						column={column}
						row={this.props.row}
						onFocus={() => {}}
						onBlur={() => {}}
						onUpdateColumn={BoardHelper.updateColumn}
						value={value}
						board={this.props.board}
						history={this.props.history}
					/>
				</div>
			);
		}
	}
}
export default RowColumnForm;
