import React from "react";
import styled from "styled-components";
import { Tooltip } from "@shopify/polaris";
import Colors, { colorToRgba } from "../Colors";

interface BadgeProps {
	// Content of the badge
	children?: React.ReactNode;
	// Function to call when the badge is clicked
	onClick?: () => void;
	// Color of the badge
	color?: string | null;
	// Number between 0 and 1
	alpha?: number;
	// Font size of the badge
	fontSize?: number;
	// Whether to round the badge
	round?: boolean;
	// Style to add to the badge
	style?: React.CSSProperties;
	// Classname to add to the badge
	className?: string;
	// Status of the badge
	status?: "success" | "warning" | "attention" | "critical" | "error" | "info" | "new";
	// Icon to display
	icon?: React.ReactNode;
	// Tooltip content
	tooltip?: string;
	// Number between 0 and 100
	progress?: number;
	// Number in milliseconds
	progressDuration?: number;
}
interface WrapperProps {
	color: string;
	backgroundColor: string;
	progress?: number;
}

const Badge = ({
	color,
	className = "",
	onClick,
	alpha = 0.3,
	fontSize,
	round,
	style,
	status,
	icon,
	children,
	progress = 100,
	progressDuration = 1000,
	tooltip,
}: BadgeProps) => {
	const clr = (() => {
		if (color) return color;

		switch (status) {
			case "success":
				return Colors.green;
			case "warning":
				return Colors.yellow;
			case "attention":
				return Colors.orange;
			case "critical":
			case "error":
				return Colors.red;
			case "info":
				return Colors.blue;
			case "new":
				return Colors.lightblue;
			default:
				return Colors.grey;
		}
	})();

	const textColor = (() => {
		if (clr === Colors.grey) return "#ffffff80";
		return clr;
	})();

	const content = (
		<BadgeWrapper
			className={`number number_badge ${className} ${onClick ? "clickable" : ""}`}
			onClick={onClick}
			backgroundColor={colorToRgba(clr, alpha / 2)}
			color={textColor}
			style={Object.assign(fontSize ? { fontSize } : {}, round ? { borderRadius: "20px" } : {}, style || {})}
			progress={progress}
		>
			<ProgressBackdrop progressDuration={progressDuration} />
			{icon}
			{children}
		</BadgeWrapper>
	);

	if (tooltip) return <Tooltip content={tooltip}>{content}</Tooltip>;

	return content;
};

export default Badge;

const ProgressBackdrop = styled.div.attrs({ className: "progress" })<{ progressDuration?: number }>`
	transition: width ${({ progressDuration }) => progressDuration || 1000}ms, background-color ${({ progressDuration }) => progressDuration || 1000}ms;
	position: absolute;
	inset: 0;
	background-color: ${({ color }) => color};
`;

export const BadgeWrapper = styled.div<WrapperProps>`
	padding: 0.15rem 1rem;
	border-radius: 0.3em;
	font-size: 0.7em;
	// display: flex;
	align-items: center;
	justify-items: center;
	transition: all 250ms;
	font-weight: 600;
	background-color: ${({ backgroundColor }) => backgroundColor};
	color: ${({ color }) => color};
	width: max-content;
	display: inline-flex;
	position: relative;
	margin-inline: 0.5rem;

	${ProgressBackdrop} {
		background-color: ${({ backgroundColor }) => backgroundColor};
		width: ${({ progress }) => progress}%;
		max-width: 100%;
	}

	&.hover,
	&.clickable {
		&:hover {
			filter: brightness(1.2);
		}
	}

	&.clickable {
		user-select: none;
		cursor: pointer;
	}

	// &:hover {
	// 	// filter: brightness(1.25);
	// 	// -webkit-filter: brightness(1.25);
	// 	font-weight: 600;
	// }
`;
