import React, { useEffect, useMemo } from "react";
import API, { fetchAndSetTokens } from "src/js/API";
import { toastr } from "src/js/components/toastr";
import useQuery from "src/js/hooks/useQuery";
import { store } from "src/js/store";
import { getLocalStorage } from "src/js/Utilities";
import MyDashboard from "../Dashboard/MyDashboard";
import BoardSalesDashboard from "./sales/BoardSalesDashboard";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ServiceDeskDashboard from "./service/ServiceDeskDashboard";
import checkForScopes from "../Fortnox/Utilities/checkForScopes";
import EconomyDashboard from "./economy/EconomyDashboard";
import EconomyDashboardWithoutOfferOrder from "./economy/EconomyDashboardWithoutOfferOrder";
import moment from "moment";
import httpBuildQuery from "http-build-query";
import { SelectorLabel, Wrapper, Header } from "./styles";
import Select from "src/js/components/select/Select";
import DashboardView from "../Workspaces/DashboardView";
import Skeleton2Col from "src/js/components/skeleton_2col";
import Spinner from "src/js/components/Spinner";
import TargetsDashboard from "./targets/TargetsDashboard";

const Dashboards = ({ match, history, t }) => {
	const from = moment().subtract(1, "month").format("YYYY-MM-DD");
	const to = moment().format("YYYY-MM-DD");

	const storeDashboards = useSelector((state: any) => state.dashboards);
	const user = useSelector((state: any) => state.user);
	const account = useSelector((state: any) => state.account);
	const workspaces = useSelector((state: any) => state.workspaces);

	const fetchDashboards = async () => {
		try {
			const result = await API.get("/api/dashboards.json");

			if (result.data.error) throw result.data.error;

			store.dispatch({ type: "SET_DASHBOARDS", dashboards: result.data.dashboards });

			return result.data.dashboards;
		} catch (e) {
			toastr.error(e);
		}
	};

	const { data: databaseDashboards, isFetching: isLoadingDatabaseDashboards } = useQuery({
		queryKey: ["dashboards"],
		queryFn: fetchDashboards,
		initialData: storeDashboards || [],
	});

	const generatedDashboards = useMemo(() => {
		const dashboards = [
			{
				id: "mine",
				title: t("dashboard.classic.title", "Klassisk"),
				render: <MyDashboard history={history} />,
			},

			{
				id: "sales",
				title: t("dashboard.sales.title", "Försäljning"),
				render: () => <BoardSalesDashboard history={history} />,
				enabled: !!account.sales_board_id,
			},

			{
				id: "service_desk",
				title: t("dashboard.service_desk.title", "Servicedesk"),
				render: <ServiceDeskDashboard history={history} />,
				enabled:
					account.support_board_id && workspaces?.find((w) => w.title === "Servicedesk")
						? user.roles.includes("ROLE_WORKSPACE_" + workspaces.find((w) => w.title === "Servicedesk").id)
						: user.roles.includes("ROLE_SUPPORT"),
			},

			{
				id: "economy",
				title: t("dashboard.economy.title", "Ekonomi"),
				render: account.order_board?.id ? <EconomyDashboard history={history} /> : <EconomyDashboardWithoutOfferOrder history={history} />,
				enabled: checkForScopes(["invoice"]) && account.invoice_board?.id,
			},
			{
				id: "targets",
				title: t("dashboard.targets.title", "Målsättningar"),
				render: <TargetsDashboard />,
				// enabled: !!store.getState().targets?.length,
			},
		].concat(
			account?.integrations?.map((integration) => {
				const data = { from, to, signed_token: user.signed_token, account_id: account.id, slideshow: "0" };

				const customDashboardUrl =
					integration.dashboard_url &&
					`${integration.dashboard_url}${integration.dashboard_url?.includes("?") ? "&" : "?"}${httpBuildQuery(data).replace(/\+/g, "%20")}`;

				return {
					id: "integration" + integration.id,
					iframe: customDashboardUrl,
					title: integration.title,
					enabled: integration.dashboard_url && store.getState().user.roles.indexOf("ROLE_INTEGRATION_" + integration.id) >= 0,
				};
			}) || [],
			(databaseDashboards || storeDashboards || []).map((dashboard) => {
				return {
					id: dashboard.id,
					title: dashboard.title,
					dashboard,
				};
			})
		);

		return dashboards.filter((d) => !("enabled" in d) || d.enabled);
	}, [databaseDashboards, history, storeDashboards, t, workspaces, user, account, from, to]);

	const selectedDashboardId = useMemo(() => {
		const dashboards = generatedDashboards;
		const id = match?.params?.type;
		const dashboard = dashboards.find((d) => String(d.id) === String(id));

		if (!dashboard && !isLoadingDatabaseDashboards) {
			const defaultDashboardIds = [getLocalStorage("selectedDashboardId"), dashboards?.[0]?.id, "mine"];
			const defaultDashboardId = defaultDashboardIds.find((id) => dashboards.find((d) => String(d.id) === String(id)));

			history.replace("/admin/dashboard/" + defaultDashboardId);
			return defaultDashboardId;
		}

		return id;
	}, [match?.params?.type, generatedDashboards, history, isLoadingDatabaseDashboards]);

	useEffect(() => {
		if (new URLSearchParams(document.location.search).get("update_token") === "true") {
			fetchAndSetTokens()
				.then(() => {
					window.history.pushState({}, document.title, document.location.pathname);
				})
				.catch((error) => console.error(error));
		}
	}, []);

	const dashboard: any = useMemo(() => {
		const dashboards = generatedDashboards;
		const id = selectedDashboardId;
		return dashboards.find((d) => String(d.id) === String(id));
	}, [generatedDashboards, selectedDashboardId]);

	const loading = isLoadingDatabaseDashboards;

	return (
		<Wrapper>
			<Header>
				<SelectorLabel>{t("dashboard.actions.choose", "Välj dashboard")}</SelectorLabel>
				<Select
					options={generatedDashboards.map((dashboard) => ({
						label: dashboard.title,
						value: String(dashboard.id),
					}))}
					onChange={(id) => {
						history.replace("/admin/dashboard/" + id);
					}}
					value={selectedDashboardId}
				/>
				{loading && <Spinner size="small" />}
			</Header>

			{!dashboard && loading && <Skeleton2Col />}

			{dashboard?.iframe ? (
				<iframe
					title={dashboard?.title}
					className="dashy"
					src={dashboard?.iframe}
					width="100%"
					frameBorder="0"
					// allowTransparency="true"
					height="100%"
					style={{ minHeight: 600 }}
				/>
			) : dashboard?.dashboard ? (
				<DashboardView noheader match={match} history={history} dashboard={dashboard?.dashboard} />
			) : dashboard?.render instanceof Function ? (
				dashboard?.render()
			) : (
				dashboard?.render
			)}
		</Wrapper>
	);
};
export default withTranslation(["dashboard", "common"])(Dashboards);
