import React, { Component } from "react";
import { Modal } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Miniboard from "./Miniboard.js";
import RowColumnForm from "./RowColumnForm.js";

class ColumnActionModal extends Component {
	constructor(props) {
		super(props);

		this.state = { open: props.open, row: null };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
			if (props.open) {
				this.setState({ row: null });
			}
		}
	}

	render() {
		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={
					this.state.row
						? this.props.column.title + " - " + this.state.row.title
						: this.props.t("common.actions.choose", "Välj") + " " + this.props.board.singular
				}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				{!this.state.row ? (
					<div style={{ paddingLeft: 20, paddingRight: 20 }}>
						<Miniboard
							onClickRow={(row) => {
								this.setState({ row });
							}}
							board={this.props.board}
							board_id={this.props.board.id}
							contact_id={this.props.contact.id}
						/>
					</div>
				) : null}
				{this.state.row ? (
					<Modal.Section>
						<RowColumnForm
							board={this.props.board}
							contact_id={this.props.contact.id}
							column={this.props.column}
							row={this.state.row}
							history={this.props.history}
						/>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(ColumnActionModal);
