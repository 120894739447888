import React, { useEffect } from "react";
import API from "src/js/API";
import "@shopify/polaris/build/esm/styles.css";
import { store } from "src/js/store";
import "../css/board.scss";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import EditRowExternal from "../js/views/Workspaces/EditRowExternal.js";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "../js/Properties";
import ExternalBoardViewForm from "./ExternalBoardViewForm";

const BoardView = React.lazy(() => import("../js/views/Workspaces/BoardView.js"));
const DashboardView = React.lazy(() => import("../js/views/Workspaces/DashboardView.js"));
const ColumnActionExternal = React.lazy(() => import("../js/views/Workspaces/ColumnActionExternal.js"));
const AppIntegrationExternal = React.lazy(() => import("../js/components/AppIntegrationExternal.js"));

const routes = [
	{ path: "/guest/boards/:boardId/row/:rowId", name: "Row", component: EditRowExternal },
	{ path: "/guest/boards/:boardId/row", name: "New row", component: EditRowExternal },
	{
		path: "/guest/boards/:boardId/column/:id",
		name: "Column",
		component: ColumnActionExternal,
	},
	{
		path: "/guest/boards/:id",
		name: "Board",
		component: BoardView,
	},
	{
		path: "/guest/dashboard/:id",
		name: "Dashboard",
		component: DashboardView,
	},
	{
		path: "/guest/forms/:id",
		name: "Form",
		component: ExternalBoardViewForm,
	},
	{ path: "/guest/integration/:id", name: "Integration", component: AppIntegrationExternal },
];

const GuestView = () => {
	// Maybe fetch the rest ofthe required resoucres here aswell like Preapp does. (account, boards, etc..)?
	useEffect(() => {
		const fetchUser = async () => {
			const result = await API.get("/api/me.json");
			if (result.data.user) {
				const me = { ...store.getState().user, ...result.data.user };
				store.dispatch({ type: "SET_USER", user: me });
				store.dispatch({ type: "SET_USERS", users: [me] });
			}
		};
		fetchUser();
	}, []);

	useEffect(() => {
		const fetchUsers = async () => {
			const result = await API.get("/api/users.json");
			if (result.data.users) {
				store.dispatch({ type: "SET_USERS", users: result.data.users });
			}
		};
		fetchUsers();
	}, []);

	return (
		<div className="boardembed">
			<div className="Polaris-Frame__Content">
				<Switch>
					{routes.map((prop, key) => {
						if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
						return <Route path={prop.path} exact={prop.exact} component={prop.component} key={key} />;
					})}
				</Switch>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.user,
	account: state.account,
	users: state.users,
});

export default connect(mapStateToProps)(GuestView);
