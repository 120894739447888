import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { currencyFormatter } from "src/js/Utilities";
import { WithTranslation, withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import useQuery from "src/js/hooks/useQuery";
import { store } from "src/js/store";
import API from "src/js/API";
import FortnoxContactResourceList from "../../FortnoxContactResourceList";
import { getOfferFilters } from "../../Utilities";

type FortnoxOffersContactListProps = {
	contact?: ContactType;
	history: any;
	setCount?: any;
} & WithTranslation;

const FortnoxOffersContactList = ({ contact, history, t, setCount }: FortnoxOffersContactListProps) => {
	const queryKey = [contact?.id && `contact_${contact?.id}_fortnox_offers_count`];
	useQuery({
		queryKey,
		queryFn: async () => {
			try {
				return await API.get(`/api/fortnox/contacts/${contact?.id}/offers.json`, { params: { limit: 1 } }).then((response) => response?.data?.count);
			} catch (e) {
				console.error("Error", e);
				return null;
			}
		},
		refetchOnWindowFocus: false,
		enabled: !!(contact?.id && contact.fortnox_customer_id && store.getState().account.fortnox_integration && setCount),
		initialData: {
			data: [],
			count: 0,
		},
		onSuccess: (data) => {
			if (setCount) setCount(data);
		},
	});

	const renderItem = useCallback(
		(item: FortnoxOfferType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/fortnox/offers/${item.DocumentNumber}`);
					}}
					title={
						<>
							{item.DocumentNumber}
							{item.Cancelled && <Badge color={Colors.red}>{t("fortnox.offer.statuses.Cancelled", "Avbruten")}</Badge>}
							{item.Sent && <Badge color={Colors.green}>{t("fortnox.offer.statuses.Sent", "Skickad")}</Badge>}
						</>
					}
					leftColumn={[
						{
							label: t("fortnox.offers.fields.offer_date.label", "Offert date"),
							value: item.OfferDate,
						},
						{
							label: t("fortnox.offers.fields.project.label", "Projekt"),
							value: item.Project,
							enabled: item.Project,
						},
						{
							label: t("fortnox.offers.fields.CustomerName.label", "Kund"),
							value: item.CustomerName,
							enabled: !contact,
						},
					].filter((t) => !("enabled" in t) || t.enabled)}
					rightColumn={[
						{
							label: t("fortnox.offers.fields.total.label", "Total"),
							value: currencyFormatter({
								value: item.Total,
								currency: item.Currency,
							}),
						},
					].filter((t) => !("enabled" in t) || t.enabled)}
				/>
			);
		},
		[history, t, contact]
	);
	const filters = [
		{
			key: "filter",
			label: t("fortnox.filters.status.label", "Status"),
			type: "select",
			options: getOfferFilters(),
			shortcut: true,
		},
		{
			key: "yourreference",
			label: t("fortnox.filters.yourreference.label", "Er referens"),
			type: "textField",
		},
		{
			key: "ourreference",
			label: t("fortnox.filters.ourreference.label", "Fortnox referens"),
			type: "textField",
		},
		{
			key: "notcompleted",
			label: t("fortnox.filters.notcompleted.label", "Ej slutförd"),
			type: "checkbox",
		},
		{
			key: "sent",
			label: t("fortnox.filters.sent.label", "Skickad"),
			type: "checkbox",
			options: [
				{
					label: t("common.yes", "Ja"),
					value: true,
				},
				{
					label: t("common.no", "Nej"),
					value: false,
				},
			],
		},
		// {
		// 	key: "todate",
		// 	secondKey: "fromdate",
		// 	label: t("fortnox.filters.fromdate_todate.label", "Till och från datum"),
		// 	type: "date",
		// 	range: true,
		// },
		{
			key: "todate",
			label: t("fortnox.filters.todate.label", "Till datum"),
			type: "date",
		},
		{
			key: "fromdate",
			label: t("fortnox.filters.fromdate.label", "Från datum"),
			type: "date",
		},
		{
			key: "lastmodified",
			label: t("fortnox.filters.lastmodified.label", "Senast ändrad"),
			type: "date",
		},
	];
	return (
		<FortnoxContactResourceList
			filters={filters}
			contact={contact}
			resource="Offers"
			resourceName={{
				singular: "Offer",
				plural: "Offers",
			}}
			renderItem={renderItem}
			additionalAction={
				contact?.id && {
					content: t("fortnox.offers.actions.create", "Skapa offert"),
					plain: true,
					icon: CirclePlusMajor,
					// primary: true,
					onAction: () => {
						history.push(`/admin/fortnox/contacts/${contact.id}/offers/create`, {
							contact,
						});
					},
				}
			}
		/>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOffersContactList);
