import React, { Component } from "react";
import { Modal } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class RemoveModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let description;
		let title;
		if (this.props.numItems === 1) {
			title = this.props.t("modals.remove.title_remove_resourcename", "Ta bort {{ resourceName }}?", {
				resourceName: this.props.resourceName.singular,
			});
			if (this.props.objectName) {
				description = this.props.t(
					"modals.remove.prompt_remove_resourcename",
					"Är du säker på att du vill ta bort {{ resourceName }}? Detta går ej att ångra.",
					{ resourceName: this.props.objectName }
				);
			} else {
				description = this.props.t(
					"modals.remove.prompt_remove_resourcename",
					"Är du säker på att du vill ta bort denna {{ resourceName }}? Detta går ej att ångra.",
					{ resourceName: this.props.resourceName.singular }
				);
			}
		} else {
			title = this.props.t("modals.remove.title_remove_many_resourcename", "Ta bort {{resourceName}}?", {
				resourceName: this.props.resourceName.plural,
			});
			description = this.props.t(
				"modals.remove.prompt_remove_x_resourcename",
				"Är du säker på att du vill ta bort {{ count }} {{ resourceName }}? Detta går ej att ångra.",
				{ count: this.props.numItems, resourceName: this.props.resourceName.plural }
			);
		}

		return (
			<Modal
				large
				size="large"
				open={this.props.open}
				onClose={this.props.onClose}
				title={title}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					destructive: true,
					content: this.props.t("common.actions.remove", "Ta bort"),
					loading: this.props.loading,
					onAction: this.props.onConfirm,
				}}
			>
				<Modal.Section>
					<div dangerouslySetInnerHTML={{ __html: description }} />
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(RemoveModal);
