import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { Link, Tooltip } from "@shopify/polaris";
import nl2br from "react-nl2br";

import { toastr } from "src/js/components/toastr";
import API from "src/js/API";
import BoardHelper from "../Workspaces/BoardHelper";
import BigData from "../../components/big_data";
import NumberBadge from "../../components/NumberBadge";
import Colors from "../../Colors";
import { store } from "../../store";
import CommentContentPopover from "./CommentContentPopover";

type ColumnsObject = {
	contactColumn?: BoardColumnType;
	dateColumn?: BoardColumnType;
};
type UsersActivityUserPageProps = any;
type UsersActivityUserPageState = {
	datesFrom?: string | null;
	datesTo?: string | null;
	columns?: {
		[key: string | number]: ColumnsObject;
	};
	dataParams?: any;
	exporting?: boolean;
};

class UsersActivityUserPage extends Component<UsersActivityUserPageProps, UsersActivityUserPageState, any> {
	refresh = () => {};

	bigData: any;

	constructor(props: UsersActivityUserPageProps) {
		super(props);
		this.state = {
			datesFrom: props.location?.state?.datesFrom || null,
			datesTo: props.location?.state?.datesTo || null,
			columns: {},
		};
		this.bigData = React.createRef();
	}

	renderCell(item: any, column: BigDataColumnType) {
		if (column.handle == "TEXT") {
			// const user = store.getState().users.find((usr: UserType) => String(usr.id) === String(item.user_id));
			// item.user = user;
			return <CommentContentPopover>{item.content?.trim()}</CommentContentPopover>;
		}

		if (column.handle == "CREATED_AT") {
			return moment(item.created_at).locale("sv").calendar() || "-";
		}

		if (column.handle == "ACTIVITY") {
			return item.activity?.title || "-";
		}

		if (column.handle == "DATE") {
			return item.date || "-";
		}
		if (column.handle == "CONTACTS") {
			return item.contact?.id ? <Link url={`/admin/contacts/${item.contact?.id}`}>{item.contact?.fullname || "-"}</Link> : "-";
		}
		if (column.handle == "COMPLETED_ROW") {
			return item.completed_row?.id ? (
				<Link url={`/admin/workspaces/none/boards/${item.completed_row.board_id}?row_id=${item.completed_row?.id}`}>
					{/* {item.completed_row?.title?.length > 23 ? `${item.completed_row?.title.substring(0, 20)}...` : item.completed_row?.title || "-"} */}
					{item.completed_row?.title || "-"}
				</Link>
			) : (
				"-"
			);
		}
		if (column.handle == "ROW") {
			return item.row?.id ? (
				<Link url={`/admin/workspaces/none/boards/${item.row.board_id}?row_id=${item.row?.id}`}>{item.row?.title || "-"}</Link>
			) : (
				"-"
			);
		}

		if (column.handle == "CONTACTS") {
			const { contactColumn } = this.state.columns?.[item.board_id] || {};
			if (contactColumn) {
				const value = item.column_values[contactColumn?.id]?.value;

				const contacts = value?.map((id: number) => store.getState().board_contacts[String(id)]).filter((i: ContactType | undefined) => i);

				const contactsWithCompany = contacts?.filter(
					(con: ContactType) => (con.is_company || con.parent) && !store.getState().users.find((u: UserType) => u.email === con.email)
				);
				const contactsWithoutCompany = contacts?.filter(
					(con: ContactType) => !con.is_company && !con.parent && !store.getState().users.find((u: UserType) => u.email === con.email)
				);

				return (
					<div>
						<div className="CustomerListItem__Profile">
							{!!contactsWithCompany?.length && (
								<Tooltip
									content={String(
										nl2br(`Kontakter med företag\n\n${contactsWithCompany.map((contact: ContactType) => contact.fullname).join("\n")}`)
									)}
								>
									<NumberBadge color={Colors.green}>{contactsWithCompany?.length}</NumberBadge>
								</Tooltip>
							)}
							{!!contactsWithoutCompany?.length && (
								<Tooltip
									content={String(
										nl2br(`Kontakter utan företag\n\n${contactsWithoutCompany.map((contact: ContactType) => contact.fullname).join("\n")}`)
									)}
								>
									<NumberBadge color={Colors.red}>{contactsWithoutCompany?.length}</NumberBadge>
								</Tooltip>
							)}
						</div>
					</div>
				);
			}
		}
	}

	async handleExport() {
		try {
			const id = this.props.match?.params?.id;

			const params = { ...(this.bigData?.current?.getParams() || {}) };
			this.setState({ exporting: true });

			const result = await API({
				url: `/api/users_activity/users/${id}/export.xls`,
				method: "POST",
				responseType: "blob",
				params,
			});

			if (result.data.error) {
				console.error("error:", result.data.error);
				toastr.error(result.data.error);
				return;
			}

			// const textData = "Your text data to be encoded as UTF-8";
			// const encoder = new TextEncoder();
			// const dataUint8Array = encoder.encode(textData);

			const type = result.headers["content-type"];
			// const blob = new Blob([result.data], { type, encoding: "UTF-8" });
			const blob = new Blob([result.data], { type });
			// const blob = new Blob([dataUint8Array], { type });
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(blob);
			link.download = `export_user-${id}_activity.xlsx`;
			link.click();
			window.URL.revokeObjectURL(link.href);

			toastr.success(this.props.t("users_activity.responses.exported", "Export klar"));
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
		} finally {
			this.setState({ exporting: false });
		}
	}

	render() {
		const filters = [
			{
				key: "date",
				label: this.props.t("users_activity.filters.date.label", "Datum"),
				operatorText: this.props.t("common.terms.since", "sedan"),
				type: "dateSelector",
				future: false,
				range: true,
				shortcut: true,
				onChange: (v: string | null) => {
					if (!v) {
						this.setState({ datesFrom: null, datesTo: null });
					} else {
						if (v.includes(" - ")) {
							const [datesFrom, datesTo] = v.split(" - ");
							this.setState({ datesFrom, datesTo }, this.refresh.bind(this));
						} else {
							const [datesFrom, datesTo] = BoardHelper.dateStringToDate(v) || [];
							this.setState({ datesFrom, datesTo }, this.refresh.bind(this));
						}
					}
				},
			},
			{
				key: "types",
				label: this.props.t("users_activity.filters.types.label", "Typer"),
				type: "options",
				options: [
					...store.getState().contact_activities.map((activity) => ({
						value: activity.id + "",
						label: activity.title,
					})),
				],
				allowMultiple: true,
				shortcut: true,
			},
		];
		const columns = [
			{
				handle: "TEXT",
				label: this.props.t("users_activity.users.columns.text.label", "Text"),
				sortable: false,
				type: "text",
			},
			{
				handle: "ACTIVITY",
				label: this.props.t("users_activity.users.columns.acticity.label", "Akvitiets typ"),
				sortable: false,
				type: "text",
			},

			{
				handle: "DATE",
				label: this.props.t("users_activity.users.columns.date.label", "Datum"),
				sortable: false,
				type: "text",
			},
			{
				handle: "CONTACTS",
				label: this.props.t("users_activity.users.columns.contact.label", "Kontakt"),
				sortable: false,
				type: "text",
			},
			{
				handle: "ROW",
				label: this.props.t("users_activity.users.columns.row.label", "Rad"),
				sortable: false,
				type: "text",
			},
			{
				handle: "COMPLETED_ROW",
				label: this.props.t("users_activity.users.columns.created_from_row.label", "Skapad ifrån Rad"),
				sortable: false,
				type: "text",
			},
			{
				handle: "CREATED_AT",
				label: this.props.t("users_activity.users.columns.created_at.label", "Skapad"),
				sortable: false,
				type: "text",
			},
		];

		const userId = this.props.match?.params?.id;
		const user = store.getState().users.find((u: UserType) => String(u.id) === String(userId));
		return (
			<BigData
				ref={this.bigData}
				breadcrumbs={[
					{
						content: this.props.t("common.actions.back", "Tillbaka"),
						onAction: () => this.props.history.goBack(),
					},
				]}
				primaryAction={{
					content: this.props.t("users_activity.users.actions.export", "Exportera"),
					onAction: this.handleExport.bind(this),
					loading: this.state.exporting,
				}}
				history={this.props.history}
				setRefreshHandler={(refreshHandler: any) => {
					this.refresh = refreshHandler;
				}}
				title={user?.name}
				savedSearchHandle="adimn_overview_users"
				resourceUrl={`/api/users_activity/users/${userId}.json`}
				resourceHandle="comments"
				params={{
					dates_from: this.state.datesFrom,
					dates_to: this.state.datesTo,
				}}
				resourceName={{
					singular: this.props.t("users_activity.users.singular", "Användare"),
					plural: this.props.t("users_activity.users.plural", "Användare"),
				}}
				renderCell={this.renderCell.bind(this)}
				columns={columns}
				filters={filters}
				promotedBulkActions={[]}
				bulkActions={[]}
				defaultSort="CREATED_AT_DESC"
				defaultAppliedFilters={this.props.location?.state?.defaultAppliedFilters}
			/>
		);
	}
}
const mapStateToProps = (state: any) => ({
	users: state.users,
	board_contacts: state.board_contacts,
});

export default connect(mapStateToProps)(withTranslation(["users_activity", "common"], { withRef: true })(UsersActivityUserPage));
