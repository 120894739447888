import React, { Component } from "react";
import { Heading, Button, Scrollable, Spinner } from "@shopify/polaris";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import TriggerModal from "./TriggerModal.js";
import TriggerPreview from "./TriggerPreview.js";
import Sheet from "../../../components/sheet";
import Banner from "../../../components/banner";

class AutomationSheet extends Component {
	constructor(props) {
		super(props);
		this.state = { triggers: [] };
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open && !this.props.open) {
			this.fetchTriggers(props.board.id);
		}
	}

	fetchTriggers(boardId, softLoading) {
		if (softLoading) {
			this.setState({ softLoading: true });
		} else {
			this.setState({ triggers: [], loadingTriggers: true });
		}
		API.get("/api/boards/" + boardId + "/triggers.json", { params: {} })
			.then((result) => {
				this.setState({
					triggers: result.data.triggers,
					loadingTriggers: false,
					softLoading: false,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingTriggers: false, softLoading: false });
			});
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = Array.from(this.state.triggers);
		// const trigger = items.find()
		const [removed] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, removed);
		this.setState({ triggers: items });

		API.put(
			"/api/boards/" + this.props.board.id + "/triggers/" + removed.id + ".json",
			{ position: result.destination.index },
			{
				params: {},
			}
		)
			.then((result) => {
				toastr.success(this.props.t("automation.responses.order_updated", "Uppdaterade trigger ordning"));
				// this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				// this.setState({ saving: false });
			});
	}

	render() {
		const accessDenied = this.props.roles.indexOf("ROLE_BOARD_ADMIN") < 0 && this.props.roles.indexOf("ROLE_BOARD_OWNER") < 0;

		const sheetContent = this.state.loadingTriggers ? (
			<div style={{ margin: "25px" }}>
				<Spinner size="large" />
			</div>
		) : (
			<React.Fragment>
				<Droppable droppableId="asd">
					{(provided, snapshot) => (
						// eslint-disable-next-line react/jsx-props-no-spreading
						<Scrollable style={{ padding: "1.6rem", height: "100%" }} {...provided.droppableProps}>
							{accessDenied && (
								<Banner status="warning" title="Ingen behörighet">
									<p>Du saknar Admin behörighet att ändra automatiseringar för denna board</p>
									<p>
										Kontakta någon board admin för att få tillgång. (
										{this.props.board?.members
											?.filter((member) => member.roles?.some((r) => ["ROLE_BOARD_ADMIN", "ROLE_BOARD_OWNER"].includes(r)))
											?.map((member, index, array) => (
												<React.Fragment key={member.id}>
													<Link to={`/admin/users/${member.user.id}`}>
														<b
															style={{
																color: "var(--textColor)",
															}}
														>
															{member.user.name}
														</b>
													</Link>
													{index < array.length - 1 ? ", " : ""}
												</React.Fragment>
											))}
										)
									</p>
								</Banner>
							)}
							<div className="triggers_wrapper" ref={provided.innerRef}>
								{this.state.triggers.map((trigger, index) => (
									<Draggable key={trigger.id} index={index} isDragDisabled={accessDenied} draggableId={String(trigger.id)}>
										{(provided, snapshot) => (
											// eslint-disable-next-line react/jsx-props-no-spreading
											<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} key={trigger.id}>
												<TriggerPreview
													onChange={(trigger) => {
														this.state.triggers[index] = trigger;
														this.setState({ triggers: this.state.triggers });
													}}
													onRemove={() => {
														this.state.triggers.splice(index, 1);
														this.setState({ triggers: this.state.triggers });
													}}
													onClick={() => {
														if (accessDenied) {
															return;
														}
														this.setState({
															showTriggerModal: true,
															editingTrigger: trigger,
														});
													}}
													disabled={accessDenied}
													trigger={trigger}
													board={this.props.board}
													groups={this.props.groups}
												/>
											</div>
										)}
									</Draggable>
								))}
							</div>
							{provided.placeholder}
						</Scrollable>
					)}
				</Droppable>
				{this.state.softLoading && <Spinner />}
			</React.Fragment>
		);

		return (
			<div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Sheet open={this.props.open && !this.state.showTriggerModal} onClose={this.props.onClose} hideHeader>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "100%",
							}}
						>
							<div
								className="headingOverflow"
								style={{
									alignItems: "center",
									borderBottom: "1px solid #DFE3E8",
									display: "flex",
									justifyContent: "space-between",
									padding: "1.6rem",
									width: "100%",
									gap: "2rem",
								}}
							>
								<Heading>{this.props.t("automations.title", "Automatiseringar")}</Heading>
								{!accessDenied && (
									<Button
										primary
										onClick={() => {
											this.setState({ showTriggerModal: true });
										}}
									>
										{this.props.t("common.actions.create_new", "Skapa ny")}
									</Button>
								)}
								<Button accessibilityLabel="Cancel" icon={MobileCancelMajor} onClick={this.props.onClose} plain />
							</div>
							{sheetContent}
						</div>
					</Sheet>
				</DragDropContext>
				<TriggerModal
					open={this.state.showTriggerModal}
					board={this.props.board}
					trigger={this.state.editingTrigger}
					groups={this.props.groups}
					onCreate={() => {
						this.setState({ editingTrigger: null, showTriggerModal: false });
						this.fetchTriggers(this.props.board.id, true);
					}}
					onClose={() => {
						this.setState({ editingTrigger: null, showTriggerModal: false });
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["automation", "common"], { withRef: true })(AutomationSheet);
