import React, { Component } from "react";
import { Button, Icon, Tooltip, ButtonGroup } from "@shopify/polaris";

import {
	ChevronLeftMinor,
	ChevronRightMinor,
	TypeMajor,
	AddImageMajor,
	MobilePlusMajor,
	MinusMinor,
	SearchMajor,
	MobileBackArrowMajor,
	DuplicateMinor,
} from "@shopify/polaris-icons";
import { fabric } from "fabric";
import { withTranslation } from "react-i18next";
import ActiveObjectToolbar from "./ActiveObjectToolbar";
import UploadModal from "../../../components/UploadModal";
import AddVariable from "./AddVariable";
import NameModal from "./NameModal";
import AddLink from "./AddLink";
import AddTable from "./Table/AddTable";

class Toolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	cancel() {
		this.props.history.push(`/admin/pdf_templates/${this.props.id}`);
	}

	openModal(name) {
		this.setState({ [name]: true });
	}

	closeModal(name) {
		if (name === "variable" && !this.state["variable-input-focused"]) {
			this.setState((c) => ({ [name]: false, input: "" }));
			return;
		}
		this.setState((c) => ({ [name]: false }));
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateForm(field, value) {
		this.setState({ [field]: value });
	}

	addVariable(input = this.state.input, type) {
		if (type === "profile" || input === "user.avatar") {
			this.props.addImage(["https://www.sellfinity.com/assets/pdf_profile_placeholder.jpeg"], (img) => {
				const d = 500;
				const size = 40 / d;

				return {
					centeredScaling: true,
					top: 100,
					left: 100,
					originX: "center",
					originY: "center",
					scaleX: size * this.props.zoom,
					scaleY: size * this.props.zoom,
					clipPath: new fabric.Circle({
						radius: d / 2,
						originX: "center",
						originY: "center",
					}),
				};
			});

			return;
		}
		this.props.addText(`{{${input}}}`);
		this.setState({ input: "" });
	}

	render() {
		return (
			<div className="editor__toolbar">
				<div>
					<div className="editor__toolbar__leftButtons">
						<Button icon={MobileBackArrowMajor} onClick={this.cancel.bind(this)}>
							{this.props.t("common.actions.back", "tillbaka")}
						</Button>
					</div>
					<span className="editor__toolbar__newPageBtn">
						<Button primary onClick={this.props.addPage}>
							{this.props.t("pdf.actions.add_page", "+ Sida")}
						</Button>
					</span>

					<ButtonGroup>
						<Tooltip content={this.props.t("pdf.tooltips.add_text", "Lägg till text")}>
							<Button onClick={this.props.addText.bind(this, "Text")} icon={TypeMajor} />
						</Tooltip>

						<AddLink onSubmit={this.props.addLink} />
						<AddVariable addVariable={this.addVariable.bind(this)} />
						<AddTable onSubmit={this.props.addTable} />

						<Tooltip content={this.props.t("pdf.tooltips.add_image", "Lägg till en bild")}>
							<Button onClick={this.openModal.bind(this, "openUploadImage")} icon={AddImageMajor} />
						</Tooltip>
						<Tooltip content={this.props.t("pdf.tooltips.add_line", "Lägg till en linje")}>
							<Button onClick={this.props.addLine}>|</Button>
						</Tooltip>
					</ButtonGroup>
					<Tooltip content={this.props.t("pdf.tooltips.duplicate_object", "Duplicera valt objekt")}>
						<Button onClick={this.props.duplicateObject} icon={DuplicateMinor} />
					</Tooltip>
					<div className="editor__toolbar__buttonGroup">
						<button className="prev" onClick={this.props.gotoPrevPage} disabled={this.props.activePageNr === 0}>
							<Icon source={ChevronLeftMinor} />
						</button>
						<div className="nr">{this.props.activePageNr + 1}</div>
						<button
							className="next"
							onClick={this.props.gotoNextPage}
							disabled={this.props.activePageNr + 1 >= this.props.form.pages && this.props.form.pages.length}
						>
							<Icon source={ChevronRightMinor} />
						</button>
					</div>
					<div className="editor__toolbar__buttonGroup zoom">
						<button onClick={this.props.zoomOut}>
							<Icon source={MinusMinor} />
						</button>
						<span className="zoom-icon">
							<Icon source={SearchMajor} />
						</span>
						<button onClick={this.props.zoomIn}>
							<Icon source={MobilePlusMajor} />
						</button>
					</div>
					<div className="editor__toolbar__rightButtons">
						<Button
							primary
							loading={this.props.saving}
							onClick={() => {
								this.props.setZoom(1);
								if (this.props.requireName) {
									this.setState({ nameModalIsOpen: true });
									return;
								}
								this.props.onSave();
							}}
						>
							{this.props.print ? this.props.t("common.actions.print", "Skriv ut") : this.props.t("common.actions.save", "Spara")}
						</Button>
					</div>
				</div>
				<ActiveObjectToolbar
					key={this.props.uniqueId}
					canvases={this.props.canvases}
					canvas={this.props.canvas}
					activeObjects={this.props.activeObjects || []}
					addLastFontSize={this.props.addLastFontSize}
					addLastFontFamily={this.props.addLastFontFamily}
					fontSize={this.props.fontSize}
					page={this.props.activePageNr}
					goToPage={this.props.goToPage}
					setActiveObject={this.props.setActiveObject}
				/>

				<UploadModal
					open={this.state.openUploadImage}
					onClose={this.closeModal.bind(this, "openUploadImage")}
					onSelect={(imgs) => {
						const urls = [imgs].flat(1).map(({ url } = {}) => url);
						this.props.addImage(urls);
						this.closeModal("openUploadImage");
					}}
				/>

				<NameModal
					saving={this.props.saving}
					onSave={this.props.onSave}
					open={this.state.nameModalIsOpen}
					onClose={() => this.setState({ nameModalIsOpen: false })}
				/>
			</div>
		);
	}
}

export default withTranslation(["pdf", "common"], { withRef: true })(Toolbar);
