import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Select from "src/js/components/select/Select";
import TextField from "src/js/components/TextField";

type CustomFieldValueComponentProps = {
	customField: CustomFieldType;
	value: string;
	onChange: (value: string) => void;
	disabled?: boolean;
} & WithTranslation;

const CustomFieldValueComponent = ({ customField, value, onChange, disabled, t }: CustomFieldValueComponentProps) => {
	if (customField.type === "text") {
		return (
			<TextField required={customField.required} placeholder="" key={customField.id} label={customField.title} value={value} onChange={onChange} />
		);
	}

	if (customField.type === "dropdown") {
		return (
			<Select
				required={customField.required}
				key={customField.id}
				disabled={disabled}
				label={customField.title}
				value={value}
				onChange={onChange}
				options={[
					{
						label: t("customfields.fields.default_value.options.select", "Välj"),
						value: "",
					},
					...(customField.options || []),
				]}
			/>
		);
	}

	if (customField.type === "date") {
		return (
			<TextField
				required={customField.required}
				placeholder=""
				key={customField.id}
				label={customField.title}
				value={value}
				onChange={onChange}
				type="date"
			/>
		);
	}

	return null;
};
export default withTranslation(["orders", "comon"])(CustomFieldValueComponent);
