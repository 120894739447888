import styled, { css } from "styled-components";

export const Wrapper = styled.div``;
export const TabsContentView = styled.div``;
export const CardAction = styled.div<{ open?: boolean }>`
	display: flex;
	gap: 0.5rem;
	align-items: center;

	.Polaris-Icon {
		transition: transform 250ms ease-in-out;
	}

	${({ open }) =>
		open &&
		css`
			.Polaris-Icon {
				transform: rotateZ(-180deg);
			}
		`}
`;
