import React, { Component } from "react";
import { Icon, Button, Caption, TextField, Truncate } from "@shopify/polaris";
import { AttachmentMajor, EditMajor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import RemoveCircleButton from "./RemoveCircleButton";

class Attachment extends Component {
	constructor(props) {
		super(props);
		this.state = { editing: false };
		// this.onChange = () => {
		// 	clearTimeout(this.timer);
		// 	this.timer = setTimeout(() => {
		// 		if (props.onChange) props.onChange(props.file);
		// 	}, 750);
		// };
	}

	removeFile() {
		if (this.props.onRemove) this.props.onRemove();
	}

	render() {
		let file;

		if (this.state.editing) {
			file = (
				<TextField
					placeholder={this.props.t("attachment.fields.name.placeholder", "Skriv in namn på filen...")}
					value={this.props.file.name || this.props.file.filename}
					onChange={(value) => {
						this.props.file.name = value;
						this.props.file.filename = value;
						// this.onChange();
						if (this.props.onChange) this.props.onChange(this.props.file, false);
					}}
				/>
			);
		} else {
			file = this.props.file.url ? (
				<a href={this.props.file.url} target="_blank" className="img_link" rel="noreferrer">
					<Truncate>{this.props.file?.name || this.props.file?.filename || "-"}</Truncate>
				</a>
			) : (
				// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
				<p role="button" onClick={this.props.onClick} target="_blank" className="img_link">
					<Truncate>{this.props.file?.name || this.props.file?.filename || "-"}</Truncate>
				</p>
			);
		}

		return (
			<Wrapper
				className={`attachment_files${this.props.plain ? " plain" : ""}`}
				fontSize={this.props.fontSize}
				onClick={(event) => {
					if (!this.props.clickThrough) {
						event.stopPropagation();
					}
				}}
			>
				{this.props.icon === null ? null : React.isValidElement(this.props.icon) ? (
					this.props.icon
				) : (
					<Icon source={this.props.icon || AttachmentMajor} />
				)}

				<Header>
					{/* {this.props.file.user_id && <MemberAvatar user={store.getState().users.find((user) => user.id === this.props.file.user_id)} />} */}
					<Title>
						{file}
						{/* {this.props.file.created_at && <Caption>{moment(this.props.file.created_at).format("YYYY-MM-DD")}</Caption>} */}
						<Caption>
							{this.props.file.size > 1048576 ? (
								<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{(this.props.file.size / 1048576).toFixed(1) || "-"} MB</span>
							) : (
								<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{Math.ceil(this.props.file.size / 1024) || "-"} kB</span>
							)}
						</Caption>
					</Title>

					{this.props.children}
				</Header>

				<RightSideWrapper className="attachment_edit_btn">
					{this.props.editable && this.props.onChange && !this.props.disabled && (
						<Button
							plain
							onClick={(e) => {
								e.stopPropagation();
								this.setState(
									(c) => ({ editing: !c.editing }),
									() => {
										if (!this.state.editing && this.props.onChange) this.props.onChange(this.props.file);
									}
								);
							}}
							primary={this.state.editing}
						>
							{this.state.editing ? this.props.t("common.actions.save", "Spara") : <Icon source={EditMajor} />}
						</Button>
					)}

					{this.props.onOpen && (
						<Button primary onClick={this.props.onOpen}>
							{this.props.t("attachment.actions.choose", "Välj")}
						</Button>
					)}
				</RightSideWrapper>
				{this.props.onRemove && !this.props.disabled && (
					<RemoveCircleButton
						title={this.props.t("attachment.text", "Ta bort fil {{name}}?", {
							name: this.props.file?.filename || this.props.file?.name || "-",
						})}
						onRemove={this.removeFile.bind(this)}
					/>
				)}
			</Wrapper>
		);
	}
}
export default withTranslation(["attachment"], { withRef: true })(Attachment);

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1rem;
	position: relative;
	font-size: ${({ fontSize }) => fontSize};

	.Polaris-Icon {
		margin: auto 0;
		width: 1.4em;
		height: 1.4em;
	}
`;

const Title = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 1rem;
	justify-content: space-between;
	/* flex: 1; */
	.Polaris-Link {
		width: unset;
	}
`;

const Header = styled.div.attrs({ className: "attachment-title" })`
	flex: 1;
	gap: 0.75rem;
	display: flex;
	flex-direction: column;
`;

const RightSideWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const RemoveButton = styled.div.attrs({ className: "removeButton" })`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(40%, -50%);

	button {
		padding: 0.5rem;
		min-height: unset;
		min-width: unset;
	}

	.Polaris-Icon {
		height: 1.5rem;
		width: 1.5rem;
	}
`;
