import React, { Component } from "react";
import { Stack, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";

class BoardFiltersNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	toggleFilter(n, value) {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		if (foundIndex >= 0) {
			if (n == 1) {
				this.props.filters[foundIndex].value = value * 1;
			} else {
				this.props.filters[foundIndex].value2 = value * 1;
			}
		} else if (value && foundIndex < 0) {
			const ob = {
				key: this.props.column_id + "-" + this.props.board.id,
				board_id: this.props.board.id,
				operator: "between",
				column_id: this.props.column_id,
			};
			if (n == 1) {
				ob.value = value * 1;
			} else {
				ob.value2 = value * 1;
			}
			this.props.filters.push(ob);
		}
		if (foundIndex >= 0 && !this.props.filters[foundIndex].value && !this.props.filters[foundIndex].value2) {
			this.props.filters.splice(foundIndex, 1);
		}
		this.props.onChangeFilters(this.props.filters);
	}

	render() {
		const foundIndex = this.props.filters.findIndex((filter) => filter.column_id == this.props.column_id && filter.board_id == this.props.board.id);

		return (
			<Stack distrubution="fillEvenly">
				<TextField
					label={this.props.t("board.filters.number.fields.from.label", "från")}
					type="tel"
					value={foundIndex >= 0 && this.props.filters[foundIndex].value ? this.props.filters[foundIndex].value + "" : ""}
					onChange={this.toggleFilter.bind(this, 1)}
				/>
				<TextField
					label={this.props.t("board.filters.number.fields.to.label", "till")}
					type="tel"
					value={foundIndex >= 0 && this.props.filters[foundIndex].value2 ? this.props.filters[foundIndex].value2 + "" : ""}
					onChange={this.toggleFilter.bind(this, 2)}
				/>
			</Stack>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardFiltersNumber);
