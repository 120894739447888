import React, { Component } from "react";
import { SkeletonPage, Layout, Card, TextContainer, SkeletonDisplayText, SkeletonBodyText } from "@shopify/polaris";

class Skeleton1Col extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<SkeletonPage fullWidth secondaryActions={this.props.secondaryActions || 1} primaryAction={this.props.primaryAction || true}>
				<Layout>
					<Layout.Section>
						<Card sectioned>
							<SkeletonBodyText />
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
						<Card sectioned>
							<TextContainer>
								<SkeletonDisplayText size="small" />
								<SkeletonBodyText />
							</TextContainer>
						</Card>
					</Layout.Section>
				</Layout>
			</SkeletonPage>
		);
	}
}

export default Skeleton1Col;
