import React, { Component } from "react";
import styled from "styled-components";
import ModalBox from "./ModalBox";

class ModalBoxes extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Wrapper>
				{this.props.items?.map((item, index) => {
					return <ModalBox key={index} item={item} />;
				})}
			</Wrapper>
		);
	}
}
export default ModalBoxes;

ModalBoxes.defaultProps = {};

const Wrapper = styled.div`
	display: flex;
	justify-content: space-evenly;
	gap: 3rem;
`;
