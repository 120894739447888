import { Button, Card, Checkbox } from "@shopify/polaris";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import Attachment from "../Attachment";
import Badge from "../Badge";

type EmailRowProps = {
	email: GraphEmail;
	seleted: boolean;
	onSelect: (checked: boolean) => void;
} & WithTranslation;

const EmailRow = ({ email, seleted, onSelect, t }: EmailRowProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [height, setHeight] = useState<number | null>(null);
	const ref = useRef<HTMLDivElement>(null);

	const getDownloadAttachmentHandler = useCallback((attachment) => {
		return () => {
			const type = attachment.contentType;

			fetch(`data:${type};base64,${attachment.contentBytes}`)
				.then((response) => response.blob())
				.then((blob) => {
					const url = window.URL.createObjectURL(blob);

					const link = document.createElement("a");
					link.href = url;
					link.download = attachment.name;
					link.click();
					window.URL.revokeObjectURL(url);
				});
		};
	}, []);

	const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
		const div = e.target as HTMLDivElement;
		if (div.classList.contains("open")) {
			const doc = document.documentElement;
			let { scrollTop: offset } = doc;
			const { y: refY, height: refHeight } = div.getBoundingClientRect();
			const { height: docHeight } = doc.getBoundingClientRect();
			const navHeight = parseFloat(getComputedStyle(doc).fontSize) * 5.6;

			offset = offset + refY - navHeight - (docHeight - refHeight - navHeight) / 2;

			window.scroll({ top: offset, behavior: "smooth" });
		}
	};

	useEffect(() => {
		setHeight(ref?.current?.scrollHeight || null);
	}, [ref]);

	return (
		<Card>
			<Header>
				<Checkbox disabled={email.imported} label="" checked={seleted} onChange={onSelect} />
				<div style={{ flex: 1 }}>
					<EmailSubject>{email.subject}</EmailSubject>
					<p>{moment(email.sentDateTime).format("YYYY-MM-DD HH:mm")}</p>
				</div>
				{email.imported && (
					<Badge style={{ marginLeft: "1rem", padding: "0.5rem 1rem", height: "max-content" }} fontSize={12} status="success">
						{t("emails.status.imported", "Importerad")}
					</Badge>
				)}

				{(height || 0) > 150 && <Button size="large" icon={open ? ChevronUpMinor : ChevronDownMinor} plain onClick={() => setOpen(!open)} />}
			</Header>

			{!!email.attachments?.length && (
				<Card.Section>
					{email.attachments.map((attachment, index) => (
						<Attachment onClick={getDownloadAttachmentHandler(attachment)} key={index} file={attachment} />
					))}
				</Card.Section>
			)}
			<Card.Section>
				{email.body?.content && (
					<EmailPreview
						onTransitionEnd={onTransitionEnd}
						ref={ref}
						dangerouslySetInnerHTML={{ __html: email.body.content }}
						className={open ? "open" : ""}
					/>
				)}
			</Card.Section>
		</Card>
	);
};

export default withTranslation(["emails", "common"], { withRef: true })(EmailRow);

const EmailPreview = styled.div<{ open?: boolean }>`
	transition: max-height 500ms ease-in-out;
	background-color: #ffffff;
	max-height: 150px;
	overflow-y: hidden;
	border-radius: 5px;
	/* color: "#ffffff"; */
	padding: 1rem;
	color: #000;

	&.open {
		max-height: calc(100vh - 9rem);
		transition: max-height 500ms ease-in-out;
		overflow-y: scroll;
	}
	/* td,
	a,
	div {
		color: #ffffff !important;
	} */
`;

const Header = styled.div`
	width: 100%;
	display: flex;
	padding-inline: 2rem;
	padding-top: 2rem;
	gap: 0.5rem;
`;

const EmailSubject = styled.h2.attrs({ className: "Polaris-Header-Title" })`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
	flex: 1;
`;
