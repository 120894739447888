import React, { useEffect } from "react";
import { Route, withRouter } from "react-router-dom";
import { store } from "./js/store";

const RouteWithTitle = ({ route, component: Component, location, ...rest }) => {
	const { name } = route;

	useEffect(() => {
		const titles: string[] = [];
		if (store.getState().account?.company) titles.push(store.getState().account.company);
		if (name) titles.push(name);

		titles.push("Sellfinity");

		document.title = titles.join(" - ");
	}, [location.pathname, name]);

	return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default withRouter(RouteWithTitle as any);
