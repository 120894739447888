import { Button, Icon } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import { ChevronRightMinor, EditMajor } from "@shopify/polaris-icons";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import { RocketIcon } from "../../icons";
import { colorToRgba } from "../../Colors";
import { store } from "../../store";

class HelpBox extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { openModal } = this.props;
		const { id, text, title, children, color, icon } = this.props.data;

		const content = (
			<Wrapper>
				{["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].some((rule) => store.getState().user.roles.includes(rule)) &&
					String(store.getState().account.id) === "24" && (
						<StyledEditButton>
							<Button
								plain
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									openModal();
								}}
								icon={EditMajor}
							/>
						</StyledEditButton>
					)}

				<TitleWrapper>
					<IconWrapper color={color}>
						{icon ? <img alt="" style={{ height: "100%", width: "100%", objectFit: "cover" }} src={icon} /> : <RocketIcon />}
					</IconWrapper>
					<H3>{nl2br(title)}</H3>
				</TitleWrapper>
				<Text>{nl2br(text)}</Text>
				{children}
				<ShowMore>
					{this.props.t("help.terms.show", "Visa")} <Icon source={ChevronRightMinor} />
				</ShowMore>
			</Wrapper>
		);

		return (
			<StyledLink
				to={{
					pathname: `/admin/help/${id}`,
					state: {
						item: this.props.data,
					},
				}}
			>
				{content}
			</StyledLink>
		);
		// if (url)
		// 	return (
		// 		<StyledLink
		// 			to={{
		// 				pathname: url,
		// 				state: {
		// 					item: this.props,
		// 				},
		// 			}}
		// 		>
		// 			{content}
		// 		</StyledLink>
		// 	);

		// return <StyledWrapper>{content}</StyledWrapper>;
	}
}
export default withTranslation(["help", "common"], { withRef: true })(HelpBox);

const StyledLink = styled(Link)``;

const Wrapper = styled.div`
	background-color: var(--cardbg);
	padding: 2.5rem;
	box-shadow: 1px 4px 12px 0px #00000030;
	cursor: pointer;
	color: var(--textColor);
	transition: transform 250ms;
	border-radius: 0.5rem;
	position: relative;

	&:hover {
		transform: scale(1.05);
	}
`;

const TitleWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 2rem;
`;
const Text = styled.p`
	color: var(--textColor2);
	margin-right: 35px;
	font-size: 0.9em;
	height: 40px;
	overflow: hidden;
	line-height: 20px;
	text-overflow: ellipsis;
	white-space: pre-wrap;
`;

const ShowMore = styled.span`
	display: flex;
	justify-content: flex-end;
	color: ${({ color }) => color || "#ffffff"};
	transition: filter 250ms, transform 250ms;
	margin-top: 1rem;

	.Polaris-Icon {
		margin: 0;
		fill: ${({ color }) => color || "#ffffff"};
	}

	&:hover {
		filter: brightness(1.1);
	}
`;

const IconWrapper = styled.div`
	/* background-color: ${({ color }) => (color ? colorToRgba(color, 0.2) : "rgba(100, 100, 100, 0.2)")}; */
	/* border-radius: 100px; */
	min-width: 25px;
	min-height: 25px;
	height: 25px;
	width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	.Polaris-Icon,
	svg {
		min-width: 25px;
		min-height: 25px;
		fill: ${({ color }) => color || "#ffffff"};
	}
`;

const StyledEditButton = styled.div`
	position: absolute;
	right: 2.5rem;
	top: 2.5rem;
`;

const H3 = styled.h3`
	font-size: 22px;
	line-height: 26px;
	font-weight: 700;
`;
