import React, { Component } from "react";
import { ResourceList, DataTable, Card, Spinner, Select, Pagination, Icon, Stack, Checkbox, Layout } from "@shopify/polaris";
import axios from "axios";
import httpBuildQuery from "http-build-query";
import { DeleteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import styled from "styled-components";
import nl2br from "react-nl2br";
import API from "../API";
import SavedSearchTabs from "./saved_search_tabs.js";
import MyFilters from "./MyFilters.js";
import { store } from "../store";
import { toastr } from "./toastr.js";
import Page from "./page";
import { parseQuery } from "./BigData/utils";
import LimitField from "./LimitField";

class BigData extends Component {
	constructor(props) {
		super(props);
		this.state = { columns: [] };
		this.state = {
			loading: true,
			items: [],
			totals: [],
			count: 0,
			offset: 0,
			limit: props.limit || 50,
			searchValue: "",
			selectedItems: [],
			appliedFilters: props.defaultAppliedFilters || [],
			resourceUrl: props.resourceUrl,
			saveableSearch: false,
			timeout: null,
			sortValue: this.getDefaultSortValue(),
			defaultSortValue: this.getDefaultSortValue(),
			cancelToken: null,
			fieldOptionActive: false,
			selectedFields: props.selectedFields,
			queryString: "",
			columns: [],
		};

		for (let i = 0; i < this.props.columns.length; i++) {
			if (i <= this.getMaxColumns()) {
				let index = i;
				if (index > 0) {
					if (
						this.props.savedSearchHandle in store.getState().column_selection &&
						i in store.getState().column_selection[this.props.savedSearchHandle] &&
						store.getState().column_selection[this.props.savedSearchHandle][i]
					) {
						index = store.getState().column_selection[this.props.savedSearchHandle][i];
					}
				}
				const col = this.props.columns[index];
				col.index = index;
				this.state.columns.push(col);
			}
		}

		if (props.history.location.search) {
			this.state.queryString = props.history.location.search;
			const state = this.parseState(props.history.location.search);
			// eslint-disable-next-line no-restricted-syntax
			for (const i in state) {
				this.state[i] = state[i];
			}
		}
	}

	getMaxColumns() {
		if (window.innerWidth > 1900) {
			return 6;
		} else if (window.innerWidth > 1700) {
			return 5;
		} else if (window.innerWidth > 1500) {
			return 4;
		}
		return 3;
	}

	parseState(queryString) {
		const data = parseQuery(queryString);

		const state = {
			offset: 0,
			sortValue: this.state.defaultSortValue,
			searchValue: "",
			appliedFilters: [],
		};

		// eslint-disable-next-line no-restricted-syntax
		for (const i in data) {
			if (i == "offset") {
				if (data[i]) {
					state.offset = parseInt(data[i]);
				} else {
					state.offset = 0;
				}
			} else if (i == "sort") {
				state.sortValue = data[i];
			} else if (i == "q") {
				state.searchValue = data[i];
			} else {
				state.appliedFilters.push({ key: i, value: data[i] });
			}
		}

		return state;
	}

	getDefaultSortValue() {
		if (this.props.defaultSort) {
			return this.props.defaultSort;
		}
		for (let i = 0; i < this.state.columns; i++) {
			if (this.state.columns[i].sortable) {
				return this.state.columns[i].handle + "_DESC";
			}
		}
		return "ID_DESC";
	}

	getDefaultSortDirection() {
		const defaultSortValue = this.getDefaultSortValue();
		for (let i = 0; i < this.state.columns; i++) {
			if (
				this.state.columns[i].sortable &&
				(this.state.columns[i].handle + "_DESC" == defaultSortValue || this.state.columns[i].handle + "_ASC" == defaultSortValue)
			) {
				if (this.state.columns[i].handle + "_DESC" == defaultSortValue) {
					return "descending";
				} else if (this.state.columns[i].handle + "_ASC" == defaultSortValue) {
					return "asccending";
				}
			}
		}
	}

	getDefaultSortIndex() {
		const defaultSortValue = this.getDefaultSortValue();
		for (let i = 0; i < this.state.columns; i++) {
			if (
				this.state.columns[i].sortable &&
				(this.state.columns[i].handle + "_DESC" == defaultSortValue || this.state.columns[i].handle + "_ASC" == defaultSortValue)
			) {
				return i;
			}
		}
	}

	clickIndex(event) {
		if (!$(event.target).closest(".Polaris-DataTable__TableRow").length) {
			return;
		}
		const index = $(event.target).closest(".Polaris-DataTable__TableRow").index();
		const item = this.state.items[index];

		if (
			((this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) &&
				(this.state.selectedItems == "All" || this.state.selectedItems.length > 0)) ||
			event.target?.className?.includes("Polaris-Checkbox")
		) {
			// Selection mode
			// checkbox onChange triggers "handleSelectChange" and then clickIndex runs "handleSelectChange" runs twice after so a total of 3 (add,remove,add)
			// If we only run the checkbox onChange then the bulkactions wont show.
			this.handleSelectChange(item.id, index);
		} else if (this.props.onClickRow) {
			event.stopPropagation();

			this.props.onClickRow(item);
		}
	}

	componentDidUpdate() {
		$(".Polaris-DataTable tbody input:checked").closest("tr").addClass("selected");
		$(".Polaris-DataTable tbody input").not(":checked").closest("tr").removeClass("selected");
		$(".Polaris-DataTable tbody input").not(":checked").closest("tr").removeClass("selected");
	}

	// used for getting the params outside of this component
	// eslint-disable-next-line react/no-unused-class-component-methods
	getParams() {
		const params = Object.assign({}, this.props.params || {});

		for (let i = 0; i < this.state.appliedFilters?.length; i++) {
			params[this.state.appliedFilters[i].key] = this.state.appliedFilters[i].value;
		}
		params.q = this.state.searchValue;
		params.sort = this.state.sortValue;
		params.offset = this.state.offset;
		params.limit = this.state.limit;

		return params;
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (this.state.queryString != props.history.location.search) {
			this.state.queryString = props.history.location.search;
			this.setState({ queryString: this.state.queryString, selectedItems: [] });
			const state = this.parseState(props.history.location.search);
			this.setState(state);
			this.fetchItems(state.appliedFilters, state.searchValue, state.sortValue, state.offset, false);
			this.props.onSelectionChange?.([]);
		}
		if (props.resourceUrl != this.state.resourceUrl) {
			this.state.resourceUrl = props.resourceUrl;
			this.setState({ resourceUrl: props.resourceUrl, defaultSortValue: this.getDefaultSortValue() }, this.reload.bind(this, false));
		}
		if (props.columns) {
			const columns = [];
			for (let i = 0; i < props.columns.length; i++) {
				if (i <= this.getMaxColumns()) {
					let index = i;
					if (index > 0) {
						if (
							props.savedSearchHandle in store.getState().column_selection &&
							i in store.getState().column_selection[this.props.savedSearchHandle] &&
							store.getState().column_selection[this.props.savedSearchHandle][i]
						) {
							index = store.getState().column_selection[this.props.savedSearchHandle][i];
						}
					}
					const col = props.columns[index];
					col.index = index;
					// if (!this.state.columns.find((c) => c.handle === col.handle)) {
					columns.push(col);
					// }
				}
			}
			this.setState({ columns });
		}
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	handleSearchChange(searchValue) {
		clearTimeout(this.state.timeout);
		this.setState({
			offset: 0,
			searchValue,
			loading: true,
			timeout: setTimeout(this.updateSearch.bind(this, this.state.appliedFilters, searchValue, this.state.sortValue), 10),
		});
	}

	handleSortChange(index, direction) {
		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			index--;
		}
		if (this.props.renderMedia) {
			index--;
		}
		const handle = this.state.columns[index].handle;
		if (direction == "descending") {
			direction = "DESC";
		} else if (direction == "ascending") {
			direction = "ASC";
		}
		this.state.sortValue = handle + "_" + direction;
		this.setState({ sortValue: this.state.sortValue });
		this.updateSearch(this.state.appliedFilters, this.state.searchValue, this.state.sortValue);
	}

	handleFiltersChange(appliedFilters) {
		this.setState({ appliedFilters, offset: 0 });
		if (this.props.onFiltersChange) {
			this.props.onFiltersChange(appliedFilters);
		}
		this.updateSearch(appliedFilters, this.state.searchValue, this.state.sortValue);
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	fetchItems(filters, search, sorting, offset, silent) {
		this.cancelRequest();
		const CancelToken = axios.CancelToken;

		const params = Object.assign({}, this.props.params || {}, store.getState().authentication.defaultParams);

		for (let i = 0; i < filters.length; i++) {
			params[filters[i].key] = filters[i].value;
		}
		params.q = search;
		params.sort = sorting;
		params.offset = offset;
		params.limit = this.state.limit;

		if (!silent) this.setState({ loading: true });
		API.get(this.props.resourceUrl, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				this.setState({
					loading: false,
					count: result.data.count,
					items:
						(this.props.onFilter ? this.props.onFilter(result.data[this.props.resourceHandle], params) : result.data[this.props.resourceHandle]) ||
						[],
					totals: result.data.totals,
				});

				if (this.props.onItemsFetched) {
					this.props.onItemsFetched(result.data[this.props.resourceHandle]);
				}
				if (this.props.onResultFetched) {
					this.props.onResultFetched(result.data);
				}
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					// eslint-disable-next-line no-console
					console.debug("Request canceled");
				} else {
					toastr.error(error);
				}
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	componentDidMount() {
		this.fetchItems(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, this.state.offset);
		if (this.props.setRefreshHandler) this.props.setRefreshHandler(this.reload.bind(this));
		this.clickHandler = this.clickIndex.bind(this);

		this.ref?.addEventListener("click", this.clickHandler, false);
	}

	componentWillUnmount() {
		this.ref?.removeEventListener("click", this.clickHandler, false);
	}

	changeTab(tab) {
		// let pcs = tab.sorting.split("_");
		// if (pcs.length > 1) {
		// 	let handle = [];
		// 	for (let i = 0; i < pcs.length - 1; i++) {
		// 		handle.push(pcs[i]);
		// 	}
		// 	handle = handle.join("_");
		// }

		this.setState({ appliedFilters: tab.filters, searchValue: tab.search, offset: 0 });
		this.updateSearch(tab.filters, tab.search, this.state.sortValue, 0);
	}

	setSaveable(val) {
		this.setState({ saveableSearch: val });
	}

	saveSearchModal() {
		this.setState({ showSaveSearchModal: true });
	}

	closeSaveSearchModal() {
		this.setState({ showSaveSearchModal: false });
	}

	removeSearchModal() {
		this.setState({ removeSearchModal: true });
	}

	closeRemoveSearchModal() {
		this.setState({ removeSearchModal: false });
	}

	updateSearch(filters, search, sorting, offset) {
		const params = {};
		for (let i = 0; i < filters.length; i++) {
			params[filters[i].key] = filters[i].value;
		}
		params.q = search;
		params.sort = sorting;
		params.offset = offset;
		this.props.onUpdateParams?.(Object.assign({}, params));
		this.props.history.replace(this.props.history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
	}

	reload(silent = false) {
		this.setState({ selectedItems: [] });
		this.fetchItems(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, this.state.offset, silent);
	}

	goPrevious() {
		if (this.state.offset > 0) {
			const offset = this.state.offset - this.state.limit;
			this.setState({ offset });
			this.updateSearch(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, offset);
		}
	}

	goNext() {
		if (this.state.offset + this.state.limit < this.state.count) {
			const offset = this.state.offset + this.state.limit;
			this.setState({ offset });
			this.updateSearch(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, offset);
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	getSelectedItems() {
		return this.state.selectedItems;
	}

	handleSelectChange(id, key) {
		if (this.state.selectedItems == "All") {
			const selection = [];
			for (let i = 0; i < this.state.items.length; i++) {
				if (this.state.items[i].id != id) {
					selection.push(this.state.items[i].id);
				}
			}
			this.state.selectedItems = selection;
			this.setState({ selectedItems: selection });
		} else {
			if (this.state.selectedItems.indexOf(id) >= 0) {
				const selection = [];
				for (let i = 0; i < this.state.selectedItems.length; i++) {
					if (this.state.selectedItems[i] != id) {
						selection.push(this.state.selectedItems[i]);
					}
				}
				this.state.selectedItems = selection;
				this.setState({ selectedItems: selection });
			} else {
				this.state.selectedItems.push(id);
				this.setState({ selectedItems: this.state.selectedItems });
			}
		}
		$(".datatable ul.Polaris-ResourceList input").eq(key).click();
		this.props.onSelectionChange?.(this.state.selectedItems);
	}

	renderRow(item, key) {
		const data = [];

		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			data.push(
				<span
					onClick={(event) => {
						event.stopPropagation();
					}}
				>
					<Checkbox
						checked={!!(this.state.selectedItems == "All" || this.state.selectedItems.indexOf(item.id) >= 0)}
						onChange={this.handleSelectChange.bind(this, item.id, key)}
					/>
				</span>
			);
		}

		if (this.props.renderMedia) {
			data.push(this.props.renderMedia(item));
		}

		for (let i = 0; i < this.state.columns.length; i++) {
			data.push(this.props.renderCell(item, this.state.columns[i]));
		}

		return data;
	}

	// toggleFieldOptionPopover() {
	// 	this.setState({ fieldOptionActive: !this.state.fieldOptionActive });
	// }

	renderDummyItem(item) {
		return <ResourceList.Item id={item.id}>dummy</ResourceList.Item>;
	}

	// onSelectFieldAction(item) {
	// 	this.setState({ fieldOptionActive: false });
	// 	this.setState({ selectedFields: [item.value] });
	// 	this.props.onSelectedFieldsChange([item.value]);
	// }

	renderTotals() {
		if (!this.state.totals || !this.props.renderTotal) {
			return null;
		}
		const arr = [];
		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			arr.push("");
		}
		if (this.props.renderMedia) {
			arr.push("");
		}
		let n = 0;
		for (let i = 0; i < this.state.columns.length; i++) {
			const val = this.props.renderTotal(this.state.totals, this.state.columns[i]);
			arr.push(val);
			if (val && this.state.columns[i].handle != "TITLE" && this.state.columns[i].handle != "NAME") {
				n++;
			}
		}
		if (!n) {
			return null;
		}
		return arr;
	}

	changeColumn(index, i) {
		const col = this.props.columns[i];
		col.index = i;
		this.state.columns[index] = col;
		this.setState({ columns: this.state.columns });
		store.dispatch({
			type: "UPDATE_COLUMN_SELECTION",
			handle: this.props.savedSearchHandle,
			index,
			selection: i,
		});
	}

	render() {
		$(".Polaris-EmptySearchResult__Image").attr("src", "/assets/images/empty_state/NoResults.png");

		let haveActions = false;

		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			haveActions = true;
		}

		const resourceName = this.props.resourceName;

		const filters = this.props.filters;

		const hasPrevious = this.state.offset > 0;
		const hasNext = this.state.offset + this.state.limit < this.state.count;

		const filterControl = (
			<MyFilters
				hideQueryField={this.props.hideQueryField}
				filters={filters}
				appliedFilters={this.state.appliedFilters}
				onFiltersChange={this.handleFiltersChange.bind(this)}
				searchValue={this.state.searchValue}
				onSearchChange={this.handleSearchChange.bind(this)}
				additionalAction={
					this.state.saveableSearch
						? {
								content: this.props.t("components.big_search.saved_searches.actions.save", "Spara"),
								onAction: this.saveSearchModal.bind(this),
						  }
						: (this.state.appliedFilters.length > 0 || this.state.searchValue) &&
						  !this.state.saveableSearch &&
						  typeof this.savedSearchRef?.getSelectedTab()?.id != "string"
						? {
								destructive: true,
								content: <Icon source={DeleteMajor} />,
								onAction: this.removeSearchModal.bind(this),
						  }
						: null
				}
			/>
		);

		const columnTypes = [];
		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			columnTypes.push("text");
		}
		if (this.props.renderMedia) {
			columnTypes.push("text");
		}
		for (let i = 0; i < this.state.columns.length; i++) {
			columnTypes.push(this.state.columns[i].type);
		}

		const headings = [];
		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			headings.push("");
		}
		if (this.props.renderMedia) {
			headings.push("");
		}
		for (let i = 0; i < this.state.columns.length; i++) {
			if (i == 0) {
				headings.push(this.state.columns[i].label);
			} else if (i <= this.getMaxColumns()) {
				const options = [];
				for (let x = 1; x < this.props.columns.length; x++) {
					options.push({ label: this.props.columns[x].label, value: x + "" });
				}
				headings.push(
					<span
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<Select options={options} value={this.state.columns[i].index + ""} onChange={this.changeColumn.bind(this, i)} />
					</span>
				);
			}
		}

		const sortable = [];
		if (this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0) {
			sortable.push(false);
		}
		if (this.props.renderMedia) {
			sortable.push("");
		}
		for (let i = 0; i < this.state.columns.length; i++) {
			sortable.push(!!this.state.columns[i].sortable);
		}

		return (
			<Page
				fullWidth
				breadcrumbs={this.props.breadcrumbs}
				title={this.props.title}
				primaryAction={this.props.primaryAction}
				secondaryActions={this.props.secondaryActions}
				pagination={{
					hasPrevious,
					onPrevious: this.goPrevious.bind(this),
					hasNext,
					onNext: this.goNext.bind(this),
				}}
				subtitle={this.props.subtitle}
				titleMetadata={this.props.titleMetadata}
			>
				{this.props.description && <Description>{nl2br(this.props.description)}</Description>}
				{this.props.extraHeader || null}
				<div
					ref={(ref) => {
						this.ref = ref;
					}}
					className={
						"big_data datatable" +
						(this.state.selectedItems.length > 0 ? " haveselection" : "") +
						(haveActions ? " haveactions" : "") +
						(this.props.renderMedia ? " havemedia" : "") +
						(this.props.onClickRow ? " clickable" : "")
					}
				>
					<Layout>
						<Layout.Section>
							{this.props.secondTitle && <h1 className="Polaris-Header-Title">{this.props.secondTitle}</h1>}
							<Card>
								<Stack wrap={false} spacing="none">
									<Stack.Item fill>
										<SavedSearchTabs
											ref={(ref) => {
												this.savedSearchRef = ref;
											}}
											resource={this.props.savedSearchHandle}
											resourceName={this.props.resourceName}
											showModal={this.state.showSaveSearchModal}
											onCloseModal={this.closeSaveSearchModal.bind(this)}
											showRemoveModal={this.state.removeSearchModal}
											onCloseRemoveModal={this.closeRemoveSearchModal.bind(this)}
											onChange={this.changeTab.bind(this)}
											onSaveable={this.setSaveable.bind(this)}
											defaultSort={this.state.defaultSortValue}
											sorting={this.state.sortValue}
											search={this.state.searchValue}
											filters={this.state.appliedFilters}
											defaultSavedSearches={this.props.defaultSavedSearches}
										/>
									</Stack.Item>
									{this.props.selector ? (
										<Stack.Item>
											<div
												style={{
													padding: 10,
													height: 53,
													borderLeft: "0.1rem solid #dfe3e8",
													borderBottom: "0.1rem solid #dfe3e8",
												}}
											>
												{this.props.selector}
											</div>
										</Stack.Item>
									) : null}
								</Stack>
								{this.props.promotedBulkActions?.length > 0 || this.props.bulkActions?.length > 0 ? (
									<ResourceList
										showHeader
										resourceName={resourceName}
										items={this.state.items}
										hasMoreItems={store.getState().user.roles.indexOf("ROLE_SUPER_ADMIN") >= 0 && this.state.count > this.state.limit}
										filterControl={filterControl}
										selectedItems={this.state.selectedItems}
										loading={this.state.loading}
										onSelectionChange={(selection) => {
											this.state.selectedItems = selection;
											this.setState({ selectedItems: selection });
											this.props.onSelectionChange?.(selection);
										}}
										promotedBulkActions={this.props.promotedBulkActions}
										renderItem={this.renderDummyItem}
										bulkActions={this.props.bulkActions}
										sortValue={this.state.sortValue}
										onSortChange={this.handleSortChange.bind(this)}
										emptyState={this.props.emptyState}
									/>
								) : (
									<div>
										<div className="Polaris-ResourceList__FiltersWrapper">{filterControl}</div>
										{/* {this.state.loading && !this.state.items.length ? ( */}
										{this.state.loading ? (
											<div style={{ textAlign: "center" }}>
												<Spinner />
											</div>
										) : null}
									</div>
								)}
								{this.state.items && this.state.items.length > 0 ? (
									<div style={this.state.loading ? { opacity: 0.5 } : null}>
										<DataTable
											columnContentTypes={columnTypes}
											headings={headings}
											truncate={false}
											rows={this.state.items.map((item, index) => this.renderRow(item, index))}
											totals={this.renderTotals()}
											sortable={sortable}
											defaultSortDirection={this.getDefaultSortDirection()}
											initialSortColumnIndex={this.getDefaultSortIndex()}
											showTotalsInFooter
											footerContent={this.props.t("components.big_data.footer", "Visar {{from}} - {{to}} av {{total}} {{resourceName}}", {
												from: this.state.offset + 1,
												to: this.state.offset + this.state.items.length,
												total: this.state.count,
												resourceName: this.state.count == 1 ? this.props.resourceName.singular : this.props.resourceName.plural,
											})}
											onSort={this.handleSortChange.bind(this)}
										/>
									</div>
								) : null}
							</Card>

							{!this.props.emptyState && (
								<Footer>
									<Pagination hasPrevious={hasPrevious} onPrevious={this.goPrevious.bind(this)} hasNext={hasNext} onNext={this.goNext.bind(this)} />
									{store.getState().user.roles.includes("ROLE_ADMIN") && (
										<LimitField
											value={this.state.limit}
											onChange={(v) => {
												this.setState({ limit: v }, () =>
													this.fetchItems(this.state.appliedFilters, this.state.searchValue, this.state.sortValue, this.state.offset)
												);
											}}
										/>
									)}
								</Footer>
							)}
						</Layout.Section>
						{this.props.secondaryLayout && <Layout.Section secondary>{this.props.secondaryLayout}</Layout.Section>}
					</Layout>
				</div>
				{this.props.footer}
			</Page>
		);
	}
}

export default withTranslation(["components"], { withRef: true })(BigData);

const Description = styled.p`
	/* margin-top: -1.6rem; */
	margin-bottom: 1.6rem;
	color: var(--textColor2);
`;

const Footer = styled.div`
	height: 100px;
	padding-top: 30px;
	text-align: center;

	display: flex;
	flex-direction: column;
	width: max-content;
	margin: auto;
	gap: 1rem;
	margin-bottom: 3rem;
`;
