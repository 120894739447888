import React, { Component } from "react";
import { Button, Popover, Stack } from "@shopify/polaris";

import {
	GrammarMajor,
	CalendarMajor,
	QuestionMarkMajor,
	OrdersMajor,
	RepeatOrderMajor,
	CircleAlertMajor,
	CircleTickMajor,
	ChatMajor,
	PhoneMajor,
	UploadMajor,
	CircleDotsMajor,
	CircleCancelMajor,
	CircleDisabledMajor,
	AnalyticsMajor,
	BalanceMajor,
	CustomersMajor,
	EmailMajor,
} from "@shopify/polaris-icons";
import MyPopover from "../../../components/Popover";

export function getIconByString(string) {
	if (string == "GrammarMajor") {
		return GrammarMajor;
	} else if (string == "CalendarMajor") {
		return CalendarMajor;
	} else if (string == "QuestionMarkMajor") {
		return QuestionMarkMajor;
	} else if (string == "OrdersMajor") {
		return OrdersMajor;
	} else if (string == "PhoneMajor") {
		return PhoneMajor;
	} else if (string == "RepeatOrderMajor") {
		return RepeatOrderMajor;
	} else if (string == "CircleAlertMajor") {
		return CircleAlertMajor;
	} else if (string == "CircleTickMajor") {
		return CircleTickMajor;
	} else if (string == "ChatMajor") {
		return ChatMajor;
	} else if (string == "CircleDotsMajor") {
		return CircleDotsMajor;
	} else if (string == "CircleDisabledMajor") {
		return CircleDisabledMajor;
	} else if (string == "CircleCancelMajor") {
		return CircleCancelMajor;
	} else if (string == "AnalyticsMajor") {
		return AnalyticsMajor;
	} else if (string == "BalanceMajor") {
		return BalanceMajor;
	} else if (string == "CustomersMajor") {
		return CustomersMajor;
	} else if (string == "UploadMajor") {
		return UploadMajor;
	} else if (string == "MailIcon") {
		return EmailMajor;
	}

	return GrammarMajor;
}

export default class IconSelector extends Component {
	constructor(props) {
		super(props);

		this.state = { active: false, icon: props.icon, color: props.color };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ icon: props.icon, color: props.color });
	}

	selectIcon(icon) {
		this.setState({ icon, active: false });
		this.props.onChange(icon);
	}

	selectColor(color) {
		this.setState({ color, active: false });
		this.props.onChangeColor(color);
	}

	render() {
		const icons = [
			"GrammarMajor",
			"PhoneMajor",
			"CalendarMajor",
			"QuestionMarkMajor",
			"OrdersMajor",
			"RepeatOrderMajor",
			"CircleAlertMajor",
			"CircleTickMajor",
			"UploadMajor",
			"ChatMajor",
			"CircleDotsMajor",
			"CircleDisabledMajor",
			"CircleCancelMajor",
			"AnalyticsMajor",
			"BalanceMajor",
			"CustomersMajor",
		];
		return (
			<MyPopover
				active={this.state.active}
				activator={
					<Button
						plain
						onClick={() => {
							this.setState({ active: true });
						}}
						icon={getIconByString(this.state.icon)}
					/>
				}
				onClose={() => {
					this.setState({ active: false });
				}}
			>
				<Popover.Section>
					<div style={{ maxWidth: 200 }}>
						<Stack>
							{icons.map((icon, key) => (
								<Button key={key + ""} icon={getIconByString(icon)} onClick={this.selectIcon.bind(this, icon)} />
							))}
						</Stack>
					</div>
				</Popover.Section>
				{this.props.onChangeColor ? (
					<Popover.Section>
						<div style={{ maxWidth: 200 }}>
							<Stack>
								{["pink", "blue", "orange", "purple", "green", "yellow"].map((color, key) => (
									<div
										key={key + ""}
										onClick={this.selectColor.bind(this, color)}
										className={"selector color-" + color}
										style={{
											borderRadius: 5,
											height: 20,
											width: 20,
											border: color == this.state.color ? "1px solid red" : "1px solid white",
										}}
									/>
								))}
							</Stack>
						</div>
					</Popover.Section>
				) : null}
			</MyPopover>
		);
	}
}
