import React, { Component } from "react";
import { Modal, FormLayout, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";

class CompanyContactModal extends Component {
	constructor(props) {
		super(props);

		this.state = { open: props.open, status_column_id: null };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
		}
	}

	getColumns(types) {
		const arr = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf("*") >= 0 || types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push(this.props.board.columns[i]);
			}
		}
		return arr;
	}

	saveForm() {
		this.setState({ saving: true });
		const data = {
			connected_column_id: this.state.status_column_id,
		};
		API.put("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				this.props.onUpdateColumn(result.data.column);
				toastr.success(this.props.t("contact.automations.responses.settings.updated", "Sparade inställning för automatisk kontaktkoppling"));
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		const statusOptions = this.getColumns(["contact"]).map((column) => ({
			value: column.id + "",
			label: column.title,
		}));
		statusOptions.unshift({
			value: "",
			label: this.props.t("column.actions.choose", "Välj kolumn"),
		});

		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("contact.automations.title", "Automatiskt kontaktkoppling")}
				primaryAction={{
					disabled: !this.state.status_column_id,
					onAction: this.saveForm.bind(this),
					loading: this.state.saving,
					content: this.props.t("contact.automations.terms.connect_columns", "Koppla ihop kolumner"),
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<Modal.Section>
					<FormLayout>
						<p>{this.props.t("contact.automations.text.connect", "Anslut en kontaktkolumn att uppdatera när vi får en träff i företagsregistret")}</p>
						<Select
							label={this.props.t("contact.automations.fields.column_id.label", "Välj en kontaktkolumn")}
							options={statusOptions}
							value={this.state.status_column_id}
							onChange={(v) => {
								this.setState({ status_column_id: v });
							}}
						/>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}

export default withTranslation(["contact", "common"], { withRef: true })(CompanyContactModal);
