import React, { Component } from "react";
import { Modal, Stack, TextField, FormLayout, TextStyle, Avatar, ActionList, Button, Icon, Tooltip } from "@shopify/polaris";
import { ChevronDownMinor, CircleInformationMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API from "../../../API";
import MemberAvatar from "./MemberAvatar";
import { toastr } from "../../../components/toastr.js";
import MemberRoles from "./MemberRoles";
import { store } from "../../../store";
import ResourcePicker from "../../../components/resource_picker";
import Banner from "../../../components/banner";
import NumberBadge from "../../../components/NumberBadge.js";
import Colors from "../../../Colors.js";

class BoardMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roles: props.roles || [],
			open: false,
			page: null,
			selectedRoles: [],
			workspace: props.workspace,
			board: props.board,
			dashboard: props.dashboard,
			type: props.type || (props.board ? "board" : props.workspace ? "workspace" : props.dashboard ? "dashboarad" : ""),
		};
	}

	componentDidMount() {
		if (!this.props.roles) {
			const BoardWorkspaceDashboard = this.props.board || this.props.workspace || this.props.dashboard || {};
			const members = BoardWorkspaceDashboard.members;
			if (members && members.length) {
				const meAsMember = members.find((m) => m.user_id === store.getState().user.id);
				if (meAsMember) {
					this.setState({ roles: meAsMember.roles });
				}
			} else {
				if (this.props.dashboard) {
					const endpoint = "/api/dashboards/" + this.props.dashboard.id + ".json";
					API.get(endpoint).then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
							this.setState({ saving: false });
							return;
						}
						if (result.data.dashboard && result.data.dashboard.members) {
							const meAsMember = result.data.dashboard.members.find((m) => m.user_id === store.getState().user.id);
							this.setState({
								roles: meAsMember.roles,
								dashboard: result.data.dashboard,
								type: "dashboard",
							});
						}
					});
				} else if (this.props.workspace) {
					const endpoint = `/api/workspaces/${this.props.workspace.id}.json`;
					API.get(endpoint).then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
							this.setState({ saving: false });
							return;
						}
						if (result.data.workspace) {
							const workspace = result.data.workspace;
							if (workspace && workspace.members) {
								const meAsMember = workspace.members.find((m) => m.user_id === store.getState().user.id);
								this.setState({
									roles: meAsMember.roles,
									workspace,
									type: "workspace",
								});
							}
						}
					});
				} else if (this.props.board) {
					const endpoint = "/api/boards/" + this.props.board.id + ".json";
					API.get(endpoint).then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
							this.setState({ saving: false });
							return;
						}
						if (result.data.board && result.data.board.members) {
							const meAsMember = result.data.board.members.find((m) => m.user_id === store.getState().user.id);
							this.setState({
								roles: meAsMember.roles,
								board: result.data.board,
								type: "board",
							});
						}
					});
				}
			}
		}
	}

	editMember(member) {
		if (
			this.state.roles.indexOf(`ROLE_${this.state.type.toUpperCase()}_ADMIN`) >= 0 ||
			this.state.roles.indexOf(`ROLE_${this.state.type.toUpperCase()}_OWNER`) >= 0
		) {
			this.setState({
				page: "editMember",
				editingMember: member,
				selectedRoles: member.roles,
			});
		}
	}

	submiteNewRoles(member) {
		this.setState({ saving: true });
		let endpoint;
		if (this.state.dashboard) {
			endpoint = "/api/dashboards/" + this.state.dashboard.id + "/members/" + member.id + ".json";
		} else if (this.state.workspace) {
			endpoint = "/api/workspaces/" + this.state.workspace.id + "/members/" + member.id + ".json";
		} else {
			endpoint = "/api/boards/" + this.state.board.id + "/members/" + member.id + ".json";
		}
		API.put(endpoint, { roles: this.state.selectedRoles }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				this.setState({ saving: false });

				if (this.state.workspace && this.props.onUpdateWorkspace) {
					this.props.onUpdateWorkspace(result.data.workspace);
				} else if (this.state.dashboard && this.props.onUpdateDashboard) {
					this.props.onUpdateDashboard(result.data.dashboard);
				} else if (this.props.onUpdateBoard) {
					this.props.onUpdateBoard(result.data.board);
				}
				toastr.success(this.props.t("board.members.responses.roles_updated", "Roler uppdaterade"));
				this.setPage(null);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	removeMember(member) {
		this.setState({ saving: true });
		let endpoint;
		if (this.state.dashboard) {
			endpoint = "/api/dashboards/" + this.state.dashboard.id + "/members/" + member.id + ".json";
		} else if (this.state.workspace) {
			endpoint = "/api/workspaces/" + this.state.workspace.id + "/members/" + member.id + ".json";
		} else {
			endpoint = "/api/boards/" + this.state.board.id + "/members/" + member.id + ".json";
		}
		API.delete(endpoint, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}

				this.setState({ saving: false });

				if (this.state.workspace && this.props.onUpdateWorkspace) {
					this.props.onUpdateWorkspace(result.data.workspace);
				} else if (this.state.dashboard && this.props.onUpdateDashboard) {
					this.props.onUpdateDashboard(result.data.dashboard);
				} else if (this.props.onUpdateBoard) {
					this.props.onUpdateBoard(result.data.board);
				}

				toastr.success(this.props.t("board.members.responses.removed", "Tog bort medlem"));

				this.setPage(null);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	isEmailValid(email) {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	}

	submitInviteContact() {
		const isEmailValid = this.isEmailValid(this.state.email);
		if (!isEmailValid) {
			this.setState({
				emailError: this.props.t("board.members.errors.invalid_email", "Ogiltig email"),
			});
			return;
		}
		this.setState({ saving: true });
		let endpoint;
		if (this.state.dashboard) {
			endpoint = "/api/dashboards/" + this.state.dashboard.id + "/members.json";
		} else if (this.state.workspace) {
			endpoint = "/api/workspaces/" + this.state.workspace.id + "/members.json";
		} else {
			endpoint = "/api/boards/" + this.state.board.id + "/members.json";
		}
		API.post(
			endpoint,
			{
				title: this.state.title,
				email: this.state.email,
				roles: this.state.selectedRoles,
			},
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				this.setState({ saving: false });

				if (this.state.workspace && this.props.onUpdateWorkspace) {
					this.props.onUpdateWorkspace(result.data.workspace);
				} else if (this.state.dashboard && result.data.dashboard && this.props.onUpdateDashboard) {
					this.props.onUpdateDashboard(result.data.dashboard);
				} else if (result.data.board && this.props.onUpdateBoard) {
					this.props.onUpdateBoard(result.data.board);
				}
				toastr.success(this.props.t("board.members.responses.invited", "Bjöd in ny medlem"));
				this.handleClose();
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	getBoard() {
		if (this.state.workspace) {
			return this.state.workspace;
		}
		if (this.state.dashboard) {
			return this.state.dashboard;
		}
		if (this.state.board) {
			return this.state.board;
		}
	}

	setPage(page) {
		this.setState({
			page,
			editingMember: null,
			selectedRoles: [],
			saving: null,
			title: null,
			email: null,
		});
	}

	handleClose() {
		this.setState({
			open: false,
			page: null,
			editingMember: null,
			selectedRoles: [],
			saving: null,
			title: null,
			email: null,
		});
		if (this.props.onClose) this.props.onClose();
	}

	renderModalContent(params) {
		switch (this.state.page) {
			case "inviteType":
				return (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							padding: "25px",
						}}
					>
						<Button onClick={() => this.setPage("newUser")}>{this.props.t("board.members.singular", "Bjud in ny medlem")}</Button>
						{!this.props.hideNewExternal && (
							<Button onClick={() => this.setPage("newExternalUser")}>
								{this.props.t("board.members.actions.new_external", "Ny (extern) användare")}
							</Button>
						)}
					</div>
				);
			case "editMember":
				return (
					<React.Fragment>
						<MemberAvatar
							member={this.state.editingMember}
							showName
							style={{
								display: "flex",
								gap: "15px",
								alignItems: "center",
								paddingLeft: "0.8rem",
								marginBottom: "10px",
							}}
						/>
						<MemberRoles
							type={this.state.type}
							onChange={(value) => this.setState({ selectedRoles: value })}
							selected={(this.state.editingMember && this.state.editingMember.roles) || []}
						/>
					</React.Fragment>
				);
			case "newUser":
				return (
					<FormLayout>
						<div style={{ minHeight: "200px" }}>
							<ResourcePicker
								caption={<Icon source={ChevronDownMinor} />}
								showJustContent
								fixedCaption
								resource="users.json?enabled=1"
								resource_handle="users"
								item={null}
								plain
								fullWidth
								id_handle="id"
								label_handle="name"
								resourceName={{
									singular: this.props.t("contact.person.singular", "kontaktperson"),
									plural: this.props.t("contact.person.plural", "kontaktpersoner"),
								}}
								clearOption={this.props.t("contact.person.clear", "Ingen person")}
								onChange={(user) =>
									this.setState({
										title: user.name,
										email: user.email,
										selectedRoles: [],
									})
								}
								placeholder={this.props.t("board.members.fields.search.placeholder", "Sök efter person")}
								style={{ padding: 0 }}
							/>
							{this.state.title && this.state.email && (
								<div style={{ marginTop: "20px" }}>
									<p style={{ marginLeft: "15px", fontWeight: "bold" }}>{this.state.title}</p>
									<MemberRoles type={this.state.type} onChange={(value) => this.setState({ selectedRoles: value })} selected={[]} roles={null} />
								</div>
							)}
						</div>
					</FormLayout>
				);
			case "newExternalUser":
				return (
					<FormLayout>
						<TextField
							label={this.props.t("board.members.fields.name.label", "För- och efternamn")}
							value={this.state.title}
							onChange={(val) => {
								this.setState({ title: val });
							}}
						/>
						<TextField
							label={this.props.t("board.members.fields.email.label", "E-post")}
							type="email"
							value={this.state.email}
							onChange={(val) => {
								this.setState({ email: val, emailError: null });
							}}
							error={this.state.emailError}
						/>
						<MemberRoles type={this.state.type} onChange={(value) => this.setState({ selectedRoles: value })} selected={[]} roles={null} />
					</FormLayout>
				);

			default: {
				const enabled =
					this.state.roles.indexOf(`ROLE_${this.state.type.toUpperCase()}_ADMIN`) >= 0 ||
					this.state.roles.indexOf(`ROLE_${this.state.type.toUpperCase()}_OWNER`) >= 0;

				const options =
					this.getBoard() && this.getBoard().members
						? this.getBoard().members.map((member) => {
								const isAdmin = member.roles.includes(`ROLE_${this.state.type.toUpperCase()}_ADMIN`);
								const isCreator = member.roles.includes(`ROLE_${this.state.type.toUpperCase()}_OWNER`);

								return {
									onAction: this.editMember.bind(this, member),
									content: (
										<Stack>
											<MemberAvatar member={member} />
											<Stack vertical spacing="none">
												<Stack>
													<p>{member.title}</p>
													{isAdmin && <NumberBadge color={Colors.accent}>{this.props.t("common.terms.admin", "Admin")}</NumberBadge>}
													{isCreator && <NumberBadge color={Colors.purple}>{this.props.t("common.terms.creator", "Skapare")}</NumberBadge>}
												</Stack>
												<TextStyle variation="subdued">{member.email}</TextStyle>
											</Stack>
										</Stack>
									),
									disabled: !enabled,
								};
						  })
						: [];

				options.push({
					content: (
						<div style={{ opacity: !enabled ? 0.5 : 1 }}>
							<Stack>
								<Avatar customer size="small" />
								<Stack vertical spacing="none">
									<div>{this.props.t("board.members.actions.invite_member", "Bjud in medlem")}</div>
									<TextStyle variation="subdued">{this.props.t("board.members.actions.add_new", "Lägg till ny medlem")}</TextStyle>
								</Stack>
								{!enabled && (
									<Tooltip
										content={
											<Banner opacity={0.5} status="info" style={{ minWidth: "400px" }} title="Du kan inte bjuda in medlemmar">
												<p>Du kan inte bjuda in användare, bara skaparen eller admins kan bjuda in</p>
											</Banner>
										}
									>
										<InfoButton>
											<Button destructive plain icon={CircleInformationMajor} />
										</InfoButton>
									</Tooltip>
								)}
							</Stack>
						</div>
					),
					// disabled: !enabled,
					onAction: () => {
						if (!enabled) return null;

						if (this.props.hideNewExternal) {
							this.setPage("newUser");
						} else {
							this.setPage("inviteType");
						}
					},
				});

				return <ActionList items={options} />;
			}
		}
	}

	render() {
		const pageinfo = (() => {
			switch (this.state.page) {
				case "inviteType":
					return {
						title: this.props.t("board.members.plural", "Medlemmar"),
						secondaryActions: [
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: this.setPage.bind(this, null),
							},
						],
					};

				case "editMember":
					return {
						title: this.state.editingMember.title,
						primaryAction: {
							content: this.props.t("common.actions.save", "Spara"),
							loading: this.state.saving,
							onAction: this.submiteNewRoles.bind(this, this.state.editingMember),
						},
						secondaryActions: [
							{
								content: this.props.t("common.actions.remove", "Ta bort"),
								destructive: true,
								loading: this.state.saving,
								onAction: this.removeMember.bind(this, this.state.editingMember),
							},
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: this.setPage.bind(this, null),
							},
						],
					};
				case "newUser":
					return {
						title: this.props.t("board.members.actions.add_new", "Lägg till ny medlem"),
						primaryAction: {
							content: this.props.t("board.members.actions.invite", "Bjud in"),
							loading: this.state.saving,
							onAction: this.submitInviteContact.bind(this),
							disabled: !this.state.title || !this.state.email,
						},
						secondaryActions: [
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: this.setPage.bind(this, "inviteType"),
							},
						],
					};

				case "newExternalUser":
					return {
						title: this.props.t("board.members.actions.add_new_external", "Lägg till ny (Extern) medlem"),
						primaryAction: {
							content: this.props.t("board.members.actions.invite", "Bjud in"),
							loading: this.state.saving,
							onAction: this.submitInviteContact.bind(this),
							disabled: !this.state.title || !this.state.email,
						},
						secondaryActions: [
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: this.setPage.bind(this, "inviteType"),
							},
						],
					};

				default:
					return {
						title: this.props.t("board.members.plural", "Medlemmar"),
						secondaryActions: [
							{
								content: this.props.t("common.actions.close", "Stäng"),
								onAction: this.handleClose.bind(this),
							},
						],
					};
			}
		})();

		return (
			<React.Fragment>
				{!this.props.hideTrigger && (
					<div
						className="smallerAvatars"
						style={{ cursor: "pointer" }}
						onClick={() => {
							this.setState({
								open: true,
								editingMember: null,
								page: null,
							});
						}}
					>
						{this.getBoard().members.map((member) => (
							<MemberAvatar key={member.id} tooltip member={member} />
						))}
					</div>
				)}
				<Modal
					open={this.state.open || this.props.show}
					onClose={this.handleClose.bind(this)}
					title={pageinfo.title}
					primaryAction={pageinfo.primaryAction}
					secondaryActions={
						pageinfo.secondaryActions || [
							{
								content: this.props.t("common.actions.close", "Stäng"),
								onAction: this.handleClose.bind(this),
							},
						]
					}
				>
					<Modal.Section>{this.renderModalContent()}</Modal.Section>
				</Modal>
			</React.Fragment>
		);
	}
}
export default withTranslation(["board", "contact", "common"], {
	withRef: true,
})(BoardMembers);

const InfoButton = styled.div`
	cursor: pointer;
	z-index: 999;

	svg {
		fill: var(--red);
	}
`;
